import React from 'react';
import { Input, Checkbox } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import Picker from 'emoji-picker-react';

function FormField(props) {
  const {
    label,
    fieldType,
    required,
    placeholder,
    options,
    enableSearch,
    name,
    value,
    onChange,
    disabled,
    onSearch,
    emojiPickerTitle,
    setEmojiPickerTitle,
    emojiPickerBody,
    setEmojiPickerBody,
    onEmojiClick,
  } = props;
  return (
    <div className="field-container">
      <h2 className="field-label">
        {label}
        {required && <span className="field-label-required">*</span>}
      </h2>
      {fieldType === 'text-input' &&
        (enableSearch ? (
          <Input.Search
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onSearch={() => {
              onSearch();
            }}
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'start' }}>
            <div style={{ width: '100%' }}>
              <Input
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                maxLength={65}
                onFocus={() => (
                  setEmojiPickerTitle(false), setEmojiPickerBody(false)
                )}
              />
              <p className="text-area-char-count">{`${value['length']}/65`}</p>
            </div>
            <div style={{ position: 'relative' }}>
              <SmileOutlined
                style={{
                  fontSize: '20px',
                  marginLeft: '5px',
                  color: '#7c7c7c',
                }}
                onClick={() => {
                  setEmojiPickerTitle(!emojiPickerTitle);
                  setEmojiPickerBody(false);
                }}
              />
              {emojiPickerTitle ? (
                <Picker
                  pickerStyle={{
                    right: '20px',
                    position: 'absolute',
                    zIndex: 10,
                    top: 0,
                  }}
                  onEmojiClick={onEmojiClick}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        ))}
      {fieldType === 'text-area' && (
        <div
          className="field-text-area"
          style={{ display: 'flex', alignItems: 'start' }}
        >
          <div style={{ width: '100%' }}>
            <Input.TextArea
              rows={4}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={onChange}
              disabled={disabled}
              maxLength={178}
              onFocus={() => (
                setEmojiPickerTitle(false), setEmojiPickerBody(false)
              )}
            />
            <p className="text-area-char-count">{`${value['length']}/178`}</p>
          </div>
          <div style={{ position: 'relative' }}>
            <SmileOutlined
              style={{ fontSize: '20px', marginLeft: '5px', color: '#7c7c7c' }}
              onClick={() => {
                setEmojiPickerBody(!emojiPickerBody);
                setEmojiPickerTitle(false);
              }}
            />
            {emojiPickerBody ? (
              <Picker
                pickerStyle={{
                  right: '20px',
                  position: 'absolute',
                  zIndex: 10,
                  top: 0,
                }}
                onEmojiClick={onEmojiClick}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      )}
      {fieldType === 'checkbox' && (
        <div className="field-checkbox">
          <Checkbox.Group
            options={options}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
}

export default FormField;
