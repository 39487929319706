import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, TreeSelect } from 'antd';
import request from '../../utils/apisauce';

const { Option } = Select;

const SelectVendorWorker = props => {
  const {
    handleOnChange,
    value,
    loading,
    vendorId,
    treeStructure,
    customProps,
  } = props;
  const [vendorWorkers, updateVendorWorkers] = useState([]);
  const [loader, setLoader] = useState(false);
  let timeout = null;

  let role = 'worker,admin_worker';
  const getVendorWorkersList = useCallback(async () => {
    if (vendorId) {
      let url = `/api/v1/admin/vendors/users?vendor_id=${vendorId}&role=${role}`;
      setLoader(true);
      try {
        const { vendor_users } = await request('GET', url);
        updateVendorWorkers([
          { id: null, first_name: 'No Worker', last_name: 'Assigned' },
          ...vendor_users,
        ]);
        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    }
  }, [vendorId]);

  const onSearchVendor = () => {
    setLoader(true);
    const url = `/api/v1/admin/vendors/users?vendor_id=${vendorId}&role=${role}`;
    try {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(async () => {
        const { vendor_users } = await request('GET', url);
        updateVendorWorkers(vendor_users);
        setLoader(false);
      }, 500);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getVendorWorkersList();
  }, [getVendorWorkersList]);

  const activeVendorWorkers = vendorWorkers.filter(
    worker => worker.status === 'active'
  );
  const inactiveVendorWorkers = vendorWorkers.filter(
    worker => worker.status === 'inactive'
  );

  let treeNode = [
    ...activeVendorWorkers.map(worker => ({
      title: worker.first_name + ' ' + worker.last_name,
      value: worker.id,
    })),
  ];
  if (inactiveVendorWorkers.length > 0) {
    treeNode = [
      ...treeNode,
      {
        title: 'Inactive Workers',
        value: -99,
        treeCheckable: true,
        children: [
          ...inactiveVendorWorkers.map(worker => ({
            title: worker.first_name + ' ' + worker.last_name,
            value: worker.id,
          })),
        ],
      },
    ];
  }

  return treeStructure ? (
    <TreeSelect
      style={{ width: '100%' }}
      value={value ? value : null}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={vendorWorkers.length > 0 ? treeNode : []}
      placeholder="Search vendor worker"
      showSearch
      filterOption={(input, option) =>
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={val => {
        if (val !== -99) {
          handleOnChange(vendorWorkers.find(worker => worker['id'] === val));
        }
      }}
      {...customProps}
    />
  ) : (
    <Select
      style={{ width: '100%' }}
      value={value}
      placeholder="Search vendor worker"
      loading={loading || loader}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onSearch={onSearchVendor}
      onChange={value =>
        handleOnChange(vendorWorkers.find(worker => worker['id'] === value))
      }
      {...customProps}
    >
      {vendorWorkers.map(el => (
        <Option key={el.id} value={el.id}>
          {`${el.first_name} ${el.last_name}`}
        </Option>
      ))}
    </Select>
  );
};

SelectVendorWorker.propTypes = {
  value: PropTypes.number,
  vendorId: PropTypes.string,
  loading: PropTypes.bool,
  customProps: PropTypes.object,
  handleOnChange: PropTypes.func,
  onSearchVendor: PropTypes.func,
};

export default SelectVendorWorker;
