import React from 'react';
import { Avatar, Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';

const TimelineItem = props => {
  const { jobId, time, workerName, vendorName, clientName, action } = props;
  const goToJob = () => props.history.push('#' + jobId);
  return (
    <div className="timeline-content">
      <div className="checkbox-container">
        <Checkbox />
      </div>
      <div className="avatar-container">
        <Avatar
          size={52}
          src={null}
          icon={<UserOutlined />}
          className="timeline-avatar"
        />
      </div>
      <div onClick={goToJob} className="timeline-details-container">
        <p className="timeline-details-name">
          <span className="font-bold mr-2" title={vendorName}>
            {workerName}
          </span>{' '}
          {vendorName}
        </p>
        <p className="timeline-details-check-text">
          <span className="mr-2">{action}</span>
          <span className="timeline-details-check-text-link" title={clientName}>
            {clientName}
          </span>
        </p>
      </div>
      <p className="timeline-timestamp">{time}</p>
    </div>
    // <Timeline.Item onClick={goToJob} color="grey">
    //   <p>{time}</p>
    //   <div className="timeline-content">
    //     <Avatar
    //       size={48}
    //       src={null}
    //       icon={<UserOutlined />}
    //       className="timeline-avatar"
    //     />
    //     <div onClick={goToJob} className="timeline-details-container">
    //       <p className="timeline-details-name">
    //         {workerName} &nbsp;&nbsp; - &nbsp;&nbsp;
    //         <span className="timeline-details-secondary-text">
    //           {vendorName}
    //         </span>
    //       </p>
    //       <p className="timeline-details-check-text">
    //         {action}{' '}
    //         <span className="timeline-details-check-text-link">
    //           {clientName}
    //         </span>
    //       </p>
    //     </div>
    //   </div>
    // </Timeline.Item>
  );
};

TimelineItem.propTypes = {
  jobId: PropTypes.number,
  time: PropTypes.string,
  workerName: PropTypes.string,
  vendorName: PropTypes.string,
  clientName: PropTypes.string,
  action: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(TimelineItem);
