import React from 'react';

const Archive = props => (
  <span aria-label="message" className="anticon anticon-message">
    <svg
      width="1.1em"
      height="1.2em"
      viewBox="0 0 220 220"
      fill="green"
      cursor="pointer"
      id="Flat"
      {...props}
    >
      <path d="M208,36H48A12.01343,12.01343,0,0,0,36,48V159.94189c-.00049.02-.00586.03809-.00586.05811s.00537.03809.00586.05811V208a12.01343,12.01343,0,0,0,12,12H208a12.01343,12.01343,0,0,0,12-12V48A12.01343,12.01343,0,0,0,208,36ZM48,44H208a4.00427,4.00427,0,0,1,4,4V156H179.30762a11.92254,11.92254,0,0,0-8.48535,3.51465l-19.31348,19.31348A3.97188,3.97188,0,0,1,148.68066,180h-41.373a3.975,3.975,0,0,1-2.82813-1.1709l-19.314-19.31543A11.92372,11.92372,0,0,0,76.68066,156H44V48A4.00427,4.00427,0,0,1,48,44ZM208,212H48a4.00427,4.00427,0,0,1-4-4V164H76.68066a3.975,3.975,0,0,1,2.82813,1.1709l19.314,19.31543A11.92374,11.92374,0,0,0,107.30762,188h41.373a11.92257,11.92257,0,0,0,8.48536-3.51465l19.31347-19.31347A3.97185,3.97185,0,0,1,179.30762,164H212v44A4.00427,4.00427,0,0,1,208,212ZM91.23047,120.88672a3.99975,3.99975,0,0,1,5.65674-5.65625L124,142.34277V72a4,4,0,0,1,8,0v70.34277l27.11279-27.1123a3.99975,3.99975,0,1,1,5.65674,5.65625L130.8291,154.82715a4.01542,4.01542,0,0,1-.60449.49609c-.05518.03711-.11719.06055-.17383.09473a3.96946,3.96946,0,0,1-.51318.27344c-.08106.03418-.16895.0498-.25244.07861a3.93275,3.93275,0,0,1-.49414.15039,3.97064,3.97064,0,0,1-1.582,0,3.93275,3.93275,0,0,1-.49414-.15039c-.08349-.02881-.17138-.04443-.25244-.07861a3.96946,3.96946,0,0,1-.51318-.27344c-.05664-.03418-.11865-.05762-.17383-.09473a4.01542,4.01542,0,0,1-.60449-.49609Z" />
    </svg>
  </span>
);

export default Archive;
