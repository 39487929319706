import { createSlice } from '@reduxjs/toolkit';
// import { message, notification } from 'antd';
// import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'companyAccounts',
  initialState: {
    submitting: false,
    loading: false,
    accounts: [],
    error: null,
  },
  reducers: {
    getAccounts: state => {
      state.loading = true;
      state.error = null;
    },
    getAccountsSuccess: (state, { payload }) => {
      state.loading = false;
      state.meta = payload['meta'];
      state.accounts = payload['company_accounts'];
      state.error = null;
    },
    getAccountsFailed: (state, { payload }) => {
      state.loading = false;
      state.accounts = [];
      state.error = payload;
    },
  },
});

export const {
  getAccounts,
  getAccountsSuccess,
  getAccountsFailed,
} = slice.actions;

export default slice.reducer;
