import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import moment from 'moment';

import {
  getActiveClientsCount,
  getActiveClientsCountSuccess,
  getActiveClientsCountFailed,
  getJobFeedCount,
  getJobFeedCountSuccess,
  getJobFeedCountFailed,
  getJobStatisticsCount,
  getJobStatisticsCountSuccess,
  getJobStatisticsCountFailed,
  getWorkStatus,
  getWorkStatusSuccess,
  getWorkStatusFailed,
  getJobFeed,
  getJobFeedSuccess,
  getJobFeedFailed,
  getWorkerLocation,
  getWorkerLocationSuccess,
  getWorkerLocationFailed,
  getJobs,
  getJobsSuccess,
  getJobsFailed,
} from './reducers';

function* getActiveClientsCountSaga() {
  let res;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/dashboard/user_activity_count`
    );
    res = getActiveClientsCountSuccess(response);
  } catch (error) {
    res = getActiveClientsCountFailed(error.message);
  }
  yield put(res);
}

function* getJobFeedCountSaga(action) {
  let res;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/dashboard/job_feed_count?date=${action['payload']}`
    );
    res = getJobFeedCountSuccess(response);
  } catch (error) {
    res = getJobFeedCountFailed(error.message);
  }
  yield put(res);
}

function* getJobStatisticsCountSaga(action) {
  let res;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/dashboard/job_statistics_count?date=${action['payload']}`
    );
    res = getJobStatisticsCountSuccess(response);
  } catch (error) {
    res = getJobStatisticsCountFailed(error.message);
  }
  yield put(res);
}

function* getWorkStatusSaga(action) {
  let res;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/dashboard/worker_status?date=${action['payload']}`
    );
    res = getWorkStatusSuccess(response);
  } catch (error) {
    res = getWorkStatusFailed(error.message);
  }
  yield put(res);
}

function* getJobFeedSaga() {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/dashboard/job_event_logs`
    );
    let updatedResponse = { ...response };
    updatedResponse['job_event_logs'] = updatedResponse['job_event_logs'].sort(
      (a, b) => {
        const timestampA = new Date(a['created_at']).getTime();
        const timestampB = new Date(b['created_at']).getTime();
        return timestampB - timestampA;
      }
    );
    action = getJobFeedSuccess(updatedResponse);
  } catch (error) {
    action = getJobFeedFailed(error.message);
  }
  yield put(action);
}

function* getWorkerLocationSaga() {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      '/api/v1/admin/dashboard/worker_location'
    );
    action = getWorkerLocationSuccess(response);
  } catch (error) {
    action = getWorkerLocationFailed(error.message);
  }
  yield put(action);
}

function* getJobsSaga() {
  let action;
  try {
    const response = yield call(request, 'GET', `/api/v1/jobs`, {
      date: moment().format('YYYY-MM-DD'),
      all: true,
    });
    action = getJobsSuccess(response);
  } catch (error) {
    action = getJobsFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getActiveClientsCount, getActiveClientsCountSaga);
  yield takeLatest(getJobFeedCount, getJobFeedCountSaga);
  yield takeLatest(getJobStatisticsCount, getJobStatisticsCountSaga);
  yield takeLatest(getWorkStatus, getWorkStatusSaga);
  yield takeLatest(getJobFeed, getJobFeedSaga);
  yield takeLatest(getWorkerLocation, getWorkerLocationSaga);
  yield takeLatest(getJobs, getJobsSaga);
}
