import { Modal } from 'antd';
import React from 'react';

export default function CategoryDelete({
  selectedRow,
  modalOpened,
  onCloseModal,
  performDelete,
}) {
  return (
    <Modal
      className="delete-user-modal"
      visible={modalOpened === 2}
      onCancel={onCloseModal}
      onOk={performDelete}
      okText="Yes"
      destroyOnClose
    >
      {selectedRow && (
        <>
          <h1 className="main-message pop-up-heading">Are you sure?</h1>
          <p className="pop-up-body">
            Do you really want to remove this category? The process cannot be
            undone.
          </p>
        </>
      )}
    </Modal>
  );
}
