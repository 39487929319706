import { CloseOutlined } from '@ant-design/icons';
import { Card, Drawer, Modal, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import JobDetailModal from './jobDetailModal';
import JobForm from './form';
import { getJobs, deleteJob, updateJob } from '../reducers';
import DataTable from '../../../components/DataTable';
import { withRouter } from 'react-router-dom';
import {
  DeleteFilledIcon,
  EditFilledIcon,
} from '../../../components/Icons/Icons';
import { capitalize } from '../../../utils/apisauce';
import MobileTable from './MobTable';

const statusLabels = {
  pending_schedule: {
    color: '#F1B13B',
    label: 'Unassigned',
  },
  scheduled: {
    color: '#34A6BD',
    label: 'In Scheduled',
  },
  delayed: {
    color: '#34A6BD',
    label: 'Delayed',
  },
  draft: {
    color: '#DF5E67',
    label: 'Draft',
  },
  paused: {
    color: '#F1B13B',
    label: 'Paused',
  },
  skipped: {
    color: '#DF5E67',
    label: 'Skipped',
  },
  in_progress: {
    color: '#6ABB98',
    label: 'In Progress',
  },
  completed: {
    color: '#828282',
    label: 'Completed',
  },
  cancelled: {
    color: '#DF5E67',
    label: 'Cancelled/Skipped',
  },
};

class SingleJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      selectedRow: null,
      formData: null,
      pageQuery: {
        page: 1,
        per: 20,
        query: null,
      },
      windowSize: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { getJobs, queryString } = this.props;
    const { pageQuery } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getJobs({ ...pageQuery, query: queryString });
      } else {
        getJobs(pageQuery);
      }
    }
  }

  handleWindowSize = () => {
    if (window.innerWidth < 767) {
      this.setState({ windowSize: true });
    } else {
      this.setState({ windowSize: false });
    }
  };

  componentDidMount() {
    const { getJobs, queryString } = this.props;
    const { pageQuery } = this.state;
    if (window.innerWidth < 767) {
      this.handleWindowSize();
    }
    window.addEventListener('resize', this.handleWindowSize);
    if (queryString) {
      getJobs({ ...pageQuery, query: queryString });
    } else {
      getJobs(pageQuery);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSize);
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onClickRow = (e, { id }) => {
    const { setJobId, jobDetailsModalOpen } = this.props;
    setJobId(id);
    jobDetailsModalOpen();
  };

  onEdit = record => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        id: record.id,
        title: record.title,
        description: record.description,
        job_duration_in_minutes: record.job_duration_in_minutes,
        client_type:
          record.client_type === 'PersonalClient' ? 'personal' : 'business',
        vendor_id: record.vendor_id,
        assigned_worker_id: record['assigned_worker']
          ? record['assigned_worker'].id
          : '',
        assigned_time: record.assigned_time,
        client_id: record.client.id,
        client_title: record.client.title,
        address_title: record.address?.address_title,
        address_id: record.address.id,
        service_date: record.service_date,
        preferred_time: record.preferred_time,
        job_notes: record.job_notes,
        customer_notes: record.customer_notes,
        fixed_service_time: record.fixed_service_time,
      },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  performDelete = () => {
    const { selectedRow, pageQuery } = this.state;
    const { deleteJob, queryString } = this.props;

    if (queryString) {
      deleteJob({ ...pageQuery, query: queryString, id: selectedRow.id });
    } else {
      deleteJob({ ...pageQuery, id: selectedRow.id });
    }
    this.closeModal();
  };

  onSubmit = data => {
    const { updateJob } = this.props;
    const {
      pageQuery: { page, per },
    } = this.state;
    const body = {
      job: {
        title: data?.title,
        description: data?.description,
        job_duration_in_minutes: data?.job_duration_in_minutes,
        vendor_id: data?.vendor_id,
        assigned_worker_id: data?.assigned_worker_id,
        client_type: 'PersonalClient',
        client_id: data?.client_id,
        address_id: data?.address_id,
        service_date: data?.service_date,
        preferred_time: data?.preferred_time,
        assigned_time: data?.assigned_time,
        job_notes: data?.job_notes,
        fixed_service_time: data?.fixed_service_time,
      },
    };
    updateJob(data.id, body, page, per);
    this.closeModal();
  };

  tableColumns = () => [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
      width: 70,
      // defaultSortOrder: 'ascend',
      // sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Status',
      key: 'job_status',
      fixed: 'left',
      width: 70,
      render: (_, record) =>
        record?.job_status ? (
          <span style={{ color: statusLabels[record.job_status].color }}>
            {statusLabels[record.job_status].label}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Service Date',
      key: 'service_date',
      dataIndex: 'service_date',
      defaultSortOrder: 'descend',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.service_date).getTime();
        const dateB = new Date(b.service_date).getTime();
        return dateA - dateB;
      },
      render: (_, record) =>
        record?.service_date
          ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-',
    },
    {
      title: 'Order ID',
      key: 'order_id',
      width: 140,
      render: (_, record) => (record?.order ? record?.order?.id : '-'),
    },
    {
      title: 'Order Amount',
      key: 'order_amount',
      width: 140,
      align: 'center',
      render: (_, record) => (record?.order ? record?.order?.amount : '-'),
    },
    {
      title: 'Created By',
      key: 'created_by',
      width: 140,
      render: (_, record) =>
        record?.creator_type
          ? capitalize(record?.creator_type)
          : 'Order System',
    },
    {
      title: 'Title',
      key: 'title',
      width: 120,
      render: (_, record) => (record?.title ? capitalize(record.title) : '-'),
    },
    {
      title: 'Client Name',
      key: 'client_name',
      width: 180,
      render: (_, record) =>
        record?.client_type === 'PersonalClient'
          ? capitalize(record.client?.title)
          : record?.client?.business_name
          ? capitalize(record.client?.business_name)
          : '-',
    },
    {
      title: 'Client Address',
      key: 'client_address',
      render: (_, record) =>
        record?.address ? (
          // ? `${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`
          <Tooltip
            title={`${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`}
            placement="top"
          >
            {capitalize(
              `${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`
            ).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'Vendor Name',
      key: 'vendor_id',

      render: (_, record) =>
        record?.vendor?.vendor_name
          ? capitalize(record.vendor.vendor_name)
          : '-',
    },
    {
      title: 'Assigned Worker',
      key: 'assigned_worker',
      width: 160,
      render: (_, record) =>
        record['assigned_worker']
          ? Object.keys(record['assigned_worker']).length > 0
            ? `${capitalize(
                record['assigned_worker']?.first_name
              )} ${capitalize(record['assigned_worker']?.last_name)}`
            : '-'
          : '-',
    },
    {
      title: 'Preferred Time',
      key: 'preferred_time',
      width: 120,
      render: (_, record) => {
        return record['preferred_time'] ? record['preferred_time'] : '-';
      },
    },
    {
      title: 'Assigned Time',
      key: 'assigned_time',
      width: 120,
      render: (_, record) =>
        record['assigned_time'] ? record['assigned_time'] : '-',
    },
    {
      title: 'Service Duration',
      key: 'job_duration_in_minutes',
      width: 120,
      render: (_, record) =>
        record['job_duration_in_minutes']
          ? record['job_duration_in_minutes'] + ' min'
          : '-',
    },
    {
      title: 'Check In',
      key: 'checkin_time',
      width: 120,
      render: (_, record) => (record?.checkin_time ? record.checkin_time : '-'),
    },
    {
      title: 'Check Out',
      key: 'checkout_time',
      width: 120,
      render: (_, record) =>
        record?.checkout_time ? record.checkout_time : '-',
    },
    {
      title: 'Active Job Duration',
      width: 160,
      render: (_, record) => {
        if (record?.checkin_time && record?.checkout_time) {
          return record['total_job_time'] ? record['total_job_time'] : '-';
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Job Notes',
      key: 'job_notes',

      render: (_, record) =>
        record['job_notes']
          ? `${
              record['job_notes']['length'] > 25
                ? record['job_notes'].slice(0, 25) + '...'
                : record['job_notes']
            }`
          : '-',
    },
    {
      title: 'Customer Notes',
      key: 'customer_notes',
      width: 120,
      render: (_, record) =>
        record['customer_notes']
          ? `${
              record['customer_notes']['length'] > 25
                ? record['customer_notes'].slice(0, 25) + '...'
                : record['customer_notes']
            }`
          : '-',
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (text, record) => {
        // const day = new Date().getDate();
        // const month = new Date().getMonth();
        // const year = new Date().getFullYear();
        // const serviceDate = new Date(record['service_date']).getTime();
        // const currentStartDate = new Date(
        //   new Date(`${year}-${month + 1}-${day}`).setHours(0, 0, 0, 0)
        // ).getTime();
        // const currentEndDate = new Date(
        //   new Date(`${year}-${month + 1}-${day}`).setHours(24, 0, 0, 0)
        // ).getTime();

        return (
          <div
            className="ant-table-action-cell"
            style={{ justifyContent: 'center' }}
          >
            {/* {currentStartDate <= serviceDate &&
              serviceDate <= currentEndDate && (
                <EditFilled
                  onClick={e => e.stopPropagation() || this.onEdit(record)}
                />
              )} */}
            <EditFilledIcon
              onClick={e => e.stopPropagation() || this.onEdit(record)}
            />
            <DeleteFilledIcon
              onClick={e => e.stopPropagation() || this.onDelete(record)}
            />
          </div>
        );
      },
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { order } = sorter;
    const { pageQuery } = this.state;

    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    updatedPageQuery['order'] = order;
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const { modalOpened, selectedRow, formData, windowSize } = this.state;
    const { loading, jobs } = this.props;
    const { limit, total, page, data } = jobs;

    return (
      <>
        {windowSize ? (
          <Card
            title="One time Jobs"
            className="overflow-x-auto"
            style={{ marginTop: 15 }}
          >
            <MobileTable
              records={data}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        ) : (
          <Card
            title="One time Jobs"
            className="overflow-x-auto"
            style={{ marginTop: 15 }}
          >
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              columns={this.tableColumns()}
              onClickRow={this.onClickRow}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        )}
        <Drawer
          closable={false}
          placement="right"
          width={450}
          className="job-create-drawer"
          visible={modalOpened === 1}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Edit Job</h3>
                <div className="header-actions">
                  <DeleteFilledIcon
                    className="action-primary"
                    onClick={() => this.onDelete(formData)}
                  />
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <JobForm
                  data={formData}
                  type="single"
                  onSubmit={this.onSubmit}
                />
              </div>
            </>
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <h1 className="main-message pop-up-heading">Are you sure?</h1>
              <p className="pop-up-body">
                Do you really want to remove this job? The process cannot be
                undone.
              </p>
            </>
          )}
        </Modal>
        {/* <JobDetailModal
          data={selectedRow}
          isOpened={modalOpened === 3}
          onClose={this.closeModal}
        /> */}
      </>
    );
  }
}

SingleJobs.propTypes = {
  loading: PropTypes.bool,
  jobs: PropTypes.object,
  getJobs: PropTypes.func,
  deleteJob: PropTypes.func,
  updateJob: PropTypes.func,
  queryString: PropTypes.string,
  jobDetailsModalOpen: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.jobs.loading,
    jobs: state.jobs.jobs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJobs: payload => dispatch(getJobs({ type: 'single', ...payload })),
    deleteJob: payload => dispatch(deleteJob({ type: 'single', ...payload })),
    updateJob: (id, data, page, per) =>
      dispatch(updateJob({ id, data, page, per })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SingleJobs));
