import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorTransactions',
  initialState: {
    submitting: false,
    loading: false,
    transactions: {
      limit: 0,
      total: 0,
      page: 1,
      data: [],
    },
    error: '',
  },
  reducers: {
    getTransactionsforTransfer: state => {
      state.loading = true;
    },
    getTransactionsforTransferSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload['accounting_transactions'];
      let meta = payload['meta'];
      state.transactions.limit = meta['per_page'];
      state.transactions.page = parseInt(meta.page);
      state.transactions.total = meta['total_count'];
    },
    getTransactionsforTransferFailed: state => {
      state.loading = false;
      state.transactions = [];
    },
    getTransactionsforSubcription: state => {
      state.loading = true;
    },
    getTransactionsforSubcriptionSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload['accounting_transactions'];
      let meta = payload['meta'];
      state.transactions.limit = meta['per_page'];
      state.transactions.page = parseInt(meta.page);
      state.transactions.total = meta['total_count'];
    },
    getTransactionsforSubcriptionFailed: state => {
      state.loading = false;
      state.transactions = [];
    },
    gettransactionsforService: state => {
      state.loading = true;
    },
    getTransactionsforServiceSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload['accounting_transactions'];
      let meta = payload['meta'];
      state.transactions.limit = meta['per_page'];
      state.transactions.page = parseInt(meta.page);
      state.transactions.total = meta['total_count'];
    },
    getTransactionsforServiceFailed: state => {
      state.loading = false;
      state.transactions = [];
    },
    gettransactionsforProduct: state => {
      state.loading = true;
    },
    getTransactionsforProductSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload['accounting_transactions'];
      let meta = payload['meta'];
      state.transactions.limit = meta['per_page'];
      state.transactions.page = parseInt(meta.page);
      state.transactions.total = meta['total_count'];
    },
    getTransactionsforProductFailed: state => {
      state.loading = false;
      state.transactions = [];
    },
    getTransactionsfortopup: state => {
      state.loading = true;
    },
    getTransactionsfortopupSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload['accounting_transactions'];
      let meta = payload['meta'];
      state.transactions.limit = meta['per_page'];
      state.transactions.page = parseInt(meta.page);
      state.transactions.total = meta['total_count'];
    },
    getTransactionsfortopupFailed: state => {
      state.loading = false;
      state.transactions = [];
    },
    getTransactions: state => {
      state.loading = true;
    },
    getTransactionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions.data = payload['accounting_transactions'];
      let meta = payload['meta'];
      state.transactions.limit = meta['per_page'];
      state.transactions.page = parseInt(meta.page);
      state.transactions.total = meta['total_count'];
    },
    getTransactionsFailed: state => {
      state.loading = false;
      state.transactions = [];
    },
    createTransaction: state => {
      state.submitting = true;
      state.message = message.loading('Creating a new Transaction ...', 0);
    },
    createTransactionSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.transactions.push({
        ...payload,
        worker_count: 0,
      });
      notification.success({
        message: 'New Transaction',
        description: `New Transaction has been created.`,
      });
    },
    createTransactionFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    updateTransaction: state => {
      state.submitting = true;
      state.message = message.loading('Updating transaction info...', 0);
    },
    updateTransactionSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      const idx = state.transactions.findIndex(el => el.id === payload.id);
      if (idx >= 0) {
        state.transactions[idx] = {
          ...state.transactions[idx],
          ...payload,
        };
      }
      notification.success({
        message: 'Transaction Updated',
        description: `Transaction has been updated.`,
      });
    },
    updateTransactionFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    deleteTransaction: state => {
      state.message = message.loading('Deleting Transaction ...', 0);
    },
    deleteTransactionSuccess: (state, { payload }) => {
      state.message();
      state.transactions = state.transactions.filter(x => x.id !== payload.id);
      notification.success({
        message: 'Delete Transaction',
        description: `Transaction have been deleted.`,
      });
    },
    deleteTransactionFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    createWithdrawalRequest: state => {
      state.submitting = true;
      state.message = message.loading('Creating a new Request ...', 0);
    },
    createWithdrawalRequestSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      // state.transactions.push({
      //   ...payload,
      //   worker_count: 0,
      // });
      notification.success({
        message: 'New Request',
        description: payload.meta.message,
      });
    },
    createWithdrawalRequestFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
  },
});

export const {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
  getTransactionsforTransfer,
  getTransactionsforTransferSuccess,
  getTransactionsforTransferFailed,
  getTransactionsfortopup,
  getTransactionsfortopupSuccess,
  getTransactionsfortopupFailed,
  gettransactionsforProduct,
  getTransactionsforProductSuccess,
  getTransactionsforProductFailed,
  gettransactionsforService,
  getTransactionsforServiceSuccess,
  getTransactionsforServiceFailed,
  getTransactionsforSubcription,
  getTransactionsforSubcriptionSuccess,
  getTransactionsforSubcriptionFailed,
  createTransaction,
  createTransactionSuccess,
  createTransactionFailed,
  updateTransaction,
  updateTransactionSuccess,
  updateTransactionFailed,
  deleteTransaction,
  deleteTransactionSuccess,
  deleteTransactionFailed,
  createWithdrawalRequest,
  createWithdrawalRequestSuccess,
  createWithdrawalRequestFailed,
} = slice.actions;

export default slice.reducer;
