import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Drawer,
  Input,
  Modal,
  // Select,
  Checkbox,
  Form,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dig } from 'digdata';
import DataTable from '../../../../components/DataTable';
import TransactionTable from '../../../../components/TransactionTable';
import AvatarUpload from '../../../../components/AvatarUpload';
import { capitalize, getImageURI } from '../../../../utils/apisauce';
import {
  getClients,
  updateClient,
  deleteClient,
  // getTransactionDetails,
} from '../reducers';
import { Formik } from 'formik';
import { formInitialValues } from '../../../../utils/formsInitialValues';
import { formValidations } from '../../../../utils/formValidations';
import {
  EditFilledIcon,
  // DeleteFilledIcon,
  // MoneyCollectOutlinedIcon,
} from '../../../../components/Icons/Icons';

class PersonalClients extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    showPassword: false,
  };

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onEdit = record => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        clientId: record.id,
        userId: record['personal_users'][0].id,
        picture_path: record['personal_users'][0]['picture_path'],
        first_name: record['personal_users'][0].first_name,
        last_name: record['personal_users'][0].last_name,
        email: record['personal_users'][0].email,
        mobile: record['personal_users'][0].mobile,
        status: record['personal_users'][0].status,
        password: '',
        image_type: record['personal_users'][0].image_type,
      },
    });
  };

  handleOnSubmit = values => {
    const { formData } = this.state;
    const { updateClient } = this.props;
    updateClient(formData.clientId, formData.userId, {
      picture: formData.picture,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      mobile: values.mobile,
      status: formData.status,
      password: values.password,
    });

    this.closeModal();
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteClient } = this.props;

    deleteClient(selectedRow.id);
    this.closeModal();
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  showClientAddress = record => {
    const {
      history: { push },
    } = this.props;

    push({
      pathname: `/vendor/clients/personal/${record.id}`,
    });
  };

  tableColumns = () => [
    {
      title: 'Client ID',
      key: 'id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      fixed: 'left',
      width: 100,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Image',
      key: 'userImg',
      dataIndex: 'userImg',
      fixed: 'left',
      width: 70,
      render: (text, record) => (
        <Avatar
          size={42}
          src={getImageURI(
            dig(
              record['personal_users'] && record['personal_users'].length
                ? record['personal_users'][0]
                : {},
              'picture_path.url'
            )
          )}
          icon={<UserOutlined />}
        />
      ),
    },
    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'first_name',
      render: (text, record) => (
        <p>{capitalize(record?.personal_users[0]?.first_name)}</p>
      ),
    },
    {
      title: 'Last Name',
      key: 'last_name',
      dataIndex: 'last_name',
      render: (text, record) => (
        <p>{capitalize(record?.personal_users[0]?.last_name)}</p>
      ),
    },

    {
      title: 'Phone Number',
      key: 'mobile',
      render: (text, record) =>
        record['personal_users'] && record['personal_users'].length
          ? record['personal_users'][0].mobile
          : '',
    },
    {
      title: 'Email Address',
      key: 'email',
      render: (text, record) =>
        record['personal_users'] && record['personal_users'].length
          ? record['personal_users'][0].email
          : '',
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) =>
        record['personal_users'] && record['personal_users'].length
          ? record['personal_users'][0].status
          : '',
    },
    {
      title: 'Last Activity',
      key: 'last_activity_at',
      render: (text, record) =>
        record['personal_users'] &&
        record['personal_users'].length &&
        record['personal_users'][0].last_activity_at
          ? moment(record['personal_users'][0].last_activity_at).format(
              'DD-MM-YYYY hh:mm:ss'
            )
          : '-',
    },
    {
      title: 'Saved Address',
      key: 'address_count',
      render: (text, record) =>
        record['address_books'] ? record['address_books'].length : 0,
    },
    {
      title: 'Account Balance',
      key: 'formatted_balance',
      dataIndex: 'formatted_balance',
      render: (text, record) => {
        return (
          <p>
            {record?.personal_users[0]?.account?.formatted_balance
              ? record?.personal_users[0]?.account?.formatted_balance
              : '-'}
          </p>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className="ant-table-action-cell">
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
        </div>
      ),
    },
  ];

  render() {
    const {
      modalOpened,
      selectedRow,
      formData,
      showPassword,
      // pageQuery,
      // dataSource,
    } = this.state;
    const { loading, dataSource, pagination, handleTableChange } = this.props;
    return (
      <>
        <Card
          title="Clients"
          style={{ marginTop: 15 }}
          className="overflow-x-auto"
        >
          <DataTable
            rowKey={record => record['id'] || 'id'}
            loading={loading}
            data={dataSource}
            columns={this.tableColumns(this.showClientAddress)}
            // onClickRow={this.onClickRow}
            pagination={pagination}
            handleTableChange={handleTableChange}
          />
        </Card>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={modalOpened === 1}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Edit Client</h3>
                <div className="header-actions">
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <Formik
                  initialValues={{
                    ...formInitialValues['createPersonalClientForm'],
                    ...formData,
                  }}
                  validationSchema={
                    formValidations['UpdatePersonalClientFormValidation']
                  }
                  onSubmit={values => this.handleOnSubmit(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="user-avatar">
                          <AvatarUpload
                            value={getImageURI(
                              dig(formData, 'picture_path.url')
                            )}
                            icon={<UserOutlined />}
                            onChange={value =>
                              this.onInputChange({
                                target: {
                                  name: 'picture',
                                  value: { data: value },
                                },
                              })
                            }
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                        <div className="form-group">
                          <label>Client ID</label>
                          <span>{formData.clientId}</span>
                        </div>
                        <div className="form-group">
                          <label>First Name</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['first_name'] && errors['first_name']
                                ? 'error'
                                : null
                            }
                            help={touched['first_name'] && errors['first_name']}
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="first_name"
                              value={values.first_name}
                              placeholder="Enter first name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Last Name</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['last_name'] && errors['last_name']
                                ? 'error'
                                : null
                            }
                            help={touched['last_name'] && errors['last_name']}
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="last_name"
                              value={values.last_name}
                              placeholder="Enter last name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['mobile'] && errors['mobile']
                                ? 'error'
                                : null
                            }
                            help={touched['mobile'] && errors['mobile']}
                            style={{ margin: 0 }}
                          >
                            <PhoneInput
                              defaultCountry={'sg'}
                              inputStyle={{ width: '100%' }}
                              value={values.mobile}
                              onChange={value =>
                                handleChange({
                                  target: { name: 'mobile', value },
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Email Address</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['email'] && errors['email']
                                ? 'error'
                                : null
                            }
                            help={touched['email'] && errors['email']}
                            style={{ margin: 0 }}
                          >
                            <Input
                              type="email"
                              name="email"
                              value={values.email}
                              placeholder="Enter email address"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        {formData.clientId && (
                          <Checkbox
                            style={{ margin: '1em 0' }}
                            onChange={() =>
                              this.setState({ showPassword: !showPassword })
                            }
                          >
                            Change Password
                          </Checkbox>
                        )}
                        {(formData.clientId && showPassword) ||
                        !formData.clientId ? (
                          <div className="form-group">
                            <label>New Password</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['password'] && errors['password']
                                  ? 'error'
                                  : null
                              }
                              help={touched['password'] && errors['password']}
                              style={{ margin: 0 }}
                            >
                              <Input.Password
                                name="password"
                                value={values.password}
                                placeholder="Set New Password"
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </div>
                        ) : null}
                        <Button type="primary" htmlType="submit" block>
                          Save
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <div className="user-info">
                <Avatar
                  src={getImageURI(dig(selectedRow, 'picture_path.url'))}
                  icon={<UserOutlined />}
                  size={72}
                />
                <div className="username">{selectedRow.vendor_name}</div>
                <span>Client ID:&nbsp;{selectedRow.id}</span>
              </div>
              <p className="main-message">
                Are you sure want to remove this client?
              </p>
              <p>This action can not be undone</p>
            </>
          )}
        </Modal>
        <Modal
          visible={modalOpened === 3}
          onCancel={this.closeModal}
          destroyOnClose
          okButtonProps={{ style: { display: 'none' } }}
          width={'100%'}
        >
          <Card className="overflow-x-auto">
            <TransactionTable
              dataSource={this.props.accounting_transactions}
              loading={this.props['loading']}
              // setModalOpen={setModalOpen}
            />
          </Card>
        </Modal>
      </>
    );
  }
}

PersonalClients.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.object,
  clients: PropTypes.array,
  metaInfo: PropTypes.object,
  getClients: PropTypes.func,
  createClient: PropTypes.func,
  updateClient: PropTypes.func,
  deleteClient: PropTypes.func,
  // getTransactionDetails: PropTypes.func,
  dataSource: PropTypes.array,
  pagination: PropTypes.object,
  handleTableChange: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  accounting_transactions: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    submitting: state.vendorClient.submitting,
    error: state.vendorClient.error,
    accounting_transactions: state.vendorClient.accounting_transactions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClients: payload =>
      dispatch(getClients({ type: 'personal', ...payload })),
    updateClient: (clientId, userId, data) =>
      dispatch(updateClient({ type: 'personal', clientId, userId, data })),
    deleteClient: id => dispatch(deleteClient({ type: 'personal', id })),
    // getTransactionDetails: payload => dispatch(getTransactionDetails(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PersonalClients));
