import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Drawer,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Button,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import DataTable from '../../../../components/DataTable';
import TableColumns from '../coupon-table';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

const CouponAllTab = ({
  loading,
  data,
  onEditClick,
  onDeleteClick,
  limit,
  total,
  page,
  modalOpened,
  onCloseModal,
  formData,
  onSubmit,
  performDelete,
  onRowSelect,
}) => {
  const formInitialValues = {
    code: '',
    discount_percent: 0,
    short_description: '',
    start_date: '',
    end_date: '',
    enabled: true,
    max_redemptions: 1,
    times_redeemed: '',
  };
  const formValidations = Yup.object().shape({
    code: Yup.string().required('Enter coupen code'),
    discount_percent: Yup.number().required('Enter discount percentage'),
    short_description: Yup.string().required('Enter short description'),
    start_date: Yup.string().required('Select start date'),
    end_date: Yup.string()
      .test('is-greater', 'End date should be greater', function (value) {
        const { start_date } = this.parent;
        return moment(value, 'YYYY-MM-DD').isAfter(
          moment(start_date, 'YYYY-MM-DD')
        );
      })
      .required('Select end date'),
    enabled: Yup.boolean().required('Select value'),
    max_redemptions: Yup.number()
      .typeError('you must specify a number')
      .min(1, 'Min value 1.')
      .max(1000, 'Max value 1000.')
      .required('Enter value here'),
  });
  function disabledDate(current) {
    return current && current < moment().startOf('day');
  }
  return (
    <div>
      <Card>
        <DataTable
          rowKey={record => record.code || 'code'}
          loading={loading}
          data={data}
          columns={TableColumns(onEditClick, onDeleteClick)}
          onClickRow={(e, item) => onRowSelect(item)}
          pagination={{
            limit,
            total,
            page,
            data,
            // onChange: onPageChange,
          }}
        />
      </Card>
      <Drawer
        closable={false}
        placement="right"
        width={450}
        visible={modalOpened === 3}
        onClose={onCloseModal}
        onCancel={onCloseModal}
        destroyOnClose
      >
        <>
          <div className="drawer-header">
            <h3>Coupon Detail</h3>
            <div className="header-actions">
              {/* <EditFilledIcon
            className="action-primary"
            onClick={() => onEditClick(selectedRow)}
          /> */}
              <CloseOutlined onClick={onCloseModal} />
            </div>
          </div>
          <div className="drawer-body">
            <div className="user-info-row">
              <label>Coupon ID</label>
              <span>{formData?.id}</span>
            </div>
            <div className="user-info-row">
              <label>Coupon Code</label>
              <span>{formData?.code}</span>
            </div>
            <div className="user-info-row">
              <label>Description</label>
              <span>{formData?.short_description}</span>
            </div>
            <div className="user-info-row">
              <label>Discount Percentage</label>
              <span>{formData?.discount_percent}</span>
            </div>
            <div className="user-info-row">
              <label>Max Redemptions</label>
              <span>{formData?.max_redemptions}</span>
            </div>
            <div className="user-info-row">
              <label>Time Redemptions</label>
              <span>{formData?.times_redeemed}</span>
            </div>
            <div className="user-info-row">
              <label>Start Date</label>
              <span>{formData?.start_date}</span>
            </div>
            <div className="user-info-row">
              <label>End Date</label>
              <span>{formData?.end_date}</span>
            </div>
          </div>
        </>
      </Drawer>
      <Drawer
        closable={false}
        placement="right"
        width={450}
        visible={modalOpened === 1}
        onClose={onCloseModal}
        onCancel={onCloseModal}
        destroyOnClose
      >
        <>
          <div className="drawer-header">
            <h3>{formData?.code ? 'Edit Coupon' : 'Create New Coupon'}</h3>
          </div>
          <div className="drawer-body">
            <Formik
              initialValues={{
                ...formInitialValues,
                ...formData,
              }}
              validationSchema={formValidations}
              onSubmit={values => {
                onSubmit(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    {/* {formData.code && (
                      <div className="form-group">
                        <label>Coupon Code</label>
                        <span>{formData.code}</span>
                      </div>
                    )} */}
                    <div className="form-group">
                      <label>Code</label>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['code'] && errors['code'] ? 'error' : null
                        }
                        help={touched['code'] && errors['code']}
                        style={{ margin: 0 }}
                      >
                        <Input
                          name="code"
                          value={values?.code ? values.code.toUpperCase() : ''}
                          placeholder="Enter Coupen Code"
                          onChange={e => {
                            setFieldValue(
                              'code',
                              e.target.value.toString().toUpperCase()
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>Discount Percent %</label>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['discount_percent'] &&
                          errors['discount_percent']
                            ? 'error'
                            : null
                        }
                        help={
                          touched['discount_percent'] &&
                          errors['discount_percent']
                        }
                        style={{ margin: 0 }}
                      >
                        <InputNumber
                          defaultValue={0}
                          name="discount_percent"
                          min={0}
                          max={100}
                          value={values.discount_percent}
                          formatter={value => `${value}%`}
                          parser={value => value.replace('%', '')}
                          placeholder="Enter discount here"
                          onChange={value => {
                            setFieldValue('discount_percent', value);
                          }}
                          prefix={'%'}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['short_description'] &&
                          errors['short_description']
                            ? 'error'
                            : null
                        }
                        help={
                          touched['short_description'] &&
                          errors['short_description']
                        }
                        style={{ margin: 0 }}
                      >
                        <Input.TextArea
                          name="short_description"
                          value={values.short_description}
                          placeholder="Enter the description here"
                          onChange={handleChange}
                          autoSize={{ minRows: 5 }}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>Start Date</label>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['start_date'] && errors['start_date']
                            ? 'error'
                            : null
                        }
                        help={touched['start_date'] && errors['start_date']}
                        style={{ margin: 0 }}
                      >
                        <DatePicker
                          name="start_date"
                          placeholder="Start date"
                          disabledDate={disabledDate}
                          onChange={(date, dateString) =>
                            setFieldValue('start_date', dateString)
                          }
                          value={
                            values.start_date
                              ? moment(values.start_date, 'YYYY-MM-DD')
                              : null
                          }
                          style={{ width: '100%', display: 'inline-block' }}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>End Date</label>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['end_date'] && errors['end_date']
                            ? 'error'
                            : null
                        }
                        help={touched['end_date'] && errors['end_date']}
                        style={{ margin: 0 }}
                      >
                        <DatePicker
                          name="end_date"
                          placeholder="End date"
                          disabledDate={disabledDate}
                          onChange={(date, dateString) =>
                            setFieldValue('end_date', dateString)
                          }
                          value={
                            values.end_date
                              ? moment(values.end_date, 'YYYY-MM-DD')
                              : null
                          }
                          style={{ width: '100%', display: 'inline-block' }}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>Max Redemptions</label>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['max_redemptions'] &&
                          errors['max_redemptions']
                            ? 'error'
                            : null
                        }
                        help={
                          touched['max_redemptions'] &&
                          errors['max_redemptions']
                        }
                        style={{ margin: 0 }}
                      >
                        <InputNumber
                          name="max_redemptions"
                          value={
                            values?.max_redemptions
                              ? values.max_redemptions
                              : ''
                          }
                          placeholder="Enter the redemptions here"
                          onChange={value => {
                            setFieldValue('max_redemptions', value);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['enabled'] && errors['enabled']
                            ? 'error'
                            : null
                        }
                        help={touched['enabled'] && errors['enabled']}
                        style={{ margin: 0 }}
                      >
                        <Select
                          style={{ width: '100%' }}
                          value={values.enabled}
                          onChange={value =>
                            handleChange({
                              target: { name: 'enabled', value },
                            })
                          }
                        >
                          <Select.Option value={true}>Active</Select.Option>
                          <Select.Option value={false}>Inactive</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loading}
                    >
                      Save
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </>
      </Drawer>
      <Modal
        className="delete-user-modal"
        visible={modalOpened === 2}
        onCancel={onCloseModal}
        onOk={performDelete}
        okText="Yes"
        destroyOnClose
      >
        {formData && (
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
            <p className="pop-up-body">
              Do you really want to remove this coupon? The process cannot be
              undone.
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};
CouponAllTab.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  limit: PropTypes.string,
  total: PropTypes.string,
  page: PropTypes.string,
  modalOpened: PropTypes.bool,
  onCloseModal: PropTypes.bool,
  formData: PropTypes.array,
  uiState: PropTypes.object,
  onSubmit: PropTypes.func,
  performDelete: PropTypes.func,
  onRowSelect: PropTypes.func,
};

export default CouponAllTab;
