import React from 'react';
import { Spin } from 'antd';
import './style.css';

function Loader() {
  return (
    <div className="loader-container">
      <Spin />
    </div>
  );
}

function renderLoadableComponent(condition, component) {
  if (condition) {
    return <Loader />;
  }
  return component;
}

export { renderLoadableComponent };
export default Loader;
