import React, { useState } from 'react';
import { Tabs, Card } from 'antd';
import CreateNewTab from './CreateNewTab';
import HistoryTab from './HistoryTab';
import './style.css';

const { TabPane } = Tabs;

function PushNotification() {
  const [activeTab, setActiveTab] = useState('tab_one');

  return (
    <div className="order-container">
      <div className="page-header">
        <div className="page-title">
          <h4>Push Notification</h4>
        </div>
      </div>
      <Card className="pushNotification-tab-container tab-container">
        <Tabs
          className="pushNotification-tabs"
          activeKey={activeTab}
          onChange={key => setActiveTab(key)}
        >
          <TabPane key="tab_one" tab="Create New" />
          <TabPane key="tab_two" tab="History" />
        </Tabs>
      </Card>
      {activeTab === 'tab_one' && <CreateNewTab />}
      {activeTab === 'tab_two' && <HistoryTab />}
    </div>
  );
}

export default PushNotification;
