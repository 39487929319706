import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableSearch from '../../components/TableSearch';
import AllJobs from './components/all';
import { getJobsFeedback } from './reducers';
import JobDetailModal from './components/jobDetailModal';
import { withRouter } from 'react-router-dom';

import './styles.css';

class JobsFeedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      modalOpened: false,
      query: '',
      isJobDetailsModalOpen: false,
    };
  }

  componentDidUpdate() {
    // const { error } = this.props;
  }

  onSearch = () => {
    const { getJobsFeedback } = this.props;
    const { query } = this.state;
    const { limit } = getJobsFeedback;

    getJobsFeedback({ query, per: limit, page: 1 });
  };

  jobDetailsModalOpen = () => {
    this.setState(state => ({
      ...state,
      isJobDetailsModalOpen: true,
    }));
  };

  jobDetailsModalClose = () => {
    this.setState(state => ({
      ...state,
      isJobDetailsModalOpen: false,
    }));
  };

  render() {
    const { query } = this.state;
    return (
      <div className="jobs-page">
        <div className="page-header">
          <Row>
            <Col>
              <div className="page-title">
                <h4>Jobs Feedback</h4>
              </div>
            </Col>
          </Row>
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search Job"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={query}
            onInputChange={ev =>
              this.setState({ query: ev['target']['value'] })
            }
          />
          <AllJobs
            queryString={query}
            jobDetailsModalOpen={this.jobDetailsModalOpen}
          />
        </div>
        {this.state.isJobDetailsModalOpen && (
          <JobDetailModal jobDetailsModalClose={this.jobDetailsModalClose} />
        )}
      </div>
    );
  }
}

JobsFeedback.propTypes = {
  getJobsFeedback: PropTypes.func,
  jobsfeedback: PropTypes.object,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    jobsfeedback: state.jobsFeedback.jobsfeedback,
    error: state.jobsFeedback.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJobsFeedback: payload => dispatch(getJobsFeedback(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobsFeedback));
