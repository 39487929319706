import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_TAGS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};
const slice = createSlice({
  name: 'quoterequest',
  initialState: {
    loading: false,
    quoteRequests: DEFAULT_TAGS,
    error: '',
    errors: {},
  },
  reducers: {
    getQuoteRequest: state => {
      state.loading = true;
    },
    getQuoteRequestSuccess: (state, { payload }) => {
      state.loading = false;
      state.quoteRequests = {
        data: payload.quote_requests,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getQuoteRequestFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    updateQuoteRequest: state => {
      state.message = message.loading('Updating quote request status...', 0);
    },
    updateQuoteRequestSuccess: (state, { payload }) => {
      const { response } = payload;
      const { quote_request } = response;
      state.message();
      state.errors = {};
      let idx = state.quoteRequests.data.findIndex(
        el => el.id === quote_request.id
      );
      if (idx >= 0) {
        state.quoteRequests.data[idx] = {
          ...state.quoteRequests.data[idx],
          ...quote_request,
        };
      }
      notification.success({
        message: 'Quote Request Updated',
        description: `Quote Request has been updated.`,
      });
    },
    updateQuoteRequestFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
      if (payload && payload.length > 0) {
        state.errors = payload[0].meta.errors;
      }
    },
  },
});

export const {
  getQuoteRequest,
  getQuoteRequestFailed,
  getQuoteRequestSuccess,
  updateQuoteRequest,
  updateQuoteRequestSuccess,
  updateQuoteRequestFailed,
} = slice.actions;
export default slice.reducer;
