import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Marker } from 'react-leaflet';
import L from 'leaflet';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import icon from '../../../../../assets/icons/ic_pin_job.svg';

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [24, 0],
});

function JobMarker(props) {
  return (
    <Marker
      key={props.jobId}
      position={[props.lat, props.lng]}
      icon={DefaultIcon}
    >
      <Tooltip direction="top">
        <div className="map_pin_job-tooltip_container">
          <div className="map_pin_job-tooltip_header">
            <h3
              title={props.job_title}
              className="map_pin_job-tooltip_header_title truncate"
            >
              {props.job_title}
            </h3>
            <p className="map_pin_job_time">{props.job_assigned_time}</p>
          </div>
          <div className="map_pin_job_duration_container">
            <p className="map_pin_job_duration_title">{props.job_type}</p>
            <div className="dot" />
            <p className="map_pin_job_duration_text">
              {props.job_duration} Mins
            </p>
          </div>
          <div className="map_pin_job_user_info_container">
            <div style={{ alignSelf: 'center' }}>
              <Avatar
                size={32}
                src={props.client_avatar}
                icon={<UserOutlined />}
              />
            </div>
            <div className="map_pin_job_user_info">
              <h3
                title={props.client_name}
                className="map_pin_job_user_info_name truncate"
              >
                {props.client_name}
              </h3>
              <p
                title={props.client_address}
                className="map_pin_job_user_info_addr truncate"
              >
                {props.client_address}
              </p>
              <p className="map_pin_job_user_info_tag">
                @{props.client_building}
              </p>
            </div>
          </div>
        </div>
      </Tooltip>
    </Marker>
  );
}

JobMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  job_title: PropTypes.string,
  job_type: PropTypes.string,
  job_duration: PropTypes.number,
  job_assigned_time: PropTypes.string,
  client_avatar: PropTypes.string,
  client_name: PropTypes.string,
  client_address: PropTypes.string,
  client_building: PropTypes.string,
  jobId: PropTypes.number,
};

export default JobMarker;
