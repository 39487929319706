import React from 'react';
import { CloseOutlined, DeleteFilled, UserOutlined } from '@ant-design/icons';
import { Button, Input, Form } from 'antd';
import { dig } from 'digdata';
import PropTypes from 'prop-types';
import AvatarUpload from '../../../components/AvatarUpload';
import { getImageURI } from '../../../utils/apisauce';
import { Formik } from 'formik';
// import { formInitialValues } from '../../../utils/formsInitialValues';
// import { formValidations } from '../../../utils/formValidations';

export default function CreateVendor(props) {
  const {
    formData,
    onDelete,
    closeModal,
    onInputChange,
    onSubmit,
    loading,
    disabled,
    handleChange,
  } = props;

  return (
    <>
      <div className="drawer-header">
        <h3>{formData.id ? 'Edit Property' : 'Add Property'}</h3>
        <div className="header-actions">
          {formData.id && (
            <DeleteFilled
              className="action-primary"
              onClick={() => onDelete(formData)}
            />
          )}
          <CloseOutlined onClick={closeModal} />
        </div>
      </div>
      <div className="drawer-body">
        <Formik
          initialValues={{
            ...formData,
          }}
          //   validationSchema={formValidations['CreateVendorFormValidation']}
          onSubmit={values => {
            onSubmit(values);
            closeModal();
          }}
        >
          {({ errors, touched, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="user-avatar">
                  <AvatarUpload
                    value={
                      formData['image']
                        ? getImageURI(dig(formData, 'image.url'))
                        : null
                    }
                    icon={<UserOutlined />}
                    onChange={value =>
                      onInputChange({
                        target: {
                          name: 'image',
                          value: { data: value },
                        },
                      })
                    }
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                {formData.id && (
                  <div className="form-group">
                    <label>Property ID</label>
                    <span>{formData.id}</span>
                  </div>
                )}
                <div className="form-group">
                  <label>Property Name</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['title'] && errors['title'] ? 'error' : null
                    }
                    help={touched['title'] && errors['title']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="title"
                      value={formData.title}
                      placeholder="Enter Property Name"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                {/* <div className="form-group">
                  <label>Type</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['category_type'] && errors['category_type']
                        ? 'error'
                        : null
                    }
                    help={touched['category_type'] && errors['category_type']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      mode="multiple"
                      value={formData.property_type}
                      onChange={value =>
                        handleChange({
                          target: { name: 'property_type', value },
                        })
                      }
                    >
                      <Select.Option value="service">Service</Select.Option>
                      <Select.Option value="subscription">
                        Subscription
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div> */}
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={disabled}
                >
                  Save
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

CreateVendor.propTypes = {
  formData: PropTypes.object,
  onDelete: PropTypes.func,
  closeModal: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};
