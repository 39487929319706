import { CloseOutlined, DeleteFilled } from '@ant-design/icons';
import { Button, Input, Select, Form } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import DraggerUpload from '../../../components/DragDropImageUpload';
import { getImageURI } from '../../../utils/apisauce';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';

function CategoryForm({
  formData,
  onSubmit,
  onInputChange,
  onDeleteClick,
  onCloseModal,
  loading,
  disabled,
}) {
  return (
    <>
      <div className="drawer-header">
        <h3>{formData.id ? 'Edit Category' : 'Create New Category'}</h3>
        <div className="header-actions">
          {formData.id && (
            <DeleteFilled
              className="action-primary"
              onClick={() => onDeleteClick(formData)}
            />
          )}
          <CloseOutlined onClick={onCloseModal} />
        </div>
      </div>
      <div className="drawer-body">
        <Formik
          initialValues={{
            ...formInitialValues['createCategoryForm'],
            ...formData,
          }}
          validationSchema={formValidations['CreateCategoryFormValidation']}
          onSubmit={values => onSubmit(values)}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                {formData.id && (
                  <div className="form-group">
                    <label>Categories ID</label>
                    <span>{formData.id}</span>
                  </div>
                )}
                <DraggerUpload
                  value={
                    formData['image']
                      ? getImageURI(formData['image'].url)
                      : null
                  }
                  onChange={value =>
                    onInputChange({
                      target: { name: 'images', value: { data: value } },
                    })
                  }
                  accept=".png"
                  aspectRatio={1 / 1}
                  message="Click or drag png file to this area to upload"
                />
                <div className="form-group">
                  <label>Name</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['title'] && errors['title'] ? 'error' : null
                    }
                    help={touched['title'] && errors['title']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="title"
                      value={values.title}
                      placeholder="Enter Category Name"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['description'] && errors['description']
                        ? 'error'
                        : null
                    }
                    help={touched['description'] && errors['description']}
                    style={{ margin: 0 }}
                  >
                    <Input.TextArea
                      name="description"
                      value={values.description}
                      placeholder="Enter the description here"
                      onChange={handleChange}
                      autoSize={{ minRows: 5 }}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Type</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['category_type'] && errors['category_type']
                        ? 'error'
                        : null
                    }
                    help={touched['category_type'] && errors['category_type']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      value={values.category_type}
                      onChange={value =>
                        handleChange({
                          target: { name: 'category_type', value },
                        })
                      }
                    >
                      <Select.Option value="product">Product</Select.Option>
                      <Select.Option value="service">Service</Select.Option>
                      <Select.Option value="classes">Classes</Select.Option>
                      <Select.Option value="subscription">
                        Subscription
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['status'] && errors['status'] ? 'error' : null
                    }
                    help={touched['status'] && errors['status']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      value={values.status}
                      onChange={value =>
                        handleChange({
                          target: { name: 'status', value },
                        })
                      }
                    >
                      <Select.Option value="active">Active</Select.Option>
                      <Select.Option value="inactive">Inactive</Select.Option>
                      <Select.Option value="suspended">Suspended</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={disabled}
                >
                  Save
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

CategoryForm.propTypes = {
  formData: PropTypes.object,
  formErrors: PropTypes.object,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onCloseModal: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CategoryForm;
