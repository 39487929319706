import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_CATEGORIES = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

export const initialUiState = {
  modalOpened: 0,
  selectedRow: null,
  formData: null,
  formErrors: {},
};
const slice = createSlice({
  name: 'categories',
  initialState: {
    loading: false,
    submitting: false,
    categories: DEFAULT_CATEGORIES,
    error: null,
    errors: {},
    uiState: initialUiState,
  },
  reducers: {
    getCategories: state => {
      state.loading = true;
    },
    getCategoriesSuccess: (state, { payload }) => {
      state.loading = false;
      state.categories = {
        data: payload.categories,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getCategoriesFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    createCategory: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Creating a new category ...', 0);
    },
    createCategorySuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      state.formOpen = false;
      state.errors = {};
      const category = payload.category;
      state.categories.data = [category, ...state.categories.data];

      state.categories.total += 1;
      state.uiState.formData = null;
      state.uiState.modalOpened = false;
      state.uiState.formErrors = {};
      notification.success({
        message: 'New Category',
        description: `New Category ${category.title} has been created.`,
      });
    },
    createCategoryFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
      if (payload && payload.length > 0) {
        state.uiState.formErrors = payload[0].meta.errors;
      }
    },
    updateCategory: state => {
      state.submitting = true;
      state.message = message.loading('Updating category info...', 0);
    },
    updateCategorySuccess: (state, { payload }) => {
      state.message();
      state.formOpen = false;
      state.submitting = false;
      state.uiState.formErrors = {};

      const category = payload.category;
      const idx = state.categories.data.findIndex(el => el.id === category.id);
      if (idx >= 0) {
        state.categories.data[idx] = {
          ...state.categories.data[idx],
          ...category,
        };
      }
      state.uiState.formData = null;
      state.uiState.modalOpened = false;
      notification.success({
        message: 'Category Updated',
        description: `Category ${category.title} has been updated.`,
      });
    },
    updateCategoryFailed: (state, { payload }) => {
      state.message();
      state.submitting = true;
      apiError(payload);
      if (payload && payload.length > 0) {
        state.uiState.formErrors = payload[0].meta.errors;
      }
    },
    deleteCategory: state => {
      state.message = message.loading('Deleting Category ...', 0);
    },
    deleteCategorySuccess: (state, { payload }) => {
      state.message();
      const category = payload.category;
      state.categories.data = state.categories.data.filter(
        x => x.id !== category.id
      );
      notification.success({
        message: 'Category Deleted',
        description: `Category ${category.title} has been deleted.`,
      });
      state.uiState.formData = null;
      state.uiState.selectedRow = null;
      state.uiState.modalOpened = false;
      state.categories.total -= 1;
      if (state.categories.total < 0) {
        state.categories.total = 0;
      }
    },
    deleteCategoryFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    updateUI: (state, { payload }) => {
      state.uiState = { ...payload };
    },
  },
});

export const {
  getCategories,
  getCategoriesFailed,
  getCategoriesSuccess,
  createCategory,
  createCategorySuccess,
  createCategoryFailed,
  updateCategory,
  updateCategorySuccess,
  updateCategoryFailed,
  deleteCategory,
  deleteCategoryFailed,
  deleteCategorySuccess,
  updateUI,
} = slice.actions;
export default slice.reducer;
