import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import { renderLoadableComponent } from '../../../../components/Loader';

function StatsCard(props) {
  const { num, title, /*  icon, */ loading } = props;
  return (
    <Card className="stats-card">
      {renderLoadableComponent(
        loading,
        <Fragment>
          <div className="stats-card-data">
            <h3 className="stats-card-num">{num}</h3>
            <p className="stats-card-data-title">{title}</p>
          </div>
          {/* <div className="stats-card-icon-container">
            <img src={icon} alt="icon" className="stats-card-icon" />
          </div> */}
        </Fragment>
      )}
    </Card>
  );
}

StatsCard.propTypes = {
  num: PropTypes.number,
  title: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
};

export default StatsCard;
