import React from 'react';
import { Avatar, Tooltip, Button } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import {
  InfoCircleOutlinedIcon,
  SendOutlinedIcon,
  CheckCircleOutlinedIcon,
} from '../../components/Icons/Icons';
import { capitalize } from '../../utils/apisauce';
import { titleize, orderStatus } from '../../utils/helpers';

const disableShipAction = (status, disabled = false) =>
  disabled
    ? status === orderStatus.SHIPPED ||
      status === orderStatus.DELIVERED ||
      status === orderStatus.CANCEL_REQUEST_BY_VENDOR ||
      status === orderStatus.IN_CART
    : status !== orderStatus.SHIPPED &&
      status !== orderStatus.DELIVERED &&
      status !== orderStatus.CANCEL_REQUEST_BY_VENDOR &&
      status !== orderStatus.IN_CART;

const disableCancelAction = (status, isVendor = false) =>
  status === orderStatus.SHIPPED ||
  status === orderStatus.DELIVERED ||
  status === orderStatus.IN_CART ||
  (isVendor && status === orderStatus.CANCEL_REQUEST_BY_VENDOR);

const ColumnLink = ({ onClick, text }) =>
  typeof text === 'string' && text?.toLowerCase() === 'detail' ? (
    <Tooltip title="detail">
      <InfoCircleOutlinedIcon onClick={e => e.stopPropagation() || onClick()} />
    </Tooltip>
  ) : (
    <Button type="link" onClick={onClick}>
      {text}
    </Button>
  );

ColumnLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

const orderTableColumns = (
  setModalOpen,
  activeTab,
  showjobDetailModal,
  showOrderItemsModal,
  showClientAddress,
  showOrderTransactionsModal,
  // orderShipped,
  orderDelivered,
  setTrackingNumberId,
  setTrackingIdModalOpen,
  isVendor,
  cancelOrder,
  handleOrderDetails
) => {
  return [
    {
      title: <span className="table-column-title">ID</span>,
      key: 'id',
      wdith: activeTab === 'admin' ? '10%' : 'auto',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      fixed: activeTab === 'admin' || activeTab === 'topup' ? false : 'left',

      sorter: (a, b) => a.id - b.id,
      render: (text, record) => {
        return (
          <ColumnLink
            onClick={
              activeTab === 'subscription'
                ? () => handleOrderDetails(record)
                : () => {}
            }
            text={record['id']}
          />
        );
      },
    },
    {
      title: <span className="table-column-title">Created Date</span>,
      key: 'created_at',
      wdith: activeTab === 'admin' ? '18%' : 'auto',
      dataIndex: 'created_at',
      fixed: activeTab === 'admin' || activeTab === 'topup' ? false : 'left',

      render: (text, record) =>
        record['created_at'] ? (
          <Tooltip
            title={`${new Date(record['created_at']).toLocaleString()}`}
            placement="top"
          >
            {capitalize(
              `${new Date(record['created_at']).toLocaleString()}`
            ).substring(0, 10)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>...</span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: <span className="table-column-title">Created By</span>,
      key: 'created_by',
      wdith: activeTab === 'admin' ? '18%' : 'auto',
      responsive: activeTab === 'admin' ? ['sm'] : [],
      render: (text, record) =>
        record['buyer']
          ? capitalize(
              record['buyer'].first_name + ' ' + record['buyer'].last_name
            )
          : '-',
    },
    {
      title: <span className="table-column-title">Client Name</span>,
      key: 'client_name_',
      wdith: activeTab === 'admin' ? '18%' : 'auto',
      responsive: activeTab === 'admin' ? ['sm'] : [],
      fixed: activeTab === 'admin' || activeTab === 'topup' ? false : 'left',
      render: (text, record) =>
        record['account']
          ? capitalize(record['account'].accountable.full_name)
          : '-',
    },

    {
      title: <span className="table-column-title">Client Name</span>,
      key: 'clientName',

      responsive: activeTab === 'admin' ? [] : ['sm'],
      fixed: activeTab === 'admin' || activeTab === 'topup' ? false : 'left',
      render: (text, record) => {
        return (
          <a href="#/" onClick={() => showClientAddress(record)}>
            {record['order_type']?.toLowerCase() === 'admin'
              ? capitalize(record['account'].accountable.full_name)
              : record['buyer']
              ? `${capitalize(record['buyer']['first_name'])} ${capitalize(
                  record['buyer']['last_name']
                )}`
              : '-'}
          </a>
        );
      },
    },
    {
      title: <span className="table-column-title">Delivery Address</span>,
      key: 'order_items',
      responsive: activeTab === 'topup' || activeTab === 'admin' ? [] : ['sm'],

      render: (text, record) =>
        record['address_book'] === null
          ? '-'
          : record['address_book']['address'],
    },
    {
      title: <span className="table-column-title">Service Date</span>,
      key: 'service_date',
      render: (text, record) =>
        record['service_date'] === null ? '-' : record['service_date'],
    },
    {
      title: <span className="table-column-title">Preferred Time</span>,
      key: 'preferred_time',
      render: (text, record) =>
        record['preferred_time'] === null ? '-' : record['preferred_time'],
    },
    {
      title: <span className="table-column-title">Placement ID </span>,
      key: 'p_id',

      responsive: activeTab === 'admin' ? [] : ['sm'],
      render: (text, record) =>
        record['placement'] ? `${record['placement'].id}` : '-',
    },
    {
      title: <span className="table-column-title">Placement Date </span>,
      key: 'p_date',

      responsive: activeTab === 'admin' ? [] : ['sm'],
      render: (text, record) =>
        record['placement']
          ? `${new Date(record['placement'].created_at).toLocaleString()}`
          : '-',
    },
    {
      title: <span className="table-column-title">Start Date</span>,
      key: 'start_subscription_at',

      responsive: activeTab === 'subscription' ? ['sm'] : [],
      render: (text, record) =>
        new Date(record['start_subscription_at']).toLocaleString(),
    },
    {
      title: <span className="table-column-title">Title</span>,
      key: 'title',

      responsive: activeTab === 'concierge' ? ['sm'] : [],
      dataIndex: 'title',
      render: (text, record) => capitalize(record['title']) || '-',
    },
    {
      title: <span className="table-column-title">Description</span>,
      key: 'description',
      dataIndex: 'discription',
      responsive: activeTab === 'concierge' ? ['sm'] : [],
      render: (text, record) =>
        record['description'] ? (
          <Tooltip placement="topLeft" title={record['description']}>
            {capitalize(record['description']).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: <span className="table-column-title">Order Type</span>,
      key: 'order_type',
      // fixed: activeTab === 'admin' || activeTab === 'topup' ? false : 'left',
      responsive: activeTab === 'admin' ? [] : ['sm'],
      render: (text, record) => capitalize(record['order_type']),
    },
    {
      title: <span className="table-column-title">Client Type</span>,
      key: 'buyer_type',

      responsive: activeTab === 'admin' ? [] : ['sm'],
      render: (text, record) => capitalize(record['buyer_type']),
    },

    {
      title: <span className="table-column-title">Job ID</span>,
      key: 'jobId',

      responsive:
        activeTab === 'topup' ||
        activeTab === 'concierge' ||
        activeTab === 'product' ||
        activeTab === 'admin'
          ? []
          : ['sm'],
      render: function jobDetail(text, record) {
        return (
          <ColumnLink
            onClick={() => showjobDetailModal(record, activeTab)}
            text={
              record['job_id'] ? record['job_id'] : record['repeating_job_id']
            }
          />
        );
      },
    },
    {
      title: <span className="table-column-title">Amount</span>,
      key: 'amount',
      fixed: activeTab === 'topup' ? false : 'right',
      responsive: activeTab === 'admin' ? [] : ['sm'],
      render: (text, record) => (
        <Tooltip
          title={`${record?.amount > 0 ? 'debit' : 'credit'}`}
          placement="top"
          className={`${record?.amount > 0 ? 'debit' : 'credit'}`}
        >
          {record['formatted_amount']
            ? `${
                record?.amount > 0
                  ? `${capitalize(record['formatted_amount'])}`
                  : `(${capitalize(
                      record['formatted_amount'].replace('-', '')
                    )})`
              }`
            : '-'}
        </Tooltip>
      ),
    },
    {
      title: <span className="table-column-title">Adjustment Amount</span>,
      key: 'formatted_adjustment_amount',
      wdith: activeTab === 'admin' ? '18%' : 'auto',
      responsive: activeTab === 'admin' ? ['sm'] : [],
      render: (text, record) => record['formatted_adjustment_amount'],
    },
    {
      title: (
        <span className="table-column-title">
          <Tooltip title="GST Amount">GST</Tooltip>
        </span>
      ),
      key: 'tax_amount',
      responsive: activeTab === 'topup' || activeTab === 'admin' ? [] : ['sm'],
      fixed: 'right',
      render: (text, record) => record['formatted_tax_amount'],
    },
    {
      title: (
        <span className="table-column-title">
          <Tooltip title="Net Amount">Net</Tooltip>
        </span>
      ),
      key: 'net_amount',
      responsive: activeTab === 'topup' || activeTab === 'admin' ? [] : ['sm'],
      fixed: 'right',
      render: (text, record) => record['formatted_net_amount'],
    },

    {
      title: <span className="table-column-title">Status</span>,
      key: 'orderStatus',
      fixed: activeTab === 'topup' || activeTab === 'admin' ? false : 'right',
      wdith: activeTab === 'admin' ? '18%' : 'auto',
      render: (text, record) =>
        activeTab === 'admin'
          ? capitalize(record['status'])
          : capitalize(record['display_status']),
    },
    {
      title: <span className="table-column-title">Item(s)</span>,
      key: 'order_items',
      fixed: 'right',
      responsive: activeTab === 'topup' || activeTab === 'admin' ? [] : ['sm'],

      render: function orderItems(text, record) {
        return (
          <ColumnLink
            onClick={() => showOrderItemsModal(record, activeTab)}
            text={
              activeTab === 'concierge'
                ? record['concierge_order_items'].length
                : record['order_items'].length
            }
          />
        );
      },
    },
    {
      title: (
        <span className="table-column-title">
          <Tooltip title="Actions">Action(s)</Tooltip>
        </span>
      ),
      key: 'order_transactions',
      fixed: 'right',
      align: 'center',
      responsive: activeTab === 'topup' || activeTab === 'admin' ? [] : ['sm'],

      render: function orderTransactions(text, record) {
        return (
          <div
            className="ant-table-action-cell"
            style={{ justifyContent: 'center' }}
          >
            <ColumnLink
              href="#/"
              onClick={() => showOrderTransactionsModal(record, activeTab)}
              text={'Detail'}
            />
            {record['order_type']
              ? record['order_type'] === 'product' &&
                activeTab === 'product' && (
                  <>
                    {record?.status === orderStatus.IN_CART ||
                    record?.status === orderStatus.ACTIVE ||
                    record?.status === orderStatus.CANCEL_REQUEST_BY_VENDOR ? (
                      <Tooltip
                        title={
                          record?.status === orderStatus.SHIPPED ||
                          record?.status === orderStatus.DELIVERED
                            ? 'Shipped'
                            : 'Ship'
                        }
                      >
                        <SendOutlinedIcon
                          onClick={e => {
                            e.stopPropagation();
                            if (disableShipAction(record?.status)) {
                              setTrackingIdModalOpen(true);
                              setTrackingNumberId(
                                record['id'] ? record['id'] : null
                              );
                            }
                          }}
                          color={
                            record?.status === orderStatus.SHIPPED ||
                            record?.status === orderStatus.DELIVERED
                              ? '#48BB53'
                              : null
                          }
                          disabled={disableShipAction(record?.status, true)}
                        />
                      </Tooltip>
                    ) : record?.status === orderStatus.SHIPPED ||
                      record?.status === orderStatus.DELIVERED ? (
                      <Tooltip
                        title={
                          record?.status === orderStatus.DELIVERED
                            ? 'Delivered'
                            : 'Deliver'
                        }
                      >
                        <CheckCircleOutlinedIcon
                          onClick={e => {
                            e.stopPropagation();
                            if (record?.status !== orderStatus.DELIVERED) {
                              orderDelivered(record.id);
                            }
                          }}
                          color={
                            record?.status === orderStatus.DELIVERED
                              ? '#48BB53'
                              : '#2d9cdb'
                          }
                          disabled={record?.status === orderStatus.DELIVERED}
                        />
                      </Tooltip>
                    ) : null}
                  </>
                )
              : null}
            {(activeTab === 'product' ||
              activeTab === 'classes' ||
              activeTab === 'service' ||
              activeTab === 'subscription') &&
              record?.status !== 'canceled' && (
                <CloseOutlined
                  style={{
                    opacity: disableCancelAction(record?.status, isVendor)
                      ? '0.5'
                      : '1',
                    cursor: disableCancelAction(record?.status, isVendor)
                      ? 'not-allowed'
                      : 'pointer',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    if (isVendor) {
                      record?.status === orderStatus.ACTIVE &&
                        cancelOrder(record);
                    } else if (
                      record?.status !== orderStatus.SHIPPED &&
                      record?.status !== orderStatus.DELIVERED &&
                      record?.status !== orderStatus.IN_CART
                    )
                      cancelOrder(record);
                  }}
                />
              )}
          </div>
        );
      },
    },
  ];
};

const orderItemsTableColumns = () => [
  {
    title: <span className="table-column-title">ID</span>,
    key: 'id',
    render: (text, record) => record['item']['id'],
  },
  {
    title: <span className="table-column-title">Image</span>,
    key: 'image',
    render: function itemImage(text, record) {
      return (
        <Avatar
          size={56}
          src={record['item']['image']}
          icon={<UserOutlined />}
        />
      );
    },
  },
  {
    title: <span className="table-column-title">Item Name</span>,
    key: 'title',
    render: (text, record) => capitalize(record['item']['title']),
  },
  {
    title: <span className="table-column-title">Description</span>,
    key: 'description',

    render: (text, record) =>
      record['item']['description'] ? (
        <Tooltip placement="topLeft" title={record['item']['description']}>
          {capitalize(record['item']['description']).substring(0, 20)}
          <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
            ...Read more
          </span>
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    title: <span className="table-column-title">Unit</span>,
    key: 'unit',
    render: (text, record) => record['item']['unit'],
  },
  {
    title: <span className="table-column-title">Quantity</span>,
    key: 'quantity',
    dataIndex: 'quantity',
    render: (text, record) => record['quantity'],
  },
  {
    title: <span className="table-column-title">Amount Rate</span>,
    key: 'amount',
    render: (text, record) => record['item']['formatted_amount'],
  },
];

const orderTransactionsTableColumns = () => [
  {
    title: <span className="table-column-title">Order Transaction ID</span>,
    key: 'trx_id',
    render: (text, record) => record['trx_id'],
  },
  {
    title: <span className="table-column-title">Transaction Date</span>,
    key: 'created_at',
    render: (text, record) => new Date(record['created_at']).toLocaleString(),
  },
  {
    title: <span className="table-column-title">Title</span>,
    key: 'title',
    render: (text, record) => capitalize(record['title']),
  },
  {
    title: <span className="table-column-title">Transaction Type</span>,
    key: 'transaction_type',
    render: (text, record) => titleize(record['transaction_type']),
  },
  {
    title: <span className="table-column-title">Amount</span>,
    key: 'formatted_amount',
    render: (text, record) => record['formatted_amount'],
  },
  {
    title: <span className="table-column-title">Status</span>,
    key: 'status',
    render: (text, record) => capitalize(record['status']),
  },
];

const conciergeorderItemsTableColumns = () => [
  {
    title: <span className="table-column-title">ID</span>,
    key: 'id',
    render: (text, record) => record['id'],
  },
  {
    title: <span className="table-column-title">Title</span>,
    key: 'title',
    render: (text, record) => capitalize(record['title']),
  },
  {
    title: <span className="table-column-title">Description</span>,
    key: 'description',
    render: (text, record) => record['description'],
  },
  {
    title: <span className="table-column-title">Job Created? </span>,
    key: 'is_create_job',
    render: (text, record) => (record['is_create_job'] ? 'Yes' : 'No'),
  },
  {
    title: <span className="table-column-title">Job Notes </span>,
    key: 'job_notes',
    render: (text, record) => record['job_notes'],
  },
  {
    title: <span className="table-column-title"> Preferred Time</span>,
    key: 'preferred_time',
    render: (text, record) =>
      record['preferred_time'] ? record['preferred_time'] : '-',
  },
  {
    title: <span className="table-column-title">Service Date</span>,
    key: 'service_date',
    render: (text, record) =>
      record.service_date
        ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '-',
  },
  {
    title: <span className="table-column-title">Unit</span>,
    key: 'unit',
    render: (text, record) => record['unit'],
  },
  {
    title: <span className="table-column-title">Amount</span>,
    key: 'amount',
    render: (text, record) => (
      <Tooltip
        title={`${record?.amount > 0 ? 'debit' : 'credit'}`}
        placement="top"
        className={`${record?.amount > 0 ? 'debit' : 'credit'}`}
      >
        {record['formatted_amount']
          ? `${
              record?.amount > 0
                ? `${capitalize(record['formatted_amount'])}`
                : `(${capitalize(record['formatted_amount'].replace('-', ''))})`
            }`
          : '-'}
      </Tooltip>
    ),
  },
];

const statusLabels = {
  pending_schedule: {
    color: '#F1B13B',
    label: 'Unassigned',
  },
  scheduled: {
    color: '#34A6BD',
    label: 'In Scheduled',
  },
  in_progress: {
    color: '#6ABB98',
    label: 'In Progress',
  },
  completed: {
    color: '#828282',
    label: 'Completed',
  },
  cancelled: {
    color: '#DF5E67',
    label: 'Cancelled/Skipped',
  },
};

const getDayStyle = (record, day) => {
  const style = {
    padding: '0 2px',
    fontWeight: 500,
  };

  if (record.days.includes(day)) {
    return {
      ...style,
      color: '#2D9CDB',
    };
  }

  return style;
};

const jobDetailRepeatingTableColums = () => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Status',
    key: 'status',
    fixed: 'left',
    width: 120,
    render: function render(text, record) {
      if (record.status === 'active') {
        if (record.canceled_at) {
          return 'cancelling';
        } else {
          return record.status;
        }
      } else {
        return record.status;
      }
    },
  },
  {
    title: 'Job Title',
    key: 'title',
    width: 140,
    render: (_, record) =>
      record.job_details ? capitalize(record.job_details.title) : '-',
  },
  {
    title: 'Created By',
    key: 'created_by',
    width: 100,
    render: (_, record) =>
      record?.job_details?.creator_type ? record?.creator_type : 'Order System',
  },
  {
    title: 'Subscription',
    key: 'subscription',
    width: 100,
    render: (_, record) => {
      if (!record?.job_details?.creator_type) {
        return <span style={{ color: '#059c5a' }}>Yes</span>;
      } else {
        return <span style={{ color: '#DF5E67' }}>No</span>;
      }
    },
  },
  {
    title: 'Client Name',
    key: 'client_name',

    render: (_, record) =>
      record.job_details && record.job_details.client
        ? record.job_details.client_type === 'PersonalClient'
          ? capitalize(record.job_details.client.title)
          : capitalize(record.job_details.client.business_name)
        : '',
  },
  {
    title: 'Client Address',
    key: 'client_address',

    render: (_, record) =>
      record.job_details && record.job_details.address ? (
        <Tooltip
          title={`${record.job_details.address.address_title}, ${record.job_details.address.address}, ${record.job_details.address.unit_number}, ${record.job_details.address.postal_code}`}
          placement="top"
        >
          {capitalize(
            `${record.job_details.address.address_title}, ${record.job_details.address.address}, ${record.job_details.address.unit_number}, ${record.job_details.address.postal_code}`
          ).substring(0, 20)}
          <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
            ...Read more
          </span>
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    title: 'Vender Name',
    key: 'client_address',

    render: (_, record) =>
      record.job_details && record.job_details.vendor
        ? `${capitalize(record.job_details.vendor.vendor_name)}`
        : '-',
  },
  {
    title: 'Start Date',
    key: 'start_date',
    dataIndex: 'start_date',
    width: 120,
    render: (_, record) =>
      moment(record.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
  },
  {
    title: 'End Date',
    key: 'end_date',
    width: 120,
    render: (_, record) =>
      record.end_date
        ? moment(record.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '-',
  },
  {
    title: 'Days Limit',
    key: 'days_quantity_limit',
    width: 120,
    render: (_, record) =>
      `${record.days_quantity_limit ? record.days_quantity_limit : '-'}`,
  },
  {
    title: '# of Jobs',
    key: 'jobs_count',
    width: 120,
    render: (_, record) => `${record.jobs_count ? record.jobs_count : '-'}`,
  },
  {
    title: 'Term',
    key: 'term',
    width: 120,
    render: (_, record) =>
      `${record.term_value ? record.term_value : '-'} ${
        record.term_type ? record.term_type : ''
      }`,
  },
  {
    title: 'Frequency',
    key: 'frequency',
    width: 120,
    render: (_, record) => `Every ${record.frequency_value} week`,
  },
  {
    title: 'Selected Days',
    key: 'days',
    fixed: 'right',
    width: 120,
    render: (_, record) => (
      <>
        <span style={getDayStyle(record, 'sunday')}>S</span>
        <span style={getDayStyle(record, 'monday')}>M</span>
        <span style={getDayStyle(record, 'tuesday')}>T</span>
        <span style={getDayStyle(record, 'wednesday')}>W</span>
        <span style={getDayStyle(record, 'thursday')}>T</span>
        <span style={getDayStyle(record, 'friday')}>F</span>
        <span style={getDayStyle(record, 'saturday')}>S</span>
      </>
    ),
  },
];

const jobDetailSingleTableColums = () => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Status',
    key: 'job_status',
    fixed: 'left',
    width: 70,
    render: (_, record) =>
      record?.job_status ? (
        <span style={{ color: statusLabels[record.job_status].color }}>
          {statusLabels[record.job_status].label}
        </span>
      ) : (
        '-'
      ),
  },
  {
    title: 'Service Date',
    key: 'service_date',
    dataIndex: 'service_date',
    defaultSortOrder: 'descend',
    width: 120,
    sorter: (a, b) => {
      const dateA = new Date(a.service_date).getTime();
      const dateB = new Date(b.service_date).getTime();
      return dateA - dateB;
    },
    render: (_, record) =>
      record?.service_date
        ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '-',
  },
  {
    title: 'Order ID',
    key: 'order_id',
    width: 140,
    render: (_, record) => (record?.order ? record?.order?.id : '-'),
  },
  {
    title: 'Order Amount',
    key: 'order_amount',
    width: 140,
    render: (_, record) => (record?.order ? record?.order?.amount : '-'),
  },
  {
    title: 'Created By',
    key: 'created_by',
    width: 140,
    render: (_, record) =>
      record?.creator_type ? capitalize(record?.creator_type) : 'Order System',
  },
  {
    title: 'Job Title',
    key: 'title',
    width: 120,
    render: (_, record) => (record?.title ? capitalize(record.title) : '-'),
  },
  {
    title: 'Client Name',
    key: 'client_name',
    width: 180,
    render: (_, record) =>
      record?.client_type === 'PersonalClient'
        ? capitalize(record.client?.title)
        : record?.client?.business_name
        ? capitalize(record.client?.business_name)
        : '-',
  },
  {
    title: 'Client Address',
    key: 'client_address',
    render: (_, record) =>
      record?.address ? (
        // ? `${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`
        <Tooltip
          title={`${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`}
          placement="top"
        >
          {capitalize(
            `${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`
          ).substring(0, 20)}
          <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
            ...Read more
          </span>
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    title: 'Vendor Name',
    key: 'vendor_id',

    render: (_, record) =>
      record?.vendor?.vendor_name ? capitalize(record.vendor.vendor_name) : '-',
  },
  {
    title: 'Assigned Worker',
    key: 'assigned_worker',
    width: 120,
    render: (_, record) =>
      record['assigned_worker']
        ? Object.keys(record['assigned_worker']).length > 0
          ? `${capitalize(record['assigned_worker']?.first_name)} ${capitalize(
              record['assigned_worker']?.last_name
            )}`
          : '-'
        : '-',
  },
  {
    title: 'Preferred Time',
    key: 'preferred_time',
    width: 120,
    render: (_, record) => {
      return record['preferred_time'] ? record['preferred_time'] : '-';
    },
  },
  {
    title: 'Assigned Time',
    key: 'assigned_time',
    width: 120,
    render: (_, record) =>
      record['assigned_time'] ? record['assigned_time'] : '-',
  },
  {
    title: 'Service Duration',
    key: 'job_duration_in_minutes',
    width: 120,
    render: (_, record) =>
      record['job_duration_in_minutes']
        ? record['job_duration_in_minutes'] + ' min'
        : '-',
  },
  {
    title: 'Check In',
    key: 'checkin_time',
    width: 120,
    render: (_, record) => (record?.checkin_time ? record.checkin_time : '-'),
  },
  {
    title: 'Check Out',
    key: 'checkout_time',
    width: 120,
    render: (_, record) => (record?.checkout_time ? record.checkout_time : '-'),
  },
  {
    title: 'Active Job Duration',
    width: 130,
    render: (_, record) => {
      if (record?.checkin_time && record?.checkout_time) {
        return record['total_job_time'] ? record['total_job_time'] : '-';
      } else {
        return '-';
      }
    },
  },
  {
    title: 'Job Notes',
    key: 'job_notes',
    width: 120,
    render: (_, record) =>
      record['job_notes']
        ? `${
            record['job_notes']['length'] > 25
              ? record['job_notes'].slice(0, 25) + '...'
              : record['job_notes']
          }`
        : '-',
  },
  {
    title: 'Customer Notes',
    key: 'customer_notes',
    width: 120,
    fixed: 'right',
    render: (_, record) =>
      record['customer_notes']
        ? `${
            record['customer_notes']['length'] > 25
              ? record['customer_notes'].slice(0, 25) + '...'
              : record['customer_notes']
          }`
        : '-',
  },
];

export {
  orderTableColumns,
  orderItemsTableColumns,
  conciergeorderItemsTableColumns,
  orderTransactionsTableColumns,
  jobDetailSingleTableColums,
  jobDetailRepeatingTableColums,
};
