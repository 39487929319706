import { createSlice } from '@reduxjs/toolkit';
import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'jobsFeedback',
  initialState: {
    activeTab: 'all',
    loading: false,
    modalLoading: false,
    modalJob: null,
    submitting: false,
    jobsfeedback: {
      limit: 0,
      total: 0,
      page: 1,
      data: [],
    },
    error: null,
  },
  reducers: {
    setModalJob: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
    },
    getJobById: state => {
      state.modalLoading = true;
    },
    getJobByIdSuccess: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload['job'];
    },
    getJobByIdFailed: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
      apiError(payload);
    },
    getjobsfeedbackSuccess: (state, { payload }) => {
      state.loading = false;
      state.submitting = false;
      const { response } = payload;
      state.jobsfeedback.limit = response.meta['per_page'];
      state.jobsfeedback.page = parseInt(response.meta.page);
      state.jobsfeedback.total = response.meta['total_count'];
      state.jobsfeedback.data = response.jobs;
      state.error = null;
    },
    getjobsfeedbackFailed: (state, { payload }) => {
      state.loading = false;
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    getJobsFeedback: (state, { page }) => {
      state.loading = true;
      state.submitting = true;
      state.jobsfeedback.page = page || 1;
      state.error = null;
    },
  },
});

export const {
  setModalJob,
  getJobById,
  getJobByIdFailed,
  getJobByIdSuccess,
  getJobsFeedback,
  getjobsfeedbackFailed,
  getjobsfeedbackSuccess,
} = slice.actions;

export default slice.reducer;
