import { useState, useLayoutEffect } from 'react';
import moment from 'moment';
import Store from '../library/redux-saga';

const SHIPPED = 'shipped';
const ACTIVE = 'active';
const DELIVERED = 'delivered';
const CANCELED = 'canceled';
const IN_CART = 'in_cart';
const CANCEL_REQUEST_BY_VENDOR = 'cancel_request_by_vendor';
const orderStatus = {
  SHIPPED,
  ACTIVE,
  DELIVERED,
  CANCELED,
  IN_CART,
  CANCEL_REQUEST_BY_VENDOR,
};

const onSearch = (originalList, stateList, key, value) => {
  let updatedDataSource = [...stateList];
  const trimmedValue = value ? value.trim() : '';
  if (!trimmedValue) {
    return originalList;
  } else {
    updatedDataSource = updatedDataSource.filter(
      x => x[key].toLowerCase().indexOf(trimmedValue.toLowerCase()) !== -1
    );
    return updatedDataSource;
  }
};

const utclizeDate = (data, format) => moment(data, format).utc().format(format);

const showTabForCurrentUser = (visibilityDependency, tabName) => {
  const storeState = Store.getState();
  if (
    storeState['auth']['profile'] &&
    storeState['auth']['profile']['admin_user'] &&
    storeState['auth']['profile']['admin_user']['role_permission'] &&
    storeState['auth']['profile']['admin_user']['role_permission'][
      'permissions'
    ]
  ) {
    const { permissions } =
      storeState['auth']['profile']['admin_user']['role_permission'];
    if (permissions['all']) {
      return true;
    } else if (
      permissions[tabName] ||
      (visibilityDependency && permissions[visibilityDependency])
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result['dropSource'] = {
    index: droppableSource.droppableId,
    sourceClone,
  };
  result['dropDestination'] = {
    index: droppableDestination.droppableId,
    destClone,
  };

  return result;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function titleize(input) {
  if (
    input !== null &&
    input !== '' &&
    input !== undefined &&
    typeof input === 'string'
  ) {
    var words = input?.split('_');
    var CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(
        element[0]?.toUpperCase() + element?.slice(1, element?.length)
      );
    });
    return CapitalizedWords?.join(' ');
  }
  input = '';
  return input;
}

const onDragEnd = (result, getList) => {
  let { source, destination } = result;

  // dropped outside the list
  if (!destination) {
    return;
  }

  if (source.droppableId === destination.droppableId) {
    const items = reorder(
      getList(source.droppableId),
      source.index,
      destination.index
    );

    let state = {};

    state['items'] = {
      index: source.droppableId,
      sourceClone: items,
    };

    if (
      source.droppableId !== 'droppable' &&
      source.droppableId !== 'droppable3'
    ) {
      state['selected'] = {
        index: destination.droppableId,
        destClone: items,
      };
    }

    return state;
  } else {
    const result = move(
      getList(source.droppableId),
      getList(destination.droppableId),
      source,
      destination
    );

    return {
      items: result.dropSource,
      selected: result.dropDestination,
    };
  }
};
function removeNullAttrsFromCalender(form) {
  if (form) {
    if (form?.items?.length) {
      form.items.forEach(val => {
        if (val?.calendar_settings_attributes?.length) {
          val.calendar_settings_attributes.forEach(cal_val => {
            if (cal_val.day_name === null) delete cal_val.day_name;
            if (cal_val.from === null) delete cal_val.from;
            if (cal_val.schedule_date === null) delete cal_val.schedule_date;
            if (cal_val.to === null) delete cal_val.to;
          });
        }
      });
    }
  }
}

function groupTransactions(groupedData) {
  if (groupedData.length > 0) {
    groupedData.sort((a, b) => parseInt(a?.order?.id) - parseInt(b?.order?.id));

    for (let i = 0; i < groupedData.length; i++) {
      let k = 1;
      let childArr = [];
      while (groupedData[i + k]) {
        if (
          groupedData[i + k]?.order !== null &&
          groupedData[i]?.order !== null &&
          groupedData[i + k]?.order?.id === groupedData[i].order.id
        ) {
          childArr.push(
            ...groupedData.splice(i + k, 1).map(obj => ({
              ...obj,
              key: Math.random() * (i + 1) * (i + 2),
            }))
          );
          k--;
        }
        k++;
      }
      if (childArr.length > 0) {
        groupedData[i] = { ...groupedData[i], children: childArr };
      }
    }
  }
  return groupedData;
}

export {
  onSearch,
  utclizeDate,
  showTabForCurrentUser,
  useWindowSize,
  onDragEnd,
  titleize,
  orderStatus,
  removeNullAttrsFromCalender,
  groupTransactions,
};
