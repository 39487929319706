import React from 'react';
import { connect } from 'react-redux';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Modal, Button, Drawer, Avatar } from 'antd';
import PropTypes from 'prop-types';
import { dig } from 'digdata';
import DataTable from '../../components/DataTable';
import { capitalize, getImageURI } from '../../utils/apisauce';

import {
  getCategories,
  getSubCategories,
  createSubCategory,
  updateSubCategory,
  deleteSubCategory,
  formUpdate,
  editSubCategory,
} from './reducers';
import TableSearch from '../../components/TableSearch';
// import AvatarUpload from '../../components/AvatarUpload';
import CreateProperties from './Component/CreateProperties';
import { EditFilledIcon, DeleteFilledIcon } from '../../components/Icons/Icons';

class SubCategoryPage extends React.PureComponent {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: { name: '' },
    editData: {},
    lastSearch: '',
    // perPage: 20,
    pageQuery: {
      page: 1,
      per: 20,
    },
    queryString: '',
    editStatus: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { getSubCategories } = this.props;
    const { pageQuery, queryString } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getSubCategories({ ...pageQuery, query: queryString });
      } else {
        getSubCategories(pageQuery);
      }
    }
    // if (submitting === false) {
    //   this.setState({ modalOpened: false });
    // }
  }

  componentDidMount() {
    const { getSubCategories } = this.props;
    const { pageQuery } = this.state;
    getSubCategories(pageQuery);
  }

  onSearch = () => {
    const { getSubCategories } = this.props;
    const { pageQuery, queryString } = this.state;
    const updatedPageQuery = { ...pageQuery, page: 1 };
    this.setState({ pageQuery: updatedPageQuery }, () =>
      getSubCategories({ query: queryString, per: pageQuery['per'] })
    );
  };

  onClickRow = (e, record) => {
    const { editSubCategory } = this.props;
    editSubCategory(record);
    // this.titleInput.focus();
  };

  onEdit = record => {
    const { editSubCategory } = this.props;
    this.setState({ editStatus: true, modalOpened: 1, formData: record });
    editSubCategory(record);
    // this.titleInput.focus();
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
    });
  };

  onSubmit = () => {
    const { formData, editData } = this.state;

    if (formData.id) {
      const edit = {
        ...editData,
        status: formData.status,
        category_id: formData.category.id,
        id: formData.id,
      };

      const { updateSubCategory } = this.props;
      this.setState({ editStatus: false }, () =>
        updateSubCategory({ sub_category: edit })
      );
    } else {
      const { createSubCategory } = this.props;
      createSubCategory({ sub_category: formData });
    }
    this.closeModal();
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteSubCategory } = this.props;

    deleteSubCategory(selectedRow.id);
    this.closeModal();
  };

  onInputChange = ({ target: { name, value } }) => {
    const { onChange } = this.props;

    if (!value) {
      this.setState({ editStatus: false });
    }

    if (value.data) {
      this.setState(state => ({
        ...state,
        formData: {
          ...state.formData,
          [name]: value,
        },
      }));
    }

    if (this.state.editStatus) {
      this.setState(state => ({
        ...state,
        editData: {
          ...state.editData,
          [name]: value,
        },
      }));
    }

    onChange(name, value);
  };

  TableColumns = () => [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Image',
      key: 'image',
      dataIndex: 'image',
      align: 'center',
      render: (text, record) => (
        <Avatar
          size={42}
          src={getImageURI(dig(record, 'image.url'))}
          icon={<UserOutlined />}
        />
      ),
    },
    {
      title: 'Category',
      key: 'categoryName',
      render: (text, record) => capitalize(record?.category?.title),
    },
    {
      title: 'Sub Category',
      key: 'name',
      render: (text, record) => capitalize(record?.name),
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || this.onDelete(record)}
          />
        </div>
      ),
    },
  ];

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: { name: '' },
    });
  };

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  renderDeleteModel = () => {
    const { modalOpened, selectedRow } = this.state;
    if (modalOpened !== 2) {
      return;
    }
    return (
      <Modal
        className="delete-user-modal"
        visible={modalOpened === 2}
        onCancel={this.closeModal}
        onOk={this.performDelete}
        okText="Yes"
        destroyOnClose
      >
        {selectedRow && (
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
            <p className="pop-up-body">
              Do you really want to remove this sub category? The process cannot
              be undone.
            </p>
          </>
        )}
      </Modal>
    );
  };
  renderForm = () => {
    const { formData } = this.props;
    const { editStatus } = this.state;
    return (
      <TableSearch
        placeholder="Enter SubCategory Name"
        btnText={editStatus ? 'Update SubCategory' : 'Add SubCategory'}
        onBtnClick={this.onSubmit}
        name="name"
        value={formData.name}
        onInputChange={this.onInputChange}
      />
    );
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState(state => ({
      ...state,
      formData: {
        ...state.formData,
        [name]: value,
      },
    }));

    if (this.state.editStatus) {
      this.setState(state => ({
        ...state,
        editData: {
          ...state.editData,
          [name]: value,
        },
      }));
    }
  };

  onCreate = () => {
    this.props.getCategories();
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        name: '',
      },
    });
  };

  render() {
    const { pageQuery, queryString, formData } = this.state;
    const { loading, subCategories, categories, submitting } = this.props;
    const { data, total } = subCategories;

    // const FormComponent = this.renderForm();
    return (
      <>
        <div className="page-header">
          <div className="page-title">
            <h4>Sub Categories</h4>
          </div>
          <div className="page-header-actions">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.onCreate}
            >
              Add Sub Category
            </Button>
          </div>
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search sub categories"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={queryString}
            onInputChange={ev =>
              this.setState({ queryString: ev['target']['value'] })
            }
          />
          {/* {FormComponent} */}
          <Card style={{ marginTop: 15 }}>
            <DataTable
              rowKey={record => record.id}
              loading={loading}
              data={data}
              columns={this.TableColumns()}
              // onClickRow={this.onClickRow}
              pagination={{
                limit: pageQuery['per'],
                total,
                page: pageQuery['page'],
                data,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
            />
          </Card>
          {this.renderDeleteModel()}
        </div>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={this.state.modalOpened === 1}
          onClose={this.closeModal}
          destroyOnClose
        >
          {formData && (
            <CreateProperties
              formData={formData}
              onDelete={this.onDelete}
              closeModal={this.closeModal}
              onSubmit={this.onSubmit}
              onInputChange={this.onInputChange}
              loading={loading || submitting}
              handleChange={this.handleChange}
              categories={categories}
            />
          )}
        </Drawer>
      </>
    );
  }
}

SubCategoryPage.propTypes = {
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  subCategories: PropTypes.array,
  formData: PropTypes.object,
  getSubCategories: PropTypes.func,
  createSubCategory: PropTypes.func,
  updateSubCategory: PropTypes.func,
  deleteSubCategory: PropTypes.func,
  editSubCategory: PropTypes.func,
  onChange: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.subCategories.loading,
    subCategories: state.subCategories.subCategories,
    categories: state.subCategories.categories,
    formData: state.subCategories.formData,
    submitting: state.subCategories.submitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories: () => dispatch(getCategories()),
    getSubCategories: q => dispatch(getSubCategories(q)),
    createSubCategory: data => dispatch(createSubCategory(data)),
    updateSubCategory: data => dispatch(updateSubCategory(data)),
    deleteSubCategory: id => dispatch(deleteSubCategory(id)),
    editSubCategory: subCategory => dispatch(editSubCategory(subCategory)),
    onChange: (name, value) => dispatch(formUpdate({ name, value })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryPage);
