import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  updateUI,
} from './reducers';
import TableSearch from '../../components/TableSearch';
import CategoryAllTab from './components/tabs/category-all';
import CategoryProduct from './components/tabs/category-product';
import CategoryClasses from './components/tabs/category-classes';
import CategoryService from './components/tabs/category-service';
import CategorySubscription from './components/tabs/category-subscription';

const DEFAULT_FORM_DATA = {
  title: '',
  description: '',
  status: 'active',
  image: null,
  images: null,
};

const handlerStatusChange = (history, getProductById) => {
  getProductById(null);
  history.push({ pathname: `/admin/products/form/create` });
};

function CategoryPage({
  loading,
  categories,
  getCategories,
  createCategory,
  deleteCategory,
  updateCategory,
  updateUI,
  uiState,
}) {
  // const [categoryUiState, dispatch] = useReducer(reducer, initialUiState);
  const [queryString, updateQueryString] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  const { formData, modalOpened, selectedRow } = uiState;
  const { limit, total, data, page } = categories;

  const onSubmit = values => {
    const _formData = { ...formData, image: null };
    const { title, description, category_type, status } = values;
    if (formData.images) {
      _formData.image = formData.images;
    } else {
      _formData.images = null;
    }
    if (formData.id) {
      updateCategory({
        ..._formData,
        title,
        description,
        category_type,
        status,
      });
    } else {
      createCategory({
        ..._formData,
        title,
        description,
        category_type,
        status,
      });
    }
  };

  const performDelete = () => {
    deleteCategory(selectedRow.id);
  };

  const onInputChange = ({ target: { name, value } }) => {
    const newUi = { ...uiState.formData, [name]: value };
    updateUI({ ...uiState, formData: newUi });
  };

  const onSearch = () => {
    getCategories({ query: queryString, per: limit });
  };

  const handleTableChange = pagination => {
    getCategories({
      page: pagination['current'],
      per: pagination['pageSize'],
      query: queryString ? queryString : null,
      activeTab,
    });
  };

  useEffect(() => {
    getCategories({
      page: 1,
      per: 20,
      query: null,
      activeTab,
      all: activeTab === 'all',
    });
  }, [getCategories, activeTab]);

  const onCloseModal = () => {
    updateUI({ ...uiState, modalOpened: false });
  };

  const onAddClick = () => {
    updateUI({
      ...uiState,
      modalOpened: 1,
      selectedRow: null,
      formData: DEFAULT_FORM_DATA,
    });
  };

  const onEditClick = item => {
    updateUI({
      ...uiState,
      modalOpened: 1,
      selectedRow: null,
      formData: { ...item },
    });
  };

  const onRowClick = item => {
    updateUI({ ...uiState, modalOpened: 1, selectedRow: item, formData: null });
  };

  const onDeleteClick = item => {
    updateUI({ ...uiState, modalOpened: 2, selectedRow: item, formData: null });
  };

  return (
    <div className="jobs-page">
      <div className="page-header">
        <Row>
          <Col>
            <div className="page-title">
              <h4>Categories</h4>
            </div>
            <div className="page-header-actions">
              {activeTab !== 'all' && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={onAddClick}
                >
                  Add New Category
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ant-tabs-nav ant-tabs-nav-animated">
              <div
                className={`ant-tabs-tab${
                  activeTab === 'all' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('all')}
              >
                ALL
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'product' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('product')}
              >
                Product
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'classes' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('classes')}
              >
                Classes
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'service' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('service')}
              >
                Service
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'subscription' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setActiveTab('subscription')}
              >
                Subscription
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        <TableSearch
          placeholder="Search Category"
          btnText="Search"
          onBtnClick={onSearch}
          value={queryString}
          onInputChange={ev => updateQueryString(ev['target']['value'])}
        />
        {activeTab === 'all' ? (
          <CategoryAllTab
            loading={loading}
            data={data}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            handlerStatusChange={handlerStatusChange}
            onRowClick={onRowClick}
            limit={limit}
            total={total}
            page={page}
            handleTableChange={handleTableChange}
            modalOpened={modalOpened}
            selectedRow={selectedRow}
            onCloseModal={onCloseModal}
            formData={formData}
            uiState={uiState}
            onSubmit={onSubmit}
            onInputChange={onInputChange}
            performDelete={performDelete}
          />
        ) : activeTab === 'product' ? (
          <CategoryProduct
            loading={loading}
            data={data}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            handlerStatusChange={handlerStatusChange}
            onRowClick={onRowClick}
            limit={limit}
            total={total}
            page={page}
            handleTableChange={handleTableChange}
            modalOpened={modalOpened}
            selectedRow={selectedRow}
            onCloseModal={onCloseModal}
            formData={formData}
            uiState={uiState}
            onSubmit={onSubmit}
            onInputChange={onInputChange}
            performDelete={performDelete}
          />
        ) : activeTab === 'service' ? (
          <CategoryService
            loading={loading}
            data={data}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            handlerStatusChange={handlerStatusChange}
            onRowClick={onRowClick}
            limit={limit}
            total={total}
            page={page}
            handleTableChange={handleTableChange}
            modalOpened={modalOpened}
            selectedRow={selectedRow}
            onCloseModal={onCloseModal}
            formData={formData}
            uiState={uiState}
            onSubmit={onSubmit}
            onInputChange={onInputChange}
            performDelete={performDelete}
          />
        ) : activeTab === 'classes' ? (
          <CategoryClasses
            loading={loading}
            data={data}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            handlerStatusChange={handlerStatusChange}
            onRowClick={onRowClick}
            limit={limit}
            total={total}
            page={page}
            handleTableChange={handleTableChange}
            modalOpened={modalOpened}
            selectedRow={selectedRow}
            onCloseModal={onCloseModal}
            formData={formData}
            uiState={uiState}
            onSubmit={onSubmit}
            onInputChange={onInputChange}
            performDelete={performDelete}
          />
        ) : (
          <CategorySubscription
            loading={loading}
            data={data}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            handlerStatusChange={handlerStatusChange}
            onRowClick={onRowClick}
            limit={limit}
            total={total}
            page={page}
            handleTableChange={handleTableChange}
            modalOpened={modalOpened}
            selectedRow={selectedRow}
            onCloseModal={onCloseModal}
            formData={formData}
            uiState={uiState}
            onSubmit={onSubmit}
            onInputChange={onInputChange}
            performDelete={performDelete}
          />
        )}
      </div>
    </div>
  );
}

CategoryPage.propTypes = {
  loading: PropTypes.bool,
  categories: PropTypes.object,
  getCategories: PropTypes.func,
  createCategory: PropTypes.func,
  deleteCategory: PropTypes.func,
  updateCategory: PropTypes.func,
  updateUI: PropTypes.func,
  uiState: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.categories.loading,
    categories: state.categories.categories,
    uiState: state.categories.uiState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories: keyword => dispatch(getCategories(keyword)),
    createCategory: data => dispatch(createCategory(data)),
    updateCategory: data => dispatch(updateCategory(data)),
    deleteCategory: id => dispatch(deleteCategory(id)),
    updateUI: payload => dispatch(updateUI(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
