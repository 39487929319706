import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Button, InputNumber, Typography } from 'antd';
import { login, getProfile, otpCheck } from './reducers';
import { Redirect } from 'react-router-dom';
import butlerLogo from '../../assets/icons/butlerLogo.png';
import '../../auth.css';

const { Title } = Typography;

class OTPCheck extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      otpCode: 0,
      submit: false,
    };
  }

  handleOnChange = value => {
    if (String(value)?.length === 6) {
      this.setState({ submit: true, otpCode: value });
    } else {
      this.setState({ submit: false });
    }
  };

  renderOTP = () => {
    return (
      <Form.Item name="otp-code">
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>
              <b>Enter The Verification Code</b>
            </p>
            <p
              style={{ color: '#5f9cd4', cursor: 'pointer' }}
              onClick={() =>
                this.props.login({
                  username: this.props.username,
                  password: this.props.password,
                })
              }
            >
              <b>Resend Code</b>
            </p>
          </div>
          <InputNumber
            keyboard={false}
            inputMode="numeric"
            controls={true}
            className="otp-code-input"
            maxLength={6}
            onChange={value => this.handleOnChange(value)}
          />
        </div>
        <Button
          key="submit"
          type="primary"
          style={{ width: '100%', marginTop: '5px' }}
          htmlType="submit"
          disabled={!this.state.submit}
          onClick={e => {
            e.stopPropagation();
            this.handleSubmit();
          }}
        >
          Confirm The Code
        </Button>
      </Form.Item>
    );
  };

  handleSubmit = () => {
    this.props.otpCheck({
      verify: {
        login: this.props.username,
        otp: `${this.state.otpCode}`,
      },
    });
  };

  render() {
    if (this.props.authenticated) {
      return <Redirect key="dashboard" to="/" />;
    }

    if (this.props.authenticated && !this.loading && !this.props.error) {
      this.props.getProfile();
    }
    return (
      <div>
        <div className="login-main">
          <div className="login-wrap">
            <div className="img-side">
              <a href="#" id="logo">
                <img src={butlerLogo} />
              </a>
              <div className="img-content">
                <h1>Welcome to BUTLER Admin Portal</h1>
                <p>
                  We are glad to see you again! Instant deposits, withdrawals &
                  payouts trusted by millions worldwide.
                </p>
              </div>
            </div>
            <div className="form-side">
              <div className="otp-page">
                <Form className="login-form" id="otp-form">
                  <Form.Item>
                    <Title level={3}>Please Verify Your Number</Title>
                  </Form.Item>
                  <p>
                    A 6-digit code was just sent to your number {'**********'}
                    {this.props.number.substr(this.props.number?.length - 4)}.
                    Please enter it below .
                  </p>
                  {this.renderOTP()}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OTPCheck.propTypes = {
  authenticated: PropTypes.bool,
  profile: PropTypes.object,
  error: PropTypes.string,
  login: PropTypes.func,
  getProfile: PropTypes.func,
  otpCheck: PropTypes.func,
  username: PropTypes.string,
  password: PropTypes.string,
  number: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    profile: state.auth.profile,
    loading: state.auth.loading,
    error: state.auth.error,
    username: state.auth.username,
    password: state.auth.password,
    number: state.auth.number,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    otpCheck: payload => dispatch(otpCheck(payload)),
    login: payload => dispatch(login(payload)),
    getProfile: () => dispatch(getProfile()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OTPCheck);
