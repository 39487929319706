import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  getJobs,
  getJobsFailed,
  getJobsSuccess,
  createJob,
  createJobFailed,
  createJobSuccess,
  updateJob,
  updateJobFailed,
  updateJobSuccess,
  deleteJob,
  deleteJobFailed,
  deleteJobSuccess,
  getJobById,
  getJobByIdFailed,
  getJobByIdSuccess,
  cancelJob,
  cancelJobSuccess,
  cancelJobFailed,
  updateDays,
  updateDaysSuccess,
  updateDaysFailed,
  udpateJobNotes,
  udpateJobNotesSuccess,
  updateJobNotesFailed,
} from './reducers';

function* getJobByIdSaga({ payload }) {
  let action;

  try {
    const response = yield call(request, 'GET', `/api/v1/jobs/${payload}`);
    action = getJobByIdSuccess(response);
  } catch (error) {
    action = getJobByIdFailed(error.message);
  }
  yield put(action);
}

function* getJobsSaga({ payload }) {
  let action;
  let url = '/api/v1/jobs?';
  if (payload.type === 'single') {
    url += `type=single&active=true&sort=service_date`;
    if (payload['order'] && payload['order'] === 'ascend') {
      url += `&direction=asc`;
    } else {
      url += `&direction=desc`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
  } else if (payload.type === 'repeating') {
    url = '/api/v1/vendor/jobs/type/repeatings?';
    url += `sort=start_date&direction=desc`;

    if (payload?.activeTab) {
      url += `&status=${payload.activeTab}`;
    }

    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
  } else if (payload.type === 'history') {
    url = '/api/v1/jobs/history?sort=service_date';
    if (payload['order'] && payload['order'] === 'ascend') {
      url += `&direction=asc`;
    } else {
      url += `&direction=desc`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
  } else {
    url += `active=true&sort=service_date&direction=asc`;
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
  }

  try {
    const response = yield call(request, 'GET', url);
    action = getJobsSuccess({
      type: payload.type,
      response,
    });
  } catch (error) {
    action = getJobsFailed(error.message);
  }
  yield put(action);
}

function* createJobSaga({ payload }) {
  let action;
  let path = `api/v1/jobs`,
    params = {
      page: 1,
      per: 20,
      direction: 'desc',
    };
  if (payload.type === 'single') {
    params.type = 'single';
    params.active = true;
  } else if (payload.type === 'repeating') {
    params.sort = 'start_date';
    if (payload?.activeTab) {
      params.status = payload.activeTab;
    }
    path = '/api/v1/jobs/type/repeatings';
  } else if (payload.type === 'history') {
    params.sort = 'service_date';
    path = '/api/v1/jobs/history';
  }

  try {
    const newJob = yield call(request, 'POST', path, payload.data);
    const jobs = yield call(request, 'GET', path, params);

    action = createJobSuccess({
      type: payload.type,
      newJob:
        payload.type === 'repeating'
          ? { ...newJob, title: payload.data['jobs_attributes']['title'] }
          : newJob['job'],
      jobs,
    });
  } catch (error) {
    action = createJobFailed(error.message);
  }
  yield put(action);
}

function* updateJobSaga({ payload }) {
  let action;
  let path = `api/v1/jobs`,
    params = {
      page: payload.page || 1,
      per: payload.per,
      type: 'single',
      active: true,
    };

  let url = `api/v1/jobs/${payload.id}`;

  if (payload.type === 'repeating') {
    url = `/api/v1/jobs/type/repeatings/${payload.id}/update_jobs`;
    path = '/api/v1/jobs/type/repeatings';
    params = {
      ...params,
      direction: 'desc',
      sort: 'start_date',
      status: payload.activeTab,
    };
    delete params['type'];
    delete params['active'];
  }

  try {
    const updatedJob = yield call(request, 'PUT', url, payload.data);

    const jobs = yield call(request, 'GET', path, params);
    action = updateJobSuccess({
      updatedJob: payload.type === 'repeating' ? updatedJob : updatedJob['job'],
      jobs,
    });
  } catch (error) {
    action = updateJobFailed(error.message);
  }
  yield put(action);
}

function* deleteJobSaga({ payload }) {
  let action;
  let path = `/api/v1/admin/jobs/${payload.id}`;
  if (payload.type === 'repeating') {
    path = `/api/v1/admin/repeating_jobs/${payload.id}`;
  }

  try {
    const deletedJob = yield call(request, 'DELETE', path);
    path = `api/v1/jobs`;
    let params = { page: 1 };
    if (payload.type === 'repeating') {
      path = `api/v1/jobs/type/repeatings`;
      if (payload?.activeTab) {
        path += `?status=${payload.activeTab}`;
      }
    } else {
      params.type = 'single';
      params.active = true;
      params.sort = 'service_date';
      params.direction = 'desc';
      params.per = 20;
    }
    const jobs = yield call(request, 'GET', path, params);
    action = deleteJobSuccess({
      type: payload.type,
      deletedJob,
      jobs,
    });
  } catch (error) {
    action = deleteJobFailed(error.message);
  }
  yield put(action);
}

function* cancelJobSaga({ payload }) {
  let action;
  let path = `/api/v1/admin/repeating_jobs/${payload.id}/cancel`;
  try {
    const cancelJob = yield call(request, 'PATCH', path, {
      repeating_job: {
        canceled_at: payload.canceled_at,
      },
    });
    path = `api/v1/jobs`;
    let params = { page: 1 };
    if (payload.type === 'repeating') {
      path = `api/v1/jobs/type/repeatings`;
      if (payload?.activeTab) {
        path += `?status=${payload.activeTab}`;
      }
    } else {
      params.type = 'single';
      params.active = true;
      params.sort = 'service_date';
      params.direction = 'desc';
      params.per = 20;
    }
    const jobs = yield call(request, 'GET', path, params);
    action = cancelJobSuccess({
      type: payload.type,
      cancelJob,
      jobs,
    });
  } catch (error) {
    action = cancelJobFailed(error.message);
  }
  yield put(action);
}

function* updateDaysSaga({ payload }) {
  let action;
  let path = `/api/v1/admin/repeating_jobs/${payload.id}/update_days`;
  try {
    const repeating_days = yield call(request, 'PATCH', path, {
      repeating_job: {
        start_date: payload.start_date,
        days: payload.days,
        days_quantity_limit: payload.days_quantity_limit,
      },
    });
    path = `api/v1/jobs`;
    let params = { page: 1 };
    if (payload.type === 'repeating') {
      path = `api/v1/jobs/type/repeatings`;
      if (payload?.activeTab) {
        path += `?status=${payload.activeTab}`;
      }
    } else {
      params.type = 'single';
      params.active = true;
      params.sort = 'service_date';
      params.direction = 'desc';
      params.per = 20;
    }
    const jobs = yield call(request, 'GET', path, params);
    action = updateDaysSuccess({
      type: payload.type,
      repeating_days,
      jobs,
    });
  } catch (error) {
    action = updateDaysFailed(error.message);
  }
  yield put(action);
}
function* updateJobNotesSaga({ payload }) {
  const { id, job_notes } = payload;
  let action;
  let path = `/api/v1/vendor/jobs/${id}`;
  try {
    const response = yield call(request, 'PATCH', path, { job_notes });
    action = udpateJobNotesSuccess(response);
  } catch (error) {
    action = updateJobNotesFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getJobById, getJobByIdSaga);
  yield takeLatest(getJobs, getJobsSaga);
  yield takeLatest(createJob, createJobSaga);
  yield takeLatest(updateJob, updateJobSaga);
  yield takeLatest(deleteJob, deleteJobSaga);
  yield takeLatest(cancelJob, cancelJobSaga);
  yield takeLatest(updateDays, updateDaysSaga);
  yield takeLatest(udpateJobNotes, updateJobNotesSaga);
}
