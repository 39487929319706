import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_PRODUCTS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'concierge',
  initialState: {
    loading: false,
    submitting: false,
    created: false,
    concierge: DEFAULT_PRODUCTS,
    error: '',
    conciergeList: [],
    uiState: {
      selectedRow: null,
    },
  },
  reducers: {
    getConcierge: state => {
      state.loading = true;
    },
    getConciergeSuccess: (state, { payload }) => {
      state.loading = false;
      state.concierge = {
        data: payload?.orders,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getConciergeFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    createConcierge: state => {
      state.loading = true;
      state.submitting = true;
      state.created = false;
      state.message = message.loading('Creating a new concierge ...', 0);
    },
    createConciergeSuccess: (state, { payload }) => {
      state.message();
      state.loading = false;
      state.submitting = false;
      state.created = true;
      const concirge_data = state.concierge.data;
      concirge_data.push(payload?.order);
      state.concierge.data = concirge_data;

      notification.success({
        message: 'New concierge',
        description: `${payload.meta.message}`,
      });
    },
    createConciergeFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      state.created = false;
      apiError(payload);
    },
    // updateProduct: state => {
    //   state.loading = true;
    //   state.submitting = true;
    //   state.message = message.loading('Updating product info...', 0);
    // },
    // updateProductSuccess: (state, { payload }) => {
    //   state.message();
    //   state.formObject = payload.product;
    //   state.loading = false;
    //   state.submitting = false;
    //   const idx = state.products.data.findIndex(
    //     el => el.id === payload.product.id
    //   );
    //   if (idx >= 0) {
    //     state.products[idx] = payload.product;
    //   }
    //   notification.success({
    //     message: 'Product Updated',
    //     description: `Product ${payload.product.title} has been updated.`,
    //   });
    // },
    // updateProductFailed: (state, { payload }) => {
    //   state.message();
    //   state.submitting = false;
    //   state.loading = false;
    //   apiError(payload);
    // },
    // deleteProduct: state => {
    //   state.message = message.loading('Deleting product ...', 0);
    // },
    // deleteProductSuccess: (state, { payload }) => {
    //   state.message();
    //   state.products.data = state.products.data.filter(
    //     x => x.id !== payload.product.id
    //   );
    //   notification.success({
    //     message: 'Product Deleted',
    //     description: `Product ${payload.product.title} has been deleted.`,
    //   });
    //   state.uiState = {
    //     ...state.uiState,
    //     modalOpened: false,
    //     selectedRow: null,
    //   };
    // },
    // deleteProductFailed: (state, { payload }) => {
    //   state.message();
    //   apiError(payload);
    // },
    getProductById: (state, { payload }) => {
      state.formObject = payload;
      //TODO: move code from product-create to here
    },
    // updateUI: (state, { payload }) => {
    //   state.uiState = { ...payload };
    // },
  },
});

export const {
  getConcierge,
  getConciergeSuccess,
  getConciergeFailed,
  createConcierge,
  createConciergeSuccess,
  createConciergeFailed,
  // updateProduct,
  // updateProductSuccess,
  // updateProductFailed,
  // deleteProduct,
  // deleteProductFailed,
  // deleteProductSuccess,
  getProductById,
  // updateUI,
} = slice.actions;
export default slice.reducer;
