import { CloseOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Drawer,
  Input,
  Modal,
  Select,
  // TimePicker,
  Checkbox,
  Form,
  Row,
  Col,
} from 'antd';
import ReactTimePicker from '../../../components/TimePicker';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dig } from 'digdata';
import PhoneInput from 'react-phone-input-2';
import moment from 'moment';
import PropTypes from 'prop-types';

import DataTable from '../../../components/DataTable';
import { getImageURI, capitalize } from '../../../utils/apisauce';
import { getVendor, createUser, updateUser, deleteUser } from './reducers';
import './styles.css';
import AvatarUpload from '../../../components/AvatarUpload';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import {
  DeleteFilledIcon,
  EditFilledIcon,
} from '../../../components/Icons/Icons';

// to set 00.00.00 to remove error from trying to format null which is not a time value on DatePicker
const defaultTime = () => {
  return moment().startOf('day').format('HH:mm');
};

class VendorProfilePage extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    showPassword: false,
    activeTab: 'active',
    pageQuery: {
      page: 1,
      per: 50,
      query: null,
    },
  };

  componentDidMount() {
    const { getVendor } = this.props;

    getVendor({ activeTab: 'active' });
  }

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onClickRow = (e, record) => {
    this.setState({
      modalOpened: 1,
      selectedRow: record,
      formData: null,
    });
  };

  onCreate = () => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        picture: null,
        first_name: '',
        last_name: '',
        nric: '',
        mobile: '',
        email: '',
        country: 'singapore',
        postal_code: '',
        role: 'worker',
        status: 'active',
        start_time: defaultTime(),
        end_time: defaultTime(),
        password: '',
      },
    });
  };

  onEdit = record => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        id: record.id,
        first_name: record.first_name,
        last_name: record.last_name,
        nric: record.nric,
        mobile: record.mobile,
        email: record.email,
        country: record.country,
        postal_code: record.postal_code,
        role: record.role,
        status: record.status,
        start_time: record.start_time,
        end_time: record.end_time,
        picture_path: record.picture_path,
        password: '',
      },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  onSubmit = values => {
    const {
      match: {
        params: { id },
      },
      updateUser,
      createUser,
    } = this.props;

    const { formData, activeTab } = this.state;

    if (formData.id) {
      updateUser({
        vendor_id: id,
        vendor_user: { ...formData, ...values },
        activeTab,
      });
    } else {
      createUser({
        vendor_id: id,
        vendor_user: {
          ...formData,
          ...values,
          picture: formData['picture'],
        },
        activeTab,
      });
    }
    // this.closeModal();
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const {
      match: {
        params: { id },
      },
      deleteUser,
    } = this.props;

    deleteUser(id, selectedRow.id);
    this.closeModal();
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  tableColumns = () => [
    {
      title: 'Image',
      key: 'image',
      render: (text, record) => (
        <Avatar
          src={getImageURI(dig(record, 'picture_path.url'))}
          icon={<UserOutlined />}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) =>
        `${capitalize(record.first_name)} ${capitalize(record.last_name)}`,
    },
    {
      title: 'Employee ID',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const colorVal = {
          active: '#6ABB98',
          inactive: '#F1B13B',
          suspended: '#DF5E67',
        };
        return (
          <span
            style={{
              color: colorVal[record.status],
              textTransform: 'capitalize',
            }}
          >
            {record.status}
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className="ant-table-action-cell">
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          {record.status !== 'active' ? (
            ''
          ) : (
            <DeleteFilledIcon
              onClick={e => e.stopPropagation() || this.onDelete(record)}
            />
          )}
        </div>
      ),
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  setActiveTab = value => {
    const { getVendor } = this.props;
    this.setState(state => ({ ...state, activeTab: value }));
    getVendor({ activeTab: value });
  };

  render() {
    const { vendor, loading, submitting } = this.props;
    const { modalOpened, selectedRow, formData, pageQuery, showPassword } =
      this.state;
    let vendor_users = [];

    if (vendor) {
      vendor_users = vendor;
    }
    return (
      <div className="vendor-profile-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Users</h4>
          </div>
          <div className="page-header-actions">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.onCreate}
            >
              Add New Worker
            </Button>
          </div>
        </div>
        <div className="page-content">
          <div className="jobs-page">
            <div className="page-header">
              <Row>
                <Col>
                  <div className="ant-tabs-nav ant-tabs-nav-animated">
                    <div
                      className={`ant-tabs-tab${
                        this.state.activeTab === 'active'
                          ? ' ant-tabs-tab-active '
                          : ''
                      }`}
                      onClick={() => this.setActiveTab('active')}
                    >
                      Active
                    </div>
                    <div
                      className={`ant-tabs-tab${
                        this.state.activeTab === 'inactive'
                          ? ' ant-tabs-tab-active '
                          : ''
                      }`}
                      onClick={() => this.setActiveTab('inactive')}
                    >
                      Inactive
                    </div>
                    <div
                      className={`ant-tabs-tab${
                        this.state.activeTab === 'suspended'
                          ? ' ant-tabs-tab-active '
                          : ''
                      }`}
                      onClick={() => this.setActiveTab('suspended')}
                    >
                      Suspended
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Card>
              <DataTable
                rowKey={record => record['id']}
                loading={loading}
                data={vendor_users}
                columns={this.tableColumns()}
                onClickRow={this.onClickRow}
                pagination={{
                  limit: pageQuery['per'],
                  total: vendor_users['length'],
                  page: pageQuery['page'],
                  data: vendor_users,
                  onChange: this.onPageChange,
                }}
                handleTableChange={this.handleTableChange}
              />
            </Card>
          </div>
        </div>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={modalOpened === 1}
          onClose={this.closeModal}
        >
          {selectedRow ? (
            <>
              <div className="drawer-header">
                <h3>Worker Detail</h3>
                <div className="header-actions">
                  <EditFilledIcon
                    className="action-primary"
                    onClick={() => this.onEdit(selectedRow)}
                  />
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <div className="user-avatar">
                  <Avatar
                    src={getImageURI(dig(selectedRow, 'picture_path.url'))}
                    icon={<UserOutlined />}
                    size={100}
                  />
                  <div className="username">
                    {selectedRow.first_name}&nbsp;{selectedRow.last_name}
                  </div>
                  <div className="user-status">
                    <span className={`dot ${selectedRow.status}`} />
                    <span>{selectedRow.status}</span>
                  </div>
                </div>
                <div className="user-info-row">
                  <label>Employee ID</label>
                  <span>{selectedRow.id}</span>
                </div>
                <div className="user-info-row">
                  <label>NRIC /FIN</label>
                  <span>{selectedRow.nric}</span>
                </div>
                <div className="user-info-row">
                  <label>Phone Number</label>
                  <span>{selectedRow.mobile}</span>
                </div>
                <div className="user-info-row">
                  <label>Email Address</label>
                  <span>{selectedRow.email}</span>
                </div>
                <div className="user-info-row">
                  <label>Working Hours</label>
                  <span>{`${selectedRow['start_time']} to ${selectedRow['end_time']}`}</span>
                </div>
              </div>
            </>
          ) : (
            formData && (
              <>
                <div className="drawer-header">
                  <h3>{formData.id ? 'Edit Worker' : 'Add Worker'}</h3>
                  <div className="header-actions">
                    {formData.id && (
                      <DeleteFilledIcon
                        className="action-primary"
                        onClick={() => this.onDelete(formData)}
                      />
                    )}
                    <CloseOutlined onClick={this.closeModal} />
                  </div>
                </div>
                <div className="drawer-body">
                  <Formik
                    initialValues={{
                      ...formInitialValues['createVendorUserForm'],
                      ...formData,
                    }}
                    validationSchema={
                      formData.id
                        ? formValidations['UpdateVendorUserFormValidation']
                        : formValidations['CreateVendorUserFormValidation']
                    }
                    onSubmit={values => this.onSubmit(values)}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="user-avatar">
                            <AvatarUpload
                              value={
                                formData['picture_path']
                                  ? getImageURI(
                                      dig(formData, 'picture_path.url')
                                    )
                                  : null
                              }
                              icon={<UserOutlined />}
                              onChange={value =>
                                this.onInputChange({
                                  target: {
                                    name: 'picture',
                                    value: { data: value },
                                  },
                                })
                              }
                              accept=".png, .jpg, .jpeg"
                            />
                          </div>
                          {formData.id && (
                            <div className="form-group">
                              <label>Employee ID</label>
                              <span>{formData.id}</span>
                            </div>
                          )}
                          <div className="form-group">
                            <label>First Name</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['first_name'] && errors['first_name']
                                  ? 'error'
                                  : null
                              }
                              help={
                                touched['first_name'] && errors['first_name']
                              }
                              style={{ margin: 0 }}
                            >
                              <Input
                                name="first_name"
                                value={values.first_name}
                                placeholder="Enter first name"
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label>Last Name</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['last_name'] && errors['last_name']
                                  ? 'error'
                                  : null
                              }
                              help={touched['last_name'] && errors['last_name']}
                              style={{ margin: 0 }}
                            >
                              <Input
                                name="last_name"
                                value={values.last_name}
                                placeholder="Enter last name"
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label>NRIC/FIN</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['nric'] && errors['nric']
                                  ? 'error'
                                  : null
                              }
                              help={touched['nric'] && errors['nric']}
                              style={{ margin: 0 }}
                            >
                              <Input
                                name="nric"
                                value={values.nric}
                                placeholder="Enter NRIC number"
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label>Phone Number</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['mobile'] && errors['mobile']
                                  ? 'error'
                                  : null
                              }
                              help={touched['mobile'] && errors['mobile']}
                              style={{ margin: 0 }}
                            >
                              <PhoneInput
                                defaultCountry={'sg'}
                                inputStyle={{ width: '100%' }}
                                value={values.mobile}
                                onChange={value =>
                                  handleChange({
                                    target: { name: 'mobile', value },
                                  })
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label>Email</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['email'] && errors['email']
                                  ? 'error'
                                  : null
                              }
                              help={touched['email'] && errors['email']}
                              style={{ margin: 0 }}
                            >
                              <Input
                                type="email"
                                name="email"
                                value={values.email}
                                placeholder="Enter email"
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label>Postal Code</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['postal_code'] && errors['postal_code']
                                  ? 'error'
                                  : null
                              }
                              help={
                                touched['postal_code'] && errors['postal_code']
                              }
                              style={{ margin: 0 }}
                            >
                              <Input
                                name="postal_code"
                                value={values.postal_code}
                                placeholder="Enter postal code"
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label>Status</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['status'] && errors['status']
                                  ? 'error'
                                  : null
                              }
                              help={touched['status'] && errors['status']}
                              style={{ margin: 0 }}
                            >
                              <Select
                                style={{ width: '100%' }}
                                value={values.status}
                                onChange={value =>
                                  handleChange({
                                    target: { name: 'status', value },
                                  })
                                }
                              >
                                <Select.Option value="active">
                                  Active
                                </Select.Option>
                                <Select.Option value="inactive">
                                  Inactive
                                </Select.Option>
                                <Select.Option value="suspended">
                                  Suspended
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <label>Work Hours</label>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['start_time'] && errors['start_time']
                                  ? 'error'
                                  : null
                              }
                              help={
                                touched['start_time'] && errors['start_time']
                              }
                              style={{ margin: 0 }}
                            >
                              {/* <TimePicker
                                format="HH:mm"
                                style={{ width: '100%' }}
                                value={
                                  values.start_time
                                    ? moment(values.start_time, 'HH:mm')
                                    : moment().startOf('day')
                                }
                                placeholder="Pick start time"
                                onChange={val => {
                                  handleChange({
                                    target: {
                                      name: 'start_time',
                                      value: val.format('HH:mm'),
                                    },
                                  });
                                }}
                              /> */}
                              <ReactTimePicker
                                name="start_time"
                                item={values}
                                cahngeFormTime={(item, timeString) => {
                                  handleChange({
                                    target: {
                                      name: 'start_time',
                                      value: timeString,
                                    },
                                  });
                                }}
                                placeholder="Pick start time"
                              />
                            </Form.Item>
                            <Form.Item
                              hasFeedback
                              validateStatus={
                                touched['end_time'] && errors['end_time']
                                  ? 'error'
                                  : null
                              }
                              help={touched['end_time'] && errors['end_time']}
                              style={{ marginTop: 10 }}
                            >
                              {/* <TimePicker
                                format="HH:mm"
                                style={{ width: '100%', marginTop: '15px' }}
                                value={
                                  values.end_time
                                    ? moment(values.end_time, 'HH:mm')
                                    : moment().startOf('day')
                                }
                                placeholder="Pick end time"
                                onChange={val =>
                                  handleChange({
                                    target: {
                                      name: 'end_time',
                                      value: val.format('HH:mm'),
                                    },
                                  })
                                }
                              /> */}
                              <ReactTimePicker
                                name="end_time"
                                item={values}
                                cahngeFormTime={(item, timeString) => {
                                  handleChange({
                                    target: {
                                      name: 'end_time',
                                      value: timeString,
                                    },
                                  });
                                }}
                                placeholder="Pick end time"
                              />
                            </Form.Item>
                          </div>
                          {formData.id && (
                            <Checkbox
                              style={{ margin: '1em 0' }}
                              onChange={() =>
                                this.setState({ showPassword: !showPassword })
                              }
                            >
                              Change Password
                            </Checkbox>
                          )}
                          {(formData.id && showPassword) || !formData.id ? (
                            <div className="form-group">
                              <label>New Password</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['password'] && errors['password']
                                    ? 'error'
                                    : null
                                }
                                help={touched['password'] && errors['password']}
                                style={{ margin: 0 }}
                              >
                                <Input.Password
                                  name="password"
                                  value={values.password}
                                  placeholder="Set New Password"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          ) : null}
                          <Button
                            type="primary"
                            htmlType="submit"
                            block
                            disabled={submitting}
                            loading={submitting}
                          >
                            Save
                          </Button>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </>
            )
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <div className="user-info">
                <Avatar
                  src={getImageURI(selectedRow['picture_path'])}
                  icon={<UserOutlined />}
                  size={72}
                />
                <div className="username">
                  {selectedRow.first_name}&nbsp;{selectedRow.last_name}
                </div>
                <span>{vendor.vendor_name}</span>
              </div>
              <p className="main-message">
                Are you sure want to inactive this worker, and we will unassign
                the current and future jobs that cannot be undone ?
              </p>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

VendorProfilePage.propTypes = {
  loading: PropTypes.bool,
  vendor: PropTypes.object,
  getVendor: PropTypes.func,
  createUser: PropTypes.func,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

//vendor contain vendor_users' array
function mapStateToProps(state) {
  return {
    loading: state.VendorUsers.loading,
    vendor: state.VendorUsers.vendor,
    submitting: state.VendorUsers.submitting,
    error: state.VendorUsers.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVendor: payload => dispatch(getVendor(payload)),
    createUser: data => dispatch(createUser(data)),
    updateUser: data => dispatch(updateUser(data)),
    deleteUser: (vendorId, userId) =>
      dispatch(deleteUser({ vendorId, userId })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorProfilePage));
