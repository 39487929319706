import { CloseOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { getImageURI } from '../../../utils/apisauce';
import React from 'react';
import { EditFilledIcon } from '../../../components/Icons/Icons';

CategoryDetail.propTypes = {
  selectedRow: PropTypes.object,
  onEditClick: PropTypes.func,
  onCloseModal: PropTypes.func,
};

export default function CategoryDetail({
  selectedRow,
  onEditClick,
  onCloseModal,
}) {
  return (
    <>
      <div className="drawer-header">
        <h3>Category Detail</h3>
        <div className="header-actions">
          <EditFilledIcon
            className="action-primary"
            onClick={() => onEditClick(selectedRow)}
          />
          <CloseOutlined onClick={onCloseModal} />
        </div>
      </div>
      <div className="drawer-body">
        <div className="user-avatar">
          <Avatar
            src={getImageURI(selectedRow['image'] && selectedRow['image'].url)}
            icon={<AppstoreOutlined />}
            size={'large'}
          />
          <div className="username">{selectedRow.name}</div>
          <div className="user-status">
            <span className={`dot ${selectedRow.status}`} />
            <span>{selectedRow.status}</span>
          </div>
        </div>
        <div className="user-info-row">
          <label>Categories ID</label>
          <span>{selectedRow.id}</span>
        </div>
        <div className="user-info-row">
          <label>Description</label>
          <span>{selectedRow.description}</span>
        </div>
      </div>
    </>
  );
}
