import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getAccounts,
  getAccountsSuccess,
  getAccountsFailed,
  getJob,
  getJobSuccess,
  getJobFailed,
  getOrders,
  getOrdersSuccess,
  getOrdersFailed,
  createOrder,
  createOrderSuccess,
  createOrderFailed,
  updateOrder,
  updateOrderSuccess,
  updateOrderFailed,
  deleteOrder,
  deleteOrderSuccess,
  deleteOrderFailed,
  orderShipped,
  orderShippedSuccess,
  orderShippedFailed,
  orderDelivered,
  orderDeliveredSuccess,
  orderDeliveredFailed,
  cancelOrderRequest,
  cancelOrderRequestSuccess,
  cancelOrderRequestFailed,
} from './reducers';

function* getAccountsSaga() {
  let action;
  try {
    const response = yield call(request, 'GET', `/api/v1/admin/accounts`);
    action = getAccountsSuccess(response);
  } catch (error) {
    action = getAccountsFailed(error.message);
  }
  yield put(action);
}

function* getJobSaga({ payload }) {
  let action;
  let url;
  if (payload?.type === 'single') {
    url = `/api/v1/jobs/${payload.id}`;
  } else {
    url = `/api/v1/jobs/type/repeatings/${payload.id}`;
  }
  try {
    const response = yield call(request, 'GET', url);
    action = getJobSuccess(response);
  } catch (error) {
    action = getJobFailed(error.message);
  }
  yield put(action);
}

function* getOrdersSaga({ payload }) {
  let action;
  let url;
  if (payload?.activeTab === 'shop') {
    url = `/api/v1/admin/orders/in_cart?`;
  } else {
    url = `/api/v1/admin/orders?order_type=${payload.activeTab}&`;
  }
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getOrdersSuccess(response);
  } catch (error) {
    action = getOrdersFailed(error.message);
  }
  yield put(action);
}

function* createOrderSaga({ payload }) {
  let action;
  try {
    const response = yield call(request, 'POST', `/api/v1/admin/adjustments`, {
      order: payload,
    });
    action = createOrderSuccess(response);
  } catch (error) {
    action = createOrderFailed(error.message);
  }
  yield put(action);
}

function* updateOrderSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/vendors/${payload.id}`,
      payload
    );
    action = updateOrderSuccess(response);
  } catch (error) {
    action = updateOrderFailed(error.message);
  }
  yield put(action);
}

function* deleteOrderSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/vendors/${payload}`
    );
    action = deleteOrderSuccess(response);
  } catch (error) {
    action = deleteOrderFailed(error.message);
  }
  yield put(action);
}

function* orderShippedSaga({ payload }) {
  const { id, tracking_numbers } = payload;
  let action;
  let path = `/api/v1/admin/orders/${id}/ship`;
  try {
    const response = yield call(request, 'PATCH', path, { tracking_numbers });
    action = orderShippedSuccess(response);
  } catch (error) {
    action = orderShippedFailed(error.message);
  }
  yield put(action);
}
function* orderDeliveredSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PATCH',
      `api/v1/admin/orders/${payload}/deliver`
    );
    action = orderDeliveredSuccess(response);
  } catch (error) {
    action = orderDeliveredFailed(error.message);
  }
  yield put(action);
}
function* cancelOrderRequestSaga({ payload }) {
  let action;
  const { id, order } = payload;
  let path = `api/v1/admin/orders/${id}/cancel`;
  try {
    const response = yield call(request, 'PATCH', path, { order });
    action = cancelOrderRequestSuccess(response);
  } catch (error) {
    action = cancelOrderRequestFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getJob, getJobSaga);
  yield takeLatest(getOrders, getOrdersSaga);
  yield takeLatest(getAccounts, getAccountsSaga);
  yield takeLatest(createOrder, createOrderSaga);
  yield takeLatest(updateOrder, updateOrderSaga);
  yield takeLatest(deleteOrder, deleteOrderSaga);
  yield takeLatest(orderShipped, orderShippedSaga);
  yield takeLatest(orderDelivered, orderDeliveredSaga);
  yield takeLatest(cancelOrderRequest, cancelOrderRequestSaga);
}
