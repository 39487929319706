import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getClients,
  getClientsSuccess,
  getClientsFailed,
  createClient,
  createClientSuccess,
  createClientFailed,
  updateClient,
  updateClientSuccess,
  updateClientFailed,
  deleteClient,
  deleteClientSuccess,
  deleteClientFailed,
  getTransactionDetails,
  getTransactionDetailsSuccess,
  getTransactionDetailsFailed,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=profile`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getClientsSaga({ payload }) {
  let action;
  let url = `/api/v1/clients/${payload.type}?`;
  try {
    if (payload['filterTabActive']) {
      url += `status=${payload['filterTabActive']}`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getClientsSuccess(response);
  } catch (error) {
    action = getClientsFailed(error.message);
  }
  yield put(action);
}

function* createClientSaga({ payload }) {
  let action;
  const { personal_user } = payload.data;
  const {
    first_name,
    image_type,
    last_name,
    mobile,
    email,
    password,
    mediaImageId,
    picture,
    status,
  } = personal_user;
  const client = {
    personal_user: {
      first_name,
      last_name,
      mobile,
      email,
      password,
      status,
    },
  };
  if (image_type === 'attached') {
    client['personal_user']['picture'] = picture;
  } else {
    client['personal_user']['attach_library_image_attributes'] = {
      library_image_id: mediaImageId,
    };
  }
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/clients/${payload.type}`,
      client
    );
    action = createClientSuccess({
      type: payload.type,
      response,
    });
  } catch (error) {
    action = createClientFailed(error.message);
  }
  yield put(action);
}

function* updateClientSaga({ payload }) {
  let action;
  try {
    let path = `/api/v1/clients/business/${payload.data.id}`;
    let url = `/api/v1/clients/${payload.type}?`;
    if (payload['filterTabActive']) {
      url += `status=${payload['filterTabActive']}`;
    }
    if (payload.type === 'personal') {
      path = `api/v1/clients/personal/${payload.clientId}/users/${payload.userId}`;
    }
    yield call(request, 'PUT', path, payload.data);
    const response = yield call(request, 'GET', url);
    action = updateClientSuccess(response);
  } catch (error) {
    action = updateClientFailed(error.message);
  }
  yield put(action);
}

function* deleteClientSaga({ payload }) {
  let action;
  let response;
  try {
    response = yield call(
      request,
      'DELETE',
      `/api/v1/clients/${payload.type}/${payload.id}`
    );
    action = deleteClientSuccess({
      type: payload.type,
      response,
    });
  } catch (error) {
    action = deleteClientFailed(error.message);
  }
  yield put(action);
}

function* getTransactionDetailsSaga({ payload }) {
  let action;
  try {
    const { accounting_transactions } = yield call(
      request,
      'GET',
      `/api/v1/admin/accounting_transactions?filter[account_id]=${payload[0].account.id}`
    );
    action = getTransactionDetailsSuccess({
      accounting_transactions,
    });
  } catch (error) {
    action = getTransactionDetailsFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getClients, getClientsSaga);
  yield takeLatest(createClient, createClientSaga);
  yield takeLatest(updateClient, updateClientSaga);
  yield takeLatest(deleteClient, deleteClientSaga);
  yield takeLatest(getTransactionDetails, getTransactionDetailsSaga);
}
