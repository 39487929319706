import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import ReportTable from './components/reportTable';
import { setTab } from './reducers';
import { connect } from 'react-redux';
import './styles.css';

const ReportsPage = props => {
  const { activeTab, setTab } = props;

  return (
    <div className="reports-page">
      <div className="page-header">
        <Row>
          <Col>
            <div className="page-title">
              <h4>Reports</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ant-tabs-nav ant-tabs-nav-animated">
              <div
                className={`ant-tabs-tab${
                  activeTab === 'customer' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setTab('customer')}
              >
                Customer
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'worker' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setTab('worker')}
              >
                Worker
              </div>

              <div
                className={`ant-tabs-tab${
                  activeTab === 'department-daily'
                    ? ' ant-tabs-tab-active '
                    : ''
                }`}
                onClick={() => setTab('department-daily')}
              >
                Department Daily
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        <ReportTable />
      </div>
    </div>
  );
};

ReportsPage.propTypes = {
  activeTab: PropTypes.string,
  setTab: PropTypes.func,
  customerDetails: PropTypes.object,
  workerDailyDetails: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    activeTab: state.reports.activeTab,
    customerDetails: state.reports.customerDetails,
    workerDailyDetails: state.reports.workerDailyDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTab: tab => dispatch(setTab(tab)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
