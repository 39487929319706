import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_COUPONS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};
const slice = createSlice({
  name: 'coupons',
  initialState: {
    loading: false,
    submitting: false,
    coupons: DEFAULT_COUPONS,
    error: null,
    errors: {},
  },
  reducers: {
    getCoupons: state => {
      state.loading = true;
    },
    getCouponsSuccess: (state, { payload }) => {
      state.loading = false;
      state.coupons = {
        data: payload.coupons,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getCouponsFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    createCoupon: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Creating a new coupon ...', 0);
    },
    createCouponSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      state.errors = {};
      const coupon = payload.coupon;
      state.coupons.data = [coupon, ...state.coupons.data];
      state.coupons.total += 1;
      notification.success({
        message: 'New Coupon',
        description: `New Coupon ${coupon.code} has been created.`,
      });
    },
    createCouponFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateCoupon: state => {
      state.submitting = true;
      state.message = message.loading('Updating coupon info...', 0);
    },
    updateCouponSucccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      const coupon = payload.coupon;
      const idx = state.coupons.data.findIndex(el => el.id === coupon.id);
      if (idx >= 0) {
        state.coupons.data[idx] = {
          ...state.coupons.data[idx],
          ...coupon,
        };
      }
      notification.success({
        message: 'Coupon Updated',
        description: `Coupon ${coupon.code} has be updated`,
      });
    },
    updateCouponFailed: (state, { payload }) => {
      state.message();
      state.submitting = true;
      apiError(payload);
    },
    deleteCoupon: state => {
      state.message = message.loading('Deleting Coupon...', 0);
    },
    deleteCouponSuccess: (state, { payload }) => {
      state.message();
      const coupon = payload.coupon;
      state.coupons.data = state.coupons.data.filter(x => x.id !== coupon.id);
      notification.success({
        message: 'Coupon Deleted',
        description: `Coupon ${coupon.code} has been deleted.`,
      });
      state.coupons.total -= 1;
      if (state.coupons.total < 0) {
        state.coupons.total = 0;
      }
    },
    deleteCouponFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
  },
});
export const {
  getCoupons,
  getCouponsFailed,
  getCouponsSuccess,
  createCoupon,
  createCouponFailed,
  createCouponSuccess,
  updateCoupon,
  updateCouponFailed,
  updateCouponSucccess,
  deleteCoupon,
  deleteCouponFailed,
  deleteCouponSuccess,
} = slice.actions;
export default slice.reducer;
