import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const state = {
  loading: false,
  submitting: false,
  admins: [],
  media_library_images: { data: [] },
  error: null,
};

const slice = createSlice({
  name: 'admin',
  initialState: state,
  reducers: {
    getMedia: state => {
      state;
    },
    getMediaSuccess: (state, { payload }) => {
      state.media_library_images = {
        data: payload.library_images,
      };
    },
    getMediaFailed: state => {
      state;
    },
    getAdmins: state => {
      state.loading = true;
    },
    getAdminsSuccess: (state, { payload }) => {
      state.loading = false;
      state.meta = payload['meta'];
      state.admins = payload['admin_users'];
    },
    getAdminsFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    createAdmin: state => {
      state.submitting = true;
      state.message = message.loading('Creating a new admin ...', 0);
    },
    createAdminSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.admins.push(payload['admin_user']);
      state.error = null;
      notification.success({
        message: 'New Admin',
        description: `New Admin ${payload['admin_user'].first_name} ${payload['admin_user'].last_name} has been created.`,
      });
    },
    createAdminFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateAdmin: state => {
      state.submitting = true;
      state.message = message.loading('Updating admin info...', 0);
    },
    updateAdminSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      const idx = state.admins.findIndex(
        el => el.id === payload['admin_user'].id
      );
      if (idx >= 0) {
        state.admins[idx] = payload['admin_user'];
      }
      notification.success({
        message: 'Admin Updated',
        description: `Admin ${payload['admin_user'].first_name} ${payload['admin_user'].last_name} has been updated.`,
      });
    },
    updateAdminFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    deleteAdmin: state => {
      state.message = message.loading('Deleting admin ...', 0);
    },
    deleteAdminSuccess: (state, { payload }) => {
      state.message();
      state.admins = state.admins.filter(x => x.id !== payload.id);
      notification.success({
        message: 'Admin Deleted',
        description: `Admin ${payload.first_name} ${payload.last_name} has been deleted.`,
      });
    },
    deleteAdminFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
  },
});

export const {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getAdmins,
  getAdminsFailed,
  getAdminsSuccess,
  createAdmin,
  createAdminSuccess,
  createAdminFailed,
  updateAdmin,
  updateAdminSuccess,
  updateAdminFailed,
  deleteAdmin,
  deleteAdminFailed,
  deleteAdminSuccess,
} = slice.actions;
export function localStore(store) {
  return store[slice.name];
}
export default slice.reducer;
