import * as Yup from 'yup';

/* Admin Form */
const CreateAdminFormValidation = Yup.object().shape({
  first_name: Yup.string().required('Enter First Name'),
  last_name: Yup.string().required('Enter Last Name'),
  mobile: Yup.string().required('Enter Mobile Number'),
  email: Yup.string().required('Enter Email Address').email(),
  password: Yup.string()
    .required('Enter Password')
    .min(6, 'Password should not be less than 6 characters.'),
  role_name: Yup.string(),
  selected_tabs: Yup.array()
    .of(Yup.string())
    .typeError('Invalid Selection(s)!'),
});
const UpdateAdminFormValidation = Yup.object().shape({
  first_name: Yup.string().required('Enter First Name'),
  last_name: Yup.string().required('Enter Last Name'),
  mobile: Yup.string().required('Enter Mobile Number'),
  email: Yup.string().required('Enter Email Address').email(),
});

/* Vendor Form */
const CreateVendorFormValidation = Yup.object().shape({
  vendor_name: Yup.string().required('Enter Vendor Name'),
  company_reg: Yup.string().required('Enter Company Registration Number'),
  contact: Yup.string().required('Enter Contact Number'),
  website: Yup.string()
    .required('Enter Website')
    .matches(/(^http[s]?:\/{2})|(^www)|(^\/{1,2})/, 'Enter valid website'),
});

/* Order Form */
const CreateOrderFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter title'),
  description: Yup.string().required('Enter description'),
  adjustment_amount: Yup.string().required('Enter adjustment amount'),
  adjustment_type: Yup.string().required('Enter adjustment type'),
  account_id: Yup.string().required('Enter account id'),
});

/* Vendor User Form */
const CreateVendorUserFormValidation = Yup.object().shape({
  first_name: Yup.string().required('Enter First Name'),
  last_name: Yup.string().required('Enter Last Name'),
  nric: Yup.string().required('Enter NRIC/FIN Number'),
  mobile: Yup.string().required('Enter Mobile Number'),
  email: Yup.string().required('Enter Email Address').email(),
  postal_code: Yup.string().required('Enter Postal Code'),
  status: Yup.string().required('Enter Status'),
  start_time: Yup.string().required('Pick Start Date'),
  end_time: Yup.string().required('Pick End Date'),
  password: Yup.string()
    .required('Enter Password')
    .min(6, 'Password should not be less than 6 characters.'),
  // selected_tabs: Yup.nullable(),
});
const UpdateVendorUserFormValidation = Yup.object().shape({
  first_name: Yup.string().required('Enter First Name'),
  last_name: Yup.string().required('Enter Last Name'),
  nric: Yup.string().required('Enter NRIC/FIN Number'),
  mobile: Yup.string().required('Enter Mobile Number'),
  email: Yup.string().required('Enter Email Address').email(),
  postal_code: Yup.string().required('Enter Postal Code'),
  status: Yup.string().required('Enter Status'),
  start_time: Yup.string().required('Pick Start Date'),
  end_time: Yup.string().required('Pick End Date'),
  password: Yup.string().min(
    6,
    'Password should not be less than 6 characters.'
  ),
  // selected_tabs: Yup.nullable(),
});

/* Personal Client Form */
const CreatePersonalClientFormValidation = Yup.object().shape({
  first_name: Yup.string().required('Enter First Name'),
  last_name: Yup.string().required('Enter Last Name'),
  mobile: Yup.string().required('Enter Mobile Number'),
  email: Yup.string().required('Enter Email Address').email(),
  password: Yup.string()
    .required('Enter Password')
    .min(6, 'Password should not be less than 6 characters.'),
});
const UpdatePersonalClientFormValidation = Yup.object().shape({
  first_name: Yup.string().required('Enter First Name'),
  last_name: Yup.string().required('Enter Last Name'),
  mobile: Yup.string().required('Enter Mobile Number'),
  email: Yup.string().required('Enter Email Address').email(),
  password: Yup.string().min(
    6,
    'Password should not be less than 6 characters.'
  ),
});

/* Business Client Form */
const CreateBusinessClientFormValidation = Yup.object().shape({
  business_name: Yup.string().required('Enter Business Name'),
  uen: Yup.string().required('Enter UEN Number'),
  website: Yup.string()
    .required('Enter Website')
    .matches(/(^http[s]?:\/{2})|(^www)|(^\/{1,2})/, 'Enter valid website'),
});

/* One Time Job Form */
const CreateOneTimeJobFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter Title'),
  vendor_id: Yup.number().required('Choose Vendor'),
  client_id: Yup.number().required('Choose Client'),
  address_id: Yup.number().required('Choose Address'),
  client_type: Yup.string().required('Choose Client Type'),
  service_date: Yup.string().required('Pick Service Date'),
});

/* Repeating (Subscription) Job Form */
const CreateSubscriptionJobFormValidation = Yup.object().shape({
  days: Yup.array().required('Choose Days'),
  days_quantity_limit: Yup.number()
    .required('Enter Days Limit')
    .typeError('Only numbers are allowed'),
  frequency_type: Yup.string().required('Choose Frequency Type'),
  frequency_value: Yup.string().required('Enter Frequency Value'),
  address_id: Yup.number().required('Choose Address'),
  client_id: Yup.number().required('Choose Client'),
  client_type: Yup.string().required('Choose Client Type'),
  title: Yup.string().required('Enter Title'),
  vendor_id: Yup.number().required('Choose Vendor'),
  start_date: Yup.string().required('Pick Start Date'),
  job_status: Yup.string().required('Choose Job Status'),
});
const UpdateSubscriptionJobFormValidation = Yup.object().shape({
  frequency_value: Yup.string().required('Enter Frequency Value'),
  frequency_type: Yup.string().required('Choose Frequency Type'),
  title: Yup.string().required('Enter Title'),
  description: Yup.string().required('Enter Description'),
  job_duration_in_minutes: Yup.number()
    .required('Enter Job Duration In Minutes')
    .typeError('Only numbers are allowed'),
  job_status: Yup.string().required('Choose Job Status'),
});

/* Category Form */
const CreateCategoryFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter Category Name'),
  category_type: Yup.string().required('Choose Category Type'),
});

/* Slide Show Form */
const CreateSlideShowFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter Slide Show Title'),
  link_url: Yup.string()
    .matches(/^(https:)/, 'Url must be https protocol')
    .required('Please enter a url'),
});

/* Product Form */
const CreateFormItemValidationSchema = Yup.object().shape({
  title: Yup.string().required('Enter Title').max(50),
  description: Yup.string().required('Enter Description'),
  unit: Yup.string().required('Enter Unit'),
  stock_count: Yup.number().typeError('Only numbers are allowed'),
  amount: Yup.number()
    .typeError('Only numbers are allowed')
    .required('Enter Amount'),
  sku: Yup.mixed(),
});
const CreateProductFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter Title').max(50),
  description: Yup.string().required('Enter Description'),
  category_id: Yup.number()
    .required('Choose Category')
    .typeError('Choose Category'),
  vendor_id: Yup.number().typeError('Only numbers are allowed'),
  selectedTags: Yup.array(),
  formItems: Yup.array()
    .of(CreateFormItemValidationSchema)
    .min(1, 'Atleast One Item is required'),
});

/* Service Form */
const CreateServiceFormItemValidationSchema = Yup.object().shape({
  title: Yup.string().required('Enter Title').max(50),
  description: Yup.string().required('Enter Description'),
});
const CreateServiceFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter Title').max(50),
  description: Yup.string().required('Enter Description'),
  category_id: Yup.number()
    .required('Choose Category')
    .typeError('Choose Category'),
  vendor_id: Yup.number().typeError('Only numbers are allowed'),
  formItems: Yup.array()
    .of(CreateServiceFormItemValidationSchema)
    .min(1, 'Atleast One Item is required'),
});

// Subscription form

const CreateSubscriptionFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter Title').max(50),
  description: Yup.string().required('Enter Description'),
  category_id: Yup.number()
    .required('Choose Category')
    .typeError('Choose Category'),
  vendor_id: Yup.number().typeError('Only numbers are allowed'),
  formItems: Yup.array()
    .of(CreateServiceFormItemValidationSchema)
    .min(1, 'Atleast One Item is required'),
});

// Class form validation
const CreateClassFormItemValidationSchema = Yup.object().shape({
  title: Yup.string().required('Enter Title').max(50),
  description: Yup.string().required('Enter Description'),
});

const CreateClassFormValidation = Yup.object().shape({
  title: Yup.string().required('Enter Title').max(50),
  description: Yup.string().required('Enter Description'),
  category_id: Yup.number()
    .required('Choose Category')
    .typeError('Choose Category'),
  vendor_id: Yup.number().typeError('Only numbers are allowed'),
  formItems: Yup.array()
    .of(CreateClassFormItemValidationSchema)
    .min(1, 'Atleast One Item is required'),
});

/* Add Personal Client Address */
const AddPersonalClientAddressFormValidation = Yup.object().shape({
  address_title: Yup.string().required('Enter Address Title'),
  address: Yup.string().required('Enter Address'),
  postal_code: Yup.string().required('Enter Postal Code'),
  unit_number: Yup.string().required('Enter Unit Number'),
});

/* Assign Worker */
const AssignWorkerFormValidation = Yup.object().shape({
  start_date: Yup.string().required('Pick Start Date'),
  end_date: Yup.string().required('Pick End Date'),
  assigned_worker_id: Yup.number().required('Choose Worker'),
  assigned_time: Yup.string().required('Assign Time'),
});

const CancelSkipFormValidation = Yup.object().shape({
  start_date: Yup.string().required('Pick Start Date'),
  end_date: Yup.string().required('Pick End Date'),
});

const PauseSubscriptionValidation = Yup.object().shape({
  start_date: Yup.string().required('Pick Start Date'),
  end_date: Yup.string().required('Pick End Date'),
});

/* Report Customer */
const ReportCustomerFormValidation = Yup.object().shape({
  client_id: Yup.number().required('Choose Client'),
  address_ids: Yup.array().required('Choose atleast one address'),
  start_date: Yup.string().required('Pick Start Date').nullable(),
  end_date: Yup.string().required('Pick End Date').nullable(),
});

/* Report Worker */
const ReportWorkerFormValidation = Yup.object().shape({
  vendor_id: Yup.number().required('Choose Vendor'),
  worker_id: Yup.number().required('Choose Worker'),
  start_date: Yup.string().required('Pick Start Date').nullable(),
  end_date: Yup.string().required('Pick End Date').nullable(),
});

/* Report Worker Daily */
const ReportWorkerDailyFormValidation = Yup.object().shape({
  vendor_id: Yup.number().required('Choose Vendor'),
  date: Yup.string().required('Pick Date').nullable(),
});

const sendSmsFormValidator = Yup.object().shape({
  mobile: Yup.number().required('Enter the valid mobile'),
  message: Yup.string().required('Enter message please'),
});

const userSettingsFormValidator = Yup.object().shape({
  sales_tax_percentage: Yup.number().required('Enter the valid GST'),
  admin_email: Yup.string().required('Enter the valid Email'),
  force_renew_subscription_topup: Yup.bool().required(
    'Please Enable or Disable renew Subscriptions'
  ),
  otp_sms_body: Yup.string()
    .matches(/%otp%/, 'Enter / Adjust the "%otp%" into the content')
    .required('Enter a valid Sms OTP'),
  pool_distance_in_miles: Yup.number().required('Enter the valid Distance'),
  currency_symbol: Yup.string().required('Enter a valid Currency'),
  referral_bonus_amount_in_cents: Yup.number().required(
    'Enter the valid amount'
  ),
});

//Vendor form validations

/* Order Form */
const CreateWithdrawalFormValidation = Yup.object().shape({
  amount: Yup.string().required('Enter withdrawal amount'),
});

export const formValidations = {
  CreateAdminFormValidation,
  UpdateAdminFormValidation,
  CreateVendorFormValidation,
  CreateOrderFormValidation,
  CreateVendorUserFormValidation,
  UpdateVendorUserFormValidation,
  CreatePersonalClientFormValidation,
  UpdatePersonalClientFormValidation,
  CreateBusinessClientFormValidation,
  CreateOneTimeJobFormValidation,
  CreateSubscriptionJobFormValidation,
  UpdateSubscriptionJobFormValidation,
  CreateCategoryFormValidation,
  CreateSlideShowFormValidation,
  CreateProductFormValidation,
  CreateServiceFormValidation,
  CreateClassFormValidation,
  AddPersonalClientAddressFormValidation,
  AssignWorkerFormValidation,
  CancelSkipFormValidation,
  ReportCustomerFormValidation,
  ReportWorkerFormValidation,
  ReportWorkerDailyFormValidation,
  CreateSubscriptionFormValidation,
  sendSmsFormValidator,
  userSettingsFormValidator,
  PauseSubscriptionValidation,

  //Vendor
  CreateWithdrawalFormValidation,
};
