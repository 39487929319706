import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import { getAccounts, getAccountsSuccess, getAccountsFailed } from './reducers';

function* getAccountsSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/company_accounts';
  try {
    const queries = {
      page: payload['page'],
      per: payload['per'],
      query: payload['query'],
    };

    const response = yield call(request, 'GET', url, queries);
    action = getAccountsSuccess(response);
  } catch (error) {
    action = getAccountsFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getAccounts, getAccountsSaga);
}
