import { takeLatest, call, put } from 'redux-saga/effects';

import {
  searchVendors,
  searchVendorsFailed,
  searchVendorsSuccess,
  searchWorkers,
  searchWorkersSuccess,
  searchWorkersFailed,
  assignJobToWorker,
  assignJobToWorkerSuccess,
  assignJobToWorkerFailed,
  reassignJobToWorker,
  reassignJobToWorkerSuccess,
  reassignJobToWorkerFailed,
  unassignJobFromWorker,
  unassignJobFromWorkerSuccess,
  unassignJobFromWorkerFailed,
  updateJob,
  updateJobSuccess,
  updateJobFailed,
  assignWorkerPerm,
  assignWorkerPermFailed,
  assignWorkerPermSuccess,
} from './reducers';
import request from '../../../utils/apisauce';
// TODO: Remove below line, code crashes when we remove it, whereas this page does not need it.
import {} from '../../../utils/helpers';

function* searchVendorsSaga({ payload }) {
  let action;
  try {
    const params = {
      service_date: payload.date,
      // name: payload.keyword,
    };

    const response = yield call(
      request,
      'GET',
      `/api/v1/vendor/vendor_board/vendor`,
      params
    );
    action = searchVendorsSuccess(response);
  } catch (error) {
    action = searchVendorsFailed(error.message);
  }
  yield put(action);
}

function* searchWorkersSaga({ payload }) {
  let action;
  try {
    const params = {
      service_date: payload.date,
      // all: true,
      // vendor_id: payload.vendor_id,
      // worker_name: payload.keyword,
    };

    const response = yield call(
      request,
      'GET',
      `api/v1/vendor/vendor_board/vendor_users?service_date=${params.service_date}`
    );
    if (
      response &&
      response['vendor_users'] &&
      response['vendor_users']['length']
    ) {
      response['vendor_users'] = response['vendor_users'].map(item => ({
        ...item,
        vendor_id: payload.vendor_id,
      }));
    }

    action = searchWorkersSuccess(response);
  } catch (error) {
    action = searchWorkersFailed(error.message);
  }

  yield put(action);
}

function* assignJobToWorkerSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      'api/v1/vendor/scheduler/assign',
      payload.body
    );
    action = assignJobToWorkerSuccess({
      response,
      workerIndex: payload.workerIndex,
    });
  } catch (error) {
    action = assignJobToWorkerFailed(error.message);
  }
  yield put(action);
}

function* reassignJobToWorkerSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      'api/v1/vendor/scheduler/assign',
      payload.body
    );
    action = reassignJobToWorkerSuccess({
      response,
      destIndex: payload.destIndex,
      srcIndex: payload.srcIndex,
    });
  } catch (error) {
    action = reassignJobToWorkerFailed(error.message);
  }
  yield put(action);
}

function* unassignJobFromWorkerSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      'api/v1/vendor/scheduler/unassign_job',
      payload.body
    );
    action = unassignJobFromWorkerSuccess({
      response,
      workerIndex: payload.workerIndex,
    });
  } catch (error) {
    action = unassignJobFromWorkerFailed(error.message);
  }
  yield put(action);
}

function* assignWorkerPermSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/jobs/type/repeatings/${payload.id}/update_jobs`,
      payload.data
    );
    action = assignWorkerPermSuccess({
      jobsList: [...response],
      startDate: payload.data.start_date,
      previousWorkerId: payload.data.previous_worker_id,
    });
  } catch (error) {
    action = assignWorkerPermFailed(error.message);
  }
  yield put(action);
}

const JobUpdateActionCheckIn = 'CHECKIN';
const JobUpdateActionCheckOut = 'CHECKOUT';
const JobUpdateActionCancel = 'CANCEL';
const JobUpdateActionSkip = 'SKIP';
const jobUpdateActionEndpoint = {
  [JobUpdateActionCheckIn]: 'check_in',
  [JobUpdateActionCheckOut]: 'check_out',
  [JobUpdateActionCancel]: 'cancel',
  [JobUpdateActionSkip]: 'skip',
};

function* updateJobSaga({ payload }) {
  let action;
  let params = {
    remove_assigned_worker_and_time: payload?.remove_assigned_worker_and_time,
  };
  if (payload.action === 'SkIP') {
    try {
      const response = yield call(
        request,
        'PUT',
        `api/v1/vendor/jobs/${payload.id}/${
          jobUpdateActionEndpoint[payload.action]
        }`
      );
      action = updateJobSuccess({ response, action: payload.action });
    } catch (error) {
      action = updateJobFailed(error.message);
    }
  } else {
    try {
      const response = yield call(
        request,
        'PUT',
        `api/v1/vendor/jobs/${payload.id}/${
          jobUpdateActionEndpoint[payload.action]
        }`,
        params
      );
      action = updateJobSuccess({
        response,
        action: payload.action,
        checkbox: payload.remove_assigned_worker_and_time,
      });
    } catch (error) {
      action = updateJobFailed(error.message);
    }
  }
  yield put(action);
}

export {
  JobUpdateActionCheckIn,
  JobUpdateActionCheckOut,
  JobUpdateActionCancel,
  JobUpdateActionSkip,
};

export default function* saga() {
  yield takeLatest(searchVendors, searchVendorsSaga);
  yield takeLatest(searchWorkers, searchWorkersSaga);
  yield takeLatest(assignJobToWorker, assignJobToWorkerSaga);
  yield takeLatest(reassignJobToWorker, reassignJobToWorkerSaga);
  yield takeLatest(unassignJobFromWorker, unassignJobFromWorkerSaga);
  yield takeLatest(assignWorkerPerm, assignWorkerPermSaga);
  yield takeLatest(updateJob, updateJobSaga);
}
