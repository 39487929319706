import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { capitalize } from '../../utils/apisauce';
// import moment from "moment";

const getColorClass = status => {
  if (status === 'pending') {
    return 'recipient-status-pending';
  } else if (status === 'delivered') {
    return 'recipient-status-delivered';
  }
};

const tableColumns = (openAnnouncementModal, openRecipientModal) => [
  {
    title: <span className="table-column-title">ID</span>,
    key: 'id',
    dataIndex: 'id',
    render: (text, record) => record['id'],
  },
  {
    title: <span className="table-column-title">Notification Title</span>,
    key: 'title',
    dataIndex: 'title',
    render: (text, record) => (
      <a
        href="#/"
        className="link"
        onClick={() =>
          openAnnouncementModal ? openAnnouncementModal(record) : null
        }
      >
        {record['title']}
      </a>
    ),
  },
  {
    title: <span className="table-column-title">Message</span>,
    dataKey: 'message',
    key: 'message',
    render: (text, record) => (
      // <p className="text-ellipses">{record['message']}</p>
      <Tooltip placement="topLeft" title={record['message']}>
        {capitalize(record['message']).substring(0, 20)}
        <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
          ...Read more
        </span>
      </Tooltip>
    ),
  },
  {
    title: <span className="table-column-title">Recipients List</span>,
    key: 'recipients_list',
    dataIndex: 'recipients_list',
    render: (text, record) => (
      <a
        href="#/"
        className="link"
        onClick={() => (openRecipientModal ? openRecipientModal(record) : null)}
      >
        View list
      </a>
    ),
  },
];

const tableRecipientColumns = () => [
  {
    title: <span className="table-column-title">Image</span>,
    key: 'client_image',
    dataIndex: 'client_image',
    render: (text, record) => (
      <Avatar size={56} src={record['client_image']} icon={<UserOutlined />} />
    ),
  },
  {
    title: <span className="table-column-title">Client Name</span>,
    key: 'full_name',
    dataIndex: 'full_name',
    render: (text, record) => record['full_name'],
  },
  {
    title: <span className="table-column-title">Email</span>,
    key: 'email',
    dataIndex: 'email',
    render: (text, record) => record['email'],
  },
  {
    title: <span className="table-column-title">Client ID</span>,
    key: 'client_id',
    render: (text, record) => record['id'],
  },
  {
    title: <span className="table-column-title">Contact</span>,
    key: 'client_contact',
    render: (text, record) => record['mobile'],
  },
  {
    title: <span className="table-column-title">Status</span>,
    key: 'status',
    dataIndex: 'status',
    render: (text, record) => (
      <span className={`${getColorClass(record['status'])}`}>
        {record['status']}
      </span>
    ),
  },
];

export { tableColumns, tableRecipientColumns };
