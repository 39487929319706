import React from 'react';
import DataTable from '../../components/DataTable';
import { orderTransactionsTableColumns } from './columns';
import PropTypes from 'prop-types';

function OrderTransactionsTable({ dataSource, pagination, loading, footer }) {
  return (
    <DataTable
      rowKey={'id'}
      loading={loading}
      data={dataSource}
      columns={orderTransactionsTableColumns()}
      onClickRow={() => {}}
      pagination={pagination}
      footer={footer}
    />
  );
}

OrderTransactionsTable.propTypes = {
  dataSource: PropTypes.array,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loading: PropTypes.bool,
  footer: PropTypes.func,
};

export default OrderTransactionsTable;
