import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getConcierge,
  getConciergeSuccess,
  getConciergeFailed,
  createConcierge,
  createConciergeSuccess,
  createConciergeFailed,
  // updateProduct,
  // updateProductSuccess,
  // updateProductFailed,
  // deleteProduct,
  // deleteProductFailed,
  // deleteProductSuccess,
} from './reducers';

function* getConciergeSaga({ payload }) {
  let action;

  let url = `/api/v1/admin/orders?order_type=concierge`;

  try {
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);

    action = getConciergeSuccess(response);
  } catch (error) {
    action = getConciergeFailed(error.message);
  }
  yield put(action);
}

function* createConciergeSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/concierge/orders`,
      {
        order: payload,
      }
    );

    action = createConciergeSuccess(response);
  } catch (error) {
    action = createConciergeFailed(error.message);
  }
  yield put(action);
}

// function* updateProductSaga({ payload }) {
//   let action;
//   try {
//     const id = payload.id;
//     delete payload.id;
//     const response = yield call(
//       request,
//       'PUT',
//       `/api/v1/admin/products/${id}`,
//       { product: payload }
//     );
//     // console.log(response, 'update what respone i get?')
//     action = updateProductSuccess(response);
//   } catch (error) {
//     action = updateProductFailed(error.message);
//   }
//   yield put(action);
// }

// function* deleteProductSaga({ payload }) {
//   let action;
//   try {
//     const response = yield call(
//       request,
//       'DELETE',
//       `/api/v1/admin/products/${payload.id}`
//     );
//     // console.log(response, 'delete what respone i get?');

//     action = deleteProductSuccess(response);
//   } catch (error) {
//     action = deleteProductFailed(error.message);
//   }
//   yield put(action);
// }

export default function* saga() {
  yield takeLatest(getConcierge, getConciergeSaga);
  yield takeLatest(createConcierge, createConciergeSaga);
  // yield takeLatest(updateProduct, updateProductSaga);
  // yield takeLatest(deleteProduct, deleteProductSaga);
}
