import React, { useState, useEffect } from 'react';
import {
  CloseOutlined,
  // DeleteFilled,
  // UserOutlined
} from '@ant-design/icons';
import { Button, Input, Form, InputNumber, Radio, Select } from 'antd';
// import PhoneInput from 'react-phone-input-2';
// import { dig } from 'digdata';
import PropTypes from 'prop-types';
// import AvatarUpload from '../../../components/AvatarUpload';
// import { getImageURI } from '../../../utils/apisauce';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
export default function CreateOrder(props) {
  const [amount, setAmount] = useState('');
  const [accountss, setAccountss] = useState([]);

  const {
    formData,
    // onDelete,
    closeModal,
    // onInputChange,
    onSubmit,
    loading,
    disabled,
    accounts,
  } = props;

  useEffect(() => {
    setAccountss(accounts);
  }, [accounts, setAccountss]);
  // console.log('actss ',accountss);

  const onChangeHandler = (value, handleChange) => {
    handleChange({
      target: {
        name: 'adjustment_type',
        value: value.target.value,
      },
    });
    setAmount(value.target.value);
  };

  return (
    <>
      <div className="drawer-header">
        <h3>{formData.id ? 'Edit Order' : 'Add Order'}</h3>
        <div className="header-actions">
          {/* {formData.id && (
            <DeleteFilled
              className="action-primary"
              onClick={() => onDelete(formData)}
            />
          )} */}
          <CloseOutlined onClick={closeModal} />
        </div>
      </div>
      <div className="drawer-body">
        <Formik
          initialValues={{
            ...formInitialValues['createOrderForm'],
            ...formData,
          }}
          validationSchema={formValidations['CreateOrderFormValidation']}
          onSubmit={values => onSubmit(values)}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Title</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['title'] && errors['title'] ? 'error' : null
                    }
                    help={touched['title'] && errors['title']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="title"
                      value={values.title}
                      placeholder="Enter title"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label> Description</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['description'] && errors['description']
                        ? 'error'
                        : null
                    }
                    help={touched['description'] && errors['description']}
                    style={{ margin: 0 }}
                  >
                    <Input.TextArea
                      value={values.description}
                      onChange={handleChange}
                      name="description"
                      placeholder="Enter description here"
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Account Id</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['account_id'] && errors['account_id']
                        ? 'error'
                        : null
                    }
                    help={touched['account_id'] && errors['account_id']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      showSearch
                      value={values.account_id}
                      onChange={value =>
                        handleChange({
                          target: {
                            name: 'account_id',
                            value,
                          },
                        })
                      }
                      filterOption={(input, option) => {
                        return option?.children
                          ? option?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          : false;
                      }}
                      placeholder="Select account id"
                      // notFoundContent={
                      //   categoryloading ? (
                      //     <Spin size="small" />
                      //   ) : null
                      // }
                      // filterOption={false}
                      // onSearch={setcategorySearchKeyword}
                      // showSearch
                      // name="account_id"
                      // style={{ width: '100%' }}
                    >
                      {accountss &&
                        accountss.map(account => {
                          return (
                            <Select.Option key={account.id} value={account.id}>
                              {account.account_holder_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group ">
                  <label>Adjustment Type</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['adjustment_type'] && errors['adjustment_type']
                        ? 'error'
                        : null
                    }
                    help={
                      touched['adjustment_type'] && errors['adjustment_type']
                    }
                    style={{ margin: 0 }}
                  >
                    <Radio.Group
                      onChange={value => onChangeHandler(value, handleChange)}
                      name={'adjustment_type'}
                      value={amount}
                    >
                      <Radio value="add_amount">Add amount</Radio>
                      <Radio value="remove_amount">Remove amount</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Adjustment Amount</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['adjustment_amount'] &&
                      errors['adjustment_amount']
                        ? 'error'
                        : null
                    }
                    help={
                      touched['adjustment_amount'] &&
                      errors['adjustment_amount']
                    }
                    style={{ margin: 0 }}
                  >
                    <InputNumber
                      value={values.adjustment_amount}
                      onChange={value =>
                        handleChange({
                          target: {
                            name: 'adjustment_amount',
                            value: value,
                          },
                        })
                      }
                      name="adjustment_amount"
                      placeholder="Enter adjustment amount"
                      min={1}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={disabled}
                >
                  Save
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

CreateOrder.propTypes = {
  formData: PropTypes.object,
  onDelete: PropTypes.func,
  closeModal: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
