import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'sendSms',
  initialState: {
    submitting: false,
    loading: false,
    sms: [],
    error: '',
  },
  reducers: {
    createSendSms: state => {
      state.submitting = true;
      state.message = message.loading('Saving User Send Sms..!', 0);
    },
    createSendSmsSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.sms = payload;
      notification.success({
        message: 'Successful',
        description: `Your SMS has been sent successfully.`,
      });
    },
    createSendSmsFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
  },
});

export const { createSendSms, createSendSmsSuccess, createSendSmsFailed } =
  slice.actions;

export default slice.reducer;
