import { CloseOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Select, Form, Checkbox } from 'antd';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AvatarUpload from '../../components/AvatarUpload';
import BusinessProfile from './components/businessProfile';
import PersonalProfile from './components/personalProfile';
import { createAddress, createUser } from './reducers';
import { Formik } from 'formik';
import { formInitialValues } from '../../utils/formsInitialValues';
import { formValidations } from '../../utils/formValidations';
import './styles.css';

class ClientProfilePage extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { type },
      },
      history: { push },
    } = props;

    if (type !== 'personal' && type !== 'business') {
      return push('/clients');
    }

    this.state = {
      activeTab: 'user',
      modalOpened: false,
      formData: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;
    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  onClickBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/admin/clients');
  };

  onCreateAddress = () => {
    return this.setState({
      modalOpened: 1,
      formData: {
        address_title: '',
        address: '',
        postal_code: '',
        unit_number: '',
        country: 'singapore',
        state: 'singapore',
        city: 'singapore',
        default: false,
      },
    });
  };

  onCreateUser = () => {
    return this.setState({
      modalOpened: 2,
      formData: {
        picture: null,
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        role: 'admin',
        status: 'active',
        password: '',
      },
    });
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
      formData: null,
    });
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  onSubmit = values => {
    const { modalOpened, formData } = this.state;
    const {
      match: {
        params: { id, type },
      },
      createAddress,
      createUser,
    } = this.props;

    if (modalOpened === 1) {
      createAddress({
        addressable_id: id,
        addressable_type:
          type === 'personal' ? 'PersonalClient' : 'BusinessClient',
        ...formData,
        ...values,
      });
    } else {
      createUser(id, { ...formData, ...values });
    }
    this.closeModal();
  };

  render() {
    const { modalOpened, formData } = this.state;
    const {
      match: {
        params: { type },
      },
    } = this.props;

    return (
      <div className="client-profile-page">
        <div className="page-header">
          <div className="page-title">
            <LeftOutlined onClick={this.onClickBack} />
            <h4>Client Details</h4>
          </div>
          <div className="page-header-actions">
            {/* {type === 'business' && (
              <Button
                type="default"
                icon={<PlusOutlined />}
                onClick={this.onCreateUser}
              >
                Add New User
              </Button>
            )} */}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.onCreateAddress}
            >
              Add New Address
            </Button>
          </div>
        </div>
        {type === 'personal' ? <PersonalProfile /> : <BusinessProfile />}
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={!!modalOpened}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>{modalOpened === 1 ? 'Add Address' : 'Add New User'}</h3>
                <div className="header-actions">
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <Formik
                  initialValues={{
                    ...formInitialValues[
                      modalOpened === 1
                        ? 'addPersonalClientAddressForm'
                        : 'createPersonalClientForm'
                    ],
                    ...formData,
                  }}
                  validationSchema={
                    formValidations[
                      modalOpened === 1
                        ? 'AddPersonalClientAddressFormValidation'
                        : 'CreatePersonalClientFormValidation'
                    ]
                  }
                  onSubmit={values => this.onSubmit(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        {modalOpened === 1 ? (
                          <>
                            <div className="form-group">
                              <label>Address Title</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['address_title'] &&
                                  errors['address_title']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['address_title'] &&
                                  errors['address_title']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="address_title"
                                  value={values.address_title}
                                  placeholder="Enter street name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Address</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['address'] && errors['address']
                                    ? 'error'
                                    : null
                                }
                                help={touched['address'] && errors['address']}
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="address"
                                  value={values.address}
                                  placeholder="e.g building name, floor"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Postal Code</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['postal_code'] &&
                                  errors['postal_code']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['postal_code'] &&
                                  errors['postal_code']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="postal_code"
                                  value={values.postal_code}
                                  placeholder="Enter postal code"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Unit Number</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['unit_number'] &&
                                  errors['unit_number']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['unit_number'] &&
                                  errors['unit_number']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="unit_number"
                                  value={values.unit_number}
                                  placeholder="Enter unit number"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <Form.Item style={{ marginTop: 10 }}>
                              <Checkbox
                                name="default"
                                checked={values.default}
                                onChange={handleChange}
                              >
                                Default Address
                              </Checkbox>
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <div className="user-avatar">
                              <AvatarUpload
                                onChange={value =>
                                  this.onInputChange({
                                    target: {
                                      name: 'picture',
                                      value: { data: value },
                                    },
                                  })
                                }
                                accept=".png, .jpg, .jpeg"
                              />
                            </div>
                            <div className="form-group">
                              <label>First Name</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['first_name'] && errors['first_name']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['first_name'] && errors['first_name']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="first_name"
                                  value={values.first_name}
                                  placeholder="Enter first name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Last Name</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['last_name'] && errors['last_name']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['last_name'] && errors['last_name']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="last_name"
                                  value={values.last_name}
                                  placeholder="Enter last name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Phone Number</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['mobile'] && errors['mobile']
                                    ? 'error'
                                    : null
                                }
                                help={touched['mobile'] && errors['mobile']}
                                style={{ margin: 0 }}
                              >
                                <PhoneInput
                                  defaultCountry={'sg'}
                                  inputStyle={{ width: '100%' }}
                                  value={values.mobile}
                                  onChange={value =>
                                    handleChange({
                                      target: { name: 'mobile', value },
                                    })
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Email</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['email'] && errors['email']
                                    ? 'error'
                                    : null
                                }
                                help={touched['email'] && errors['email']}
                                style={{ margin: 0 }}
                              >
                                <Input
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  placeholder="Enter email"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Status</label>
                              <Select
                                style={{ width: '100%' }}
                                value={values.status}
                                onChange={value =>
                                  handleChange({
                                    target: { name: 'status', value },
                                  })
                                }
                              >
                                <Select.Option value="active">
                                  Active
                                </Select.Option>
                                <Select.Option value="inactive">
                                  Inactive
                                </Select.Option>
                                <Select.Option value="suspended">
                                  Suspended
                                </Select.Option>
                              </Select>
                            </div>
                            <div className="form-group">
                              <label>Password</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['password'] && errors['password']
                                    ? 'error'
                                    : null
                                }
                                help={touched['password'] && errors['password']}
                                style={{ margin: 0 }}
                              >
                                <Input.Password
                                  name="password"
                                  value={values.password}
                                  placeholder="set new password"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        <Button type="primary" htmlType="submit" block>
                          Save
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}
        </Drawer>
      </div>
    );
  }
}

ClientProfilePage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  createUser: PropTypes.func,
  createAddress: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    submitting: state.client.submitting,
    error: state.client.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createAddress: data => dispatch(createAddress(data)),
    createUser: (id, data) => dispatch(createUser({ id, data })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfilePage);
