import React from 'react';
import { getImageURI, capitalize } from '../../../utils/apisauce';
import { dig } from 'digdata';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
  DeleteFilledIcon,
  EditFilledIcon,
  EyeFilledIcon,
} from '../../../components/Icons/Icons';

function MobileTable({ records, showDelete, showEdit, selectRow }) {
  const colorVal = {
    active: '#6ABB98',
    inactive: '#F1B13B',
    suspended: '#DF5E67',
  };
  return (
    <>
      {records.map(el => (
        <table className="mob-table" key={el.id}>
          <tr className="mob-table-row">
            <th className="mob-table-heading">ID</th>
            <td className="mob-table-data">{el?.id}</td>
          </tr>
          <tr className="mob-table-row">
            <th className="mob-table-heading">Name</th>
            <td>
              <span>
                <Avatar
                  src={getImageURI(dig(el, 'picture_path.url'))}
                  icon={<UserOutlined />}
                />
                &nbsp; {capitalize(el.first_name)} {capitalize(el.last_name)}
              </span>
            </td>
          </tr>
          <tr className="mob-table-row">
            <th className="mob-table-heading">Phone Number</th>
            <td className="mob-table-data">
              {el?.mobile ? '+' + el.mobile.replace(/\+/g, '') : '-'}
            </td>
          </tr>
          <tr className="mob-table-row">
            <th className="mob-table-heading">Email</th>
            <td className="mob-table-data">
              {el.email?.length > 23 ? (
                <Tooltip title={`${el.email}`} placement="top">
                  {capitalize(el.email).substring(0, 23)}...
                </Tooltip>
              ) : (
                el.email
              )}
            </td>
          </tr>
          <tr className="mob-table-row">
            <th className="mob-table-heading">Status</th>
            <td className="mob-table-data">
              <span
                style={{
                  color: colorVal[el.status],
                  textTransform: 'capitalize',
                }}
              >
                {el.status}
              </span>
            </td>
          </tr>
          <tr className="mob-table-row">
            <th className="mob-table-heading">Action</th>
            <td className="mob-table-data">
              <div className="ant-table-action-cell">
                <EyeFilledIcon
                  onClick={e => e.stopPropagation() || selectRow(el)}
                />
                <EditFilledIcon
                  onClick={e => e.stopPropagation() || showEdit(el)}
                />
                <DeleteFilledIcon
                  onClick={e => e.stopPropagation() || showDelete(el)}
                />
              </div>
            </td>
          </tr>
        </table>
      ))}
    </>
  );
}

export default MobileTable;
