import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorScheduler',
  initialState: {
    loadingVendors: false,
    loadingWorkers: false,
    vendors: [],
    workers: [],
    error: '',
    vendorSelected: undefined,
    loadingVendor: false,
    currentWorkerPage: 0,
    totalWorkerPages: 0,
    totalWorkers: 0,
  },
  reducers: {
    formatWorkerContent: state => {
      state.workers = [];
      state.currentWorkerPage = 0;
      state.totalWorkerPages = 0;
      state.totalWorkers = 0;
    },
    searchVendors: state => {
      state.loadingVendors = true;
    },
    searchVendorsSuccess: (state, { payload }) => {
      state.loadingVendors = false;
      state.vendorSelected = payload;
    },
    searchVendorsFailed: (state, { payload }) => {
      state.loadingVendors = false;
      state.error = payload;
      apiError(payload);
    },
    searchWorkers: state => {
      state.loadingWorkers = true;
    },
    searchWorkersSuccess: (state, { payload }) => {
      let updatedVendorUsers = [...state.workers, ...payload.vendor_users];
      updatedVendorUsers = updatedVendorUsers.map(vendorUser => {
        if (vendorUser['jobs'] && vendorUser['jobs']['length']) {
          return {
            ...vendorUser,
            jobs: vendorUser['jobs'].map(job => ({
              ...job,
              permanentReassign: false,
              permanentReassignSelectedDay: false,
              permanentlyReassignedJobsId: [],
              permanentReassignSelectedDayString: '',
            })),
          };
        } else {
          return vendorUser;
        }
      });
      return {
        ...state,
        loadingWorkers: false,
        workers: updatedVendorUsers,
        currentWorkerPage: parseInt(payload.meta.page),
        totalWorkerPages: parseInt(payload.meta.total_pages),
        totalWorkers: parseInt(payload.meta.total_count),
      };
    },
    handleOnPermanentReassignChange: (state, { payload }) => {
      const { ev, job, identifier, workerId } = payload;

      if (identifier === 'vendor-list') {
        let vendorSelected = { ...state.vendorSelected };

        vendorSelected['unassigned_jobs'] = vendorSelected[
          'unassigned_jobs'
        ].map(snap => {
          if (snap['id'] === job['id']) {
            let updatedPermanentlyReassignedJobsId = [
              ...('permanentlyReassignedJobsId' in snap
                ? snap['permanentlyReassignedJobsId']
                : []),
            ];

            if (ev['target']['checked']) {
              const idExists =
                'permanentlyReassignedJobsId' in snap
                  ? snap['permanentlyReassignedJobsId'].find(
                      id => id === job.repeating_job_id
                    )
                  : '';
              if (!idExists) {
                updatedPermanentlyReassignedJobsId = [
                  ...('permanentlyReassignedJobsId' in snap
                    ? snap['permanentlyReassignedJobsId']
                    : []),
                  job.repeating_job_id,
                ];
              }
            } else {
              updatedPermanentlyReassignedJobsId =
                'permanentlyReassignedJobsId' in snap
                  ? snap['permanentlyReassignedJobsId'].filter(
                      id => id !== job.repeating_job_id
                    )
                  : '';
            }

            return {
              ...snap,
              permanentReassign: ev['target']['checked'],
              permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
            };
          } else {
            return snap;
          }
        });

        state.vendorSelected = vendorSelected;
      } else if (identifier === 'worker-list') {
        let updatedWorkers = [...state.workers];

        const wIndex = updatedWorkers.findIndex(
          item => item['id'] === workerId
        );

        updatedWorkers[wIndex]['jobs'] = updatedWorkers[wIndex]['jobs'].map(
          snap => {
            if (snap['id'] === job['id']) {
              let updatedPermanentlyReassignedJobsId = [
                ...snap['permanentlyReassignedJobsId'],
              ];

              if (ev['target']['checked']) {
                const idExists = snap['permanentlyReassignedJobsId'].find(
                  id => id === job.repeating_job_id
                );
                if (!idExists) {
                  updatedPermanentlyReassignedJobsId = [
                    ...snap['permanentlyReassignedJobsId'],
                    job.repeating_job_id,
                  ];
                }
              } else {
                updatedPermanentlyReassignedJobsId = snap[
                  'permanentlyReassignedJobsId'
                ].filter(id => id !== job.repeating_job_id);
              }

              return {
                ...snap,
                permanentReassign: ev['target']['checked'],
                permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
              };
            } else {
              return snap;
            }
          }
        );

        state.workers = updatedWorkers;
      } else {
        return state;
      }
    },
    handleOnPermReassignSelectedDayChange: (state, { payload }) => {
      const { ev, job, identifier, workerId, currentDate } = payload;

      if (identifier === 'vendor-list') {
        let vendorSelected = { ...state.vendorSelected };

        vendorSelected['unassigned_jobs'] = vendorSelected[
          'unassigned_jobs'
        ].map(snap => {
          if (snap['id'] === job['id']) {
            let updatedPermanentlyReassignedJobsId = [
              ...('permanentlyReassignedJobsId' in snap
                ? snap['permanentlyReassignedJobsId']
                : []),
            ];
            const dayString = currentDate.format('dddd').toLowerCase();

            if (ev['target']['checked']) {
              const idExists = snap?.permanentlyReassignedJobsId?.find(
                id => id === job.repeating_job_id
              );
              if (!idExists) {
                updatedPermanentlyReassignedJobsId = [
                  ...('permanentlyReassignedJobsId' in snap
                    ? snap['permanentlyReassignedJobsId']
                    : []),
                  job.repeating_job_id,
                ];
              }
            } else {
              updatedPermanentlyReassignedJobsId =
                snap?.permanentlyReassignedJobsId
                  ? snap['permanentlyReassignedJobsId'].filter(
                      id => id !== job.repeating_job_id
                    )
                  : '';
            }

            return {
              ...snap,
              permanentReassignSelectedDay: ev['target']['checked'],
              permanentReassignSelectedDayString: ev['target']['checked']
                ? dayString
                : '',
              permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
            };
          } else {
            return snap;
          }
        });

        state.vendorSelected = vendorSelected;
      } else if (identifier === 'worker-list') {
        let updatedWorkers = [...state.workers];

        const wIndex = updatedWorkers.findIndex(
          item => item['id'] === workerId
        );

        updatedWorkers[wIndex]['jobs'] = updatedWorkers[wIndex]['jobs'].map(
          snap => {
            if (snap['id'] === job['id']) {
              let updatedPermanentlyReassignedJobsId = [
                ...snap['permanentlyReassignedJobsId'],
              ];
              const dayString = currentDate.format('dddd').toLowerCase();

              if (ev['target']['checked']) {
                const idExists = snap['permanentlyReassignedJobsId'].find(
                  id => id === job.repeating_job_id
                );
                if (!idExists) {
                  updatedPermanentlyReassignedJobsId = [
                    ...snap['permanentlyReassignedJobsId'],
                    job.repeating_job_id,
                  ];
                }
              } else {
                updatedPermanentlyReassignedJobsId = snap[
                  'permanentlyReassignedJobsId'
                ].filter(id => id !== job.repeating_job_id);
              }

              return {
                ...snap,
                permanentReassignSelectedDay: ev['target']['checked'],
                permanentReassignSelectedDayString: ev['target']['checked']
                  ? dayString
                  : '',
                permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
              };
            } else {
              return snap;
            }
          }
        );

        state.workers = updatedWorkers;
      } else {
        return state;
      }
    },
    searchWorkersFailed: (state, { payload }) => {
      state.loadingWorkers = false;
      state.error = payload;
      apiError(payload);
    },
    assignJobToWorker: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    assignJobToWorkerSuccess: (state, { payload }) => {
      state.assign();
      const vendorSelected = { ...state.vendorSelected };
      state.vendorSelected = {
        ...vendorSelected,
        unassigned_jobs: vendorSelected['unassigned_jobs'].filter(
          el => el.id !== payload.response.id
        ),
      };
      state.workers[payload.workerIndex].jobs.push({
        ...payload.response,
        permanentReassign: false,
        permanentReassignSelectedDay: false,
        permanentlyReassignedJobsId: [],
        permanentReassignSelectedDayString: '',
      });
      notification.success({
        message: 'Notice',
        description: 'Job assigned successfully',
      });
    },
    assignJobToWorkerFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    reassignJobToWorker: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    reassignJobToWorkerSuccess: (state, { payload }) => {
      state.assign();
      const idx = state.workers[payload.srcIndex].jobs.findIndex(
        el => el.id === payload.response.id
      );
      if (payload.destIndex === payload.srcIndex) {
        state.workers[payload.destIndex].jobs[idx] = {
          ...state.workers[payload.destIndex].jobs[idx],
          ...payload.response,
        };
      } else {
        state.workers[payload.srcIndex].jobs.splice(idx, 1);
        state.workers[payload.destIndex].jobs.push({
          ...payload.response,
          permanentReassign: false,
          permanentReassignSelectedDay: false,
          permanentlyReassignedJobsId: [],
          permanentReassignSelectedDayString: '',
        });
      }
      notification.success({
        message: 'Notice',
        description: 'Job reassigned successfully',
      });
    },
    reassignJobToWorkerFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    unassignJobFromWorker: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    unassignJobFromWorkerSuccess: (state, { payload }) => {
      state.assign();
      const vendorSelected = { ...state.vendorSelected };
      state.vendorSelected = {
        ...vendorSelected,
        unassigned_jobs: [
          ...vendorSelected['unassigned_jobs'],
          {
            ...payload.response,
            permanentReassign: false,
            permanentReassignSelectedDay: false,
            permanentlyReassignedJobsId: [],
            permanentReassignSelectedDayString: '',
          },
        ],
      };
      state.workers[payload.workerIndex].jobs = state.workers[
        payload.workerIndex
      ].jobs.filter(el => el.id !== payload.response.id);
      notification.success({
        message: 'Notice',
        description: 'Job unassigned successfully',
      });
    },
    unassignJobFromWorkerFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    unassignWorkerPerm: state => {
      state.submitting = true;
      state.message = message.loading('Updating job info...', 0);
    },
    unassignWorkerPermFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    assignWorkerPerm: state => {
      state.submitting = true;
      state.message = message.loading('Updating job info...', 0);
    },
    assignWorkerPermSuccess: (state, { payload }) => {
      const startDate = payload.startDate;
      const previousWorkerId = payload.previousWorkerId;
      delete payload.previousWorkerId;
      delete payload.startDate;
      const jobsArray = [...payload['jobsList']].map(job => ({
        ...job,
        permanentReassign: false,
        permanentReassignSelectedDay: false,
        permanentlyReassignedJobsId: [],
        permanentReassignSelectedDayString: '',
      }));
      state.message();
      state.submitting = false;
      const assigned_worker = jobsArray[0].assigned_worker;

      if (assigned_worker === null) {
        const filteredPayload = jobsArray.filter(
          el => el.service_date === startDate
        );
        const workerIdx = state.workers.findIndex(
          worker => worker.id === previousWorkerId
        );

        filteredPayload.forEach(el => {
          if (workerIdx >= 0) {
            state.workers[workerIdx].jobs = state.workers[
              workerIdx
            ].jobs.filter(job => job.id !== el.id);
          }
          const updatedVendors = [...state.vendors].map(vendor => {
            if (vendor['id'] === state.vendorSelected) {
              return {
                ...vendor,
                unassigned_jobs: [...vendor['unassigned_jobs'], el],
              };
            } else {
              return vendor;
            }
          });
          state.vendors = updatedVendors;
        });
      } else if (assigned_worker && previousWorkerId) {
        const filteredPayload = jobsArray.filter(
          el => el.service_date === startDate
        );
        const workerIdx = state.workers.findIndex(
          worker => worker.id === assigned_worker.id
        );
        const previousWorkerIdx = state.workers.findIndex(
          worker => worker.id === previousWorkerId
        );

        filteredPayload.forEach(el => {
          state.workers[previousWorkerIdx].jobs = state.workers[
            previousWorkerIdx
          ].jobs.filter(job => job.id !== el.id);
          if (workerIdx >= 0) {
            state.workers[workerIdx].jobs = [
              ...state.workers[workerIdx].jobs,
              el,
            ];
          }
        });
      } else {
        const filteredPayload = jobsArray.filter(
          el => el.service_date === startDate
        );
        const workerIdx = state.workers.findIndex(
          worker => worker.id === assigned_worker.id
        );

        filteredPayload.forEach(el => {
          if (workerIdx >= 0) {
            state.workers[workerIdx].jobs = [
              ...state.workers[workerIdx].jobs,
              el,
            ];
          }
          const updatedVendor = {
            ...state.vendorSelected,
            unassigned_jobs: [
              ...state.vendorSelected['unassigned_jobs'],
            ].filter(job => job.id !== el.id),
          };
          state.vendorSelected = updatedVendor;
        });
      }
      notification.success({
        message: 'Job Updated',
        description: `${jobsArray.length} Jobs have been updated.`,
      });
    },
    assignWorkerPermFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    updateJob: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    updateJobSuccess: (state, { payload }) => {
      state.assign();
      let idx = state.vendorSelected.unassigned_jobs.findIndex(
        el => el.id === payload.id
      );
      if (payload.action.toLowerCase() === 'skip') {
        let workerIndex;
        let jobIndex;
        state.workers.forEach((worker, wIndex) => {
          if (worker.jobs.length > 0) {
            worker.jobs.forEach((item, jIndex) => {
              if (item.id === payload.response.id) {
                workerIndex = wIndex;
                jobIndex = jIndex;
                state.vendorSelected.unassigned_jobs.push(payload.response);
              }
            });
          }
        });
        state.workers[workerIndex].jobs.splice(jobIndex, 1);
      } else if (
        payload.action.toLowerCase() === 'cancel' &&
        payload.checkbox
      ) {
        let workerIndex;
        let jobIndex;
        state.workers.forEach((worker, wIndex) => {
          if (worker.jobs.length > 0) {
            worker.jobs.forEach((item, jIndex) => {
              if (item.id === payload.response.id) {
                workerIndex = wIndex;
                jobIndex = jIndex;
                state.vendorSelected.unassigned_jobs.push(payload.response);
              }
            });
          }
        });
        state.workers[workerIndex].jobs.splice(jobIndex, 1);
      } else {
        if (idx >= 0) {
          state.vendorSelected.jobs[idx] = {
            ...state.vendorSelected.jobs[idx],
            ...payload.response,
          };
        }
        if (payload.response.assigned_worker?.id) {
          idx = state.workers.findIndex(
            el => el.id === payload.response.assigned_worker.id
          );
          if (idx >= 0) {
            const jobIdx = state.workers[idx].jobs.findIndex(
              el => el.id === payload.response.id
            );
            state.workers[idx].jobs[jobIdx] = {
              ...state.workers[idx].jobs[jobIdx],
              checkin_time: payload.response.checkin_time,
              checkout_time: payload.response.checkout_time,
              job_status: payload.response.job_status,
            };
          }
        }
      }
      notification.success({
        message: 'Notice',
        description: 'Job update successfully',
      });
    },
    updateJobFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
  },
});

export const {
  handleVendorSelected,
  handleVendorSelectedSucess,
  handleVendorSelectedFailure,
  handleVendorChange,
  formatWorkerContent,
  searchVendors,
  searchVendorsSuccess,
  searchVendorsFailed,
  searchWorkers,
  searchWorkersSuccess,
  searchWorkersFailed,
  assignJobToWorker,
  assignJobToWorkerSuccess,
  assignJobToWorkerFailed,
  reassignJobToWorker,
  reassignJobToWorkerSuccess,
  reassignJobToWorkerFailed,
  unassignJobFromWorker,
  unassignJobFromWorkerFailed,
  unassignJobFromWorkerSuccess,
  assignWorkerPerm,
  assignWorkerPermFailed,
  assignWorkerPermSuccess,
  unassignWorkerPerm,
  unassignWorkerPermFailed,
  unassignWorkerPermSuccess,
  updateJob,
  updateJobSuccess,
  updateJobFailed,
  handleOnPermanentReassignChange,
  handleOnPermReassignSelectedDayChange,
} = slice.actions;

export default slice.reducer;
