import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState, useLayoutEffect } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { dig } from 'digdata';
import { getImageURI } from '../../../../utils/apisauce';
import JobCard from './jobCard';
import { connect } from 'react-redux';

const getItemStyle = (isDragging, draggableStyle) => {
  if (isDragging) {
    return {
      ...draggableStyle,
      height: 55,
    };
  }

  return {
    ...draggableStyle,
    margin: '5px 0',
  };
};

const WorkerCard = ({
  worker,
  index,
  setSchedulerState,
  allowUpdate,
  currentDate,
  vendorSelected,
  vendors,
  workers,
}) => {
  const [screenDimensions, setScreenDimensions] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setScreenDimensions([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const { start_time, end_time } = worker;
  const in_time_range = (s, e, v) => {
    const start = moment(s, 'hh:mm A');
    const end = moment(e, 'hh:mm A');
    const value = moment(v, 'hh:mm A');

    return value >= start && value <= end;
  };

  const time_slots = [];
  const blockLength = Math.abs(
    Number(end_time.split(':')[0]) - Number(start_time.split(':')[0])
  );
  for (
    let start_hour = Number(start_time.split(':')[0]), i = 0;
    i <= blockLength;
    i++
  ) {
    time_slots.push({
      label: `${start_hour}:00`,
      jobs: worker.jobs.filter(el =>
        in_time_range(
          `${start_hour}:00:00`,
          `${start_hour}:59:59`,
          el.assigned_time
        )
      ),
      workerId: worker['id'],
    });
    if (start_hour >= 23) {
      start_hour = 0;
    } else {
      start_hour += 1;
    }
  }

  let vendorSelectedObj = null;

  if (vendorSelected && vendors) {
    vendorSelectedObj = vendors.find(item => item['id'] === vendorSelected);
  }

  const sum = (arr = []) => {
    return arr.length > 0 ? arr.reduce((prev, next) => prev + next) : 0;
  };

  return (
    <div className="worker-card">
      <div className="worker-card-header">
        <div className="worker-avatar">
          <Avatar
            src={getImageURI(dig(worker, 'picture_path.url'))}
            icon={<UserOutlined />}
          />
        </div>
        <div className="worker-info">
          <div className="worker-name">
            <span>
              {worker.first_name} {worker.last_name}
            </span>
            <span> · ID: {worker.id}</span>
          </div>
          <span>
            {vendorSelectedObj ? vendorSelectedObj['vendor_name'] : ''}
          </span>
        </div>
      </div>
      <div className="job-assigned">
        <div className="job-assigned-row">
          <label>Ad-Hoc Jobs Assigned</label>
          <span>
            {
              worker.jobs.filter(
                el => el.job_type === 'single' && !el.repeating_job_id
              ).length
            }
          </span>
        </div>
        <div className="job-assigned-row">
          <label>Service Duration</label>
          <span>
            {Math.floor(
              sum(worker.jobs.map(item => item.job_duration_in_minutes)) / 60
            ) +
              'h ' +
              (sum(worker.jobs.map(item => item.job_duration_in_minutes)) %
                60) +
              'm'}
          </span>
        </div>

        <div className="job-assigned-row">
          <label>Service Duration Completed</label>
          <span>
            {Math.floor(
              sum(
                worker.jobs.map(item =>
                  item.job_status === 'completed'
                    ? item.job_duration_in_minutes
                    : 0
                )
              ) / 60
            ) +
              'h ' +
              (sum(
                worker.jobs.map(item =>
                  item.job_status === 'completed'
                    ? item.job_duration_in_minutes
                    : 0
                )
              ) %
                60) +
              'm'}
          </span>
        </div>
        <div className="job-assigned-row">
          <label>Regular Jobs Assigned</label>
          <span>{worker.jobs.filter(el => el.repeating_job_id).length}</span>
        </div>
      </div>
      <div
        className={`worker-schedule ${
          screenDimensions[0] <= 576 ? 'worker-schedule--disabled' : ''
        }`}
        draggable={screenDimensions[0] > 576}
      >
        <PerfectScrollbar style={{ maxHeight: 450 }} id="areaRef">
          {time_slots.map((el, i) => (
            <div key={i} className="schedule-row">
              <label>{el.label}</label>
              <Droppable
                ignoreContainerClipping={true}
                droppableId={`${index}.${el.label}`}
              >
                {(provided, snapshot) => (
                  <div
                    className="schedule-row-content"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {snapshot.isDraggingOver && (
                      <div className="drop-preview" />
                    )}
                    {el.jobs.map(j => (
                      <Draggable
                        key={j.id}
                        draggableId={`${j.id}`}
                        index={j.id}
                        isDragDisabled={screenDimensions[0] <= 576}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {provided.placeholder}
                            {/* <JobContext.Consumer>
                              {context => ( */}
                            <JobCard
                              job={j}
                              workerId={el['workerId']}
                              allowUpdate={allowUpdate}
                              setSchedulerState={setSchedulerState}
                              dragging={snapshot.isDragging}
                              currentDate={currentDate}
                              permanentReassign={j['permanentReassign']}
                              permanentReassignSelectedDay={
                                j['permanentReassignSelectedDay']
                              }
                              // handlePermReassignSelectedDay={
                              //   context['handlePermReassignSelectedDay']
                              // }
                              // checkboxOnChange={context['checkboxOnChange']}
                              identifier="worker-list"
                              workers={workers}
                              areaRef={'areaRef'}
                            />
                            {/* )}
                            </JobContext.Consumer> */}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

WorkerCard.propTypes = {
  worker: PropTypes.object,
  index: PropTypes.number,
  setSchedulerState: PropTypes.func,
  allowUpdate: PropTypes.bool,
  currentDate: PropTypes.object,
  vendorSelected: PropTypes.number,
  vendors: PropTypes.array,
  workers: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    vendorSelected: state.vendorScheduler.vendorSelected,
    vendors: state.vendorScheduler.vendors,
    workers: state.vendorScheduler.workers,
  };
}

export default connect(mapStateToProps, null)(WorkerCard);
