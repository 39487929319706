import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { dig } from 'digdata';
const baseURL = process.env.REACT_APP_BASE_URL;

const getImageURI = imagePath => {
  if (!imagePath) {
    return undefined;
  }
  return `${baseURL}${imagePath}`;
};

const tableColumns = () => [
  {
    title: <span className="table-column-title">Client Type</span>,
    key: 'client_type',
    dataIndex: 'client_type',
    render: (text, record) => record['client_type']['text'],
  },
  {
    title: <span className="table-column-title">Image</span>,
    key: 'picture_path',
    dataIndex: 'picture_path',
    align: 'center',
    render: function avatar(text, record) {
      return (
        <Avatar
          size={56}
          src={getImageURI(dig(record, 'picture_path.url'))}
          icon={<UserOutlined />}
        />
      );
    },
  },
  {
    title: <span className="table-column-title">Client Name</span>,
    key: 'client_name',
    dataIndex: 'client_name',
    render: (text, record) => record['client_name'],
  },
  {
    title: <span className="table-column-title">Client ID</span>,
    key: 'id',
    align: 'center',
    render: (text, record) => record['id'],
  },
  {
    title: <span className="table-column-title">Contact</span>,
    key: 'mobile',
    render: (text, record) => record['mobile'],
  },
];

export default tableColumns;
