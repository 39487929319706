import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import DataTable from '../../components/DataTable';
import { orderTableColumns } from './columns';
import PropTypes from 'prop-types';

const { TabPane } = Tabs;
function OrderTable({
  dataSource,
  setModalOpen,
  pagination,
  loading,
  showjobDetailModal,
  showOrderItemsModal,
  showClientAddress,
  showOrderTransactionsModal,
  handleTableChange,
  getOrders,
  setActiveTab,
  activeTab,
  orderShipped,
  orderDelivered,
  isVendor,
  setTrackingNumberId,
  setTrackingIdModalOpen,
  cancelOrder,
  handleOrderDetails,
}) {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (activeTab === 'shop' || activeTab === 'all') {
      setTableData(dataSource);
    } else {
      const filteredData = dataSource?.filter(
        item => item.order_type === activeTab
      );
      if (filteredData?.length) {
        setTableData([...filteredData]);
      } else {
        setTableData([]);
      }
    }
  }, [dataSource, activeTab, orderShipped, orderDelivered]);

  const handleTabPaneChange = key => {
    setActiveTab(key);
    getOrders({
      page: 1,
      per: 20,
      query: null,
      activeTab: key,
    });
  };

  return (
    <>
      <Tabs activeKey={activeTab} onChange={handleTabPaneChange}>
        <TabPane tab="All" key="all" />
        {!isVendor && <TabPane tab="Top Up" key="topup" />}
        <TabPane tab="Product" key="product" />
        <TabPane tab="Services" key="service" />
        <TabPane tab="Classes" key="classes" />
        <TabPane tab="Subscription" key="subscription" />
        {!isVendor && <TabPane tab="Concierge" key="concierge" />}
        {!isVendor && <TabPane tab="Admin" key="admin" />}
      </Tabs>

      <DataTable
        rowKey={record => record['id'] || 'id'}
        loading={loading}
        data={tableData}
        columns={orderTableColumns(
          setModalOpen,
          activeTab,
          showjobDetailModal,
          showOrderItemsModal,
          showClientAddress,
          showOrderTransactionsModal,
          // orderShipped,
          orderDelivered,
          setTrackingNumberId,
          setTrackingIdModalOpen,
          isVendor,
          cancelOrder,
          handleOrderDetails
        )}
        handleTableChange={handleTableChange}
        onClickRow={() => {}}
        pagination={pagination}
        activeTab={activeTab}
      />
    </>
  );
}

OrderTable.propTypes = {
  dataSource: PropTypes.array,
  setModalOpen: PropTypes.func,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loading: PropTypes.bool,
  showjobDetailModal: PropTypes.func,
  showOrderItemsModal: PropTypes.func,
  showOrderTransactionsModal: PropTypes.func,
  handleTableChange: PropTypes.func,
  getOrders: PropTypes.func,
  setActiveTab: PropTypes.func,
  activeTab: PropTypes.string,
  orderShipped: PropTypes.func,
  orderDelivered: PropTypes.func,
};

export default OrderTable;
