import React from 'react';

import PropTypes from 'prop-types';

import { PlusOutlined } from '@ant-design/icons';

const ProductCreateFieldTitle = props => (
  <div
    className="product-create-field-title-container"
    style={{ marginBottom: 0 }}
  >
    <label>{props['label']}</label>
    <button
      className="product-create-field-title-btn"
      onClick={props['btnOnClick']}
    >
      <PlusOutlined />{' '}
      <span className="product-create-field-title-btn-text">
        {props['btnText']}
      </span>
    </button>
  </div>
);

ProductCreateFieldTitle.propTypes = {
  btnOnClick: PropTypes.func,
  btnText: PropTypes.string,
  label: PropTypes.string,
};

export default ProductCreateFieldTitle;
