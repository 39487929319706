import { LeftOutlined } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getOrders, getJob } from './reducers';
import OrderDetails from './components/order-details';
import JobDetailTable from '../../components/OrderTable/JobDetailTable';
import { Modal } from 'antd';

const OrderTableHeading = ({ title, orderId, itemCount, subStart }) => {
  return (
    <div className="order-item-table-heading-container">
      {title && <h2 className="order-item-table-heading">{title}</h2>}
      {orderId !== undefined && itemCount !== undefined && (
        <p className="order-item-table-text">{`Order ID: ${orderId} - ${itemCount} items`}</p>
      )}
      {subStart !== undefined && (
        <p className="order-item-table-text">{`Subscription start date : ${subStart}`}</p>
      )}
    </div>
  );
};

class OrderProfilePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      jobDetailModal: {
        status: false,
        records: this.props.jobs,
        orderId: '',
      },
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getOrders,
    } = this.props;
    getOrders({ id });
  }

  showjobDetailModal = data => {
    const { getJob } = this.props;

    getJob({
      id: data?.job_id ? data?.job_id : data?.repeating_job_id,
      type: data?.job_id ? 'single' : 'repeating',
    });
    this.setState(state => ({
      jobDetailModal: {
        ...state.jobDetailModal,
        orderId: data?.id,
      },
    }));
  };

  componentDidUpdate(prevProps) {
    const { jobs } = this.props;

    if (prevProps['jobs'] !== jobs && jobs?.length && jobs !== ['undefined']) {
      this.setState(state => ({
        jobDetailModal: {
          ...state.jobDetailModal,
          status: true,
          records: jobs,
        },
      }));
    }
  }

  onClickBack = () => {
    const {
      history: { push },
    } = this.props;

    push('/admin/orders');
  };

  render() {
    const { orders } = this.props;
    const { jobDetailModal } = this.state;

    const origin = window.location.origin;
    return (
      <div className="job-profile-page">
        <div className="page-header">
          <div className="page-title">
            <LeftOutlined onClick={this.onClickBack} />
            <h4>Subscription Order Details</h4>
          </div>
        </div>
        <div className="extra-header mob-tab">
          <div className="job-info">
            <div className="job-title">
              <div className="job-info-block">
                <span>Subscription Order ID: {orders.id}</span>
              </div>
            </div>
            <div className="job-other-info">
              <div className="job-info-block">
                <label style={{ textAlign: 'center' }}>Buyer</label>
                <span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${origin}/admin/clients/personal/${orders?.buyer?.personal_client_id}`}
                  >
                    {orders.buyer
                      ? `${orders.buyer.first_name} ${orders.buyer.last_name}`
                      : '-'}
                  </a>
                </span>
              </div>
              <div className="job-info-block">
                <label style={{ textAlign: 'center' }}>Placement Date</label>
                <span>
                  {orders.placement
                    ? new Date(
                        orders['placement']?.created_at
                      )?.toLocaleString()
                    : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <OrderDetails
            orders={orders}
            showjobDetailModal={this.showjobDetailModal}
          />
        </div>
        <Modal
          title={<OrderTableHeading title={'Job'} />}
          visible={jobDetailModal['status']}
          onOk={() =>
            this.setState(state => ({
              jobDetailModal: {
                ...state.jobDetailModal,
                status: false,
                records: [],
              },
            }))
          }
          onCancel={() =>
            this.setState(state => ({
              jobDetailModal: {
                ...state.jobDetailModal,
                status: false,
                records: [],
              },
            }))
          }
          width="80%"
        >
          <JobDetailTable dataSource={jobDetailModal['records']} />
        </Modal>
      </div>
    );
  }
}

OrderProfilePage.propTypes = {
  orders: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getOrders: PropTypes.func,
  jobs: PropTypes.array,
  getJob: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.orderDetail.loading,
    orders: state.orderDetail.orders,
    jobs: state.orderDetail.jobs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOrders: payload => dispatch(getOrders(payload)),
    getJob: payload => dispatch(getJob(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderProfilePage);
