import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import moment from 'moment';
import { dig } from 'digdata';

import JobMarker from './markers/jobs';
import WorkerMarker from './markers/worker';
import { renderLoadableComponent } from '../../../components/Loader';
import { getWorkerLocation, getJobs } from '../reducers';
import { getImageURI } from '../../../utils/apisauce';

// function centerXY(arr) {
//   // console.log('centerXY', arr);
//   var x = arr.map(xy => xy[0]);
//   var y = arr.map(xy => xy[1]);
//   var cx = (Math.min(...x) + Math.max(...x)) / 2;
//   var cy = (Math.min(...y) + Math.max(...y)) / 2;
//   if (isNaN(cx) || isNaN(cy)) {
//     return [0, 0];
//   }
//   return [cx, cy];
// }
function MapContainer(props) {
  const {
    workerLoading,
    jobLoading,
    workers,
    jobs,
    getWorkerLocation,
    getJobs,
  } = props;

  useEffect(() => {
    getWorkerLocation();
    getJobs();
  }, [getWorkerLocation, getJobs]);

  return (
    <div className="map-container">
      <div className="job-feed-header">
        <h4 className="job-feed-header-title">Activities Map</h4>
      </div>
      <div className="map-main">
        {renderLoadableComponent(
          workerLoading || jobLoading,
          <Map
            center={[1.29027, 103.851959]}
            // center={
            //   workers && workers['length'] && jobs && jobs['length']
            //     ? centerXY([
            //         ...workers.map(worker => {
            //           if (worker.device_location) {
            //             return [
            //               [
            //                 worker.device_location.latitude,
            //                 worker.device_location.longitude,
            //               ],
            //             ];
            //           }
            //           return [];
            //         }),
            //         ...jobs.map(job => {
            //           if (
            //             job.address &&
            //             job.address.latitude &&
            //             job.address.longitude
            //           ) {
            //             return [[job.address.latitude, job.address.longitude]];
            //           }
            //           return [];
            //         }),
            //       ])
            //     : [0, 0]
            // }
            zoom={12}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {jobs.map(renderJobMarker)}
            {workers
              .filter(worker => worker.device_location && worker.current_job)
              .map(renderWorkerMarker)}
          </Map>
        )}
      </div>
    </div>
  );
}

function renderWorkerMarker(worker) {
  const { device_location, id, current_job } = worker;
  const updatedTime = moment(device_location.updated_at);
  const currentTime = moment(
    current_job.service_date + ' ' + current_job.checkin_time,
    'YYYY-MM-DD HH:mm:ss AAA'
  );
  const duration = moment().diff(updatedTime, 'minutes', false);
  const service = moment.utc(moment().diff(currentTime)).format('HH:mm:ss');
  return (
    <WorkerMarker
      key={id}
      jobId={id}
      lat={device_location.latitude}
      lng={device_location.longitude}
      service_duration={service}
      last_update={duration}
      worker_avatar={getImageURI(dig(worker, 'picture_path.url'))}
      worker_name={worker.first_name + ' ' + worker.last_name}
      vendor_name={dig(worker, 'vendor.vendor_name')}
      client_name={dig(current_job, 'client.title')}
    />
  );
}

function renderJobMarker(job) {
  const {
    title,
    preferred_time,
    job_duration_in_minutes,
    address,
    client,
    id,
  } = job;
  const { latitude, longitude } = address;
  if (!latitude || !longitude) {
    return;
  }
  return (
    <JobMarker
      key={id}
      jobId={id}
      lat={latitude}
      lng={longitude}
      job_title={title}
      job_type={job.repeating_job_id ? 'Regular' : 'Ad-Hoc'}
      job_duration={job_duration_in_minutes}
      job_assigned_time={preferred_time}
      client_avatar={getImageURI(dig(client, 'picture_path.url'))}
      client_name={client.title}
      client_address={`${address.address}, ${address.unit_number}`}
      client_building={address.address_title}
    />
  );
}

MapContainer.propTypes = {
  workerLoading: PropTypes.bool,
  workers: PropTypes.array,
  getWorkerLocation: PropTypes.func,
  jobLoading: PropTypes.bool,
  jobs: PropTypes.array,
  getJobs: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    workerLoading: state.dashboard.getWorkerLocationLoading,
    workers: state.dashboard.workerLocation,
    jobLoading: state.dashboard.getJobsLoading,
    jobs: state.dashboard.jobs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWorkerLocation: q => dispatch(getWorkerLocation(q)),
    getJobs: q => dispatch(getJobs(q)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
