import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorJobs',
  initialState: {
    activeTab: 'all',
    loading: false,
    modalLoading: false,
    modalJob: null,
    submitting: false,
    jobs: {
      limit: 0,
      total: 0,
      page: 1,
      data: [],
    },
    error: null,
  },
  reducers: {
    setTab: (state, { payload }) => {
      if (state.activeTab !== payload) {
        state.activeTab = payload;
        state.jobs = {
          limit: 0,
          total: 0,
          page: 1,
          data: [],
        };
      }
    },
    setModalJob: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
    },
    getJobById: state => {
      state.modalLoading = true;
    },
    getJobByIdSuccess: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload['job'];
    },
    getJobByIdFailed: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
      apiError(payload);
    },
    getJobs: (state, { page }) => {
      state.loading = true;
      state.submitting = true;
      state.jobs.page = page || 1;
      state.error = null;
    },
    getJobsSuccess: (state, { payload }) => {
      state.loading = false;
      state.submitting = false;
      const { response, type } = payload;
      state.jobs.limit = response.meta['per_page'];
      state.jobs.page = parseInt(response.meta.page);
      state.jobs.total = response.meta['total_count'];
      if (type === 'repeating') {
        state.jobs.data = response['repeating_jobs'];
      } else {
        state.jobs.data = response.jobs;
      }
      state.error = null;
    },
    getJobsFailed: (state, { payload }) => {
      state.loading = false;
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    createJob: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Creating a new job ...', 0);
    },
    createJobSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const { newJob, jobs, type } = payload;
      if (type === state.activeTab) {
        state.jobs.limit = jobs.meta['per_page'];
        state.jobs.page = parseInt(jobs.meta.page);
        state.jobs.total = jobs.meta['total_count'];
        if (type === 'repeating') {
          state.jobs.data = jobs['repeating_jobs'];
        } else {
          state.jobs.data = jobs.jobs;
        }
      }

      notification.success({
        message: 'New Job',
        description: `New Job ${newJob.title || ''} has been created.`,
      });
    },
    createJobFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateJob: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Updating job info...', 0);
    },
    updateJobSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const { updatedJob, jobs } = payload;
      if (state.activeTab === 'single') {
        // state.jobs.limit = jobs.meta['per_page'];
        // state.jobs.page = parseInt(jobs.meta.page);
        // state.jobs.total = jobs.meta['total_count'];
        state.jobs.data = jobs.jobs;
      }
      if (state.activeTab === 'repeating') {
        state.jobs.data = jobs['repeating_jobs'];
        notification.success({
          message: 'Jobs Updated',
          description: `${updatedJob.length} Job(s) has been updated.`,
        });
      } else {
        notification.success({
          message: 'Job Updated',
          description: `Job ${updatedJob.title} has been updated.`,
        });
      }
    },
    updateJobFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    deleteJob: state => {
      state.message = message.loading('Deleting job ...', 0);
      state.error = null;
    },
    deleteJobSuccess: (state, { payload }) => {
      state.message();
      const { deletedJob, jobs, type } = payload;
      if (type === state.activeTab) {
        state.jobs.limit = jobs.meta['per_page'];
        state.jobs.page = parseInt(jobs.meta.page);
        state.jobs.total = jobs.meta['total_count'];
        if (type === 'repeating') {
          state.jobs.data = jobs['repeating_jobs'];
        } else {
          state.jobs.data = jobs.jobs;
        }
      }
      state.error = null;
      notification.success({
        message: 'Job Deleted',
        description: `Job ${deletedJob.id} has been deleted.`,
      });
    },
    deleteJobFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
    cancelJob: state => {
      state.message = message.loading('Cancelling Job...', 0);
      state.error = null;
    },
    cancelJobSuccess: (state, { payload }) => {
      state.message();
      const { cancelJob, jobs, type } = payload;
      if (type === state.activeTab) {
        state.jobs.limit = jobs.meta['per_page'];
        state.jobs.page = parseInt(jobs.meta.page);
        state.jobs.total = jobs.meta['total_count'];
        if (type === 'repeating') {
          state.jobs.data = jobs['repeating_jobs'];
        } else {
          state.jobs.data = jobs.jobs;
        }
      }
      state.error = null;
      notification.success({
        message: 'Job Cancelled',
        description: `Job ${cancelJob.repeating_job.id} has been schduled to cancel.`,
      });
    },
    cancelJobFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
    updateDays: state => {
      state.message = message.loading('Updating Job Days...', 0);
      state.error = null;
    },
    updateDaysSuccess: (state, { payload }) => {
      state.message();
      const { repeating_days, jobs, type } = payload;
      if (type === state.activeTab) {
        state.jobs.limit = jobs.meta['per_page'];
        state.jobs.page = parseInt(jobs.meta.page);
        state.jobs.total = jobs.meta['total_count'];
        if (type === 'repeating') {
          state.jobs.data = jobs['repeating_jobs'];
        } else {
          state.jobs.data = jobs.jobs;
        }
      }
      state.error = null;
      notification.success({
        message: 'Job Days updated',
        description: `${repeating_days.data}`,
      });
    },
    updateDaysFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
    udpateJobNotes: state => {
      state.message = message.loading('Updating Job Notes...', 0);
      state.error = null;
    },
    udpateJobNotesSuccess: (state, { payload }) => {
      state.error = null;
      state.message();
      const idx = state.jobs.data.findIndex(el => el.id === payload?.id);
      if (idx >= 0) {
        state.jobs.data[idx] = {
          ...state.jobs.data[idx],
          ...payload,
        };
      }
      notification.success({
        message: 'Job Notes updated',
      });
    },
    updateJobNotesFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
  },
});

export const {
  setTab,
  setModalJob,
  getJobs,
  getJobsFailed,
  getJobsSuccess,
  createJob,
  createJobFailed,
  createJobSuccess,
  updateJob,
  updateJobFailed,
  updateJobSuccess,
  deleteJob,
  deleteJobFailed,
  deleteJobSuccess,
  getJobById,
  getJobByIdFailed,
  getJobByIdSuccess,
  cancelJob,
  cancelJobSuccess,
  cancelJobFailed,
  updateDays,
  updateDaysSuccess,
  updateDaysFailed,
  udpateJobNotes,
  udpateJobNotesSuccess,
  updateJobNotesFailed,
} = slice.actions;

export default slice.reducer;
