import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../../pages/ReportsPage/reducers';

const { Option } = Select;

const SelectClient = props => {
  const { handleOnChange, value, customProps, clientType } = props;
  const dispatch = useDispatch();
  const { loading, clientQueryLoading, clients } = useSelector(
    store => store['reports']
  );
  let timeout = null;

  const onSearchClient = ev => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(async () => {
      dispatch(getClients({ type: clientType, query: ev, searchMode: true }));
    }, 500);
  };

  useEffect(() => {
    dispatch(getClients({ type: clientType }));
  }, [dispatch, clientType]);

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      placeholder="Search Customer Name"
      loading={loading || clientQueryLoading}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onSearch={onSearchClient}
      onChange={value =>
        handleOnChange(clients.find(client => client['id'] === value))
      }
      {...customProps}
    >
      {clients.map(client => (
        <Option key={client['id']} value={client['id']}>
          {client['title'] || client['business_name']}
        </Option>
      ))}
    </Select>
  );
};

SelectClient.propTypes = {
  value: PropTypes.number,
  clientType: PropTypes.string,
  customProps: PropTypes.object,
  handleOnChange: PropTypes.func,
  onSearchClient: PropTypes.func,
};

export default SelectClient;
