import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import {
  getProducts,
  createProduct,
  updateProduct,
  archiveProduct,
  unArchiveProduct,
  deleteProduct,
  getProductById,
  updateUI,
  setCreatedFalse,
  productbulkDelete,
  productbulkArchive,
  productbulkUnarchive,
} from './reducers';
import 'react-phone-input-2/lib/style.css';
import { TableColumns, expandedRowRender } from './components/product-table';
import DeleteProduct from './components/delete-product';
import ArchiveProduct from './components/archive-product';
import UnarchiveProduct from './components/unarchive-product';
import TableSearch from '../../components/TableSearch';
import './style.css';

const handlerEdit = (selectedRow, history, getProductById) => {
  getProductById(null);
  history.push({ pathname: `/admin/list/products/form/${selectedRow.id}` });
};

const handlerDelete = (uiState, selectedRow, deleteProduct) => {
  deleteProduct({
    ...uiState,
    selectedRow: selectedRow,
    modalOpened: 2,
  });
};

const handlerCreate = (history, getProductById) => {
  getProductById(null);
  history.push({ pathname: `/admin/list/products/form/create` });
};
export function ProductsPage({
  loading,
  products,
  getProducts,
  getProductById,
  // updateProduct,
  archiveProduct,
  unArchiveProduct,
  deleteProduct,
  uiState,
  updateUI,
  setCreatedFalse,
  productbulkDelete,
  productbulkArchive,
  productbulkUnarchive,
}) {
  const history = useHistory();
  const [queryString, updateQueryString] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterTab, setFilterTab] = useState('active');
  const { limit, total, page, data } = products;

  const onSearch = () => {
    getProducts({ query: queryString, per: limit, filterTab });
  };

  const handleTableChange = pagination => {
    getProducts({
      page: pagination['current'],
      per: pagination['pageSize'],
      query: queryString ? queryString : null,
      filterTab,
    });
  };

  const handlerArchive = selectedRow => {
    archiveProduct(selectedRow);
  };
  const handlerUnarchive = selectedRow => {
    unArchiveProduct(selectedRow);
  };

  useEffect(() => {
    setCreatedFalse();
  }, [setCreatedFalse]);
  useEffect(() => {
    const { limit, page } = products;
    getProducts({
      page: page ? page : 1,
      per: limit ? limit : 20,
      query: null,
      filterTab,
    });
  }, [getProducts]);

  const onSelectRecord = selectedRows => {
    setSelectedRows(selectedRows);
  };
  const handleRowSelect = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
    onSelectRecord(selectedRowKeys);
  };
  const bulkDeleteHandler = () => {
    productbulkDelete(selectedRows);
    setSelectedRows([]);
  };

  const bulkArchiveHandler = (uiState, selectedRow, productbulkArchive) => {
    productbulkArchive({
      ...uiState,
      selectedRow: selectedRow,
      modalOpened: 3,
    });
  };
  const bulkUnarchiveHandler = (
    uiState,
    selectedRows,
    productbulkUnarchive
  ) => {
    productbulkUnarchive({
      ...uiState,
      selectedRow: selectedRows,
      modalOpened: 4,
    });
  };

  const setActiveTab = value => {
    setFilterTab(value);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    getProducts({
      page: page ? page : 1,
      per: limit ? limit : 20,
      filterTab: value,
    });
  };

  return (
    <div className="products-page">
      <div className="page-header">
        <div className="page-title">
          <h4>Product</h4>
        </div>
        <div className="page-header-actions">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handlerCreate(history, getProductById)}
          >
            Add New Product
          </Button>
        </div>
      </div>
      <div className="page-content">
        <TableSearch
          placeholder="Search Product"
          btnText="Search"
          onBtnClick={onSearch}
          value={queryString}
          onInputChange={ev => updateQueryString(ev['target']['value'])}
        />
        <div className="page-header">
          <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    filterTab === 'active' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setActiveTab('active')}
                >
                  Active
                </div>
                <div
                  className={`ant-tabs-tab${
                    filterTab === 'archive' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setActiveTab('archive')}
                >
                  Archived
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Card>
          <div style={{ marginBottom: 16 }}>
            <Button
              className={`${
                selectedRows.length > 0 ? 'admin-products-page-btn' : ''
              }`}
              disabled={selectedRows.length === 0 ? true : false}
              onClick={() => bulkDeleteHandler(selectedRows)}
              style={{ marginRight: '5px' }}
            >
              Delete
            </Button>
            {filterTab === 'active' ? (
              <Button
                type="primary"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() =>
                  bulkArchiveHandler(uiState, selectedRows, updateUI)
                }
              >
                Archive
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() =>
                  bulkUnarchiveHandler(uiState, selectedRows, updateUI)
                }
              >
                Unachive
              </Button>
            )}
            <span style={{ marginLeft: 8 }}>
              {selectedRows.length !== 0
                ? `Selected ${selectedRows.length} items`
                : ''}
            </span>
          </div>
          <DataTable
            rowSelectionFlag={true}
            onSelectRecord={onSelectRecord}
            rowKey={record => record['id']}
            loading={loading}
            data={products.products}
            columns={TableColumns(
              record => handlerEdit(record, history, getProductById),
              record => handlerDelete(uiState, record, updateUI),
              record => handlerArchive(record),
              record => handlerUnarchive(record)
            )}
            pagination={{
              limit,
              total,
              page,
              data,
            }}
            handleTableChange={handleTableChange}
            rowSelection={{ selectedRowKeys, onChange: handleRowSelect }}
            // childrenColumnName="items"
            expandedRowRender={expandedRowRender}
          />
          <DeleteProduct
            uiState={uiState}
            updateUI={updateUI}
            deleteProduct={deleteProduct}
          />
          <ArchiveProduct
            uiState={uiState}
            updateUI={updateUI}
            productbulkArchive={productbulkArchive}
            setSelectedRows={setSelectedRows}
          />
          <UnarchiveProduct
            uiState={uiState}
            updateUI={updateUI}
            productbulkUnarchive={productbulkUnarchive}
            setSelectedRows={setSelectedRows}
          />
        </Card>
      </div>
    </div>
  );
}

ProductsPage.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.object,
  uiState: PropTypes.object,
  getProducts: PropTypes.func,
  createProduct: PropTypes.func,
  updateProduct: PropTypes.func,
  archiveProduct: PropTypes.func,
  unArchiveProduct: PropTypes.func,
  deleteProduct: PropTypes.func,
  productbulkArchive: PropTypes.func,
  productbulkUnarchive: PropTypes.func,
  getProductById: PropTypes.func,
  updateUI: PropTypes.func,
  setCreatedFalse: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.products.loading,
    products: state.products.products,
    uiState: state.products.uiState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProducts: keyword => dispatch(getProducts(keyword)),
    createProduct: data => dispatch(createProduct(data)),
    updateProduct: data => dispatch(updateProduct(data)),
    archiveProduct: data => dispatch(archiveProduct(data)),
    unArchiveProduct: data => dispatch(unArchiveProduct(data)),
    deleteProduct: id => dispatch(deleteProduct(id)),
    getProductById: id => dispatch(getProductById(id)),
    updateUI: payload => dispatch(updateUI(payload)),
    setCreatedFalse: data => dispatch(setCreatedFalse(data)),
    productbulkDelete: data => dispatch(productbulkDelete(data)),
    productbulkArchive: data => dispatch(productbulkArchive(data)),
    productbulkUnarchive: data => dispatch(productbulkUnarchive(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
