import React, { Fragment, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Col, Drawer, Row } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderLoadableComponent } from '../../../components/Loader';
import { getJobs, setModalJob, getJobById } from '../reducers';

const statusLabels = {
  pending_schedule: 'Unassigned',
  scheduled: 'In Scheduled',
  in_progress: 'In Progress',
  completed: 'Completed',
  cancelled: 'Cancelled/Skipped',
};

const JobDetailModal = props => {
  const {
    modalLoading,
    modalJob,
    jobs,
    setModalJob,
    getJobById,
    onClose,
    isOpended,
    jobId,
  } = props;

  useEffect(() => {
    if (!isNaN(jobId) && jobId !== '' && jobId !== null) {
      const job = jobs.data.find(x => x.id === jobId);
      if (job) {
        setModalJob(job);
      } else {
        getJobById(jobId);
      }
    }
  }, [jobId, getJobById, setModalJob, jobs]);

  const calculate_active_job = modalJob => {
    if (modalJob?.checkin_time && modalJob?.checkout_time) {
      const startTime = moment(modalJob?.checkin_time, 'HH:mm:ss a');
      const endTime = moment(modalJob?.checkout_time, 'HH:mm:ss a');
      const { _data } = moment.duration(endTime.diff(startTime));
      return `${_data.hours}hr ${_data.minutes}min ${_data.seconds}sec`;
    } else {
      return '-';
    }
  };
  return (
    <Drawer
      closable={false}
      className="job-detail-drawer"
      placement="right"
      width={850}
      visible={jobId && isOpended}
      onClose={e => e.stopPropagation() || onClose()}
      destroyOnClose
    >
      {renderLoadableComponent(
        modalLoading,
        modalJob && (
          <Fragment>
            <div className="drawer-header">
              <div className="header-info">
                <h3>Job Detail</h3>
                <div className="job-id">Job ID: {modalJob?.id}</div>
              </div>
              <div className="header-actions">
                <div className={`badge ${modalJob?.job_status}`}>
                  {statusLabels[modalJob?.job_status]}
                </div>
                <div className="header-actions">
                  <div
                    className={`badge ${
                      modalJob?.repeating_job_id ? 'repeating' : 'no-repeating'
                    }`}
                  >
                    {modalJob['repeating_job_id']
                      ? 'Repeating'
                      : 'Non-repeating'}
                  </div>
                </div>
                <CloseOutlined
                  onClick={e => {
                    e.stopPropagation();
                    onClose();
                  }}
                />
              </div>
            </div>
            <div className="drawer-body">
              <Row>
                <Col span={12}>
                  <label>Job Title</label>
                  <span>{modalJob?.title ? modalJob.title : '-'}</span>
                </Col>
                <Col span={12}>
                  <label>Assigned Vendor</label>
                  <span>
                    {modalJob?.vendor?.vendor_name
                      ? modalJob.vendor.vendor_name
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Scheduled Date</label>
                  <span>
                    {modalJob?.service_date
                      ? moment(modalJob.service_date, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY'
                        )
                      : ''}{' '}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Assigned Worker</label>
                  <span>
                    {modalJob['assigned_worker']
                      ? Object.keys(modalJob['assigned_worker'])?.length > 0
                        ? `${modalJob['assigned_worker'].first_name} ${modalJob['assigned_worker'].last_name}`
                        : '-'
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Preferred Time</label>
                  <span>
                    {modalJob['preferred_time'] ? modalJob.preferred_time : '-'}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Assigned Time</label>
                  <span>
                    {modalJob['assigned_time']
                      ? modalJob['assigned_time']
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Check-in Time</label>
                  <span>
                    {modalJob?.checkin_time ? modalJob.checkin_time : '-'}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Check-out Time</label>
                  <span>
                    {modalJob?.checkout_time ? modalJob.checkout_time : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Service Duration</label>
                  <span>
                    {modalJob?.job_duration_in_minutes
                      ? modalJob.job_duration_in_minutes + ' minutes'
                      : '-'}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Active Job Duration</label>
                  <span>{modalJob ? calculate_active_job(modalJob) : ''}</span>
                </Col>
              </Row>
            </div>
            <div className="drawer-footer">
              <Row>
                <Col span={12}>
                  <Row style={{ paddingTop: 0 }}>
                    <Col span={20}>
                      <label>Client Name</label>
                      <span>
                        {modalJob?.client ? modalJob.client?.title : ''}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <label>Address</label>
                      <span>
                        {modalJob?.address
                          ? `${modalJob.address?.address_title}, ${modalJob.address?.address}, ${modalJob.address?.unit_number}, ${modalJob.address.postal_code}`
                          : '-'}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row style={{ paddingTop: 0 }}>
                    <Col span={20}>
                      <label>Job Notes</label>
                      <span>
                        {modalJob['job_notes'] ? modalJob['job_notes'] : ''}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <label>Customer Notes</label>
                      <span>
                        {modalJob['customer_notes']
                          ? modalJob['customer_notes']
                          : ''}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Fragment>
        )
      )}
    </Drawer>
  );
};

JobDetailModal.propTypes = {
  modalLoading: PropTypes.bool,
  modalJob: PropTypes.object,
  jobs: PropTypes.object,
  isOpened: PropTypes.bool,
  setModalJob: PropTypes.func,
  getJobById: PropTypes.func,
  jobDetailsModalClose: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    jobs: state.jobs.jobs,
    modalLoading: state.jobs.modalLoading,
    modalJob: state.jobs.modalJob,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModalJob: job => dispatch(setModalJob(job)),
    getJobById: jobId => dispatch(getJobById(jobId)),
    getJobs: payload => dispatch(getJobs(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailModal);
