import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getVendorUsers,
  getVendorUsersSuccess,
  getVendorUsersFailed,
} from './reducers';

function* getVendorUsersSaga({ payload }) {
  let action;
  let url = `/api/v1/admin/vendor_board/vendors/${payload['vendorId']}/vendor_users?all=true?`;
  try {
    if (payload['service_date']) {
      url += `service_date=${payload['service_date']}`;
    }
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getVendorUsersSuccess(response);
  } catch (error) {
    action = getVendorUsersFailed(error.message);
  }
  yield put(action);
}

// function* createOrderSaga({ payload }) {
//   let action;
//   try {
//     const response = yield call(request, 'POST', `/api/v1/admin/vendors`, payload);
//     action = createOrderSuccess(response);
//   } catch (error) {
//     action = createOrderFailed(error.message);
//   }
//   yield put(action);
// }

// function* updateOrderSaga({ payload }) {
//   let action;
//   try {
//     const response = yield call(
//       request,
//       'PUT',
//       `/api/v1/admin/vendors/${payload.id}`,
//       payload
//     );
//     action = updateOrderSuccess(response);
//   } catch (error) {
//     action = updateOrderFailed(error.message);
//   }
//   yield put(action);
// }

// function* deleteOrderSaga({ payload }) {
//   let action;
//   try {
//     const response = yield call(
//       request,
//       'DELETE',
//       `/api/v1/admin/vendors/${payload}`
//     );
//     action = deleteOrderSuccess(response);
//   } catch (error) {
//     action = deleteOrderFailed(error.message);
//   }
//   yield put(action);
// }

export default function* saga() {
  yield takeLatest(getVendorUsers, getVendorUsersSaga);
  //   yield takeLatest(createOrder, createOrderSaga);
  //   yield takeLatest(updateOrder, updateOrderSaga);
  //   yield takeLatest(deleteOrder, deleteOrderSaga);
}
