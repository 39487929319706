import React from 'react';
import PropTypes from 'prop-types';
import {
  // Avatar,
  Modal,
} from 'antd';

DeleteModal.propTypes = {
  onClose: PropTypes.func,
  onAssign: PropTypes.func,
  visible: PropTypes.bool,
  job: PropTypes.object,
  worker: PropTypes.object,
  time: PropTypes.string,
};

function DeleteModal(props) {
  return (
    <Modal
      className="delete-user-modal"
      visible={props.visible}
      onCancel={props.onClose}
      onOk={props.onAssign}
      okText="Yes"
      destroyOnClose
    >
      {
        <>
          <div className="user-info"></div>

          <h1 className="main-message pop-up-heading">Are you sure?</h1>
          <p className="pop-up-body">
            Do you really want to assign Job ({props.job.id}) to{' '}
            {props.worker?.first_name} {props.worker?.last_name} scheduled at{' '}
            {props.time}? The process cannot be undone.
          </p>
        </>
      }
    </Modal>
  );
}

export default DeleteModal;
