import React from 'react';
import PropTypes from 'prop-types';
import {
  CloseOutlined,
  PlusOutlined,
  ProfileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Drawer,
  Radio,
  Card,
  Avatar,
  Input,
  Row,
  Select,
  Form,
} from 'antd';
import PhoneInput from 'react-phone-input-2';
// import BusinessClients from './components/businessClients';
import PersonalClients from './components/personalClients';
import AvatarUpload from '../../components/AvatarUpload';
import { getClients, getMedia, createClient, setTab } from './reducers';
import { getImageURI } from '../../utils/apisauce';
import { Formik } from 'formik';
import { formInitialValues } from '../../utils/formsInitialValues';
import { formValidations } from '../../utils/formValidations';
import TableSearch from '../../components/TableSearch';
import './styles.css';
import { connect } from 'react-redux';

class ClientPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      formData: null,
      dataSource: [],
      personalDataSource: [],
      businessDataSource: [],
      pageQuery: {
        page: 1,
        per: 20,
      },
      queryString: '',
      filterTabActive: 'active_with_mobile',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.deleteSucess) {
      this.getClientsList();
    }
  }

  getClientsList = tab => {
    const { getClients, activeTab, getMedia } = this.props;
    const { pageQuery, queryString, filterTabActive } = this.state;
    getClients({
      ...pageQuery,
      type: tab ? tab : activeTab,
      query: queryString ? queryString : null,
      filterTabActive,
    });
    getMedia();
  };

  componentDidUpdate(prevProps) {
    const { clients, activeTab, submitting, error } = this.props;

    if (clients && prevProps['clients'] !== clients) {
      if (clients?.personal_clients) {
        this.setState({ personalDataSource: clients.personal_clients });
      } else {
        this.setState({ businessDataSource: clients.business_clients });
      }
    }
    if (activeTab && prevProps['activeTab'] !== activeTab) {
      this.setState(
        {
          pageQuery: {
            page: 1,
            per: 20,
            query: null,
          },
        },
        () => this.getClientsList()
      );
    }

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  componentDidMount() {
    this.getClientsList();
  }

  onSearch = () => {
    const { activeTab, getClients, getMedia } = this.props;
    const { pageQuery, queryString, filterTabActive } = this.state;
    const updatedPageQuery = { ...pageQuery, page: 1 };

    this.setState({ pageQuery: updatedPageQuery }, () => {
      if (activeTab === 'personal') {
        getClients({
          query: queryString,
          type: 'personal',
          per: pageQuery['per'],
          filterTabActive,
        });
        getMedia();
      } else {
        getClients({
          query: queryString,
          type: 'business',
          per: pageQuery['per'],
          filterTabActive,
        });
        getMedia();
      }
    });
  };

  onClickTab = tab => {
    const { setTab } = this.props;
    setTab(tab);
  };

  openModal = () => {
    const { activeTab } = this.props;

    let formData = {
      picture: null,
      business_name: '',
      uen: '',
      phone: '',
      website: '',
    };
    if (activeTab === 'personal') {
      formData = {
        picture: null,
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        password: '',
        status: 'active',
      };
    }

    return this.setState({
      modalOpened: true,
      formData,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
      formData: null,
    });
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  handleOnSubmit = values => {
    const { formData } = this.state;
    const { activeTab, createClient } = this.props;

    const {
      business_name,
      email,
      first_name,
      last_name,
      mobile,
      password,
      uen,
      website,
      image_type,
      mediaImageId,
    } = values;

    if (activeTab === 'personal') {
      createClient({
        type: 'personal',
        data: {
          personal_user: {
            ...formData,
            first_name,
            last_name,
            mobile,
            email,
            password,
            image_type,
            mediaImageId,
          },
        },
      });
    } else {
      createClient({
        type: 'business',
        data: {
          ...formData,
          business_name,
          uen,
          website,
          image_type,
          mediaImageId,
          business_users_attributes: [],
        },
      });
    }
    // this.closeModal();
  };

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery }, () => this.getClientsList());
  };

  setActiveTab = value => {
    this.setState(state => ({ ...state, filterTabActive: value }));
    const { getClients, activeTab } = this.props;
    const { pageQuery, queryString } = this.state;
    getClients({
      ...pageQuery,
      type: activeTab,
      query: queryString ? queryString : null,
      filterTabActive: value,
    });
  };

  render() {
    const {
      formData,
      modalOpened,
      pageQuery,
      queryString,
      personalDataSource,
      // businessDataSource,
    } = this.state;
    const { activeTab, loading, metaInfo, submitting, libraryImage } =
      this.props;
    return (
      <div className="clients-page">
        <div className="page-header">
          <Row>
            <Col>
              <div className="page-title">
                <h4>Clients</h4>
              </div>
              <div className="page-header-actions">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={this.openModal}
                >
                  Add Client
                </Button>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'personal' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => this.onClickTab('personal')}
                >
                  Personal
                </div>
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'business' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => this.onClickTab('business')}
                >
                  Business
                </div>
              </div>
            </Col>
          </Row> */}
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search Client"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={queryString}
            onInputChange={ev =>
              this.setState({ queryString: ev['target']['value'] })
            }
          />
          {/* {activeTab === 'personal' ? ( */}
          <div className="page-header">
            <Row>
              <Col>
                <div className="ant-tabs-nav ant-tabs-nav-animated">
                  <div
                    className={`ant-tabs-tab${
                      this.state.filterTabActive === 'active_with_mobile'
                        ? ' ant-tabs-tab-active '
                        : ''
                    }`}
                    onClick={() => this.setActiveTab('active_with_mobile')}
                  >
                    Active With Mobile
                  </div>
                  <div
                    className={`ant-tabs-tab${
                      this.state.filterTabActive === 'active_without_mobile'
                        ? ' ant-tabs-tab-active '
                        : ''
                    }`}
                    onClick={() => this.setActiveTab('active_without_mobile')}
                  >
                    Active Without Mobile
                  </div>
                  <div
                    className={`ant-tabs-tab${
                      this.state.filterTabActive === 'inactive'
                        ? ' ant-tabs-tab-active '
                        : ''
                    }`}
                    onClick={() => this.setActiveTab('inactive')}
                  >
                    Inactive
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <PersonalClients
            loading={loading}
            dataSource={personalDataSource}
            pagination={{
              limit: pageQuery['per'],
              total: metaInfo ? metaInfo['total_count'] : 0,
              page: pageQuery['page'],
              data: personalDataSource,
              onChange: this.onPageChange,
            }}
            total_count={metaInfo?.total_count}
            filterTabActive={this.state.filterTabActive}
            handleTableChange={this.handleTableChange}
            libraryImage={libraryImage}
          />
          {/* ) : (
            <BusinessClients
              loading={loading}
              dataSource={businessDataSource}
              pagination={{
                limit: pageQuery['per'],
                total: metaInfo ? metaInfo['total_count'] : 0,
                page: pageQuery['page'],
                data: businessDataSource,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
            />
          )} */}
        </div>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={modalOpened}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Add Client</h3>
                <div className="header-actions">
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <Formik
                  initialValues={{
                    ...formInitialValues['createPersonalClientForm'],
                    ...formInitialValues['createBusinessClientForm'],
                    ...formData,
                  }}
                  validationSchema={
                    activeTab === 'personal'
                      ? formValidations['CreatePersonalClientFormValidation']
                      : formValidations['CreateBusinessClientFormValidation']
                  }
                  onSubmit={values => this.handleOnSubmit(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Radio.Group
                          defaultValue={values.image_type}
                          onChange={handleChange}
                          name="image_type"
                          buttonStyle="solid"
                          style={{ marginBottom: '10px' }}
                        >
                          <Radio.Button name="image_type" value={'attached'}>
                            Upload Image
                          </Radio.Button>
                          <Radio.Button name="image_type" value={'library'}>
                            Choose From Library
                          </Radio.Button>
                        </Radio.Group>
                        {values.image_type === 'attached' ? (
                          <div className="user-avatar">
                            <AvatarUpload
                              value={
                                formData['picture_path']
                                  ? getImageURI(formData['picture_path'])
                                  : null
                              }
                              icon={
                                activeTab === 'personal' ? (
                                  <UserOutlined />
                                ) : (
                                  <ProfileOutlined />
                                )
                              }
                              onChange={value =>
                                this.onInputChange({
                                  target: {
                                    name: 'picture',
                                    value: { data: value },
                                  },
                                })
                              }
                              accept=".png, .jpg, .jpeg"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              height: '250px',
                              overflowY: 'scroll',
                              textAlign: 'center',
                            }}
                          >
                            <Radio.Group
                              onChange={handleChange}
                              size="large"
                              // defaultValue={
                              //   formData['picture_path']?.library_image_id
                              // }
                              name="mediaImageId"
                            >
                              {libraryImage?.map(item => (
                                <Radio
                                  key={item?.id}
                                  className="radioImage"
                                  name="mediaImageId"
                                  value={item?.id}
                                >
                                  <Card>
                                    <Avatar
                                      src={getImageURI(item?.image_path?.url)}
                                      size={100}
                                    />
                                  </Card>
                                </Radio>
                              ))}
                            </Radio.Group>
                          </div>
                        )}
                        {activeTab === 'personal' ? (
                          <>
                            <div className="form-group">
                              <label>First Name</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['first_name'] && errors['first_name']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['first_name'] && errors['first_name']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="first_name"
                                  value={values.first_name}
                                  placeholder="Enter first name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Last Name</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['last_name'] && errors['last_name']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['last_name'] && errors['last_name']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="last_name"
                                  value={values.last_name}
                                  placeholder="Enter last name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Phone Number</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['mobile'] && errors['mobile']
                                    ? 'error'
                                    : null
                                }
                                help={touched['mobile'] && errors['mobile']}
                                style={{ margin: 0 }}
                              >
                                <PhoneInput
                                  defaultCountry={'sg'}
                                  inputStyle={{ width: '100%' }}
                                  value={values.mobile}
                                  onChange={value =>
                                    handleChange({
                                      target: { name: 'mobile', value },
                                    })
                                  }
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Email Address</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['email'] && errors['email']
                                    ? 'error'
                                    : null
                                }
                                help={touched['email'] && errors['email']}
                                style={{ margin: 0 }}
                              >
                                <Input
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  placeholder="Enter email address"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Status</label>
                              <Select
                                style={{ width: '100%' }}
                                value={formData.status}
                                onChange={value =>
                                  this.onInputChange({
                                    target: { name: 'status', value },
                                  })
                                }
                              >
                                <Select.Option value="active">
                                  Active
                                </Select.Option>
                                <Select.Option value="inactive">
                                  Inactive
                                </Select.Option>
                                <Select.Option value="suspended">
                                  Suspended
                                </Select.Option>
                              </Select>
                            </div>
                            <div className="form-group">
                              <label>Password</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['password'] && errors['password']
                                    ? 'error'
                                    : null
                                }
                                help={touched['password'] && errors['password']}
                                style={{ margin: 0 }}
                              >
                                <Input.Password
                                  name="password"
                                  value={values.password}
                                  placeholder="set new password"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="form-group">
                              <label>Business Name</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['business_name'] &&
                                  errors['business_name']
                                    ? 'error'
                                    : null
                                }
                                help={
                                  touched['business_name'] &&
                                  errors['business_name']
                                }
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="business_name"
                                  value={values.business_name}
                                  placeholder="Enter business name"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Registration Number (UEN)</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['uen'] && errors['uen']
                                    ? 'error'
                                    : null
                                }
                                help={touched['uen'] && errors['uen']}
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="uen"
                                  value={values.uen}
                                  placeholder="Enter registration number"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Contact Number</label>
                              <PhoneInput
                                defaultCountry={'sg'}
                                inputStyle={{ width: '100%' }}
                                value={formData.phone}
                                onChange={value =>
                                  this.onInputChange({
                                    target: { name: 'phone', value },
                                  })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label>Website</label>
                              <Form.Item
                                hasFeedback
                                validateStatus={
                                  touched['website'] && errors['website']
                                    ? 'error'
                                    : null
                                }
                                help={touched['website'] && errors['website']}
                                style={{ margin: 0 }}
                              >
                                <Input
                                  name="website"
                                  value={values.website}
                                  placeholder="Enter website"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          disabled={submitting}
                          loading={submitting}
                        >
                          Save
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}
        </Drawer>
      </div>
    );
  }
}

ClientPage.propTypes = {
  activeTab: PropTypes.string,
  getClients: PropTypes.func,
  getMedia: PropTypes.func,
  createClient: PropTypes.func,
  setTab: PropTypes.func,
  clients: PropTypes.array,
  metaInfo: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  deleteSucess: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    activeTab: state.clients.activeTab,
    loading: state.clients.loading,
    clients: state.clients.clients,
    metaInfo: state.clients.meta,
    submitting: state.clients.submitting,
    error: state.clients.error,
    deleteSucess: state.clients.deleteSucess,
    libraryImage: state.clients.media_library_images?.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTab: tab => dispatch(setTab(tab)),
    getClients: payload => dispatch(getClients(payload)),
    getMedia: () => dispatch(getMedia()),
    createClient: data => dispatch(createClient(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientPage);
