import { takeLatest, call, put } from 'redux-saga/effects';

import {
  getPersonalRecipientsList,
  getPersonalRecipientsListSuccess,
  getPersonalRecipientsListFailed,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailed,
  sendNotification,
  sendNotificationSuccess,
  sendNotificationFailed,
} from './reducers';
import request from '../../utils/apisauce';

function* getPersonalRecipientsListSaga(payload) {
  let action;
  let url = `/api/v1/clients/personal?status=active_with_mobile&per=100`;
  if (payload.payload?.page) {
    url += `&page=${payload.payload['page']}`;
  }
  if (payload.payload?.query) {
    url += `&query=${payload.payload['query']}`;
  }
  try {
    const response = yield call(request, 'GET', url);
    action = getPersonalRecipientsListSuccess({
      personal_clients: response['personal_clients'].map(item => {
        if (item['personal_users'] && item['personal_users']['length']) {
          return { ...item['personal_users'][0], id: item['id'] };
        }
        return {
          account: {},
          birthday: null,
          deleted_at: null,
          email: 'user@not.found',
          fcm: null,
          first_name: 'No User',
          full_name: 'Client no user',
          id: item.id,
          image_type: 'library',
          last_activity_at: null,
          last_name: 'no found',
          mobile: '+00000000',
          personal_client_id: item.id,
          picture_path: null,
          referral_code: '000000000',
          status: 'active',
          stripe_customer_id: null,
          user_type: 'PersonalUser',
        };
      }),
      meta: response.meta,
      query: payload.payload?.query ? true : false,
    });
    // action = getPersonalRecipientsListSuccess(response);
  } catch (error) {
    action = getPersonalRecipientsListFailed(error.message);
  }
  yield put(action);
}

function* getNotificationsSaga({ payload }) {
  let action;

  let url = `/api/v1/admin/notifications?`;
  if (payload?.page) {
    url += `page=${payload?.page}`;
  }
  if (payload?.per) {
    url += `&per=${payload?.per}`;
  }

  try {
    const response = yield call(request, 'GET', url);
    action = getNotificationsSuccess(response);
  } catch (error) {
    action = getNotificationsFailed(error.message);
  }
  yield put(action);
}

function* sendNotificationSaga({ payload }) {
  let action;

  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/notifications`,
      payload
    );
    action = sendNotificationSuccess(response);
  } catch (error) {
    action = sendNotificationFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getPersonalRecipientsList, getPersonalRecipientsListSaga);
  yield takeLatest(getNotifications, getNotificationsSaga);
  yield takeLatest(sendNotification, sendNotificationSaga);
}
