import { Avatar, Upload, message } from 'antd';
import { dig } from 'digdata';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import ImgCrop from 'antd-img-crop';

class ImageUpload extends React.Component {
  state = {
    value: this.props.value,
    localValue: null,
  };

  componentDidUpdate(prevProps) {
    const value = dig(this.props, 'value');
    if (
      typeof value === 'string' &&
      value.startsWith('http') &&
      this.state.value !== value
    ) {
      this.props.onChange(undefined);
      this.setState({ value });
    }

    if (prevProps.value !== this.props?.value) {
      this.setState({
        value: this.props.value,
        localValue: null,
      });
    }
  }

  handleRequest = ({ file }) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(reader.result);
      }
      this.setState({
        loading: false,
        localValue: reader.result,
      });
    });
    reader.readAsDataURL(file);
  };
  handleCrop = file => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 5) {
      message.error('File size must be lesser than 2 MB');
      return;
    }
    return true;
  };
  render() {
    const { value, localValue } = this.state;
    const {
      accept,
      // selected
    } = this.props;
    const { error } = this.props;

    let uploadProps = {
      className: 'image-uploader',
      name: 'image',
      listType: 'picture-card',
      showUploadList: false,
      customRequest: this.handleRequest,
    };

    if (accept) {
      uploadProps['accept'] = accept;
    }
    return (
      <>
        <ImgCrop
          aspect={
            this.props?.aspectRatio === undefined
              ? 1 / 1
              : this.props?.aspectRatio
          }
          beforeCrop={this.handleCrop}
        >
          <Upload
            {...uploadProps}
            style={{ height: '0px' }}
            className={`${error?.image === 'Upload' && 'error'}`}
            // disabled={selected === 'single'}
          >
            <Avatar
              icon={<PlusOutlined />}
              shape="square"
              src={localValue || value}
              size={100}
              style={{
                color: '#d3d3d3',
                backgroundColor: '#fff',
              }}
            />
          </Upload>
        </ImgCrop>
        {error?.image === 'Upload' && (
          <div role="alert" className="image-alert-message">
            {'Select Image'}
          </div>
        )}
      </>
    );
  }
}

ImageUpload.propTypes = {
  value: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.string,
  error: PropTypes.any,
};

export default ImageUpload;
