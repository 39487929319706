import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const closeModal = (uiState, updateUI) => {
  updateUI({
    ...uiState,
    selectedRow: null,
    modalOpened: false,
  });
};
const performUnarchive = (selectedRow, classbulkUnarchive, setSelectedRows) => {
  classbulkUnarchive(selectedRow);
  setSelectedRows([]);
};

const UnarchiveClass = ({
  uiState,
  updateUI,
  classbulkUnarchive,
  setSelectedRows,
}) => {
  const { modalOpened, selectedRow } = uiState;
  return (
    <Modal
      className="delete-user-modal"
      visible={modalOpened === 4}
      onCancel={() => closeModal(uiState, updateUI)}
      onOk={() =>
        performUnarchive(selectedRow, classbulkUnarchive, setSelectedRows)
      }
      okText="Yes"
      destroyOnClose
    >
      {selectedRow && (
        <>
          <p className="main-message">
            Are you sure want to unarchive classes?
          </p>
        </>
      )}
    </Modal>
  );
};

UnarchiveClass.propTypes = {
  uiState: PropTypes.object,
  updateUI: PropTypes.object,
  classbulkUnarchive: PropTypes.func,
};

export default UnarchiveClass;
