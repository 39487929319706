import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getVendor,
  getVendorSuccess,
  getVendorFailed,
  createUser,
  createUserSuccess,
  createUserFailed,
  updateUser,
  updateUserSuccess,
  updateUserFailed,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailed,
  getWithdrawals,
  getWithdrawalsSuccess,
  getWithdrawalsFailed,
  withdrawalRequestAction,
  withdrawalRequestActionSuccess,
  withdrawalRequestActionFailed,
  getVendorUsers,
  getVendorUsersSuccess,
  getVendorUsersFailed,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=profile`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getVendorSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/vendors/${payload}`
    );
    action = getVendorSuccess(response['vendor']);
  } catch (error) {
    action = getVendorFailed(error.message);
  }
  yield put(action);
}

function* getVendorUsersSaga({ payload }) {
  let action;
  let response;
  try {
    response = yield call(
      request,
      'GET',
      `/api/v1/admin/vendors/users?status=${payload.activeTab}&vendor_id=${payload.id}`
    );
    action = getVendorUsersSuccess(response);
  } catch (error) {
    action = getVendorUsersFailed(error.message);
  }
  yield put(action);
}

function* createUserSaga({ payload }) {
  let action;
  try {
    let response;
    yield call(request, 'POST', `api/v1/admin/vendors/users`, payload);
    if (payload?.activeTab === 'all' || !payload.activeTab) {
      response = yield call(
        request,
        'GET',
        `/api/v1/admin/vendors/users?vendor_id=${payload.vendor_id}`
      );
    } else {
      response = yield call(
        request,
        'GET',
        `/api/v1/admin/vendors/users?status=${payload.activeTab}&vendor_id=${payload.vendor_id}`
      );
    }
    action = createUserSuccess(response);
  } catch (error) {
    action = createUserFailed(error.message);
  }
  yield put(action);
}

function* updateUserSaga({ payload }) {
  let action;
  try {
    let response;
    yield call(
      request,
      'PUT',
      `/api/v1/admin/vendors/users/${payload.vendor_user.id}`,
      payload
    );
    if (payload?.activeTab === 'all' || !payload.activeTab) {
      response = yield call(
        request,
        'GET',
        `/api/v1/admin/vendors/users?vendor_id=${payload.vendor_id}`
      );
    } else {
      response = yield call(
        request,
        'GET',
        `/api/v1/admin/vendors/users?status=${payload.activeTab}&vendor_id=${payload.vendor_id}`
      );
    }
    action = updateUserSuccess(response);
  } catch (error) {
    action = updateUserFailed(error.message);
  }
  yield put(action);
}

function* deleteUserSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/vendors/users/${payload.userId}?vendor_id=${payload.vendorId}`
    );
    action = deleteUserSuccess(response);
    yield put(action);
  } catch (error) {
    action = deleteUserFailed(error.message);
    yield put(action);
  }

  let action2;
  try {
    let response2;
    response2 = yield call(
      request,
      'GET',
      `/api/v1/admin/vendors/users?status=${payload.activeTab}&vendor_id=${payload.vendorId}`
    );
    action2 = getVendorUsersSuccess(response2);
    yield put(action2);
  } catch (error) {
    action2 = getVendorUsersFailed(error.message);
    yield put(action2);
  }
}

function* getWithdrawalsSaga() {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/withdrawal_requests`
    );
    action = getWithdrawalsSuccess(response);
  } catch (error) {
    action = getWithdrawalsFailed(error.message);
  }
  yield put(action);
}

function* withdrawalRequestActionSaga({ payload }) {
  let action;
  const status = {
    withdrawal_request: {
      status: payload.status,
    },
  };
  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/withdrawal_requests/${payload.id}`,
      status
    );
    action = withdrawalRequestActionSuccess(response);
  } catch (error) {
    action = withdrawalRequestActionFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getVendor, getVendorSaga);
  yield takeLatest(getVendorUsers, getVendorUsersSaga);
  yield takeLatest(createUser, createUserSaga);
  yield takeLatest(updateUser, updateUserSaga);
  yield takeLatest(deleteUser, deleteUserSaga);
  yield takeLatest(getWithdrawals, getWithdrawalsSaga);
  yield takeLatest(withdrawalRequestAction, withdrawalRequestActionSaga);
}
