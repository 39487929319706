import { Card, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
// import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { getTransactions } from '../reducers';
import DataTable from '../../../components/DataTable';
import { capitalize } from '../../../utils/apisauce';
import { groupTransactions } from '../../../utils/helpers';
import { IdcardOutlinedIcon } from '../../../components/Icons/Icons';
import OrdersTable from '../OrdersTable/OrdersTable';
import Modal from 'antd/lib/modal/Modal';

const ColumnLink = ({ href, onClick, text }) => (
  <a href={href} className="link" onClick={onClick}>
    {text}
  </a>
);

ColumnLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.number,
};
function All() {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [orderModal, updateOrderModal] = useState({
    status: false,
    record: [],
    orderId: '',
  });
  // const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { loading, transactions } = useSelector(state => state.transactions);
  const { data } = transactions;
  let groupedData = [...data];
  groupedData = groupTransactions(groupedData);

  useEffect(() => {
    dispatch(getTransactions({ page: page }));
  }, [dispatch, page]);

  const onClickIcon = () => {};

  const showOrderModal = order => {
    updateOrderModal({
      status: true,
      record: [order],
      orderId: order['id'],
    });
  };

  const tableColumns = () => [
    {
      title: <span className="table-column-title">Date</span>,
      key: 'created_at',
      fixed: 'left',
      render: (text, record) =>
        record['created_at']
          ? `${new Date(record['created_at']).toLocaleString()}`
          : '',
    },
    {
      title: <span className="table-column-title">ID</span>,
      key: 'trx_id',
      dataIndex: 'trx_id',
      render: (text, record) => record['trx_id'],
    },
    {
      title: <span className="table-column-title">Amount</span>,
      key: 'formatted_amount',
      render: function amountFunc(text, record) {
        return (
          <Tooltip
            title={`${record?.amount > 0 ? 'debit' : 'credit'}`}
            placement="top"
            className={`${record?.amount > 0 ? 'debit' : 'credit'}`}
          >
            {record['formatted_amount']
              ? `${
                  record?.amount > 0
                    ? `${capitalize(record['formatted_amount'])}`
                    : `(${capitalize(
                        record['formatted_amount'].replace('-', '')
                      )})`
                }`
              : '-'}
          </Tooltip>
        );
      },
    },
    {
      title: <span className="table-column-title">From Account</span>,
      key: 'from_account',
      render: (text, record) =>
        record['transfer']
          ? capitalize(record['transfer']?.from_account.accountable.full_name)
          : '-',
    },
    {
      title: <span className="table-column-title">To Account</span>,
      key: 'to_account',
      render: (text, record) =>
        record['transfer']
          ? capitalize(record['transfer']?.to_account.accountable.full_name)
          : '-',
    },
    {
      title: <span className="table-column-title">Description</span>,
      key: 'description',
      dataIndex: 'description',
      render: description => (
        <Tooltip placement="topLeft" title={description}>
          {capitalize(description).substring(0, 20)}
          <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
            ...Read more
          </span>
        </Tooltip>
      ),
    },

    {
      title: <span className="table-column-title">Client Name</span>,
      key: 'order',
      dataIndex: 'order',
      width: 150,
      render: function render(order) {
        return (
          <span>
            {`${
              order?.buyer?.first_name
                ? capitalize(order.buyer.first_name)
                : '-'
            } 
            ${
              order?.buyer?.last_name ? capitalize(order.buyer.last_name) : ''
            }`}
          </span>
        );
      },
    },
    {
      title: <span className="table-column-title">Order Type</span>,
      key: 'order_type',
      dataIndex: 'order_type',
      render: (text, record) => capitalize(record?.order?.order_type),
    },
    {
      title: <span className="table-column-title">Order ID</span>,
      key: 'orderId',
      align: 'center',
      render: function orderItems(text, record) {
        return (
          <ColumnLink
            href="#/"
            onClick={() => showOrderModal(record.order)}
            text={record['order'] ? record['order'].id : '-'}
          />
        );
      },
    },
    {
      title: <span className="table-column-title">Action</span>,
      key: 'action',
      fixed: 'right',
      align: 'center',
      render: function actionsFunc(text, record) {
        return (
          <div
            className="ant-table-action-cell"
            style={{ justifyContent: 'center' }}
          >
            <Tooltip
              title={`${record.stripe_charge_id} / ${record.stripe_invoice_id}`}
            >
              <IdcardOutlinedIcon
                onClick={e => e.stopPropagation() || onClickIcon(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // const getColorClass = status => {
  //   if (status === 'debit') {
  //     return 'tran-type-debit';
  //   } else if (status === 'credit') {
  //     return 'tran-type-credit';
  //   } else if (status === 'success') {
  //     return 'tran-status-success';
  //   }
  // };

  // const onClickRow = (_, { id }) => {
  const onClickRow = () => {
    // const { history, jobDetailsModalOpen } = this.props;
    // history.push('#' + id);
    // jobDetailsModalOpen();
  };

  // const closeModal = () => {
  //     const { history } = props;
  //     history.goBack();
  // };

  const onPageChange = newPage => {
    setPage(prevState => ({ ...prevState, page: newPage }));
  };

  const handleTableChange = pagination => {
    setLimit(pagination['pageSize']);
    setPage(pagination['current']);
  };

  return (
    <>
      <Card title="All" className="overflow-x-auto" style={{ marginTop: 15 }}>
        <DataTable
          rowKey={record => record['trx_id']}
          loading={loading}
          pagination={{
            limit,
            total: groupedData.length,
            page,
            data: groupedData,
            onChange: onPageChange,
          }}
          onClickRow={onClickRow}
          columns={tableColumns()}
          handleTableChange={handleTableChange}
        />
      </Card>
      <Modal
        title={'Orders'}
        visible={orderModal['status']}
        onOk={() => updateOrderModal({ status: false, record: [] })}
        onCancel={() => updateOrderModal({ status: false, record: [] })}
        width="80%"
      >
        <OrdersTable dataSource={orderModal['record']} />
      </Modal>
    </>
  );
}

All.propTypes = {
  loading: PropTypes.bool,
  jobs: PropTypes.object,
  getJobs: PropTypes.func,
  history: PropTypes.object,
  queryString: PropTypes.string,
  jobDetailsModalOpen: PropTypes.func,
};

export default withRouter(All);
