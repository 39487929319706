import React, { useState /* useEffect,  useState*/ } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
// import DataTable from '../../components/DataTable';
import { Button, Row, Col } from 'antd';
import ConciergeHistory from './components/concierge-history';
import Iframe from 'react-iframe';
import { PlusOutlined } from '@ant-design/icons';
import {
  // getProducts,
  createConcierge,
  getProductById,
  // updateUI,
} from './reducers';
import 'react-phone-input-2/lib/style.css';
// import TableColumns from './components/concierge-table';
// import DeleteService from './components/delete-concierge';

// const handlerEdit = (selectedRow, history, getProductById) => {
//   getProductById(null);
//   history.push({ pathname: `/concierge/form/${selectedRow.id}` });
// };

// const handlerDelete = (uiState, selectedRow, deleteProduct) => {
//   deleteProduct({
//     ...uiState,
//     selectedRow: selectedRow,
//     modalOpened: 2,
//   });
// };

const handlerCreate = (history, getProductById) => {
  getProductById(null);
  history.push({ pathname: `/admin/concierge/form/create` });
};
export function ConciergePage(/* {
  // loading,
  // products,
  // getProducts,
  // getProductById,
  // updateProduct,
  // deleteProduct,
  // history,
  // uiState,
  // updateUI,
  // currentUserId,
} */) {
  // const { limit, total, data } = products;
  // const [pageQuery, setPage] = useState({
  //   page: products.page,
  //   query: null,
  // });

  // const onPageChange = newPage => setPage(old => ({ ...old, page: newPage }));
  // const onSearch = query => setPage({ query: query, page: 1 });
  const history = useHistory();
  const [activeTab, setTab] = useState('channel');

  // useEffect(() => {
  //   getProducts(pageQuery);
  // }, [getProducts, pageQuery]);

  // const { page } = pageQuery;
  return (
    <div className="jobs-page">
      <div className="page-header">
        <Row>
          <Col>
            <div className="page-title">
              <h4>Concierge</h4>
            </div>
            <div className="page-header-actions">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => handlerCreate(history, getProductById)}
              >
                Add New Concierge Order
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ant-tabs-nav ant-tabs-nav-animated">
              <div
                className={`ant-tabs-tab${
                  activeTab === 'channel' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setTab('channel')}
              >
                Channel
              </div>
              <div
                className={`ant-tabs-tab${
                  activeTab === 'history' ? ' ant-tabs-tab-active ' : ''
                }`}
                onClick={() => setTab('history')}
              >
                History
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        {activeTab === 'channel' ? (
          <Iframe
            src="https://live-290.wati.io/teamInbox"
            width="100%"
            height="650px"
            allowFullScreen
            position="relative"
          />
        ) : (
          <ConciergeHistory />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.products.loading,
    products: state.products.products,
    uiState: state.products.uiState,
    currentUserId: state?.auth?.profile?.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getProducts: keyword => dispatch(getProducts(keyword)),
    createConcierge: data => dispatch(createConcierge(data)),
    // updateProduct: data => dispatch(updateProduct(data)),
    // deleteProduct: id => dispatch(deleteProduct(id)),
    getProductById: id => dispatch(getProductById(id)),
    // updateUI: payload => dispatch(updateUI(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConciergePage);
