import {
  SafetyOutlined,
  UserOutlined,
  ScheduleOutlined,
  TeamOutlined,
  AppstoreOutlined,
  DatabaseOutlined,
  MessageOutlined,
  FundProjectionScreenOutlined,
  SolutionOutlined,
  DashboardOutlined,
  BarsOutlined,
  MailOutlined,
  ProjectOutlined,
  // ScheduleFilled,
  GoldOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  TagOutlined,
  FileImageOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import JobIcon from './assets/icons/jobIcon';
import JobFeedbackIcon from './assets/icons/jobFeedback';
import WithDrawRequestIcon from './assets/icons/withDrawRequestIcon';

import Dashboard from './pages/Dashboard';
import AdminPage from './pages/AdminPage';
import AuthPage from './pages/AuthPage';
import OtpPage from './pages/AuthPage/OTPCheck';
import JobsFeedback from './pages/JobsFeedback';
import VendorPage from './pages/VendorPage';
import JobPage from './pages/JobPage';
import VendorProfilePage from './pages/VendorProfilePage';
import SchedulerPage from './pages/SchedulerPage';
import ClientPage from './pages/ClientPage';
import ClientProfilePage from './pages/ClientProfilePage';
import JobProfilePage from './pages/JobProfilePage';
import ConciergePage from './pages/ConciergePage';
import ConciergeFormPage from './pages/ConciergePage/components/concierge-create';
import ProductsPage from './pages/ProductsPage';
import ServicesPage from './pages/ServicesPage';
import ServiceFormPage from './pages/ServicesPage/components/service-create';
import ClassesPage from './pages/ClassesPage';
import ClassFormPage from './pages/ClassesPage/components/class-create';
import CompanyAccounts from './pages/CompanyAccounts';
import AccountTransactionPage from './pages/AccountTransactionPage';
import GroupedCompanyTrans from './pages/CompanyAccounts/GroupedTrans';
import WithdrawalRequest from './pages/WithdrawalRequest/index';
import CouponPage from './pages/CouponCodePage';
import MediaLibrary from './pages/MediaLibrary';
import QuoteRequest from './pages/QuoteRequest';

import OrderPage from './pages/Order';
import OrderProfilePage from './pages/OrderProfilePage';
import TransactionPage from './pages/Transaction';
import ProductFormPage from './pages/ProductsPage/components/product-create';
import TagsPage from './pages/TagsPage';
import SlidesShow from './pages/SlidesShow';
import CategoriesPage from './pages/CategoriesPage';
import SubCategoriesPage from './pages/SubCategoriesPage';
import PushNotification from './pages/PushNotification';
import ReportsPage from './pages/ReportsPage';
// import PoolSchedularPage from './pages/PoolSchedularPage';
import SubscriptionPage from './pages/SubscriptionPage';
import SubscriptionCreate from './pages/SubscriptionPage/components/subscription-create';
import SendSmsPage from './pages/SendSmsPage/index';
import SettingsPage from './pages/SettingsPage/index';
import ClientTrans from './pages/ClientPage/ClientTrans/index';
import VendorTransactions from './pages/VendorPage/VendorTransactions/index';

const Routes = [
  {
    path: '/admin',
    component: Dashboard,
    disableWrapper: true,
    name: 'dashboard_tab',
    visibilityDependency: null,
    menu: {
      name: 'Dashboard',
      Icon: DashboardOutlined,
    },
  },
  {
    path: '/admin/login',
    component: AuthPage,
    disableWrapper: true,
    name: 'auth_page',
    visibilityDependency: null,
  },
  {
    path: '/admin/login/otp-verification',
    component: OtpPage,
    disableWrapper: true,
    name: 'otp_page',
    visibilityDependency: null,
  },
  {
    path: '/admin/admins',
    component: AdminPage,
    disableWrapper: true,
    name: 'admins_tab',
    visibilityDependency: null,
    menu: {
      name: 'Admins',
      Icon: SafetyOutlined,
    },
  },
  {
    path: '/admin/vendors',
    component: VendorPage,
    disableWrapper: true,
    name: 'vendors_tab',
    visibilityDependency: null,
    menu: {
      name: 'Vendors',
      Icon: UserOutlined,
    },
  },
  {
    path: '/admin/scheduler',
    component: SchedulerPage,
    disableWrapper: true,
    name: 'scheduler_tab',
    visibilityDependency: null,
    menu: {
      name: 'Scheduler',
      Icon: ScheduleOutlined,
    },
  },
  {
    path: '/admin/clients',
    component: ClientPage,
    disableWrapper: true,
    name: 'clients_tab',
    visibilityDependency: null,
    menu: {
      name: 'Clients',
      Icon: TeamOutlined,
    },
  },
  {
    path: '/admin/concierge',
    component: ConciergePage,
    disableWrapper: true,
    name: 'concierge_tab',
    visibilityDependency: null,
    menu: {
      name: 'Concierge',
      Icon: MessageOutlined,
    },
  },
  {
    path: '/admin/concierge/form/:id',
    component: ConciergeFormPage,
    disableWrapper: true,
    visibilityDependency: 'concierge_tab',
  },
  {
    path: '/admin/list/subscription',
    component: SubscriptionPage,
    disableWrapper: true,
    name: 'subscription_tab',
    visibilityDependency: null,
  },
  {
    path: '/admin/list/services',
    component: ServicesPage,
    disableWrapper: true,
    name: 'services_tab',
    visibilityDependency: null,
  },
  {
    path: '/admin/list/services/form/:id',
    component: ServiceFormPage,
    disableWrapper: true,
    visibilityDependency: 'services_tab',
  },
  {
    path: '/admin/list/classes',
    component: ClassesPage,
    disableWrapper: true,
    name: 'classes',
    visibilityDependency: null,
  },
  {
    path: '/admin/list/classes/form/:id',
    component: ClassFormPage,
    disableWrapper: true,
    visibilityDependency: 'classes',
  },
  {
    path: '/admin/jobs',
    component: JobPage,
    disableWrapper: true,
    name: 'jobs_tab',
    visibilityDependency: null,
    menu: {
      name: 'Jobs',
      Icon: JobIcon,
    },
  },
  {
    path: '/admin/jobsfeedback',
    component: JobsFeedback,
    disableWrapper: true,
    name: 'jobs_tab',
    visibilityDependency: null,
    menu: {
      name: 'Jobs Feedback',
      Icon: JobFeedbackIcon,
    },
  },
  {
    path: '/admin/list/products',
    component: ProductsPage,
    disableWrapper: true,
    name: 'products_tab',
    visibilityDependency: null,
  },
  {
    path: '/admin/list/products/form/:id',
    component: ProductFormPage,
    disableWrapper: true,
    visibilityDependency: 'products_tab',
  },
  {
    path: '/admin/cat/categories',
    component: CategoriesPage,
    disableWrapper: true,
    name: 'categories_tab',
    visibilityDependency: null,
  },
  {
    path: '/admin/cat/subcategories',
    component: SubCategoriesPage,
    disableWrapper: true,
    name: 'subcategories_tab',
    visibilityDependency: null,
  },
  {
    path: '/admin/cat/tags',
    component: TagsPage,
    disableWrapper: true,
    name: 'tags_tab',
    visibilityDependency: null,
  },
  {
    name: 'List',
    Icon: AppstoreOutlined,
    visibilityDependency: null,
    path: '/admin/list',
    menu: {
      name: 'List',
      Icon: AppstoreOutlined,
    },
    children: [
      {
        path: '/admin/list/classes',
        component: ClassesPage,
        disableWrapper: true,
        name: 'Classes',
        visibilityDependency: null,
        Icon: GoldOutlined,
      },
      {
        path: '/admin/list/services',
        component: ServicesPage,
        disableWrapper: true,
        name: 'Services',
        visibilityDependency: null,
        Icon: GoldOutlined,
      },
      {
        path: '/admin/list/subscription',
        component: SubscriptionPage,
        disableWrapper: true,
        name: 'Subscription',
        visibilityDependency: null,
        Icon: GoldOutlined,
      },
      {
        path: '/admin/list/products',
        component: ProductsPage,
        disableWrapper: true,
        name: 'Products',
        Icon: AppstoreOutlined,
        visibilityDependency: null,
      },
    ],
  },
  {
    path: '/admin/quoterequest',
    component: QuoteRequest,
    disableWrapper: true,
    name: 'quoterequest_tab',
    visibilityDependency: null,
    menu: {
      name: 'Quote Requests',
      Icon: DollarOutlined,
    },
  },
  {
    path: '/admin/medialibrary',
    component: MediaLibrary,
    disableWrapper: true,
    name: 'medialibrary_tab',
    visibilityDependency: null,
    menu: {
      name: 'Media Library',
      Icon: FileImageOutlined,
    },
  },
  {
    path: '/admin/list/subscription/form/:id',
    component: SubscriptionCreate,
    disableWrapper: true,
    visibilityDependency: 'subscription_tab',
  },
  {
    name: 'Categories and Properties',
    Icon: AppstoreOutlined,
    visibilityDependency: null,
    path: '/admin/cat',
    menu: {
      name: 'Categories and Properties',
      Icon: AppstoreOutlined,
    },
    children: [
      {
        path: '/admin/cat/categories',
        component: CategoriesPage,
        disableWrapper: true,
        visibilityDependency: null,
        name: 'Categories',
        Icon: BarsOutlined,
      },
      {
        path: '/admin/cat/subcategories',
        component: SubCategoriesPage,
        disableWrapper: true,
        visibilityDependency: null,
        name: 'Sub Categories',
        Icon: BarsOutlined,
      },
      {
        path: '/admin/cat/tags',
        component: TagsPage,
        disableWrapper: true,
        visibilityDependency: null,
        name: 'Properties',
        Icon: DatabaseOutlined,
      },
    ],
  },
  {
    path: '/admin/company_accounts',
    component: CompanyAccounts,
    disableWrapper: true,
    name: 'accounts_tab',
    visibilityDependency: null,
    menu: {
      name: 'Company Accounts',
      Icon: TeamOutlined,
    },
  },
  {
    path: '/admin/transactions',
    component: TransactionPage,
    disableWrapper: true,
    name: 'Transactions',
    Icon: MoneyCollectOutlined,
    visibilityDependency: null,
    menu: {
      name: 'Transactions',
      Icon: MoneyCollectOutlined,
    },
  },
  {
    path: '/admin/withdrawal_requests',
    component: WithdrawalRequest,
    disableWrapper: true,
    name: 'Withdrawal Requests',
    Icon: WithDrawRequestIcon,
    visibilityDependency: null,
    menu: {
      name: 'Withdrawal Requests',
      Icon: WithDrawRequestIcon,
    },
  },
  {
    path: '/admin/orders',
    component: OrderPage,
    disableWrapper: true,
    name: 'orders_tab',
    visibilityDependency: null,
    menu: {
      name: 'Orders',
      Icon: SolutionOutlined,
    },
  },
  {
    path: '/admin/orders/:id',
    component: OrderProfilePage,
    disableWrapper: true,
    visibilityDependency: 'orders_tab',
  },
  {
    path: '/admin/jobs/:id',
    component: JobProfilePage,
    disableWrapper: true,
    visibilityDependency: 'jobs_tab',
  },
  {
    path: '/admin/clients/:type/:id',
    component: ClientProfilePage,
    disableWrapper: true,
    visibilityDependency: 'clients_tab',
  },
  {
    path: '/admin/vendors/:id',
    component: VendorProfilePage,
    disableWrapper: true,
    visibilityDependency: 'vendors_tab',
  },
  {
    path: '/admin/company_accounts/:id/transactions',
    component: AccountTransactionPage,
    disableWrapper: true,
    visibilityDependency: 'accounts_tab',
  },
  {
    path: '/admin/company_accounts/:id/grouped_transactions',
    component: GroupedCompanyTrans,
    disableWrapper: true,
    visibilityDependency: 'accounts_tab',
  },
  {
    path: '/admin/vendors/:id/transactions',
    component: VendorTransactions,
    disableWrapper: true,
    visibilityDependency: 'vendors_tab',
  },
  {
    path: '/admin/clients/:id',
    component: ClientTrans,
    disableWrapper: true,
    visibilityDependency: 'clients_tab',
  },
  {
    path: '/admin/services',
    component: ServicesPage,
    disableWrapper: true,
    name: 'services_tab',
    visibilityDependency: 'null',
  },
  {
    path: '/admin/slideshow',
    component: SlidesShow,
    disableWrapper: true,
    name: 'slideshow_tab',
    visibilityDependency: null,
    menu: {
      name: 'SlideShow',
      Icon: FundProjectionScreenOutlined,
    },
  },
  {
    path: '/admin/coupon',
    component: CouponPage,
    disableWrapper: true,
    menu: {
      name: 'Create Coupon',
      Icon: TagOutlined,
    },
  },
  {
    path: '/admin/push_notification',
    component: PushNotification,
    disableWrapper: true,
    name: 'pushNotification_tab',
    visibilityDependency: null,
    menu: {
      name: 'Push Notification (APP)',
      Icon: MailOutlined,
    },
  },
  {
    path: '/admin/reports',
    component: ReportsPage,
    disableWrapper: true,
    name: 'reports_tab',
    visibilityDependency: null,
    menu: {
      name: 'Reports',
      Icon: ProjectOutlined,
      iconProps: {
        rotate: 180,
      },
    },
  },
  // {
  //   path: '/admin/pool-scheduler',
  // component: PoolSchedularPage,
  //   disableWrapper: true,
  //   menu: {
  //     name: 'Pool Scheduler',
  //     Icon: ScheduleFilled,
  //   },
  // },
  {
    path: '/admin/send-sms',
    component: SendSmsPage,
    disableWrapper: true,
    menu: {
      name: 'Send SMS',
      Icon: MessageOutlined,
    },
  },
  {
    path: '/admin/settings',
    component: SettingsPage,
    disableWrapper: true,
    menu: {
      name: 'Settings',
      Icon: SettingOutlined,
    },
  },
];

export default Routes;
