import { createSlice } from '@reduxjs/toolkit';
import {
  checkToken,
  checkVendorToken,
  checkImpVendorToken,
} from '../../../utils/apisauce';
import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorLogin',
  initialState: {
    loading: false,
    username: '',
    password: '',
    error: '',
    profile: null,
    vendorName: '',
    authenticated: checkVendorToken() ? true : checkImpVendorToken(),
  },
  reducers: {
    login: (state, { payload }) => {
      state.loading = true;
      state.username = payload.username;
      state.password = payload.password;
    },
    loginSuccess: (state, { payload }) => {
      state.loading = false;
      const adminToken = checkToken();
      if (adminToken) localStorage.removeItem('access_token');
      localStorage.setItem('vendor_access_token', payload.access_token);
      state.authenticated = checkVendorToken();
    },
    loginFailed: (state, { payload }) => {
      state.loading = false;
      state.authenticated = false;
      state.error = payload;
      apiError(payload);
    },
    impersonateLogin: state => {
      state.loading = true;
    },
    impersonateLoginSuccess: (state, { payload }) => {
      state.loading = false;
      localStorage.setItem(
        'imp_vendor_access_token',
        payload.meta.auth.access_token
      );
      state.authenticated = checkImpVendorToken();
    },
    impersonateLoginFailed: (state, { payload }) => {
      state.loading = false;
      state.authenticated = false;
      state.error = payload;
      apiError(payload);
    },
    searchVendors: () => {},
    searchVendorsSuccess: (state, { payload }) => {
      const { vendor_name } = payload;
      state.vendorName = vendor_name;
      localStorage.setItem(
        'vendor_name',
        vendor_name !== null ? vendor_name : ''
      );
    },
    searchVendorsFailed: () => {},
    getProfile: state => {
      state.loading = true;
    },
    getProfileSuccess: (state, { payload }) => {
      state.loading = false;
      state.authenticated = true;
      state.profile = payload;
      const {
        vendor_user: { role_permission },
      } = payload;
      let keys = Object.keys(role_permission.permissions);
      keys = keys.map(item => {
        return item.replace(/_tab/g, '');
      });
      localStorage.setItem('routes', JSON.stringify(keys));
    },
    getProfileFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFailed,
  impersonateLogin,
  impersonateLoginSuccess,
  impersonateLoginFailed,
  getProfile,
  getProfileSuccess,
  getProfileFailed,
  searchVendors,
  searchVendorsSuccess,
  searchVendorsFailed,
} = slice.actions;
export default slice.reducer;
