import styled from 'styled-components';

export default styled.div`
  display: block;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 20px;

  .image-uploader {
    height: 0px;
    display: grid;
    grid-template-rows: 2rem [row-start]; // height of label, be below it

    .ant-upload {
      width: unset;
    }
  }
`;
