import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Drawer, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dig } from 'digdata';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import {
  getVendors,
  getMedia,
  createVendor,
  updateVendor,
  deleteVendor,
} from './reducers';
import { capitalize, getImageURI } from '../../utils/apisauce';
import CreateVendor from './components/create-vendor';
import TableSearch from '../../components/TableSearch';
import {
  EditFilledIcon,
  DeleteFilledIcon,
  EyeFilledIcon,
  MoneyCollectOutlinedIcon,
} from '../../components/Icons/Icons';

class VendorPage extends React.PureComponent {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    lastSearch: '',
    dataSource: [],
    pageQuery: {
      page: 1,
      per: 20,
    },
    queryString: '',
  };

  componentDidUpdate(prevProps, prevState) {
    const { getVendors, vendors, submitting, error } = this.props;
    const { pageQuery, queryString } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getVendors({ ...pageQuery, query: queryString });
      } else {
        getVendors(pageQuery);
      }
    }

    if (vendors && prevProps['vendors'] !== vendors) {
      this.setState({ dataSource: vendors });
    }

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  componentDidMount() {
    const { getVendors, getMedia } = this.props;
    const { pageQuery } = this.state;
    getVendors(pageQuery);
    getMedia();
  }

  onSearch = () => {
    const { getVendors } = this.props;
    const { pageQuery, queryString } = this.state;
    const updatedPageQuery = { ...pageQuery, page: 1 };
    this.setState({ pageQuery: updatedPageQuery }, () =>
      getVendors({ query: queryString, per: pageQuery['per'] })
    );
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onClickRow = record => {
    const {
      history: { push },
    } = this.props;

    push(`/admin/vendors/${record.id}`);
  };
  onClickTrans = record => {
    const {
      history: { push },
    } = this.props;

    // push(`/admin/vendors/${record.account.id}/transactions`);
    push({
      pathname: `/admin/vendors/${record.account.id}/transactions`,
      state: {
        clientName: record.vendor_name,
      },
    });
  };

  onCreate = () => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        picture: null,
        vendor_name: '',
        company_reg: '',
        website: '',
        contact: '',
      },
    });
  };

  onEdit = record => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: {
        id: record.id,
        picture_path: record.picture_path,
        vendor_name: record.vendor_name,
        company_reg: record.company_reg,
        website: record.website,
        contact: record.contact,
        image_type: record.image_type,
      },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  onSubmit = values => {
    const { formData } = this.state;
    formData['image_type'] = values.image_type;
    const { picture, ...remainingFormData } = formData;
    const { image_type, mediaImageId, ...remainingValues } = values;

    const payload = { ...remainingValues };
    if (image_type === 'attached') {
      payload['picture'] = picture;
    } else {
      payload['attach_library_image_attributes'] = {
        library_image_id: mediaImageId,
      };
    }
    if (formData.id) {
      const { updateVendor } = this.props;
      updateVendor({ ...remainingFormData, ...payload });
    } else {
      const { createVendor } = this.props;
      createVendor({
        ...remainingFormData,
        ...payload,
        vendor_users_attributes: [],
      });
    }
    // this.closeModal();
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteVendor } = this.props;

    deleteVendor(selectedRow.id);
    this.closeModal();
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  tableColumns = () => [
    {
      title: 'Image',
      key: 'userImg',
      fixed: 'left',
      width: 70,
      render: (text, record) => (
        <Avatar
          size={42}
          src={getImageURI(dig(record, 'picture_path.url'))}
          icon={<UserOutlined />}
        />
      ),
    },
    {
      title: 'ID',
      key: 'id',
      fixed: 'left',
      width: 60,
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      key: 'vendor_name',
      render: (text, record) => <p>{capitalize(record?.vendor_name)}</p>,
    },
    {
      title: 'Registration No.',
      key: 'company_reg',
      dataIndex: 'company_reg',
    },
    {
      title: 'Contact',
      key: 'contact',
      dataIndex: 'contact',
    },
    {
      title: 'URL',
      key: 'website',
      dataIndex: 'website',
    },
    {
      title: 'No. of Workers',
      key: 'worker_count',
      dataIndex: 'worker_count',
      align: 'center',
    },
    {
      title: 'Account Balance',
      key: 'account_bal',
      render: (_, record) => <span>{record.account?.formatted_balance}</span>,
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      align: 'center',
      render: (text, record) => (
        <div className="ant-table-action-cell">
          <MoneyCollectOutlinedIcon
            onClick={e => e.stopPropagation() || this.onClickTrans(record)}
          />
          <EyeFilledIcon
            onClick={e => e.stopPropagation() || this.onClickRow(record)}
          />
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || this.onDelete(record)}
          />
        </div>
      ),
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const {
      modalOpened,
      selectedRow,
      formData,
      pageQuery,
      dataSource,
      queryString,
    } = this.state;
    const { loading, submitting, metaInfo, libraryImage } = this.props;

    return (
      <div className="vendors-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Vendor</h4>
          </div>
          <div className="page-header-actions">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.onCreate}
            >
              Add Vendor
            </Button>
          </div>
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search Vendor"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={queryString}
            onInputChange={ev =>
              this.setState({ queryString: ev['target']['value'] })
            }
          />
          <Card title="Vendors" style={{ marginTop: 15 }}>
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              data={dataSource}
              columns={this.tableColumns()}
              // onClickRow={this.onClickRow}
              pagination={{
                limit: pageQuery['per'],
                total: metaInfo ? metaInfo['total_count'] : 0,
                page: pageQuery['page'],
                data: dataSource,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        </div>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={modalOpened === 1}
          onClose={this.closeModal}
        >
          {formData && (
            <CreateVendor
              formData={formData}
              onDelete={this.onDelete}
              closeModal={this.closeModal}
              onSubmit={this.onSubmit}
              onInputChange={this.onInputChange}
              loading={loading || submitting}
              libraryImage={libraryImage}
            />
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <h1 className="main-message pop-up-heading">Are you sure?</h1>
              <p className="pop-up-body">
                Inactivating this vendor will unassign current and future jobs.
                The process cannot be undone.
              </p>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

VendorPage.propTypes = {
  loading: PropTypes.bool,
  vendors: PropTypes.array,
  metaInfo: PropTypes.object,
  getVendors: PropTypes.func,
  getMedia: PropTypes.func,
  createVendor: PropTypes.func,
  updateVendor: PropTypes.func,
  deleteVendor: PropTypes.func,
  history: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.vendors.loading,
    vendors: state.vendors.vendors,
    libraryImage: state.vendors.media_library_images?.data,
    submitting: state.vendors.submitting,
    error: state.vendors.error,
    metaInfo: state.vendors.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVendors: payload => dispatch(getVendors(payload)),
    getMedia: () => dispatch(getMedia()),
    createVendor: data => dispatch(createVendor(data)),
    updateVendor: data => dispatch(updateVendor(data)),
    deleteVendor: id => dispatch(deleteVendor(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorPage));
