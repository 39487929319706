import React from 'react';
import { Input, Form, TreeSelect } from 'antd';

export default function RolerPermissions({
  values,
  errors,
  touched,
  handleChange,
  tProps,
  roleName,
}) {
  React.useEffect(() => {}, [roleName]);
  return (
    <>
      <h3 className="role-perm-heading">Role Permission</h3>
      <div className="form-group">
        <label>Role Name</label>
        <Form.Item
          hasFeedback
          validateStatus={
            touched['role_name'] && errors['role_name'] ? 'error' : null
          }
          help={touched['role_name'] && errors['role_name']}
          style={{ margin: 0 }}
        >
          <Input
            name="role_name"
            value={values.role_name}
            placeholder="Enter role name"
            onChange={handleChange}
            disabled={roleName === 'worker'}
          />
        </Form.Item>
      </div>
      <div className="form-group">
        <label>Accessible Tab(s)</label>
        <Form.Item
          hasFeedback
          validateStatus={
            roleName !== 'worker' && errors['selected_tabs'] ? 'error' : null
          }
          help={roleName !== 'worker' && errors['selected_tabs']}
          style={{ margin: 0 }}
        >
          <TreeSelect
            {...tProps}
            disabled={roleName === 'worker'}
            treeNodeFilterProp={value => {
              console.log(value);
            }}
          />
        </Form.Item>
      </div>
    </>
  );
}
