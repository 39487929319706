import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
// import logger from 'redux-logger';
import { all, fork } from 'redux-saga/effects';

import AuthReducer from '../pages/AuthPage/reducers';
import AuthSaga from '../pages/AuthPage/sagas';

import AdminReducer from '../pages/AdminPage/reducers';
import AdminSaga from '../pages/AdminPage/sagas';

import CompanyAccountsReducer from '../pages/CompanyAccounts/reducers';
import CompanyAccountsSaga from '../pages/CompanyAccounts/sagas';

import GroupedTransReducer from '../pages/CompanyAccounts/GroupedTrans/reducers';
import GroupedTransSaga from '../pages/CompanyAccounts/GroupedTrans/sagas';

import AccountTransactionPageReducer from '../pages/AccountTransactionPage/reducers';
import AccountTransactionPageSaga from '../pages/AccountTransactionPage/sagas';

import VendorsReducer from '../pages/VendorPage/reducers';
import VendorsSaga from '../pages/VendorPage/sagas';

import VendorReducer from '../pages/VendorProfilePage/reducers';
import VendorSaga from '../pages/VendorProfilePage/sagas';

import VendorTransReducer from '../pages/VendorPage/VendorTransactions/reducers';
import VendorTransSaga from '../pages/VendorPage/VendorTransactions/sagas';

import TransactionReducer from '../pages/Transaction/reducers';
import TransactionSaga from '../pages/Transaction/sagas';

import OrderReducer from '../pages/Order/reducers';
import OrderSaga from '../pages/Order/sagas';

import OrderDetailReducer from '../pages/OrderProfilePage/reducers';
import OrderDetailSaga from '../pages/OrderProfilePage/sagas';

import SchedulerReducer from '../pages/SchedulerPage/reducers';
import SchedulerSaga from '../pages/SchedulerPage/sagas';

import ClientsReducer from '../pages/ClientPage/reducers';
import ClientsSaga from '../pages/ClientPage/sagas';

import ClientsTransReducer from '../pages/ClientPage/ClientTrans/reducers';
import ClientsTransSaga from '../pages/ClientPage/ClientTrans/sagas';

import ClientReducer from '../pages/ClientProfilePage/reducers';
import ClientSaga from '../pages/ClientProfilePage/sagas';

import JobsReducer from '../pages/JobPage/reducers';
import JobsSaga from '../pages/JobPage/sagas';

import JobsFeedbackReducer from '../pages/JobsFeedback/reducers';
import JobsFeedbackSaga from '../pages/JobsFeedback/sagas';

import JobReducer from '../pages/JobProfilePage/reducers';
import JobSaga from '../pages/JobProfilePage/sagas';

import ProductsReducer from '../pages/ProductsPage/reducers';
import ProductsSaga from '../pages/ProductsPage/sagas';

import QuoteRequestReducer from '../pages/QuoteRequest/reducers';
import QuoteRequestSaga from '../pages/QuoteRequest/sagas';

import MediaReducer from '../pages/MediaLibrary/reducers';
import MediaSaga from '../pages/MediaLibrary/sagas';

import ClassesReducer from '../pages/ClassesPage/reducers';
import ClassesSaga from '../pages/ClassesPage/sagas';

import ServicesReducer from '../pages/ServicesPage/reducers';
import ServicesSaga from '../pages/ServicesPage/sagas';

import SubscriptionReducer from '../pages/SubscriptionPage/reducers';
import SubscriptionSaga from '../pages/SubscriptionPage/sagas';

import TagsReducer from '../pages/TagsPage/reducers';
import TagsSaga from '../pages/TagsPage/sagas';

import DashboardReducer from '../pages/Dashboard/reducers';
import DashboardSaga from '../pages/Dashboard/sagas';

import CategoriesReducer from '../pages/CategoriesPage/reducers';
import CategoriesSaga from '../pages/CategoriesPage/sagas';

import SubCategoriesReducer from '../pages/SubCategoriesPage/reducers';
import SubCategoriesSaga from '../pages/SubCategoriesPage/sagas';

import SlidesReducer from '../pages/SlidesShow/reducers';
import SlidesSaga from '../pages/SlidesShow/sagas';

import PushNotificationReducer from '../pages/PushNotification/reducers';
import PushNotificationSaga from '../pages/PushNotification/sagas';

import ReportsReducer from '../pages/ReportsPage/reducers';
import ReportsSaga from '../pages/ReportsPage/sagas';

import PoolSchedularReducer from '../pages/PoolSchedularPage/reducers';
import PoolSchedularSaga from '../pages/PoolSchedularPage/sagas';

import SendSmsReducer from '../pages/SendSmsPage/reducer';
import SendSmsSaga from '../pages/SendSmsPage/sagas';

import SettingsReducer from '../pages/SettingsPage/reducer';
import SettingsSaga from '../pages/SettingsPage/sagas';

import ConciergeReducer from '../pages/ConciergePage/reducers';
import ConciergeSaga from '../pages/ConciergePage/sagas';

import CouponReducer from '../pages/CouponCodePage/reducers';
import CouponSaga from '../pages/CouponCodePage/sagas';

//Vendor
import VendorAuthReducer from '../Vendor/pages/AuthPage/reducers';
import VendorAuthSaga from '../Vendor/pages/AuthPage/sagas';

import VendorDashboardReducer from '../Vendor/pages/Dashboard/reducers';
import VendorDashboardSaga from '../Vendor/pages/Dashboard/sagas';

import VendorUsersReducer from '../Vendor/pages/VendorProfilePage/reducers';
import VendorUsersSaga from '../Vendor/pages/VendorProfilePage/sagas';

import VendorClientPageReducer from '../Vendor/pages/ClientPage/reducers';
import VendorClientPageSaga from '../Vendor/pages/ClientPage/sagas';

import VendorSchedulerReducer from '../Vendor/pages/SchedulerPage/reducers';
import VendorSchedulerSaga from '../Vendor/pages/SchedulerPage/sagas';

import VendorOrderReducer from '../Vendor/pages/Order/reducers';
import VendorOrderSaga from '../Vendor/pages/Order/sagas';

import VendorJobsReducer from '../Vendor/pages/JobPage/reducers';
import VendorJobsSaga from '../Vendor/pages/JobPage/sagas';

import VendorJobReducer from '../Vendor/pages/JobProfilePage/reducers';
import VendorJobSaga from '../Vendor/pages/JobProfilePage/sagas';

import VendorTransactionsReducer from '../Vendor/pages/Transaction/reducers';
import VendorTransactionsSaga from '../Vendor/pages/Transaction/sagas';

import VendorProductsReducer from '../Vendor/pages/ProductsPage/reducers';
import VendorProductsSaga from '../Vendor/pages/ProductsPage/sagas';

import VendorServicesReducer from '../Vendor/pages/ServicesPage/reducers';
import VendorServicesSaga from '../Vendor/pages/ServicesPage/sagas';

import VendorSubscriptionReducer from '../Vendor/pages/SubscriptionPage/reducers';
import VendorSubscriptionSaga from '../Vendor/pages/SubscriptionPage/sagas';

import VendorClassesReducer from '../Vendor/pages/ClassesPage/reducers';
import VendorClassesSaga from '../Vendor/pages/ClassesPage/sagas';

const forkAll = (...sagas) => all(sagas.map(saga => fork(saga)));

const rootSaga = function* saga() {
  yield forkAll(
    AuthSaga,
    AdminSaga,
    CompanyAccountsSaga,
    GroupedTransSaga,
    AccountTransactionPageSaga,
    VendorsSaga,
    VendorSaga,
    VendorTransSaga,
    SchedulerSaga,
    ClientsSaga,
    ClientsTransSaga,
    ClientSaga,
    JobsSaga,
    JobSaga,
    QuoteRequestSaga,
    MediaSaga,
    JobsFeedbackSaga,
    ProductsSaga,
    ServicesSaga,
    ClassesSaga,
    TagsSaga,
    TransactionSaga,
    OrderSaga,
    OrderDetailSaga,
    DashboardSaga,
    CategoriesSaga,
    SubCategoriesSaga,
    SlidesSaga,
    PushNotificationSaga,
    ReportsSaga,
    PoolSchedularSaga,
    SubscriptionSaga,
    SendSmsSaga,
    SettingsSaga,
    ConciergeSaga,
    CouponSaga,

    //Vendor
    VendorAuthSaga,
    VendorDashboardSaga,
    VendorUsersSaga,
    VendorSchedulerSaga,
    VendorOrderSaga,
    VendorJobsSaga,
    VendorJobSaga,
    VendorTransactionsSaga,
    VendorClientPageSaga,
    VendorProductsSaga,
    VendorServicesSaga,
    VendorSubscriptionSaga,
    VendorClassesSaga
  );
};

const rootReducer = {
  auth: AuthReducer,
  admin: AdminReducer,
  companyAccounts: CompanyAccountsReducer,
  companyAccountTransaction: AccountTransactionPageReducer,
  groupedCompanyTrans: GroupedTransReducer,
  vendors: VendorsReducer,
  vendorTrans: VendorTransReducer,
  transactions: TransactionReducer,
  orders: OrderReducer,
  orderDetail: OrderDetailReducer,
  vendor: VendorReducer,
  scheduler: SchedulerReducer,
  clients: ClientsReducer,
  clientsTrans: ClientsTransReducer,
  client: ClientReducer,
  jobs: JobsReducer,
  job: JobReducer,
  quote: QuoteRequestReducer,
  media: MediaReducer,
  jobsFeedback: JobsFeedbackReducer,
  products: ProductsReducer,
  services: ServicesReducer,
  classes: ClassesReducer,
  tags: TagsReducer,
  dashboard: DashboardReducer,
  slides: SlidesReducer,
  categories: CategoriesReducer,
  subCategories: SubCategoriesReducer,
  pushNotification: PushNotificationReducer,
  reports: ReportsReducer,
  poolSchedular: PoolSchedularReducer,
  subscription: SubscriptionReducer,
  sendSms: SendSmsReducer,
  settings: SettingsReducer,
  concierge: ConciergeReducer,
  coupons: CouponReducer,

  //Vendor
  vendorAuth: VendorAuthReducer,
  vendorDashboard: VendorDashboardReducer,
  VendorUsers: VendorUsersReducer,
  vendorScheduler: VendorSchedulerReducer,
  vendorOrders: VendorOrderReducer,
  vendorJobs: VendorJobsReducer,
  vendorJob: VendorJobReducer,
  vendorTransactions: VendorTransactionsReducer,
  vendorClient: VendorClientPageReducer,
  vendorProducts: VendorProductsReducer,
  vendorServices: VendorServicesReducer,
  vendorSubscription: VendorSubscriptionReducer,
  vendorClasses: VendorClassesReducer,
};

const redux = combineReducers(rootReducer);
const saga = createSagaMiddleware();
const middleware = [saga];
// if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
//   middleware.push(logger);
// }

const store = configureStore({
  reducer: redux,
  devTools: true,
  middleware,
});
saga.run(rootSaga);
export default store;
