import { createSlice } from '@reduxjs/toolkit';
import { onDragEnd } from '../../utils/helpers';
import moment from 'moment';
// import { message, notification } from 'antd';

// import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'poolSchedular',
  initialState: {
    selectedVendor: undefined,
    vendorUsers: null,
    getVendorUsersloading: false,
    loading: false,
    poolSchedulers: [
      {
        _id: `${Math.floor(Math.random() * 12888 + 1)}`,
        title: 'New Pool 01',
        poolJobsList: [],
        poolWorkersList: [],
      },
    ],
    currentDate: moment(),
  },
  reducers: {
    setCurrentDate: (state, { payload }) => {
      state.currentDate = payload;
    },
    updateSelectedVendor: (state, { payload }) => {
      state.selectedVendor = payload;
    },
    handleDragEnd: (state, { payload }) => {
      const { result, list } = payload;

      const dragResult = onDragEnd(result, list);

      const updateJobsList = () => {
        if (
          !dragResult['selected'] &&
          dragResult['items']['index'] === 'droppable'
        ) {
          state.selectedVendor['unassigned_jobs'] =
            dragResult['items']['sourceClone'];
        } else if (
          dragResult['selected'] &&
          dragResult['items']['index'] === 'droppable'
        ) {
          const { index, destClone } = dragResult['selected'];
          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === index
          );
          updatedPoolSchedulers[schedulerIndex]['poolJobsList'] = destClone;
          state.poolSchedulers = updatedPoolSchedulers;
          state.selectedVendor['unassigned_jobs'] =
            dragResult['items']['sourceClone'];
        } else if (
          dragResult['items'] &&
          dragResult['selected']['index'] === 'droppable'
        ) {
          const { index, sourceClone } = dragResult['items'];
          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === index
          );
          updatedPoolSchedulers[schedulerIndex]['poolJobsList'] = sourceClone;
          state.poolSchedulers = updatedPoolSchedulers;
          state.selectedVendor['unassigned_jobs'] =
            dragResult['selected']['destClone'];
        } else if (
          dragResult['items'] &&
          dragResult['selected'] &&
          dragResult['items']['index'] === dragResult['selected']['index']
        ) {
          const { index, destClone } = dragResult['selected'];
          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === index
          );
          updatedPoolSchedulers[schedulerIndex]['poolJobsList'] = destClone;
          state.poolSchedulers = updatedPoolSchedulers;
        } else if (
          dragResult['items'] &&
          dragResult['selected'] &&
          dragResult['items']['index'] !== dragResult['selected']['index']
        ) {
          const { index, sourceClone } = dragResult['items'];
          const { index: dropIndex, destClone } = dragResult['selected'];

          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === index
          );
          const schedulerIndex2 = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === dropIndex
          );
          updatedPoolSchedulers[schedulerIndex]['poolJobsList'] = sourceClone;
          updatedPoolSchedulers[schedulerIndex2]['poolJobsList'] = destClone;
          state.poolSchedulers = updatedPoolSchedulers;
        }
      };

      const getWorkerSchedularId = id => id.split('_')[1];

      const updateWorkersList = () => {
        if (
          !dragResult['selected'] &&
          dragResult['items']['index'] === 'droppable3'
        ) {
          state.vendorUsers['vendor_users'] =
            dragResult['items']['sourceClone'];
        } else if (
          dragResult['selected'] &&
          dragResult['items']['index'] === 'droppable3'
        ) {
          const { index, destClone } = dragResult['selected'];
          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === getWorkerSchedularId(index)
          );
          updatedPoolSchedulers[schedulerIndex]['poolWorkersList'] = destClone;
          state.poolSchedulers = updatedPoolSchedulers;
          state.vendorUsers['vendor_users'] =
            dragResult['items']['sourceClone'];
        } else if (
          dragResult['items'] &&
          dragResult['selected']['index'] === 'droppable3'
        ) {
          const { index, sourceClone } = dragResult['items'];
          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === getWorkerSchedularId(index)
          );
          updatedPoolSchedulers[schedulerIndex][
            'poolWorkersList'
          ] = sourceClone;
          state.poolSchedulers = updatedPoolSchedulers;
          state.vendorUsers['vendor_users'] =
            dragResult['selected']['destClone'];
        } else if (
          dragResult['items'] &&
          dragResult['selected'] &&
          dragResult['items']['index'] === dragResult['selected']['index']
        ) {
          const { index, destClone } = dragResult['selected'];
          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === getWorkerSchedularId(index)
          );
          updatedPoolSchedulers[schedulerIndex]['poolWorkersList'] = destClone;
          state.poolSchedulers = updatedPoolSchedulers;
        } else if (
          dragResult['items'] &&
          dragResult['selected'] &&
          dragResult['items']['index'] !== dragResult['selected']['index']
        ) {
          const { index, sourceClone } = dragResult['items'];
          const { index: dropIndex, destClone } = dragResult['selected'];

          let updatedPoolSchedulers = [...state.poolSchedulers];
          const schedulerIndex = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === getWorkerSchedularId(index)
          );
          const schedulerIndex2 = updatedPoolSchedulers.findIndex(
            scheduler => scheduler['_id'] === getWorkerSchedularId(dropIndex)
          );
          updatedPoolSchedulers[schedulerIndex][
            'poolWorkersList'
          ] = sourceClone;
          updatedPoolSchedulers[schedulerIndex2]['poolWorkersList'] = destClone;
          state.poolSchedulers = updatedPoolSchedulers;
        }
      };

      if (dragResult) {
        if (result['type'] === 'SIDE_BAR_JOBS') {
          updateJobsList();
        } else if (result['type'] === 'SIDE_BAR_WORKERS') {
          updateWorkersList();
        }
      }
    },
    getVendorUsers: state => {
      state.getVendorUsersloading = true;
    },
    getVendorUsersSuccess: (state, { payload }) => {
      state.getVendorUsersloading = false;
      state.vendorUsers = payload;
    },
    getVendorUsersFailed: state => {
      state.getVendorUsersloading = false;
      state.vendorUsers = null;
    },
    addNewPool: state => {
      state.loading = true;
    },
    addNewPoolSuccess: (state, { payload }) => {
      state.loading = false;
      state.poolSchedulers = payload;
    },
    addNewPoolFailed: state => {
      state.loading = false;
    },
  },
});

export const {
  updateSelectedVendor,
  handleDragEnd,
  getVendorUsers,
  getVendorUsersSuccess,
  getVendorUsersFailed,
  addNewPool,
  addNewPoolSuccess,
  addNewPoolFailed,
  setCurrentDate,
} = slice.actions;

export default slice.reducer;
