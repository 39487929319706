import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLocationArrow,
  faStickyNote,
  faCheck,
  faClock,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(faLocationArrow, faStickyNote, faCheck, faClock, faUserCircle);
