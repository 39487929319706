import { Card, Modal, Tooltip, Select } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from '../../components/DataTable';
import { getQuoteRequest, updateQuoteRequest } from './reducers';
import { capitalize } from '../../utils/apisauce';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import './styles.css';

class QuoteRequest extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    pageQuery: {
      page: 1,
      per: 20,
    },
    status: '',
  };

  tableColumns = () => [
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      fixed: 'left',
      render: (_title, record) => record['customer_name'],
    },
    {
      title: 'Service Title',
      dataIndex: 'service_title',
      key: 'service_title',
      render: (_title, record) => record['service_title'],
    },
    {
      title: 'Message',
      key: 'message',

      render: (_, record) =>
        record?.message ? (
          <Tooltip title={`${record.message}`} placement="top">
            {capitalize(`${record.message}`).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      render: (_title, record) =>
        moment(record.created_at, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: (_, record) => (
        <Select
          value={record.status}
          onChange={value => this.handleUpdate(value, record)}
        >
          <Select.Option value={'open'}>Open</Select.Option>
          <Select.Option value={'in_progress'}>In Progress</Select.Option>
          <Select.Option value={'closed'}>Close</Select.Option>
        </Select>
      ),
    },
  ];

  componentDidUpdate(prevProps, prevState) {
    const { getQuoteRequest } = this.props;
    const { pageQuery, queryString } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getQuoteRequest({ ...pageQuery, query: queryString });
      } else {
        getQuoteRequest({ ...pageQuery });
      }
    }
  }

  componentDidMount() {
    const { getQuoteRequest } = this.props;
    const { pageQuery } = this.state;
    getQuoteRequest({ ...pageQuery });
  }

  handleUpdate = (value, record) => {
    if (record.status !== value) {
      this.setState({ modalOpened: 1, selectedRow: record, status: value });
    }
  };

  performUpdate = () => {
    const { updateQuoteRequest } = this.props;
    updateQuoteRequest({
      id: this.state.selectedRow.id,
      status: this.state.status,
    });
    this.setState({ modalOpened: false, selectedRow: null, status: '' });
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onShowSizeChange = (current, pageSize) => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = current;
    updatedPageQuery['per'] = pageSize;
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const { selectedRow, modalOpened, pageQuery } = this.state;

    const { loading, quoteRequests } = this.props;
    const { data, total } = quoteRequests;
    return (
      <div className="admins-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Quote Request</h4>
          </div>
        </div>
        <div className="page-content">
          <Card>
            <DataTable
              rowKey={record => record.id}
              loading={loading}
              data={data}
              columns={this.tableColumns()}
              onClickRow={() => {}}
              pagination={{
                limit: pageQuery['per'],
                total,
                page: pageQuery['page'],
                data,
              }}
              handleTableChange={this.onShowSizeChange}
            />
          </Card>
          <Modal
            className="delete-user-modal"
            visible={modalOpened === 1}
            onCancel={this.closeModal}
            onOk={this.performUpdate}
            okText="Yes"
            destroyOnClose
          >
            {selectedRow && (
              <>
                <h1 className="main-message pop-up-heading">Are you sure?</h1>
                <p className="pop-up-body">
                  Do you really want to update status? The process cannot be
                  undone.
                </p>
              </>
            )}
          </Modal>
        </div>
      </div>
    );
  }
}
QuoteRequest.propTypes = {
  loading: PropTypes.bool,
  quoteRequests: PropTypes.array,
  errors: PropTypes.array,
  getQuoteRequest: PropTypes.func,
  updateQuoteRequest: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.quote.loading,
    quoteRequests: state.quote.quoteRequests,
    errors: state.quote.errors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getQuoteRequest: keyword => dispatch(getQuoteRequest(keyword)),
    updateQuoteRequest: data => dispatch(updateQuoteRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteRequest);
