import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const closeModal = (uiState, updateUI) => {
  updateUI({
    ...uiState,
    selectedRow: null,
    modalOpened: false,
  });
};
const performDelete = (selectedRow, deleteService) => {
  deleteService(selectedRow);
};

const DeleteProduct = ({ uiState, updateUI, deleteService }) => {
  const { modalOpened, selectedRow } = uiState;
  return (
    <Modal
      className="delete-user-modal"
      visible={modalOpened === 2}
      onCancel={() => closeModal(uiState, updateUI)}
      onOk={() => performDelete(selectedRow, deleteService)}
      okText="Yes"
      destroyOnClose
    >
      {selectedRow && (
        <>
          <h1 className="main-message pop-up-heading">Are you sure?</h1>
          <p className="pop-up-body">
            Do you really want to remove this service? The process cannot be
            undone.
          </p>
        </>
      )}
    </Modal>
  );
};

DeleteProduct.propTypes = {
  uiState: PropTypes.object,
  updateUI: PropTypes.object,
  deleteService: PropTypes.func,
};

export default DeleteProduct;
