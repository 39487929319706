import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Typography, Avatar, Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DownOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import ProLayout, {
  PageHeaderWrapper,
  RouteContext,
} from '@ant-design/pro-layout';
import styled from 'styled-components';
import { dig } from 'digdata';
// import moment from 'moment';
// import QRCode from 'qrcode.react';

import Routes from './vendor-routes';
import { getImageURI, vendorLogout, impersonateExit } from './utils/apisauce';
import { getProfile } from './Vendor/pages/AuthPage/reducers';
import { showTabForCurrentUser } from './utils/helpers';
import 'antd/dist/antd.css';
import './assets/css/override.css';
import 'sendbird-uikit/dist/index.css';
import './vendor-app.css';

const MenuCloseIcon = styled(MenuFoldOutlined)`
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 10px;

  :hover {
    color: #1890ff;
  }
`;

const MenuOpenIcon = styled(MenuUnfoldOutlined)`
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 10px;

  :hover {
    color: #1890ff;
  }
`;

const LogoImage = styled.svg`
  margin-right: 5px;
`;

const { Footer } = Layout;
const { Title } = Typography;

const Header = props => {
  const {
    propsData,
    handleCollpase,
    data,
    renderSideMenu,
    userProfile,
    getProfile,
  } = props;

  React.useEffect(() => {
    getProfile();
  }, [getProfile]);

  let userData = {};

  if (userProfile && userProfile['vendor_user']) {
    userData = userProfile['vendor_user'];
  }
  const _vendorName =
    localStorage.getItem('vendor_name') !== null
      ? localStorage.getItem('vendor_name')
      : '';

  return (
    <div
      className="main-header-container"
      style={{ display: 'flex', alignItems: 'baseline' }}
    >
      <div
        className="header-drawer-icon"
        onClick={() => handleCollpase(!data.isCollapsed)}
      >
        {propsData.collapsedButtonRender(data.isCollapsed)}
      </div>
      <h3 className="route-name">{`${_vendorName}`}</h3>
      <div className="antd-header-dropdown main-header">
        {localStorage.getItem('vendor_access_token') !== null ? (
          <Dropdown overlay={renderSideMenu(userProfile)} trigger={['click']}>
            <div className="qr-one-container logout">
              <Avatar
                style={{
                  fontSize: 25,
                  margin: 'auto',
                  marginRight: 8,
                  marginTop: 15,
                }}
                src={getImageURI(dig(userData, 'picture_path.url'))}
                icon={<UserOutlined />}
                className="qr-one"
              />
              {userData && (
                <p className="qr-text">
                  {userData.email} <DownOutlined />
                </p>
              )}
            </div>
          </Dropdown>
        ) : (
          <p
            className="qr-text"
            style={{ cursor: 'pointer' }}
            onClick={impersonateExit}
          >
            Exit
          </p>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  propsData: PropTypes.object,
  handleCollpase: PropTypes.func,
  data: PropTypes.object,
  userProfile: PropTypes.object,
  collapsedButtonRender: PropTypes.func,
  renderSideMenu: PropTypes.func,
};

class VendorApplication extends React.PureComponent {
  state = {
    workerApp: null,
    clientApp: null,
    navTheme: 'light',
    theme: 'light',
    primaryColor: '#1890ff',
    layout: 'sidemenu',
    contentWidth: 'Fluid',
    fixSiderbar: false,
    fixedHeader: false,
    colorWeak: true,
    isCollapsed: false,
  };

  renderMenuHeader = (/* logo, title, props */) => {
    return (
      <RouteContext.Consumer>
        {value => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 64,
            }}
          >
            <LogoImage
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
            >
              <path
                d="M20.407 6.29862L18.4602 4.9116L18.3065 4.80154L18.0769 4.63836L16.4622 3.48472L13.0316 1.03512L12.8419 0.894712C12.7824 0.850439 12.7236 0.808695 12.6654 0.769481C12.0641 0.369861 11.3471 0.181176 10.627 0.233031C9.90688 0.284886 9.22434 0.574346 8.68648 1.05599V1.03323L5.27109 3.48472L3.82334 4.52262L3.77401 4.55867L3.2655 4.92108L1.46482 6.20565C1.0194 6.50336 0.654368 6.90645 0.402142 7.37912C0.149917 7.8518 0.0182995 8.37944 0.0189744 8.9152L0 16.5163C0.0010057 17.3797 0.344692 18.2074 0.955559 18.8176C1.56643 19.4277 2.39451 19.7705 3.25791 19.7705H5.43048C6.29271 19.7705 7.11974 19.4285 7.73014 18.8195C8.34054 18.2105 8.68447 17.3843 8.68648 16.522H3.25791V8.9152L5.91432 7.01776L8.20074 5.38216L8.68079 5.03872L10.8439 3.49231H10.8628L13.0259 5.03872L13.506 5.38216L15.8114 7.01965L18.4678 8.91709V16.5163H13.0316C13.0319 16.9439 13.1163 17.3673 13.2802 17.7622C13.4441 18.1572 13.6841 18.516 13.9866 18.8181C14.2892 19.1203 14.6482 19.3599 15.0434 19.5233C15.4385 19.6867 15.8619 19.7707 16.2895 19.7705H18.4602C19.3243 19.7705 20.1529 19.4272 20.7639 18.8162C21.3749 18.2053 21.7181 17.3766 21.7181 16.5125V8.9152C21.7195 8.40689 21.6016 7.90534 21.3739 7.45089C21.1462 6.99644 20.815 6.60177 20.407 6.29862Z"
                fill="#2B2B2B"
              />
            </LogoImage>

            {!value.collapsed && (
              <Title style={{ margin: 0 }} level={4}>
                Butler Vendor
              </Title>
            )}
          </div>
        )}
      </RouteContext.Consumer>
    );
  };

  renderMenuItem = (route, defaultDom) => {
    return <Link to={route.path}>{defaultDom}</Link>;
  };

  renderFooter = props => {
    if (props.location.pathname === '/scheduler') {
      return null;
    }
    return (
      <Footer className={props.classname} style={{ textAlign: 'center' }}>
        Copyright Butler © 2020
      </Footer>
    );
  };

  renderSideMenu = userProfile => {
    return (
      <Menu>
        {userProfile &&
          userProfile['vendor_user'] &&
          (userProfile.vendor_user?.first_name ||
            userProfile.vendor_user?.last_name) && (
            <Menu.Item key="2">
              <UserOutlined />{' '}
              {userProfile.vendor_user?.first_name
                ? userProfile.vendor_user.first_name
                : ''}{' '}
              {userProfile.vendor_user?.last_name
                ? userProfile.vendor_user.last_name
                : ''}
            </Menu.Item>
          )}
        <Menu.Item onClick={vendorLogout}>
          <LogoutOutlined />
          {' Logout.'}
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    if (!this.props.authenticated) {
      return <Redirect to="/vendor/login" />;
    }

    let menus = [];
    const routes = [];
    for (const route of Routes) {
      if (route.menu) {
        const { Icon, iconProps } = route.menu;
        const showTab = showTabForCurrentUser(
          route.visibilityDependency,
          route.name
        );
        if (showTab) {
          menus.push({
            exact: true,
            path: route.path,
            name: route.menu.name,
            icon: <Icon {...iconProps} />,
            children: route?.children,
          });
        }
      }

      const Component = route.component;
      const component = route.disableWrapper
        ? Component
        : props => {
            if (!Component) {
              return null;
            }
            return (
              <PageHeaderWrapper style={{ marginLeft: 24, marginRight: 24 }}>
                <Component {...props} />
              </PageHeaderWrapper>
            );
          };
      if (route.isStandalone) {
        for (const path of route.path) {
          const showTab = showTabForCurrentUser(
            route.visibilityDependency,
            route.name
          );
          if (showTab) {
            routes.push(
              <Route
                exact={true}
                key={path}
                path={path}
                component={component}
              />
            );
          }
        }
      } else {
        const showTab = showTabForCurrentUser(
          route.visibilityDependency,
          route.name
        );
        if (showTab) {
          routes.push(
            <Route
              exact={true}
              key={route.path}
              path={route.path}
              component={component}
            />
          );
        }
      }
    }

    let tabs = [];
    if (localStorage.routes) tabs = JSON.parse(localStorage.routes);
    if (tabs && tabs[0] === 'all') {
      // do nothing
    } else {
      menus = menus.filter(item => {
        return tabs.includes(item.name.toLowerCase());
      });
    }

    routes.push(<Redirect to={routes[0]['key']} />);

    return (
      <Router>
        <Switch>
          <ProLayout
            {...this.state}
            title="Butler Vendor"
            route={{ routes: menus }}
            headerRender={props => (
              <Header
                propsData={props}
                handleCollpase={status =>
                  this.setState({ isCollapsed: status })
                }
                data={this.state}
                userProfile={this.props.profile}
                getProfile={this.props.getProfile}
                renderSideMenu={this.renderSideMenu}
              />
            )}
            footerRender={this.renderFooter}
            menuItemRender={this.renderMenuItem}
            menuHeaderRender={this.renderMenuHeader}
            collapsed={this.state.isCollapsed}
            // onPageChange={(e) => console.log(e,'test')}
            onCollapse={isCollapsed => this.setState({ isCollapsed })}
            collapsedButtonRender={collapsed =>
              collapsed ? <MenuOpenIcon /> : <MenuCloseIcon />
            }
          >
            <Switch>{routes}</Switch>
            {/* <SettingDrawer collapse={true} settings={this.state} onSettingChange={this.setState} /> */}
          </ProLayout>
        </Switch>
      </Router>
      // <h2>vendor logedn in</h2>
    );
  }
}

VendorApplication.propTypes = {
  authenticated: PropTypes.bool,
  profile: PropTypes.object,
  vendorName: PropTypes.string,
  getProfile: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    authenticated: state.vendorAuth.authenticated,
    profile: state.vendorAuth.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProfile: () => dispatch(getProfile()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorApplication);
