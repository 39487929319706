import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { getImageURI } from '../../../utils/apisauce';
import { UserOutlined } from '@ant-design/icons';
import { StarFilled } from '@ant-design/icons';
import moment from 'moment';
import { dig } from 'digdata';

const statusLabels = {
  pending_schedule: {
    color: '#F1B13B',
    label: 'Unassigned',
  },
  delayed: {
    color: '#F1B13B',
    label: 'Delayed',
  },
  scheduled: {
    color: '#34A6BD',
    label: 'In Scheduled',
  },
  rescheduled: {
    color: '#34A6BD',
    label: 'Rescheduled',
  },
  in_progress: {
    color: '#6ABB98',
    label: 'In Progress',
  },
  completed: {
    color: '#828282',
    label: 'Completed',
  },
  cancelled: {
    color: '#DF5E67',
    label: 'Cancelled',
  },
  skipped: {
    color: '#DF5E67',
    label: 'Skipped',
  },
};

const customerTableColumns = () => [
  {
    title: 'ID',
    key: 'id',
    render: function jobId(text, record) {
      return <Link to={`/jobs#${record['id']}`}>{record['id']}</Link>;
    },
  },
  {
    title: 'Service Date',
    key: 'service_date',
    dataIndex: 'service_date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      const dateA = new Date(a.service_date).getTime();
      const dateB = new Date(b.service_date).getTime();
      return dateA - dateB;
    },
    render: (_, record) =>
      record?.service_date
        ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '-',
  },
  {
    title: 'Status',
    key: 'job_status',
    render: function Status(_, record) {
      return record?.job_status ? (
        <span style={{ color: statusLabels[record.job_status]?.color }}>
          {statusLabels[record.job_status]?.label}
        </span>
      ) : (
        '-'
      );
    },
  },
  {
    title: 'Job Title',
    key: 'title',
    dataIndex: 'title',
  },
  {
    title: 'Assigned Vendor',
    key: 'vendor',
    render: (text, record) =>
      record?.assigned_vendor ? record['assigned_vendor'] : '-',
  },
  {
    title: 'Assigned Vendor',
    key: 'vendor',
    render: (text, record) =>
      record?.assigned_worker ? record['assigned_worker'] : '-',
  },
  {
    title: 'Preferred Time',
    key: 'preferred_time',
    render: (_, record) =>
      record?.preferred_time ? record['preferred_time'] : '-',
  },
  {
    title: 'Assigned Time',
    key: 'assigned_time',
    render: (_, record) =>
      record?.assigned_time ? record['assigned_time'] : '-',
  },
  {
    title: 'Service Duration',
    key: 'job_duration_in_minutes',
    width: 120,
    render: (_, record) =>
      record?.job_duration_in_minutes
        ? record['job_duration_in_minutes'] + ' min'
        : '-',
  },
  {
    title: 'Check In',
    key: 'checkin_time',
    render: (_, record) => (record?.check_in_time ? record.check_in_time : '-'),
  },
  {
    title: 'Check Out',
    key: 'checkout_time',
    render: (_, record) =>
      record?.check_out_time ? record.check_out_time : '-',
  },
  {
    title: 'Active Job Duration',
    width: 130,
    render: (_, record) => {
      if (record?.check_in_time && record?.check_out_time) {
        return record['active_duration_in_minutes'];
      } else {
        return '-';
      }
    },
  },
  {
    title: 'Job Notes',
    key: 'job_notes',

    render: (_, record) =>
      record?.job_notes
        ? `${
            record['job_notes']['length'] > 25
              ? record['job_notes'].slice(0, 25) + '...'
              : record['job_notes']
          }`
        : '-',
  },
  {
    title: 'Customer Notes',
    key: 'customer_notes',
    width: 120,
    render: (_, record) =>
      record?.customer_notes
        ? `${
            record['customer_notes']['length'] > 25
              ? record['customer_notes'].slice(0, 25) + '...'
              : record['customer_notes']
          }`
        : '-',
  },
  {
    title: 'Rating',
    key: 'rating',
    dataIndex: 'rating',
    width: 60,
    render: function Rating(_, record) {
      return record?.job_rating !== null ? (
        <div style={{ width: '60px', textAlign: 'center' }}>
          {generateRateStar(record['job_rating'])}
        </div>
      ) : (
        ''
      );
    },
  },
  {
    title: 'Rating Feedback',
    key: 'rating_feedback',
    render: (_, record) =>
      record?.rating_feedback !== null ? record['rating_feedback'] : '',
  },
  {
    title: 'Repeating',
    key: 'job_repeating',
    render: (_, record) => {
      if (record['repeating_job_id']) {
        return <span style={{ color: '#059c5a' }}>Yes</span>;
      } else {
        return <span style={{ color: '#DF5E67' }}>No</span>;
      }
    },
  },
];

const workerTableColumns = () => [
  {
    title: 'ID',
    key: 'id',
    render: function jobId(text, record) {
      return <Link to={`/jobs#${record['id']}`}>{record['id']}</Link>;
    },
  },
  {
    title: 'Service Date',
    key: 'service_date',
    dataIndex: 'service_date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateA - dateB;
    },
    render: (_, record) =>
      record.date
        ? moment(record.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '-',
  },
  {
    title: 'Status',
    key: 'job_status',
    render: function Status(_, record) {
      return (
        <span style={{ color: statusLabels[record.job_status].color }}>
          {statusLabels[record.job_status].label}
        </span>
      );
    },
  },
  {
    title: 'Job Title',
    key: 'title',
    dataIndex: 'title',
  },
  {
    title: 'Customer Name',
    key: 'customer_name',
    render: (text, record) =>
      record['customer_name'] ? record['customer_name'] : '-',
  },
  {
    title: 'Customer Address',
    key: 'address',
    render: (text, record) => (record['address'] ? record['address'] : '-'),
  },
  {
    title: 'Unit',
    key: 'unit',
    render: (text, record) => (record['unit'] ? record['unit'] : '-'),
  },
  {
    title: 'Postal Code',
    key: 'postal_code',
    render: (text, record) =>
      record['postal_code'] ? record['postal_code'] : '-',
  },
  {
    title: 'Service Duration',
    key: 'job_duration_in_minutes',
    width: 120,
    render: (_, record) =>
      record['job_duration_in_minutes']
        ? record['job_duration_in_minutes'] + ' min'
        : '-',
  },
  {
    title: 'Check In',
    key: 'check_in_time',
    render: (_, record) => (record.check_in_time ? record.check_in_time : '-'),
  },
  {
    title: 'Check Out',
    key: 'check_out_time',
    render: (_, record) =>
      record.check_out_time ? record.check_out_time : '-',
  },
  {
    title: 'Active Job Duration',
    width: 130,
    render: (_, record) => {
      if (record?.check_in_time && record?.check_out_time) {
        return record['active_duration_in_minutes'];
      } else {
        return '-';
      }
    },
  },
  // {
  //   title: 'Job Notes',
  //   key: 'job_notes',

  //   render: (_, record) =>
  //     record['job_notes']
  //       ? `${
  //           record['job_notes']['length'] > 25
  //             ? record['job_notes'].slice(0, 25) + '...'
  //             : record['job_notes']
  //         }`
  //       : '-',
  // },
  // {
  //   title: 'Customer Notes',
  //   key: 'customer_notes',
  //   width: 120,
  //   render: (_, record) =>
  //     record['customer_notes']
  //       ? `${
  //           record['customer_notes']['length'] > 25
  //             ? record['customer_notes'].slice(0, 25) + '...'
  //             : record['customer_notes']
  //         }`
  //       : '-',
  // },
  // {
  //   title: 'Rating',
  //   key: 'rating',
  //   dataIndex: 'rating',
  //   width: 60,
  //   render: function Rating(_, record) {
  //     return record['job_rating'] !== null ? (
  //       <div style={{ width: '60px', textAlign: 'center' }}>
  //         {generateRateStar(record['job_rating'])}
  //       </div>
  //     ) : (
  //       ''
  //     );
  //   },
  // },
  // {
  //   title: 'Rating Feedback',
  //   key: 'rating_feedback',
  //   render: (_, record) =>
  //     record['rating_feedback'] !== null ? record['rating_feedback'] : '',
  // },
  // {
  //   title: 'Status',
  //   key: 'job_status',
  //   render: function Status(_, record) {
  //     return (
  //       <span style={{ color: statusLabels[record.job_status].color }}>
  //         {statusLabels[record.job_status].label}
  //       </span>
  //     );
  //   },
  // },
  {
    title: 'Repeating',
    key: 'job_repeating',
    render: (_, record) => {
      if (record['repeating_job_id']) {
        return <span style={{ color: '#059c5a' }}>Yes</span>;
      } else {
        return <span style={{ color: '#DF5E67' }}>No</span>;
      }
    },
  },
];

const groupedWorkerTable = () => [
  {
    title: 'Service Date',
    key: 'service_date',
    dataIndex: 'service_date',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateA - dateB;
    },
    render: (_, record) =>
      record.service_date
        ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '-',
  },
  {
    title: 'Job Counts',
    key: 'jobs_count',
    render: (_, record) => (record?.jobs_count ? record.jobs_count : '-'),
  },
  {
    title: 'Start Time',
    key: 'start_time',
    render: (text, record) =>
      record['start_time'] ? record['start_time'] : '-',
  },
  {
    title: 'End Time',
    key: 'end_time',
    render: (text, record) => (record['end_time'] ? record['end_time'] : '-'),
  },
  {
    title: 'Service Duration',
    key: 'total_service_duration',
    render: (text, record) =>
      record['total_service_duration']
        ? Math.floor(record['total_service_duration'] / 60) +
          'h ' +
          (record['total_service_duration'] % 60) +
          'm'
        : '-',
  },
  {
    title: 'Service Duration Completed',
    key: 'completed_service_duation',
    render: (text, record) =>
      record['completed_service_duation']
        ? Math.floor(record['completed_service_duation'] / 60) +
          'h ' +
          (record['completed_service_duation'] % 60) +
          'm'
        : '-',
  },
  {
    title: 'Active Job Duration',
    key: 'total_duration',
    render: (text, record) =>
      record['total_duration'] ? record['total_duration'] : '-',
  },
];

const workerDailyTableColumns = () => [
  {
    title: 'Worker Id',
    key: 'worker_id',
    dataIndex: 'worker_id',
  },
  {
    title: '',
    key: 'avatar',
    render: function avatar(_, record) {
      return (
        <Avatar
          src={getImageURI(dig(record, 'picture_path.url'))}
          icon={<UserOutlined />}
        />
      );
    },
  },
  {
    title: 'Worker Name',
    key: 'worker_name',
    dataIndex: 'worker_name',
  },
  {
    title: 'Completed Jobs',
    key: 'completed_jobs',
    render: (text, record) =>
      `${record['completed_jobs']} of ${record['total_jobs']}`,
  },
  {
    title: 'Start Time',
    key: 'start_time',
    render: (text, record) =>
      record['start_time'] ? record['start_time'] : '-',
  },
  {
    title: 'End Time',
    key: 'end_time',
    render: (text, record) => (record['end_time'] ? record['end_time'] : '-'),
  },
  {
    title: 'Service Duration',
    key: 'total_service_duration',
    render: (text, record) =>
      record['total_service_duration']
        ? Math.floor(record['total_service_duration'] / 60) +
          'h ' +
          (record['total_service_duration'] % 60) +
          'm'
        : '-',
  },
  {
    title: 'Service Duration Completed',
    key: 'completed_service_duation',
    render: (text, record) =>
      record['completed_service_duation']
        ? Math.floor(record['completed_service_duation'] / 60) +
          'h ' +
          (record['completed_service_duation'] % 60) +
          'm'
        : '-',
  },
  {
    title: 'Total Working Duration',
    key: 'total_jobs_active_duration_in_minutes',
    render: (text, record) =>
      calculate_total_working_dur(record['start_time'], record['end_time']),
  },
  {
    title: 'Total Active Job Duration',
    key: 'total_jobs_active_duration_in_minutes',
    render: (text, record) =>
      `${record['total_jobs_active_duration_in_minutes']} min`,
  },
  {
    title: 'Transport Time',
    key: 'total_jobs_active_duration_in_minutes',
    render: (text, record) =>
      calculate_transport_time(
        record['start_time'],
        record['end_time'],
        record['total_jobs_active_duration_in_minutes']
      ),
  },
  {
    title: 'Efficiency',
    key: 'total_jobs_active_duration_in_minutes',
    render: (text, record) =>
      calculate_efficiency(
        record['start_time'],
        record['end_time'],
        record['total_jobs_active_duration_in_minutes']
      ),
  },
];

function calculate_total_working_dur(start_time, end_time) {
  if (!start_time || !end_time) {
    return '-';
  } else {
    let duration = moment.duration(
      moment(end_time, 'HH:mm:ss a').diff(moment(start_time, 'HH:mm:ss a'))
    );

    return (
      Math.floor(
        duration.hours() * 60 + duration.minutes() + duration.seconds() / 60
      ) + ' min'
    );
  }
}

function calculate_transport_time(
  start_time,
  end_time,
  total_job_active_duration
) {
  if (!start_time || !end_time) {
    return '-';
  } else {
    let duration = moment.duration(
      moment(end_time, 'HH:mm:ss a').diff(moment(start_time, 'HH:mm:ss a'))
    );
    duration = Math.floor(
      duration.hours() * 60 + duration.minutes() + duration.seconds() / 60
    );
    duration = duration - total_job_active_duration;

    return duration + ' min';
  }
}

function calculate_efficiency(start_time, end_time, total_job_active_duration) {
  if (!start_time || !end_time) {
    return '-';
  } else {
    let duration = moment.duration(
      moment(end_time, 'HH:mm:ss a').diff(moment(start_time, 'HH:mm:ss a'))
    );
    duration = Math.floor(
      duration.hours() * 60 + duration.minutes() + duration.seconds() / 60
    );
    let efficiency = parseFloat(
      (total_job_active_duration / duration) * 100
    ).toFixed(2);

    return efficiency + ' %';
  }
}

function generateRateStar(star) {
  let stars = new Array(star).fill('');
  return stars.map((val, index) => {
    return (
      <StarFilled style={{ fontSize: '12px', color: 'gold' }} key={index} />
    );
  });
}

export {
  customerTableColumns,
  workerTableColumns,
  groupedWorkerTable,
  workerDailyTableColumns,
  generateRateStar,
  statusLabels,
};
