import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import HistoryTable from '../../components/HistoryTable';
import { getNotifications } from './reducers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function HistoryTab(props) {
  const { getNotifications, notificationsList, loadingNotifications, meta } =
    props;
  const [pageQuery, setPageQuery] = useState({ per: 20, page: 1 });

  useEffect(() => {
    getNotifications(pageQuery);
  }, []);

  function onPageChange(newPage) {
    setPageQuery({ ...pageQuery, page: newPage });
  }

  function handleTableChange(pagination) {
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    getNotifications(updatedPageQuery);
  }

  return (
    <Card className="tab-container">
      <HistoryTable
        loading={loadingNotifications}
        dataSource={notificationsList}
        pagination={{
          limit: meta['per'],
          total: meta['total_count'],
          page: meta['page'],
          data: notificationsList,
          onChange: onPageChange,
        }}
        handleTableChange={handleTableChange}
      />
    </Card>
  );
}

HistoryTab.propTypes = {
  getNotifications: PropTypes.func,
  notificationsList: PropTypes.array,
  loadingNotifications: PropTypes.bool,
  meta: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loadingNotifications: state.pushNotification.loadingNotifications,
    notificationsList: state.pushNotification.notificationsList,
    meta: state.pushNotification.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNotifications: payload => dispatch(getNotifications(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
