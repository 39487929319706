import { LeftOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { searchVendors, handleVendorChange } from '../reducers';
import JobCard from './jobCard';
// import { JobContext } from './../index';
import 'react-perfect-scrollbar/dist/css/styles.css';

const { Option } = Select;

class VendorList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fixed: false,
      screenDimensions: [0, 0],
    };
  }

  updateSize = () => {
    this.setState({
      screenDimensions: [window.innerWidth, window.innerHeight],
    });
  };

  componentDidMount() {
    this.fetchVendors();
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps) {
    const { currentDate } = this.props;
    if (currentDate && currentDate !== prevProps.currentDate) {
      this.fetchVendors();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.updateSize);
  }

  fetchVendors = () => {
    const { searchVendors, currentDate } = this.props;

    searchVendors({
      date: currentDate.format('YYYY-MM-DD'),
    });
  };

  onScroll = () => {
    this.setState({
      fixed: window.scrollY > 161,
    });
  };

  selectVendor = vendor => {
    const { handleVendorChange } = this.props;

    handleVendorChange(vendor);
  };

  getItemStyle = (isDragging, draggableStyle) => {
    if (isDragging) {
      return {
        ...draggableStyle,
        height: 55,
      };
    }

    return {
      ...draggableStyle,
      margin: '8px 0',
    };
  };

  getListStyle = (isDraggingOver, jobCount) => {
    if (isDraggingOver) {
      return {
        border: '1px solid #6ABB98',
        background: 'rgba(106, 187, 152, 0.1)',
        padding: 5,
        borderRadius: 5,
        width: 220,
        minHeight: jobCount * 120 < 550 ? 550 : jobCount * 115 + 120,
      };
    }

    return {
      width: 220,
      minHeight: jobCount * 120 < 550 ? 550 : jobCount * 115,
    };
  };

  render() {
    const {
      loadingVendors,
      vendors,
      vendorSelected,
      collapsed,
      handleCollapse,
      setSchedulerState,
      // reassignedJobIds,
      workers,
      allowUpdate,
      currentDate,
      loadingWorkers,
    } = this.props;

    const { fixed, screenDimensions } = this.state;

    let vendorSelectedObj = null;

    if (vendorSelected && vendors) {
      vendorSelectedObj = vendors.find(item => item['id'] === vendorSelected);
    }

    return (
      <div
        className={`vendor-list ${fixed ? 'fixed ' : ''}${
          collapsed ? 'collapsed' : 'expanded'
        }`}
      >
        <div className="vendor-list-container">
          <PerfectScrollbar id="vendorRef">
            <div className="vendor-list-content">
              <div
                className="list-header"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h3>Vendor</h3>
                {this.props.reloadShow ? (
                  <Button
                    // shape="circle"
                    type="link"
                    style={{ border: 0 }}
                    onClick={this.props.handleReload}
                    loading={loadingWorkers}
                    icon={<SyncOutlined style={{ margin: 0 }} />}
                  />
                ) : (
                  ''
                )}
              </div>

              {vendors && vendors.length ? (
                <>
                  <Select
                    value={vendorSelected}
                    style={{ width: '100%', marginBottom: 15 }}
                    onChange={this.selectVendor}
                    placeholder="Choose Vendor"
                  >
                    {vendors.map((el, i) => {
                      let name = '';

                      if (el['vendor_name'].length > 20) {
                        name = el['vendor_name'].slice(0, 20) + '...';
                      } else {
                        name = el['vendor_name'];
                      }

                      name += `(${el['assigned_jobs_count']}, ${el['unassigned_jobs']['length']})`;

                      return (
                        <Option key={i} value={el.id} title={name}>
                          {name}
                        </Option>
                      );
                    })}
                  </Select>

                  {vendorSelected && vendorSelectedObj && (
                    <>
                      <div className="vendor-info">
                        <p>{vendorSelectedObj.vendor_name}</p>
                        <p>Vendor ID: {vendorSelectedObj.id}</p>
                        <p>Total worker: {workers['length']} person</p>
                      </div>

                      {loadingVendors ? (
                        <div className="loading-overlay">
                          <LoadingOutlined />
                        </div>
                      ) : (
                        <div className="list-content">
                          <div className="list-content-header">
                            <h4>
                              {vendorSelectedObj['unassigned_jobs']['length']}{' '}
                              Unassigned Jobs
                            </h4>
                          </div>
                          <Droppable
                            droppableId="unassign"
                            ignoreContainerClipping={true}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="list-content-body"
                                style={this.getListStyle(
                                  snapshot.isDraggingOver,
                                  vendorSelectedObj['unassigned_jobs'].length
                                )}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {vendorSelectedObj['unassigned_jobs'].map(
                                  el => (
                                    <Draggable
                                      key={`${el.id}`}
                                      draggableId={`${el.id}`}
                                      index={el.id}
                                      isDragDisabled={
                                        screenDimensions[0] <= 576
                                      }
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={this.getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                        >
                                          {provided.placeholder}
                                          {/* <JobContext.Consumer>
                                            {context => ( */}
                                          <JobCard
                                            vendorId={vendorSelectedObj['id']}
                                            allowUpdate={allowUpdate}
                                            checked={
                                              el.repeating_job_id
                                                ? el.permanentlyReassignedJobsId?.includes(
                                                    el.repeating_job_id
                                                  )
                                                : false
                                            }
                                            setSchedulerState={
                                              setSchedulerState
                                            }
                                            permanentReassign={
                                              el['permanentReassign']
                                            }
                                            permanentReassignSelectedDay={
                                              el['permanentReassignSelectedDay']
                                            }
                                            // handlePermReassignSelectedDay={
                                            //   context[
                                            //     'handlePermReassignSelectedDay'
                                            //   ]
                                            // }
                                            // checkboxOnChange={
                                            //   context['checkboxOnChange']
                                            // }
                                            dragging={snapshot.isDragging}
                                            currentDate={currentDate}
                                            job={el}
                                            identifier="vendor-list"
                                            workers={workers}
                                            areaRef={'vendorRef'}
                                          />
                                          {/* )}
                                          </JobContext.Consumer> */}
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : loadingVendors ? (
                <div className="loading-overlay">
                  <LoadingOutlined />
                </div>
              ) : (
                <p>Empty Data</p>
              )}
            </div>
          </PerfectScrollbar>
        </div>

        <Button
          type="link"
          className="btn-collapse"
          icon={<LeftOutlined />}
          onClick={handleCollapse}
        />
      </div>
    );
  }
}

VendorList.propTypes = {
  loadingVendors: PropTypes.bool,
  vendors: PropTypes.array,
  vendorSelected: PropTypes.number,
  collapsed: PropTypes.bool,
  handleCollapse: PropTypes.func,
  setSchedulerState: PropTypes.func,
  reassignedJobIds: PropTypes.array,
  workers: PropTypes.array,
  assignedJobsLength: PropTypes.number,
  allowUpdate: PropTypes.bool,
  handleVendorChange: PropTypes.func,
  currentDate: PropTypes.object,
  searchVendors: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loadingVendors: state.scheduler.loadingVendors,
    loadingWorkers: state.scheduler.loadingWorkers,
    vendors: state.scheduler.vendors,
    workers: state.scheduler.workers,
    vendorSelected: state.scheduler.vendorSelected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleVendorChange: v => dispatch(handleVendorChange(v)),
    searchVendors: params => dispatch(searchVendors(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
