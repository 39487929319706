import React from 'react';
import PropTypes from 'prop-types';
import {
  // Avatar,
  Modal,
} from 'antd';

DeleteModal.propTypes = {
  onClose: PropTypes.func,
  onAssign: PropTypes.func,
  visible: PropTypes.bool,
  job: PropTypes.object,
  worker: PropTypes.object,
  time: PropTypes.string,
};

function DeleteModal(props) {
  return (
    <Modal
      className="delete-user-modal"
      visible={props.visible}
      onCancel={props.onClose}
      onOk={props.onAssign}
      okText="Yes"
      destroyOnClose
    >
      {
        <>
          <div className="user-info"></div>
          <p className="main-message">
            Are you sure want to assing Job id {props.job.id} to worker{' '}
            {props.worker?.first_name} under {props.time} time?
          </p>
          <p>This action can not be undone</p>
        </>
      }
    </Modal>
  );
}

export default DeleteModal;
