import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'scheduler',
  initialState: {
    loadingVendors: false,
    loadingNotes: false,
    loadingWorkers: false,
    vendors: [],
    workers: [],
    notes: [],
    newNotes: [],
    error: '',
    vendorSelected: undefined,
    currentWorkerPage: 0,
    totalWorkerPages: 0,
    totalWorkers: 0,
  },
  reducers: {
    handleVendorChange: (state, { payload }) => {
      state.vendorSelected = payload;
    },
    formatWorkerContent: state => {
      state.workers = [];
      state.currentWorkerPage = 0;
      state.totalWorkerPages = 0;
      state.totalWorkers = 0;
    },
    searchVendors: state => {
      state.loadingVendors = true;
    },
    searchVendorsSuccess: (state, { payload }) => {
      state.loadingVendors = false;
      let updatedVendors = [...payload['vendors']];
      updatedVendors = updatedVendors.map(vendor => {
        if (vendor['unassigned_jobs'] && vendor['unassigned_jobs']['length']) {
          return {
            ...vendor,
            unassigned_jobs: vendor['unassigned_jobs'].map(job => ({
              ...job,
              permanentReassign: false,
              permanentReassignSelectedDay: false,
              permanentlyReassignedJobsId: [],
              permanentReassignSelectedDayString: '',
            })),
          };
        } else {
          return vendor;
        }
      });
      state.vendors = updatedVendors;
    },
    searchVendorsFailed: (state, { payload }) => {
      state.loadingVendors = false;
      state.error = payload;
      apiError(payload);
    },
    searchWorkers: state => {
      state.loadingWorkers = true;
    },
    searchWorkersSuccess: (state, { payload }) => {
      let updatedVendorUsers = [...state.workers, ...payload.vendor_users];
      updatedVendorUsers = updatedVendorUsers.map(vendorUser => {
        if (vendorUser['jobs'] && vendorUser['jobs']['length']) {
          return {
            ...vendorUser,
            jobs: vendorUser['jobs'].map(job => ({
              ...job,
              permanentReassign: false,
              permanentReassignSelectedDay: false,
              permanentlyReassignedJobsId: [],
              permanentReassignSelectedDayString: '',
            })),
          };
        } else {
          return vendorUser;
        }
      });
      return {
        ...state,
        loadingWorkers: false,
        workers: updatedVendorUsers,
        currentWorkerPage: parseInt(payload.meta.page),
        totalWorkerPages: parseInt(payload.meta.total_pages),
        totalWorkers: parseInt(payload.meta.total_count),
      };
    },
    handleOnPermanentReassignChange: (state, { payload }) => {
      const { ev, job, identifier, vendorId, workerId } = payload;

      if (identifier === 'vendor-list') {
        let updatedVendors = [...state.vendors];
        const vIndex = updatedVendors.findIndex(
          item => item['id'] === vendorId
        );

        updatedVendors[vIndex]['unassigned_jobs'] = updatedVendors[vIndex][
          'unassigned_jobs'
        ].map(snap => {
          if (snap['id'] === job['id']) {
            let updatedPermanentlyReassignedJobsId = [
              ...snap['permanentlyReassignedJobsId'],
            ];

            if (ev['target']['checked']) {
              const idExists = snap['permanentlyReassignedJobsId'].find(
                id => id === job.repeating_job_id
              );
              if (!idExists) {
                updatedPermanentlyReassignedJobsId = [
                  ...snap['permanentlyReassignedJobsId'],
                  job.repeating_job_id,
                ];
              }
            } else {
              updatedPermanentlyReassignedJobsId = snap[
                'permanentlyReassignedJobsId'
              ].filter(id => id !== job.repeating_job_id);
            }

            return {
              ...snap,
              permanentReassign: ev['target']['checked'],
              permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
            };
          } else {
            return snap;
          }
        });

        state.vendors = updatedVendors;
      } else if (identifier === 'worker-list') {
        let updatedWorkers = [...state.workers];

        const wIndex = updatedWorkers.findIndex(
          item => item['id'] === workerId
        );

        updatedWorkers[wIndex]['jobs'] = updatedWorkers[wIndex]['jobs'].map(
          snap => {
            if (snap['id'] === job['id']) {
              let updatedPermanentlyReassignedJobsId = [
                ...snap['permanentlyReassignedJobsId'],
              ];

              if (ev['target']['checked']) {
                const idExists = snap['permanentlyReassignedJobsId'].find(
                  id => id === job.repeating_job_id
                );
                if (!idExists) {
                  updatedPermanentlyReassignedJobsId = [
                    ...snap['permanentlyReassignedJobsId'],
                    job.repeating_job_id,
                  ];
                }
              } else {
                updatedPermanentlyReassignedJobsId = snap[
                  'permanentlyReassignedJobsId'
                ].filter(id => id !== job.repeating_job_id);
              }

              return {
                ...snap,
                permanentReassign: ev['target']['checked'],
                permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
              };
            } else {
              return snap;
            }
          }
        );

        state.workers = updatedWorkers;
      } else {
        return state;
      }
    },
    handleOnPermReassignSelectedDayChange: (state, { payload }) => {
      const { ev, job, identifier, vendorId, workerId, currentDate } = payload;

      if (identifier === 'vendor-list') {
        let updatedVendors = [...state.vendors];
        const vIndex = updatedVendors.findIndex(
          item => item['id'] === vendorId
        );

        updatedVendors[vIndex]['unassigned_jobs'] = updatedVendors[vIndex][
          'unassigned_jobs'
        ].map(snap => {
          if (snap['id'] === job['id']) {
            let updatedPermanentlyReassignedJobsId = [
              ...snap['permanentlyReassignedJobsId'],
            ];
            const dayString = currentDate.format('dddd').toLowerCase();

            if (ev['target']['checked']) {
              const idExists = snap['permanentlyReassignedJobsId'].find(
                id => id === job.repeating_job_id
              );
              if (!idExists) {
                updatedPermanentlyReassignedJobsId = [
                  ...snap['permanentlyReassignedJobsId'],
                  job.repeating_job_id,
                ];
              }
            } else {
              updatedPermanentlyReassignedJobsId = snap[
                'permanentlyReassignedJobsId'
              ].filter(id => id !== job.repeating_job_id);
            }

            return {
              ...snap,
              permanentReassignSelectedDay: ev['target']['checked'],
              permanentReassignSelectedDayString: ev['target']['checked']
                ? dayString
                : '',
              permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
            };
          } else {
            return snap;
          }
        });

        state.vendors = updatedVendors;
      } else if (identifier === 'worker-list') {
        let updatedWorkers = [...state.workers];

        const wIndex = updatedWorkers.findIndex(
          item => item['id'] === workerId
        );

        updatedWorkers[wIndex]['jobs'] = updatedWorkers[wIndex]['jobs'].map(
          snap => {
            if (snap['id'] === job['id']) {
              let updatedPermanentlyReassignedJobsId = [
                ...snap['permanentlyReassignedJobsId'],
              ];
              const dayString = currentDate.format('dddd').toLowerCase();

              if (ev['target']['checked']) {
                const idExists = snap['permanentlyReassignedJobsId'].find(
                  id => id === job.repeating_job_id
                );
                if (!idExists) {
                  updatedPermanentlyReassignedJobsId = [
                    ...snap['permanentlyReassignedJobsId'],
                    job.repeating_job_id,
                  ];
                }
              } else {
                updatedPermanentlyReassignedJobsId = snap[
                  'permanentlyReassignedJobsId'
                ].filter(id => id !== job.repeating_job_id);
              }

              return {
                ...snap,
                permanentReassignSelectedDay: ev['target']['checked'],
                permanentReassignSelectedDayString: ev['target']['checked']
                  ? dayString
                  : '',
                permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
              };
            } else {
              return snap;
            }
          }
        );

        state.workers = updatedWorkers;
      } else {
        return state;
      }
    },
    searchWorkersFailed: (state, { payload }) => {
      state.loadingWorkers = false;
      state.error = payload;
      apiError(payload);
    },
    assignJobToWorker: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    assignJobToWorkerSuccess: (state, { payload }) => {
      state.assign();
      const updatedVendors = [...state.vendors].map(vendor => {
        if (vendor['id'] === state.vendorSelected) {
          return {
            ...vendor,
            unassigned_jobs: vendor['unassigned_jobs'].filter(
              el => el.id !== payload.response.id
            ),
          };
        } else {
          return vendor;
        }
      });
      state.vendors = updatedVendors;
      state.workers[payload.workerIndex].jobs.push({
        ...payload.response,
        permanentReassign: false,
        permanentReassignSelectedDay: false,
        permanentlyReassignedJobsId: [],
        permanentReassignSelectedDayString: '',
      });
      notification.success({
        message: 'Notice',
        description: 'Job assigned successfully',
      });
    },
    assignJobToWorkerFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    reassignJobToWorker: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    reassignJobToWorkerSuccess: (state, { payload }) => {
      state.assign();
      const idx = state.workers[payload.srcIndex].jobs.findIndex(
        el => el.id === payload.response.id
      );
      if (payload.destIndex === payload.srcIndex) {
        state.workers[payload.destIndex].jobs[idx] = {
          ...state.workers[payload.destIndex].jobs[idx],
          ...payload.response,
        };
      } else {
        state.workers[payload.srcIndex].jobs.splice(idx, 1);
        state.workers[payload.destIndex].jobs.push({
          ...payload.response,
          permanentReassign: false,
          permanentReassignSelectedDay: false,
          permanentlyReassignedJobsId: [],
          permanentReassignSelectedDayString: '',
        });
      }
      notification.success({ message: 'Job reassigned successfully' });
    },
    reassignJobToWorkerFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    unassignJobFromWorker: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    unassignJobFromWorkerSuccess: (state, { payload }) => {
      state.assign();
      const updatedVendors = [...state.vendors].map(vendor => {
        if (vendor['id'] === state.vendorSelected) {
          return {
            ...vendor,
            unassigned_jobs: [
              ...vendor['unassigned_jobs'],
              {
                ...payload.response,
                permanentReassign: false,
                permanentReassignSelectedDay: false,
                permanentlyReassignedJobsId: [],
                permanentReassignSelectedDayString: '',
              },
            ],
          };
        } else {
          return vendor;
        }
      });
      state.vendors = updatedVendors;
      state.workers[payload.workerIndex].jobs = state.workers[
        payload.workerIndex
      ].jobs.filter(el => el.id !== payload.response.id);
      notification.success({ message: 'Job unassigned successfully' });
    },
    unassignJobFromWorkerFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    unassignWorkerPerm: state => {
      state.submitting = true;
      state.message = message.loading('Updating job info...', 0);
    },
    unassignWorkerPermFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    assignWorkerPerm: state => {
      state.submitting = true;
      state.message = message.loading('Updating job info...', 0);
    },
    assignWorkerPermSuccess: (state, { payload }) => {
      const previousWorkerId = payload.previousWorkerId;
      delete payload.previousWorkerId;
      const responseJob = {
        ...payload['job'],
        permanentReassign: false,
        permanentReassignSelectedDay: false,
        permanentlyReassignedJobsId: [],
        permanentReassignSelectedDayString: '',
      };

      state.message();
      state.submitting = false;
      const assigned_worker = responseJob.assigned_worker;
      let jIndex;

      if (assigned_worker === null) {
        const workerIdx = state.workers.findIndex(
          worker => worker.id === previousWorkerId
        );

        if (workerIdx >= 0) {
          state.workers[workerIdx].jobs = state.workers[workerIdx].jobs.filter(
            job => job.id !== responseJob.id
          );
        }
        const updatedVendors = [...state.vendors].map(vendor => {
          if (vendor['id'] === state.vendorSelected) {
            jIndex = vendor.unassigned_jobs.findIndex(
              el => el.id === responseJob.id
            );
            vendor.unassigned_jobs.splice(jIndex, 1, responseJob);
            return {
              ...vendor,
              unassigned_jobs: [...vendor['unassigned_jobs']],
            };
          } else {
            return vendor;
          }
        });
        state.vendors = updatedVendors;
      } else if (assigned_worker && previousWorkerId) {
        const workerIdx = state.workers.findIndex(
          worker => worker.id === assigned_worker.id
        );
        const previousWorkerIdx = state.workers.findIndex(
          worker => worker.id === previousWorkerId
        );

        state.workers[previousWorkerIdx].jobs = state.workers[
          previousWorkerIdx
        ].jobs.filter(job => job.id !== responseJob.id);
        if (workerIdx >= 0) {
          state.workers[workerIdx].jobs = [
            ...state.workers[workerIdx].jobs,
            responseJob,
          ];
        }
      } else {
        const workerIdx = state.workers.findIndex(
          worker => worker.id === assigned_worker.id
        );

        if (workerIdx >= 0) {
          state.workers[workerIdx].jobs = [
            ...state.workers[workerIdx].jobs,
            responseJob,
          ];
        }
        const updatedVendors = [...state.vendors].map(vendor => {
          if (vendor['id'] === state.vendorSelected) {
            return {
              ...vendor,
              unassigned_jobs: [...vendor['unassigned_jobs']].filter(
                job => job.id !== responseJob.id
              ),
            };
          } else {
            return vendor;
          }
        });
        state.vendors = updatedVendors;
      }
      notification.success({
        message: 'Job Updated',
        description: `${responseJob.id} Job has been updated.`,
      });
    },
    assignWorkerPermFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    updateJob: state => {
      state.assign = message.loading('Updating scheduler ...', 0);
    },
    updateJobSuccess: (state, { payload }) => {
      state.assign();

      const vIndex = state.vendors.findIndex(
        el => el.id === state.vendorSelected
      );
      if (payload.action.toLowerCase() === 'skip') {
        let workerIndex;
        let jobIndex;
        state.workers.forEach((worker, wIndex) => {
          if (worker.jobs.length > 0) {
            worker.jobs.forEach((item, jIndex) => {
              if (item.id === payload.response.id) {
                workerIndex = wIndex;
                jobIndex = jIndex;
                state.vendors[vIndex].unassigned_jobs.push(payload.response);
              }
            });
          }
        });
        state.workers[workerIndex].jobs.splice(jobIndex, 1);
      } else if (
        payload.action.toLowerCase() === 'cancel' &&
        payload.checkbox
      ) {
        let workerIndex;
        let jobIndex;
        state.workers.forEach((worker, wIndex) => {
          if (worker.jobs.length > 0) {
            worker.jobs.forEach((item, jIndex) => {
              if (item.id === payload.response.id) {
                workerIndex = wIndex;
                jobIndex = jIndex;
                state.vendors[vIndex].unassigned_jobs.push(payload.response);
              }
            });
          }
        });
        state.workers[workerIndex].jobs.splice(jobIndex, 1);
      } else {
        let idx = state.vendors[vIndex].unassigned_jobs.findIndex(
          el => el.id === payload.response.id
        );
        if (idx >= 0) {
          state.vendors[vIndex].jobs[idx] = {
            ...state.vendors[vIndex].jobs[idx],
            ...payload.response,
          };
        }
        if (payload.response.assigned_worker.id) {
          idx = state.workers.findIndex(
            el => el.id === payload.response.assigned_worker.id
          );
          if (idx >= 0) {
            const jobIdx = state.workers[idx].jobs.findIndex(
              el => el.id === payload.response.id
            );
            state.workers[idx].jobs[jobIdx] = {
              ...state.workers[idx].jobs[jobIdx],
              checkin_time: payload.response.checkin_time,
              checkout_time: payload.response.checkout_time,
              job_status: payload.response.job_status,
            };
          }
        }
      }
      notification.success({ message: 'Job update successfully' });
    },
    updateJobFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },

    getNotes: state => {
      state;
    },
    getNotesSuccess: (state, { payload }) => {
      state.notes = payload;
    },
    getNotesFailed: (state, { payload }) => {
      state.error = payload;
      apiError(payload);
    },
    getNewNotes: state => {
      state.loadingNotes = true;
    },
    getNewNotesSuccess: (state, { payload }) => {
      state.loadingNotes = false;
      state.newNotes = payload;
    },
    getNewNotesFailed: (state, { payload }) => {
      state.loadingNotes = false;
      state.error = payload;
      apiError(payload);
    },
    updateNotes: state => {
      state;
    },
    updateNotesSuccess: (state, { payload }) => {
      state.newNotes = [];
      state.notes = payload;
    },
    updateNotesFailed: (state, { payload }) => {
      state.error = payload;
      apiError(payload);
    },
    enableCheckInOut: state => {
      state.assign = message.loading('Updating job order...', 0);
    },
    updateJobData: state => {
      state.assign = message.loading('Updating job info...', 0);
    },
    updateJobDataSuccess: (state, { payload }) => {
      state.assign();

      if (payload.type === 'single') {
        const { job } = payload.response;
        let wIndex;
        let vIndex;
        let jIndex;
        let selectedVIndex;

        vIndex = state.vendors.findIndex(el => el.id === job?.vendor_id);

        selectedVIndex = state.vendors.findIndex(
          el => el.id === state.vendorSelected
        );

        if (job?.vendor_id) {
          if (state.vendors[vIndex]?.id !== state.vendorSelected) {
            jIndex = state.vendors[selectedVIndex]?.unassigned_jobs?.findIndex(
              el => el.id === job.id
            );
            if (jIndex >= 0) {
              state.workers[wIndex].jobs[jIndex] = job;
            } else {
              jIndex = state.vendors[
                selectedVIndex
              ]?.unassigned_jobs?.findIndex(el => el.id === job.id);
              if (jIndex >= 0) {
                state.workers[wIndex].jobs.push(job);
                state.vendors[selectedVIndex].unassigned_jobs.splice(jIndex, 1);
              } else {
                for (let i = 0; i < state.workers?.length; i++) {
                  if (i === wIndex) {
                    state.workers[i].jobs.push({
                      ...job,
                      permanentReassign: false,
                      permanentReassignSelectedDay: false,
                      permanentlyReassignedJobsId: [],
                      permanentReassignSelectedDayString: '',
                    });
                  } else {
                    let wJobIndex = state.workers[i].jobs.findIndex(
                      el => el.id === job.id
                    );
                    if (wJobIndex >= 0) {
                      state.workers[i].jobs.splice(wJobIndex, 1);
                    }
                  }
                }
              }
            }
          } else {
            if (job?.assigned_worker) {
              wIndex = state.workers.findIndex(
                el => el.id === job?.assigned_worker?.id
              );
              jIndex = state.workers[wIndex]?.jobs?.findIndex(
                el => el.id === job.id
              );
              if (jIndex >= 0) {
                state.workers[wIndex].jobs[jIndex] = job;
              } else {
                state.workers[wIndex].jobs.push(job);
                jIndex = state.vendors[
                  selectedVIndex
                ]?.unassigned_jobs?.findIndex(el => el.id === job.id);
                state.vendors[selectedVIndex].unassigned_jobs.splice(jIndex, 1);
              }
            } else {
              jIndex = state.vendors[vIndex]?.unassigned_jobs?.findIndex(
                el => el.id === job.id
              );
              if (jIndex >= 0) {
                state.vendors[selectedVIndex].unassigned_jobs[jIndex] = job;
              } else {
                for (let i = 0; i < state.workers?.length; i++) {
                  for (let j = 0; j < state.workers[i].jobs?.length; j++) {
                    if (job.id === state.workers[i].jobs[j]?.id) {
                      state.workers[i].jobs.splice(j, 1);
                      state.vendors[selectedVIndex].unassigned_jobs.push(job);
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        let selectedVIndex;
        selectedVIndex = state.vendors.findIndex(
          el => el.id === state.vendorSelected
        );

        state.workers.forEach((worker, wIndex) => {
          if (worker.jobs.length > 0) {
            worker.jobs.forEach((item, jIndex) => {
              payload.response.forEach(job => {
                if (item.id === job.id) {
                  state.workers[wIndex]?.jobs.splice(jIndex, 1);
                  state.workers[wIndex]?.jobs.push(job);
                }
              });
            });
          }
        });

        for (
          let j = 0;
          j < state.vendors[selectedVIndex]?.unassigned_jobs?.length;
          j++
        ) {
          for (let i = 0; i < payload?.response?.length; i++) {
            if (
              state.vendors[selectedVIndex]?.unassigned_jobs[j]?.id ===
              payload?.response[i]?.id
            ) {
              state.vendors[selectedVIndex]?.unassigned_jobs.splice(j, 1);
              state.vendors[selectedVIndex]?.unassigned_jobs.push(
                payload.response[i]
              );
            }
          }
        }
      }

      notification.success({
        message: 'Job Updated',
        description: `Job has been updated.`,
      });
    },
    updateJobDataFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    deleteJob: state => {
      state.message = message.loading('Deleting job ...', 0);
      state.error = null;
    },
    deleteJobSuccess: (state, { payload }) => {
      state.message();
      const { deletedJob } = payload;
      let wIndex;
      let vIndex;
      let jIndex;
      if (deletedJob?.assigned_worker) {
        wIndex = state.workers.findIndex(
          el => el.id === deletedJob?.assigned_worker?.id
        );
        jIndex = state.workers[wIndex]?.jobs?.findIndex(
          el => el.id === deletedJob.id
        );
        state.workers[wIndex].jobs.splice(jIndex, 1);
      } else {
        vIndex = state.vendors.findIndex(el => el.id === state.vendorSelected);
        jIndex = state.vendors[vIndex]?.unassigned_jobs?.findIndex(
          el => el.id === deletedJob.id
        );
        state.vendors[vIndex].unassigned_jobs.splice(jIndex, 1);
      }
      state.error = null;
      notification.success({
        message: 'Job Deleted',
        description: `Job ${deletedJob.id} has been deleted.`,
      });
    },
    deleteJobFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
    pauseJobs: state => {
      state.assign = message.loading('Updating Subscription ...', 0);
    },
    pauseJobsSuccess: (state, { payload }) => {
      state.assign();

      const { jobs } = payload;

      jobs.forEach(job => {
        if (job.assigned_worker) {
          state.workers.forEach((worker, wIndex) => {
            if (worker.jobs.length > 0) {
              worker.jobs.forEach((item, jIndex) => {
                if (item.id === job.id) {
                  state.workers[wIndex].jobs[jIndex] = job;
                }
              });
            }
          });
        } else {
          let vIndex = state.vendors.findIndex(
            el => el.id === state.vendorSelected
          );
          state.vendors[vIndex].unassigned_jobs.forEach((item, jIndex) => {
            if (item.id === job.id) {
              state.vendors[vIndex].unassigned_jobs[jIndex] = job;
            }
          });
        }
      });
      notification.success({ message: 'Subscription updated successfully' });
    },
    pauseJobsFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
    cancelRepeatingJob: state => {
      state.message = message.loading('Cancelling Job...', 0);
      state.error = null;
    },
    cancelRepeatingJobSuccess: (state, { payload }) => {
      state.message();

      state.error = null;
      notification.success({
        message: 'Job Cancelled',
        description: `Job ${payload.id} has been schduled to cancel.`,
      });
    },
    cancelRepeatingJobFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
    cancelSubscription: state => {
      state;
    },
    cancelSubscriptionSuccess: state => {
      state.error = null;
      notification.success({
        message: 'Job Updated',
        description: `Job has been updated.`,
      });
    },
    cancelSubscriptionFailed: (state, { payload }) => {
      state.error = payload;
      apiError(payload);
    },
    skipSubscription: state => {
      state;
    },
    skipSubscriptionSuccess: state => {
      state.error = null;
      notification.success({
        message: 'Job Updated',
        description: `Job has been updated.`,
      });
    },
    skipSubscriptionFailed: (state, { payload }) => {
      state.error = payload;
      apiError(payload);
    },
    activeJob: state => {
      state.assign = message.loading('Activating job ...', 0);
    },
    activeJobSuccess: (state, { payload }) => {
      state.assign();

      state.workers.forEach((worker, wIndex) => {
        if (worker.jobs.length > 0) {
          worker.jobs.forEach((item, jIndex) => {
            if (item.id === payload.id) {
              state.workers[wIndex].jobs[jIndex] = payload;
            }
          });
        }
      });

      notification.success({ message: 'Job activated successfully' });
    },
    activeJobFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },

    downloadCsvJobs: state => {
      state.assign = message.loading('Downloading CSV ...', 0);
    },

    downloadCsvJobsSuccess: (state, { payload }) => {
      state.assign();

      const currentDate = new Date();
      const formattedDate = `${currentDate.getDate()}_${currentDate.getMonth() + 1}_${currentDate.getFullYear()}`;
      const blob = new Blob([payload], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `daily_backup_for_${formattedDate}.csv`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);

      notification.success({ message: 'CSV downloaded successfully' });
    },

    downloadCsvJobsFailed: (state, { payload }) => {
      state.assign();
      apiError(payload);
    },
  },
});

export const {
  handleVendorChange,
  formatWorkerContent,
  searchVendors,
  searchVendorsSuccess,
  searchVendorsFailed,
  searchWorkers,
  searchWorkersSuccess,
  searchWorkersFailed,
  assignJobToWorker,
  assignJobToWorkerSuccess,
  assignJobToWorkerFailed,
  reassignJobToWorker,
  reassignJobToWorkerSuccess,
  reassignJobToWorkerFailed,
  unassignJobFromWorker,
  unassignJobFromWorkerFailed,
  unassignJobFromWorkerSuccess,
  assignWorkerPerm,
  assignWorkerPermFailed,
  assignWorkerPermSuccess,
  unassignWorkerPerm,
  unassignWorkerPermFailed,
  unassignWorkerPermSuccess,
  updateJob,
  updateJobSuccess,
  updateJobFailed,
  enableCheckInOut,
  updateJobData,
  updateJobDataSuccess,
  updateJobDataFailed,
  deleteJob,
  deleteJobFailed,
  deleteJobSuccess,
  pauseJobs,
  pauseJobsSuccess,
  pauseJobsFailed,
  cancelRepeatingJob,
  cancelRepeatingJobSuccess,
  cancelRepeatingJobFailed,
  handleOnPermanentReassignChange,
  handleOnPermReassignSelectedDayChange,
  getNotes,
  getNotesFailed,
  getNotesSuccess,
  getNewNotes,
  getNewNotesFailed,
  getNewNotesSuccess,
  updateNotes,
  updateNotesFailed,
  updateNotesSuccess,
  cancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailed,
  skipSubscription,
  skipSubscriptionSuccess,
  skipSubscriptionFailed,
  activeJob,
  activeJobSuccess,
  activeJobFailed,
  downloadCsvJobs,
  downloadCsvJobsSuccess,
  downloadCsvJobsFailed,
} = slice.actions;

export default slice.reducer;
