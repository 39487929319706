import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'vendors',
  initialState: {
    submitting: false,
    loading: false,
    vendors: [],
    error: null,
    media_library_images: { data: [] },
  },
  reducers: {
    getMedia: state => {
      state;
    },
    getMediaSuccess: (state, { payload }) => {
      state.media_library_images = {
        data: payload.library_images,
      };
    },
    getMediaFailed: state => {
      state;
    },
    getVendors: state => {
      state.loading = true;
      state.error = null;
    },
    getVendorsSuccess: (state, { payload }) => {
      state.loading = false;
      state.meta = payload['meta'];
      state.vendors = payload['vendors'];
      state.error = null;
    },
    getVendorsFailed: (state, { payload }) => {
      state.loading = false;
      state.vendors = [];
      state.error = payload;
    },
    createVendor: state => {
      state.submitting = true;
      state.message = message.loading('Creating a new vendor ...', 0);
      state.error = null;
    },
    createVendorSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const newVendor = payload.vendor;
      state.vendors = [newVendor, ...state.vendors];
      notification.success({
        message: 'New Vendor',
        description: `New Vendor ${payload['vendor']['vendor_name']} has been created.`,
      });
    },
    createVendorFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateVendor: state => {
      state.submitting = true;
      state.message = message.loading('Updating vendor info...', 0);
      state.error = null;
    },
    updateVendorSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const idx = state.vendors.findIndex(
        el => el.id === payload['vendor']['id']
      );
      if (idx >= 0) {
        state.vendors[idx] = {
          ...state.vendors[idx],
          ...payload['vendor'],
        };
      }
      notification.success({
        message: 'Vendor Updated',
        description: `Vendor ${payload['vendor']['vendor_name']} has been updated.`,
      });
    },
    updateVendorFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    deleteVendor: state => {
      state.message = message.loading('Deleting vendor ...', 0);
      state.error = null;
    },
    deleteVendorSuccess: (state, { payload }) => {
      state.message();
      state.error = null;
      state.vendors = state.vendors.filter(
        x => x.id !== payload['vendor']['id']
      );
      notification.success({
        message: 'Delete Vendor',
        description: `Vendor ${payload['vendor']['vendor_name']} have been deleted.`,
      });
    },
    deleteVendorFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
  },
});

export const {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getVendors,
  getVendorsSuccess,
  getVendorsFailed,
  createVendor,
  createVendorSuccess,
  createVendorFailed,
  updateVendor,
  updateVendorSuccess,
  updateVendorFailed,
  deleteVendor,
  deleteVendorSuccess,
  deleteVendorFailed,
} = slice.actions;

export default slice.reducer;
