import React, { useEffect, useCallback, useState } from 'react';
import StyledFormItem from '../styled-components/form-item';
import StyledForm from '../styled-components/form-wrapper';
import { Formik } from 'formik';
import { Button, Input, Select, Card, Row, Col, Breadcrumb, Radio } from 'antd';
import request from '../../../utils/apisauce';
import { createConcierge, getProductById } from '../reducers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConciergeItemForm from './concierge-item-form';
import { LeftOutlined } from '@ant-design/icons';
import { getTags } from '../../TagsPage/reducers';
const { Option } = Select;

const defaultForm = {
  title: '',
  address_book_id: '',
  buyer_id: '',
  description: '',
  buyer_type: 'PersonalUser',
  concierge_instant_charge: false,
  concierge_order_items_attributes: [],
};
const defaultItemForm = {
  vendor_id: '',
  title: '',
  description: '',
  unit: '',
  amount: '',
  is_create_job: false,
  service_date: '',
  preferred_time: '',
  job_notes: '',
};

// to later on suffix * dynamically when see how required is determiend from form passed
function ConciergeCreate(props) {
  const {
    // getTags,
    history: { push },
    created,
    history,
  } = props;
  const [id] = useState(props.match.params.id);
  const [form, setForm] = useState(defaultForm);
  const [formItems, setFormItems] = useState([]);
  const [mode, setMode] = useState(null);
  const [clients, setClients] = useState([]);
  const [formData] = useState({ client_type: 'personal' });
  const [clientAddress, setClientAddress] = useState([]);
  const itemFormRefs = [];

  const searchClients = useCallback(
    async query => {
      let url = `/api/v1/clients/${formData.client_type}/users`;
      if (query) {
        url += '?query=' + query;
      }
      const clients = await request('GET', url);
      setClients([...clients.personal_clients]);
    },
    [formData.client_type]
  );

  useEffect(() => {
    searchClients();
  }, [searchClients]);

  useEffect(() => {
    setMode(id);
  }, [id]);

  useEffect(() => {
    if (created) {
      history.push('/admin/concierge/');
    }
  }, [created, history]);

  const onSubmit = async () => {
    form.concierge_order_items_attributes = formItems;

    props.createConcierge(form);
  };

  const addItem = () => {
    const newItem = Object.assign({}, defaultItemForm);
    newItem.sku = Math.random();
    setFormItems(formItems => [...formItems, newItem]);
  };
  const removeItem = item => {
    setFormItems(formItems => formItems.filter(x => x !== item));
  };
  const onInputChange = ({ target: { name, value } }) => {
    setForm(form => ({ ...form, [name]: value }));

    if (name === 'buyer_id') {
      const client = clients.find(el => el?.personal_users[0]?.id === value);
      setClientAddress(client);
    }
  };

  const [tagSearchKeyword] = useState(null);
  useEffect(() => {
    getTags({
      page: 1,
      per: 30,
      query: tagSearchKeyword,
    });
  }, [tagSearchKeyword]);

  const onClickBack = useCallback(() => {
    push('/admin/concierge');
  }, [push]);

  const { submitting } = props;
  // const tagsDataOption = [...tagsData.data, ...form.properties];
  // const tagsDataOption = tagsData.data;

  // const formCategory = form.category? categories.find(x => x.id === form.category_id): null;
  const pageTitle = mode === 'create' ? 'Add New Concierge' : 'Edit Concierge';
  return (
    <>
      <div className="page-header">
        <div className="page-title">
          <LeftOutlined onClick={onClickBack} />
          <Row>
            <Col span={24}>
              <h4>{pageTitle}</h4>
            </Col>
            <Col>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <a href="/admin/">Home</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="/admin/concierge">Concierge</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </div>
      <div className="page-content">
        <Formik
          initialValues={{
            // ...formInitialValues['createSubscriptionForm'],
            ...form,
            formItems,
          }}
          enableReinitialize={true}
          // validationSchema={formValidations['CreateSubscriptionFormValidation']
        >
          {({
            values,
            // errors,
            // touched,
            // handleChange,
            setFieldValue,
          }) => {
            return (
              <StyledForm>
                <Row>
                  <Col span={24}>
                    <Card bordered={false}>
                      <Card title="Order" bordered={false}>
                        <Row gutter={24}>
                          <Col xs={24} sm={24} md={8}>
                            <StyledFormItem label={'Title*'}>
                              <Input
                                value={form.title}
                                onChange={onInputChange}
                                name="title"
                                placeholder="Enter title here"
                              />
                            </StyledFormItem>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <StyledFormItem label={'Client Name *'}>
                              <Select
                                style={{ width: '100%' }}
                                value={form.buyer_id}
                                placeholder="Search client name"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                // onSearch={onSearchClients}
                                onChange={value => {
                                  onInputChange({
                                    target: { name: 'buyer_id', value },
                                  });
                                }}
                              >
                                {clients?.map(el =>
                                  formData.client_type === 'personal' ? (
                                    <Option
                                      key={el?.personal_users[0]?.id}
                                      value={el?.personal_users[0]?.id}
                                    >
                                      {el.title}
                                    </Option>
                                  ) : (
                                    <Option
                                      key={el?.personal_users[0]?.id}
                                      value={el?.personal_users[0]?.id}
                                    >
                                      {el.business_name}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </StyledFormItem>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <StyledFormItem label="Address">
                              <Select
                                style={{ width: '100%' }}
                                value={form.address_book_id}
                                placeholder="Select address"
                                onChange={value => {
                                  onInputChange({
                                    target: { name: 'address_book_id', value },
                                  });
                                }}
                              >
                                {clientAddress?.address_books?.map(el => (
                                  <Option key={el.id} value={el.id}>
                                    {el.address_title}
                                  </Option>
                                ))}
                              </Select>
                            </StyledFormItem>
                          </Col>
                        </Row>
                        <StyledFormItem label={'Description *'}>
                          <Input.TextArea
                            value={form.description}
                            onChange={onInputChange}
                            name="description"
                            placeholder="Enter description here"
                          />
                        </StyledFormItem>

                        <StyledFormItem label="Instant Charge? *">
                          {/* <Radio.Group onChange={() => {}} value={value.concierge_instant_charge}> */}
                          <Radio.Group
                            onChange={e => {
                              onInputChange({
                                target: {
                                  name: 'concierge_instant_charge',
                                  value: e.target.value,
                                },
                              });
                            }}
                            value={form.concierge_instant_charge}
                          >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </StyledFormItem>
                      </Card>
                      <Card
                        title="Items"
                        bordered={false}
                        className="ItemsCard"
                      >
                        {formItems.length > 0 && (
                          <>
                            {/* <h1>2. Product Items</h1> */}
                            {formItems.map((item, index) => (
                              <Card
                                // title={'Order Item ' + (index + 1)}
                                // extra={
                                //   <Button
                                //     type="link"
                                //     onClick={() => removeItem(item)}
                                //   >
                                //     remove
                                //   </Button>
                                // }

                                key={'item' + index}
                                style={{ marginTop: index > 0 ? 16 : '' }}
                              >
                                <ConciergeItemForm
                                  ref={itemForm =>
                                    (itemFormRefs[index] = itemForm)
                                  }
                                  index={index}
                                  handleRemoveItem={item => {
                                    return removeItem(item);
                                  }}
                                  initialState={item}
                                  inputChange={ev => {
                                    const { name, value } = ev['target'];
                                    let updatedFormItems = [
                                      ...values.formItems,
                                    ];
                                    updatedFormItems[index][name] = value;
                                    setFieldValue(
                                      'formItems',
                                      updatedFormItems,
                                      false
                                    );
                                  }}
                                />
                              </Card>
                            ))}
                          </>
                        )}
                        <Button
                          type="default"
                          onClick={addItem}
                          block
                          style={{ marginTop: 16, width: 142 }}
                        >
                          + ADD NEW ITEM
                        </Button>
                      </Card>
                      <Row gutter={16}>
                        <Col push={18} span={5}>
                          <Button
                            disabled={submitting}
                            onClick={onSubmit}
                            type="primary"
                            htmlType="submit"
                            block
                          >
                            Save
                          </Button>
                        </Col>
                        <Col push={9} span={4}>
                          <Button
                            type="default"
                            block
                            disabled={submitting}
                            // onClick={onClickBack}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </StyledForm>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

ConciergeCreate.propTypes = {
  history: PropTypes.object,
  createConcierge: PropTypes.func,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  created: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    submitting: state.concierge.submitting,
    created: state.concierge.created,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getCategories: q => dispatch(getCategories(q)),
    createConcierge: data => dispatch(createConcierge(data)),
    // updateProduct: data => dispatch(updateProduct(data)),
    getProductById: data => dispatch(getProductById(data)),
    // getTags: q => dispatch(getTags(q)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConciergeCreate);
