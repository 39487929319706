import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import request from '../../utils/apisauce';

const { Option } = Select;

const SelectVendor = props => {
  const { handleOnChange, value, loading, customProps } = props;
  const [vendors, updateVendors] = useState([]);
  const [loader, setLoader] = useState(false);
  let timeout = null;

  const getVendorsList = async () => {
    let url = `api/v1/admin/vendors`;
    setLoader(true);
    try {
      const { vendors } = await request('GET', url);
      updateVendors(vendors);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const onSearchVendor = ev => {
    setLoader(true);
    const url = '/api/v1/admin/vendor_board/vendors?query=' + ev;
    try {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(async () => {
        const { vendors } = await request('GET', url);
        updateVendors(vendors);
        setLoader(false);
      }, 500);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getVendorsList();
  }, []);

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      placeholder="Search vendor name"
      loading={loading || loader}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onSearch={onSearchVendor}
      onChange={value =>
        handleOnChange(vendors.find(vendor => vendor['id'] === value))
      }
      {...customProps}
    >
      {vendors.map(el => (
        <Option key={el.id} value={el.id}>
          {el.vendor_name}
        </Option>
      ))}
    </Select>
  );
};

SelectVendor.propTypes = {
  value: PropTypes.number,
  loading: PropTypes.bool,
  customProps: PropTypes.object,
  handleOnChange: PropTypes.func,
  onSearchVendor: PropTypes.func,
};

export default SelectVendor;
