import React, { useEffect, Fragment } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import FeedCount from './FeedCount';
import TimelineItem from './TimelineItem';
import { getJobFeed, getJobFeedCount } from '../reducers';
// import RedoIcon from '../../../assets/icons/ic_replay.svg';
import { renderLoadableComponent } from '../../../components/Loader';

function JobFeed(props) {
  const {
    getJobFeedCount,
    getjobFeedCountLoading,
    jobFeedCount,
    getJobFeed,
    getJobFeedLoading,
    jobFeeds,
  } = props;

  useEffect(() => {
    getJobFeedCount();
    getJobFeed();
  }, [getJobFeedCount, getJobFeed]);

  return (
    <Card className="job-feed-card">
      <div className="job-feed-header">
        <h4 className="job-feed-header-title">Job Feed</h4>
        {/* <div className="job-feed-header-icon-container">
          <img
            src={RedoIcon}
            alt="icon"
            className="job-feed-header-icon"
            onClick={getJobFeedCount}
          />
        </div> */}
      </div>
      <div className="feed-count-section">
        {renderLoadableComponent(
          getjobFeedCountLoading,
          <Fragment>
            <FeedCount
              title={'Unassigned Jobs'}
              count={jobFeedCount['unassigned_jobs_count']}
            />
            <FeedCount
              title={'Total Jobs'}
              count={jobFeedCount['total_count']}
            />
            <FeedCount
              title={'Completed'}
              count={jobFeedCount['completed_count']}
            />
            <FeedCount
              title={'Remaining'}
              count={jobFeedCount['remaining_count']}
            />
          </Fragment>
        )}
      </div>
      <div className="divider" />
      <h2 className="job-feed-header-title active-worker-list-header">
        Active Workers List
      </h2>
      <div className="divider" style={{ marginTop: 0 }} />
      <div className="timeline-container">
        {renderLoadableComponent(
          getJobFeedLoading,
          <>
            {jobFeeds.map(({ id, event_name, event_params, created_at }) => (
              <TimelineItem
                key={id}
                jobId={event_params.job_id}
                time={moment(created_at).fromNow()}
                workerName={event_params.worker_name}
                vendorName={event_params.vendor_name}
                clientName={event_params.client_name}
                action={
                  event_name === 'job_checked_in'
                    ? 'Check In to'
                    : 'Check Out from'
                }
              />
            ))}
          </>
        )}
        {/* {renderLoadableComponent(
          getJobFeedLoading,
          <Timeline>
            {jobFeeds.map(({ id, event_name, event_params, created_at }) => (
              <TimelineItem
                key={id}
                jobId={event_params.job_id}
                time={moment(created_at).format('hh:mm A')}
                workerName={event_params.worker_name}
                vendorName={event_params.vendor_name}
                clientName={event_params.client_name}
                action={
                  event_name === 'job_checked_in'
                    ? 'Check In to'
                    : 'Check Out from'
                }
              />
            ))}
          </Timeline>
        )} */}
      </div>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    getjobFeedCountLoading: state.dashboard.getjobFeedCountLoading,
    getJobFeedLoading: state.dashboard.getJobFeedLoading,
    jobFeedCount: state.dashboard.jobFeedCount,
    jobFeeds: state.dashboard.jobFeeds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJobFeedCount: q => dispatch(getJobFeedCount(q)),
    getJobFeed: () => dispatch(getJobFeed()),
  };
}

JobFeed.propTypes = {
  getJobFeedCount: PropTypes.func,
  getjobFeedCountLoading: PropTypes.bool,
  getJobFeed: PropTypes.func,
  getJobFeedLoading: PropTypes.bool,
  jobFeedCount: PropTypes.object,
  jobFeeds: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps, mapDispatchToProps)(JobFeed);
