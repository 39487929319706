import React from 'react';
import { Avatar, Modal } from 'antd';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { getImageURI } from '../../../../utils/apisauce';

const closeModal = (uiState, updateUI) => {
  updateUI({
    ...uiState,
    selectedRow: null,
    modalOpened: false,
  });
};
const performDelete = (selectedRow, deleteClass) => {
  deleteClass(selectedRow);
};

const DeleteProduct = ({ uiState, updateUI, deleteClass }) => {
  const { modalOpened, selectedRow } = uiState;
  return (
    <Modal
      className="delete-user-modal"
      visible={modalOpened === 2}
      onCancel={() => closeModal(uiState, updateUI)}
      onOk={() => performDelete(selectedRow, deleteClass)}
      okText="Yes"
      destroyOnClose
    >
      {selectedRow && (
        <>
          <div className="user-info">
            <Avatar
              src={getImageURI(
                selectedRow['image'] && selectedRow['image'].url
              )}
              icon={<UserOutlined />}
              size={'large'}
            />
            <div className="username">{selectedRow.title}</div>
            <span>Class ID:&nbsp;{selectedRow.id}</span>
          </div>
          <p className="main-message">
            Are you sure want to remove this class from database?
          </p>
          <p>This action can not be undone</p>
        </>
      )}
    </Modal>
  );
};

DeleteProduct.propTypes = {
  uiState: PropTypes.object,
  updateUI: PropTypes.object,
  deleteClass: PropTypes.func,
};

export default DeleteProduct;
