import styled from 'styled-components';
import { Form } from 'antd';

export default styled(Form)`
  width: 100%;

  Button + Button {
    margin-top: 1.6rem;
  }
  .ItemsCard > .ant-card-body {
    padding: 0;
  }
  .ant-row .ant-form-item {
    margin-bottom: 15px !important;
  }
`;
