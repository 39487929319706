import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import Application from './app';
import VendorApplication from './vendor-app';
import './library/font-awesome';
import * as serviceWorker from './library/service-worker';
import store from './library/redux-saga';
import 'leaflet/dist/leaflet.css';
import AuthPage from './pages/AuthPage';
import VendorAuthPage from './Vendor/pages/AuthPage';
import PrivateRoute from './privateRoute';
import OTPCheck from './pages/AuthPage/OTPCheck';

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/admin/login" exact>
          <AuthPage />
        </Route>
        <Route path="/admin/login/otp-verification" exact>
          <OTPCheck />
        </Route>
        <PrivateRoute path="/vendor/login" component={VendorAuthPage} exact />
        {/* <Route path="/vendor/login" exact>
          <VendorAuthPage />
        </Route> */}
        <Route path="/vendor" exact>
          <VendorApplication />
        </Route>
        <Route path="/admin" exact>
          <Application />
        </Route>
        <Application />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// disable service workers for staging
serviceWorker.unregister();
