import { PlusOutlined, UserOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Image,
  Drawer,
  Input,
  Modal,
  DatePicker,
  Row,
  Col,
  Form,
  Select,
  Tooltip,
  message,
  Radio,
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from '../../components/DataTable';
import DraggerUpload from '../../components/DragDropImageUpload';
import {
  getSlides,
  getMedia,
  createSlide,
  updateSlide,
  deleteSlide,
  startEditSlide,
} from './reducers';
import { getImageURI, capitalize } from '../../utils/apisauce';
import moment from 'moment';
import { Formik } from 'formik';
import { formInitialValues } from '../../utils/formsInitialValues';
import { formValidations } from '../../utils/formValidations';
import TableSearch from '../../components/TableSearch';
import 'react-phone-input-2/lib/style.css';
import './styles.css';
import { DeleteFilledIcon, EditFilledIcon } from '../../components/Icons/Icons';

const userData = {
  title: '',
  content: '',
  start_date: moment().format('YYYY-MM-DD'),
  end_date: moment().format('YYYY-MM-DD'),
  link_url: '',
  image_url: '',
  image: null,
  images: null,
  image_type: 'attached',
};

const dateFormat = 'YYYY-MM-DD';

class SlidesShowPage extends React.Component {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    lastSearch: '',
    isFileGreater: false,
    pageQuery: {
      page: 1,
      per: 20,
    },
    queryString: '',
    isCopied: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { getSlides, getMedia } = this.props;
    const { pageQuery, queryString } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getSlides({ ...pageQuery, query: queryString });
        getMedia();
      } else {
        getSlides(pageQuery);
        getMedia();
      }
    }
  }

  componentDidMount() {
    const { getSlides, getMedia } = this.props;
    const { pageQuery } = this.state;
    getSlides(pageQuery);
    getMedia();
  }

  onSearch = () => {
    const { getSlides } = this.props;
    const { pageQuery, queryString } = this.state;
    const updatedPageQuery = { ...pageQuery, page: 1 };
    this.setState({ pageQuery: updatedPageQuery }, () =>
      getSlides({ query: queryString, per: pageQuery['per'] })
    );
  };

  closeModal = () => {
    const { startEditSlide } = this.props;
    startEditSlide(false);
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onClickRow = (e, record) => {
    const { startEditSlide } = this.props;
    startEditSlide(true);
    this.setState({
      modalOpened: 1,
      selectedRow: record,
      formData: null,
    });
  };

  onCreate = () => {
    const { startEditSlide } = this.props;
    startEditSlide(true);
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      isFileGreater: false,
      formData: { ...userData },
    });
  };

  onEdit = record => {
    const { startEditSlide } = this.props;
    startEditSlide(true);
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      isFileGreater: false,
      formData: {
        ...record,
        password: '',
      },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  onSubmit = values => {
    // e.preventDefault();
    const { formData } = this.state;
    const { title, content, link_url, active, image_type, mediaImageId } =
      values;
    if (formData.images) {
      formData.image = formData.images;
    } else {
      formData.image = null;
    }
    if (formData.id) {
      const { updateSlide } = this.props;
      if (!this.state.isFileGreater) {
        updateSlide({
          ...formData,
          title,
          content,
          link_url,
          active,
          mediaImageId,
          image_type,
        });
      } else {
        message.error('File size must be lesser than 3 MB');
      }
    } else {
      const { createSlide } = this.props;
      if (!this.state.isFileGreater) {
        createSlide({
          ...formData,
          title,
          content,
          link_url,
          active,
          mediaImageId,
          image_type,
        });
      } else {
        message.error('File size must be lesser than 3 MB');
      }
    }
    // this.closeModal();
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteSlide } = this.props;

    deleteSlide(selectedRow.id);
    this.closeModal();
  };

  onInputChange = ({ target: { name, value } }) => {
    const { formData } = this.state;

    formData[name] = value;
    this.setState({ formData });
  };

  tableColumns = () => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      defaultSortOrder: 'descend',
      fixed: 'left',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      fixed: 'left',
      render: (_title, record) => (
        <>
          <Avatar
            src={getImageURI(record['image'] && record['image'].url)}
            icon={<UserOutlined />}
            size={'large'}
            shape="square"
          />
        </>
      ),
    },
    {
      title: 'Promo Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'content',
      key: 'content',
      render: description => (
        <Tooltip placement="topLeft" title={description}>
          {capitalize(description).substring(0, 20)}
          <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
            ...Read more
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Link',
      dataIndex: 'link_url',
      key: 'link_url',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: text => {
        return <span>{text ? 'Active' : 'Inactive'}</span>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: 'Expire Date',
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      align: 'center',
      render: (text, record) => (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || this.onDelete(record)}
          />
        </div>
      ),
    },
  ];

  ValidationProps = name => {
    const { errors } = this.props;
    if (errors && errors[name] && errors[name].length > 0) {
      const field = errors[name];
      const validationStatus = 'error';
      const error = field[0];
      return {
        validateStatus: validationStatus,
        help: error,
      };
    }
    return {};
  };

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };
  handleSubmitCheck = value => {
    this.setState(state => ({ ...state, isFileGreater: value }));
  };

  copyText = async text => {
    await navigator.clipboard.writeText(text);
    this.setState({ isCopied: true });
    message.success('Image URL copied');
    setTimeout(() => this.setState({ isCopied: false }), 2000);
  };

  renderFormData = () => {
    const { formData } = this.state;
    const { media_library_images } = this.props;
    const { data } = media_library_images;
    if (!formData) {
      return;
    }
    return (
      <>
        <div className="drawer-header">
          <h3>{formData.id ? 'Edit Slide' : 'Create New Slide'}</h3>
          <div className="header-actions">
            {formData.id && (
              <DeleteFilledIcon
                className="action-primary"
                onClick={() => this.onDelete(formData)}
              />
            )}
            <CloseOutlined onClick={this.closeModal} />
          </div>
        </div>
        <div className="drawer-body">
          <Formik
            initialValues={{
              ...formInitialValues['createSlideShowForm'],
              ...formData,
            }}
            validationSchema={formValidations['CreateSlideShowFormValidation']}
            onSubmit={values => this.onSubmit(values)}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Radio.Group
                    defaultValue={values.image_type}
                    onChange={handleChange}
                    name="image_type"
                    buttonStyle="solid"
                    style={{ marginBottom: '10px' }}
                  >
                    <Radio.Button name="image_type" value={'attached'}>
                      Upload Image
                    </Radio.Button>
                    <Radio.Button name="image_type" value={'library'}>
                      Choose From Library
                    </Radio.Button>
                  </Radio.Group>
                  {values.image_type === 'attached' ? (
                    <DraggerUpload
                      value={
                        formData['image']
                          ? getImageURI(formData['image'].url)
                          : null
                      }
                      onChange={value =>
                        this.onInputChange({
                          target: { name: 'images', value: { data: value } },
                        })
                      }
                      accept=".png, .jpg, .jpeg"
                      aspectRatio={21 / 9}
                      width={100} // in percentage
                      height={100} // in percentage
                      handleSubmit={this.handleSubmitCheck}
                      largeSize={true}
                    />
                  ) : (
                    <div style={{ height: '250px', overflowY: 'scroll' }}>
                      <Radio.Group
                        onChange={handleChange}
                        size="large"
                        defaultValue={formData['image']?.library_image_id}
                        name="mediaImageId"
                      >
                        {data?.map(item => (
                          <Radio
                            key={item?.id}
                            className="radioImage"
                            name="mediaImageId"
                            value={item?.id}
                          >
                            <Card>
                              <Image
                                src={getImageURI(item?.image_path?.url)}
                                width={120}
                                preview={false}
                              />
                            </Card>
                          </Radio>
                        ))}
                      </Radio.Group>
                    </div>
                  )}
                  <div className="form-group">
                    <label>Slide Title</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['title'] && errors['title'] ? 'error' : null
                      }
                      help={touched['title'] && errors['title']}
                      style={{ margin: 0 }}
                    >
                      <Input
                        name="title"
                        value={values.title}
                        placeholder="Enter Slide Title"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['description'] && errors['description']
                          ? 'error'
                          : null
                      }
                      help={touched['description'] && errors['description']}
                      style={{ margin: 0 }}
                    >
                      <Input.TextArea
                        name="content"
                        value={values.content}
                        placeholder="Enter the description here"
                        onChange={handleChange}
                        autoSize={{ minRows: 5 }}
                      />
                    </Form.Item>
                  </div>
                  <label>Status</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['active'] && errors['active'] ? 'error' : null
                    }
                    help={touched['active'] && errors['active']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      value={values.active}
                      onChange={value =>
                        handleChange({
                          target: { name: 'active', value },
                        })
                      }
                    >
                      <Select.Option value={true}>Active</Select.Option>
                      <Select.Option value={false}>Inactive</Select.Option>
                    </Select>
                  </Form.Item>
                  <Row>
                    <Col span={12}>
                      <div className="form-group">
                        <label>Start Date</label>
                        <Form.Item>
                          <DatePicker
                            defaultValue={moment(
                              formData.start_date,
                              dateFormat
                            )}
                            name="start_date"
                            placeholder="Set start Date"
                            onChange={(date, dateString) =>
                              this.onInputChange({
                                target: {
                                  name: 'start_date',
                                  value: dateString,
                                },
                              })
                            }
                            format={dateFormat}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="form-group">
                        <label>End Date</label>
                        <Form.Item>
                          <DatePicker
                            defaultValue={moment(formData.end_date, dateFormat)}
                            name="end_date"
                            placeholder="Set end Date"
                            onChange={(date, dateString) =>
                              this.onInputChange({
                                target: {
                                  name: 'end_date',
                                  value: dateString,
                                },
                              })
                            }
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <label>Link URL</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['link_url'] && errors['link_url']
                          ? 'error'
                          : null
                      }
                      help={touched['link_url'] && errors['link_url']}
                      style={{ margin: 0 }}
                    >
                      <Input
                        type="text"
                        name="link_url"
                        value={values.link_url}
                        placeholder="Enter link url here"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <Button type="primary" htmlType="submit" block>
                    Save
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  };
  render() {
    const { selectedRow, modalOpened, pageQuery, queryString, isCopied } =
      this.state;

    const { loading, slide_show_images, formOpen } = this.props;
    const { data, total } = slide_show_images;
    return (
      <div className="admins-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Slides</h4>
          </div>
          <div className="page-header-actions">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.onCreate}
            >
              Add New Slide
            </Button>
          </div>
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search Slide"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={queryString}
            onInputChange={ev =>
              this.setState({ queryString: ev['target']['value'] })
            }
          />
          <Card>
            <DataTable
              rowKey={record => record.id}
              loading={loading}
              data={data}
              columns={this.tableColumns()}
              onClickRow={this.onClickRow}
              pagination={{
                limit: pageQuery['per'],
                total,
                page: pageQuery['page'],
                data,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
            />
          </Card>
          <Drawer
            closable={false}
            placement="right"
            width={450}
            visible={modalOpened === 1 && formOpen === true}
            onClose={this.closeModal}
            destroyOnClose
          >
            {selectedRow ? (
              <>
                <div className="drawer-header">
                  <h3>Slide Detail</h3>
                  <div className="header-actions">
                    <EditFilledIcon
                      className="action-primary"
                      onClick={() => this.onEdit(selectedRow)}
                    />
                    <CloseOutlined onClick={this.closeModal} />
                  </div>
                </div>
                <div className="drawer-body">
                  <div className="user-avatar">
                    <Avatar
                      src={getImageURI(
                        selectedRow['image'] && selectedRow['image'].url
                      )}
                      icon={<UserOutlined />}
                      size={'large'}
                      style={{ width: '100%', height: '200px' }}
                      shape="square"
                    />
                  </div>
                  <Row
                    justify="space-around"
                    align="middle"
                    className="user-info-row"
                  >
                    <Col span={12}>
                      <label>Start Date</label>
                    </Col>
                    <Col span={12}>
                      <label>End Date</label>
                    </Col>
                  </Row>
                  <Row className="user-info-row">
                    <Col span={12}>
                      <span>{selectedRow.start_date}</span>
                    </Col>
                    <Col span={12}>
                      <span>{selectedRow.end_date}</span>
                    </Col>
                  </Row>
                  <div className="user-info-row">
                    <label>Title</label>
                    <span>{selectedRow.title}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Description</label>
                    <span>{selectedRow.content}</span>
                  </div>
                  <div className="user-info-row">
                    <label>Link URL</label>
                    <div
                      style={{
                        backgroundColor: '#eee',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                        borderRadius: '4px',
                      }}
                    >
                      <span style={{ color: '#000' }}>
                        {selectedRow.link_url}
                      </span>
                      <span style={{ alignSelf: 'end', cursor: 'default' }}>
                        {isCopied ? (
                          <span
                            style={{
                              backgroundColor: '#dbdbdb',
                              padding: '1px 15px',
                              borderRadius: '7px',
                              color: '#000',
                            }}
                          >
                            Copied!
                          </span>
                        ) : (
                          <span
                            onClick={() => this.copyText(selectedRow.link_url)}
                            style={{
                              backgroundColor: '#dbdbdb',
                              padding: '1px 15px',
                              borderRadius: '7px',
                              color: '#000',
                            }}
                          >
                            Copy
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              this.renderFormData()
            )}
          </Drawer>
          <Modal
            className="delete-user-modal"
            visible={modalOpened === 2}
            onCancel={this.closeModal}
            onOk={this.performDelete}
            okText="Yes"
            destroyOnClose
          >
            {selectedRow && (
              <>
                <h1 className="main-message pop-up-heading">Are you sure?</h1>
                <p className="pop-up-body">
                  Do you really want to remove this slide? The process cannot be
                  undone.
                </p>
              </>
            )}
          </Modal>
        </div>
      </div>
    );
  }
}

SlidesShowPage.propTypes = {
  loading: PropTypes.bool,
  slide_show_images: PropTypes.array,
  errors: PropTypes.array,
  formOpen: PropTypes.object,
  getSlides: PropTypes.func,
  getMedia: PropTypes.func,
  createSlide: PropTypes.func,
  updateSlide: PropTypes.func,
  deleteSlide: PropTypes.func,
  startEditSlide: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.slides.loading,
    slide_show_images: state.slides.slide_show_images,
    media_library_images: state.slides.media_library_images,
    errors: state.slides.errors,
    formOpen: state.slides.formOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSlides: keyword => dispatch(getSlides(keyword)),
    getMedia: keyword => dispatch(getMedia(keyword)),
    createSlide: data => dispatch(createSlide(data)),
    updateSlide: data => dispatch(updateSlide(data)),
    deleteSlide: id => dispatch(deleteSlide(id)),
    startEditSlide: open => dispatch(startEditSlide(open)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SlidesShowPage);
