import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Row, Col, Input, Card, Spin } from 'antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import StyledForm from './styled-components/form-wrapper';
import { formInitialValues } from '../../utils/formsInitialValues';
import { formValidations } from '../../utils/formValidations';
import { createSendSms } from './reducer';
import PhoneInput from 'react-phone-input-2';

const SendSmsPage = props => {
  const { loading, submitting, createSendSms } = props;

  const makePayload = values => {
    let sms = {
      mobile: `+${values['mobile']}`,
      message: values['message'],
    };
    return sms;
  };

  return (
    <div className="order-container">
      <div className="page-header">
        <div className="page-title">
          <h4>SMS</h4>
        </div>
      </div>

      <div className="page-content">
        <Formik
          initialValues={{
            ...formInitialValues['sendSmsForm'],
          }}
          enableReinitialize={true}
          validationSchema={formValidations['sendSmsFormValidator']}
          onSubmit={values => createSendSms(makePayload(values))}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <Card className="tab-container">
                <Spin spinning={loading}>
                  <StyledForm onSubmit={handleSubmit}>
                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>Enter Mobile</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['mobile'] && errors['mobile']
                                ? 'error'
                                : null
                            }
                            help={touched['mobile'] && errors['mobile']}
                            style={{ margin: 0 }}
                          >
                            <PhoneInput
                              defaultCountry={'sg'}
                              onlyCountries={['sg']}
                              localization={{ sg: 'Singapur' }}
                              inputStyle={{ width: '100%' }}
                              value={values.mobile}
                              onChange={value =>
                                handleChange({
                                  target: { name: 'mobile', value },
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-1">
                      <Row gutter={20}>
                        <Col span={4}>
                          <label>Message</label>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['message'] && errors['message']
                                ? 'error'
                                : null
                            }
                            help={touched['message'] && errors['message']}
                            style={{ margin: 0 }}
                          >
                            <Input.TextArea
                              value={values.message}
                              onChange={handleChange}
                              name="message"
                              placeholder="Enter Message Body"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>

                    <Row gutter={16}>
                      <Col push={10} span={4}>
                        <Button
                          disabled={submitting}
                          onClick={handleSubmit}
                          type="primary"
                          htmlType="submit"
                          block
                        >
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </StyledForm>
                </Spin>
              </Card>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

SendSmsPage.propTypes = {
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  createSendSms: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.sendSms.loading,
    submitting: state.sendSms.submitting,
    error: state.sendSms.submitting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSendSms: payload => dispatch(createSendSms(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendSmsPage);
