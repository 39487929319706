import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getSubscription,
  //   getSubscriptionById,
  getSubscriptionFailed,
  getSubscriptionSuccess,
  createSubscription,
  createSubscriptionFailed,
  createSubscriptionSuccess,
  deleteSubscription,
  deleteSubscriptionSuccess,
  deleteSubscriptionFailed,
  updateSubscription,
  updateSubscriptionFailed,
  updateSubscriptionSuccess,
  archiveSubscription,
  archiveSubscriptionFailed,
  archiveSubscriptionSuccess,
  unArchiveSubscription,
  unArchiveSubscriptionFailed,
  unArchiveSubscriptionSuccess,
  subscriptionbulkDelete,
  subscriptionbulkDeleteSuccess,
  subscriptionbulkDeleteFailed,
  subscriptionbulkArchive,
  subscriptionbulkArchiveSuccess,
  subscriptionbulkArchiveFailed,
  subscriptionbulkUnarchive,
  subscriptionbulkUnarchiveSuccess,
  subscriptionbulkUnarchiveFailed,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=list`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getSubscriptionSaga({ payload }) {
  let action;
  let url = `/api/v1/admin/subscriptions?`;
  try {
    if (payload['filterTab']) {
      url += `status=${payload['filterTab']}`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getSubscriptionSuccess(response);
  } catch (error) {
    action = getSubscriptionFailed(error.message);
  } finally {
    yield put(action);
  }
}

function* createSubscriptionSaga({ payload }) {
  let action;

  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/subscriptions`,
      {
        subscription: payload,
      }
    );

    action = createSubscriptionSuccess(response);
  } catch (error) {
    action = createSubscriptionFailed(error.message);
  } finally {
    yield put(action);
  }
}

function* updateSubscriptionSaga({ payload }) {
  let action;

  try {
    const id = payload.id;
    delete payload.id;
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/subscriptions/${id}`,
      { subscription: payload }
    );
    action = updateSubscriptionSuccess(response);
  } catch (error) {
    action = updateSubscriptionFailed(error.message);
  } finally {
    yield put(action);
  }
}

function* archiveSubscriptionSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/subscriptions/${id}/archive`
    );

    action = archiveSubscriptionSuccess(response);
  } catch (error) {
    action = archiveSubscriptionFailed(error.message);
  }
  yield put(action);
}

function* unArchiveSubscriptionSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/subscriptions/${id}/unarchive`
    );

    action = unArchiveSubscriptionSuccess(response);
  } catch (error) {
    action = unArchiveSubscriptionFailed(error.message);
  }
  yield put(action);
}

function* deleteSubscriptionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/products/${payload.id}`
    );

    action = deleteSubscriptionSuccess(response);
  } catch (error) {
    action = deleteSubscriptionFailed(error.message);
  }
  yield put(action);
}

function* subscriptionbulkDeleteSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/products/bulk_destroy`,
      product
    );
    action = subscriptionbulkDeleteSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = subscriptionbulkDeleteFailed(error.message);
  }
  yield put(action);
}

function* subscriptionbulkArchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const subscription = {
    subscription: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/subscriptions/bulk_archive`,
      subscription
    );
    action = subscriptionbulkArchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = subscriptionbulkArchiveFailed('');
  }
  yield put(action);
}

function* subscriptionbulkUnarchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const subscription = {
    subscription: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/subscriptions/bulk_unarchive`,
      subscription
    );
    action = subscriptionbulkUnarchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = subscriptionbulkUnarchiveFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getSubscription, getSubscriptionSaga);
  yield takeLatest(createSubscription, createSubscriptionSaga);
  yield takeLatest(updateSubscription, updateSubscriptionSaga);
  yield takeLatest(archiveSubscription, archiveSubscriptionSaga);
  yield takeLatest(unArchiveSubscription, unArchiveSubscriptionSaga);
  yield takeLatest(deleteSubscription, deleteSubscriptionSaga);
  yield takeLatest(subscriptionbulkDelete, subscriptionbulkDeleteSaga);
  yield takeLatest(subscriptionbulkArchive, subscriptionbulkArchiveSaga);
  yield takeLatest(subscriptionbulkUnarchive, subscriptionbulkUnarchiveSaga);
}
