import { CloseOutlined, ProfileOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Drawer,
  Input,
  Modal,
  Form,
  Checkbox,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { dig } from 'digdata';
import { getImageURI } from '../../../utils/apisauce';
import DataTable from '../../../components/DataTable';
import { getClient, updateAddress, deleteAddress } from '../reducers';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import {
  EditFilledIcon,
  DeleteFilledIcon,
} from '../../../components/Icons/Icons';

class PersonalProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: false,
      selectedRow: null,
      formData: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;
    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getClient,
    } = this.props;

    getClient(id);
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
    });
  };

  onEdit = record => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData: { ...record },
    });
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
      formData: null,
    });
  };

  onSubmit = values => {
    const { formData } = this.state;
    const { updateAddress } = this.props;
    updateAddress({ ...formData, ...values });
    // this.closeModal();
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteAddress } = this.props;

    deleteAddress(selectedRow.id);
    this.closeModal();
  };

  onInputChange = ({ target: { name, value } }) => {
    const formData = { ...this.state.formData };

    formData[name] = value;
    this.setState({ formData });
  };

  tableColumns = () => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'address_title',
      key: 'address_title',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Postal Code',
      dataIndex: 'postal_code',
      key: 'postal_code',
    },
    {
      title: 'Unit No.',
      dataIndex: 'unit_number',
      key: 'unit_number',
      align: 'center',
    },
    {
      title: 'Default Address',
      key: 'default',
      width: 180,
      align: 'center',
      render: function adminStatus(_, record) {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {record?.default ? 'yes' : 'no'}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || this.onDelete(record)}
          />
        </div>
      ),
    },
  ];

  render() {
    const { modalOpened, selectedRow, formData } = this.state;
    const { client, loading, submitting } = this.props;

    return (
      <>
        <div className="page-content">
          {client['personal_users'] && client['personal_users'].length > 0 && (
            <div className="client-info">
              <div className="user-avatar">
                <div className="user-avatar-picture">
                  <Avatar
                    src={getImageURI(
                      dig(client['personal_users'][0], 'picture_path.url')
                    )}
                    icon={<ProfileOutlined />}
                    size={80}
                  />
                </div>
                <div className="user-avatar-info">
                  <div className="user-name">
                    {client['personal_users'][0].first_name}{' '}
                    {client['personal_users'][0].last_name}
                  </div>
                  <div className="client-id">Client ID: {client.id}</div>
                </div>
              </div>
              <div className="user-info-block">
                <label>Phone</label>
                <span>{client['personal_users'][0].mobile}</span>
              </div>
              <div className="user-info-block">
                <label>Email</label>
                <span>{client['personal_users'][0].email}</span>
              </div>
              <div className="user-info-block">
                <label>Saved Addresses</label>
                <span style={{ textAlign: 'center' }}>
                  {client['address_books'] ? client['address_books'].length : 0}
                </span>
              </div>
            </div>
          )}
          <Card title="Addresses">
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              data={client['address_books']}
              columns={this.tableColumns()}
            />
          </Card>
        </div>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={modalOpened === 1}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Edit Address</h3>
                <div className="header-actions">
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <Formik
                  initialValues={{
                    ...formInitialValues['addPersonalClientAddressForm'],
                    ...formData,
                  }}
                  validationSchema={
                    formValidations['AddPersonalClientAddressFormValidation']
                  }
                  onSubmit={values => this.onSubmit(values)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Address Title</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['address_title'] &&
                              errors['address_title']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['address_title'] &&
                              errors['address_title']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="address_title"
                              value={values.address_title}
                              placeholder="Enter street name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Address</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['address'] && errors['address']
                                ? 'error'
                                : null
                            }
                            help={touched['address'] && errors['address']}
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="address"
                              value={values.address}
                              placeholder="e.g building name, floor"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Postal Code</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['postal_code'] && errors['postal_code']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['postal_code'] && errors['postal_code']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="postal_code"
                              value={values.postal_code}
                              placeholder="Enter postal code"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <label>Unit Number</label>
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              touched['unit_number'] && errors['unit_number']
                                ? 'error'
                                : null
                            }
                            help={
                              touched['unit_number'] && errors['unit_number']
                            }
                            style={{ margin: 0 }}
                          >
                            <Input
                              name="unit_number"
                              value={values.unit_number}
                              placeholder="Enter unit number"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item style={{ marginTop: 10 }}>
                          <Checkbox
                            name="default"
                            checked={values.default}
                            onChange={handleChange}
                          >
                            Default Address
                          </Checkbox>
                        </Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          disabled={submitting}
                          loading={submitting}
                        >
                          Save
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </>
          )}
        </Drawer>
        <Modal
          className="delete-address-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <h1 className="main-message pop-up-heading">Are you sure?</h1>
              <p className="pop-up-body">
                Do you really want to remove this address? The process cannot be
                undone.
              </p>
            </>
          )}
        </Modal>
      </>
    );
  }
}

PersonalProfile.propTypes = {
  loading: PropTypes.bool,
  client: PropTypes.object,
  match: PropTypes.object,
  getClient: PropTypes.func,
  updateAddress: PropTypes.func,
  deleteAddress: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.client.loading,
    client: state.client.client,
    submitting: state.client.submitting,
    error: state.client.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClient: id => dispatch(getClient({ type: 'personal', id })),
    updateAddress: data => dispatch(updateAddress(data)),
    deleteAddress: id => dispatch(deleteAddress(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PersonalProfile));
