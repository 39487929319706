import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const closeModal = (uiState, updateUI) => {
  updateUI({
    ...uiState,
    selectedRow: null,
    modalOpened: false,
  });
};
const performUnarchive = (
  selectedRow,
  servicebulkUnarchive,
  setSelectedRows
) => {
  servicebulkUnarchive(selectedRow);
  setSelectedRows([]);
};

const UnarchiveService = ({
  uiState,
  updateUI,
  servicebulkUnarchive,
  setSelectedRows,
}) => {
  const { modalOpened, selectedRow } = uiState;
  return (
    <Modal
      className="delete-user-modal"
      visible={modalOpened === 4}
      onCancel={() => closeModal(uiState, updateUI)}
      onOk={() =>
        performUnarchive(selectedRow, servicebulkUnarchive, setSelectedRows)
      }
      okText="Yes"
      destroyOnClose
    >
      {selectedRow && (
        <>
          <p className="main-message">
            Are you sure want to unarchive services?
          </p>
        </>
      )}
    </Modal>
  );
};

UnarchiveService.propTypes = {
  uiState: PropTypes.object,
  updateUI: PropTypes.object,
  servicebulkUnarchive: PropTypes.func,
};

export default UnarchiveService;
