import { createSlice } from '@reduxjs/toolkit';
// import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'companyAccountTransaction',
  initialState: {
    submitting: false,
    loading: false,
    transactions: [],
    error: null,
  },
  reducers: {
    getTransaction: state => {
      state.loading = true;
      state.error = null;
    },
    getTransactionSuccess: (state, { payload }) => {
      state.loading = false;
      state.transactions = payload.accounting_transactions;
      state.error = null;
    },
    getTransactionFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
  },
});

export const {
  getTransaction,
  getTransactionSuccess,
  getTransactionFailed,
} = slice.actions;

export default slice.reducer;
