import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import * as tagReducers from './reducers';

const API_BASE_NAME = '/api/v1/admin/properties/';

function* getTagSaga({ payload }) {
  let action;
  let url = `${API_BASE_NAME}?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }

    const response = yield call(request, 'GET', url);
    action = tagReducers.getTagsSuccess(response);
  } catch (error) {
    action = tagReducers.getTagsFailed(error.message);
  }
  yield put(action);
}

function* createTagSaga({ payload }) {
  let action;
  try {
    const response = yield call(request, 'POST', `${API_BASE_NAME}`, payload);
    action = tagReducers.createTagSuccess(response);
  } catch (error) {
    action = tagReducers.createTagFailed(error.message);
  }
  yield put(action);
}

function* updateTagSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `${API_BASE_NAME}${payload.property.id}`,
      payload
    );
    action = tagReducers.updateTagSuccess(response);
  } catch (error) {
    action = tagReducers.updateTagFailed(error.message);
  }
  yield put(action);
}

function* deleteTagSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `${API_BASE_NAME}${payload}`
    );
    action = tagReducers.deleteTagSuccess(response);
  } catch (error) {
    action = tagReducers.deleteTagFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(tagReducers.getTags, getTagSaga);
  yield takeLatest(tagReducers.createTag, createTagSaga);
  yield takeLatest(tagReducers.updateTag, updateTagSaga);
  yield takeLatest(tagReducers.deleteTag, deleteTagSaga);
}
