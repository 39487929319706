import React from 'react';
import { Modal } from 'antd';

const closeModal = (uiState, updateUI) => {
  updateUI({
    ...uiState,
    selectedRow: null,
    modalOpened: false,
  });
};
const performDelete = (selectedRow, deleteProduct) => {
  deleteProduct(selectedRow);
};

const DeleteProduct = ({ uiState, updateUI, deleteProduct }) => {
  const { modalOpened, selectedRow } = uiState;
  return (
    <Modal
      className="delete-user-modal"
      visible={modalOpened === 2}
      onCancel={() => closeModal(uiState, updateUI)}
      onOk={() => performDelete(selectedRow, deleteProduct)}
      okText="Yes"
      destroyOnClose
    >
      {selectedRow && (
        <>
          <h1 className="main-message pop-up-heading">Are you sure?</h1>
          <p className="pop-up-body">
            Do you really want to remove this product? The process cannot be
            undone.
          </p>
        </>
      )}
    </Modal>
  );
};

export default DeleteProduct;
