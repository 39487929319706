import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  createSendSms,
  createSendSmsSuccess,
  createSendSmsFailed,
} from './reducer';

function* createSendSmsSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/sms';
  try {
    const response = yield call(request, 'POST', url, {
      sms: payload,
    });
    action = createSendSmsSuccess(response);
  } catch (error) {
    action = createSendSmsFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(createSendSms, createSendSmsSaga);
}
