import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  getTransaction,
  getTransactionSuccess,
  getTransactionFailed,
} from './reducers';

function* getTransactionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/accounting_transactions?filter[account_id]=${payload}`
    );
    action = getTransactionSuccess(response);
  } catch (error) {
    action = getTransactionFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getTransaction, getTransactionSaga);
}
