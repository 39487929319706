import React from 'react';
import moment from 'moment';
import { capitalize } from '../../../utils/apisauce';
import { Tooltip, Pagination } from 'antd';
import {
  DeleteFilledIcon,
  EditFilledIcon,
} from '../../../components/Icons/Icons';
import { CloseOutlined, CalendarOutlined } from '@ant-design/icons';

function MobileTable({
  records,
  handleTableChange,
  pagination,
  allTab,
  onEdit,
  onDelete,
  cancelJob,
  updateDays,
  getDayStyle,
  repeatingTab,
  onClickRow,
}) {
  const statusLabels = {
    pending_schedule: {
      color: '#F1B13B',
      label: 'Unassigned',
    },
    scheduled: {
      color: '#34A6BD',
      label: 'In Scheduled',
    },
    in_progress: {
      color: '#6ABB98',
      label: 'In Progress',
    },
    completed: {
      color: '#828282',
      label: 'Completed',
    },
    cancelled: {
      color: '#DF5E67',
      label: 'Cancelled/Skipped',
    },
  };

  function SizeChanger(current, size) {
    const sorter = {
      column: {
        dataIndex: 'service_date',
        defaultSortOrder: 'ascend',
        key: 'service_date',
        title: 'Service Date',
        width: 120,
      },
      columnKey: 'service_date',
      field: 'service_date',
      order: 'ascend',
    };
    const filter = null;
    const pagination = {
      current,
      pageSize: size,
    };
    handleTableChange(pagination, filter, sorter);
  }
  return (
    <>
      {records.map(el => (
        <table
          className="mob-table"
          onClick={repeatingTab ? e => onClickRow(e, el) : ''}
          key={el.id}
        >
          {repeatingTab ? (
            <>
              <tr className="mob-table-row">
                <th className="mob-table-heading">ID</th>
                <td className="mob-table-data">{el?.id}</td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Status</th>
                <td className="mob-table-data">
                  {el.status === 'active'
                    ? el.canceled_at
                      ? 'cancelling'
                      : el.status
                    : el.status}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Job Title</th>
                <td className="mob-table-data">
                  {el?.title ? capitalize(el.title) : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Created By</th>
                <td className="mob-table-data">
                  {el?.job_details?.creator_type
                    ? el?.job_details?.creator_type
                    : 'Order System'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Subscription</th>
                <td className="mob-table-data">
                  {!el?.job_details?.creator_type ? (
                    <span style={{ color: '#059c5a' }}>Yes</span>
                  ) : (
                    <span style={{ color: '#DF5E67' }}>No</span>
                  )}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Client Name</th>
                <td className="mob-table-data">
                  {el.job_details && el.job_details.client
                    ? el.job_details.client_type === 'PersonalClient'
                      ? capitalize(el.job_details.client.title)
                      : capitalize(el.job_details.client.business_name)
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Client Address</th>
                <td className="mob-table-data">
                  {el.job_details && el.job_details.address ? (
                    <Tooltip
                      title={`${el.job_details.address.address_title}, ${el.job_details.address.address}, ${el.job_details.address.unit_number}, ${el.job_details.address.postal_code}`}
                      placement="top"
                    >
                      {capitalize(
                        `${el.job_details.address.address_title}, ${el.job_details.address.address}, ${el.job_details.address.unit_number}, ${el.job_details.address.postal_code}`
                      ).substring(0, 20)}
                      ...
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Vendor Name</th>
                <td className="mob-table-data">
                  {el?.job_details && el.job_details.vendor
                    ? `${capitalize(el.job_details.vendor.vendor_name)}`
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Start Date</th>
                <td className="mob-table-data">
                  {moment(el.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">End Date</th>
                <td className="mob-table-data">
                  {el.end_date
                    ? moment(el.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Days Limit</th>
                <td className="mob-table-data">
                  {el.days_quantity_limit ? el.days_quantity_limit : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading"># of Jobs</th>
                <td className="mob-table-data">
                  {el?.jobs_count ? el.jobs_count : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Term</th>
                <td className="mob-table-data">
                  {`${el.term_value ? el.term_value : '-'} ${
                    el.term_type ? el.term_type : ''
                  }`}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Frequency</th>
                <td className="mob-table-data">
                  {`Every ${el.frequency_value} week`}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Selected Days</th>
                <td className="mob-table-data">
                  <>
                    <span style={getDayStyle(el, 'sunday')}>S</span>
                    <span style={getDayStyle(el, 'monday')}>M</span>
                    <span style={getDayStyle(el, 'tuesday')}>T</span>
                    <span style={getDayStyle(el, 'wednesday')}>W</span>
                    <span style={getDayStyle(el, 'thursday')}>T</span>
                    <span style={getDayStyle(el, 'friday')}>F</span>
                    <span style={getDayStyle(el, 'saturday')}>S</span>
                  </>
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Action</th>
                <td className="mob-table-data">
                  <div className="ant-table-action-cell">
                    <EditFilledIcon
                      onClick={e => e.stopPropagation() || onEdit(el)}
                    />
                    <DeleteFilledIcon
                      onClick={e => e.stopPropagation() || onDelete(el)}
                    />
                    <CloseOutlined
                      onClick={e => e.stopPropagation() || cancelJob(el)}
                    />
                    <CalendarOutlined
                      onClick={e => e.stopPropagation() || updateDays(el)}
                    />
                  </div>
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr className="mob-table-row">
                <th className="mob-table-heading">ID</th>
                <td className="mob-table-data">{el?.id}</td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Status</th>
                <td className="mob-table-data">
                  {el?.job_status ? (
                    <span
                      style={{
                        color: statusLabels[el?.job_status]
                          ? statusLabels[el?.job_status].color
                          : '',
                      }}
                    >
                      {statusLabels[el?.job_status]
                        ? statusLabels[el?.job_status].label
                        : ''}
                    </span>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Service Date</th>
                <td className="mob-table-data">
                  {el?.service_date
                    ? moment(el.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Order ID</th>
                <td className="mob-table-data">
                  {el?.order ? el?.order?.id : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Order Amount</th>
                <td className="mob-table-data">
                  {el?.order ? el?.order?.amount : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Created By</th>
                <td className="mob-table-data">
                  {el?.creator_type
                    ? capitalize(el?.creator_type)
                    : 'Order System'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Job Title</th>
                <td className="mob-table-data">
                  {el?.title ? capitalize(el.title) : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Client Name</th>
                <td className="mob-table-data">
                  {el?.client_type
                    ? el.client_type === 'PersonalClient'
                      ? capitalize(el.client.title)
                      : capitalize(el.client.business_name)
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Client Address</th>
                <td className="mob-table-data">
                  {el?.address ? (
                    <Tooltip
                      title={`${el.address?.address_title}, ${el.address?.address}, ${el.address?.unit_number}, ${el.address?.postal_code}`}
                      placement="top"
                    >
                      {capitalize(
                        `${el.address?.address_title}, ${el.address?.address}, ${el.address?.unit_number}, ${el.address?.postal_code}`
                      ).substring(0, 20)}
                      ...
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Vendor Name</th>
                <td className="mob-table-data">
                  {el?.vendor?.vendor_name
                    ? capitalize(el.vendor.vendor_name)
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Assigned Worker</th>
                <td className="mob-table-data">
                  {el['assigned_worker']
                    ? Object?.keys(el['assigned_worker'])?.length > 0
                      ? `${capitalize(
                          el['assigned_worker']?.first_name
                        )} ${capitalize(el['assigned_worker']?.last_name)}`
                      : '-'
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Preferred Time</th>
                <td className="mob-table-data">
                  {el['preferred_time'] ? el['preferred_time'] : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Assigned Time</th>
                <td className="mob-table-data">
                  {el?.['assigned_time'] ? el['assigned_time'] : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Service Duration</th>
                <td className="mob-table-data">
                  {el['job_duration_in_minutes']
                    ? el['job_duration_in_minutes'] + ' min'
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Check-in Time</th>
                <td className="mob-table-data">
                  {el['checkin_time'] ? el['checkin_time'] : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Check-out Time</th>
                <td className="mob-table-data">
                  {el['checkout_time'] ? el['checkout_time'] : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Active Job Duration</th>
                <td className="mob-table-data">
                  {el?.checkin_time && el?.checkout_time
                    ? el['total_job_time']
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Job Notes</th>
                <td className="mob-table-data">
                  {el?.['job_notes']
                    ? `${
                        el['job_notes']['length'] > 25
                          ? el['job_notes'].slice(0, 25) + '...'
                          : el['job_notes']
                      }`
                    : '-'}
                </td>
              </tr>
              <tr className="mob-table-row">
                <th className="mob-table-heading">Customer Notes</th>
                <td className="mob-table-data">
                  {el?.['customer_notes']
                    ? `${
                        el['customer_notes']['length'] > 25
                          ? el['customer_notes'].slice(0, 25) + '...'
                          : el['customer_notes']
                      }`
                    : '-'}
                </td>
              </tr>
              {allTab ? (
                <tr className="mob-table-row">
                  <th className="mob-table-heading">Repeating</th>
                  <td className="mob-table-data">
                    {el['repeating_job_id'] ? (
                      <span style={{ color: '#059c5a' }}>Yes</span>
                    ) : (
                      <span style={{ color: '#DF5E67' }}>No</span>
                    )}
                  </td>
                </tr>
              ) : (
                <tr className="mob-table-row">
                  <th className="mob-table-heading">Action</th>
                  <td className="mob-table-data">
                    <div className="ant-table-action-cell">
                      <EditFilledIcon
                        onClick={e => e.stopPropagation() || onEdit(el)}
                      />
                      <DeleteFilledIcon
                        onClick={e => e.stopPropagation() || onDelete(el)}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </>
          )}
        </table>
      ))}
      <Pagination
        showSizeChanger
        simple
        style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}
        disabled={records.length == 0}
        defaultPageSize={pagination['limit'] ? pagination['limit'] : 20}
        onShowSizeChange={SizeChanger}
        current={pagination['page'] ? pagination['page'] : 1}
        onChange={pagination?.onChange}
        total={pagination['total'] ? pagination['total'] : ''}
      />
    </>
  );
}

export default MobileTable;
