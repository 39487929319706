import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Row, Col, DatePicker, Button } from 'antd';
import { dig } from 'digdata';
import moment from 'moment';
import { getImageURI } from '../../../utils/apisauce';
import { UserOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

function CustomerInfo(props) {
  const { data, activeTab, handleOnSubmit } = props;

  const [tableDate, setTableDate] = useState(
    moment(data['date'], 'DD-MM-YYYY')
  );

  const handleDateChange = date => {
    if (date === 'prev') {
      const prevDate = moment(tableDate).subtract(1, 'days');
      setTableDate(prevDate);
      handleOnSubmit({ vendor_id: data['id'], date: prevDate });
    } else if (date === 'next') {
      const nextDate = moment(tableDate).add(1, 'days');
      setTableDate(nextDate);
      handleOnSubmit({ vendor_id: data['id'], date: nextDate });
    } else {
      const _date = moment(data['date'], 'DD-MM-YYYY');
      setTableDate(_date);
      handleOnSubmit({ vendor_id: data['id'], date: _date });
    }
  };

  const _renderCustomerInfoName = activeTab => {
    if (activeTab === 'department-daily') {
      return `${data['vendor_name']}`;
    } else if (activeTab === 'worker') {
      return `${data['selectedWorker']['first_name']} ${data['selectedWorker']['last_name']}`;
    } else {
      return data['business_name']
        ? data['business_name']
        : `${data['first_name']} ${data['last_name']}`;
    }
  };

  const _renderCustomerInfoId = activeTab => {
    if (activeTab === 'customer') {
      return `Client ID: ${data['personal_client_id'] || data['id']}`;
    } else if (activeTab === 'worker') {
      return `${data['vendor_name']}`;
    } else {
      return '';
    }
  };

  const _renderCustomerPeriod = activeTab => {
    if (activeTab === 'customer' || activeTab === 'worker') {
      return (
        <div className="customer-period-info">
          <h4 className="customer-period-heading">Period Time</h4>
          <p className="customer-period-date">{`${data['start_date']} - ${data['end_date']}`}</p>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="customer-info-container">
      <div className="customer-info">
        {activeTab !== 'department-daily' && (
          <div>
            <Avatar
              size={80}
              src={getImageURI(dig(data, 'picture_path.url'))}
              icon={<UserOutlined />}
            />
          </div>
        )}
        <div className="customer-info-name-container">
          <h2 className="customer-info-name">
            {_renderCustomerInfoName(activeTab)}
          </h2>
          {activeTab === 'department-daily' && (
            <Row className="page-date-selector">
              <Col flex={1}>
                <Button
                  type="link"
                  className="page-btn"
                  icon={<LeftOutlined />}
                  onClick={() => handleDateChange('prev')}
                />
              </Col>
              <Col
                flex={3}
                //   onClick={() => this.handleDatePicker(true)}
                style={{ textAlign: 'center' }}
              >
                <DatePicker
                  bordered={false}
                  allowClear={false}
                  suffixIcon={null}
                  showToday={true}
                  format="DD-MM-YYYY"
                  open={false}
                  inputReadOnly
                  // onOpenChange={this.handleDatePicker}
                  value={tableDate}
                  // onChange={this.handleDateChange}
                />
              </Col>
              <Col flex={1} style={{ textAlign: 'end' }}>
                <Button
                  type="link"
                  className="page-btn"
                  icon={<RightOutlined />}
                  onClick={() => handleDateChange('next')}
                />
              </Col>
            </Row>
          )}
          <p className="customer-info-id">{_renderCustomerInfoId(activeTab)}</p>
        </div>
      </div>
      {_renderCustomerPeriod(activeTab)}
    </div>
  );
}

CustomerInfo.propTypes = {
  data: PropTypes.object,
  activeTab: PropTypes.string,
  handleOnSubmit: PropTypes.func,
};

export default CustomerInfo;
