import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'client',
  initialState: {
    loading: false,
    submitting: false,
    client: {},
  },
  reducers: {
    getClient: state => {
      state.loading = true;
    },
    getClientSuccess: (state, { payload }) => {
      state.loading = false;
      state.client = payload;
    },
    getClientFailed: (state, { payload }) => {
      state.loading = false;
      apiError(payload);
    },
    createAddress: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Creating a new address ...', 0);
    },
    createAddressSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      state.client['address_books'] = payload?.address_books;

      notification.success({
        message: 'New Address',
        description: `New Address has been created.`,
      });
    },
    createAddressFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateAddress: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Updating address ...', 0);
    },
    updateAddressSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      state.client['address_books'] = payload?.address_books;

      notification.success({
        message: 'Address Updated',
        description: `Address has been updated.`,
      });
    },
    updateAddressFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    deleteAddress: state => {
      state.message = message.loading(`Deleting address ...`, 0);
    },
    deleteAddressSuccess: (state, { payload }) => {
      state.message();
      state.client['address_books'] = state.client['address_books'].filter(
        el => el.id !== payload.id
      );
      notification.success({
        message: `Delete Address`,
        description: `Address ${payload.id} have been deleted.`,
      });
    },
    deleteAddressFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    createUser: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Creating a new user ...', 0);
    },
    createUserSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      state.client['business_users'].push(payload);

      notification.success({
        message: 'New User',
        description: `New User ${payload.first_name} ${payload.last_name} has been created.`,
      });
    },
    createUserFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateUser: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Updating user ...', 0);
    },
    updateUserSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const idx = state.client['business_users'].findIndex(
        el => el.id === payload.id
      );
      if (idx >= 0) {
        state.client['business_users'][idx] = payload;
      }

      notification.success({
        message: 'User Updated',
        description: `Business user ${payload.id} has been updated.`,
      });
    },
    updateUserFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    deleteUser: state => {
      state.message = message.loading(`Deleting user ...`, 0);
    },
    deleteUserSuccess: (state, { payload }) => {
      state.message();
      state.client['business_users'] = state.client['business_users'].filter(
        el => el.id !== payload.id
      );
      notification.success({
        message: `Delete User`,
        description: `User ${payload.first_name} ${payload.last_name} have been deleted.`,
      });
    },
    deleteUserFailed: (state, { payload }) => {
      state.delete();
      apiError(payload);
    },
  },
});

export const {
  getClient,
  getClientFailed,
  getClientSuccess,
  createAddress,
  createAddressFailed,
  createAddressSuccess,
  updateAddress,
  updateAddressFailed,
  updateAddressSuccess,
  deleteAddress,
  deleteAddressFailed,
  deleteAddressSuccess,
  createUser,
  createUserFailed,
  createUserSuccess,
  updateUser,
  updateUserFailed,
  updateUserSuccess,
  deleteUser,
  deleteUserFailed,
  deleteUserSuccess,
} = slice.actions;

export default slice.reducer;
