import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const INIT_FORM = {
  id: null,
  title: null,
};

const DEFAULT_TAGS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'tags',
  initialState: {
    submitting: false,
    loading: false,
    tags: DEFAULT_TAGS,
    error: null,
    formData: INIT_FORM,
  },
  reducers: {
    getTags: (state, { page }) => {
      state.loading = true;
      state.tags.page = page || 1;
    },
    getTagsSuccess: (state, { payload }) => {
      state.loading = false;

      state.tags = {
        data: payload.properties,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getTagsFailed: state => {
      state.loading = false;
    },
    createTag: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Creating a new tag ...', 0);
    },
    createTagSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const tag = payload.property;
      state.tags.data.push({
        ...tag,
      });
      state.formData = {
        id: null,
        title: null,
      };
      notification.success({
        message: 'New Tag',
        description: `New Tag ${tag.title} has been created.`,
      });
    },
    createTagFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateTag: state => {
      state.submitting = true;
      state.message = message.loading('Updating tag info...', 0);
    },
    updateTagSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      const tag = payload.property;
      const idx = state.tags.data.findIndex(el => el.id === tag.id);
      if (idx >= 0) {
        state.tags.data[idx] = {
          ...state.tags.data[idx],
          ...tag,
        };
      }
      state.formData = {
        id: null,
        title: null,
      };
      notification.success({
        message: 'Tag Updated',
        description: `Tag ${tag.title} has been updated.`,
      });
    },
    updateTagFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    deleteTag: state => {
      state.message = message.loading('Deleting tag ...', 0);
    },
    deleteTagSuccess: (state, { payload }) => {
      state.message();
      const tag = payload.property;
      state.tags.data = state.tags.data.filter(x => x.id !== tag.id);
      notification.success({
        message: 'Delete Tag',
        description: `Tag ${tag.title} have been deleted.`,
      });
    },
    deleteTagFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    formUpdate: (state, { payload }) => {
      const formData = { ...state.formData };
      const { name, value } = payload;
      formData[name] = value;
      state.formData = formData;
    },
    editTag: (state, { payload }) => {
      const formData = { ...payload };
      state.formData = formData;
    },
  },
});

export const {
  getTags,
  getTagsSuccess,
  getTagsFailed,
  createTag,
  createTagSuccess,
  createTagFailed,
  updateTag,
  updateTagSuccess,
  updateTagFailed,
  deleteTag,
  deleteTagSuccess,
  deleteTagFailed,
  formUpdate,
  editTag,
} = slice.actions;

export default slice.reducer;
