import { createSlice } from '@reduxjs/toolkit';
import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'reports',
  initialState: {
    activeTab: 'customer',
    submitting: false,
    loading: false,
    meta: null,
    modalLoading: false,
    vendorQueryLoading: false,
    clientQueryLoading: false,
    clients: [],
    vendors: [],
    modalJob: null,
    vendorWorkers: [],
    customerDetails: null,
    workerDetails: null,
    groupedWorkerDetails: null,
    workerDailyDetails: null,
    error: null,
  },
  reducers: {
    setModalJob: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
    },
    getJobById: state => {
      state.modalLoading = true;
    },
    getJobByIdSuccess: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload['job'];
    },
    getJobByIdFailed: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
      apiError(payload);
    },
    setTab: (state, { payload }) => {
      if (state.activeTab !== payload) {
        state.activeTab = payload;
        state.clients = [];
        state.customerDetails = null;
        state.workerDetails = null;
        state.workerDailyDetails = null;
        state.groupedWorkerDetails = null;
      }
    },
    getClients: (state, { payload }) => {
      if (!payload['searchMode']) {
        state.loading = true;
        state.error = null;
      }
    },
    getClientsSuccess: (state, { payload }) => {
      state.loading = false;
      state.clientQueryLoading = false;
      state.error = null;
      // state.meta = payload['meta'];
      state.clients =
        payload['personal_clients'] || payload['business_clients'];
    },
    getClientsFailed: (state, { payload }) => {
      state.loading = false;
      state.clientQueryLoading = false;
      state.error = payload;
      apiError(payload);
    },
    getVendors: state => {
      state.loading = true;
      state.error = null;
    },
    getVendorsByQuery: state => {
      state.vendorQueryLoading = true;
      state.error = null;
    },
    getVendorsByQueryDebounce: state => {
      state.vendorQueryLoading = true;
      state.error = null;
    },
    getVendorsSuccess: (state, { payload }) => {
      state.loading = false;
      state.vendorQueryLoading = false;
      state.error = null;
      state.vendors = payload['vendors'];
    },
    getVendorsFailed: (state, { payload }) => {
      state.loading = false;
      state.vendorQueryLoading = false;
      state.error = payload;
      apiError(payload);
    },
    getVendorWorkers: state => {
      state.loading = true;
      state.error = null;
      state.vendorWorkers = [];
    },
    getVendorWorkersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.vendorWorkers = payload;
    },
    getVendorWorkersFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    getReportCustomers: state => {
      state.loading = true;
      state.error = null;
      // state.customerDetails = null;
      state.workerDetails = null;
      state.workerDailyDetails = null;
    },
    getReportCustomersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.customerDetails = payload;
      state.meta = payload['meta'];
    },
    getReportCustomersFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    getReportWorkers: state => {
      state.loading = true;
      state.error = null;
      state.customerDetails = null;
      state.workerDailyDetails = null;
      state.groupedWorkerDetails = null;
    },
    getReportWorkersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.workerDetails = payload;
      state.meta = payload['meta'];
    },
    getReportWorkersFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    getReportGroupedWorkers: state => {
      state.loading = true;
      state.error = null;
      state.customerDetails = null;
      state.workerDailyDetails = null;
      state.workerDetails = null;
    },
    getReportGroupedWorkersSuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.groupedWorkerDetails = payload;
      state.meta = payload['meta'];
    },
    getReportGroupedWorkersFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },

    getReportWorkerDaily: state => {
      state.loading = true;
      state.error = null;
      state.customerDetails = null;
      state.workerDetails = null;
      state.groupedWorkerDetails = null;
    },
    getReportWorkerDailySuccess: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.workerDailyDetails = payload;
      state.meta = payload['meta'];
    },
    getReportWorkerDailyFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    resetReportsData: state => {
      state.loading = false;
      state.customerDetails = null;
      state.workerDetails = null;
      state.workerDailyDetails = null;
      state.groupedWorkerDetails = null;
    },
  },
});

export const {
  setTab,
  getClients,
  getClientsFailed,
  getClientsSuccess,
  getVendors,
  getVendorsByQuery,
  getVendorsByQueryDebounce,
  getVendorsFailed,
  getVendorsSuccess,
  getVendorWorkers,
  getVendorWorkersFailed,
  getVendorWorkersSuccess,
  getReportCustomers,
  getReportCustomersFailed,
  getReportCustomersSuccess,
  getReportWorkers,
  getReportWorkersFailed,
  getReportWorkersSuccess,
  getReportGroupedWorkers,
  getReportGroupedWorkersSuccess,
  getReportGroupedWorkersFailed,
  getReportWorkerDaily,
  getReportWorkerDailyFailed,
  getReportWorkerDailySuccess,
  resetReportsData,
  setModalJob,
  getJobById,
  getJobByIdSuccess,
  getJobByIdFailed,
} = slice.actions;

export default slice.reducer;
