import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_PRODUCTS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'product',
  initialState: {
    loading: false,
    created: false,
    submitting: false,
    products: DEFAULT_PRODUCTS,
    error: '',
    media_library_images: { data: [] },
    formObject: null,
    uiState: {
      modalOpened: false,
      selectedRow: null,
    },
  },
  reducers: {
    getMedia: state => {
      state;
    },
    getMediaSuccess: (state, { payload }) => {
      state.media_library_images = {
        data: payload.library_images,
      };
    },
    getMediaFailed: state => {
      state;
    },
    getProducts: state => {
      state.loading = true;
    },
    getProductsSuccess: (state, { payload }) => {
      state.loading = false;
      state.products = {
        data: payload.products,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getProductsFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    archiveProduct: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Archiving product info...', 0);
    },
    archiveProductSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.product;
      state.loading = false;
      state.submitting = false;
      const idx = state.products.data.findIndex(
        el => el.id === payload.product.id
      );
      if (idx >= 0) {
        state.products.data.splice(idx, 1);
      }
      notification.success({
        message: 'Product Archived',
        description: `Product ${payload.product.title} has been Archived.`,
      });
    },
    archiveProductFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    unArchiveProduct: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Unarchiving product info...', 0);
    },
    unArchiveProductSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.product;
      state.loading = false;
      state.submitting = false;
      const idx = state.products.data.findIndex(
        el => el.id === payload.product.id
      );
      if (idx >= 0) {
        state.products.data.splice(idx, 1);
      }
      notification.success({
        message: 'Product Unarchived',
        description: `Product ${payload.product.title} has been unarchived.`,
      });
    },
    unArchiveProductFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    createProduct: state => {
      state.loading = true;
      state.created = false;
      state.submitting = true;
      state.message = message.loading('Creating a new product ...', 0);
    },
    setCreatedFalse: state => {
      state.created = false;
    },
    createProductSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.product;
      state.loading = false;
      state.created = true;
      state.submitting = false;
      state.products.data.push(payload.product);

      notification.success({
        message: 'New Product',
        description: `New Product ${payload.product.title} has been created.`,
      });
    },
    createProductFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      state.created = false;
      apiError(payload);
    },
    updateProduct: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Updating product info...', 0);
    },
    updateProductSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.product;
      state.loading = false;
      state.submitting = false;
      const idx = state.products.data.findIndex(
        el => el.id === payload.product.id
      );
      if (idx >= 0) {
        state.products[idx] = payload.product;
      }
      notification.success({
        message: 'Product Updated',
        description: `Product ${payload.product.title} has been updated.`,
      });
    },
    updateProductFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    productbulkDelete: state => {
      state.message = message.loading('Deleting products ...', 0);
    },
    productbulkDeleteSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.products.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.products.data = updateArr;
      notification.success({
        message: 'Products Deleted',
        description: `Selected Products ${payload.ids} has been deleted.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    productbulkDeleteFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    productbulkArchive: state => {
      state.message = message.loading('Archiving Products ...', 0);
    },
    productbulkArchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.products.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.products.data = updateArr;
      notification.success({
        message: 'Products Archived',
        description: `Selected Products ${payload.ids} has been Archived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    productbulkArchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    productbulkUnarchive: state => {
      state.message = message.loading('Unarchiving Products ...', 0);
    },
    productbulkUnarchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.products.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.products.data = updateArr;
      notification.success({
        message: 'Products Unarchived',
        description: `Selected Products ${payload.ids} has been Unarchived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    productbulkUnarchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    deleteProduct: state => {
      state.message = message.loading('Deleting product ...', 0);
    },
    deleteProductSuccess: (state, { payload }) => {
      state.message();
      state.products.data = state.products.data.filter(
        x => x.id !== payload.product.id
      );
      notification.success({
        message: 'Product Deleted',
        description: `Product ${payload.product.title} has been deleted.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    deleteProductFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },

    getProductById: (state, { payload }) => {
      state.formObject = payload;
    },
    updateUI: (state, { payload }) => {
      state.uiState = { ...payload };
    },
  },
});

export const {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  setCreatedFalse,
  getProducts,
  getProductsFailed,
  getProductsSuccess,
  archiveProduct,
  archiveProductFailed,
  archiveProductSuccess,
  unArchiveProduct,
  unArchiveProductFailed,
  unArchiveProductSuccess,
  createProduct,
  createProductSuccess,
  createProductFailed,
  updateProduct,
  updateProductSuccess,
  updateProductFailed,
  deleteProduct,
  deleteProductFailed,
  deleteProductSuccess,
  getProductById,
  updateUI,
  productbulkDelete,
  productbulkDeleteSuccess,
  productbulkDeleteFailed,
  productbulkArchive,
  productbulkArchiveSuccess,
  productbulkArchiveFailed,
  productbulkUnarchive,
  productbulkUnarchiveSuccess,
  productbulkUnarchiveFailed,
} = slice.actions;
export default slice.reducer;
