import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  getVendor,
  getVendorSuccess,
  getVendorFailed,
  createUser,
  createUserSuccess,
  createUserFailed,
  updateUser,
  updateUserSuccess,
  updateUserFailed,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailed,
} from './reducers';

function* getVendorSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/vendor/users?status=${payload.activeTab}`
    );
    action = getVendorSuccess(response['vendor_users']);
  } catch (error) {
    action = getVendorFailed(error.message);
  }
  yield put(action);
}

function* createUserSaga({ payload }) {
  let action;
  try {
    let response;
    yield call(request, 'POST', `api/v1/vendor/users`, payload);
    if (payload?.activeTab === 'all' || !payload.activeTab) {
      response = yield call(request, 'GET', `/api/v1/vendor/users`);
    } else {
      response = yield call(
        request,
        'GET',
        `/api/v1/vendor/users?status=${payload.activeTab}`
      );
    }
    action = createUserSuccess(response);
  } catch (error) {
    action = createUserFailed(error.message);
  }
  yield put(action);
}

function* updateUserSaga({ payload }) {
  let action;
  try {
    let response;
    yield call(
      request,
      'PUT',
      `/api/v1/vendor/users/${payload.vendor_user.id}`,
      payload
    );
    if (payload?.activeTab === 'all' || !payload.activeTab) {
      response = yield call(request, 'GET', `/api/v1/vendor/users`);
    } else {
      response = yield call(
        request,
        'GET',
        `/api/v1/vendor/users?status=${payload.activeTab}`
      );
    }
    action = updateUserSuccess(response);
  } catch (error) {
    action = updateUserFailed(error.message);
  }
  yield put(action);
}

function* deleteUserSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/vendor/users/${payload.userId}`
    );
    action = deleteUserSuccess(response);
  } catch (error) {
    action = deleteUserFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getVendor, getVendorSaga);
  yield takeLatest(createUser, createUserSaga);
  yield takeLatest(updateUser, updateUserSaga);
  yield takeLatest(deleteUser, deleteUserSaga);
}
