import { createSlice } from '@reduxjs/toolkit';
// import { message, notification } from "antd";

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'dashboard',
  initialState: {
    getjobFeedCountLoading: false,
    getJobStatisticsCountLoading: false,
    getWorkStatusLoading: false,
    getJobFeedLoading: false,
    getWorkerLocationLoading: true,
    getActiveClientsCountLoading: true,
    getJobsLoading: true,
    jobFeedCount: {
      total_count: 0,
      completed_count: 0,
      remaining_count: 0,
    },
    jobStatisticsCount: {
      unassigned_jobs_count: 0,
      assigned_vendor_count: 0,
      cancelled_and_skipped_jobs_count: 0,
    },
    clientStatisticsCount: {
      activity_since_yesterday_count: 0,
      total_active_users_count: 0,
      activity_since_seven_days_count: 0,
    },
    workStatus: [],
    jobFeeds: [],
    workerLocation: [],
    jobs: [],
  },
  reducers: {
    getActiveClientsCount: state => {
      state.getActiveClientsCountLoading = true;
    },
    getActiveClientsCountSuccess: (state, { payload }) => {
      state.getActiveClientsCountLoading = false;
      state.clientStatisticsCount = payload;
    },
    getActiveClientsCountFailed: (state, { payload }) => {
      state.getActiveClientsCountLoading = false;
      state.clientStatisticsCount = {
        activity_since_yesterday_count: 0,
        activity_since_seven_days_count: 0,
        total_active_users_count: 0,
      };
      apiError(payload);
    },
    getJobFeedCount: state => {
      state.getjobFeedCountLoading = true;
    },
    getJobFeedCountSuccess: (state, { payload }) => {
      state.getjobFeedCountLoading = false;
      state.jobFeedCount = payload;
    },
    getJobFeedCountFailed: (state, { payload }) => {
      state.getjobFeedCountLoading = false;
      state.jobFeedCount = {
        total_count: 0,
        completed_count: 0,
        remaining_count: 0,
      };
      apiError(payload);
    },
    getJobStatisticsCount: state => {
      state.getJobStatisticsCountLoading = true;
    },
    getJobStatisticsCountSuccess: (state, { payload }) => {
      state.getJobStatisticsCountLoading = false;
      state.jobStatisticsCount = payload;
    },
    getJobStatisticsCountFailed: (state, { payload }) => {
      state.getJobStatisticsCountLoading = false;
      state.jobStatisticsCount = {
        unassigned_jobs_count: 0,
        assigned_vendor_count: 0,
        cancelled_and_skipped_jobs_count: 0,
      };
      apiError(payload);
    },
    getWorkStatus: state => {
      state.getWorkStatusLoading = true;
    },
    getWorkStatusSuccess: (state, { payload }) => {
      state.getWorkStatusLoading = false;
      state.workStatus = payload['job_details'];
    },
    getWorkStatusFailed: (state, { payload }) => {
      state.getWorkStatusLoading = false;
      state.workStatus = [];
      apiError(payload);
    },
    getJobFeed: state => {
      state.getJobFeedLoading = true;
    },
    getJobFeedSuccess: (state, { payload }) => {
      state.getJobFeedLoading = false;
      state.jobFeeds = payload['job_event_logs'];
    },
    getJobFeedFailed: (state, { payload }) => {
      state.getJobFeedLoading = false;
      apiError(payload);
    },
    getWorkerLocation: state => {
      state.getWorkerLocationLoading = true;
    },
    getWorkerLocationSuccess: (state, { payload }) => {
      state.getWorkerLocationLoading = false;
      state.workerLocation = payload['vendor_users'];
    },
    getWorkerLocationFailed: (state, { payload }) => {
      state.getWorkerLocationLoading = false;
      apiError(payload);
    },
    getJobs: state => {
      state.getJobsLoading = true;
    },
    getJobsSuccess: (state, { payload }) => {
      state.getJobsLoading = false;
      state.jobs = payload['jobs'];
    },
    getJobsFailed: (state, { payload }) => {
      state.getJobsLoading = false;
      apiError(payload);
    },
  },
});

export const {
  getActiveClientsCount,
  getActiveClientsCountSuccess,
  getActiveClientsCountFailed,
  getJobFeedCount,
  getJobFeedCountSuccess,
  getJobFeedCountFailed,
  getJobStatisticsCount,
  getJobStatisticsCountSuccess,
  getJobStatisticsCountFailed,
  getWorkStatus,
  getWorkStatusSuccess,
  getWorkStatusFailed,
  getJobFeed,
  getJobFeedSuccess,
  getJobFeedFailed,
  getWorkerLocation,
  getWorkerLocationFailed,
  getWorkerLocationSuccess,
  getJobs,
  getJobsFailed,
  getJobsSuccess,
} = slice.actions;

export default slice.reducer;
