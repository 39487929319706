import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorJob',
  initialState: {
    submitting: false,
    loading: false,
    job: {},
    modalLoading: false,
    modalJob: null,
    error: null,
    upcomingJobs: {
      limit: 0,
      total: 0,
      page: 1,
      data: [],
    },
  },
  reducers: {
    getJobById: state => {
      state.modalLoading = true;
    },
    getJobByIdSuccess: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload['job'];
    },
    getJobByIdFailed: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
      apiError(payload);
    },
    getJob: state => {
      state.loading = true;
      state.error = null;
    },
    getJobSuccess: (state, { payload }) => {
      state.loading = false;
      state.job = payload;
      state.error = null;
    },
    getJobFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    getUpcomingJob: state => {
      state.loading = true;
      state.submitting = true;
      state.error = null;
    },
    getUpcomingJobs: (state, { payload }) => {
      state.loading = true;
      state.submitting = true;
      state.upcomingJobs.page = payload.page || 1;
      state.error = null;
    },
    getUpcomingSuccess: (state, { payload }) => {
      state.loading = false;
      state.submitting = false;
      state.upcomingJobs.limit = payload.meta['per_page'];
      state.upcomingJobs.page = parseInt(payload.meta.page);
      state.upcomingJobs.total = payload.meta['total_count'];
      state.upcomingJobs.data = payload.jobs;
      state.error = null;
    },
    getUpcomingFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.submitting = false;
      apiError(payload);
    },
    updateJob: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Updating job info...', 0);
    },
    updateJobSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const idx = state.job.jobs.findIndex(el => el.id === payload.job.id);
      if (idx >= 0) {
        state.job.jobs[idx] = payload['job'];
      }

      notification.success({
        message: 'Job Updated',
        description: `Job ${payload.job.title} has been updated.`,
      });
    },
    updateJobFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    assignWorker: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Updating job info...', 0);
    },
    assignWorkerSuccess: (state, { payload }) => {
      state.message();
      state.error = null;
      state.submitting = false;

      payload.forEach(el => {
        const idx = state.job.jobs.findIndex(j => j.id === el.id);
        if (idx >= 0) {
          state.job.jobs[idx] = el;
        }
      });

      notification.success({
        message: 'Job Updated',
        description: `${payload.length} Jobs have been updated.`,
      });
    },
    assignWorkerFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    deleteJob: state => {
      state.message = message.loading(`Deleting job ...`, 0);
      state.error = null;
    },
    deleteJobSuccess: (state, { payload }) => {
      state.message();
      state.job.jobs = state.job.jobs.filter(el => el.id !== payload.id);
      state.error = null;
      notification.success({
        message: `Job Deleted`,
        description: `Job ${payload.title} have been deleted.`,
      });
    },
    deleteJobFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
    setModalJob: (state, { payload }) => {
      state.modalLoading = false;
      state.modalJob = payload;
    },
    cancelJobs: state => {
      state.loading = true;
      state.error = null;
    },
    cancelJobsSuccess: state => {
      state.loading = false;
      state.submitting = false;
      state.error = null;
      notification.success({
        message: 'Job Updated',
        description: `Job has been updated.`,
      });
    },
    cancelJobsFailed: (state, { payload }) => {
      state.loading = false;
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    skipJobs: state => {
      state.loading = true;
      state.error = null;
    },
    skipJobsSuccess: state => {
      state.loading = false;
      state.submitting = false;
      state.error = null;
      notification.success({
        message: 'Job Updated',
        description: `Job has been updated.`,
      });
    },
    skipJobsFailed: (state, { payload }) => {
      state.loading = false;
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
  },
});

export const {
  setModalJob,
  getJobById,
  getJobByIdFailed,
  getJobByIdSuccess,
  getJob,
  getJobFailed,
  getJobSuccess,
  getUpcomingJob,
  getUpcomingSuccess,
  getUpcomingFailed,
  getUpcomingJobs,
  updateJob,
  updateJobFailed,
  updateJobSuccess,
  deleteJob,
  deleteJobFailed,
  deleteJobSuccess,
  assignWorker,
  assignWorkerSuccess,
  assignWorkerFailed,
  cancelJobs,
  cancelJobsSuccess,
  cancelJobsFailed,
  skipJobs,
  skipJobsSuccess,
  skipJobsFailed,
} = slice.actions;

export default slice.reducer;
