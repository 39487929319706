import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../../utils/notification';

const DEFAULT_SUBSCIPTION = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'vendorSubscription',
  initialState: {
    loading: false,
    submitting: false,
    created: false,
    subscription: DEFAULT_SUBSCIPTION,
    error: '',
    formObject: null,
    uiState: {
      modalOpened: false,
      selectedRow: null,
    },
  },
  reducers: {
    getSubscription: state => {
      state.loading = true;
    },
    getSubscriptionSuccess: (state, { payload }) => {
      state.loading = false;
      state.subscription = {
        data: payload.subscriptions,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getSubscriptionFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    setCreatedFalse: state => {
      state.created = false;
    },
    createSubscription: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Creating a new subscription ...', 0);
    },
    createSubscriptionSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.subscription;
      state.created = true;
      state.loading = false;
      state.submitting = false;
      state.subscription.data.push(payload.subscription);

      notification.success({
        message: 'New Subscription',
        description: `New Subscription ${payload.subscription.title} has been created.`,
      });
    },
    createSubscriptionFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      state.created = false;
      apiError(payload);
    },
    updateSubscription: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Updating subscription info...', 0);
    },
    updateSubscriptionSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.subscription;
      state.loading = false;
      state.submitting = false;
      const idx = state.subscription.data.findIndex(
        el => el.id === payload.subscription.id
      );
      if (idx >= 0) {
        state.subscription[idx] = payload.subscription;
      }
      notification.success({
        message: 'Subscription Updated',
        description: `Subscription ${payload.subscription.title} has been updated.`,
      });
    },
    updateSubscriptionFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    archiveSubscription: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Archiving subscription info...', 0);
    },
    archiveSubscriptionSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.subscription;
      state.loading = false;
      state.submitting = false;
      const idx = state.subscription.data.findIndex(
        el => el.id === payload.subscription.id
      );
      if (idx >= 0) {
        state.subscription.data.splice(idx, 1);
      }
      notification.success({
        message: 'Subscription Archived',
        description: `Subscription ${payload.subscription.title} has been Archived.`,
      });
    },
    archiveSubscriptionFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    unArchiveSubscription: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Unarchiving subscription info...', 0);
    },
    unArchiveSubscriptionSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.subscription;
      state.loading = false;
      state.submitting = false;
      const idx = state.subscription.data.findIndex(
        el => el.id === payload.subscription.id
      );
      if (idx >= 0) {
        state.subscription.data.splice(idx, 1);
      }
      notification.success({
        message: 'Subscription Unarchived',
        description: `Subscription ${payload.subscription.title} has been unarchived.`,
      });
    },
    unArchiveSubscriptionFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    subscriptionbulkArchive: state => {
      state.message = message.loading('Archiving Subscriptions ...', 0);
    },
    subscriptionbulkArchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.subscription.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.subscription.data = updateArr;
      notification.success({
        message: 'Subscriptions Archived',
        description: `Selected Subscriptions ${payload.ids} has been Archived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    subscriptionbulkArchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    subscriptionbulkUnarchive: state => {
      state.message = message.loading('Unarchiving Subscriptions ...', 0);
    },
    subscriptionbulkUnarchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.subscription.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.subscription.data = updateArr;
      notification.success({
        message: 'Subscriptions Unarchived',
        description: `Selected Subscriptions ${payload.ids} has been Unarchived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    subscriptionbulkUnarchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    deleteSubscription: state => {
      state.message = message.loading('Deleting subscription ...', 0);
    },
    deleteSubscriptionSuccess: (state, { payload }) => {
      state.message();
      state.subscription.data = state.subscription.data.filter(
        x => x.id !== payload.subscription.id
      );
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
      notification.success({
        message: 'Subscription Deleted',
        description: `Subscription ${payload.subscription.title} has been deleted.`,
      });
    },
    deleteSubscriptionFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    subscriptionbulkDelete: state => {
      state.message = message.loading('Deleting subscriptions ...', 0);
    },
    subscriptionbulkDeleteSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.subscription.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.subscription.data = updateArr;
      notification.success({
        message: 'Subscriptions Deleted',
        description: `Selected Subscriptions ${payload.ids} has been deleted.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    subscriptionbulkDeleteFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    getSubscriptionById: (state, { payload }) => {
      state.formObject = payload;
      //TODO: move code from product-create to here
    },
    updateUI: (state, { payload }) => {
      state.uiState = { ...payload };
    },
  },
});

export const {
  setCreatedFalse,
  getSubscription,
  getSubscriptionById,
  getSubscriptionFailed,
  getSubscriptionSuccess,
  createSubscription,
  createSubscriptionFailed,
  createSubscriptionSuccess,
  deleteSubscription,
  deleteSubscriptionFailed,
  deleteSubscriptionSuccess,
  subscriptionbulkDelete,
  subscriptionbulkDeleteSuccess,
  subscriptionbulkDeleteFailed,
  updateSubscription,
  updateSubscriptionFailed,
  updateSubscriptionSuccess,
  archiveSubscription,
  archiveSubscriptionFailed,
  archiveSubscriptionSuccess,
  unArchiveSubscription,
  unArchiveSubscriptionFailed,
  unArchiveSubscriptionSuccess,
  subscriptionbulkArchive,
  subscriptionbulkArchiveSuccess,
  subscriptionbulkArchiveFailed,
  subscriptionbulkUnarchive,
  subscriptionbulkUnarchiveSuccess,
  subscriptionbulkUnarchiveFailed,
  updateUI,
} = slice.actions;
export default slice.reducer;
