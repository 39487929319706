import React from 'react';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import TimeKeeper from 'react-timekeeper';
import './style.css';

function useOutsideAlerter(ref, closeModal) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, closeModal]);
}

const ReactTimePicker = ({
  name,
  item,
  cahngeFormTime,
  showMinutes = false,
  disabled,
  placeholder = 'Enter Time',
}) => {
  const [focused, setFocusChange] = React.useState(false);
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, setFocusChange);

  let value = item[name];
  if (value) {
    value = value.replace(/\b0+/g, '');
    value = value.split(':')[0];
    value = Number(value).toString();
  }

  const handleFocusedChange = () => {
    setFocusChange(val => !val);
  };

  return (
    <div style={{ position: 'relative' }} ref={wrapperRef}>
      <Input
        placeholder={placeholder}
        value={
          item[name] ? moment(item[name], 'hh:mm A').format('hh:mm A') : null
        }
        readOnly
        onClick={handleFocusedChange}
        suffix={<ClockCircleOutlined onClick={handleFocusedChange} />}
        disabled={disabled}
      />
      {focused && (
        <TimeKeeper
          name={name}
          time={
            item[name]
              ? moment(item[name], 'hh:mm A').format('hh:mm A')
              : '00:00'
          }
          onChange={newTime => {
            const { formatted12 } = newTime;
            cahngeFormTime(
              item,
              moment(formatted12, 'hh:mm A').format('HH:mm')
            );
          }}
          onDoneClick={newTime => {
            const { formatted12 } = newTime;
            cahngeFormTime(
              item,
              moment(formatted12, 'hh:mm A').format('HH:mm')
            );
            setFocusChange(false);
          }}
          coarseMinutes={showMinutes ? 1 : 60}
          // switchToMinuteOnHourSelect
        />
      )}
    </div>
  );
};

export default ReactTimePicker;
