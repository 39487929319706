const createAdminForm = {
  first_name: '',
  last_name: '',
  mobile: '',
  email: '',
  password: '',
  role_name: '',
  selected_tabs: [],
  image_type: 'attached',
};

const createSubscriptionForm = {
  vendor_id: '',
  product_type: 'subscription',
  title: '',
  description: '',
  category_id: null,
  sub_category_id: null,
  property_ids: null,
  image_type: 'attached',
};

const createVendorForm = {
  first_name: '',
  last_name: '',
  mobile: '',
  email: '',
  password: '',
  image_type: 'attached',
};

const createOrderForm = {
  title: '',
  description: '',
  adjustment_amount: 1,
  adjustment_type: 'add_amount',
  account_id: 18,
};

const createVendorUserForm = {
  first_name: '',
  last_name: '',
  nric: '',
  mobile: '',
  email: '',
  postal_code: '',
  status: '',
  start_time: '',
  end_time: '',
  password: '',
  image_type: 'attached',
  skill_certificate: [],
};

const createPersonalClientForm = {
  first_name: '',
  last_name: '',
  mobile: '',
  email: '',
  password: '',
  image_type: 'attached',
};

const createBusinessClientForm = {
  business_name: '',
  uen: '',
  website: '',
};

const createOneTimeJobForm = {
  title: '',
  vendor_id: '',
  address_id: '',
  client_id: '',
  client_type: '',
  service_date: '',
};

const createRepeatingJobForm = {
  days: [],
  days_quantity_limit: '',
  frequency_type: '',
  frequency_value: '',
  address_id: '',
  client_id: '',
  client_type: '',
  title: '',
  vendor_id: '',
  start_date: '',
  job_status: '',
};

const updateRepeatingJobForm = {
  frequency_value: '',
  frequency_type: '',
  title: '',
  description: '',
  notes: '',
  job_duration_in_minutes: '',
  vendor_id: '',
  assigned_worker_id: '',
  assigned_time: '',
  subsHours: '',
  subsMin: '',
  day: '',
};

const createCategoryForm = {
  title: '',
  description: '',
  category_type: '',
  status: '',
};

const createSlideShowForm = {
  title: '',
  description: '',
  start_date: '',
  end_date: '',
  link_url: '',
  image_type: 'attached',
};

const createProductForm = {
  title: '',
  description: '',
  category_id: '',
  vendor_id: '',
  selectedTags: [],
  image: '',
  image_type: 'attached',
  formItems: [
    {
      title: '',
      description: '',
      unit: '',
      stock_count: 0,
      amount: 0,
      image: '',
      sku: Math.random(),
      image_type: 'attached',
    },
  ],
};

const createServiceForm = {
  title: '',
  description: '',
  category_id: '',
  vendor_id: '',
  image: '',
  image_type: 'attached',
  formItems: [
    {
      title: '',
      description: '',
      unit: '',
      amount: 0,
      image: '',
      property_ids: [],
      campaign_type: 'fixed_date_time',
      image_type: 'attached',
    },
  ],
};

const createClassForm = {
  title: '',
  description: '',
  category_id: '',
  vendor_id: '',
  image: '',
  image_type: 'attached',
  formItems: [
    {
      title: '',
      description: '',
      unit: '',
      amount: 0,
      image: '',
      property_ids: [],
      image_type: 'attached',
    },
  ],
};
const addClientAddressForm = {
  address_title: '',
  address: '',
  postal_code: '',
  unit_number: '',
};

const assignWorkerForm = {
  start_date: undefined,
  end_date: undefined,
  assigned_worker_id: undefined,
  assigned_time: undefined,
};

const reportCustomerForm = {
  client_type: 'personal',
  client_id: undefined,
  address_ids: [],
  start_date: null,
  end_date: null,
};

const reportWorkerForm = {
  vendor_id: undefined,
  worker_id: undefined,
  start_date: null,
  end_date: null,
};

const reportWorkerDailyForm = {
  vendor_id: undefined,
  date: null,
};

const sendSmsForm = {
  mobile: '',
  message: '',
};

const userSettingsForm = {
  sales_tax_percentage: 13,
  force_renew_subscription_topup: true,
  otp_sms_body: '%otp%',
  pool_distance_in_miles: 10,
  currency_symbol: '$',
  referral_bonus_amount_in_cents: 0,
  admin_email: '',
};

//Vendor

const createWithdrawalForm = {
  amount: null,
};

export const formInitialValues = {
  createAdminForm,
  createVendorForm,
  createOrderForm,
  createVendorUserForm,
  createPersonalClientForm,
  createBusinessClientForm,
  createOneTimeJobForm,
  createRepeatingJobForm,
  updateRepeatingJobForm,
  createCategoryForm,
  createSlideShowForm,
  createProductForm,
  createServiceForm,
  createClassForm,
  addClientAddressForm,
  assignWorkerForm,
  reportCustomerForm,
  reportWorkerForm,
  reportWorkerDailyForm,
  sendSmsForm,
  userSettingsForm,
  createSubscriptionForm,
  //Vendor
  createWithdrawalForm,
};
