// import { getImageURI } from "../../../utils/apisauce";
import Icon, { UserOutlined } from '@ant-design/icons';
import React from 'react';
import { Avatar, List, Tooltip } from 'antd';
import { capitalize, getImageURI } from '../../../utils/apisauce';
import '../style.css';
import {
  EditFilledIcon,
  DeleteFilledIcon,
} from '../../../components/Icons/Icons';
import Archive from '../../../assets/icons/archive';
import Unarchive from '../../../assets/icons/unArchive ';

// export const expandedRowRender = () => {
// 	const columns = [
// 		{title: 'Item Name', dataIndex: 'title', key:'title'},
// 		{title: 'Unit', dataIndex: 'unit', key: 'unit'},
// 		{title: 'Amount', dataIndex: 'amount', key: 'amount'}
// 	];
// 	const data = []
// 	for (let i = 0; i < 3; ++i) {
// 		data.push({
// 			key: i,
// 			title: 'dsdsas',
// 			unit: 'dsad,',
// 			amount: 'dsasd'
// 		});
// 	}
// 	return <Table Columns={columns} dataSource={data} pagination={false}/>
// }

export default (onEdit, onDelete, onArchive, onUnarchive) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: function image(title, record) {
      return (
        <Avatar
          src={getImageURI(record['image'] && record['image'].url)}
          icon={<UserOutlined />}
          size={'large'}
          shape="square"
        />
      );
    },
  },
  {
    title: 'Service Name',
    key: 'title',
    render: function serviceName(record) {
      return <span>&nbsp; {capitalize(record.title)}</span>;
    },
  },
  // {
  // 	title: "Options",
  // 	key: 'category',
  // 	render: (title, record) => (
  // 		<>
  // 			<span>{record.category? record.category.title : ''}</span>
  // 		</>
  // 	)
  // },
  {
    title: 'Item Image',
    key: 'item-image',
    render: function itemImage(title, record) {
      return (
        <List
          split={false}
          dataSource={record.items}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={getImageURI(item['image'] && item['image'].url)}
                    icon={<UserOutlined />}
                    size={'small'}
                    shape="square"
                  />
                }
              />
            </List.Item>
          )}
        />
      );
    },
  },
  {
    title: 'Item Name',
    key: 'item-name',
    render: function itemName(title, record) {
      return (
        <List
          split={false}
          dataSource={record.items}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                style={{ width: '140px' }}
                description={
                  item.title?.length > 20 ? (
                    <Tooltip title={`${item.title}`} placement="top">
                      {capitalize(item.title).substring(0, 20)}
                      <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
                        ...Read more
                      </span>
                    </Tooltip>
                  ) : (
                    item.title
                  )
                }
              />
            </List.Item>
          )}
        />
      );
    },
  },
  {
    title: 'Category',
    key: 'category',
    render: (title, record) => {
      return <p>{record.category ? capitalize(record.category.title) : ''}</p>;
    },
  },
  {
    title: 'Sub Category',
    key: 'sub_category',
    render: (title, record) => {
      return (
        <p>{record.sub_category ? capitalize(record.sub_category.name) : ''}</p>
      );
    },
  },
  {
    title: 'Quote Request',
    key: 'quote_request',
    align: 'center',
    render: function itemUnit(title, record) {
      return (
        <List
          split={false}
          dataSource={record.items}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                description={item.is_request_for_quote ? 'Yes' : 'No'}
              />
            </List.Item>
          )}
        />
      );
    },
  },
  {
    title: 'Unit',
    key: 'unit',
    render: function itemUnit(title, record) {
      return (
        <List
          split={false}
          dataSource={record.items}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta description={item.unit ? item.unit : '-'} />
            </List.Item>
          )}
        />
      );
    },
  },
  {
    title: 'Duration (mins)',
    key: 'duration_in_minutes',
    align: 'center',
    render: function duration(item, record) {
      return (
        <List
          split={false}
          dataSource={record.items}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                description={
                  item.duration_in_minutes ? item.duration_in_minutes : '-'
                }
              />
            </List.Item>
          )}
        />
      );
    },
  },
  {
    title: 'Amount Rate',
    key: 'amount-rate',
    align: 'center',
    render: function amountRate(title, record) {
      return (
        <List
          split={false}
          dataSource={record.items}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                description={item.amount ? '$' + item.amount : '-'}
              />
            </List.Item>
          )}
        />
      );
    },
  },
  // {
  //   title: 'Assigned Vendor',
  //   key: 'assigned_vendor',
  //   render: function assignedVendor(/* title, record */) {
  //     return <div></div>;
  //   },
  // },
  {
    title: 'Actions',
    key: 'action',
    align: 'center',
    render: function action(text, record) {
      return (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || onEdit(record)}
          />
          {record.status === 'active' ? (
            <Icon
              component={Archive}
              onClick={e => e.stopPropagation() || onArchive(record)}
            />
          ) : (
            <Icon
              component={Unarchive}
              onClick={e => e.stopPropagation() || onUnarchive(record)}
            />
          )}
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || onDelete(record)}
          />
        </div>
      );
    },
  },
];
