import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
  getTransactionsforTransfer,
  getTransactionsforTransferSuccess,
  getTransactionsforTransferFailed,
  getTransactionsfortopup,
  getTransactionsfortopupSuccess,
  getTransactionsfortopupFailed,
  gettransactionsforProduct,
  getTransactionsforProductSuccess,
  getTransactionsforProductFailed,
  gettransactionsforService,
  getTransactionsforServiceSuccess,
  getTransactionsforServiceFailed,
  getTransactionsforSubcription,
  getTransactionsforSubcriptionSuccess,
  getTransactionsforSubcriptionFailed,
  createTransaction,
  createTransactionSuccess,
  createTransactionFailed,
  updateTransaction,
  updateTransactionSuccess,
  updateTransactionFailed,
  deleteTransaction,
  deleteTransactionSuccess,
  deleteTransactionFailed,
  createWithdrawalRequest,
  createWithdrawalRequestSuccess,
  createWithdrawalRequestFailed,
} from './reducers';

function* gettransactionSagaforTransfer({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=mobile_transfer`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=mobile_transfer&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforTransferSuccess(response);
  } catch (error) {
    action = getTransactionsforTransferFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagaforSubscription({ payload }) {
  let action;
  let url = `api/v1/vendor/accounting_transactions?filter[transaction_for]=subscription`;

  if (payload.query) {
    url = `api/v1/vendor/accounting_transactions?filter[transaction_for]=subscription&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforSubcriptionSuccess(response);
  } catch (error) {
    action = getTransactionsforSubcriptionFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagaforService({ payload }) {
  let action;
  let url = `api/v1/vendor/accounting_transactions?filter[transaction_for]=service`;

  if (payload.query) {
    url = `api/v1/vendor/accounting_transactions?filter[transaction_for]=service&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforServiceSuccess(response);
  } catch (error) {
    action = getTransactionsforServiceFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagaforProduct({ payload }) {
  let action;
  let url = `api/v1/vendor/accounting_transactions?filter[transaction_for]=product`;

  if (payload.query) {
    url = `api/v1/vendor/accounting_transactions?filter[transaction_for]=product&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);
    action = getTransactionsforProductSuccess(response);
  } catch (error) {
    action = getTransactionsforProductFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagafortopup({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=topup`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=topup&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsfortopupSuccess(response);
  } catch (error) {
    action = getTransactionsfortopupFailed(error.message);
  }

  yield put(action);
}

function* getTransactionsSaga() {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `api/v1/vendor/accounting_transactions`
    );
    action = getTransactionsSuccess(response);
  } catch (error) {
    action = getTransactionsFailed(error.message);
  }
  yield put(action);
}

function* createTransactionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/vendors`,
      payload
    );
    action = createTransactionSuccess(response);
  } catch (error) {
    action = createTransactionFailed(error.message);
  }
  yield put(action);
}

function* updateTransactionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/vendors/${payload.id}`,
      payload
    );
    action = updateTransactionSuccess(response);
  } catch (error) {
    action = updateTransactionFailed(error.message);
  }
  yield put(action);
}

function* deleteTransactionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/vendors/${payload}`
    );
    action = deleteTransactionSuccess(response);
  } catch (error) {
    action = deleteTransactionFailed(error.message);
  }
  yield put(action);
}

function* createWithdrawalRequestSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/vendor/withdrawal_requests`,
      payload
    );
    action = createWithdrawalRequestSuccess(response);
  } catch (error) {
    action = createWithdrawalRequestFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getTransactionsforTransfer, gettransactionSagaforTransfer);
  yield takeLatest(
    getTransactionsforSubcription,
    gettransactionSagaforSubscription
  );
  yield takeLatest(gettransactionsforService, gettransactionSagaforService);
  yield takeLatest(gettransactionsforProduct, gettransactionSagaforProduct);
  yield takeLatest(getTransactionsfortopup, gettransactionSagafortopup);
  yield takeLatest(getTransactions, getTransactionsSaga);
  yield takeLatest(createTransaction, createTransactionSaga);
  yield takeLatest(updateTransaction, updateTransactionSaga);
  yield takeLatest(deleteTransaction, deleteTransactionSaga);
  yield takeLatest(createWithdrawalRequest, createWithdrawalRequestSaga);
}
