import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'pushNotification',
  initialState: {
    loadingPersonalRecipientsList: false,
    personalRecipientsList: [],
    personalRecipientsListError: '',
    sendNotificationSuccess: '',
    sendNotificationError: '',
    notificationsList: [],
    loadingNotifications: false,
    meta: {},
    allClientsLoad: false,
    searchClients: [],
  },
  reducers: {
    getPersonalRecipientsList: state => {
      state.loadingPersonalRecipientsList = true;
      state.allClientsLoad = false;
    },
    getPersonalRecipientsListSuccess: (state, { payload }) => {
      state.loadingPersonalRecipientsList = false;
      if (!payload.personal_clients?.length) {
        state.allClientsLoad = true;
      }
      if (payload.query) {
        state.searchClients = Array.from(
          new Set(
            [...state.searchClients, ...payload.personal_clients].map(a => a.id)
          )
        ).map(id => {
          return [...state.searchClients, ...payload.personal_clients].find(
            a => a.id === id
          );
        });
      }
      state.personalRecipientsList = Array.from(
        new Set(
          [...state.personalRecipientsList, ...payload.personal_clients].map(
            a => a.id
          )
        )
      ).map(id => {
        return [
          ...state.personalRecipientsList,
          ...payload.personal_clients,
        ].find(a => a.id === id);
      });
    },
    getPersonalRecipientsListFailed: (state, { payload }) => {
      state.loadingPersonalRecipientsList = false;
      state.personalRecipientsList = [];
      apiError(payload);
    },
    getNotifications: state => {
      state.loadingNotifications = true;
      state.notificationsList = [];
      state.meta = {};
    },
    getNotificationsSuccess: (state, { payload }) => {
      state.loadingNotifications = false;
      state.notificationsList = [
        ...state.notificationsList,
        ...payload.notifications,
      ];
      state.meta = payload.meta;
    },
    getNotificationsFailed: (state, { payload }) => {
      state.loadingNotifications = false;
      state.notificationsList = [];
      state.meta = {};
      apiError(payload);
    },
    sendNotification: state => {
      state.message = message.loading('Sending Notification...', 0);
    },
    sendNotificationSuccess: state => {
      state.message();
      notification.success({
        message: 'Notification Sent',
        description: `Notification have been sent.`,
      });
    },
    sendNotificationFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
  },
});

export const {
  getPersonalRecipientsList,
  getPersonalRecipientsListSuccess,
  getPersonalRecipientsListFailed,
  getNotifications,
  getNotificationsSuccess,
  getNotificationsFailed,
  sendNotification,
  sendNotificationSuccess,
  sendNotificationFailed,
} = slice.actions;

export default slice.reducer;
