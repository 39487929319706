import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getClient,
  getClientFailed,
  getClientSuccess,
  createAddress,
  createAddressFailed,
  createAddressSuccess,
  updateAddress,
  updateAddressFailed,
  updateAddressSuccess,
  deleteAddress,
  deleteAddressFailed,
  deleteAddressSuccess,
  createUser,
  createUserFailed,
  createUserSuccess,
  updateUser,
  updateUserFailed,
  updateUserSuccess,
  deleteUser,
  deleteUserFailed,
  deleteUserSuccess,
} from './reducers';

function* getClientSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/clients/${payload.type}/${payload.id}`
    );
    action = getClientSuccess(response);
  } catch (error) {
    action = getClientFailed(error.message);
  }
  yield put(action);
}

function* createAddressSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      'api/v1/clients/addresses',
      payload
    );
    action = createAddressSuccess(response);
  } catch (error) {
    action = createAddressFailed(error.message);
  }
  yield put(action);
}

function* updateAddressSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `api/v1/clients/addresses/${payload.id}`,
      payload
    );
    action = updateAddressSuccess(response);
  } catch (error) {
    action = updateAddressFailed(error.message);
  }
  yield put(action);
}

function* deleteAddressSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `api/v1/clients/addresses/${payload}`
    );
    action = deleteAddressSuccess(response);
  } catch (error) {
    action = deleteAddressFailed(error.message);
  }
  yield put(action);
}

function* createUserSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `api/v1/clients/business/${payload.id}/users`,
      payload.data
    );
    action = createUserSuccess(response);
  } catch (error) {
    action = createUserFailed(error.message);
  }
  yield put(action);
}

function* updateUserSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `api/v1/clients/business/${payload.id}/users/${payload.data.id}`,
      payload.data
    );
    action = updateUserSuccess(response);
  } catch (error) {
    action = updateUserFailed(error.message);
  }
  yield put(action);
}

function* deleteUserSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/clients/business/${payload.clientId}/users/${payload.userId}`
    );
    action = deleteUserSuccess(response);
  } catch (error) {
    action = deleteUserFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getClient, getClientSaga);
  yield takeLatest(createAddress, createAddressSaga);
  yield takeLatest(updateAddress, updateAddressSaga);
  yield takeLatest(deleteAddress, deleteAddressSaga);
  yield takeLatest(createUser, createUserSaga);
  yield takeLatest(updateUser, updateUserSaga);
  yield takeLatest(deleteUser, deleteUserSaga);
}
