import React from 'react';
import { connect } from 'react-redux';
import { Button, Select, Modal, Checkbox } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import JobTimer from 'react-compound-timer';

import {
  // assignJobToWorker,
  // reassignJobToWorker,
  assignWorkerPerm,
  updateJob,
  handleOnPermanentReassignChange,
  handleOnPermReassignSelectedDayChange,
} from '../reducers';
import {
  JobUpdateActionSkip,
  JobUpdateActionCancel,
  JobUpdateActionCheckIn,
  JobUpdateActionCheckOut,
} from '../sagas';
import DeleteModal from './delete-modal';

const { Option } = Select;

class JobCard extends React.Component {
  constructor(props) {
    super(props);
    this.permAssignRef = React.createRef();
    this.permAssignSelectedDayRef = React.createRef();

    this.state = {
      height: undefined,
      isOpened: false,
      modalOpened: false,
      overtimed: false,
      timerStatus: false,
      modalState: false,
      assignedworkerId: undefined,
      toAssing: undefined,
      startTime: '',
      cancelSkipCheckbox: true,
    };
  }
  onClickCard = () => {
    const { isOpened } = this.state;

    if (!isOpened) {
      this.setState(
        {
          height: this.contentRef.offsetHeight,
        },
        () => {
          this.setState({
            isOpened: true,
          });
        }
      );
    } else {
      this.setState(
        {
          height: undefined,
        },
        () => {
          this.setState({
            isOpened: false,
          });
        }
      );
    }
  };

  onClickViewMore = e => {
    e.stopPropagation();

    this.setState({
      modalOpened: 1,
    });
  };

  onClickCheck = e => {
    e.stopPropagation();

    const { job, checkInJob, checkoutJob } = this.props;

    if (!job.checkin_time) {
      return checkInJob(job.id);
    }
    if (!job.checkout_time) {
      return checkoutJob(job.id);
    }
  };

  onClickCancel = e => {
    e.stopPropagation();

    this.setState({
      modalOpened: 2,
    });
  };

  openConfirmationPopup = e => {
    e.stopPropagation();
    this.setState({
      modalOpened: 3,
    });
  };

  onCancelOptionChange = value => {
    this.setState(state => ({ ...state, cancelOpt: value }));
  };

  onCancel = () => {
    const { job, cancelJob, skipJob } = this.props;
    if (this.state.cancelOpt === 'cancel') {
      cancelJob({
        id: job.id,
        remove_assigned_worker_and_time: this.state.cancelSkipCheckbox,
      });
    } else if (this.state.cancelOpt === 'skip') {
      skipJob(job.id);
    }
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  isJobStatusCanceled = job_status => job_status.toLowerCase() === 'cancelled';
  isJobStatusSkipped = job_status => job_status.toLowerCase() === 'skipped';

  getJobContainerClass = job => {
    const { checkin_time, checkout_time, job_status } = job;

    let classes = '';

    if (checkin_time) {
      classes += ' job-checked-in';
    } else {
      classes += ' job-not-checked-in';
    }

    if (checkout_time) {
      classes += ' job-checked-out';
    }

    if (
      this.isJobStatusCanceled(job_status) ||
      this.isJobStatusSkipped(job_status)
    ) {
      classes += ' job-status-cancelled';
    }

    return classes;
  };

  getCheckinCheckOutDifference = (cIn, cOut) => {
    if (cIn && cOut) {
      let startTime = moment(cIn, 'hh:mm:ss a');
      let endTime = moment(cOut, 'hh:mm:ss a');
      return endTime.diff(startTime, 'minutes');
    } else {
      return 0;
    }
  };

  getTimerInitialTime = () => {
    const { job } = this.props;
    if (job && job.checkin_time && !job.checkout_time) {
      const jobCheckInTime = moment(job.checkin_time, 'hh:mm:ss a');
      const currentTime = moment(moment(), 'hh:mm:ss a');
      const diff = currentTime.diff(jobCheckInTime, 'milliseconds');
      const diff_min = currentTime.diff(jobCheckInTime, 'minutes');
      return { diff, diff_min };
    } else if (job && job.checkin_time && job.checkout_time) {
      const jobCheckInTime = moment(job.checkin_time, 'hh:mm:ss a');
      const jobCheckoutTime = moment(job.checkout_time, 'hh:mm:ss a');
      const diff = jobCheckoutTime.diff(jobCheckInTime, 'milliseconds');
      const diff_min = jobCheckoutTime.diff(jobCheckInTime, 'minutes');
      return { diff, diff_min };
    } else {
      return { diff: 0, diff_min: 0 };
    }
  };

  componentDidUpdate(prevProps) {
    const { job } = this.props;
    if (
      job &&
      prevProps['job'] !== job &&
      this.getTimerInitialTime().diff_min > job['job_duration_in_minutes']
    ) {
      this.setState({ overtimed: true });
    }
  }

  componentDidMount() {
    const { job } = this.props;
    if (
      job &&
      this.getTimerInitialTime().diff_min > job['job_duration_in_minutes']
    ) {
      this.setState({ overtimed: true });
    }
  }

  timerControl = (start, stop) => {
    const { job } = this.props;
    if (job.checkin_time && !job.checkout_time) {
      start();
      if (!this.state.timerStatus) {
        this.setState({ timerStatus: true });
      }
    }
    if (job.checkout_time) {
      stop();
      if (this.state.timerStatus) {
        this.setState({ timerStatus: false });
      }
    }
  };

  getJobCounterClass = (overtimed, checkout_time) => {
    if (overtimed && !checkout_time) {
      return 'job-counter-title--overtime';
    } else if (overtimed && checkout_time) {
      return 'job-counter-title--completed';
    } else {
      return '';
    }
  };

  handleWorkerChange = e => {
    const { workers } = this.props;
    const toAssing = workers.findIndex(worker => worker.id === e);
    this.setState({
      assignedworkerId: e,
      toAssing: toAssing,
    });
  };
  handleTimeChange = e => {
    this.setState({
      modalState: true,
      startTime: e,
    });
  };
  onAssign = () => {
    const { job, workers, assignWorkerPerm } = this.props;
    const { assignedworkerId, startTime } = this.state;
    const workerIndex = workers.findIndex(
      worker => worker.id === assignedworkerId
    );

    let assignData = {
      start_date: job.service_date,
      end_date: null, // DONT CHANGE
      assigned_worker_id: workers[workerIndex].id,
      assigned_time: startTime,
      previous_worker_id: job.assigned_worker?.id
        ? job.assigned_worker?.id
        : null,
      // job_status: 'scheduled',
    };
    assignWorkerPerm(+job.repeating_job_id, assignData);
  };

  permanentReassignHandler = () => {
    const ev = {
      target: {
        checked: !this.permAssignRef.current.props.checked,
      },
    };
    const {
      handleOnPermanentReassignChange,
      job,
      identifier,
      vendorId,
      workerId,
    } = this.props;
    handleOnPermanentReassignChange({
      ev,
      job,
      identifier,
      vendorId,
      workerId,
    });
  };
  permanentReassignSelectedDayHandler = () => {
    const ev = {
      target: {
        checked: !this.permAssignSelectedDayRef.current.props.checked,
      },
    };
    const {
      handleOnPermReassignSelectedDayChange,
      job,
      identifier,
      vendorId,
      workerId,
      currentDate,
    } = this.props;
    handleOnPermReassignSelectedDayChange({
      ev,
      job,
      identifier,
      vendorId,
      workerId,
      currentDate,
    });
  };
  closeJobModal = () => {
    this.setState({ modalState: false });
    const _1stRef = this.permAssignRef.current.props.checked;
    _1stRef
      ? this.permanentReassignHandler()
      : this.permanentReassignSelectedDayHandler();
  };

  render() {
    const {
      isOpened,
      height,
      modalOpened,
      overtimed,
      timerStatus,
      modalState,
      toAssing,
      startTime,
    } = this.state;

    const {
      dragging,
      job,
      allowUpdate,
      permanentReassign,
      permanentReassignSelectedDay,
      // handleOnPermReassignSelectedDayChange,
      // handleOnPermanentReassignChange,
      identifier,
      // vendorId,
      // workerId,
      // currentDate,
      workers,
      // areaRef,
    } = this.props;

    const jobTimeDifference = this.getCheckinCheckOutDifference(
      job.checkin_time,
      job.checkout_time,
      'HH:mm:ss A'
    );

    let overtime = 0;
    if (job && jobTimeDifference > job['job_duration_in_minutes']) {
      overtime = jobTimeDifference - job['job_duration_in_minutes'];
    }

    const time_slots = [];
    if (toAssing !== undefined) {
      const { start_time, end_time } = workers[toAssing];
      const AM_PM = start_hour => (start_hour >= 12 ? 'PM' : 'AM');
      const blockLength = Math.abs(
        Number(end_time.split(':')[0]) - Number(start_time.split(':')[0])
      );
      for (
        let start_hour = Number(start_time.split(':')[0]), i = 0;
        i <= blockLength;
        i++
      ) {
        const currentZone = AM_PM(start_hour + i);
        time_slots.push({
          label: `${start_hour + i}:00 ${currentZone}`,
        });
      }
    }

    return (
      <>
        <DeleteModal
          onClose={this.closeJobModal}
          onAssign={this.onAssign}
          visible={modalState === true}
          job={job}
          worker={workers[toAssing]}
          time={startTime}
        />
        <div
          className={`job-card ${
            dragging ? 'dragging' : isOpened ? 'opened' : ''
          } ${this.getJobContainerClass(job)} ${
            timerStatus || job.checkout_time
              ? `job-card--with-timer-${identifier}`
              : ''
          } ${
            permanentReassign
              ? 'hightlighter-1'
              : permanentReassignSelectedDay
              ? 'hightlighter-2'
              : ''
          }`}
          style={{
            height: dragging ? undefined : height,
          }}
          // id='popScroll'
        >
          <div
            className="job-card-content"
            ref={ref => (this.contentRef = ref)}
          >
            <div className="job-summary" onClick={this.onClickCard}>
              <div className="job-summary-row">
                <span className="job-title">
                  {job.client ? job.client.title : ''}
                </span>
                <span className="job-id">Job ID: {job.id}</span>
              </div>
              <div className="job-summary-row">
                <span className="job-desc">{job.title}</span>
                <span className="job-type">
                  {job['repeating_job_id'] ? 'Regular' : 'Ad-Hoc'}
                </span>
              </div>
              <div className="job-summary-row">
                <span className="job-company">{job.description}</span>
                <span className="job-duration">
                  {job.job_duration_in_minutes} Minutes
                </span>
              </div>
              <div className="job-main-info">
                <div className="job-title">
                  {job.client ? job.client.title : ''}
                </div>
                <div className="job-desc">{job.title}</div>
              </div>
              <div className="job-duration">
                <div className="job-id">Job ID: {job.id}</div>
                {job.job_duration_in_minutes} Minutes
              </div>
            </div>
            {/* <div className="job-time-difference" onClick={this.onClickCard}>
              <p>Duration</p>
              <p className="job-time-difference-time">{`${jobTimeDifference} Minutes`}</p>
            </div> */}

            {job && job.checkin_time ? (
              <div className="job-counter" onClick={this.onClickCard}>
                {overtime || job.checkout_time ? (
                  <>
                    {job.checkout_time && (
                      <p
                        className={`job-counter-title job-counter-title--completed`}
                      >
                        Completed
                      </p>
                    )}
                    {overtime ? (
                      <p
                        className={`job-counter-title job-counter-title--overtime`}
                      >
                        Overtime
                      </p>
                    ) : null}
                  </>
                ) : (
                  <p
                    className={`job-counter-title ${this.getJobCounterClass(
                      overtimed,
                      job.checkout_time
                    )}`}
                  >
                    In time
                  </p>
                )}
                <p className="job-counter-text">
                  <JobTimer
                    formatValue={value => `${value < 10 ? `0${value}` : value}`}
                    startImmediately={false}
                    initialTime={this.getTimerInitialTime().diff}
                    checkpoints={[
                      {
                        time: job.job_duration_in_minutes * 60 * 1000,
                        callback: () => this.setState({ overtimed: true }),
                      },
                    ]}
                  >
                    {({ start, stop }) => {
                      this.timerControl(start, stop);
                      return (
                        <>
                          <JobTimer.Hours />:
                          <JobTimer.Minutes />:
                          <JobTimer.Seconds />
                        </>
                      );
                    }}
                  </JobTimer>
                </p>
              </div>
            ) : null}

            <div className="job-time" onClick={this.onClickCard}>
              <div className="preferred-time">
                <span>Preferred Time</span>
                <span>{job.preferred_time}</span>
              </div>
              <div className="job-time-row">
                <label>Check-in Time</label>
                <span>{job.checkin_time ? job.checkin_time : '-'}</span>
              </div>
              <div className="job-time-row">
                <label>Check-out Time</label>
                <span>{job.checkout_time ? job.checkout_time : '-'}</span>
              </div>
              <div className="job-time-row">
                <label>Duration</label>
                <span>{`${jobTimeDifference} min`}</span>
              </div>
              <div className="job-time-row">
                <label>Overtime</label>
                <span>{`${overtime < 0 ? 0 : overtime} min`}</span>
              </div>
            </div>

            {job.address && (
              <div className="job-location" onClick={this.onClickCard}>
                <div className="job-location-row">
                  <label>Address</label>
                  <span>{job.address.address}</span>
                </div>
                <div className="job-location-unit-postal">
                  <div className="job-location-row">
                    <label>Unit Number</label>
                    <span>{job.address.unit_number}</span>
                  </div>
                  <div className="job-location-row job-location-postal-code">
                    <label>Postal Code</label>
                    <span>{job.address.postal_code}</span>
                  </div>
                </div>
              </div>
            )}

            {
              <div className="job-notes">
                <div className="job-notes-row">
                  <div className="job-notes-viewmore">
                    <label>General Notes</label>
                    <Button type="link" onClick={this.onClickViewMore}>
                      View More
                    </Button>
                  </div>
                  <span>{job['job_notes']}</span>
                </div>
                {job['repeating_job_id'] && (
                  <>
                    <Checkbox
                      checked={permanentReassign}
                      onChange={this.permanentReassignHandler}
                      disabled={
                        permanentReassignSelectedDay ||
                        this.isJobStatusCanceled(job?.job_status) ||
                        this.isJobStatusSkipped(job?.job_status) ||
                        job.checkout_time
                      }
                      ref={this.permAssignRef}
                    >
                      Permanent Reassign
                    </Checkbox>
                    <Checkbox
                      checked={permanentReassignSelectedDay}
                      onChange={this.permanentReassignSelectedDayHandler}
                      disabled={
                        permanentReassign ||
                        this.isJobStatusCanceled(job?.job_status) ||
                        this.isJobStatusSkipped(job?.job_status) ||
                        job.checkout_time
                      }
                      ref={this.permAssignSelectedDayRef}
                      style={{ marginLeft: 0 }}
                    >
                      Permanent Reassign Selected Day
                    </Checkbox>
                  </>
                )}
              </div>
            }

            {true && (
              <div
                className="worker-location"
                style={{
                  visibility:
                    permanentReassignSelectedDay || permanentReassign
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <div className="worker-location-row">
                  <Select
                    style={{ width: 130 }}
                    onChange={this.handleWorkerChange}
                    // getPopupContainer={() =>
                    //   areaRef === 'areaRef'
                    //     ? document.getElementById('areaRef')
                    //     : document.getElementById('vendorRef')
                    // }
                    placeholder={'Select worker'}
                    disabled={job.checkout_time}
                  >
                    {workers.map(worker => (
                      <Option key={worker.id} value={worker.id}>
                        {worker.first_name}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    style={{ width: 68 }}
                    onChange={this.handleTimeChange}
                    disabled={toAssing !== undefined ? false : true}
                    showArrow={false}
                    placeholder="Time"
                  >
                    {toAssing !== undefined &&
                      time_slots.map((el, i) => (
                        <Option key={i} value={el.label}>
                          {el.label}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            )}

            {!job.checkout_time &&
              !this.isJobStatusCanceled(job?.job_status) &&
              !this.isJobStatusSkipped(job?.job_status) &&
              allowUpdate && (
                <Button type="primary" block onClick={this.onClickCheck}>
                  {job.checkin_time ? 'CHECK OUT' : 'CHECK IN'}
                </Button>
              )}
            {!job.checkin_time &&
              !this.isJobStatusCanceled(job?.job_status) &&
              !this.isJobStatusSkipped(job?.job_status) && (
                <Button type="default" block onClick={this.onClickCancel}>
                  {`CANCEL/SKIP`}
                </Button>
              )}
          </div>
        </div>
        <Modal
          title="Notes"
          visible={modalOpened === 1}
          onCancel={this.closeModal}
          footer={[
            <Button key={0} type="primary" onClick={this.closeModal}>
              Close
            </Button>,
          ]}
          destroyOnClose
        >
          <p>{job['job_notes']}</p>
        </Modal>
        <Modal
          title="Confirmation"
          className="job-cancel-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          footer={[
            <Button
              key={0}
              type="danger"
              onClick={e => {
                this.onCancelOptionChange('cancel');
                this.openConfirmationPopup(e);
                // this.onCancel('cancel');
              }}
            >
              Cancel Service
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={e => {
                this.onCancelOptionChange('skip');
                this.openConfirmationPopup(e);
              }}
            >
              Skip Service
            </Button>,
          ]}
          destroyOnClose
        >
          <p>Are you sure want to cancel/skip the service?</p>
          <p>This action can not be undone</p>
          <Checkbox
            className="checkbox"
            checked={this.state.cancelSkipCheckbox}
            onChange={() => {
              this.setState({
                cancelSkipCheckbox: !this.state.cancelSkipCheckbox,
              });
            }}
            style={{ fontWeight: 'bold' }}
          >
            Would you like to remove assigned worker and assigned time as well.
          </Checkbox>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 3}
          onCancel={() => this.closeModal()}
          onOk={() => this.onCancel()}
          okText="Yes"
          destroyOnClose
        >
          <>
            <p className="main-message">
              {`Are you sure to ${this.state.cancelOpt} this service`}
            </p>
            <p>This action can not be undone</p>
          </>
        </Modal>
      </>
    );
  }
}

JobCard.propTypes = {
  job: PropTypes.object,
  identifier: PropTypes.string,
  vendorId: PropTypes.number,
  workerId: PropTypes.number,
  checkInJob: PropTypes.func,
  checkoutJob: PropTypes.func,
  cancelJob: PropTypes.func,
  setSchedulerState: PropTypes.func,
  dragging: PropTypes.bool,
  currentDate: PropTypes.object,
  allowUpdate: PropTypes.bool,
  permanentReassign: PropTypes.bool,
  permanentReassignSelectedDay: PropTypes.bool,
  handleOnPermReassignSelectedDayChange: PropTypes.func,
  handleOnPermanentReassignChange: PropTypes.func,
  assignJobToWorker: PropTypes.func,
  workers: PropTypes.array,
  assignWorkerPerm: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    checkInJob: id =>
      dispatch(
        updateJob({
          id,
          action: JobUpdateActionCheckIn,
        })
      ),
    checkoutJob: id =>
      dispatch(
        updateJob({
          id,
          action: JobUpdateActionCheckOut,
        })
      ),
    cancelJob: payload =>
      dispatch(
        updateJob({
          id: payload.id,
          action: JobUpdateActionCancel,
          remove_assigned_worker_and_time:
            payload.remove_assigned_worker_and_time,
        })
      ),
    skipJob: id =>
      dispatch(
        updateJob({
          id,
          // action: JobUpdateActionSkip, ( currrently act as cancel )
          action: JobUpdateActionSkip,
        })
      ),

    assignWorkerPerm: (id, data) => dispatch(assignWorkerPerm({ id, data })),
    handleOnPermanentReassignChange: payload =>
      dispatch(handleOnPermanentReassignChange(payload)),
    handleOnPermReassignSelectedDayChange: payload =>
      dispatch(handleOnPermReassignSelectedDayChange(payload)),
  };
}

export default connect(null, mapDispatchToProps)(JobCard);
