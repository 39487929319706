import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getCoupons,
  createCoupon,
  updateCoupon,
  deleteCoupon,
} from './reducers';

import CouponAllTab from './components/tabs/coupon-all';

import TableSearch from '../../components/TableSearch';

const CouponPage = ({
  loading,
  coupons,
  getCoupons,
  createCoupon,
  deleteCoupon,
  updateCoupon,
}) => {
  const [queryString, updateQueryString] = useState('');
  const [formData, setFormData] = useState(null);
  const [modalOpened, setModalOpen] = useState(null);
  const { limit, total, data, page } = coupons;

  useEffect(() => {
    getCoupons({
      page: 1,
      per: 20,
      query: null,
    });
  }, [getCoupons]);

  const onSearch = () => {
    getCoupons({ query: queryString, per: limit });
  };
  const onEditClick = record => {
    setModalOpen(1);
    setFormData(record);
  };
  const onDeleteClick = record => {
    setModalOpen(2);
    setFormData(record);
  };
  const onCloseModal = () => {
    setModalOpen(null);
    setFormData(null);
  };
  const onSubmit = values => {
    onCloseModal();
    if (formData?.id) {
      updateCoupon({ ...values });
    } else {
      createCoupon({ ...values });
    }
  };
  const performDelete = () => {
    onCloseModal();
    formData?.id
      ? deleteCoupon(formData.id)
      : message.error('Coupon code not found!');
  };
  const onRowSelect = record => {
    setModalOpen(3);
    setFormData(record);
  };

  return (
    <div className="jobs-page">
      <div className="page-header">
        <Row>
          <Col>
            <div className="page-title">
              <h4>Coupons</h4>
            </div>
            <div className="page-header-actions">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setModalOpen(1);
                }}
                style={{ marginBottom: '20px' }}
              >
                Add New Coupen
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="page-content">
        <TableSearch
          placeholder="Search Coupon"
          btnText="Search"
          onBtnClick={onSearch}
          value={queryString}
          onInputChange={ev => updateQueryString(ev['target']['value'])}
        />

        <CouponAllTab
          loading={loading}
          data={data}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          limit={limit}
          total={total}
          page={page}
          modalOpened={modalOpened}
          onCloseModal={onCloseModal}
          formData={formData}
          onSubmit={onSubmit}
          performDelete={performDelete}
          onRowSelect={onRowSelect}
        />
      </div>
    </div>
  );
};

CouponPage.propTypes = {
  loading: PropTypes.bool,
  coupons: PropTypes.object,
  getCoupons: PropTypes.func,
  createCoupon: PropTypes.func,
  deleteCoupon: PropTypes.func,
  updateCoupon: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.coupons.loading,
    coupons: state.coupons.coupons,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCoupons: keyword => dispatch(getCoupons(keyword)),
    createCoupon: data => dispatch(createCoupon(data)),
    updateCoupon: data => dispatch(updateCoupon(data)),
    deleteCoupon: id => dispatch(deleteCoupon(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponPage);
