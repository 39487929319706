import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Row, Col } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableSearch from '../../../components/TableSearch';
import JobForm from './components/form';
import SingleJobs from './components/single';
import RepeatingJobs from './components/repeating';
import HistoryJobs from './components/history';
import {
  setTab,
  createJob,
  getJobs,
  setModalJob,
  getJobById,
} from './reducers';
import AllJobs from './components/all';
import JobDetailModal from './components/jobDetailModal';
import { withRouter } from 'react-router-dom';

import './styles.css';

class JobPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      modalOpened: false,
      query: '',
      isJobDetailsModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { submitting, error } = this.props;

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  onSearch = () => {
    const { activeTab, getJobs } = this.props;
    const { query } = this.state;
    const limit = 20;

    if (activeTab === 'single') {
      getJobs({ query, type: 'single', per: limit, page: 1 });
    } else if (activeTab === 'repeating') {
      getJobs({ query, type: 'repeating', per: limit, page: 1 });
    } else if (activeTab === 'history') {
      getJobs({ query, type: 'history', per: limit, page: 1 });
    } else {
      getJobs({ query, per: limit, page: 1 });
    }
  };

  openModal = () => {
    const { activeTab } = this.props;

    if (activeTab === 'single') {
      this.setState({
        modalOpened: true,
        formData: {
          title: '',
          description: '',
          job_duration_in_minutes: '',
          client_type: 'personal',
          vendor_id: undefined,
          client_id: undefined,
          address_id: undefined,
          service_date: moment().format('YYYY-MM-DD'),
          preferred_time: moment().format('hh:mm A'),
          job_notes: '',
        },
      });
    } else if (activeTab === 'repeating') {
      this.setState({
        modalOpened: true,
        formData: {
          frequency_value: '',
          frequency_type: 'weekly',
          days: [],
          term_type: 'week',
          term_value: '',
          start_date: moment().format('YYYY-MM-DD'),
          title: '',
          description: '',
          job_duration_in_minutes: '',
          client_type: 'personal',
          vendor_id: undefined,
          client_id: undefined,
          address_id: undefined,
          preferred_time: moment().format('hh:mm A'),
          job_notes: '',
        },
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
      formData: null,
    });
  };

  jobDetailsModalOpen = () => {
    this.setState(state => ({
      ...state,
      isJobDetailsModalOpen: true,
    }));
  };

  jobDetailsModalClose = () => {
    this.setState(state => ({
      ...state,
      isJobDetailsModalOpen: false,
    }));
  };

  onSubmit = data => {
    const { activeTab, createJob } = this.props;
    let body;
    if (activeTab === 'repeating') {
      body = {
        frequency_value: data.frequency_value,
        frequency_type: data.frequency_type,
        days: data.days,
        days_quantity_limit: data.days_quantity_limit,
        perpetual: data['perpetual'],
        term_type: data['perpetual'] ? undefined : data.term_type,
        term_value: data['perpetual'] ? undefined : data.term_value,
        start_date: data.start_date,
        client_id: data.client_id,
        client_type:
          data.client_type === 'personal' ? 'PersonalClient' : 'BusinessClient',
        jobs_attributes: {
          title: data.title,
          description: data.description,
          job_duration_in_minutes: data.job_duration_in_minutes,
          vendor_id: data.vendor_id,
          client_type:
            data.client_type === 'personal'
              ? 'PersonalClient'
              : 'BusinessClient',
          client_id: data.client_id,
          address_id: data.address_id,
          preferred_time: data.preferred_time,
          job_notes: data.job_notes,
        },
      };
    } else {
      body = {
        job: {
          title: data.title,
          description: data.description,
          job_duration_in_minutes: data.job_duration_in_minutes,
          vendor_id: data.vendor_id,
          client_type:
            data.client_type === 'personal'
              ? 'PersonalClient'
              : 'BusinessClient',
          client_id: data.client_id,
          address_id: data.address_id,
          service_date: data.service_date,
          preferred_time: data.preferred_time,
          job_notes: data.job_notes,
        },
      };
    }
    createJob(activeTab, body);
    // this.closeModal();
  };

  render() {
    const { submitting, activeTab, setTab } = this.props;
    const { modalOpened, formData, query } = this.state;
    return (
      <div className="jobs-page">
        <div className="page-header">
          <Row>
            <Col>
              <div className="page-title">
                <h4>Jobs</h4>
              </div>
              <div className="page-header-actions">
                {activeTab !== 'history' && activeTab !== 'all' && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={this.openModal}
                  >
                    Add New Job
                  </Button>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'all' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('all')}
                >
                  ALL
                </div>
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'single' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('single')}
                >
                  One Time
                </div>
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'repeating' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('repeating')}
                >
                  Subscription
                </div>
                <div
                  className={`ant-tabs-tab${
                    activeTab === 'history' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setTab('history')}
                >
                  History
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="page-content">
          <TableSearch
            placeholder="Search Job"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={query}
            onInputChange={ev =>
              this.setState({ query: ev['target']['value'] })
            }
          />
          {activeTab === 'all' ? (
            <AllJobs
              queryString={query}
              jobDetailsModalOpen={this.jobDetailsModalOpen}
            />
          ) : activeTab === 'single' ? (
            <SingleJobs
              queryString={query}
              jobDetailsModalOpen={this.jobDetailsModalOpen}
            />
          ) : activeTab === 'repeating' ? (
            <RepeatingJobs queryString={query} />
          ) : (
            <HistoryJobs
              queryString={query}
              jobDetailsModalOpen={this.jobDetailsModalOpen}
            />
          )}
        </div>
        {this.state.isJobDetailsModalOpen && (
          <JobDetailModal jobDetailsModalClose={this.jobDetailsModalClose} />
        )}
        <Drawer
          closable={false}
          placement="right"
          width={450}
          visible={modalOpened}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>
                  Add New Job -{' '}
                  {activeTab === 'single' ? 'One Time' : 'Subscription'}
                </h3>
                <div className="header-actions">
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <JobForm
                  data={formData}
                  type={activeTab}
                  onSubmit={this.onSubmit}
                  submitting={submitting}
                />
              </div>
            </>
          )}
        </Drawer>
      </div>
    );
  }
}

JobPage.propTypes = {
  modalLoading: PropTypes.bool,
  modalJob: PropTypes.object,
  setModalJob: PropTypes.func,
  getJobById: PropTypes.func,
  setTab: PropTypes.func,
  getJobs: PropTypes.func,
  createJob: PropTypes.func,
  activeTab: PropTypes.string,
  jobs: PropTypes.object,
  history: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    activeTab: state.vendorJobs.activeTab,
    jobs: state.vendorJobs.vendorJobs,
    modalLoading: state.vendorJobs.modalLoading,
    modalJob: state.vendorJobs.modalJob,
    submitting: state.vendorJobs.submitting,
    error: state.vendorJobs.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModalJob: job => dispatch(setModalJob(job)),
    getJobById: jobId => dispatch(getJobById(jobId)),
    setTab: tab => dispatch(setTab(tab)),
    getJobs: payload => dispatch(getJobs(payload)),
    createJob: (type, data) => dispatch(createJob({ type, data })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobPage));
