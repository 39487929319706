import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Select,
  Modal,
  Drawer,
  DatePicker,
  Checkbox,
  message,
  Menu,
  Avatar,
  Form,
  Input,
  Switch,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import JobTimer from 'react-compound-timer';
import {
  DeleteFilledIcon,
  EditFilledIcon,
} from '../../../components/Icons/Icons';
import {
  CloseOutlined,
  InboxOutlined,
  EllipsisOutlined,
  PauseOutlined,
} from '@ant-design/icons';
import JobForm from './form';
import { Formik } from 'formik';
import { formValidations } from '../../../utils/formValidations';

import {
  // assignJobToWorker,
  // reassignJobToWorker,
  assignWorkerPerm,
  updateJob,
  handleOnPermanentReassignChange,
  handleOnPermReassignSelectedDayChange,
  updateJobData,
  deleteJob,
  cancelRepeatingJob,
  cancelSubscription,
  skipSubscription,
  activeJob,
  pauseJobs,
  enableCheckInOut,
} from '../reducers';
import {
  JobUpdateActionSkip,
  JobUpdateActionCancel,
  JobUpdateActionCheckIn,
  JobUpdateActionCheckOut,
} from '../sagas';
import DeleteModal from './delete-modal';

const { Option } = Select;

class JobCard extends React.Component {
  constructor(props) {
    super(props);
    this.permAssignRef = React.createRef();
    this.permAssignSelectedDayRef = React.createRef();

    this.state = {
      height: undefined,
      isOpened: false,
      modalOpened: false,
      cancelOpt: 'cancel',
      overtimed: false,
      timerStatus: false,
      modalState: false,
      assignedworkerId: undefined,
      toAssing: undefined,
      startTime: '',
      cancelSkipCheckbox: true,
      formData: null,
      selectedRow: null,
      canceled_at: null,
      sub: false,
      cancelSkipData: {
        status: null,
        start_date: null,
        end_date: null,
        update: false,
      },
      pauseData: {
        start_date: null,
        end_date: null,
        update: false,
      },
      assignData: null,
      deleteValidation: true,
      dropdownShow: false,
      singleJobToggle: null,
      singleJobActive: null,
    };
  }
  onClickCard = () => {
    const { isOpened } = this.state;

    if (!isOpened) {
      this.setState(
        {
          height: 'auto',
        },
        () => {
          this.setState({
            isOpened: true,
            dropdownShow: false,
          });
        }
      );
    } else {
      this.setState(
        {
          height: undefined,
        },
        () => {
          this.setState({
            isOpened: false,
            dropdownShow: false,
          });
        }
      );
    }
  };

  onClickViewMore = e => {
    e.stopPropagation();

    this.setState({
      modalOpened: 1,
      dropdownShow: false,
    });
  };

  onClickCheck = e => {
    e.stopPropagation();

    const { job, checkInJob, checkoutJob } = this.props;

    if (!job.checkin_time) {
      return checkInJob(job.id);
    }
    if (!job.checkout_time) {
      return checkoutJob(job.id);
    }
    this.setState({ dropdownShow: false });
  };

  handleActiveJob = () => {
    this.setState({
      modalOpened: 13,
      dropdownShow: false,
    });
  };

  enableActiveJob = repeatJob => {
    if (repeatJob) {
      this.setState({
        modalOpened: 14,
        dropdownShow: false,
        singleJobActive: false,
      });
    } else {
      this.setState({
        modalOpened: 14,
        dropdownShow: false,
        singleJobActive: true,
      });
    }
  };

  handleJobStatus = () => {
    const { singleJobActive } = this.state;
    const { job, activeJob } = this.props;

    if (singleJobActive) {
      activeJob({
        data: { mark_active: 'single_job' },
        id: job?.id,
      });
    } else {
      activeJob({
        data: { mark_active: 'subscription' },
        id: job?.id,
      });
    }

    this.setState({
      modalOpened: false,
      dropdownShow: false,
      singleJobActive: null,
    });
  };

  onClickCancel = e => {
    e.stopPropagation();

    this.setState({
      modalOpened: 2,
      dropdownShow: false,
    });
  };

  onSubmit = data => {
    const { type } = data;
    const { updateJobData } = this.props;
    let body;
    if (data.type === 'repeating') {
      body = {
        title: data.title,
        description: data.description,
        job_notes: data.notes,
        job_duration_in_minutes: data.job_duration_in_minutes,
        frequency_value: data.frequency_value,
        client_type: 'PersonalClient',
        start_date: data.start_date,
        status: data?.job_status,
      };
      if (data?.vendor_id) {
        body['vendor_id'] = data.vendor_id;
      }
      if (data?.preferred_time) {
        body['preferred_time'] = data.preferred_time;
      }
      if (data?.assigned_time) {
        body['assigned_time'] = data.assigned_time;
      }
      if (data?.assigned_worker_id) {
        body['assigned_worker_id'] = data.assigned_worker_id;
      }
      if (data?.day) {
        body['day'] = data.day;
      }
    } else {
      body = {
        job: {
          title: data?.title,
          description: data?.description,
          job_duration_in_minutes: data?.job_duration_in_minutes,
          vendor_id: data?.vendor_id,
          assigned_worker_id: data?.assigned_worker_id,
          client_type: 'PersonalClient',
          client_id: data?.client_id,
          address_id: data?.address_id,
          service_date: data?.service_date,
          preferred_time: data?.preferred_time,
          assigned_time: data?.assigned_time,
          job_notes: data?.job_notes,
          fixed_service_time: data?.fixed_service_time,
        },
      };
    }

    updateJobData(data.id, body, type);

    this.closeModal();
  };

  onPauseJob = () => {
    this.setState({
      modalOpened: 9,
      selectedRow: null,
      formData: null,
      pauseData: {
        start_date: null,
        end_date: null,
        update: false,
      },
      dropdownShow: false,
    });
  };

  onCancelSkip = () => {
    this.setState({
      modalOpened: 7,
      selectedRow: null,
      formData: null,
      cancelSkipData: {
        status: null,
        start_date: null,
        end_date: null,
        update: false,
      },
      assignData: null,
      dropdownShow: false,
    });
  };

  pauseConfirm = () => {
    this.setState({
      modalOpened: 10,
      selectedRow: null,
      formData: null,
      assignData: null,
      dropdownShow: false,
    });
  };

  onConfirm = () => {
    this.setState({
      modalOpened: 8,
      selectedRow: null,
      formData: null,
      assignData: null,
      dropdownShow: false,
    });
  };

  onClickEdit = (record, sub) => {
    let formData;
    if (record.job_type === 'repeating' && sub) {
      formData = {
        id: record.repeating_job_id,
        vendor_id: record.vendor_id,
        start_date: record['repeating_job']?.start_date,
        end_date: record['repeating_job']?.end_date,
        title: record.title,
        notes: record.job_notes,
        description: record.description,
        job_duration_in_minutes: record.job_duration_in_minutes,
        frequency_value: record['repeating_job']?.frequency_value,
        frequency_type: record['repeating_job']?.frequency_type,
        preferred_time: record.preferred_time,
        assigned_time: record.assigned_time,
        assigned_worker_id: record['assigned_worker']
          ? record['assigned_worker'].id
          : '',
        days: record['repeating_job']?.days,
        client_type: record.client_type,
        type: record.job_type,
        sub: sub,
        job_status: sub ? record.repeating_job?.status : record.status,
      };
    } else {
      formData = {
        id: record.id,
        title: record.title,
        description: record.description,
        job_duration_in_minutes: record.job_duration_in_minutes,
        client_type: 'personal',
        vendor_id: record.vendor_id,
        assigned_worker_id: record['assigned_worker']
          ? record['assigned_worker'].id
          : '',
        assigned_time: record.assigned_time,
        client_id: record.client.id,
        client_title: record.client.title,
        address_title: record.address?.address_title,
        address_id: record.address.id,
        service_date: record.service_date,
        preferred_time: record.preferred_time,
        job_notes: record.job_notes,
        customer_notes: record.customer_notes,
        type: record.job_type,
        fixed_service_time: record.fixed_service_time,
      };
    }

    this.setState({
      modalOpened: 4,
      formData,
      dropdownShow: false,
    });
  };

  onClickDelete = record => {
    this.setState({
      modalOpened: 5,
      selectedRow: record,
    });
  };

  onClickCancelJob = record => {
    this.setState({
      modalOpened: 6,
      selectedRow: record,
      dropdownShow: false,
    });
  };

  disableFutureDates = current => {
    return current && current < moment().endOf('day');
  };

  handleDateChange = async date => {
    this.setState(prevState => ({
      ...prevState,
      canceled_at: moment(date).format('YYYY-MM-DD'),
    }));
  };

  handleCancel = () => {
    const { selectedRow, canceled_at } = this.state;
    const { cancelRepeatingJob } = this.props;

    if (canceled_at !== null) {
      cancelRepeatingJob({
        id: selectedRow.repeating_job_id,
        canceled_at,
        jobId: selectedRow.id,
      });
      this.closeModal();
    } else {
      message.error('Please select a date', 0);
    }
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteJob } = this.props;

    deleteJob({ id: selectedRow.id, type: selectedRow.job_type });
    this.closeModal();
  };

  handleCheckInOut = checked => {
    console.log(checked);
    this.setState({
      modalOpened: 11,
      dropdownShow: false,
    });
  };

  enableCheckInOut = repeatJob => {
    if (repeatJob) {
      this.setState({
        modalOpened: 12,
        dropdownShow: false,
        singleJobToggle: false,
      });
    } else {
      this.setState({
        modalOpened: 12,
        dropdownShow: false,
        singleJobToggle: true,
      });
    }
  };

  confirmEnableCheckInOut = id => {
    const { singleJobToggle } = this.state;
    const { enableCheckInOut } = this.props;

    if (singleJobToggle) {
      enableCheckInOut({
        type: 'single',
        data: { toggle_for: 'single_job' },
        id: id,
      });
      this.setState({
        modalOpened: false,
        dropdownShow: false,
        singleJobToggle: null,
      });
    } else {
      enableCheckInOut({
        type: 'repeating',
        data: { toggle_for: 'subscription' },
        id: id,
      });
      this.setState({
        modalOpened: false,
        dropdownShow: false,
        singleJobToggle: null,
      });
    }
  };

  openConfirmationPopup = e => {
    e.stopPropagation();

    this.setState({
      modalOpened: 3,
      dropdownShow: false,
    });
  };

  onCancelOptionChange = value => {
    this.setState(state => ({ ...state, cancelOpt: value }));
  };

  onCancel = () => {
    const { job, cancelJob, skipJob } = this.props;
    if (this.state.cancelOpt === 'cancel') {
      cancelJob({
        id: job.id,
        remove_assigned_worker_and_time: this.state.cancelSkipCheckbox,
      });
    } else if (this.state.cancelOpt === 'skip') {
      skipJob(job.id);
    }
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      modalOpened: null,
      selectedRow: null,
      formData: null,
      assignData: null,
      dropdownShow: false,
    });
  };

  isJobStatusCanceled = job_status => job_status.toLowerCase() === 'cancelled';
  isJobStatusSkipped = job_status => job_status.toLowerCase() === 'skipped';

  getJobContainerClass = job => {
    const { checkin_time, checkout_time, job_status } = job;

    let classes = '';

    if (checkin_time) {
      classes += ' job-checked-in';
    } else {
      classes += ' job-not-checked-in';
    }

    if (checkout_time) {
      classes += ' job-checked-out';
    }

    if (
      this.isJobStatusCanceled(job_status) ||
      this.isJobStatusSkipped(job_status)
    ) {
      classes += ' job-status-cancelled';
    }

    return classes;
  };

  getCheckinCheckOutDifference = (cIn, cOut) => {
    if (cIn && cOut) {
      let startTime = moment(cIn, 'hh:mm:ss a');
      let endTime = moment(cOut, 'hh:mm:ss a');
      return endTime.diff(startTime, 'minutes');
    } else {
      return 0;
    }
  };

  getTimerInitialTime = () => {
    const { job } = this.props;
    if (job && job.checkin_time && !job.checkout_time) {
      const jobCheckInTime = moment(job.checkin_time, 'hh:mm:ss a');
      const currentTime = moment(moment(), 'hh:mm:ss a');
      const diff = currentTime.diff(jobCheckInTime, 'milliseconds');
      const diff_min = currentTime.diff(jobCheckInTime, 'minutes');
      return { diff, diff_min };
    } else if (job && job.checkin_time && job.checkout_time) {
      const jobCheckInTime = moment(job.checkin_time, 'hh:mm:ss a');
      const jobCheckoutTime = moment(job.checkout_time, 'hh:mm:ss a');
      const diff = jobCheckoutTime.diff(jobCheckInTime, 'milliseconds');
      const diff_min = jobCheckoutTime.diff(jobCheckInTime, 'minutes');
      return { diff, diff_min };
    } else {
      return { diff: 0, diff_min: 0 };
    }
  };

  componentDidUpdate(prevProps) {
    const { job } = this.props;
    if (
      job &&
      prevProps['job'] !== job &&
      this.getTimerInitialTime().diff_min > job['job_duration_in_minutes']
    ) {
      this.setState({ overtimed: true });
    }
  }

  componentDidMount() {
    const { job } = this.props;
    if (
      job &&
      this.getTimerInitialTime().diff_min > job['job_duration_in_minutes']
    ) {
      this.setState({ overtimed: true });
    }
  }

  timerControl = (start, stop) => {
    const { job } = this.props;
    if (job.checkin_time && !job.checkout_time) {
      start();
      if (!this.state.timerStatus) {
        this.setState({ timerStatus: true });
      }
    }
    if (job.checkout_time) {
      stop();
      if (this.state.timerStatus) {
        this.setState({ timerStatus: false });
      }
    }
  };

  getJobCounterClass = (overtimed, checkout_time) => {
    if (overtimed && !checkout_time) {
      return 'job-counter-title--overtime';
    } else if (overtimed && checkout_time) {
      return 'job-counter-title--completed';
    } else {
      return '';
    }
  };

  handleWorkerChange = e => {
    const { workers } = this.props;
    const toAssing = workers.findIndex(worker => worker.id === e);
    this.setState({
      assignedworkerId: e,
      toAssing: toAssing,
      dropdownShow: false,
    });
  };
  handleTimeChange = e => {
    this.setState({
      modalState: true,
      startTime: e,
      dropdownShow: false,
    });
  };
  onAssign = () => {
    const { job, workers, assignWorkerPerm, permanentReassignSelectedDay } =
      this.props;
    const { assignedworkerId, startTime } = this.state;
    const workerIndex = workers.findIndex(
      worker => worker.id === assignedworkerId
    );

    let assignData = {
      service_date: job.service_date,
      assigned_worker_id: workers[workerIndex].id,
      assigned_time: startTime,
      previous_worker_id: job.assigned_worker ? job.assigned_worker?.id : null,
      // job_status: 'scheduled',
    };
    this.setState({ modalOpened: null });
    assignWorkerPerm(+job.id, assignData, permanentReassignSelectedDay);
  };

  permanentReassignHandler = () => {
    const ev = {
      target: {
        checked: !this.permAssignRef.current.props.checked,
      },
    };
    const { handleOnPermanentReassignChange, job, identifier, workerId } =
      this.props;
    handleOnPermanentReassignChange({
      ev,
      job,
      identifier,
      vendorId: job.vendor_id,
      workerId,
    });
  };
  permanentReassignSelectedDayHandler = () => {
    const ev = {
      target: {
        checked: !this.permAssignSelectedDayRef.current.props.checked,
      },
    };
    const {
      handleOnPermReassignSelectedDayChange,
      job,
      identifier,
      workerId,
      currentDate,
    } = this.props;
    handleOnPermReassignSelectedDayChange({
      ev,
      job,
      identifier,
      vendorId: job.vendor_id,
      workerId,
      currentDate,
    });
  };

  handlePauseJobs = () => {
    const { pauseJobs } = this.props;

    pauseJobs({
      id: this.props.job.repeating_job_id,
      start_date: this.state.pauseData.start_date,
      end_date: this.state.pauseData.end_date,
    });
  };

  handleCancelJobs = () => {
    const { cancelSubscription } = this.props;

    cancelSubscription({
      id: this.props.job.repeating_job_id,
      start_date: this.state.cancelSkipData.start_date,
      end_date: this.state.cancelSkipData.end_date,
    });
  };

  handleSkipJobs = () => {
    const { skipSubscription } = this.props;

    skipSubscription({
      id: this.props.job.repeating_job_id,
      start_date: this.state.cancelSkipData.start_date,
      end_date: this.state.cancelSkipData.end_date,
    });
  };

  renderDropdownMenu = (job, sub) => {
    return (
      <Menu className="dropdown-menu">
        <Menu.Item key={'edit'} onClick={() => this.onClickEdit(job, sub)}>
          <EditFilledIcon /> Edit Job
        </Menu.Item>
        <Menu.Item key={'delete'} onClick={() => this.onClickDelete(job)}>
          <DeleteFilledIcon /> Delete Job
        </Menu.Item>
        {job['job_type'] === 'repeating' ? (
          <>
            <Menu.Item
              key={'cancel_sub'}
              onClick={() => this.onCancelSkip(job)}
            >
              <CloseOutlined /> Cancel / Skip Range
            </Menu.Item>
            {job.assigned_time !== null ? (
              <Menu.Item key={'pause'} onClick={() => this.onPauseJob(job)}>
                <PauseOutlined /> Pause Subscription
              </Menu.Item>
            ) : (
              ''
            )}
            <Menu.Item
              key={'edit_sub'}
              onClick={() => this.onClickEdit(job, !sub)}
            >
              <EditFilledIcon /> Edit Subscription
            </Menu.Item>
            <Menu.Item
              key={'cancel'}
              onClick={() => this.onClickCancelJob(job)}
            >
              <CloseOutlined /> Cancel Subscription
            </Menu.Item>
          </>
        ) : (
          ''
        )}
      </Menu>
    );
  };

  closeJobModal = () => {
    this.setState({ modalState: false, dropdownShow: false });
    const _1stRef = this.permAssignRef.current.props.checked;
    _1stRef
      ? this.permanentReassignHandler()
      : this.permanentReassignSelectedDayHandler();
  };

  render() {
    const {
      isOpened,
      height,
      modalOpened,
      overtimed,
      timerStatus,
      modalState,
      toAssing,
      startTime,
      formData,
      selectedRow,
      sub,
    } = this.state;

    const {
      dragging,
      job,
      allowUpdate,
      permanentReassign,
      permanentReassignSelectedDay,
      // handleOnPermReassignSelectedDayChange,
      // handleOnPermanentReassignChange,
      identifier,
      // vendorId,
      // workerId,
      // currentDate,
      workers,
      // areaRef,
    } = this.props;

    const jobTimeDifference = this.getCheckinCheckOutDifference(
      job.checkin_time,
      job.checkout_time,
      'HH:mm:ss A'
    );

    let overtime = 0;
    if (job && jobTimeDifference > job['job_duration_in_minutes']) {
      overtime = jobTimeDifference - job['job_duration_in_minutes'];
    }

    const time_slots = [];
    if (toAssing !== undefined) {
      const { start_time, end_time } = workers[toAssing];
      const AM_PM = start_hour => (start_hour >= 12 ? 'PM' : 'AM');
      const blockLength = Math.abs(
        Number(end_time.split(':')[0]) - Number(start_time.split(':')[0])
      );
      for (
        let start_hour = Number(start_time.split(':')[0]), i = 0;
        i <= blockLength;
        i++
      ) {
        const currentZone = AM_PM(start_hour + i);
        time_slots.push({
          label: `${start_hour + i}:00 ${currentZone}`,
        });
      }
    }

    return (
      <>
        <DeleteModal
          onClose={this.closeJobModal}
          onAssign={this.onAssign}
          visible={modalState === true}
          job={job}
          worker={workers[toAssing]}
          time={startTime}
        />
        <div
          className={`job-card ${
            dragging ? 'dragging' : isOpened ? 'opened' : ''
          } ${this.getJobContainerClass(job)} ${
            timerStatus || job.checkout_time
              ? `job-card--with-timer-${identifier}`
              : ''
          } ${
            permanentReassign
              ? 'hightlighter-1'
              : permanentReassignSelectedDay
              ? 'hightlighter-2'
              : ''
          } ${job?.job_status === 'draft' ? 'draft-job' : ''} ${
            job?.job_status === 'paused' ? 'paused-job' : ''
          }`}
          style={{
            height: dragging ? undefined : height,
          }}
          // id='popScroll'
        >
          <div
            className="job-card-content"
            ref={ref => (this.contentRef = ref)}
            onClick={() =>
              this.state.dropdownShow
                ? this.setState({ dropdownShow: false })
                : ''
            }
          >
            <div className="job-summary" onClick={this.onClickCard}>
              <div className="job-summary-row">
                <span className="job-title">
                  {job.client ? job.client.title : ''}
                </span>
                <span className="job-id">Job ID: {job.id}</span>
              </div>
              <div className="job-summary-row">
                <span className="job-desc">{job.title}</span>
                <span className="job-type">
                  {job['repeating_job_id'] ? 'Regular' : 'Ad-Hoc'}
                </span>
              </div>
              {job?.job_order == 'unpaid' ? (
                <div className="job-summary-row">
                  <span className="job-resume-title">
                    Enable Check in / out
                  </span>
                  <span className="job-resume">
                    <Switch
                      name="check-in-out"
                      checked={job?.enable_check_in_out}
                      onChange={this.handleCheckInOut}
                    />
                  </span>
                </div>
              ) : (
                ''
              )}
              {job.job_status === 'paused' ? (
                <div className="job-summary-row">
                  <span className="job-resume-title">Resume Date</span>
                  <span className="job-resume">{job.resume_date}</span>
                </div>
              ) : (
                ''
              )}
              <div className="job-summary-row">
                <span className="job-company">{job.description}</span>
                <span className="job-duration">
                  {job.job_duration_in_minutes} Minutes
                </span>
              </div>
              <div className="job-main-info">
                <div className="job-title">
                  {job.client ? job.client.title : ''}
                </div>
                <div className="job-desc">{job.title}</div>
                {job?.job_order == 'unpaid' ? (
                  <div className="job-desc">Enable Check in / out</div>
                ) : (
                  ''
                )}
              </div>
              <div className="job-duration">
                <div className="job-id">Job ID: {job.id}</div>
                {job.job_duration_in_minutes} Minutes
                {job?.job_order == 'unpaid' ? (
                  <Switch
                    name="check-in-out"
                    checked={job?.enable_check_in_out}
                    onChange={this.handleCheckInOut}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
            {job.job_status === 'paused' && job?.assigned_worker ? (
              <div className="job-time-difference" onClick={this.onClickCard}>
                <p>Resume Date</p>
                <p className="job-time-difference-time">{`${job.resume_date}`}</p>
              </div>
            ) : (
              ''
            )}

            {job && job.checkin_time ? (
              <div className="job-counter" onClick={this.onClickCard}>
                {overtime || job.checkout_time ? (
                  <>
                    {job.checkout_time && (
                      <p
                        className={`job-counter-title job-counter-title--completed`}
                      >
                        Completed
                      </p>
                    )}
                    {overtime ? (
                      <p
                        className={`job-counter-title job-counter-title--overtime`}
                      >
                        Overtime
                      </p>
                    ) : null}
                  </>
                ) : (
                  <p
                    className={`job-counter-title ${this.getJobCounterClass(
                      overtimed,
                      job.checkout_time
                    )}`}
                  >
                    In time
                  </p>
                )}
                <p className="job-counter-text">
                  <JobTimer
                    formatValue={value => `${value < 10 ? `0${value}` : value}`}
                    startImmediately={false}
                    initialTime={this.getTimerInitialTime().diff}
                    checkpoints={[
                      {
                        time: job.job_duration_in_minutes * 60 * 1000,
                        callback: () => this.setState({ overtimed: true }),
                      },
                    ]}
                  >
                    {({ start, stop }) => {
                      this.timerControl(start, stop);
                      return (
                        <>
                          <JobTimer.Hours />:
                          <JobTimer.Minutes />:
                          <JobTimer.Seconds />
                        </>
                      );
                    }}
                  </JobTimer>
                </p>
              </div>
            ) : null}

            <div className="job-time" onClick={this.onClickCard}>
              <div className="preferred-time">
                <span>Preferred Time</span>
                <span>{job.preferred_time}</span>
              </div>
              <div className="job-time-row">
                <label>Check-in Time</label>
                <span>{job.checkin_time ? job.checkin_time : '-'}</span>
              </div>
              <div className="job-time-row">
                <label>Check-out Time</label>
                <span>{job.checkout_time ? job.checkout_time : '-'}</span>
              </div>
              <div className="job-time-row">
                <label>Duration</label>
                <span>{`${jobTimeDifference} min`}</span>
              </div>
              <div className="job-time-row">
                <label>Overtime</label>
                <span>{`${overtime < 0 ? 0 : overtime} min`}</span>
              </div>
              <div className="job-time-row">
                <label>Fixed Service Duration</label>
                <span>{job.fixed_service_time ? 'Yes' : 'No'}</span>
              </div>
            </div>

            {job.address && (
              <div className="job-location" onClick={this.onClickCard}>
                <div className="job-location-row">
                  <label>Address</label>
                  <span>{job.address.address}</span>
                </div>
                <div className="job-location-unit-postal">
                  <div className="job-location-row">
                    <label>Unit Number</label>
                    <span>{job.address.unit_number}</span>
                  </div>
                  <div className="job-location-row job-location-postal-code">
                    <label>Postal Code</label>
                    <span>{job.address.postal_code}</span>
                  </div>
                </div>
              </div>
            )}

            {
              <div className="job-notes">
                <div className="job-notes-row">
                  <div className="job-notes-viewmore">
                    <label>General Notes</label>
                    <Button type="link" onClick={this.onClickViewMore}>
                      View More
                    </Button>
                  </div>
                  <span>{job['job_notes']}</span>
                </div>
                {job['repeating_job_id'] && (
                  <>
                    <Checkbox
                      checked={permanentReassign}
                      onChange={this.permanentReassignHandler}
                      disabled={
                        permanentReassignSelectedDay ||
                        this.isJobStatusCanceled(job?.job_status) ||
                        this.isJobStatusSkipped(job?.job_status) ||
                        job.checkout_time
                      }
                      ref={this.permAssignRef}
                    >
                      Permanent Reassign
                    </Checkbox>
                    <Checkbox
                      checked={permanentReassignSelectedDay}
                      onChange={this.permanentReassignSelectedDayHandler}
                      disabled={
                        permanentReassign ||
                        this.isJobStatusCanceled(job?.job_status) ||
                        this.isJobStatusSkipped(job?.job_status) ||
                        job.checkout_time
                      }
                      ref={this.permAssignSelectedDayRef}
                      style={{ marginLeft: 0 }}
                    >
                      Permanent Reassign Selected Day
                    </Checkbox>
                  </>
                )}
              </div>
            }
            {true && (
              <div
                className={`worker-location ${
                  job?.job_status === 'paused'
                    ? 'permanent-assign-selectBox-div'
                    : ''
                }`}
                style={{
                  visibility:
                    permanentReassignSelectedDay || permanentReassign
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <div className="worker-location-row">
                  <Select
                    style={{ width: 130 }}
                    onChange={this.handleWorkerChange}
                    // getPopupContainer={() =>
                    //   areaRef === 'areaRef'
                    //     ? document.getElementById('areaRef')
                    //     : document.getElementById('vendorRef')
                    // }
                    placeholder={'Select worker'}
                    disabled={job.checkout_time}
                  >
                    {workers.map(worker => (
                      <Option key={worker.id} value={worker.id}>
                        {worker.first_name}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    style={{ width: 68 }}
                    onChange={this.handleTimeChange}
                    disabled={toAssing !== undefined ? false : true}
                    showArrow={false}
                    placeholder="Time"
                  >
                    {toAssing !== undefined &&
                      time_slots.map((el, i) => (
                        <Option key={i} value={el.label}>
                          {el.label}
                        </Option>
                      ))}
                  </Select>
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                placeItems: 'center',
                justifyContent: 'end',
              }}
            >
              <div className="schedular-card-dropdown">
                <EllipsisOutlined
                  style={{ fontSize: 22 }}
                  onClick={() =>
                    this.setState({ dropdownShow: !this.state.dropdownShow })
                  }
                />
                {this.state.dropdownShow
                  ? this.renderDropdownMenu(job, sub)
                  : ''}
              </div>
            </div>
            {!job.checkout_time &&
              !this.isJobStatusCanceled(job?.job_status) &&
              !this.isJobStatusSkipped(job?.job_status) &&
              allowUpdate && (
                <Button type="primary" block onClick={this.onClickCheck}>
                  {job.checkin_time ? 'CHECK OUT' : 'CHECK IN'}
                </Button>
              )}
            {!job.checkin_time &&
              !this.isJobStatusCanceled(job?.job_status) &&
              !this.isJobStatusSkipped(job?.job_status) && (
                <Button type="default" block onClick={this.onClickCancel}>
                  {`CANCEL/SKIP`}
                </Button>
              )}
            {(job?.job_status === 'draft' || job?.job_status === 'paused') &&
            job.assigned_worker ? (
              <Button
                type="primary"
                style={{ fontSize: '13px' }}
                block
                onClick={this.handleActiveJob}
              >
                {'Active Job'}
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <Modal
          title="Notes"
          visible={modalOpened === 1}
          onCancel={this.closeModal}
          footer={[
            <Button key={0} type="primary" onClick={this.closeModal}>
              Close
            </Button>,
          ]}
          destroyOnClose
        >
          <p>{job['job_notes']}</p>
        </Modal>
        <Modal
          title="Confirmation"
          className="job-cancel-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          footer={[
            <Button
              key={0}
              type="danger"
              onClick={e => {
                this.onCancelOptionChange('cancel');
                this.openConfirmationPopup(e);
                // this.onCancel('cancel');
              }}
            >
              Cancel Service
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={e => {
                this.onCancelOptionChange('skip');
                this.openConfirmationPopup(e);
              }}
            >
              Skip Service
            </Button>,
          ]}
          destroyOnClose
        >
          <p>Are you sure want to cancel/skip the service?</p>
          <p>This action can not be undone</p>
          <Checkbox
            className="checkbox"
            checked={this.state.cancelSkipCheckbox}
            onChange={() => {
              this.setState({
                cancelSkipCheckbox: !this.state.cancelSkipCheckbox,
              });
            }}
            style={{ fontWeight: 'bold' }}
          >
            Would you like to remove assigned worker and assigned time as well.
          </Checkbox>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 3}
          onCancel={() => this.closeModal()}
          onOk={() => this.onCancel()}
          okText="Yes"
          destroyOnClose
        >
          <>
            <p className="main-message">
              {`Are you sure to ${this.state.cancelOpt} this service`}
            </p>
            <p>This action can not be undone</p>
          </>
        </Modal>
        <Drawer
          closable={false}
          placement="right"
          width={450}
          className={'job-create-drawer'}
          visible={modalOpened === 4}
          onClose={this.closeModal}
          destroyOnClose
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Edit Job details</h3>
                <div className="header-actions">
                  <DeleteFilledIcon
                    className="action-primary"
                    onClick={() => this.onClickDelete(formData)}
                  />
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <JobForm
                  data={formData}
                  type={formData?.sub ? 'repeating' : 'single'}
                  formStatus="edit"
                  onSubmit={this.onSubmit}
                />
              </div>
            </>
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 5}
          footer={[
            <Button key={0} onClick={this.closeModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="danger"
              htmlType="submit"
              form="delete-validation"
              disabled={this.state.deleteValidation}
              onClick={this.performDelete}
            >
              Delete
            </Button>,
          ]}
          okButtonProps={{ htmlType: 'submit', form: 'delete-validation' }}
          destroyOnClose
          onCancel={this.closeModal}
        >
          {selectedRow && (
            <>
              <div className="user-info">
                <Avatar icon={<InboxOutlined />} size={72} />
                <div className="username">
                  {selectedRow.jobs && selectedRow.jobs.length
                    ? selectedRow.jobs[0].title
                    : ''}
                </div>
                <span>Job ID:&nbsp;{selectedRow.id}</span>
              </div>
              <p className="main-message">
                Are you sure want to remove this job?
              </p>
              <p>This action can not be undone</p>
              <div className="user-info">
                <div className="username">
                  <p style={{ textAlign: 'left' }}>
                    To confirm type{' '}
                    <q>
                      <i>delete</i>
                    </q>
                  </p>
                  <Input
                    style={{ width: '100%' }}
                    name="validationField"
                    placeholder="delete"
                    onChange={e =>
                      e.target.value !== 'delete'
                        ? this.setState({ deleteValidation: true })
                        : this.setState({ deleteValidation: false })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 6}
          footer={[
            <Button key={0} onClick={this.closeModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="danger"
              htmlType="submit"
              form="delete-validation"
              disabled={this.state.deleteValidation}
              onClick={this.handleCancel}
            >
              Yes
            </Button>,
          ]}
          okButtonProps={{ htmlType: 'submit', form: 'delete-validation' }}
          destroyOnClose
          onCancel={this.closeModal}
        >
          {selectedRow && (
            <>
              <div className="user-info">
                <Avatar icon={<InboxOutlined />} size={72} />
                <div className="username">
                  {selectedRow.jobs && selectedRow.jobs.length
                    ? selectedRow.jobs[0].title
                    : ''}
                </div>
                <p>Job ID:&nbsp;{selectedRow.id}</p>
                <p>
                  Please choose the date from where you want to cancel the job.
                </p>
                <DatePicker
                  disabledDate={this.disableFutureDates}
                  onChange={this.handleDateChange}
                />
              </div>
              <p className="main-message">
                Are you sure want to cancel this job? Once done, It cannot be
                reversed.
              </p>
              <div className="user-info">
                <div className="username">
                  <p style={{ textAlign: 'left', marginLeft: '12px' }}>
                    To confirm type{' '}
                    <q>
                      <i>cancel</i>
                    </q>
                  </p>
                  <Input
                    style={{ width: '95%' }}
                    name="validationField"
                    placeholder="cancel"
                    onChange={e =>
                      e.target.value !== 'cancel'
                        ? this.setState({ deleteValidation: true })
                        : this.setState({ deleteValidation: false })
                    }
                  />
                </div>
              </div>
            </>
          )}
        </Modal>
        <Modal
          title="Confirmation"
          className="assign-worker-modal"
          visible={modalOpened === 7}
          onCancel={this.closeModal}
          footer={[
            <Button
              key={0}
              type="danger"
              style={{ width: '150px', borderRadius: '4px' }}
              htmlType="submit"
              form="cancel-skip"
              onClick={e => {
                e.stopPropagation();
                this.setState(state => ({
                  ...state,
                  cancelSkipData: { ...state.cancelSkipData, status: 'cancel' },
                }));
              }}
            >
              Cancel Subscription
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{ width: '150px' }}
              htmlType="submit"
              form="cancel-skip"
              onClick={e => {
                e.stopPropagation();
                this.setState(state => ({
                  ...state,
                  cancelSkipData: { ...state.cancelSkipData, status: 'skip' },
                }));
              }}
            >
              Skip Subscription
            </Button>,
          ]}
          okButtonProps={{ htmlType: 'submit', form: 'cancel-skip' }}
          cancelButtonProps={{ htmlType: 'submit', form: 'cancel-skip' }}
          destroyOnClose
        >
          <div className="job-cancel-modal">
            <p>Are you sure want to cancel/skip the subscription?</p>
          </div>
          <Formik
            initialValues={{
              start_date: '',
              end_date: '',
            }}
            validationSchema={formValidations['CancelSkipFormValidation']}
            onSubmit={values => {
              this.setState(state => ({
                ...state,
                cancelSkipData: {
                  ...state.cancelSkipData,
                  start_date: values.start_date,
                  end_date: values.end_date,
                },
              }));
              this.onConfirm();
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form id="cancel-skip" onSubmit={handleSubmit}>
                <div className="date-range">
                  <label>Select Date Range</label>

                  <div className="form-row">
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['start_date'] && errors['start_date']
                          ? 'error'
                          : null
                      }
                      help={touched['start_date'] && errors['start_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.start_date
                            ? moment(values.start_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) => {
                          handleChange({
                            target: {
                              name: 'start_date',
                              value: dateString,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                    <span>to</span>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['end_date'] && errors['end_date']
                          ? 'error'
                          : null
                      }
                      help={touched['end_date'] && errors['end_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.end_date
                            ? moment(values.end_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) =>
                          handleChange({
                            target: {
                              name: 'end_date',
                              value: dateString,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 8}
          onCancel={() => {
            this.closeModal();
          }}
          onOk={() => {
            if (this.state.cancelSkipData.status === 'cancel') {
              this.handleCancelJobs();
            } else if (this.state.cancelSkipData.status === 'skip') {
              this.handleSkipJobs();
            }
            this.closeModal();
          }}
          okText="Yes"
          destroyOnClose
        >
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
            <p className="pop-up-body">
              {`To ${this.state?.cancelSkipData?.status} this service. The process cannot be undone.`}
            </p>
          </>
        </Modal>
        <Modal
          title="Confirmation"
          className="assign-worker-modal"
          visible={modalOpened === 9}
          onCancel={this.closeModal}
          footer={[
            <Button key={0} type="danger" onClick={this.closeModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form="pause-sub"
              onClick={e => {
                e.stopPropagation();
                this.setState(state => ({
                  ...state,
                  pauseData: { ...state.pauseData, status: 'skip' },
                }));
              }}
            >
              Pause
            </Button>,
          ]}
          okButtonProps={{ htmlType: 'submit', form: 'cancel-skip' }}
          destroyOnClose
        >
          <div className="job-cancel-modal">
            <p>Are you sure want to pause the service?</p>
          </div>
          <Formik
            initialValues={{
              start_date: '',
              end_date: '',
            }}
            validationSchema={formValidations['PauseSubscriptionValidation']}
            onSubmit={values => {
              this.pauseConfirm();
              this.setState(state => ({
                ...state,
                pauseData: {
                  ...state.pauseData,
                  start_date: values.start_date,
                  end_date: values.end_date,
                },
              }));
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form id="pause-sub" onSubmit={handleSubmit}>
                <div className="date-range">
                  <label>Select Date Range</label>

                  <div className="form-row">
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['start_date'] && errors['start_date']
                          ? 'error'
                          : null
                      }
                      help={touched['start_date'] && errors['start_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.start_date
                            ? moment(values.start_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) => {
                          handleChange({
                            target: {
                              name: 'start_date',
                              value: dateString,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                    <span>to</span>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['end_date'] && errors['end_date']
                          ? 'error'
                          : null
                      }
                      help={touched['end_date'] && errors['end_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.end_date
                            ? moment(values.end_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) =>
                          handleChange({
                            target: {
                              name: 'end_date',
                              value: dateString,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 10}
          onCancel={() => {
            this.closeModal();
          }}
          onOk={() => {
            this.handlePauseJobs();
            this.closeModal();
          }}
          okText="Yes"
          destroyOnClose
        >
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
            <p className="pop-up-body">The process cannot be undone.</p>
          </>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 11}
          footer={[
            <Button key={0} onClick={this.closeModal}>
              Cancel
            </Button>,
            <Button type="primary" onClick={() => this.enableCheckInOut(false)}>
              This Job
            </Button>,
            <Button type="primary" onClick={() => this.enableCheckInOut(true)}>
              Subscription
            </Button>,
          ]}
          destroyOnClose
          onCancel={this.closeModal}
        >
          <>
            <p className="main-message pop-up-body">
              Please choose whether you toggle access for this job or
              subscription?
            </p>
          </>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 12}
          onCancel={() => {
            this.handleCheckInOut(true);
          }}
          onOk={() => {
            this.confirmEnableCheckInOut(job?.id);
            this.closeModal();
          }}
          okText="Yes"
          destroyOnClose
        >
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
          </>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 13}
          footer={[
            <Button key={0} onClick={this.closeModal}>
              Cancel
            </Button>,
            <Button type="primary" onClick={() => this.enableActiveJob(false)}>
              This Job
            </Button>,
            <Button type="primary" onClick={() => this.enableActiveJob(true)}>
              Subscription
            </Button>,
          ]}
          destroyOnClose
          onCancel={this.closeModal}
        >
          <>
            <p className="main-message pop-up-body">
              Please choose whether you active this job or subscription?
            </p>
          </>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 14}
          onCancel={() => {
            this.handleActiveJob();
          }}
          onOk={() => {
            this.handleJobStatus();
            this.closeModal();
          }}
          okText="Yes"
          destroyOnClose
        >
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
          </>
        </Modal>
      </>
    );
  }
}

JobCard.propTypes = {
  job: PropTypes.object,
  identifier: PropTypes.string,
  vendorId: PropTypes.number,
  workerId: PropTypes.number,
  checkInJob: PropTypes.func,
  checkoutJob: PropTypes.func,
  cancelJob: PropTypes.func,
  setSchedulerState: PropTypes.func,
  updateJobData: PropTypes.func,
  deleteJob: PropTypes.func,
  cancelRepeatingJob: PropTypes.func,
  dragging: PropTypes.bool,
  currentDate: PropTypes.object,
  allowUpdate: PropTypes.bool,
  permanentReassign: PropTypes.bool,
  permanentReassignSelectedDay: PropTypes.bool,
  handleOnPermReassignSelectedDayChange: PropTypes.func,
  handleOnPermanentReassignChange: PropTypes.func,
  assignJobToWorker: PropTypes.func,
  workers: PropTypes.array,
  assignWorkerPerm: PropTypes.func,
  cancelSubscription: PropTypes.func,
  skipSubscription: PropTypes.func,
  activeJob: PropTypes.func,
  pauseJobs: PropTypes.func,
  enableCheckInOut: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    updateJobData: (id, data, type) =>
      dispatch(updateJobData({ id, data, type })),
    pauseJobs: payload => dispatch(pauseJobs(payload)),
    cancelRepeatingJob: payload => dispatch(cancelRepeatingJob(payload)),
    deleteJob: payload => dispatch(deleteJob(payload)),
    checkInJob: id =>
      dispatch(
        updateJob({
          id,
          action: JobUpdateActionCheckIn,
        })
      ),
    checkoutJob: id =>
      dispatch(
        updateJob({
          id,
          action: JobUpdateActionCheckOut,
        })
      ),
    cancelJob: payload =>
      dispatch(
        updateJob({
          id: payload.id,
          action: JobUpdateActionCancel,
          remove_assigned_worker_and_time:
            payload.remove_assigned_worker_and_time,
        })
      ),
    skipJob: id =>
      dispatch(
        updateJob({
          id,
          // action: JobUpdateActionSkip, ( currrently act as cancel )
          action: JobUpdateActionSkip,
        })
      ),

    assignWorkerPerm: (id, data, permanentReassignSelectedDay) =>
      dispatch(assignWorkerPerm({ id, data, permanentReassignSelectedDay })),
    handleOnPermanentReassignChange: payload =>
      dispatch(handleOnPermanentReassignChange(payload)),
    handleOnPermReassignSelectedDayChange: payload =>
      dispatch(handleOnPermReassignSelectedDayChange(payload)),
    cancelSubscription: payload => dispatch(cancelSubscription(payload)),
    skipSubscription: payload => dispatch(skipSubscription(payload)),
    activeJob: id => dispatch(activeJob(id)),
    enableCheckInOut: payload => dispatch(enableCheckInOut(payload)),
  };
}

export default connect(null, mapDispatchToProps)(JobCard);
