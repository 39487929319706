import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Marker } from 'react-leaflet';
import L from 'leaflet';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import icon from '../../../../../assets/icons/ic_pin_worker_2.svg';

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [24, 0],
});

function WorkerMarker(props) {
  return (
    <Marker position={[props.lat, props.lng]} icon={DefaultIcon}>
      <Tooltip direction="top">
        <div className="map_pin_job-tooltip_container">
          <div
            className="map_pin_job_user_info_container"
            style={{ marginTop: 10 }}
          >
            <div style={{ alignSelf: 'center' }}>
              <Avatar
                size={32}
                src={props.worker_avatar}
                icon={<UserOutlined />}
              />
            </div>
            <div className="map_pin_job_user_info">
              <h3 className="map_pin_job_user_info_name truncate">
                {props.worker_name}
              </h3>
              <p className="map_pin_job_user_info_addr truncate">
                {props.vendor_name}
              </p>
            </div>
          </div>
          <div className="map_pin_job-tooltip_header" style={{ marginTop: 10 }}>
            <p className="map_pin_job_user_info_addr truncate">
              Currently Servicing ( {props.service_duration} )
            </p>
          </div>
          <div className="map_pin_job-tooltip_header" style={{ marginTop: 10 }}>
            <p
              className="map_pin_job_user_info_addr truncate"
              style={{ maxWidth: 140 }}
            >
              {props.client_name}
            </p>
            <p className="map_pin_job_time">{props.last_update} mins ago</p>
          </div>
        </div>
      </Tooltip>
    </Marker>
  );
}

WorkerMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  last_update: PropTypes.number,
  service_duration: PropTypes.string,
  worker_avatar: PropTypes.string,
  worker_name: PropTypes.string,
  vendor_name: PropTypes.string,
  client_name: PropTypes.string,
};

export default WorkerMarker;
