import React from 'react';
import StyledItemForm from '../styled-components/form-split';
import StyledFormItem from '../styled-components/form-item';
import {
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  Card,
  Image,
  Radio,
  Checkbox,
} from 'antd';
import { capitalize, getImageURI } from '../../../utils/apisauce';
import { DeleteFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import MultiImgUpload from '../../../components/MultiImgUpload';

const ProductItemForm = React.forwardRef((props, ref) => {
  const {
    index,
    initialState: itemState,
    touched,
    errors,
    onInputChange,
    selected,
    handleClone,
    handleRemoveItem,
    handleCheckbox2,
    handleCheckbox,
    libraryImage,
  } = props;

  return (
    <StyledItemForm ref={ref}>
      {index !== 0 ? (
        <DeleteFilled
          style={{ float: 'right', padding: 5 }}
          onClick={() => handleRemoveItem(itemState)}
        />
      ) : (
        ''
      )}
      <div style={{ padding: 24 }}>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={16}>
            <StyledFormItem
              label={'Item'}
              hasFeedback
              validateStatus={
                touched && touched['title'] && errors && errors['title']
                  ? 'error'
                  : null
              }
              help={
                errors && errors['title']
                  ? capitalize(errors['title'].split('.')[1])
                  : null
              }
            >
              <Input
                value={itemState.title}
                name="title"
                onChange={onInputChange}
                placeholder="Enter item name"
              />
            </StyledFormItem>
            <StyledFormItem
              label={'Description'}
              hasFeedback
              validateStatus={
                touched &&
                touched['description'] &&
                errors &&
                errors['description']
                  ? 'error'
                  : null
              }
              help={
                errors && errors['description'] ? errors['description'] : null
              }
            >
              <Input.TextArea
                value={itemState.description}
                name="description"
                onChange={onInputChange}
                placeholder="Enter the message here"
              />
            </StyledFormItem>
          </Col>
          <Col xs={24} sm={24} md={8} style={{ height: 'auto' }}>
            <p style={{ marginBottom: '2px' }}>Max(5) files</p>
            <Radio.Group
              defaultValue={itemState.image_type}
              onChange={value => {
                if (itemState.image_type === 'attached') {
                  itemState.library_image_ids = [];
                } else {
                  itemState.image = '';
                }
                onInputChange(value);
              }}
              name="image_type"
              buttonStyle="solid"
              style={{ marginBottom: '10px' }}
            >
              <Radio.Button name="image_type" value={'attached'}>
                Upload Image
              </Radio.Button>
              <Radio.Button name="image_type" value={'library'}>
                Choose From Library
              </Radio.Button>
            </Radio.Group>
            {itemState.image_type === 'attached' ? (
              <MultiImgUpload
                values={
                  itemState.image?.length
                    ? itemState.image?.map(img => img)
                    : itemState.images?.length
                    ? itemState.images?.map(img => {
                        return { url: getImageURI(img?.url), id: img?.id };
                      })
                    : []
                }
                onImagesChange={onInputChange}
                accept=".png, .jpg, .jpeg"
                selected={selected}
                error={errors}
              />
            ) : (
              <div
                style={{
                  height: '200px',
                  overflowY: 'scroll',
                  textAlign: 'center',
                }}
              >
                <Checkbox.Group
                  onChange={handleCheckbox}
                  defaultValue={itemState?.images?.map(
                    el => el.library_image_id
                  )}
                  name="library_image_ids"
                >
                  {libraryImage?.map(item => (
                    <Checkbox
                      key={item?.id}
                      className={`radioImage ${
                        itemState?.library_image_ids?.length === 5
                          ? 'disabled'
                          : ''
                      }`}
                      name="library_image_ids"
                      value={item?.id}
                    >
                      <Card>
                        <Image
                          src={getImageURI(item?.image_path?.url)}
                          width={120}
                          preview={false}
                        />
                      </Card>
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </div>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={24} md={16}>
            <div
              style={
                {
                  // display: 'flex',
                  // direction: 'row',
                  // justifyContent: 'space-between',
                }
              }
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={24 / 4}>
                  <StyledFormItem
                    marginRight="10px"
                    label={'Unit'}
                    hasFeedback
                    validateStatus={
                      touched && touched['unit'] && errors && errors['unit']
                        ? 'error'
                        : null
                    }
                    help={errors && errors['unit'] ? errors['unit'] : null}
                  >
                    <Input
                      value={itemState.unit}
                      onChange={onInputChange}
                      name="unit"
                      placeholder="Enter unit name"
                    />
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={24 / 4}>
                  <StyledFormItem
                    marginRight="10px"
                    label={'Stock'}
                    hasFeedback
                    validateStatus={
                      touched &&
                      touched['stock_count'] &&
                      errors &&
                      errors['stock_count']
                        ? 'error'
                        : null
                    }
                    help={
                      errors && errors['stock_count']
                        ? errors['stock_count']
                        : null
                    }
                  >
                    <InputNumber
                      value={itemState.stock_count}
                      onChange={value =>
                        onInputChange({
                          target: { name: 'stock_count', value: value },
                        })
                      }
                      name="stock_count"
                      placeholder="Enter Stock"
                      min={0}
                      style={{ width: '100%' }}
                    />
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={24 / 4}>
                  <StyledFormItem
                    label={'Amount Price'}
                    marginRight="10px"
                    hasFeedback
                    validateStatus={
                      touched && touched['amount'] && errors && errors['amount']
                        ? 'error'
                        : null
                    }
                    help={errors && errors['amount'] ? errors['amount'] : null}
                  >
                    <InputNumber
                      formatter={value =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      value={itemState.amount}
                      onChange={value =>
                        onInputChange({ target: { name: 'amount', value } })
                      }
                      name="amount"
                      placeholder="Enter price"
                      style={{ width: '100%' }}
                      prefix={'$'}
                      min={0}
                    />
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={24 / 4}>
                  <StyledFormItem
                    label={'Commission %'}
                    hasFeedback
                    validateStatus={
                      touched &&
                      touched['commission_percentage'] &&
                      errors &&
                      errors['commission_percentage']
                        ? 'error'
                        : null
                    }
                    help={
                      errors && errors['commission_percentage']
                        ? errors['commission_percentage']
                        : null
                    }
                  >
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                      value={itemState.commission_percentage}
                      onChange={value =>
                        onInputChange({
                          target: { name: 'commission_percentage', value },
                        })
                      }
                      name="commission_percentage"
                      placeholder="Enter commission percentage"
                      style={{ width: '100%' }}
                      prefix={'%'}
                    />
                  </StyledFormItem>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          {itemState.promoted ? (
            <Col xs={24} sm={24} md={24 / 4}>
              <StyledFormItem
                label={'Max Limit'}
                marginRight="10px"
                hasFeedback
                validateStatus={
                  touched &&
                  touched['max_redemption_per_order'] &&
                  errors &&
                  errors['max_redemption_per_order']
                    ? 'error'
                    : null
                }
                help={
                  errors && errors['max_redemption_per_order']
                    ? errors['max_redemption_per_order']
                    : null
                }
              >
                <InputNumber
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  value={itemState.max_redemption_per_order}
                  onChange={value =>
                    onInputChange({
                      target: { name: 'max_redemption_per_order', value },
                    })
                  }
                  name="max_redemption_per_order"
                  placeholder="Enter Limit"
                  style={{ width: '100%' }}
                  min={0}
                />
              </StyledFormItem>
            </Col>
          ) : (
            ''
          )}
          <Col xs={24} sm={24} md={20}>
            <div
              style={{
                display: 'flex',
                direction: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Row gutter={24}>
                <Col xs={24} sm={24} md={10}>
                  <StyledFormItem
                    hasFeedback
                    validateStatus={
                      touched &&
                      touched['promoted'] &&
                      errors &&
                      errors['promoted']
                        ? 'error'
                        : null
                    }
                    help={
                      errors && errors['promoted'] ? errors['promoted'] : null
                    }
                  >
                    <Checkbox
                      defaultValue={false}
                      style={{ marginBottom: 0 }}
                      checked={itemState.promoted}
                      onChange={value => {
                        handleCheckbox2(
                          value.target.name,
                          value.target.checked
                        );
                        if (!value.target.checked) {
                          onInputChange({
                            target: {
                              name: 'max_redemption_per_order',
                              value: null,
                            },
                          });
                        }
                      }}
                      name="promoted"
                    >
                      Promoted
                    </Checkbox>
                  </StyledFormItem>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row gutter={19}></Row>
        <Button
          disabled={selected === 'single'}
          onClick={() => handleClone(itemState)}
        >
          {' '}
          Clone Product{' '}
        </Button>
      </div>
    </StyledItemForm>
  );
});

ProductItemForm.propTypes = {
  index: PropTypes.number,
  initialState: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  onInputChange: PropTypes.func,
  selected: PropTypes.string,
  handleClone: PropTypes.func,
  handleRemoveItem: PropTypes.func,
};

ProductItemForm.displayName = 'ProductItemForm';

export default React.memo(ProductItemForm);
