import React from 'react';
import StyledItemForm from '../styled-components/form-split';
import StyledFormItem from '../styled-components/form-item';
import { Input, InputNumber, Button, Row, Col } from 'antd';
import { getImageURI } from '../../../../utils/apisauce';
import { DeleteFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import MultiImgUpload from '../../../../components/MultiImgUpload';

const ProductItemForm = React.forwardRef((props, ref) => {
  const {
    index,
    initialState: itemState,
    touched,
    errors,
    onInputChange,
    selected,
    handleClone,
    handleRemoveItem,
  } = props;

  return (
    <StyledItemForm ref={ref}>
      {index !== 0 ? (
        <DeleteFilled
          style={{ float: 'right', padding: 5 }}
          onClick={() => handleRemoveItem(itemState)}
        />
      ) : (
        ''
      )}
      <div style={{ padding: 24 }}>
        <Row gutter={24}>
          <Col span={19}>
            <StyledFormItem
              label={'Item'}
              hasFeedback
              validateStatus={
                touched && touched['title'] && errors && errors['title']
                  ? 'error'
                  : null
              }
              help={errors && errors['title'] ? errors['title'] : null}
            >
              <Input
                value={itemState.title}
                name="title"
                onChange={onInputChange}
                placeholder="Enter item name"
              />
            </StyledFormItem>
            <StyledFormItem
              label={'Description'}
              hasFeedback
              validateStatus={
                touched &&
                touched['description'] &&
                errors &&
                errors['description']
                  ? 'error'
                  : null
              }
              help={
                errors && errors['description'] ? errors['description'] : null
              }
            >
              <Input.TextArea
                value={itemState.description}
                name="description"
                onChange={onInputChange}
                placeholder="Enter the message here"
              />
            </StyledFormItem>
            <Row gutter={24}>
              <Col span={24}>
                <div
                  style={{
                    display: 'flex',
                    direction: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={8}>
                      <StyledFormItem
                        marginRight="10px"
                        label={'Unit'}
                        hasFeedback
                        validateStatus={
                          touched && touched['unit'] && errors && errors['unit']
                            ? 'error'
                            : null
                        }
                        help={errors && errors['unit'] ? errors['unit'] : null}
                      >
                        <Input
                          value={itemState.unit}
                          onChange={onInputChange}
                          name="unit"
                          placeholder="Enter unit name"
                        />
                      </StyledFormItem>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <StyledFormItem
                        marginRight="10px"
                        label={'Stock'}
                        hasFeedback
                        validateStatus={
                          touched &&
                          touched['stock_count'] &&
                          errors &&
                          errors['stock_count']
                            ? 'error'
                            : null
                        }
                        help={
                          errors && errors['stock_count']
                            ? errors['stock_count']
                            : null
                        }
                      >
                        <InputNumber
                          value={itemState.stock_count}
                          onChange={value =>
                            onInputChange({
                              target: { name: 'stock_count', value: value },
                            })
                          }
                          name="stock_count"
                          placeholder="Enter Stock"
                          min={0}
                          style={{ width: '100%' }}
                        />
                      </StyledFormItem>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                      <StyledFormItem
                        label={'Amount Price'}
                        marginRight="10px"
                        hasFeedback
                        validateStatus={
                          touched &&
                          touched['amount'] &&
                          errors &&
                          errors['amount']
                            ? 'error'
                            : null
                        }
                        help={
                          errors && errors['amount'] ? errors['amount'] : null
                        }
                      >
                        <InputNumber
                          formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          value={itemState.amount}
                          onChange={value =>
                            onInputChange({ target: { name: 'amount', value } })
                          }
                          name="amount"
                          placeholder="Enter price"
                          style={{ width: '100%' }}
                          prefix={'$'}
                          min={0}
                        />
                      </StyledFormItem>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={5}>
            <p style={{ marginBottom: '2px' }}>Max(5) files</p>
            <MultiImgUpload
              values={
                itemState.images && 'vendor_id' in itemState
                  ? [getImageURI(itemState.images.url)]
                  : itemState['images']
                  ? itemState.images?.map(img => getImageURI(img?.url))
                  : []
              }
              onImagesChange={onInputChange}
              accept=".png, .jpg, .jpeg"
              selected={selected}
              error={errors}
            />
          </Col>
        </Row>
        <Row gutter={19}></Row>
        <Button
          disabled={selected === 'single'}
          onClick={() => handleClone(itemState)}
        >
          {' '}
          Clone Product{' '}
        </Button>
      </div>
    </StyledItemForm>
  );
});

ProductItemForm.propTypes = {
  index: PropTypes.number,
  initialState: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  onInputChange: PropTypes.func,
  selected: PropTypes.string,
  handleClone: PropTypes.func,
  handleRemoveItem: PropTypes.func,
};

ProductItemForm.displayName = 'ProductItemForm';

export default React.memo(ProductItemForm);
