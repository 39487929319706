import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getCoupons,
  getCouponsFailed,
  getCouponsSuccess,
  createCoupon,
  createCouponFailed,
  createCouponSuccess,
  updateCoupon,
  updateCouponFailed,
  updateCouponSucccess,
  deleteCoupon,
  deleteCouponFailed,
  deleteCouponSuccess,
} from './reducers';
const API_BASE_NAME = '/api/v1/admin/coupons';

function* getCouponsSaga({ payload }) {
  let action;
  let url = API_BASE_NAME;
  try {
    if (payload['page']) {
      url += `?page=${payload['page']}`;
    } else {
      url += `?page=1`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    } else {
      url += `&per=20`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getCouponsSuccess(response);
  } catch (error) {
    action = getCouponsFailed(error.message);
  }
  yield put(action);
}

function* createCouponSaga({ payload }) {
  let action;
  try {
    const response = yield call(request, 'POST', `${API_BASE_NAME}`, {
      coupon: payload,
    });
    action = createCouponSuccess(response);
  } catch (error) {
    action = createCouponFailed(error.message);
  }
  yield put(action);
}

function* updateCouponSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `${API_BASE_NAME}/${payload.id}`,
      { coupon: payload }
    );
    action = updateCouponSucccess(response);
  } catch (error) {
    action = updateCouponFailed(error.message);
  }
  yield put(action);
}

function* deleteCouponSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `${API_BASE_NAME}/${payload}`
    );
    action = deleteCouponSuccess(response);
  } catch (error) {
    action = deleteCouponFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getCoupons, getCouponsSaga);
  yield takeLatest(createCoupon, createCouponSaga);
  yield takeLatest(updateCoupon, updateCouponSaga);
  yield takeLatest(deleteCoupon, deleteCouponSaga);
}
