import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorClients',
  initialState: {
    activeTab: 'personal',
    submitting: false,
    loading: false,
    deleteSucess: false,
    clients: [],
    personal_clients: [],
    accounting_transactions: [],
    error: null,
  },
  reducers: {
    setTab: (state, { payload }) => {
      if (state.activeTab !== payload) {
        state.activeTab = payload;
        state.clients = [];
      }
    },
    getClients: state => {
      state.loading = true;
      state.deleteSucess = false;
      state.error = null;
    },
    getClientsSuccess: (state, { payload }) => {
      state.loading = false;
      state.deleteSucess = false;
      state.error = null;
      state.meta = payload['meta'];
      state.clients = payload;
      state.personal_clients = payload['personal_clients'];
    },
    getClientsFailed: (state, { payload }) => {
      state.loading = false;
      state.deleteSucess = false;
      state.error = payload;
      apiError(payload);
    },
    createClient: state => {
      state.submitting = true;
      state.deleteSucess = false;
      state.error = null;
      state.message = message.loading('Creating a new client ...', 0);
    },
    createClientSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.deleteSucess = false;
      state.error = null;
      if (payload.type === state.activeTab) {
        state.personal_clients.push(payload.response);
      }
      const clientName =
        payload.type === 'personal'
          ? payload.response.full_name
          : payload.response.business_name;

      notification.success({
        message: 'New Client',
        description: `New Client ${clientName} has been created.`,
      });
    },
    createClientFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.deleteSucess = false;
      state.error = payload;
      apiError(payload);
    },
    updateClient: state => {
      state.submitting = true;
      state.deleteSucess = false;
      state.error = null;
      state.message = message.loading('Updating client info...', 0);
    },
    updateClientSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.deleteSucess = false;
      state.error = null;
      if (payload.type === state.activeTab) {
        if (payload.type === 'personal') {
          const idx = state.personal_clients.findIndex(
            el =>
              el.personal_client_id === payload.response['personal_client_id']
          );
          if (idx >= 0) {
            state.personal_clients[
              idx
            ].title = `${payload.response.first_name} ${payload.response.last_name}`;
            state.personal_clients[idx] = payload.response;
            // console.log('personal in');
          }
        } else {
          const idx = state.clients.findIndex(
            el => el.id === payload.response.id
          );
          if (idx >= 0) {
            state.clients[idx] = payload.response;
          }
        }
      }

      const clientName =
        payload.type === 'personal'
          ? `${payload.response.first_name} ${payload.response.last_name}`
          : payload.response.business_name;
      notification.success({
        message: 'Client Updated',
        description: `Client ${clientName} has been updated.`,
      });
    },
    updateClientFailed: (state, { payload }) => {
      state.message();
      state.deleteSucess = false;
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    deleteClient: state => {
      state.message = message.loading('Deleting client ...', 0);
      state.error = null;
      state.deleteSucess = false;
    },
    deleteClientSuccess: (state, { payload }) => {
      state.message();
      state.error = null;
      state.deleteSucess = true;
      const clientName =
        payload.type === 'personal'
          ? payload.response.title
          : payload.response.business_name;
      notification.success({
        message: 'Delete Client',
        description: `Client ${clientName} has been deleted.`,
      });
    },
    deleteClientFailed: (state, { payload }) => {
      state.message();
      state.deleteSucess = false;
      state.error = payload;
      apiError(payload);
    },
    getTransactionDetails: state => {
      state.loading = true;
      state.deleteSucess = false;
    },
    getTransactionDetailsSuccess: (state, { payload }) => {
      state.loading = false;
      state.deleteSucess = false;
      state.accounting_transactions = payload.accounting_transactions;
    },
    getTransactionDetailsFailed: state => {
      state.loading = false;
      state.deleteSucess = false;
    },
  },
});

export const {
  setTab,
  getClients,
  getClientsFailed,
  getClientsSuccess,
  createClient,
  createClientFailed,
  createClientSuccess,
  updateClient,
  updateClientFailed,
  updateClientSuccess,
  deleteClient,
  deleteClientFailed,
  deleteClientSuccess,
  getTransactionDetails,
  getTransactionDetailsSuccess,
  getTransactionDetailsFailed,
} = slice.actions;

export default slice.reducer;
