import { Card, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTransactionsforTransfer } from '../reducers';
import DataTable from '../../../components/DataTable';
import { capitalize } from '../../../utils/apisauce';

function Transfer() {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { loading, transactions } = useSelector(state => state.transactions);
  const { total, data } = transactions;
  useEffect(() => {
    dispatch(getTransactionsforTransfer({ page: page }));
  }, [dispatch, page]);

  const tableColumns = () => [
    {
      title: <span className="table-column-title">Transaction ID</span>,
      key: 'trx_id',
      dataIndex: 'trx_id',
      render: (text, record) => record['trx_id'],
    },

    {
      title: <span className="table-column-title">Sender</span>,
      key: 'transfer',
      dataIndex: 'transfer',
      width: 150,
      render: function render(transfer) {
        return (
          <span>
            {`${
              transfer
                ? transfer.from_account.accountable
                  ? capitalize(transfer.from_account.accountable.full_name)
                  : 'Sender removed from system'
                : ''
            } `}
          </span>
        );
      },
    },
    {
      title: <span className="table-column-title">Receiver</span>,
      key: 'transfer',
      dataIndex: 'transfer',
      width: 150,
      render: function render(transfer) {
        return (
          <span>
            {`${
              transfer
                ? transfer.to_account.accountable
                  ? capitalize(transfer.to_account.accountable.full_name)
                  : 'Receiver removed from system'
                : ''
            } `}
          </span>
        );
      },
    },
    {
      title: <span className="table-column-title">Description</span>,
      key: 'description',
      dataIndex: 'description',
      render: (text, record) =>
        record['description'] ? (
          <Tooltip placement="topLeft" title={record['description']}>
            {capitalize(record['description']).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: <span className="table-column-title">Amount</span>,
      key: 'formatted_amount',
      dataIndex: 'formatted_amount',
      align: 'center',
      render: function amountFunc(text, record) {
        return (
          <Tooltip
            title={`${record?.amount > 0 ? 'debit' : 'credit'}`}
            placement="top"
            className={`${record?.amount > 0 ? 'debit' : 'credit'}`}
          >
            {record['formatted_amount']
              ? `${
                  record?.amount > 0
                    ? `${capitalize(record['formatted_amount'])}`
                    : `(${capitalize(
                        record['formatted_amount'].replace('-', '')
                      )})`
                }`
              : '-'}
          </Tooltip>
        );
      },
    },
    {
      title: <span className="table-column-title">Status</span>,
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: function render(text, record) {
        return (
          <span className={`${getColorClass(record['status'])}`}>
            {capitalize(record['status'])}
          </span>
        );
      },
    },
  ];

  const getColorClass = status => {
    if (status === 'debit') {
      return 'tran-type-debit';
    } else if (status === 'credit') {
      return 'tran-type-credit';
    } else if (status === 'success') {
      return 'tran-status-success';
    }
  };

  const onClickRow = () => {};

  const onPageChange = newPage => {
    setPage(prevState => ({ ...prevState, page: newPage }));
  };

  const handleTableChange = pagination => {
    setLimit(pagination['pageSize']);
    setPage(pagination['current']);
  };

  return (
    <Card
      title="Transfer"
      className="overflow-x-auto"
      style={{ marginTop: 15 }}
    >
      <DataTable
        loading={loading}
        pagination={{
          limit,
          total,
          page,
          data,
          onChange: onPageChange,
        }}
        onClickRow={onClickRow}
        columns={tableColumns()}
        handleTableChange={handleTableChange}
      />
    </Card>
  );
}

export default withRouter(Transfer);
