import React, { useEffect } from 'react';
import DataTable from '../../components/DataTable';
import tableColumns from './columns';
import PropTypes from 'prop-types';
// import DummyData from "./dummyData.json";

function RecipientsTable({
  pagination,
  loading,
  dataSource,
  selectedClients,
  updateSelectedClients,
  updateExceptionClients,
  setClientPage,
  clientPage,
}) {
  const rowSelection = {
    selectedRowKeys: selectedClients.map(client => client['id']),
    onChange: (selectedRowKeys, selectedRows) => (
      updateSelectedClients(selectedRows),
      updateExceptionClients(
        dataSource.filter(el => !selectedRowKeys.includes(el.id))
      )
    ),
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    function handleScroll() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.querySelector('.ant-table-body');

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        return setClientPage(clientPage + 1);
      }
      return;
    }
    document
      .querySelector('.ant-table-body')
      .addEventListener('scroll', handleScroll);
    return () => {
      document
        .querySelector('.ant-table-body')
        .removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <DataTable
      rowKey={record => record['id'] || 'id'}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      loading={loading}
      data={dataSource}
      pagination={pagination}
      columns={tableColumns()}
      onClickRow={() => {}}
      rowSelectionFlag
    />
  );
}

RecipientsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  selectedClients: PropTypes.array,
  updateSelectedClients: PropTypes.func,
};

export default RecipientsTable;
