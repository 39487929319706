import { takeLatest, call, put } from 'redux-saga/effects';

import {
  searchVendors,
  searchVendorsFailed,
  searchVendorsSuccess,
  searchWorkers,
  searchWorkersSuccess,
  searchWorkersFailed,
  assignJobToWorker,
  assignJobToWorkerSuccess,
  assignJobToWorkerFailed,
  reassignJobToWorker,
  reassignJobToWorkerSuccess,
  reassignJobToWorkerFailed,
  unassignJobFromWorker,
  unassignJobFromWorkerSuccess,
  unassignJobFromWorkerFailed,
  updateJob,
  updateJobSuccess,
  updateJobFailed,
  enableCheckInOut,
  updateJobData,
  updateJobDataSuccess,
  updateJobDataFailed,
  deleteJob,
  deleteJobFailed,
  deleteJobSuccess,
  pauseJobs,
  pauseJobsSuccess,
  pauseJobsFailed,
  cancelRepeatingJob,
  cancelRepeatingJobSuccess,
  cancelRepeatingJobFailed,
  assignWorkerPerm,
  assignWorkerPermFailed,
  assignWorkerPermSuccess,
  getNotes,
  getNotesFailed,
  getNotesSuccess,
  getNewNotes,
  getNewNotesFailed,
  getNewNotesSuccess,
  updateNotes,
  updateNotesFailed,
  updateNotesSuccess,
  cancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailed,
  skipSubscription,
  skipSubscriptionSuccess,
  skipSubscriptionFailed,
  activeJob,
  activeJobSuccess,
  activeJobFailed,
  downloadCsvJobs,
  downloadCsvJobsSuccess,
  downloadCsvJobsFailed,
} from './reducers';
import request from '../../utils/apisauce';
// TODO: Remove below line, code crashes when we remove it, whereas this page does not need it.
import {} from '../../utils/helpers';

function* searchVendorsSaga({ payload }) {
  let action;
  try {
    const params = {
      service_date: payload.date,
      name: payload.keyword,
    };

    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/vendor_board/vendors`,
      params
    );
    action = searchVendorsSuccess(response);
  } catch (error) {
    action = searchVendorsFailed(error.message);
  }
  yield put(action);
}

function* searchWorkersSaga({ payload }) {
  let action;
  try {
    const params = {
      service_date: payload.date,
      all: true,
      // vendor_id: payload.vendor_id,
      worker_name: payload.keyword,
      query: payload.query,
    };

    if (!payload.vendor_id) {
      throw new Error('Please select vendor!');
    } else {
      let url = `/api/v1/admin/vendor_board/vendors/${payload.vendor_id}/vendor_users?all=${params.all}&service_date=${params.service_date}`;
      if (payload['query']) {
        url += `&query=${payload['query']}`;
      }
      const response = yield call(request, 'GET', url);
      if (
        response &&
        response['vendor_users'] &&
        response['vendor_users']['length']
      ) {
        response['vendor_users'] = response['vendor_users'].map(item => ({
          ...item,
          vendor_id: payload.vendor_id,
        }));
      }
      action = searchWorkersSuccess(response);
    }
  } catch (error) {
    action = searchWorkersFailed(error.message);
  }

  yield put(action);
}

function* assignJobToWorkerSaga({ payload }) {
  let action;
  let url = 'api/v1/admin/scheduler/assign';
  if (payload?.job_status === 'draft' || payload?.job_status === 'paused') {
    url = 'api/v1/admin/scheduler/assign_draft_pause';
  }
  try {
    const response = yield call(request, 'POST', url, payload.body);
    action = assignJobToWorkerSuccess({
      response,
      workerIndex: payload.workerIndex,
      sourceWorkerIndex: payload.sourceWorkerIndex,
    });
  } catch (error) {
    action = assignJobToWorkerFailed(error.message);
  }
  yield put(action);
}

function* reassignJobToWorkerSaga({ payload }) {
  let action;
  let url = 'api/v1/admin/scheduler/assign';
  if (payload?.job_status === 'draft' || payload?.job_status === 'paused') {
    url = 'api/v1/admin/scheduler/assign_draft_pause';
  }
  try {
    const response = yield call(request, 'POST', url, payload.body);
    action = reassignJobToWorkerSuccess({
      response,
      destIndex: payload.destIndex,
      srcIndex: payload.srcIndex,
    });
  } catch (error) {
    action = reassignJobToWorkerFailed(error.message);
  }
  yield put(action);
}

function* unassignJobFromWorkerSaga({ payload }) {
  let action;
  let url = 'api/v1/admin/scheduler/unassign_job';
  if (payload?.job_status === 'draft' || payload?.job_status === 'paused') {
    url = 'api/v1/admin/scheduler/unassign_draft_pause_job';
  }
  try {
    const response = yield call(request, 'POST', url, payload.body);
    action = unassignJobFromWorkerSuccess({
      response,
      workerIndex: payload.workerIndex,
    });
  } catch (error) {
    action = unassignJobFromWorkerFailed(error.message);
  }
  yield put(action);
}

function* assignWorkerPermSaga({ payload }) {
  let action;
  let previous_worker_id = payload.data.previous_worker_id;
  delete payload.data.previous_worker_id;
  let url = `api/v1/admin/scheduler/permanent_reassigned_job/${payload.id}`;
  if (payload.permanentReassignSelectedDay) {
    url = `api/v1/admin/scheduler/permanent_reassigned_job_day/${payload.id}`;
  }
  try {
    const response = yield call(request, 'POST', url, payload.data);
    action = assignWorkerPermSuccess({
      job: response?.job,
      previousWorkerId: previous_worker_id,
    });
  } catch (error) {
    action = assignWorkerPermFailed(error.message);
  }
  yield put(action);
}

const JobUpdateActionCheckIn = 'CHECKIN';
const JobUpdateActionCheckOut = 'CHECKOUT';
const JobUpdateActionCancel = 'CANCEL';
const JobUpdateActionSkip = 'SKIP';
const jobUpdateActionEndpoint = {
  [JobUpdateActionCheckIn]: 'check_in',
  [JobUpdateActionCheckOut]: 'check_out',
  [JobUpdateActionCancel]: 'cancel',
  [JobUpdateActionSkip]: 'skip',
};

function* updateJobSaga({ payload }) {
  let action;
  let params = {
    remove_assigned_worker_and_time: payload?.remove_assigned_worker_and_time,
  };
  if (payload.action === 'SkIP') {
    try {
      const response = yield call(
        request,
        'PUT',
        `api/v1/admin/jobs/${payload.id}/${
          jobUpdateActionEndpoint[payload.action]
        }`
      );
      action = updateJobSuccess({ response, action: payload.action });
    } catch (error) {
      action = updateJobFailed(error.message);
    }
  } else {
    try {
      const response = yield call(
        request,
        'PUT',
        `api/v1/admin/jobs/${payload.id}/${
          jobUpdateActionEndpoint[payload.action]
        }`,
        params
      );
      action = updateJobSuccess({
        response,
        action: payload.action,
        checkbox: payload.remove_assigned_worker_and_time,
      });
    } catch (error) {
      action = updateJobFailed(error.message);
    }
  }
  yield put(action);
}

function* getNotesSaga() {
  let action;
  try {
    const response = yield call(request, 'GET', `/api/v1/admin/admin_notes`);
    action = getNotesSuccess(response);
  } catch (error) {
    action = getNotesFailed(error.message);
  }
  yield put(action);
}

function* enableCheckInOutSaga({ payload }) {
  let action;

  let url = `/api/v1/admin/jobs/${payload.id}/toggle_check_in_out_access`;
  try {
    const response = yield call(request, 'PUT', url, payload.data);

    let job = response;
    action = updateJobDataSuccess({
      response: { job },
      type: 'single',
      id: payload.id,
    });
  } catch (error) {
    action = updateJobDataFailed(error.message);
  }
  yield put(action);
}

function* updateJobDataSaga({ payload }) {
  let action;

  let url = `/api/v1/jobs/${payload.id}`;
  if (payload.type === 'repeating') {
    url = `/api/v1/jobs/type/repeatings/${payload.id}/update_jobs`;
  }
  try {
    const response = yield call(request, 'PUT', url, payload.data);

    action = updateJobDataSuccess({
      response,
      type: payload.type,
      id: payload.id,
    });
  } catch (error) {
    action = updateJobDataFailed(error.message);
  }
  yield put(action);
}

function* getNewNotesSaga() {
  let action;
  try {
    const response = yield call(request, 'GET', `/api/v1/admin/admin_notes`);
    action = getNewNotesSuccess(response);
  } catch (error) {
    action = getNewNotesFailed(error.message);
  }
  yield put(action);
}

function* deleteJobSaga({ payload }) {
  let action;

  let path = `/api/v1/admin/jobs/${payload.id}`;

  try {
    const deletedJob = yield call(request, 'DELETE', path);
    action = deleteJobSuccess({
      type: payload.type,
      deletedJob,
    });
  } catch (error) {
    action = deleteJobFailed(error.message);
  }
  yield put(action);
}

function* updateNotesSaga({ payload }) {
  let action;

  const admin_note = {
    admin_note: {
      content: { ...payload },
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/admin_notes`,
      admin_note
    );
    action = updateNotesSuccess(response);
  } catch (error) {
    action = updateNotesFailed(error.message);
  }
  yield put(action);
}

function* pauseJobsSaga({ payload }) {
  let action;
  let params = {
    start_date: payload.start_date,
    end_date: payload.end_date,
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `api/v1/admin/repeating_jobs/${payload.id}/batch_pause`,
      params
    );
    action = pauseJobsSuccess(response);
  } catch (error) {
    action = pauseJobsFailed(error.message);
  }
  yield put(action);
}

function* cancelRepeatingJobSaga({ payload }) {
  let action;
  let path = `/api/v1/admin/repeating_jobs/${payload.id}/cancel`;
  try {
    const canceledJob = yield call(request, 'PATCH', path, {
      repeating_job: {
        canceled_at: payload.canceled_at,
      },
    });

    action = cancelRepeatingJobSuccess({
      id: payload.jobId,
      canceledJob: canceledJob?.repeating_job,
    });
  } catch (error) {
    action = cancelRepeatingJobFailed(error.message);
  }
  yield put(action);
}

function* cancelSubscriptionSaga({ payload }) {
  let action;
  let params = {
    start_date: payload.start_date,
    end_date: payload.end_date,
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `api/v1/admin/repeating_jobs/${payload.id}/batch_cancel`,
      params
    );
    action = cancelSubscriptionSuccess(response);
    yield put(action);
  } catch (error) {
    action = cancelSubscriptionFailed(error.message);
    yield put(action);
    return;
  }
}

function* skipSubscriptionSaga({ payload }) {
  let action;
  let params = { start_date: payload.start_date, end_date: payload.end_date };

  try {
    const response = yield call(
      request,
      'PATCH',
      `api/v1/admin/repeating_jobs/${payload.id}/batch_skip`,
      params
    );
    action = skipSubscriptionSuccess(response);
    yield put(action);
  } catch (error) {
    action = skipSubscriptionFailed(error.message);
    yield put(action);
    return;
  }
}

function* activeJobSaga({ payload }) {
  let action;

  try {
    const response = yield call(
      request,
      'PUT',
      `api/v1/admin/jobs/${payload.id}/active`,
      payload.data
    );
    action = activeJobSuccess(response);
  } catch (error) {
    action = activeJobFailed(error.message);
  }
  yield put(action);
}

function* downloadCsvJobsSaga() {
  let action;

  try {
    const response = yield call(
      request,
      'GET',
      `api/v1/admin/scheduler/download_csv`
    );
    action = downloadCsvJobsSuccess(response);
  } catch (error) {
    action = downloadCsvJobsFailed(error.message);
  }
  yield put(action);
}

export {
  JobUpdateActionCheckIn,
  JobUpdateActionCheckOut,
  JobUpdateActionCancel,
  JobUpdateActionSkip,
};

export default function* saga() {
  yield takeLatest(searchVendors, searchVendorsSaga);
  yield takeLatest(searchWorkers, searchWorkersSaga);
  yield takeLatest(assignJobToWorker, assignJobToWorkerSaga);
  yield takeLatest(reassignJobToWorker, reassignJobToWorkerSaga);
  yield takeLatest(unassignJobFromWorker, unassignJobFromWorkerSaga);
  yield takeLatest(assignWorkerPerm, assignWorkerPermSaga);
  yield takeLatest(updateJob, updateJobSaga);
  yield takeLatest(getNotes, getNotesSaga);
  yield takeLatest(getNewNotes, getNewNotesSaga);
  yield takeLatest(updateNotes, updateNotesSaga);
  yield takeLatest(enableCheckInOut, enableCheckInOutSaga);
  yield takeLatest(updateJobData, updateJobDataSaga);
  yield takeLatest(pauseJobs, pauseJobsSaga);
  yield takeLatest(cancelRepeatingJob, cancelRepeatingJobSaga);
  yield takeLatest(deleteJob, deleteJobSaga);
  yield takeLatest(cancelSubscription, cancelSubscriptionSaga);
  yield takeLatest(skipSubscription, skipSubscriptionSaga);
  yield takeLatest(activeJob, activeJobSaga);
  yield takeLatest(downloadCsvJobs, downloadCsvJobsSaga);
}
