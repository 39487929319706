import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

DeleteModal.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  visible: PropTypes.bool,
  selected: PropTypes.object,
};

function DeleteModal(props) {
  return (
    <Modal
      className="delete-user-modal"
      visible={props.visible}
      onCancel={props.onClose}
      onOk={props.onDelete}
      okText="Yes"
      destroyOnClose
    >
      {props.selected && (
        <>
          <h1 className="main-message pop-up-heading">Are you sure?</h1>
          <p className="pop-up-body">
            Do you really want to remove this admin? The process cannot be
            undone.
          </p>
        </>
      )}
    </Modal>
  );
}

export default DeleteModal;
