import React from 'react';
import DataTable from '../../components/DataTable';
import tableColumns from './columns';
import PropTypes from 'prop-types';
// import DummyData from "./dummyData.json";

function TransactionTable({ dataSource, setModalOpen, pagination, loading }) {
  return (
    <DataTable
      rowKey={record => record['id'] || 'id'}
      loading={loading}
      data={dataSource}
      columns={tableColumns(setModalOpen)}
      onClickRow={() => {}}
      pagination={pagination}
    />
  );
}

TransactionTable.propTypes = {
  dataSource: PropTypes.array,
  setModalOpen: PropTypes.func,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loading: PropTypes.bool,
};

export default TransactionTable;
