import React from 'react';

// import { capitalize } from '../../../utils/apisauce';
import {
  DeleteFilledIcon,
  EditFilledIcon,
} from '../../../components/Icons/Icons';

const TableColumns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.id - b.id,
    fixed: 'left',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
  },

  {
    title: 'Discounts',
    dataIndex: 'discount_percent',
    key: 'discount_percent',
    align: 'center',
    render: function status(_, record) {
      return record['discount_percent']
        ? record['discount_percent'] + '%'
        : '0%';
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: 'start_date',
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: 'end_date',
  },
  {
    title: 'Max Redemptions',
    dataIndex: 'max_redemptions',
    key: 'max_redemptions',
    align: 'center',
    render: value => value,
  },
  {
    title: 'Time Redemptions',
    dataIndex: 'times_redeemed',
    key: 'times_redeemed',
    align: 'center',
    render: value => value,
  },
  {
    title: 'Status',
    key: 'status',
    align: 'center',
    render: function status(_, record) {
      const colorVal = {
        active: '#6ABB98',
        inactive: '#F1B13B',
        suspended: '#DF5E67',
      };
      return (
        <span
          style={{
            color: record?.is_expired
              ? colorVal['inactive']
              : colorVal['active'],
            textTransform: 'capitalize',
          }}
        >
          {record?.is_expired ? 'Inactive' : 'Active'}
        </span>
      );
    },
  },
  {
    title: 'Actions',
    key: 'action',
    fixed: 'right',
    align: 'center',
    render: function action(text, record) {
      return (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || onDelete(record)}
          />
        </div>
      );
    },
  },
];

export default TableColumns;
