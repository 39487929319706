import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getCategories,
  getCategoriesFailed,
  getCategoriesSuccess,
  createCategory,
  createCategorySuccess,
  createCategoryFailed,
  updateCategory,
  updateCategorySuccess,
  updateCategoryFailed,
  deleteCategory,
  deleteCategoryFailed,
  deleteCategorySuccess,
} from './reducers';

const API_BASE_NAME = '/api/v1/admin/categories';

function* getCategoriesSaga({ payload }) {
  let action;
  let url = `${API_BASE_NAME}?category_type=${
    payload.activeTab === 'all' ? '' : payload.activeTab
  }`;
  try {
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    if (payload['all']) {
      url += `&all=${payload['all']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getCategoriesSuccess(response);
  } catch (error) {
    action = getCategoriesFailed(error.message);
  }
  yield put(action);
}

function* createCategorySaga({ payload }) {
  let action;
  try {
    const response = yield call(request, 'POST', `${API_BASE_NAME}`, {
      category: payload,
    });
    action = createCategorySuccess(response);
  } catch (error) {
    action = createCategoryFailed(error.message);
  }
  yield put(action);
}

function* updateCategorySaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `${API_BASE_NAME}/${payload.id}`,
      { category: payload }
    );
    action = updateCategorySuccess(response);
  } catch (error) {
    action = updateCategoryFailed(error.message);
  }
  yield put(action);
}

function* deleteCategorySaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `${API_BASE_NAME}/${payload}`
    );
    action = deleteCategorySuccess(response);
  } catch (error) {
    action = deleteCategoryFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getCategories, getCategoriesSaga);
  yield takeLatest(createCategory, createCategorySaga);
  yield takeLatest(updateCategory, updateCategorySaga);
  yield takeLatest(deleteCategory, deleteCategorySaga);
}
