import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import TableSearch from '../../components/TableSearch';
import {
  getClasses,
  createClass,
  updateClass,
  archiveClass,
  unArchiveClass,
  deleteClass,
  getClassById,
  updateUI,
  setCreatedFalse,
  classbulkDelete,
  classbulkArchive,
  classbulkUnarchive,
} from './reducers';
import 'react-phone-input-2/lib/style.css';
import TableColumns from './components/class-table';
import DeleteClass from './components/delete-class';
import ArchiveClass from './components/archive-class';
import UnarchiveClass from './components/unarchive-class';
import './style.css';

const handlerEdit = (selectedRow, history, getClassById) => {
  getClassById(null);
  history.push({ pathname: `/admin/list/classes/form/${selectedRow.id}` });
};

const handlerDelete = (uiState, selectedRow, deleteClass) => {
  deleteClass({
    ...uiState,
    selectedRow: selectedRow,
    modalOpened: 2,
  });
};

const handlerCreate = (history, getClassById) => {
  getClassById(null);
  history.push({ pathname: `/admin/list/classes/form/create` });
};

export function ClassesPage({
  loading,
  classes,
  getClasses,
  getClassById,
  // updateClass,
  archiveClass,
  unArchiveClass,
  deleteClass,
  uiState,
  updateUI,
  setCreatedFalse,
  classbulkDelete,
  classbulkArchive,
  classbulkUnarchive,
}) {
  const history = useHistory();
  const [queryString, updateQueryString] = useState('');
  const { limit, total, page, data } = classes;
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterTab, setFilterTab] = useState('active');

  const onSearch = () => {
    getClasses({ query: queryString, per: limit });
  };

  const handleTableChange = pagination => {
    getClasses({
      page: pagination['current'],
      per: pagination['pageSize'],
      query: queryString ? queryString : '',
      filterTab,
    });
  };

  const handlerArchive = selectedRow => {
    archiveClass(selectedRow);
  };
  const handlerUnarchive = selectedRow => {
    unArchiveClass(selectedRow);
  };

  useEffect(() => {
    setCreatedFalse();
  }, [setCreatedFalse]);
  useEffect(() => {
    getClasses({
      page: 1,
      per: 20,
      query: null,
      filterTab,
    });
  }, [getClasses]);

  const onSelectRecord = selectedRows => {
    setSelectedRows(selectedRows);
  };
  const handleRowSelect = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
    onSelectRecord(selectedRowKeys);
  };
  const bulkDeleteHandler = () => {
    classbulkDelete(selectedRows);
    setSelectedRows([]);
  };

  const bulkArchiveHandler = (uiState, selectedRow, classbulkArchive) => {
    classbulkArchive({
      ...uiState,
      selectedRow: selectedRow,
      modalOpened: 3,
    });
  };
  const bulkUnarchiveHandler = (uiState, selectedRows, classbulkUnarchive) => {
    classbulkUnarchive({
      ...uiState,
      selectedRow: selectedRows,
      modalOpened: 4,
    });
  };

  const setActiveTab = value => {
    setFilterTab(value);
    setSelectedRows([]);
    setSelectedRowKeys([]);
    getClasses({
      page: page ? page : 1,
      per: limit ? limit : 20,
      filterTab: value,
    });
  };

  return (
    <div className="products-page">
      <div className="page-header">
        <div className="page-title">
          <h4>Class</h4>
        </div>
        <div className="page-header-actions">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handlerCreate(history, getClassById)}
          >
            Add New Class
          </Button>
        </div>
      </div>
      <div className="page-content">
        <TableSearch
          placeholder="Search Class"
          btnText="Search"
          onBtnClick={onSearch}
          value={queryString}
          onInputChange={ev => updateQueryString(ev['target']['value'])}
        />
        <div className="page-header">
          <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    filterTab === 'active' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setActiveTab('active')}
                >
                  Active
                </div>
                <div
                  className={`ant-tabs-tab${
                    filterTab === 'archive' ? ' ant-tabs-tab-active ' : ''
                  }`}
                  onClick={() => setActiveTab('archive')}
                >
                  Archived
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Card>
          <div style={{ marginBottom: 16 }}>
            <Button
              className={`${
                selectedRows.length > 0 ? 'admin-products-page-btn' : ''
              }`}
              disabled={selectedRows.length === 0 ? true : false}
              onClick={() => bulkDeleteHandler(selectedRows)}
              style={{ marginRight: '5px' }}
            >
              Delete
            </Button>
            {filterTab === 'active' ? (
              <Button
                type="primary"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() =>
                  bulkArchiveHandler(uiState, selectedRows, updateUI)
                }
              >
                Archive
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={selectedRows.length === 0 ? true : false}
                onClick={() =>
                  bulkUnarchiveHandler(uiState, selectedRows, updateUI)
                }
              >
                Unachive
              </Button>
            )}
            <span style={{ marginLeft: 8 }}>
              {selectedRows.length !== 0
                ? `Selected ${selectedRows.length} items`
                : ''}
            </span>
          </div>
          <DataTable
            rowSelectionFlag={true}
            onSelectRecord={onSelectRecord}
            rowKey={record => record['id']}
            loading={loading}
            data={classes.products}
            columns={TableColumns(
              record => handlerEdit(record, history, getClassById),
              record => handlerDelete(uiState, record, updateUI),
              record => handlerArchive(record),
              record => handlerUnarchive(record)
            )}
            pagination={{
              limit,
              total,
              page,
              data,
              // onChange: onPageChange,
            }}
            rowSelection={{ selectedRowKeys, onChange: handleRowSelect }}
            handleTableChange={handleTableChange}
          />
          <DeleteClass
            uiState={uiState}
            updateUI={updateUI}
            deleteClass={deleteClass}
          />
          <ArchiveClass
            uiState={uiState}
            updateUI={updateUI}
            classbulkArchive={classbulkArchive}
            setSelectedRows={setSelectedRows}
          />
          <UnarchiveClass
            uiState={uiState}
            updateUI={updateUI}
            classbulkUnarchive={classbulkUnarchive}
            setSelectedRows={setSelectedRows}
          />
        </Card>
      </div>
    </div>
  );
}

ClassesPage.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.object,
  uiState: PropTypes.object,
  getClasses: PropTypes.func,
  createClass: PropTypes.func,
  updateClass: PropTypes.func,
  archiveClass: PropTypes.func,
  unArchiveClass: PropTypes.func,
  deleteClass: PropTypes.func,
  classbulkArchive: PropTypes.func,
  classbulkUnarchive: PropTypes.func,
  getClassById: PropTypes.func,
  updateUI: PropTypes.func,
  setCreatedFalse: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.classes.loading,
    classes: state.classes.classes,
    uiState: state.classes.uiState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClasses: keyword => dispatch(getClasses(keyword)),
    createClass: data => dispatch(createClass(data)),
    updateClass: data => dispatch(updateClass(data)),
    archiveClass: data => dispatch(archiveClass(data)),
    unArchiveClass: data => dispatch(unArchiveClass(data)),
    deleteClass: id => dispatch(deleteClass(id)),
    getClassById: id => dispatch(getClassById(id)),
    updateUI: payload => dispatch(updateUI(payload)),
    setCreatedFalse: data => dispatch(setCreatedFalse(data)),
    classbulkDelete: data => dispatch(classbulkDelete(data)),
    classbulkArchive: data => dispatch(classbulkArchive(data)),
    classbulkUnarchive: data => dispatch(classbulkUnarchive(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage);
