import React, { Fragment, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Col, Drawer, Row } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderLoadableComponent } from '../../../components/Loader';
import { getJobs, setModalJob, getJobById } from '../../JobPage/reducers';

const statusLabels = {
  pending_schedule: 'Unassigned',
  scheduled: 'In Scheduled',
  in_progress: 'In Progress',
  completed: 'Completed',
  cancelled: 'Cancelled/Skipped',
};
let load = true;
const JobDetailModal = props => {
  const {
    // modalLoading,
    modalJob,
    jobs,
    setModalJob,
    getJobById,
    jobDetailsModalClose,
  } = props;

  const [vendor, setVendor] = React.useState({});
  const [worker, setWorker] = React.useState({});
  const [modalState, setModalState] = React.useState({});

  useEffect(() => {
    // const jobId = parseInt(hash.substring(1, hash.length));
    if (!isNaN(props.jobID) && props.jobID !== '') {
      const job = jobs.data.find(x => x.id === props.jobID);
      if (job) {
        setModalJob(props.jobID);
      } else {
        getJobById(props.jobID);
      }
    }
    load = true;
  }, [getJobById, setModalJob, jobs, props.jobID, jobs.data]);

  useEffect(() => {
    if (modalJob) {
      const { vendor, assigned_worker } = modalJob;
      setModalState(modalJob);
      if (assigned_worker) {
        setWorker(assigned_worker);
      }
      if (vendor) {
        setVendor(vendor);
      }
      load = false;
    }
  }, [modalJob]);

  const calculate_active_job = modalJob => {
    if (modalJob?.checkin_time && modalJob?.checkout_time) {
      const startTime = moment(modalJob?.checkin_time, 'HH:mm:ss a');
      const endTime = moment(modalJob?.checkout_time, 'HH:mm:ss a');
      const { _data } = moment.duration(endTime.diff(startTime));
      return `${_data.hours}hr ${_data.minutes}min ${_data.seconds}sec`;
    } else {
      return '-';
    }
  };

  return (
    <Drawer
      closable={false}
      className="job-detail-drawer"
      placement="right"
      width={850}
      visible={true}
      onClose={() => jobDetailsModalClose()}
      destroyOnClose
    >
      {renderLoadableComponent(
        load,
        true && (
          <Fragment>
            <div className="drawer-header">
              <div className="header-info">
                <h3>Job Detail</h3>
                <div className="job-id">Job ID: {modalState.id}</div>
              </div>
              <div className="header-actions">
                <div className={`badge ${modalState.job_status}`}>
                  {statusLabels[modalState.job_status]}
                </div>
                <div className="header-actions">
                  <div
                    className={`badge ${
                      modalState.repeating_job_id ? 'repeating' : 'no-repeating'
                    }`}
                  >
                    {modalState['repeating_job_id']
                      ? 'Repeating'
                      : 'Non-repeating'}
                  </div>
                </div>
                <CloseOutlined
                  onClick={() => {
                    // history.goBack();
                    jobDetailsModalClose();
                  }}
                />
              </div>
            </div>
            <div className="drawer-body">
              <Row>
                <Col span={12}>
                  <label>Job Title</label>
                  <span>{modalState?.title}</span>
                </Col>
                <Col span={12}>
                  <label>Assigned Vendor</label>
                  {vendor
                    ? Object.keys(vendor).length > 0
                      ? `${vendor.vendor_name}`
                      : '-'
                    : ''}
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Scheduled Date</label>
                  <span>
                    {modalState?.service_date
                      ? moment(modalState.service_date, 'YYYY-MM-DD').format(
                          'DD/MM/YYYY'
                        )
                      : ''}{' '}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Assigned Worker</label>
                  <span>
                    {worker
                      ? Object.keys(worker).length > 0
                        ? `${worker?.first_name} ${worker?.last_name}`
                        : '-'
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Preferred Time</label>
                  <span>
                    {modalState['preferred_time']
                      ? modalState?.preferred_time
                      : '-'}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Assigned Time</label>
                  <span>
                    {modalState['assigned_time']
                      ? modalState['assigned_time']
                      : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Check-in Time</label>
                  <span>
                    {modalState?.checkin_time ? modalState.checkin_time : '-'}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Check-out Time</label>
                  <span>
                    {modalState?.checkout_time ? modalState.checkout_time : '-'}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <label>Service Duration</label>
                  <span>
                    {modalState?.job_duration_in_minutes
                      ? modalState.job_duration_in_minutes + ' minutes'
                      : '-'}
                  </span>
                </Col>
                <Col span={12}>
                  <label>Active Job Duration</label>
                  <span>{calculate_active_job(modalState)}</span>
                </Col>
              </Row>
            </div>
            <div className="drawer-footer">
              <Row>
                <Col span={12}>
                  <Row style={{ paddingTop: 0 }}>
                    <Col span={20}>
                      <label>Client Name</label>
                      <span>
                        {modalState?.client ? modalState.client.title : ''}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <label>Address</label>
                      <span>
                        {modalState?.address
                          ? `${modalState.address.address_title}, ${modalState.address.address}, ${modalState.address.unit_number}, ${modalState.address.postal_code}`
                          : '-'}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row style={{ paddingTop: 0 }}>
                    <Col span={20}>
                      <label>Job Notes</label>
                      <span>
                        {modalState['job_notes'] ? modalState['job_notes'] : ''}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={20}>
                      <label>Customer Notes</label>
                      <span>
                        {modalState['customer_notes']
                          ? modalState['customer_notes']
                          : ''}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Fragment>
        )
      )}
    </Drawer>
  );
};

JobDetailModal.propTypes = {
  modalLoading: PropTypes.bool,
  modalJob: PropTypes.object,
  jobs: PropTypes.object,
  isOpened: PropTypes.bool,
  setModalJob: PropTypes.func,
  getJobById: PropTypes.func,
  jobDetailsModalClose: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    jobs: state.jobs.jobs,
    modalLoading: state.jobs.modalLoading,
    modalJob: state.jobs.modalJob,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModalJob: job => dispatch(setModalJob(job)),
    getJobById: jobId => dispatch(getJobById(jobId)),
    getJobs: payload => dispatch(getJobs(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailModal);
