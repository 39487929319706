import React from 'react';
import {
  CloseOutlined,
  InboxOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Card,
  Modal,
  Drawer,
  DatePicker,
  message,
  Select,
  Tooltip,
  Row,
  Col,
  // TimePicker,
} from 'antd';
import ReactTimePicker from '../../../components/TimePicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataTable from '../../../components/DataTable';
import {
  getJobs,
  deleteJob,
  updateJob,
  cancelJob,
  updateDays,
} from '../reducers';
import JobForm from './form';
import {
  EditFilledIcon,
  DeleteFilledIcon,
} from '../../../components/Icons/Icons';
import { capitalize } from '../../../utils/apisauce';
import MobileTable from './MobTable';

// const statusLabels = {
//   pending_schedule: {
//     color: '#F1B13B',
//     label: 'Unassigned',
//   },
//   scheduled: {
//     color: '#34A6BD',
//     label: 'In Scheduled',
//   },
//   in_progress: {
//     color: '#6ABB98',
//     label: 'In Progress',
//   },
//   completed: {
//     color: '#828282',
//     label: 'Completed',
//   },
//   cancelled: {
//     color: '#DF5E67',
//     label: 'Cancelled/Skipped',
//   },
// };

class RepeatingJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpened: null,
      selectedRow: null,
      activeTab: 'active',
      formData: null,
      canceled_at: null,
      start_date: null,
      schedules: null,
      time: null,
      days: [],
      pageQuery: {
        page: 1,
        per: 20,
        query: null,
      },
      windowSize: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { getJobs, queryString, submitting, error } = this.props;
    const { pageQuery, activeTab } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getJobs({ ...pageQuery, query: queryString, activeTab });
      } else {
        getJobs({ ...pageQuery, activeTab });
      }
    }

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
  }

  handleWindowSize = () => {
    if (window.innerWidth < 767) {
      this.setState({ windowSize: true });
    } else {
      this.setState({ windowSize: false });
    }
  };

  componentDidMount() {
    const { getJobs, queryString } = this.props;
    const { pageQuery, activeTab } = this.state;
    if (window.innerWidth < 767) {
      this.handleWindowSize();
    }
    window.addEventListener('resize', this.handleWindowSize);
    if (queryString) {
      getJobs({ ...pageQuery, query: queryString, activeTab });
    } else {
      getJobs({ ...pageQuery, activeTab });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSize);
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
    });
  };

  onClickRow = (e, record) => {
    const {
      history: { push },
      setJobId,
    } = this.props;

    push(`/admin/jobs/${record.id}`);
    setJobId(record.id);
  };

  onDelete = record => {
    this.setState({
      modalOpened: 2,
      selectedRow: record,
    });
  };

  performDelete = () => {
    const { selectedRow, pageQuery, activeTab } = this.state;
    const { deleteJob, queryString } = this.props;

    if (queryString) {
      deleteJob({
        ...pageQuery,
        query: queryString,
        id: selectedRow.id,
        activeTab,
      });
    } else {
      deleteJob({ ...pageQuery, id: selectedRow.id, activeTab });
    }

    this.closeModal();
  };

  getDayStyle = (record, day) => {
    const style = {
      padding: '0 2px',
      fontWeight: 500,
    };

    if (record.days.includes(day)) {
      return {
        ...style,
        color: '#2D9CDB',
      };
    }

    return style;
  };

  cancelJob = record => {
    this.setState({
      modalOpened: 3,
      selectedRow: record,
    });
  };

  handleCancel = () => {
    const { selectedRow, canceled_at, pageQuery, activeTab } = this.state;
    const { cancelJob, queryString } = this.props;

    if (canceled_at !== null) {
      if (queryString) {
        cancelJob({
          ...pageQuery,
          query: queryString,
          id: selectedRow.id,
          canceled_at,
          activeTab,
        });
      } else {
        cancelJob({ ...pageQuery, id: selectedRow.id, canceled_at, activeTab });
      }
      this.closeModal();
    } else {
      message.error('Please select a date', 0);
    }
  };

  updateDays = record => {
    this.setState({
      modalOpened: 4,
      selectedRow: record,
      canceled_at: record?.start_date ? record.start_date : null,
      days: record?.days ? record.days : [],
      time: record?.job_details?.preferred_time
        ? record.job_details.preferred_time
        : [],
      schedules: record?.preferred_schedules
        ? record.preferred_schedules
        : record?.days.map(val => ({
            day: val,
            time: record?.job_details?.preferred_time
              ? record?.job_details.preferred_time
              : null,
          })),
    });
  };

  handleUpdateDays = () => {
    const { selectedRow, canceled_at, schedules, activeTab } = this.state;
    const { updateDays, jobs, pageQuery } = this.props;
    if (canceled_at === null) {
      message.error('Date Should Not Empty');
      return;
    }
    // if (schedules.length !== selectedRow.days_quantity_limit) {
    //   message.error(
    //     `You must select ${selectedRow.days_quantity_limit} job days`
    //   );
    //   return;
    // }
    updateDays({
      start_date: canceled_at,
      days: schedules,
      days_quantity_limit: selectedRow.days_quantity_limit,
      id: selectedRow.id,
      query: pageQuery,
      limit: jobs.limit,
      page: jobs?.page ? jobs.page : 1,
      activeTab,
    });
    this.closeModal();
  };

  tableColumns = () => [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
      width: 70,
      // defaultSortOrder: 'ascend',
      // sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Status',
      key: 'status',
      fixed: 'left',
      width: 140,
      align: 'center',
      render: function render(text, record) {
        if (record.status === 'active') {
          if (record.canceled_at) {
            return 'cancelling';
          } else {
            return record.status;
          }
        } else {
          return record.status;
        }
      },
    },
    {
      title: 'Title',
      key: 'title',
      width: 140,
      render: (_, record) =>
        record.job_details ? capitalize(record.job_details.title) : '-',
    },
    {
      title: 'Created By',
      key: 'created_by',
      width: 140,
      render: (_, record) =>
        record?.job_details?.creator_type
          ? record?.job_details?.creator_type
          : 'Order System',
    },
    {
      title: 'Subscription',
      key: 'subscription',
      width: 100,
      align: 'center',
      render: (_, record) => {
        if (!record?.job_details?.creator_type) {
          return <span style={{ color: '#059c5a' }}>Yes</span>;
        } else {
          return <span style={{ color: '#DF5E67' }}>No</span>;
        }
      },
    },
    {
      title: 'Client Name',
      key: 'client_name',

      render: (_, record) =>
        record.job_details && record.job_details.client
          ? record.job_details.client_type === 'PersonalClient'
            ? capitalize(record.job_details.client.title)
            : capitalize(record.job_details.client.business_name)
          : '-',
    },
    {
      title: 'Client Address',
      key: 'client_address',

      render: (_, record) =>
        record.job_details && record.job_details.address ? (
          <Tooltip
            title={`${record.job_details.address.address_title}, ${record.job_details.address.address}, ${record.job_details.address.unit_number}, ${record.job_details.address.postal_code}`}
            placement="top"
          >
            {capitalize(
              `${record.job_details.address.address_title}, ${record.job_details.address.address}, ${record.job_details.address.unit_number}, ${record.job_details.address.postal_code}`
            ).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'Vender Name',
      key: 'client_address',

      render: (_, record) =>
        record.job_details && record.job_details.vendor
          ? `${capitalize(record.job_details.vendor.vendor_name)}`
          : '-',
    },
    {
      title: 'Start Date',
      key: 'start_date',
      dataIndex: 'start_date',
      width: 120,
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => {
      //   const dateA = new Date(a.start_date).getTime();
      //   const dateB = new Date(b.start_date).getTime();
      //   return dateA - dateB;
      // },
      render: (_, record) =>
        moment(record.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: 'End Date',
      key: 'end_date',
      width: 120,
      render: (_, record) =>
        record.end_date
          ? moment(record.end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-',
    },
    {
      title: 'Days Limit',
      key: 'days_quantity_limit',
      width: 120,
      align: 'center',
      render: (_, record) =>
        `${record.days_quantity_limit ? record.days_quantity_limit : '-'}`,
    },
    {
      title: 'No. of Jobs',
      key: 'jobs_count',
      width: 120,
      align: 'center',
      render: (_, record) => `${record.jobs_count ? record.jobs_count : '-'}`,
    },
    {
      title: 'Term',
      key: 'term',
      width: 120,
      render: (_, record) =>
        `${record.term_value ? record.term_value : '-'} ${
          record.term_type ? record.term_type : ''
        }`,
    },
    {
      title: 'Frequency',
      key: 'frequency',
      width: 120,
      render: (_, record) => `Every ${record.frequency_value} week`,
    },
    // will uncomment this code when shi hao add jobs count in API
    // {
    //   title: 'Total Jobs',
    //   key: 'total_jobs',
    //   render: (_, record) => record.job_details.length,
    // },
    {
      title: 'Selected Days',
      key: 'days',
      width: 120,
      render: (_, record) => (
        <>
          <span style={this.getDayStyle(record, 'sunday')}>S</span>
          <span style={this.getDayStyle(record, 'monday')}>M</span>
          <span style={this.getDayStyle(record, 'tuesday')}>T</span>
          <span style={this.getDayStyle(record, 'wednesday')}>W</span>
          <span style={this.getDayStyle(record, 'thursday')}>T</span>
          <span style={this.getDayStyle(record, 'friday')}>F</span>
          <span style={this.getDayStyle(record, 'saturday')}>S</span>
        </>
      ),
    },
    // {
    //   title: 'Vendor Name',
    //   key: 'vendor_id',
    //   render: (_, record) =>
    //     record.jobs['length'] && record.jobs[0].vendor.name
    //       ? record.jobs[0].vendor.name
    //       : '-',
    // },
    // {
    //   title: 'Assigned Worker',
    //   key: 'assigned_worker',
    //   render: (_, record) =>
    //     record.jobs['length'] && record.jobs[0].assigned_worker
    //       ? `${record.jobs[0]['assigned_worker'].first_name} ${record.jobs[0]['assigned_worker'].last_name}`
    //       : '-',
    // },
    // {
    //   title: 'Preferred Time',
    //   key: 'preferred_time',
    //   render: (_, record) =>
    //     record.jobs['length'] && record.jobs[0].preferred_time
    //       ? record.jobs[0]['preferred_time']
    //       : '-',
    // },
    // {
    //   title: 'Assigned Time',
    //   key: 'assigned_time',
    //   render: (_, record) =>
    //     record.jobs['length'] && record.jobs[0].assigned_time
    //       ? record.jobs[0]['assigned_time']
    //       : '-',
    // },
    // {
    //   title: 'Check In',
    //   key: 'checkin_time',
    //   render: (_, record) =>
    //     record.jobs['length'] && record.jobs[0].checkin_time
    //       ? record.jobs[0].checkin_time
    //       : '-',
    // },
    // {
    //   title: 'Check Out',
    //   key: 'checkout_time',
    //   render: (_, record) =>
    //     record.jobs['length'] && record.jobs[0].checkout_time
    //       ? record.jobs[0].checkout_time
    //       : '-',
    // },
    // {
    //   title: 'Duration',
    //   key: 'job_duration_in_minutes',
    //   render: (_, record) =>
    //     record.jobs['length'] && record.jobs[0].job_duration_in_minutes
    //       ? record.jobs[0]['job_duration_in_minutes']
    //       : '-',
    // },
    // {
    //   title: 'Vendor Name',
    //   key: 'vendor',
    //   render: (_, record) =>
    //     record.jobs.length ? record.jobs[0].vendor.name : '',
    // },
    // {
    //   title: 'Subscription Terms',
    //   key: 'term',
    //   render: (_, record) => `${record.term_value} ${record.term_type}`,
    // },
    // {
    //   title: 'Job Frequency',
    //   key: 'frequency',
    //   render: (_, record) => `${record.frequency_value} times / week`,
    // },
    // {
    //   title: 'Start Date - End Date',
    //   key: 'date',
    //   render: (_, record) =>
    //     `${moment(record.start_date, 'YYYY-MM-DD').format(
    //       'DD/MM/YYYY'
    //     )} - ${moment(record['end_date'], 'YYYY-MM-DD').format('DD/MM/YYYY')}`,
    // },
    // {
    //   title: 'Total Jobs',
    //   key: 'total_jobs',
    //   render: (_, record) => record.jobs.length,
    // },
    // {
    //   title: 'Selected Days',
    //   key: 'days',
    //   render: (_, record) => (
    //     <>
    //       <span style={this.getDayStyle(record, 'sunday')}>S</span>
    //       <span style={this.getDayStyle(record, 'monday')}>M</span>
    //       <span style={this.getDayStyle(record, 'tuesday')}>T</span>
    //       <span style={this.getDayStyle(record, 'wednesday')}>W</span>
    //       <span style={this.getDayStyle(record, 'thursday')}>T</span>
    //       <span style={this.getDayStyle(record, 'friday')}>F</span>
    //       <span style={this.getDayStyle(record, 'saturday')}>S</span>
    //     </>
    //   ),
    // },
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (text, record) => (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || this.onDelete(record)}
          />
          <CloseOutlined
            onClick={e => e.stopPropagation() || this.cancelJob(record)}
          />
          <CalendarOutlined
            onClick={e => e.stopPropagation() || this.updateDays(record)}
          />
        </div>
      ),
    },
  ];
  onEdit = record => {
    let formData = {
      id: record.id,
      vendor_id: '',
      start_date: record.start_date,
      end_date: record.end_date,
      title: '',
      notes: '',
      description: '',
      job_duration_in_minutes: 0,
      frequency_value: record.frequency_value,
      frequency_type: record.frequency_type,
      preferred_time: '',
      assigned_time: '',
      assigned_worker_id: '',
      days: record['days'],
      client_type: '',
      job_status: record.status,
    };

    if (record['job_details']) {
      formData['title'] = record['job_details']['title'];
      formData['description'] = record['job_details']['description'];
      formData['notes'] = record['job_details']['job_notes'];
      formData['job_duration_in_minutes'] =
        record['job_details']['job_duration_in_minutes'];
    }
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      formData,
    });
  };

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  onSubmit = data => {
    const { updateJob } = this.props;
    const {
      pageQuery: { page, per },
      activeTab,
    } = this.state;
    let body;
    body = {
      start_date: data.start_date,
      end_date: data.end_date,
      title: data.title,
      description: data.description,
      job_notes: data.notes,
      job_duration_in_minutes: data.job_duration_in_minutes,
      frequency_value: data.frequency_value,
      client_type: 'PersonalClient',
      status: data?.job_status,
    };
    if (data?.vendor_id) {
      body['vendor_id'] = data.vendor_id;
    }
    if (data?.preferred_time) {
      body['preferred_time'] = data.preferred_time;
    }
    if (data?.assigned_time) {
      body['assigned_time'] = data.assigned_time;
    }
    if (data?.assigned_worker_id) {
      body['assigned_worker_id'] = data.assigned_worker_id;
    }
    if (data?.day) {
      body['day'] = data.day;
    }

    updateJob(data.id, body, page, per, activeTab);
    // this.closeModal();
  };

  disableFutureDates = current => {
    return current && current < moment().endOf('day');
  };

  setActiveTab = value => {
    this.setState(state => ({ ...state, activeTab: value }));
    const { getJobs, queryString, repeatingJobTabStateSet } = this.props;
    const { pageQuery } = this.state;
    repeatingJobTabStateSet({ value });
    if (queryString) {
      getJobs({ ...pageQuery, query: queryString, activeTab: value });
    } else {
      getJobs({ ...pageQuery, activeTab: value });
    }
  };

  handleDateChange = async date => {
    this.setState(prevState => ({
      ...prevState,
      canceled_at: moment(date).format('YYYY-MM-DD'),
    }));
  };
  render() {
    const { modalOpened, selectedRow, formData, windowSize } = this.state;
    const { loading, jobs } = this.props;
    const { limit, total, page, data } = jobs;

    return (
      <>
        <div className="page-header">
          <Row>
            <Col>
              <div className="ant-tabs-nav ant-tabs-nav-animated">
                <div
                  className={`ant-tabs-tab${
                    this.state.activeTab === 'active'
                      ? ' ant-tabs-tab-active '
                      : ''
                  }`}
                  onClick={() => this.setActiveTab('active')}
                >
                  Active
                </div>
                <div
                  className={`ant-tabs-tab${
                    this.state.activeTab === 'draft'
                      ? ' ant-tabs-tab-active '
                      : ''
                  }`}
                  onClick={() => this.setActiveTab('draft')}
                >
                  Draft
                </div>
                <div
                  className={`ant-tabs-tab${
                    this.state.activeTab === 'cancelling'
                      ? ' ant-tabs-tab-active '
                      : ''
                  }`}
                  onClick={() => this.setActiveTab('cancelling')}
                >
                  Cancelling
                </div>
                <div
                  className={`ant-tabs-tab${
                    this.state.activeTab === 'canceled'
                      ? ' ant-tabs-tab-active '
                      : ''
                  }`}
                  onClick={() => this.setActiveTab('canceled')}
                >
                  Cancelled
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {windowSize ? (
          <Card title="Repeating Jobs" className="overflow-x-auto">
            <MobileTable
              records={data}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              repeatingTab={true}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              cancelJob={this.cancelJob}
              updateDays={this.updateDays}
              getDayStyle={this.getDayStyle}
              onClickRow={this.onClickRow}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        ) : (
          <Card
            title="Repeating Jobs"
            className="overflow-x-auto"
            // style={{ marginTop: 15 }}
          >
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              columns={this.tableColumns()}
              onClickRow={this.onClickRow}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        )}
        <Drawer
          closable={false}
          placement="right"
          width={450}
          className="job-create-drawer"
          visible={modalOpened === 1}
          onClose={this.closeModal}
          destroyOnClose
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Edit Repeating Job details</h3>
                <div className="header-actions">
                  <DeleteFilledIcon
                    className="action-primary"
                    onClick={() => this.onDelete(formData)}
                  />
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <JobForm
                  data={formData}
                  type="repeating"
                  formStatus="edit"
                  onSubmit={this.onSubmit}
                />
              </div>
            </>
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <h1 className="main-message pop-up-heading">Are you sure?</h1>
              <p className="pop-up-body">
                Do you really want to remove this job? The process cannot be
                undone.
              </p>
            </>
          )}
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 3}
          onCancel={this.closeModal}
          onOk={this.handleCancel}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <div className="user-info">
                <Avatar icon={<InboxOutlined />} size={72} />
                <div className="username">
                  {selectedRow.jobs && selectedRow.jobs.length
                    ? selectedRow.jobs[0].title
                    : ''}
                </div>
                <p>Job ID:&nbsp;{selectedRow.id}</p>
                <p>
                  Please choose the date from where you want to cancel the job.
                </p>
                <DatePicker
                  disabledDate={this.disableFutureDates}
                  onChange={this.handleDateChange}
                />
              </div>
              <p className="main-message">
                Are you sure want to cancel this job? Once done, It cannot be
                reversed.
              </p>
            </>
          )}
        </Modal>

        <Modal
          className="delete-user-modal"
          visible={modalOpened === 4}
          onCancel={this.closeModal}
          onOk={this.handleUpdateDays}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <div className="user-info">
                <Avatar icon={<InboxOutlined />} size={72} />
                <div className="username">
                  {selectedRow.jobs && selectedRow.jobs.length
                    ? selectedRow.jobs[0].title
                    : ''}
                </div>
                <p>Job ID:&nbsp;{selectedRow.id}</p>
                <p>
                  Please choose the date from where you want to update the job.
                </p>
                <Row gutter={[10, 8]} style={{ padding: '10px 0' }}>
                  <Col span={24}>
                    <DatePicker
                      value={
                        this.state.canceled_at
                          ? moment(this.state.canceled_at, 'YYYY-MM-DD')
                          : null
                      }
                      disabledDate={this.disableFutureDates}
                      onChange={this.handleDateChange}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={[10, 8]}>
                  {this.state?.schedules?.length > 0 &&
                    this.state.schedules?.map((value, index) => (
                      <>
                        {' '}
                        <Col span={12} key={index}>
                          <Select
                            // mode="multiple"
                            name="day_name"
                            value={value?.day}
                            style={{
                              width: '100%',
                            }}
                            placeholder="Select day"
                            onChange={value => {
                              const arrc = [...this.state.schedules];
                              arrc[index].day = value;
                              this.setState({ schedules: arrc });
                            }}
                          >
                            <Select.Option value="monday">Monday</Select.Option>
                            <Select.Option value="tuesday">
                              Tuesday
                            </Select.Option>
                            <Select.Option value="wednesday">
                              Wednesday
                            </Select.Option>
                            <Select.Option value="thursday">
                              Thursday
                            </Select.Option>
                            <Select.Option value="friday">Friday</Select.Option>
                            <Select.Option value="saturday">
                              Saturday
                            </Select.Option>
                            <Select.Option value="sunday">Sunday</Select.Option>
                          </Select>
                        </Col>
                        <Col span={12} key={index}>
                          {/* <TimePicker
                            name="from"
                            format="HH:00"
                            placeholder="Select start time"
                            onChange={(date, timeString) => {
                              const arr = [...this.state.schedules];
                              arr[index].time = timeString;
                              this.setState({ schedules: arr });
                            }}
                            onSelect={date => {
                              const arr = [...this.state.schedules];
                              arr[index].time = moment(date, 'h:mm A').format(
                                'HH:mm'
                              );
                              this.setState({ schedules: arr });
                            }}
                            value={
                              value?.time ? moment(value.time, 'HH:00') : null
                            }
                            style={{ width: '100%' }}
                          /> */}
                          <ReactTimePicker
                            name="time"
                            item={value}
                            cahngeFormTime={(item, timeString) => {
                              const arr = [...this.state.schedules];
                              arr[index].time = timeString;
                              this.setState({ schedules: arr });
                            }}
                            placeholder="Select start time"
                            showMinutes
                          />
                        </Col>
                      </>
                    ))}
                </Row>

                {/* <Select
                  mode="multiple"
                  value={this.state.days}
                  style={{ width: '50%' }}
                  placeholder="Select days"
                  onChange={value => {
                    const limit =
                      this.state.selectedRow.days_quantity_limit || 1;
                    if (this.state.days.length < limit) {
                      this.setState(state => ({
                        ...state,
                        days: [...value],
                      }));
                    }
                  }}
                >
                  <Select.Option value="monday">Monday</Select.Option>
                  <Select.Option value="tuesday">Tuesday</Select.Option>
                  <Select.Option value="wednesday">Wednesday</Select.Option>
                  <Select.Option value="thursday">Thursday</Select.Option>
                  <Select.Option value="friday">Friday</Select.Option>
                  <Select.Option value="saturday">Saturday</Select.Option>
                  <Select.Option value="sunday">Sunday</Select.Option>
                </Select> */}
              </div>
              <p className="main-message">
                Are you sure you want to update the Job days?
              </p>
            </>
          )}
        </Modal>
      </>
    );
  }
}

RepeatingJobs.propTypes = {
  loading: PropTypes.bool,
  jobs: PropTypes.object,
  getJobs: PropTypes.func,
  deleteJob: PropTypes.func,
  updateJob: PropTypes.func,
  history: PropTypes.object,
  queryString: PropTypes.string,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  cancelJob: PropTypes.func,
  updateDays: PropTypes.func,
  setJobId: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.jobs.loading,
    jobs: state.jobs.jobs,
    submitting: state.jobs.submitting,
    error: state.jobs.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJobs: payload => dispatch(getJobs({ type: 'repeating', ...payload })),
    deleteJob: payload =>
      dispatch(deleteJob({ type: 'repeating', ...payload })),
    updateJob: (id, data, page, per, activeTab) =>
      dispatch(
        updateJob({ id, data, page, per, activeTab, type: 'repeating' })
      ),
    cancelJob: payload =>
      dispatch(
        cancelJob({
          type: 'repeating',
          ...payload,
        })
      ),
    updateDays: payload =>
      dispatch(
        updateDays({
          id: payload.id,
          type: 'repeating',
          start_date: payload.start_date,
          days: payload.days,
          days_quantity_limit: payload.days_quantity_limit,
          query: payload.query,
          per: payload.limit,
          page: payload.page,
          activeTab: payload.activeTab,
        })
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RepeatingJobs));
