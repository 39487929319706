import { createSlice } from '@reduxjs/toolkit';
// import { message, notification } from "antd";

import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorDashboard',
  initialState: {
    getjobFeedCountLoading: false,
    getJobStatisticsCountLoading: false,
    getWorkStatusLoading: false,
    getJobFeedLoading: false,
    getWorkerLocationLoading: true,
    vendorOrderStatsLoading: false,
    getJobsLoading: true,
    jobFeedCount: {
      total_count: 0,
      completed_count: 0,
      remaining_count: 0,
    },
    jobStatisticsCount: {
      unassigned_jobs_count: 0,
      assigned_vendor_count: 0,
      cancelled_and_skipped_jobs_count: 0,
    },
    orderStatics: {
      total_count: 0,
      shipped_count: 0,
      completed_count: 0,
    },
    revenueStatics: {
      current_balance: 0,
      current_month_revenue: 0,
    },
    workStatus: [],
    jobFeeds: [],
    workerLocation: [],
    jobs: [],
  },
  reducers: {
    getJobFeedCount: state => {
      state.getjobFeedCountLoading = true;
    },
    getJobFeedCountSuccess: (state, { payload }) => {
      state.getjobFeedCountLoading = false;
      state.jobFeedCount = payload;
    },
    getJobFeedCountFailed: (state, { payload }) => {
      state.getjobFeedCountLoading = false;
      state.jobFeedCount = {
        total_count: 0,
        completed_count: 0,
        remaining_count: 0,
      };
      apiError(payload);
    },
    getOrderStats: state => {
      state.vendorOrderStatsLoading = true;
    },
    getOrderStatsSuccess: (state, { payload }) => {
      state.vendorOrderStatsLoading = false;
      state.orderStatics.total_count = payload?.order?.total_count
        ? payload.order.total_count
        : 0;
      state.orderStatics.shipped_count = payload?.order?.shipped_count
        ? payload.order.shipped_count
        : 0;
      state.orderStatics.completed_count = payload?.order?.completed_count
        ? payload.order.completed_count
        : 0;

      state.revenueStatics.current_balance = payload?.revenue?.current_balance
        ? payload.revenue.current_balance
        : 0;
      state.revenueStatics.current_month_revenue = payload?.revenue
        ?.current_month_revenue
        ? payload.revenue.current_month_revenue
        : 0;
    },
    getOrderStatsFailed: (state, { payload }) => {
      state.vendorOrderStatsLoading = false;
      console.log('payload of orders is', payload);
      state.orderStatics = {
        total_count: 0,
        shipped_count: 0,
        completed_count: 0,
      };
      state.revenueStatics = {
        current_balance: 0,
        current_month_revenue: 0,
      };
    },
    getJobStatisticsCount: state => {
      state.getJobStatisticsCountLoading = true;
    },
    getJobStatisticsCountSuccess: (state, { payload }) => {
      state.getJobStatisticsCountLoading = false;
      state.jobStatisticsCount = payload;
    },
    getJobStatisticsCountFailed: (state, { payload }) => {
      state.getJobStatisticsCountLoading = false;
      state.jobStatisticsCount = {
        unassigned_jobs_count: 0,
        assigned_vendor_count: 0,
        cancelled_and_skipped_jobs_count: 0,
      };
      apiError(payload);
    },
    getWorkStatus: state => {
      state.getWorkStatusLoading = true;
    },
    getWorkStatusSuccess: (state, { payload }) => {
      state.getWorkStatusLoading = false;
      state.workStatus = payload['job_details'];
    },
    getWorkStatusFailed: (state, { payload }) => {
      state.getWorkStatusLoading = false;
      state.workStatus = [];
      apiError(payload);
    },
    getJobFeed: state => {
      state.getJobFeedLoading = true;
    },
    getJobFeedSuccess: (state, { payload }) => {
      state.getJobFeedLoading = false;
      state.jobFeeds = payload['job_event_logs'];
    },
    getJobFeedFailed: (state, { payload }) => {
      state.getJobFeedLoading = false;
      apiError(payload);
    },
    getWorkerLocation: state => {
      state.getWorkerLocationLoading = true;
    },
    getWorkerLocationSuccess: (state, { payload }) => {
      state.getWorkerLocationLoading = false;
      state.workerLocation = payload['vendor_users'];
    },
    getWorkerLocationFailed: (state, { payload }) => {
      state.getWorkerLocationLoading = false;
      apiError(payload);
    },
    getJobs: state => {
      state.getJobsLoading = true;
    },
    getJobsSuccess: (state, { payload }) => {
      state.getJobsLoading = false;
      state.jobs = payload['jobs'];
    },
    getJobsFailed: (state, { payload }) => {
      state.getJobsLoading = false;
      apiError(payload);
    },
  },
});

export const {
  getJobFeedCount,
  getJobFeedCountSuccess,
  getJobFeedCountFailed,
  getJobStatisticsCount,
  getJobStatisticsCountSuccess,
  getJobStatisticsCountFailed,
  getWorkStatus,
  getWorkStatusSuccess,
  getWorkStatusFailed,
  getJobFeed,
  getJobFeedSuccess,
  getJobFeedFailed,
  getWorkerLocation,
  getWorkerLocationFailed,
  getWorkerLocationSuccess,
  getJobs,
  getJobsFailed,
  getJobsSuccess,
  getOrderStats,
  getOrderStatsSuccess,
  getOrderStatsFailed,
} = slice.actions;

export default slice.reducer;
