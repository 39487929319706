import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const ColumnLink = ({ href, onClick, text }) => (
  <a href={href} className="link" onClick={onClick}>
    {text}
  </a>
);

ColumnLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.number,
};

// const getColorClass = status => {
//   if (status === 'in_cart') {
//     return 'color-gray';
//   } else if (status === 'active') {
//     return 'color-yellow';
//   } else if (status === 'complete') {
//     return 'color-green';
//   }
// };

const orderTableColumns = (showOrderItemsModal, setModalOpen) => [
  {
    title: <span className="table-column-title">Order ID</span>,
    key: 'id',
    dataIndex: 'id',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.id - b.id,
    render: (text, record) => record['id'],
  },
  {
    title: <span className="table-column-title">Create Date</span>,
    key: 'created_at',
    dataIndex: 'created_at',
    render: (text, record) =>
      record['created_at']
        ? `${new Date(record['created_at']).toLocaleString()}`
        : '',
  },
  {
    title: <span className="table-column-title">Order Title</span>,
    key: 'title',
    dataIndex: 'title',
    render: (text, record) => record['title'] || '-',
  },
  {
    title: <span className="table-column-title">Order Description</span>,
    key: 'description',
    dataIndex: 'discription',
    render: (text, record) => record['description'] || '-',
  },
  {
    title: <span className="table-column-title">Order Type</span>,
    key: 'order_type',
    render: (text, record) => record['order_type'],
  },
  {
    title: <span className="table-column-title">Client Type</span>,
    key: 'buyer_type',
    render: (text, record) => record['buyer_type'],
  },
  {
    title: <span className="table-column-title">Client Name</span>,
    key: 'clientName',
    render: (text, record) =>
      record['buyer']
        ? `${record['buyer']['first_name']} ${record['buyer']['last_name']}`
        : '',
  },
  {
    title: <span className="table-column-title">Order Item(s)</span>,
    key: 'order_items',
    render: function orderItems(text, record) {
      return (
        <ColumnLink
          href="#/"
          onClick={() => showOrderItemsModal(record)}
          text={record['concierge_order_items'].length}
        />
      );
    },
  },
  {
    title: <span className="table-column-title">Amount</span>,
    key: 'amount',
    render: (text, record) => record['formatted_amount'],
  },
  {
    title: <span className="table-column-title">Tax Amount</span>,
    key: 'tax_amount',
    render: (text, record) => record['formatted_tax_amount'],
  },
  {
    title: <span className="table-column-title">Net Amount</span>,
    key: 'net_amount',
    render: (text, record) => record['formatted_net_amount'],
  },
  {
    title: <span className="table-column-title">Job ID</span>,
    key: 'jobId',
    className: 'concierge',
    render: (text, record) => (record['jobId'] ? record['jobId'] : 'NA'),
  },
  {
    title: <span className="table-column-title">Transaction ID</span>,
    key: 'transactionId',
    render: function transactionId(text, record) {
      return (
        <ColumnLink
          href="#/"
          onClick={() => (setModalOpen ? setModalOpen(true) : null)}
          text={
            record['accounting_transaction']
              ? record['accounting_transaction']['uuid']
              : ''
          }
        />
      );
    },
  },
  {
    title: <span className="table-column-title">Order Status</span>,
    key: 'orderStatus',
    render: (text, record) => record['display_status'],
  },
];

orderTableColumns.prototype = {
  setModalOpen: PropTypes.bool,
  showOrderItemsModal: PropTypes.func,
};

const orderItemsTableColumns = () => [
  {
    title: <span className="table-column-title">ID</span>,
    key: 'id',
    render: (text, record) => record['item']['id'],
  },
  {
    title: <span className="table-column-title">Item Name</span>,
    key: 'title',
    render: (text, record) => record['item']['title'],
  },
  {
    title: <span className="table-column-title">Description</span>,
    key: 'description',
    render: (text, record) => record['item']['description'],
  },
  {
    title: <span className="table-column-title">Unit</span>,
    key: 'unit',
    render: (text, record) => record['item']['unit'],
  },
  {
    title: <span className="table-column-title">Quantity</span>,
    key: 'quantity',
    dataIndex: 'quantity',
    render: (text, record) => record['quantity'],
  },
  {
    title: <span className="table-column-title">Amount Rate</span>,
    key: 'amount',
    render: (text, record) => record['item']['formatted_amount'],
  },
];

const conciergeorderItemsTableColumns = () => [
  {
    title: <span className="table-column-title">ID</span>,
    key: 'id',
    render: (text, record) => record['id'],
  },
  {
    title: <span className="table-column-title">Order Title</span>,
    key: 'title',
    render: (text, record) => record['title'],
  },
  {
    title: <span className="table-column-title">Order Description</span>,
    key: 'description',
    render: (text, record) => record['description'],
  },
  {
    title: <span className="table-column-title">Job Created? </span>,
    key: 'is_create_job',
    render: (text, record) => (record['is_create_job'] ? 'Yes' : 'No'),
  },
  {
    title: <span className="table-column-title">Job Notes </span>,
    key: 'job_notes',
    render: (text, record) => record['job_notes'],
  },
  {
    title: <span className="table-column-title"> Preferred Time</span>,
    key: 'preferred_time',
    render: (text, record) =>
      record['preferred_time'] ? record['preferred_time'] : '-',
  },
  {
    title: <span className="table-column-title">Service Date</span>,
    key: 'service_date',
    render: (text, record) =>
      record.service_date
        ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : '-',
  },
  {
    title: <span className="table-column-title">Unit</span>,
    key: 'unit',
    render: (text, record) => record['unit'],
  },
  {
    title: <span className="table-column-title">Amount</span>,
    key: 'amount',
    render: (text, record) => record['amount'],
  },
];

export {
  orderTableColumns,
  orderItemsTableColumns,
  conciergeorderItemsTableColumns,
};
