import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getQuoteRequest,
  getQuoteRequestFailed,
  getQuoteRequestSuccess,
  updateQuoteRequest,
  updateQuoteRequestSuccess,
  updateQuoteRequestFailed,
} from './reducers';

const API_BASE_NAME = '/api/v1/admin/quote_requests';

function* getQuoteRequestSaga({ payload }) {
  let action;
  let url = `${API_BASE_NAME}?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }

    const response = yield call(request, 'GET', url);
    action = getQuoteRequestSuccess(response);
  } catch (error) {
    action = getQuoteRequestFailed(error.message);
  }
  yield put(action);
}

function* updateQuoteRequestSaga({ payload }) {
  let action;
  const { id, status } = payload;
  try {
    const response = yield call(request, 'PUT', `${API_BASE_NAME}/${id}`, {
      status: status,
    });
    action = updateQuoteRequestSuccess({ response });
  } catch (error) {
    action = updateQuoteRequestFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getQuoteRequest, getQuoteRequestSaga);
  yield takeLatest(updateQuoteRequest, updateQuoteRequestSaga);
}
