import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_SERVICES = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'product',
  initialState: {
    loading: false,
    created: false,
    submitting: false,
    services: DEFAULT_SERVICES,
    media_library_images: { data: [] },
    error: '',
    formObject: null,
    uiState: {
      modalOpened: false,
      selectedRow: null,
    },
  },
  reducers: {
    getMedia: state => {
      state;
    },
    getMediaSuccess: (state, { payload }) => {
      state.media_library_images = {
        data: payload.library_images,
      };
    },
    getMediaFailed: state => {
      state;
    },
    getServices: state => {
      state.loading = true;
    },
    getServicesSuccess: (state, { payload }) => {
      state.loading = false;
      state.services = {
        data: payload.services,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getServicesFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    setCreatedFalse: state => {
      state.created = false;
    },
    createService: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Creating a new service ...', 0);
    },
    createServiceSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.service;
      state.loading = false;
      state.created = true;
      state.submitting = false;
      state.services.data.push(payload.service);

      notification.success({
        message: 'New Service',
        description: `New Service ${payload.service.title} has been created.`,
      });
    },
    createServiceFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.created = false;
      state.loading = false;
      apiError(payload);
    },
    updateService: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Updating service info...', 0);
    },
    updateServiceSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.service;
      state.loading = false;
      state.submitting = false;
      const idx = state.services.data.findIndex(
        el => el.id === payload.service.id
      );
      if (idx >= 0) {
        state.services[idx] = payload.service;
      }
      notification.success({
        message: 'Service Updated',
        description: `Service ${payload.service.title} has been updated.`,
      });
    },
    updateServiceFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    archiveService: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Archiving service info...', 0);
    },
    archiveServiceSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.service;
      state.loading = false;
      state.submitting = false;
      const idx = state.services.data.findIndex(
        el => el.id === payload.service.id
      );
      if (idx >= 0) {
        state.services.data.splice(idx, 1);
      }
      notification.success({
        message: 'Service Archived',
        description: `Service ${payload.service.title} has been Archived.`,
      });
    },
    archiveServiceFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    unArchiveService: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Unarchiving service info...', 0);
    },
    unArchiveServiceSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.service;
      state.loading = false;
      state.submitting = false;
      const idx = state.services.data.findIndex(
        el => el.id === payload.service.id
      );
      if (idx >= 0) {
        state.services.data.splice(idx, 1);
      }
      notification.success({
        message: 'Service Unarchived',
        description: `Service ${payload.service.title} has been unarchived.`,
      });
    },
    unArchiveServiceFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    servicebulkDelete: state => {
      state.message = message.loading('Deleting Services ...', 0);
    },
    servicebulkDeleteSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.services.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.services.data = updateArr;
      notification.success({
        message: 'Services Deleted',
        description: `Selected Services ${payload.ids} has been deleted.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    servicebulkDeleteFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    servicebulkArchive: state => {
      state.message = message.loading('Archiving Services ...', 0);
    },
    servicebulkArchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.services.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.services.data = updateArr;
      notification.success({
        message: 'Services Archived',
        description: `Selected Services ${payload.ids} has been Archived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    servicebulkArchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    servicebulkUnarchive: state => {
      state.message = message.loading('Unarchiving Services ...', 0);
    },
    servicebulkUnarchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.services.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.services.data = updateArr;
      notification.success({
        message: 'Services Unarchived',
        description: `Selected Services ${payload.ids} has been Unarchived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    servicebulkUnarchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    deleteService: state => {
      state.message = message.loading('Deleting service ...', 0);
    },
    deleteServiceSuccess: (state, { payload }) => {
      state.message();
      state.services.data = state.services.data.filter(
        x => x.id !== payload.product.id
      );
      notification.success({
        message: 'Service Deleted',
        description: `Service ${payload.product.title} has been deleted.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    deleteServiceFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    getServiceById: (state, { payload }) => {
      state.formObject = payload;
      //TODO: move code from product-create to here
    },
    updateUI: (state, { payload }) => {
      state.uiState = { ...payload };
    },
  },
});

export const {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  setCreatedFalse,
  getServices,
  getServicesFailed,
  getServicesSuccess,
  createService,
  createServiceSuccess,
  createServiceFailed,
  updateService,
  updateServiceSuccess,
  updateServiceFailed,
  archiveService,
  archiveServiceFailed,
  archiveServiceSuccess,
  unArchiveService,
  unArchiveServiceFailed,
  unArchiveServiceSuccess,
  deleteService,
  deleteServiceFailed,
  deleteServiceSuccess,
  getServiceById,
  updateUI,
  servicebulkDelete,
  servicebulkDeleteSuccess,
  servicebulkDeleteFailed,
  servicebulkArchive,
  servicebulkArchiveSuccess,
  servicebulkArchiveFailed,
  servicebulkUnarchive,
  servicebulkUnarchiveSuccess,
  servicebulkUnarchiveFailed,
} = slice.actions;
export default slice.reducer;
