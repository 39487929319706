import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  otpCheck,
  otpCheckSuccess,
  otpCheckFailed,
  login,
  loginSuccess,
  loginFailed,
  getProfile,
  getProfileSuccess,
  getProfileFailed,
} from './reducers';

function* profileSaga() {
  let action;
  try {
    const response = yield call(request, 'GET', '/api/v1/profile');

    action = getProfileSuccess(response);
  } catch (error) {
    action = getProfileFailed(error.message);
  }
  yield put(action);
}

function* otpCheckSaga({ payload }) {
  let action;

  try {
    const response = yield call(
      request,
      'POST',
      'api/v1/admin/auth/sessions/verify',
      payload
    );
    action = otpCheckSuccess(response);
  } catch (e) {
    action = otpCheckFailed(e.message);
  }
  yield put(action);
}

function* loginSaga({ payload }) {
  const admin_user = {
    login: payload.username,
  };

  const body = {
    admin_user,
  };

  let action;
  try {
    const response = yield call(
      request,
      'POST',
      'api/v1/admin/auth/sessions',
      body
    );
    action = loginSuccess(response);
  } catch (e) {
    action = loginFailed(e.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(otpCheck, otpCheckSaga);
  yield takeLatest(login, loginSaga);
  yield takeLatest(getProfile, profileSaga);
}
