import React from 'react';
import { CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Form, Select } from 'antd';
import PropTypes from 'prop-types';
import AvatarUpload from '../AvatarUpload';
import { Formik } from 'formik';
import * as Yup from 'yup';

const { Option, OptGroup } = Select;
const CAT_TYPES = ['PRODUCT', 'CLASSES', 'SERVICE', 'SUBSCRIPTION'];

const CreateSubCategory = ({
  categories,
  closeModal,
  onSubmit,
  loading,
  disabled,
  categoryDefaultValue,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please Enter Name'),
    category_id: Yup.string().required('Please Select Category'),
    status: Yup.string().required('Please Select Status'),
  });
  const defaultValue = categories.find(val => val.id === categoryDefaultValue);

  return (
    <>
      <div className="drawer-header">
        <h3>{'Add Sub Category'}</h3>
        <div className="header-actions">
          <CloseOutlined onClick={closeModal} />
        </div>
      </div>
      <div className="drawer-body">
        <Formik
          initialValues={{
            image: null,
            name: '',
            category_id: categoryDefaultValue,
            status: 'active',
          }}
          validationSchema={validationSchema}
          //   validationSchema={formValidations['CreateVendorFormValidation']}
          onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
        >
          {({ errors, touched, handleSubmit, values, handleChange }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="user-avatar">
                  <AvatarUpload
                    value={values.image}
                    icon={<UserOutlined />}
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="form-group">
                  <label>Sub Category Name</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['name'] && errors['name'] ? 'error' : null
                    }
                    help={touched['name'] && errors['name']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="name"
                      value={values.name}
                      placeholder="Enter Sub Category Name"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Category</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['category_id'] && errors['category_id']
                        ? 'error'
                        : null
                    }
                    help={touched['category_id'] && errors['category_id']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      placeholder="Select Category"
                      style={{ width: '100%' }}
                      defaultValue={() => {
                        return defaultValue ? defaultValue.title : '';
                      }}
                      onChange={value =>
                        handleChange({
                          target: { name: 'category_id', value },
                        })
                      }
                      onClear={() => {}}
                    >
                      {CAT_TYPES.map(cat => (
                        <OptGroup label={cat}>
                          {categories &&
                            categories.map((cItem, index) => {
                              return (
                                cItem.category_type.toLowerCase() ===
                                  cat.toLowerCase() && (
                                  <Option key={index} value={cItem.id}>
                                    {cItem.title}
                                  </Option>
                                )
                              );
                            })}
                        </OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['status'] && errors['status'] ? 'error' : null
                    }
                    help={touched['status'] && errors['status']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      placeholder="Select status"
                      style={{ width: '100%' }}
                      value={values.status}
                      onChange={value =>
                        handleChange({
                          target: { name: 'status', value },
                        })
                      }
                    >
                      <Select.Option value="active">Active</Select.Option>
                      <Select.Option value="inactive">Inactive</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={disabled}
                >
                  Save
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

CreateSubCategory.prototype = {
  formData: PropTypes.object,
  closeModal: PropTypes.func,
  // onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  categoryDefaultValue: PropTypes.number,
  // handleChange: PropTypes.func,
};
export default CreateSubCategory;
