import { create } from 'apisauce';
import qs from 'query-string';
import UnProcessedError from './unprocessed_error';
import { useEffect, useState } from 'react';

const baseURL = process.env.REACT_APP_BASE_URL;
const client = create({ baseURL });
const INTERNAL_SERVER_ERROR =
  'we are unable to process your request at this time, please contact our support.';

const getClientApk = async () => {
  const url = `https://${window.location.hostname}/clientapp.apk`;
  const options = { method: 'HEAD' };
  try {
    const response = await fetch(url, options);
    return {
      apk: url,
      version: response.headers.get('last-modified'),
    };
  } catch (error) {
    return {
      apk: null,
      version: null,
    };
  }
};

const getWorkerApk = async () => {
  const url = `https://${window.location.hostname}/workerapp.apk`;
  const options = { method: 'HEAD' };
  try {
    const response = await fetch(url, options);
    return {
      apk: url,
      version: response.headers.get('last-modified'),
    };
  } catch (error) {
    return {
      apk: null,
      version: null,
    };
  }
};

const getImageURI = imagePath => {
  if (!imagePath) {
    return undefined;
  }
  return `${baseURL}${imagePath}`;
};

const checkToken = () => {
  const token = localStorage.getItem('access_token');
  if (!token) {
    return false;
  }
  client.setHeader('Authorization', 'Bearer ' + token);
  return true;
};

const checkVendorToken = () => {
  const token = localStorage.getItem('vendor_access_token');
  if (!token) {
    return false;
  }
  client.setHeader('Authorization', 'Bearer ' + token);
  return true;
};

const checkImpVendorToken = () => {
  const token = localStorage.getItem('imp_vendor_access_token');
  if (!token) {
    return false;
  }
  client.setHeader('Authorization', 'Bearer ' + token);
  return true;
};

const logout = () => {
  localStorage.removeItem('access_token');
  return window.location.replace('/admin/login');
};

const vendorLogout = () => {
  let keysToRemove = ['vendor_access_token', 'vendor_name', 'routes'];
  keysToRemove.forEach(k => localStorage.removeItem(k));
  return window.location.replace('/vendor/login');
};

const impersonateExit = () => {
  let keysToRemove = ['imp_vendor_access_token', 'vendor_name', 'routes'];
  keysToRemove.forEach(k => localStorage.removeItem(k));
  return window.location.replace('/admin/vendors');
};

const capitalize = input => {
  if (
    input !== null &&
    input !== '' &&
    input !== undefined &&
    typeof input === 'string'
  ) {
    var words = input?.split('_');
    var CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(
        element[0]?.toUpperCase() + element?.slice(1, element?.length)
      );
    });
    return CapitalizedWords?.join(' ');
  }
  input = '';
  return input;
};

const request = async (method, path, data) => {
  let response = undefined;
  try {
    if (
      method === 'GET' &&
      (typeof data === 'object' || typeof data === 'string')
    ) {
      path += '?' + qs.stringify(data);
    }
    if (method !== 'GET') {
      response = await client[method.toLowerCase()](path, data);
    } else {
      response = await client[method.toLowerCase()](path);
    }
  } catch (error) {
    response = error.response;
  }
  if (response.status === 400) {
    let resp = JSON.stringify(response.data.errors[0]);
    throw Object(JSON.parse(resp));
  }
  if (response.status >= 400) {
    if (response.status === 401) {
      return logout();
    }
    if (response.status === 422) {
      throw new UnProcessedError(response.data.errors);
    }
    if (response.status === 404) {
      throw Error(response.data.error);
    }
    if (response.status === 500) {
      // internal server error
      if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
        throw Error(response.data.error);
      } else {
        throw Error(INTERNAL_SERVER_ERROR);
      }
    }
    if (response.status === 403) {
      throw Error(`${response.data.errors}`);
    }
    throw Error(`Unhandled Error: ${response.data}`);
  }
  if (response.problem) {
    throw Error(response.problem);
  }
  return response.data;
};

function useWindowSize() {
  const [windowSize, updateWindowSize] = useState(false);
  useEffect(() => {
    function handleWindowSize() {
      if (window.innerWidth < 767) {
        updateWindowSize(true);
      } else {
        updateWindowSize(false);
      }
    }
    if (window.innerWidth < 767) {
      this.handleWindowSize();
    }
    window.addEventListener('resize', handleWindowSize);
    return () => {
      window.removeEventListener('resize', handleWindowSize);
    };
  }, []);
  return windowSize;
}

export {
  checkToken,
  checkVendorToken,
  checkImpVendorToken,
  logout,
  vendorLogout,
  impersonateExit,
  getImageURI,
  getWorkerApk,
  getClientApk,
  capitalize,
  useWindowSize,
};
export default request;
