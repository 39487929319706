import React from 'react';
import DataTable from '../../components/DataTable';
import {
  orderItemsTableColumns,
  conciergeorderItemsTableColumns,
} from './columns';
import PropTypes from 'prop-types';

function OrderItemsTable({ dataSource, pagination, loading, footer }) {
  return (
    <DataTable
      rowKey={record => record['id'] || 'id'}
      loading={loading}
      data={dataSource}
      columns={
        dataSource[0]?.item
          ? orderItemsTableColumns()
          : conciergeorderItemsTableColumns()
      }
      onClickRow={() => {}}
      pagination={pagination}
      footer={footer}
    />
  );
}

OrderItemsTable.propTypes = {
  dataSource: PropTypes.array,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loading: PropTypes.bool,
  footer: PropTypes.func,
};

export default OrderItemsTable;
