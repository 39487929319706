import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const INIT_FORM = {
  id: null,
  title: null,
};

const DEFAULT_SUBCATEGORRIES = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'subcategories',
  initialState: {
    submitting: false,
    loading: false,
    subCategories: DEFAULT_SUBCATEGORRIES,
    subCategoriesByFilter: null,
    categories: [],
    error: null,
    formData: INIT_FORM,
  },
  reducers: {
    getCategories: state => {
      state.loading = true;
    },
    getCategoriesSuccess: (state, { payload }) => {
      state.loading = false;

      state.categories = payload.categories;
    },
    getCategoriesFailed: state => {
      state.loading = false;
    },
    getSubCategories: (state, { page }) => {
      state.loading = true;
      state.subCategories.page = page || 1;
    },
    getSubCategoriesSuccess: (state, { payload }) => {
      state.loading = false;

      state.subCategories = {
        data: payload.sub_categories,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getSubCategoriesFailed: state => {
      state.loading = false;
    },
    getSubCategoriesByFilter: state => {
      state.loading = true;
    },
    getSubCategoriesByFilterSuccess: (state, { payload }) => {
      state.loading = false;
      state.subCategoriesByFilter = payload.sub_categories;
    },
    getSubCategoriesByFilterFailed: state => {
      state.loading = false;
    },
    createSubCategory: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Creating a new subCategory ...', 0);
    },
    createSubCategorySuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      const subCategory = payload.sub_category;

      if (state.subCategories?.data?.length > 0) {
        state.subCategories.data.push({
          ...subCategory,
        });
      } else {
        state.subCategories.data = [...subCategory];
      }
      state.formData = {
        category_id: null,
        title: null,
        status: null,
      };
      notification.success({
        message: 'New SubCategory',
        description: `New SubCategory ${subCategory.title} has been created.`,
      });
    },
    createSubCategoryFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateSubCategory: state => {
      state.submitting = true;
      state.message = message.loading('Updating subCategory info...', 0);
    },
    updateSubCategorySuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      const subCategory = payload.sub_category;
      const idx = state.subCategories.data.findIndex(
        el => el.id === subCategory.id
      );
      if (idx >= 0) {
        state.subCategories.data[idx] = {
          ...state.subCategories.data[idx],
          ...subCategory,
        };
      }
      state.formData = {
        id: null,
        title: null,
      };
      notification.success({
        message: 'SubCategory Updated',
        description: `SubCategory ${subCategory.title} has been updated.`,
      });
    },
    updateSubCategoryFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    deleteSubCategory: state => {
      state.message = message.loading('Deleting subCategory ...', 0);
    },
    deleteSubCategorySuccess: (state, { payload }) => {
      state.message();
      const subCategory = payload.sub_category;
      state.subCategories.data = state.subCategories.data.filter(
        x => x.id !== subCategory.id
      );
      notification.success({
        message: 'Delete SubCategory',
        description: `SubCategory ${subCategory.title} have been deleted.`,
      });
    },
    deleteSubCategoryFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    formUpdate: (state, { payload }) => {
      const formData = { ...state.formData };
      const { name, value } = payload;
      formData[name] = value;
      state.formData = formData;
    },
    editSubCategory: (state, { payload }) => {
      const formData = { ...payload };
      state.formData = formData;
    },
  },
});

export const {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailed,
  getSubCategories,
  getSubCategoriesSuccess,
  getSubCategoriesFailed,
  getSubCategoriesByFilter,
  getSubCategoriesByFilterSuccess,
  getSubCategoriesByFilterFailed,
  createSubCategory,
  createSubCategorySuccess,
  createSubCategoryFailed,
  updateSubCategory,
  updateSubCategorySuccess,
  updateSubCategoryFailed,
  deleteSubCategory,
  deleteSubCategorySuccess,
  deleteSubCategoryFailed,
  formUpdate,
  editSubCategory,
} = slice.actions;

export default slice.reducer;
