import { createSlice } from '@reduxjs/toolkit';
import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'orderDetail',
  initialState: {
    loading: false,
    orders: {},
    jobs: [],
  },
  reducers: {
    getOrders: state => {
      state.loading = true;
    },
    getOrdersSuccess: (state, { payload }) => {
      state.loading = false;
      state.orders = payload?.order;
    },
    getOrdersFailed: (state, { payload }) => {
      state.loading = false;
      apiError(payload);
    },
    getJob: state => {
      state.loading = true;
    },
    getJobSuccess: (state, { payload }) => {
      state.loading = false;
      delete payload.job.job_type;
      payload.job.job_type = 'single';
      if (payload.job) {
        state.jobs = [payload.job];
      } else {
        state.jobs = [payload];
      }
    },
    getJobFailed: state => {
      state.loading = false;
      state.jobs = [];
    },
  },
});

export const {
  getOrders,
  getOrdersFailed,
  getOrdersSuccess,
  getJob,
  getJobSuccess,
  getJobFailed,
} = slice.actions;

export default slice.reducer;
