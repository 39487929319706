import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getProducts,
  getProductsFailed,
  getProductsSuccess,
  archiveProduct,
  archiveProductFailed,
  archiveProductSuccess,
  unArchiveProduct,
  unArchiveProductFailed,
  unArchiveProductSuccess,
  createProduct,
  createProductSuccess,
  createProductFailed,
  updateProduct,
  updateProductSuccess,
  updateProductFailed,
  deleteProduct,
  deleteProductFailed,
  deleteProductSuccess,
  productbulkDelete,
  productbulkDeleteSuccess,
  productbulkDeleteFailed,
  productbulkArchive,
  productbulkArchiveSuccess,
  productbulkArchiveFailed,
  productbulkUnarchive,
  productbulkUnarchiveSuccess,
  productbulkUnarchiveFailed,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=list`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getProductsSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/products?';
  try {
    if (payload['filterTab']) {
      url += `status=${payload['filterTab']}`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getProductsSuccess(response);
  } catch (error) {
    action = getProductsFailed(error.message);
  }
  yield put(action);
}

function* archiveProductSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/products/${id}/archive`
    );

    action = archiveProductSuccess(response);
  } catch (error) {
    action = archiveProductFailed(error.message);
  }
  yield put(action);
}

function* unArchiveProductSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/products/${id}/unarchive`
    );

    action = unArchiveProductSuccess(response);
  } catch (error) {
    action = unArchiveProductFailed(error.message);
  }
  yield put(action);
}

function* createProductSaga({ payload }) {
  let action;

  payload.items_attributes.map(({ image }, i) => {
    payload.items_attributes[i].images = image;
    delete payload.items_attributes[i].image;
    return null;
  });

  try {
    const response = yield call(request, 'POST', `/api/v1/admin/products`, {
      product: payload,
    });
    action = createProductSuccess(response);
  } catch (error) {
    action = createProductFailed(error.message);
  }
  yield put(action);
}

function* updateProductSaga({ payload }) {
  let action;

  payload.items_attributes.map(({ image }, i) => {
    payload.items_attributes[i].images = image;
    delete payload.items_attributes[i].image;
    return null;
  });

  try {
    const id = payload.id;
    delete payload.id;
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/products/${id}`,
      { product: payload }
    );

    action = updateProductSuccess(response);
  } catch (error) {
    action = updateProductFailed(error.message);
  }
  yield put(action);
}

function* deleteProductSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/products/${payload.id}`
    );

    action = deleteProductSuccess(response);
  } catch (error) {
    action = deleteProductFailed(error.message);
  }
  yield put(action);
}

function* productbulkDeleteSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/products/bulk_destroy`,
      product
    );
    action = productbulkDeleteSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = productbulkDeleteFailed(error.message);
  }
  yield put(action);
}

function* productbulkArchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/products/bulk_archive`,
      product
    );
    action = productbulkArchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = productbulkArchiveFailed('');
  }
  yield put(action);
}

function* productbulkUnarchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/products/bulk_unarchive`,
      product
    );
    action = productbulkUnarchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = productbulkUnarchiveFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getProducts, getProductsSaga);
  yield takeLatest(archiveProduct, archiveProductSaga);
  yield takeLatest(unArchiveProduct, unArchiveProductSaga);
  yield takeLatest(createProduct, createProductSaga);
  yield takeLatest(updateProduct, updateProductSaga);
  yield takeLatest(deleteProduct, deleteProductSaga);
  yield takeLatest(productbulkDelete, productbulkDeleteSaga);
  yield takeLatest(productbulkArchive, productbulkArchiveSaga);
  yield takeLatest(productbulkUnarchive, productbulkUnarchiveSaga);
}
