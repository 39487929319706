import React from 'react';
import PropTypes from 'prop-types';

function FeedCount(props) {
  const { title, count } = props;
  return (
    <div className="feed-count-container">
      <h3 className="feed-count-title">{title}</h3>
      <p className="feed-count">{count}</p>
    </div>
  );
}

FeedCount.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
};

export default FeedCount;
