import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getOrders,
  getOrdersFailed,
  getOrdersSuccess,
  getJob,
  getJobSuccess,
  getJobFailed,
} from './reducers';

function* getOrdersSaga({ payload }) {
  let action;
  let url = `/api/v1/admin/orders/${payload.id}`;

  if (payload['page']) {
    url += `?page=${payload['page']}`;
  }
  if (payload['per'] && payload['page']) {
    url += `?per=${payload['per']}`;
  }
  if (payload['per']) {
    url += `&per=${payload['per']}`;
  }

  try {
    const response = yield call(request, 'GET', url);
    action = getOrdersSuccess(response);
  } catch (error) {
    action = getOrdersFailed(error.message);
  }
  yield put(action);
}

function* getJobSaga({ payload }) {
  let action;
  let url;
  if (payload?.type === 'single') {
    url = `/api/v1/jobs/${payload.id}`;
  } else {
    url = `/api/v1/jobs/type/repeatings/${payload.id}`;
  }
  try {
    const response = yield call(request, 'GET', url);
    action = getJobSuccess(response);
  } catch (error) {
    action = getJobFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getOrders, getOrdersSaga);
  yield takeLatest(getJob, getJobSaga);
}
