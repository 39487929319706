import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getServices,
  getServicesFailed,
  getServicesSuccess,
  createService,
  createServiceSuccess,
  createServiceFailed,
  updateService,
  updateServiceSuccess,
  updateServiceFailed,
  archiveService,
  archiveServiceFailed,
  archiveServiceSuccess,
  unArchiveService,
  unArchiveServiceFailed,
  unArchiveServiceSuccess,
  deleteService,
  deleteServiceFailed,
  deleteServiceSuccess,
  servicebulkDelete,
  servicebulkDeleteSuccess,
  servicebulkDeleteFailed,
  servicebulkArchive,
  servicebulkArchiveSuccess,
  servicebulkArchiveFailed,
  servicebulkUnarchive,
  servicebulkUnarchiveSuccess,
  servicebulkUnarchiveFailed,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=list`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getServicesSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/services?';
  try {
    if (payload['filterTab']) {
      url += `status=${payload['filterTab']}`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getServicesSuccess(response);
  } catch (error) {
    action = getServicesFailed(error.message);
  }
  yield put(action);
}

function* createServiceSaga({ payload }) {
  let action;

  try {
    const response = yield call(request, 'POST', `/api/v1/admin/services`, {
      service: payload,
    });

    action = createServiceSuccess(response);
  } catch (error) {
    action = createServiceFailed(error.message);
  }
  yield put(action);
}

function* updateServiceSaga({ payload }) {
  let action;

  try {
    const id = payload.id;
    delete payload.id;
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/services/${id}`,
      { service: payload }
    );
    action = updateServiceSuccess(response);
  } catch (error) {
    action = updateServiceFailed(error.message);
  }
  yield put(action);
}

function* archiveServiceSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/services/${id}/archive`
    );

    action = archiveServiceSuccess(response);
  } catch (error) {
    action = archiveServiceFailed(error.message);
  }
  yield put(action);
}

function* unArchiveServiceSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/services/${id}/unarchive`
    );

    action = unArchiveServiceSuccess(response);
  } catch (error) {
    action = unArchiveServiceFailed(error.message);
  }
  yield put(action);
}

function* deleteServiceSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/products/${payload.id}`
    );

    action = deleteServiceSuccess(response);
  } catch (error) {
    action = deleteServiceFailed(error.message);
  }
  yield put(action);
}

function* servicebulkDeleteSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/products/bulk_destroy`,
      product
    );
    action = servicebulkDeleteSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = servicebulkDeleteFailed(error.message);
  }
  yield put(action);
}

function* servicebulkArchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const service = {
    service: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/services/bulk_archive`,
      service
    );
    action = servicebulkArchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = servicebulkArchiveFailed('');
  }
  yield put(action);
}

function* servicebulkUnarchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const service = {
    service: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/services/bulk_unarchive`,
      service
    );
    action = servicebulkUnarchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = servicebulkUnarchiveFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getServices, getServicesSaga);
  yield takeLatest(createService, createServiceSaga);
  yield takeLatest(updateService, updateServiceSaga);
  yield takeLatest(archiveService, archiveServiceSaga);
  yield takeLatest(unArchiveService, unArchiveServiceSaga);
  yield takeLatest(deleteService, deleteServiceSaga);
  yield takeLatest(servicebulkDelete, servicebulkDeleteSaga);
  yield takeLatest(servicebulkArchive, servicebulkArchiveSaga);
  yield takeLatest(servicebulkUnarchive, servicebulkUnarchiveSaga);
}
