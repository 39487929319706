import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getVendors,
  getVendorsSuccess,
  getVendorsFailed,
  createVendor,
  createVendorSuccess,
  createVendorFailed,
  updateVendor,
  updateVendorSuccess,
  updateVendorFailed,
  deleteVendor,
  deleteVendorSuccess,
  deleteVendorFailed,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=profile`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getVendorSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/vendors';
  try {
    const queries = {
      page: payload['page'],
      per: payload['per'],
      query: payload['query'],
    };
    const response = yield call(request, 'GET', url, queries);
    action = getVendorsSuccess(response);
  } catch (error) {
    action = getVendorsFailed(error.message);
  }
  yield put(action);
}

function* createVendorSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/vendors`,
      payload
    );
    action = createVendorSuccess(response);
  } catch (error) {
    action = createVendorFailed(error.message);
  }
  yield put(action);
}

function* updateVendorSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/vendors/${payload.id}`,
      payload
    );
    action = updateVendorSuccess(response);
  } catch (error) {
    action = updateVendorFailed(error.message);
  }
  yield put(action);
}

function* deleteVendorSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/vendors/${payload}`
    );
    action = deleteVendorSuccess(response);
  } catch (error) {
    action = deleteVendorFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getVendors, getVendorSaga);
  yield takeLatest(createVendor, createVendorSaga);
  yield takeLatest(updateVendor, updateVendorSaga);
  yield takeLatest(deleteVendor, deleteVendorSaga);
}
