import React from 'react';
import PropTypes from 'prop-types';
import { dig } from 'digdata';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Modal } from 'antd';

import { getImageURI } from '../../../utils/apisauce';

DeleteModal.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  visible: PropTypes.bool,
  selected: PropTypes.object,
};

function DeleteModal(props) {
  return (
    <Modal
      className="delete-user-modal"
      visible={props.visible}
      onCancel={props.onClose}
      onOk={props.onDelete}
      okText="Yes"
      destroyOnClose
    >
      {props.selected && (
        <>
          <div className="user-info">
            <Avatar
              src={getImageURI(dig(props.selected, 'picture_path.url'))}
              icon={<UserOutlined />}
              size={72}
            />
            <div className="username">
              {props.selected.first_name}&nbsp;{props.selected.last_name}
            </div>
            <span>Employee ID:&nbsp;{props.selected.id}</span>
          </div>
          <p className="main-message">
            Are you sure want to remove this admin from database?
          </p>
          <p>This action can not be undone</p>
        </>
      )}
    </Modal>
  );
}

export default DeleteModal;
