import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  // TimePicker,
  // Switch,
  Checkbox,
  InputNumber,
  Form,
  message,
} from 'antd';
import ReactTimePicker from '../../../components/TimePicker';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import request from '../../../utils/apisauce';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import SelectVendor from '../../../components/SelectVendor';
import SelectVendorWorker from '../../../components/SelectVendorWorker';

const { Option } = Select;

class JobForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      formData: { assigned_worker_id: null, ...props.data },
      clients: [],
      vendors: [],
      vendor: null,
      getVendorsLoader: false,
      getVendorLoader: false,
      checked: false,
    };
  }

  getDayFromDate = dateString =>
    moment(dateString).format('dddd').toLowerCase();

  componentDidUpdate(prevProps) {
    const { data, type, formStatus } = this.props;
    if (data && data !== prevProps['data']) {
      if (type === 'repeating' && formStatus === 'edit') {
        this.setState({ formData: { ...data } });
      } else {
        this.setState({
          formData: {
            ...data,
            perpetual: true,
            days: [this.getDayFromDate(data.start_date)],
            days_quantity_limit: 3,
          },
        });
      }
    }
  }

  componentDidMount() {
    const { data, type, formStatus } = this.props;

    // this.searchVendors();

    if (data) {
      // if (data['vendor_id']) {
      //   this.getVendor(data['vendor_id']);
      // }

      if (type === 'repeating' && formStatus === 'edit') {
        this.setState({ formData: { ...data } });
      } else {
        this.setState({
          formData: {
            ...data,
            perpetual: true,
            days: [this.getDayFromDate(data.start_date)],
            days_quantity_limit: 3,
          },
        });
      }
    }

    return this.searchClients();
  }

  onInputChange = ({ name, value, dayName }) => {
    const { formData } = this.state;
    let updatedFormData = { ...formData };
    if (name === 'subsHours') {
      const { subsMin } = this.getPreferredTime(
        updatedFormData['preferred_time']
      );
      updatedFormData['preferred_time'] = `${value}:${subsMin}`;
    } else if (name === 'subsMin') {
      const { subsHours } = this.getPreferredTime(
        updatedFormData['preferred_time']
      );
      updatedFormData['preferred_time'] = `${subsHours}:${value}`;
    } else {
      updatedFormData[name] = value;
    }
    if (name === 'client_type') {
      updatedFormData.client_id = undefined;
      updatedFormData.address_id = undefined;
      this.setState({
        clients: [],
      });
    } else if (name === 'client_id') {
      updatedFormData.address_id = undefined;
    } else if (name === 'vendor_id') {
      updatedFormData.assigned_worker_id = null;
      updatedFormData.assigned_time = '';
      this.setState({ getVendorLoader: true });
      // this.getVendor(value);
    } else if (name === 'start_date') {
      updatedFormData.days = [dayName];
    } else if (name === 'days_quantity_limit') {
      updatedFormData.days = [
        moment(formData['start_date']).format('dddd').toLowerCase(),
      ];
    } else if (name === 'assigned_worker_id') {
      if (value === null) {
        updatedFormData.assigned_worker_id = null;
        updatedFormData.assigned_time = '';
      }
    }

    this.setState(
      {
        formData: updatedFormData,
      },
      () => {
        if (name === 'client_type') {
          return this.searchClients();
        }
      }
    );
  };

  // getVendor = async id => {
  //   try {
  //     const { vendor } = await request('GET', `/api/v1/admin/vendors/${id}`);
  //     this.setState({ vendor, getVendorLoader: false });
  //   } catch (error) {
  //     this.setState({ getVendorLoader: false });
  //   }
  // };

  handleStateChange = ({ name, value }) => {
    let updatedState = { ...this.state };
    updatedState[name] = value;
    this.setState(updatedState);
  };
  handleCheckChange = e => {
    this.setState(state => ({ ...state, checked: e.target.checked }));
  };

  onSearchClients = query => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      return this.searchClients(query);
    }, 500);
  };

  // onSearchVendor = ev => {
  //   this.setState({ getVendorLoader: true });
  //   const url = '/api/v1/admin/vendors?query=' + ev;
  //   try {
  //     if (this.timeout) {
  //       clearTimeout(this.timeout);
  //     }

  //     this.timeout = setTimeout(async () => {
  //       const { vendors } = await request('GET', url);
  //       this.setState({ vendors, getVendorLoader: false });
  //     }, 500);
  //   } catch (error) {
  //     console.log(error);
  //     this.setState({ getVendorLoader: false });
  //   }
  // };

  searchClients = async query => {
    const { formData } = this.state;

    let url = `/api/v1/clients/${formData.client_type}`;
    if (query) {
      url += '?query=' + query;
    }

    const clients = await request('GET', url);
    this.setState({
      clients: clients['personal_clients'] || clients['business_clients'],
    });
  };

  searchVendors = async () => {
    let url = `/api/v1/admin/vendors`;
    this.setState({ getVendorLoader: true });
    try {
      const { vendors } = await request('GET', url);
      this.setState({ vendors, getVendorLoader: false });
    } catch (error) {
      this.setState({ getVendorLoader: false });
    }
  };

  typeFixedFromHandler = (item, stringTime) => {
    this.onInputChange({
      name: 'assigned_time',
      value: stringTime,
    });
  };
  typePrefferedTime = (item, stringTime) => {
    this.onInputChange({
      name: 'preferred_time',
      value: stringTime,
    });
  };
  error = () => {
    message.error('Please fill in all the required fields');
  };

  onSubmit = values => {
    const { onSubmit, formStatus, type } = this.props;
    const { formData } = this.state;
    if (type === 'repeating') {
      delete formData.job_status;
    }
    const {
      title,
      vendor_id,
      address_id,
      client_id,
      client_type,
      service_date,
      frequency_value,
      job_duration_in_minutes,
      description,
      notes,
      job_status,
      fixed_service_time,
    } = values;

    let passingData = {
      ...formData,
      title,
      vendor_id,
      address_id,
      client_id,
      client_type,
      service_date,
      frequency_value,
      job_duration_in_minutes,
      description,
      notes,
      fixed_service_time,
    };
    if (type === 'repeating') {
      passingData.job_status = job_status;
    }
    if (type === 'repeating' && formStatus === 'edit') {
      if (passingData?.vendor_id === '') {
        delete passingData.vendor_id;
      }
      if (passingData?.assigned_worker_id === '')
        delete passingData.assigned_worker_id;
      if (passingData?.assigned_time === '') delete passingData.assigned_time;
      if (passingData?.preferred_time === '') delete passingData.preferred_time;
      if (passingData?.day === '') delete passingData.day;
    }
    onSubmit(passingData);
  };

  renderJobForm = (values, errors, touched, handleChange) => {
    const {
      formData,
      // vendors,
      // vendor,
      // getVendorLoader,
      // getVendorsLoader,
    } = this.state;
    const { type } = this.props;

    return (
      <>
        <>
          <div className="form-group">
            <label>Job Title</label>
            <Form.Item
              hasFeedback
              validateStatus={
                touched['title'] && errors['title'] ? 'error' : null
              }
              help={touched['title'] && errors['title']}
              style={{ margin: 0 }}
            >
              <Input
                name="title"
                value={values.title}
                placeholder="Enter title"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className="form-group">
            <label>Job Description</label>
            <Input.TextArea
              name="description"
              value={values.description}
              placeholder="Enter the message here"
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>Duration</label>
            <Input
              name="job_duration_in_minutes"
              // value={formData.job_duration_in_minutes}
              defaultValue={formData.job_duration_in_minutes}
              placeholder="Enter duration (in minutes)"
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <Checkbox
              checked={values.fixed_service_time}
              onChange={e => {
                handleChange({
                  target: {
                    name: 'fixed_service_time',
                    value: e.target.checked,
                  },
                });
              }}
              style={{ display: 'flex' }}
            >
              Fixed Service Duration
            </Checkbox>
          </div>
          {type !== 'single' ? (
            <div className="form-group">
              <label>Job Status</label>
              <Form.Item style={{ margin: 0 }}>
                <Select
                  style={{ width: '100%' }}
                  value={values.job_status}
                  onChange={value => {
                    handleChange({ target: { name: 'job_status', value } });
                  }}
                >
                  <Option value="active">Active</Option>
                  <Option value="draft">Draft</Option>
                </Select>
              </Form.Item>
            </div>
          ) : (
            ''
          )}
          <div className="form-group">
            <label>Assign Vendor</label>
            <Form.Item
              hasFeedback
              validateStatus={
                touched['vendor_id'] && errors['vendor_id'] ? 'error' : null
              }
              help={touched['vendor_id'] && errors['vendor_id']}
              style={{ margin: 0 }}
            >
              <SelectVendor
                value={values.vendor_id}
                handleOnChange={vendor => {
                  handleChange({
                    target: { name: 'vendor_id', value: vendor['id'] },
                  });
                  this.onInputChange({
                    name: 'vendor_id',
                    value: vendor['id'],
                  });
                }}
                // loading={getVendorsLoader}
              />
              {/* <Select
                style={{ width: '100%' }}
                value={values.vendor_id}
                placeholder="Search vendor name"
                loading={getVendorsLoader}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                // onSelect={value => this.onSearchVendor(value)}
                onSearch={this.onSearchVendor}
                onChange={value => {
                  handleChange({ target: { name: 'vendor_id', value } });
                  this.onInputChange({ name: 'vendor_id', value });
                }}
              >
                {vendors.map(el => (
                  <Option key={el.id} value={el.id}>
                    {el.vendor_name}
                  </Option>
                ))}
              </Select> */}
            </Form.Item>
          </div>
          {formData.id && (
            <>
              <div className="form-group">
                <label>Assign Worker</label>
                <SelectVendorWorker
                  vendorId={values.vendor_id}
                  value={formData.assigned_worker_id}
                  handleOnChange={worker =>
                    this.onInputChange({
                      name: 'assigned_worker_id',
                      value: worker['id'],
                    })
                  }
                />

                {/* <Select
                  style={{ width: '100%' }}
                  value={formData.assigned_worker_id}
                  placeholder="Select worker"
                  loading={getVendorLoader}
                  onChange={value =>
                    this.onInputChange({ name: 'assigned_worker_id', value })
                  }
                >
                  {vendor &&
                    vendor.vendor_users &&
                    vendor.vendor_users.map(el => (
                      <Option key={el.id} value={el.id}>
                        {el.first_name} {el.last_name}
                      </Option>
                    ))}
                </Select> */}
              </div>
              <div className="form-group">
                <label>Assigned Time</label>
                {/* <TimePicker
                  format="hh:mm A"
                  style={{ width: '100%' }}
                  use12Hours
                  disabled={!formData.assigned_worker_id}
                  value={
                    formData.assigned_time
                      ? moment(formData.assigned_time, 'hh:mm A')
                      : null
                  }
                  onChange={val =>
                    this.onInputChange({
                      name: 'assigned_time',
                      value: val.format('hh:mm A'),
                    })
                  }
                /> */}
                <ReactTimePicker
                  name="assigned_time"
                  item={formData}
                  cahngeFormTime={this.typeFixedFromHandler}
                  showMinutes
                />
              </div>
            </>
          )}
          {type === 'single' ? (
            <div className="form-group">
              <label>Service Date</label>
              <Form.Item
                hasFeedback
                validateStatus={
                  touched['service_date'] && errors['service_date']
                    ? 'error'
                    : null
                }
                help={touched['service_date'] && errors['service_date']}
                style={{ margin: 0 }}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  value={moment(values.service_date, 'YYYY-MM-DD')}
                  onChange={(date, dateString) => {
                    handleChange({
                      target: { name: 'service_date', value: dateString },
                    });
                  }}
                />
              </Form.Item>
            </div>
          ) : (
            <Button
              type="link"
              block
              onClick={() => this.setState({ step: 0 })}
            >
              Back
            </Button>
          )}
          <Button
            type="primary"
            block
            onClick={() => this.setState({ step: type === 'single' ? 1 : 2 })}
          >
            Next
          </Button>
        </>
      </>
    );
  };

  getPreferredTime = preferred_time => {
    let obj = {
      subsHours: '',
      subsMin: '',
    };

    if (preferred_time) {
      const formatted = moment(preferred_time, 'hh:mm A').format('HH:mm');
      if (formatted && formatted !== 'Invalid date') {
        const preffered_time_splitted = formatted.split(':');

        obj['subsHours'] = preffered_time_splitted[0];
        obj['subsMin'] = preffered_time_splitted[1];
      }
    }

    return obj;
  };

  renderClientForm = (values, errors, touched, handleChange) => {
    const { formData, clients } = this.state;
    const { type, submitting } = this.props;
    const client = clients.find(el => el.id === formData.client_id);

    // const { subsHours, subsMin } = this.getPreferredTime(
    //   formData['preferred_time']
    // );

    return (
      <>
        <div className="form-group">
          <label>Client Type</label>
          <Form.Item
            hasFeedback
            validateStatus={
              touched['client_type'] && errors['client_type'] ? 'error' : null
            }
            help={touched['client_type'] && errors['client_type']}
            style={{ margin: 0 }}
          >
            <Select
              style={{ width: '100%' }}
              value={values.client_type}
              onChange={value => {
                handleChange({ target: { name: 'client_type', value } });
                this.onInputChange({ name: 'client_type', value });
              }}
            >
              <Option value="personal">Personal</Option>
              {/* <Option value="business">Business</Option> */}
            </Select>
          </Form.Item>
        </div>
        <div className="form-group">
          <label>Client Name</label>
          <Form.Item
            hasFeedback
            validateStatus={
              touched['client_id'] && errors['client_id'] ? 'error' : null
            }
            help={touched['client_id'] && errors['client_id']}
            style={{ margin: 0 }}
          >
            <Select
              style={{ width: '100%' }}
              value={values.client_id}
              placeholder="Search client name"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              onSearch={this.onSearchClients}
              onChange={value => {
                handleChange({ target: { name: 'client_id', value } });
                this.onInputChange({ name: 'client_id', value });
              }}
            >
              {clients.map(el =>
                formData.client_type === 'personal' ? (
                  <>
                    <Option key={el.id} value={el.id}>
                      {el.title}
                    </Option>
                    {!clients.map(el => el.id).includes(values?.client_id) &&
                    values?.address_id ? (
                      <Option key={values?.client_id} value={values?.client_id}>
                        {values?.client_title}
                      </Option>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <Option key={el.id} value={el.id}>
                    {el.business_name}
                  </Option>
                )
              )}
            </Select>
          </Form.Item>
          {/* <Button
            type="link"
            style={{ float: 'right' }}
            onClick={() => this.props.history.push('/clients')}
          >
            Add New Client
          </Button> */}
        </div>
        <div className="form-group">
          <label>Client Address</label>
          <Form.Item
            hasFeedback
            validateStatus={
              touched['address_id'] && errors['address_id'] ? 'error' : null
            }
            help={touched['address_id'] && errors['address_id']}
            style={{ margin: 0 }}
          >
            <Select
              style={{ width: '100%' }}
              value={values.address_id}
              placeholder="Select address"
              onChange={value => {
                handleChange({ target: { name: 'address_id', value } });
                this.onInputChange({ name: 'address_id', value });
              }}
            >
              {client &&
                client.address_books &&
                client.address_books.map(el => (
                  <>
                    <Option key={el.id} value={el.id}>
                      {el.address_title}
                    </Option>
                    {!client.address_books
                      .map(el => el.id)
                      .includes(values?.address_id) && values?.address_id ? (
                      <Option
                        key={values?.address_id}
                        value={values?.address_id}
                      >
                        {values?.address_title}
                      </Option>
                    ) : (
                      ''
                    )}
                  </>
                ))}
            </Select>
          </Form.Item>
          {/* {formData['client_id'] && formData['client_type'] ? (
            <Button
              type="link"
              style={{ float: 'right' }}
              onClick={() =>
                this.props.history.push(
                  `/clients/${formData['client_type']}/${formData['client_id']}`
                )
              }
            >
              Add New Address
            </Button>
          ) : null} */}
        </div>
        <div className="form-group">
          <label>Job Notes</label>
          <Input.TextArea
            name="job_notes"
            value={formData.job_notes}
            placeholder="Enter the message here"
            onChange={({ target }) => this.onInputChange(target)}
          />
        </div>
        <div className="form-group">
          <label>Preferred Time</label>
          {/* <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}> */}
          {/* <InputNumber
              placeholder="Hours"
              name="subsHours"
              value={subsHours}
              width="20px"
              onChange={value =>
                this.onInputChange({ name: 'subsHours', value })
              }
              min={0}
              max={23}
              style={{ margin: '0 1em', width: '100%' }}
            />
            <InputNumber
              placeholder="Minutes"
              style={{ margin: '0 1em', width: '100%' }}
              name="subsMin"
              value={subsMin}
              onChange={value => this.onInputChange({ name: 'subsMin', value })}
              min={0}
              max={59}
            /> */}
          {/* <Switch
              checkedChildren="PM"
              unCheckedChildren="AM"
              checked={ampm === 'PM' ? true : false}
              style={{ width: '15%' }}
              onChange={checked =>
                this.onInputChange({ name: 'ampm', value: checked })
              }
            /> */}
          {/* </div> */}
          {/* <TimePicker
            format="hh:mm A"
            style={{ width: '100%' }}
            use12Hours
            value={
              formData.preferred_time
                ? moment(formData.preferred_time, 'hh:mm A')
                : null
            }
            onChange={val => {
              this.onInputChange({
                name: 'preferred_time',
                value: val.format('hh:mm A'),
              });
            }}
          /> */}
          <ReactTimePicker
            name="preferred_time"
            item={formData}
            cahngeFormTime={this.typePrefferedTime}
            showMinutes
          />
          {/* <TimePicker
            format="hh:mm A"
            style={{ width: "100%" }}
            use12Hours
            value={moment(formData.preferred_time, "hh:mm A")}
            onChange={(val) =>
              this.onInputChange({
                name: "preferred_time",
                value: val.format("hh:mm A"),
              })
            }
          /> */}
        </div>

        {/* <div className="form-group">
          <label>Customer Notes</label>
          <Input.TextArea
            name="customer_notes"
            value={formData.customer_notes}
            placeholder="Enter the message here"
            onChange={({ target }) => this.onInputChange(target)}
          />
        </div> */}
        <Button
          type="link"
          block
          onClick={() => this.setState({ step: type === 'single' ? 0 : 1 })}
        >
          Back
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          block
          disabled={submitting}
          loading={submitting}
          onClick={() => {
            if (Object.keys(errors).length) this.error();
          }}
        >
          Save
        </Button>
      </>
    );
  };

  getFormInitialValues = (type, formStatus) => {
    if (type === 'repeating' && formStatus === 'edit') {
      return formInitialValues['updateRepeatingJobForm'];
    } else {
      return formInitialValues['createRepeatingJobForm'];
    }
  };

  getFormValidations = (type, formStatus) => {
    if (type === 'repeating' && formStatus === 'edit') {
      return formValidations['UpdateSubscriptionJobFormValidation'];
    } else {
      return formValidations['CreateSubscriptionJobFormValidation'];
    }
  };

  render() {
    const {
      step,
      formData,
      // getVendorLoader,
      // vendor,
      // getVendorsLoader,
      // vendors,
    } = this.state;
    const { type, formStatus, submitting } = this.props;

    if (type === 'single') {
      return (
        <Formik
          initialValues={{
            ...formInitialValues['createOneTimeJobForm'],
            ...formData,
          }}
          validationSchema={formValidations['CreateOneTimeJobFormValidation']}
          onSubmit={values => this.onSubmit(values)}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                {step === 0
                  ? this.renderJobForm(values, errors, touched, handleChange)
                  : this.renderClientForm(
                      values,
                      errors,
                      touched,
                      handleChange
                    )}
              </form>
            );
          }}
        </Formik>
      );
    }

    return (
      <Formik
        initialValues={{
          ...this.getFormInitialValues(type, formStatus),
          ...formData,
        }}
        validationSchema={this.getFormValidations(type, formStatus)}
        onSubmit={values => this.onSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {type === 'repeating' && formStatus === 'edit' ? (
                <>
                  <div className="form-group">
                    <label>Job Frequency</label>
                    <Row>
                      <Col span="12" style={{ paddingRight: '8px' }}>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['frequency_value'] &&
                            errors['frequency_value']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['frequency_value'] &&
                            errors['frequency_value']
                          }
                          style={{ margin: 0 }}
                        >
                          <Input
                            name="frequency_value"
                            value={values.frequency_value}
                            placeholder="Enter amount"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span="12" style={{ paddingLeft: '8px' }}>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['frequency_type'] &&
                            errors['frequency_type']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['frequency_type'] &&
                            errors['frequency_type']
                          }
                          style={{ margin: 0 }}
                        >
                          <Select
                            value={values.frequency_type}
                            style={{ width: '100%' }}
                            onChange={value =>
                              handleChange({
                                target: {
                                  name: 'frequency_type',
                                  value,
                                },
                              })
                            }
                          >
                            <Option value="weekly">per week</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <label>Job Title</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['title'] && errors['title'] ? 'error' : null
                      }
                      help={touched['title'] && errors['title']}
                      style={{ margin: 0 }}
                    >
                      <Input
                        name="title"
                        value={values.title}
                        placeholder="Enter title"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>Job Description</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['description'] && errors['description']
                          ? 'error'
                          : null
                      }
                      help={touched['description'] && errors['description']}
                      style={{ margin: 0 }}
                    >
                      <Input.TextArea
                        name="description"
                        value={values.description}
                        placeholder="Enter the message here"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>Job Notes</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['notes'] && errors['notes'] ? 'error' : null
                      }
                      help={touched['notes'] && errors['notes']}
                      style={{ margin: 0 }}
                    >
                      <Input.TextArea
                        name="notes"
                        value={values.notes}
                        placeholder="Enter the message here"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>Duration</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['job_duration_in_minutes'] &&
                        errors['job_duration_in_minutes']
                          ? 'error'
                          : null
                      }
                      help={
                        touched['job_duration_in_minutes'] &&
                        errors['job_duration_in_minutes']
                      }
                      style={{ margin: 0 }}
                    >
                      <Input
                        name="job_duration_in_minutes"
                        value={values.job_duration_in_minutes}
                        placeholder="Enter duration (in minutes)"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>Job Status</label>
                    <Form.Item style={{ margin: 0 }}>
                      <Select
                        style={{ width: '100%' }}
                        value={values.job_status}
                        onChange={value => {
                          handleChange({
                            target: { name: 'job_status', value },
                          });
                        }}
                      >
                        <Option value="active">Active</Option>
                        <Option value="draft">Draft</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <Checkbox
                      onChange={this.handleCheckChange}
                      style={{ display: 'flex' }}
                    >
                      More Details
                    </Checkbox>
                  </div>
                  {this.state.checked && (
                    <>
                      <div className="form-group">
                        <label>Assign Vendor</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['vendor_id'] && errors['vendor_id']
                              ? 'error'
                              : null
                          }
                          help={touched['vendor_id'] && errors['vendor_id']}
                          style={{ margin: 0 }}
                        >
                          <SelectVendor
                            value={values.vendor_id}
                            handleOnChange={vendor => {
                              handleChange({
                                target: {
                                  name: 'vendor_id',
                                  value: vendor['id'],
                                },
                              });
                              this.onInputChange({
                                name: 'vendor_id',
                                value: vendor['id'],
                              });
                            }}
                          />
                          {/* <Select
                        style={{ width: '100%' }}
                        value={values.vendor_id}
                        placeholder="Search vendor name"
                        loading={getVendorsLoader}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // onSelect={value => this.onSearchVendor(value)}
                        onSearch={this.onSearchVendor}
                        onChange={value =>
                          handleChange({ target: { name: 'vendor_id', value } })
                        }
                      >
                        {vendors.map(el => (
                          <Option key={el.id} value={el.id}>
                            {el.vendor_name}
                          </Option>
                        ))}
                      </Select> */}
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Assign Worker</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['assigned_worker_id'] &&
                            errors['assigned_worker_id']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['assigned_worker_id'] &&
                            errors['assigned_worker_id']
                          }
                          style={{ margin: 0 }}
                        >
                          <SelectVendorWorker
                            vendorId={values.vendor_id}
                            value={formData.assigned_worker_id}
                            handleOnChange={worker =>
                              this.onInputChange({
                                name: 'assigned_worker_id',
                                value: worker['id'],
                              })
                            }
                          />
                          {/* <Select
                        style={{ width: '100%' }}
                        value={values.assigned_worker_id}
                        placeholder="Select worker"
                        loading={getVendorLoader}
                        onChange={value =>
                          handleChange({
                            target: {
                              name: 'assigned_worker_id',
                              value,
                            },
                          })
                        }
                      >
                        {vendor &&
                          vendor.vendor_users &&
                          vendor.vendor_users.map(el => (
                            <Option key={el.id} value={el.id}>
                              {el.first_name} {el.last_name}
                            </Option>
                          ))}
                      </Select> */}
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Assigned Time</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['assigned_time'] && errors['assigned_time']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['assigned_time'] && errors['assigned_time']
                          }
                          style={{ margin: 0 }}
                        >
                          {/* <TimePicker
                        format="hh:mm A"
                        style={{ width: '100%' }}
                        disabled={!formData.assigned_worker_id}
                        use12Hours
                        value={
                          formData.assigned_time
                            ? moment(formData.assigned_time, 'hh:mm A')
                            : null
                        }
                        onChange={val =>
                          this.onInputChange({
                            name: 'assigned_time',
                            value: val.format('hh:mm A'),
                          })
                        }
                      /> */}
                          <ReactTimePicker
                            name="assigned_time"
                            item={formData}
                            cahngeFormTime={this.typeFixedFromHandler}
                            showMinutes
                            disabled={!formData.assigned_worker_id}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Preferred Time</label>
                        {/* <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['subsHours'] && errors['subsHours']
                            ? 'error'
                            : null
                        }
                        help={touched['subsHours'] && errors['subsHours']}
                        style={{ margin: 0, width: '100%' }}
                      >
                        <InputNumber
                          placeholder="Hours"
                          name="subsHours"
                          style={{ width: '100%' }}
                          value={subsHours}
                          onChange={value => {
                            handleChange({
                              target: {
                                name: 'subsHours',
                                value,
                              },
                            });
                            this.onInputChange({ name: 'subsHours', value });
                          }}
                          min={0}
                          max={23}
                        />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        validateStatus={
                          touched['subsMin'] && errors['subsMin']
                            ? 'error'
                            : null
                        }
                        help={touched['subsMin'] && errors['subsMin']}
                        style={{ margin: 0, width: '100%' }}
                      >
                        <InputNumber
                          placeholder="Minutes"
                          style={{ margin: '0 1em', width: '100%' }}
                          name="subsMin"
                          value={subsMin}
                          onChange={value => {
                            handleChange({
                              target: {
                                name: 'subsMin',
                                value,
                              },
                            });
                            this.onInputChange({ name: 'subsMin', value });
                          }}
                          min={0}
                          max={59}
                        />
                      </Form.Item>
                    </div> */}
                        {/* <TimePicker
                      format="hh:mm A"
                      style={{ width: '100%' }}
                      use12Hours
                      value={
                        formData.preferred_time
                          ? moment(formData.preferred_time, 'hh:mm A')
                          : null
                      }
                      onChange={val => {
                        this.onInputChange({
                          name: 'preferred_time',
                          value: val.format('hh:mm A'),
                        });
                      }}
                    /> */}
                        <ReactTimePicker
                          name="preferred_time"
                          item={formData}
                          cahngeFormTime={this.typePrefferedTime}
                          showMinutes
                        />
                      </div>
                      <div className="form-group">
                        <label>Day Selector</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['day'] && errors['day'] ? 'error' : null
                          }
                          help={touched['day'] && errors['day']}
                          style={{ margin: 0 }}
                        >
                          <Select
                            value={formData.days}
                            mode="multiple"
                            disabled={this.props.data}
                            style={{ width: '100%' }}
                            placeholder="Select day"
                            onChange={value =>
                              handleChange({ target: { name: 'day', value } })
                            }
                          >
                            {formData['days'].map((day, i) => (
                              <Option key={i} value={day}>
                                {day.charAt(0).toUpperCase() + day.slice(1)}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={submitting}
                    loading={submitting}
                  >
                    Save
                  </Button>
                </>
              ) : step === 0 ? (
                <>
                  <div className="form-group">
                    <label>Subscription Term</label>
                    <Row>
                      <Col span="10" style={{ paddingRight: '8px' }}>
                        <Select
                          value={formData.term_type}
                          style={{ width: '100%' }}
                          disabled={formData['perpetual']}
                          onChange={value =>
                            this.onInputChange({ name: 'term_type', value })
                          }
                        >
                          <Option value="week">Weekly</Option>
                          <Option value="month">Monthly</Option>
                        </Select>
                      </Col>
                      <Col span="10" style={{ paddingLeft: '8px' }}>
                        <Input
                          name="term_value"
                          value={formData.term_value}
                          placeholder="Enter amount"
                          disabled={formData['perpetual']}
                          onChange={({ target }) => this.onInputChange(target)}
                        />
                      </Col>
                      <Col
                        span="4"
                        style={{
                          paddingLeft: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        title="Perpetual Subscription"
                      >
                        <Checkbox
                          checked={formData['perpetual']}
                          onChange={ev =>
                            this.onInputChange({
                              name: 'perpetual',
                              value: ev['target']['checked'],
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <label>Job Frequency</label>
                    <Row className="job-freq-field-container">
                      <Col span="3" style={{ paddingRight: '8px' }}>
                        <p>Every</p>
                      </Col>
                      <Col span="10" style={{ paddingRight: '8px' }}>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['frequency_value'] &&
                            errors['frequency_value']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['frequency_value'] &&
                            errors['frequency_value']
                          }
                          style={{ margin: 0 }}
                        >
                          <Input
                            name="frequency_value"
                            value={values.frequency_value}
                            placeholder="Enter amount"
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span="10" style={{ paddingLeft: '8px' }}>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['frequency_type'] &&
                            errors['frequency_type']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['frequency_type'] &&
                            errors['frequency_type']
                          }
                          style={{ margin: 0 }}
                        >
                          <Select
                            value={values.frequency_type}
                            style={{ width: '100%' }}
                            onChange={value =>
                              handleChange({
                                target: {
                                  name: 'frequency_type',
                                  value,
                                },
                              })
                            }
                          >
                            <Option value="weekly">week</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group">
                    <label>Start Date</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['start_date'] && errors['start_date']
                          ? 'error'
                          : null
                      }
                      help={touched['start_date'] && errors['start_date']}
                      style={{ margin: 0 }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={moment(values.start_date, 'YYYY-MM-DD')}
                        onChange={(date, dateString) => {
                          handleChange({
                            target: { name: 'start_date', value: dateString },
                          });
                          this.onInputChange({
                            name: 'start_date',
                            value: dateString,
                            dayName: moment(dateString)
                              .format('dddd')
                              .toLowerCase(),
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>Day(s) Limit</label>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['days_quantity_limit'] &&
                        errors['days_quantity_limit']
                          ? 'error'
                          : null
                      }
                      help={
                        touched['days_quantity_limit'] &&
                        errors['days_quantity_limit']
                      }
                      style={{ margin: 0 }}
                    >
                      <InputNumber
                        placeholder="Limit"
                        name="days_quantity_limit"
                        value={values.days_quantity_limit}
                        onChange={value => {
                          handleChange({
                            target: {
                              name: 'days_quantity_limit',
                              value,
                            },
                          });
                          this.onInputChange({
                            name: 'days_quantity_limit',
                            value,
                          });
                          setFieldValue('days', [
                            moment(values['start_date'])
                              .format('dddd')
                              .toLowerCase(),
                          ]);
                        }}
                        min={1}
                        max={7}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>
                      Day Selector{' '}
                      <span className="limit-title">
                        ({formData.days_quantity_limit} days must be selected)
                      </span>
                    </label>

                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['days'] && errors['days'] ? 'error' : null
                      }
                      help={touched['days'] && errors['days']}
                      style={{ margin: 0 }}
                    >
                      <Select
                        mode="multiple"
                        value={values.days}
                        style={{ width: '100%' }}
                        placeholder="Select days"
                        onChange={value => {
                          const limit = formData.days_quantity_limit || 1;
                          if (formData['days']['length'] < limit) {
                            handleChange({
                              target: {
                                name: 'days',
                                value,
                              },
                            });
                            this.onInputChange({ name: 'days', value });
                          } else if (
                            value['length'] < formData['days']['length']
                          ) {
                            handleChange({
                              target: {
                                name: 'days',
                                value,
                              },
                            });
                            this.onInputChange({ name: 'days', value });
                          }
                        }}
                      >
                        <Option value="monday">Monday</Option>
                        <Option value="tuesday">Tuesday</Option>
                        <Option value="wednesday">Wednesday</Option>
                        <Option value="thursday">Thursday</Option>
                        <Option value="friday">Friday</Option>
                        <Option value="saturday">Saturday</Option>
                        <Option value="sunday">Sunday</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <Button
                    type="primary"
                    block
                    onClick={() => this.setState({ step: 1 })}
                  >
                    Next
                  </Button>
                </>
              ) : step === 1 ? (
                this.renderJobForm(values, errors, touched, handleChange)
              ) : (
                this.renderClientForm(values, errors, touched, handleChange)
              )}
            </form>
          );
        }}
      </Formik>
    );
  }
}

JobForm.propTypes = {
  type: PropTypes.string,
  formStatus: PropTypes.string,
  data: PropTypes.object,
  history: PropTypes.object,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};

export default withRouter(JobForm);
