import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  getTags,
  getTagsSuccess,
  getTagsFailed,
  getClasses,
  getClassesFailed,
  getClassesSuccess,
  createClass,
  createClassSuccess,
  createClassFailed,
  updateClass,
  updateClassSuccess,
  updateClassFailed,
  archiveClass,
  archiveClassFailed,
  archiveClassSuccess,
  unArchiveClass,
  unArchiveClassFailed,
  unArchiveClassSuccess,
  classbulkArchive,
  classbulkArchiveSuccess,
  classbulkArchiveFailed,
  classbulkUnarchive,
  classbulkUnarchiveSuccess,
  classbulkUnarchiveFailed,
  deleteClass,
  deleteClassFailed,
  deleteClassSuccess,
  classbulkDelete,
  classbulkDeleteSuccess,
  classbulkDeleteFailed,
} from './reducers';

function* getTagSaga({ payload }) {
  let action;
  let url = `${'/api/v1/vendor/properties'}?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }

    const response = yield call(request, 'GET', url);
    action = getTagsSuccess(response);
  } catch (error) {
    action = getTagsFailed(error.message);
  }
  yield put(action);
}

function* getClassesSaga({ payload }) {
  let action;
  let url = '/api/v1/vendor/classes?';
  try {
    if (payload['filterTab']) {
      url += `status=${payload['filterTab']}`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getClassesSuccess(response);
  } catch (error) {
    action = getClassesFailed(error.message);
  }
  yield put(action);
}

function* createClassSaga({ payload }) {
  let action;

  try {
    const response = yield call(request, 'POST', `/api/v1/vendor/classes`, {
      classes: payload,
    });

    action = createClassSuccess(response);
  } catch (error) {
    action = createClassFailed(error.message);
  }
  yield put(action);
}

function* updateClassSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    delete payload.id;
    const response = yield call(
      request,
      'PUT',
      `/api/v1/vendor/classes/${id}`,
      {
        classes: payload,
      }
    );
    action = updateClassSuccess(response);
  } catch (error) {
    action = updateClassFailed(error.message);
  }
  yield put(action);
}

function* archiveClassSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/classes/${id}/archive`
    );

    action = archiveClassSuccess(response);
  } catch (error) {
    action = archiveClassFailed(error.message);
  }
  yield put(action);
}

function* unArchiveClassSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/classes/${id}/unarchive`
    );

    action = unArchiveClassSuccess(response);
  } catch (error) {
    action = unArchiveClassFailed(error.message);
  }
  yield put(action);
}

function* classbulkArchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const classes = {
    classes: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/classes/bulk_archive`,
      classes
    );
    action = classbulkArchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = classbulkArchiveFailed('');
  }
  yield put(action);
}

function* classbulkUnarchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const classes = {
    classes: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/classes/bulk_unarchive`,
      classes
    );
    action = classbulkUnarchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = classbulkUnarchiveFailed(error.message);
  }
  yield put(action);
}

function* deleteClassSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/vendor/classes/${payload.id}`
    );

    action = deleteClassSuccess(response);
  } catch (error) {
    action = deleteClassFailed(error.message);
  }
  yield put(action);
}

function* classbulkDeleteSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const classes = {
    product: {
      ids: idsString,
    },
  };

  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/vendor/products/bulk_destroy`,
      classes
    );
    action = classbulkDeleteSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = classbulkDeleteFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(deleteClass, deleteClassSaga);
  yield takeLatest(classbulkDelete, classbulkDeleteSaga);
  yield takeLatest(getTags, getTagSaga);
  yield takeLatest(getClasses, getClassesSaga);
  yield takeLatest(createClass, createClassSaga);
  yield takeLatest(updateClass, updateClassSaga);
  yield takeLatest(archiveClass, archiveClassSaga);
  yield takeLatest(unArchiveClass, unArchiveClassSaga);
  yield takeLatest(classbulkArchive, classbulkArchiveSaga);
  yield takeLatest(classbulkUnarchive, classbulkUnarchiveSaga);
}
