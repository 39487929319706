import React from 'react';
const JobIcon = props => (
  <span aria-label="message" className="anticon anticon-message">
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width="16px"
      height="16px"
      strokeWidth="1.5"
      {...props}
    >
      <path d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
    </svg>
  </span>
);

export default JobIcon;
