import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getAdmins,
  getAdminsFailed,
  getAdminsSuccess,
  createAdmin,
  createAdminSuccess,
  createAdminFailed,
  updateAdmin,
  updateAdminSuccess,
  updateAdminFailed,
  deleteAdmin,
  deleteAdminFailed,
  deleteAdminSuccess,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=profile`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getAdminsSaga({ payload }) {
  let action;
  let url = `/api/v1/admin/users?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getAdminsSuccess(response);
  } catch (error) {
    action = getAdminsFailed(error.message);
  }
  yield put(action);
}

function* createAdminSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/users`,
      payload
    );
    action = createAdminSuccess(response);
  } catch (error) {
    action = createAdminFailed(error.message);
  }
  yield put(action);
}

function* updateAdminSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/users/${payload['admin_user']['id']}`,
      payload
    );
    action = updateAdminSuccess(response);
  } catch (error) {
    action = updateAdminFailed(error.message);
  }
  yield put(action);
}

function* deleteAdminSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/users/${payload}`
    );
    action = deleteAdminSuccess(response);
  } catch (error) {
    action = deleteAdminFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getAdmins, getAdminsSaga);
  yield takeLatest(createAdmin, createAdminSaga);
  yield takeLatest(updateAdmin, updateAdminSaga);
  yield takeLatest(deleteAdmin, deleteAdminSaga);
}
