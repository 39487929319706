import React from 'react';
import { capitalize } from '../../utils/apisauce';
// import moment from 'moment';

const getColorClass = status => {
  if (status === 'debit') {
    return 'tran-type-debit';
  } else if (status === 'credit') {
    return 'tran-type-credit';
  } else if (status === 'success') {
    return 'tran-status-success';
  }
};

const tableColumns = () => [
  {
    title: <span className="table-column-title">Transaction ID</span>,
    key: 'trx_id',
    dataIndex: 'trx_id',
    render: (text, record) => record['trx_id'],
  },
  {
    title: <span className="table-column-title">Charged ID (stripe)</span>,
    key: 'stripe_charge_id',
    dataIndex: 'stripe_charge_id',
    render: (text, record) => record['stripe_charge_id'],
  },
  {
    title: <span className="table-column-title">Invoice ID (stripe)</span>,
    key: 'stripe_invoice_id',
    dataIndex: 'stripe_invoice_id',
    width: 150,
    render: (text, record) => record['stripe_invoice_id'],
  },
  {
    title: <span className="table-column-title">Client Name</span>,
    key: 'order',
    dataIndex: 'order',
    width: 150,
    render: function render(order) {
      return (
        <span>{`${capitalize(order?.buyer?.first_name)} ${capitalize(
          order?.buyer?.last_name
        )}`}</span>
      );
    },
  },
  {
    title: <span className="table-column-title">Currency</span>,
    key: 'currency',
    dataIndex: 'currency',
    render: (text, record) => record['currency'],
  },
  {
    title: <span className="table-column-title">Transaction Type</span>,
    key: 'transaction_type',
    dataIndex: 'transaction_type',
    render: (text, record) => capitalize(record['transaction_type']),
  },
  {
    title: <span className="table-column-title">Order Type</span>,
    key: 'order_type',
    dataIndex: 'order_type',
    render: (text, record) => capitalize(record['transaction_for']),
  },
  {
    title: <span className="table-column-title">Description</span>,
    key: 'description',
    dataIndex: 'description',
    render: (text, record) => record['description'],
  },
  {
    title: <span className="table-column-title">Order ID</span>,
    key: 'orderId',
    render: (text, record) => record['order']['id'],
  },
  {
    title: <span className="table-column-title">Amount</span>,
    key: 'formatted_amount',
    dataIndex: 'formatted_amount',
    render: (text, record) => record['formatted_amount'],
  },
  {
    title: <span className="table-column-title">Status</span>,
    key: 'status',
    dataIndex: 'status',
    render: function render(text, record) {
      return (
        <span className={`${getColorClass(record['status'])}`}>
          {record['status']}
        </span>
      );
    },
  },
];

export default tableColumns;
