import React from 'react';
import PropTypes from 'prop-types';

const ColumnLink = ({ href, onClick, text }) => (
  <a href={href} className="link" onClick={onClick}>
    {text}
  </a>
);

ColumnLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.number,
};

export default ColumnLink;
