import React, { useState } from 'react';
import { Modal } from 'antd';
import DataTable from '../../components/DataTable';
import RecipientListTable from './RecipientListTable';
import { tableColumns } from './columns';
import PropTypes from 'prop-types';

const ModalHeader = ({ title, text }) => (
  <div className="modal-header-container">
    <h1 className="modal-header-title">{title}</h1>
    <p className="modal-header-text">{text}</p>
  </div>
);

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const initialState = () => ({
  status: false,
  data: {
    title: '',
    announcement_id: '',
    media: '',
    message: '',
    audiences: [],
  },
});

const initialRecipientState = () => ({
  status: false,
  data: {
    client_count: 0,
    audiences: [],
  },
});

function HistoryTable({ pagination, dataSource, loading, handleTableChange }) {
  const [modalData, updateModalData] = useState(initialState());
  const [modalRecipientData, updateRecipientModalData] = useState(
    initialRecipientState()
  );

  const openAnnouncementModal = record => {
    const { title, id, media, message, audiences } = record;
    updateModalData({
      status: true,
      data: {
        title,
        announcement_id: id,
        media,
        message,
        audiences,
      },
    });
  };

  const openRecipientModal = record => {
    const { audiences } = record;
    updateRecipientModalData({
      status: true,
      data: {
        client_count: audiences['length'],
        audiences,
      },
    });
  };

  return (
    <>
      <DataTable
        columns={tableColumns(openAnnouncementModal, openRecipientModal)}
        // onClickRow={() => {}}
        // pagination={pagination}
        rowKey={record => record['id'] || 'id'}
        loading={loading}
        data={dataSource}
        // columns={this.tableColumns(this.showClientAddress)}
        // onClickRow={this.onClickRow}
        pagination={pagination}
        handleTableChange={handleTableChange}
      />

      <Modal
        title={
          <ModalHeader
            title={modalData['data']['title']}
            text={`Notification ID: ${modalData['data']['announcement_id']}`}
          />
        }
        visible={modalData['status']}
        onOk={() => updateModalData(initialState())}
        onCancel={() => updateModalData(initialState())}
        centered
        width="30%"
        footer={false}
      >
        <div className="modal-body-container">
          <div className="modal-body-head-container">
            <div className="modal-body-head">
              <h4 className="modal-body-title">Recipient List</h4>
              <a
                href="#/"
                className="modal-body-text"
                onClick={() => {
                  updateModalData(initialState());
                  openRecipientModal(modalData['data']);
                }}
              >
                View List
              </a>
            </div>
          </div>
          <div className="modal-message-container">
            <h4 className="modal-body-title">Message</h4>
            <p className="modal-body-text">{modalData['data']['message']}</p>
          </div>
        </div>
      </Modal>

      <Modal
        title={
          <ModalHeader
            title="Recepient List"
            text={`Total Client: ${modalRecipientData['data']['client_count']}`}
          />
        }
        visible={modalRecipientData['status']}
        onOk={() => updateRecipientModalData(initialRecipientState())}
        onCancel={() => updateRecipientModalData(initialRecipientState())}
        centered
        width="60%"
        footer={false}
      >
        <RecipientListTable
          dataSource={modalRecipientData['data']['audiences']}
        />
      </Modal>
    </>
  );
}

export default HistoryTable;
