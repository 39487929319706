import React, { useEffect, useCallback, useState } from 'react';
// import StyledFormItem from '../styled-components/form-item';
import StyledForm from '../styled-components/form-wrapper';
import ImageUpload from '../../../components/ImageUpload';
import CardWrapper from '../styled-components/card-wrapper';
import {
  Button,
  Input,
  Select,
  Card,
  Row,
  Col,
  Breadcrumb,
  Spin,
  Drawer,
  Form,
  message,
  Radio,
  Image,
} from 'antd';
import request from '../../../utils/apisauce';
import {
  createSubscription,
  updateSubscription,
  getSubscriptionById,
  getMedia,
} from '../reducers';
import { connect } from 'react-redux';
import { getVendors, createVendor } from '../../VendorPage/reducers';
import {
  getCategories,
  createCategory,
  updateUI,
} from '../../CategoriesPage/reducers';
import { getSubCategoriesByFilter } from '../../SubCategoriesPage/reducers';
import CreateSubCategoryDrawer from '../../../components/createCategory';
import ProductCreateFieldTitle from '../../../components/ProductCreateFieldTitle';
import SubscriptionItemForm from './subscription-item-form';
import StyledItemForm from '../styled-components/form-split';
import { LeftOutlined, CloseCircleFilled } from '@ant-design/icons';
import { getTags, createTag } from '../../TagsPage/reducers';
import { getImageURI } from '../../../utils/apisauce';
import PropTypes from 'prop-types';
import CategoryForm from '../../CategoriesPage/components/category-form';
import CreateVendorForm from '../../VendorPage/components/create-vendor';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import { removeNullAttrsFromCalender } from '../../../utils/helpers';
// import SelectVendor from '../../../components/SelectVendor';

const AddItemForms = props => (
  <Card
    title={props['title']}
    className="card-container"
    extra={
      <CloseCircleFilled
        className="close-icon"
        onClick={props['onCloseIconClick']}
      />
    }
  >
    {props['children']}
  </Card>
);

AddItemForms.propTypes = {
  onCloseIconClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// import the reducers here so you can access delete,create,update
const defaultForm = {
  vendor_id: '',
  product_type: 'subscription',
  title: '',
  description: '',
  category_id: null,
  sub_category_id: null,
  property_ids: null,
  image_type: 'attached',
  //   image: null,
};
const defaultItemForm = {
  title: '',
  description: '',
  amount: '',
  day_limit: 1,
  unit: '',
  property_ids: [],
  frequency_value: 0,
  frequency_type: 'week',
  term_perpetual: true,
  term: '',
  duration_in_minutes: 0,
  image_type: 'attached',
};

const createNewForms = {
  showCreateCategoryForm: false,
  showCreateVendorForm: false,
  showCreateTagForm: false,
};

const DEFAULT_CATEGORY_FORM_DATA = {
  title: '',
  description: '',
  status: 'active',
  image: null,
  images: null,
};

// to later on suffix * dynamically when see how required is determiend from form passed
function SubscriptionFormPage(props) {
  const {
    getTags,
    created,
    updateUI,
    createCategory,
    categorySubmitting,
    categoryError,
    uiState,
    vendorSubmitting,
    vendorError,
    createVendor,
    tagSubmitting,
    tagError,
    createTag,
    history: { push },
    getMedia,
    libraryImage,
  } = props;

  const [id] = useState(props.match.params.id);
  const [form, setForm] = useState(defaultForm);
  const [formStatus, setFormStatus] = useState(createNewForms);
  const [formItems, setFormItems] = useState([]);
  const [createVendorForm, updateCreateVendorForm] = useState(null);
  const [createTagForm, updateCreateTagForm] = useState({ title: '' });
  const [mode, setMode] = useState(null);
  const [hasOptions, setHasOptions] = useState(false);
  const [hasQuantity, setHasQuantity] = useState(false);
  const [singleOrMultipleItems, setSingleOrMultipleItems] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    categoryState,
    getCategories,
    subCategoryState,
    getSubCategoriesByFilter,
  } = props;

  const itemFormRefs = [];
  // if no find match route params is create
  const onClickBack = useCallback(() => {
    push('/admin/list/subscription');
  }, [push]);

  const [vendorSearchKeyword, setVendorSearchKeyword] = useState(null);
  const { vendorsState, getVendors } = props;
  const vendorsloading = vendorsState.loading;
  const { vendors } = vendorsState;
  const [categorySearchKeyword, setcategorySearchKeyword] = useState(null);
  const categoryloading = categoryState.loading;
  const { categories } = categoryState;

  const { formData } = uiState;

  useEffect(() => {
    getMedia();
  }, []);

  useEffect(() => {
    if (created) {
      push('/admin/list/subscription');
    }
  }, [created, push]);

  const handleCloseDrawer = () => {
    setFormStatus({
      showCreateCategoryForm: false,
      showCreateVendorForm: false,
      showCreateTagForm: false,
    });
  };

  const handleCloseSubCategoryDrawer = () => {
    setIsModalOpen(false);
  };
  const handleOpenSubCategoryDrawer = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (vendorSearchKeyword && vendorSearchKeyword.length >= 3) {
      getVendors({
        query: vendorSearchKeyword,
        per: 30,
      });
    }
    if (!vendorSearchKeyword) {
      getVendors({
        per: 20,
      });
    }
  }, [vendorSearchKeyword, getVendors]);

  useEffect(() => {
    getCategories({
      query: categorySearchKeyword,
      // per: 30,
      activeTab: 'subscription',
      all: true,
    });
  }, [categorySearchKeyword, getCategories]);

  useEffect(() => {
    if (!categorySubmitting && !categoryError) {
      setFormStatus({
        showCreateCategoryForm: false,
        showCreateVendorForm: false,
        showCreateTagForm: false,
      });
    }
  }, [categorySubmitting, categoryError]);

  useEffect(() => {
    if (!vendorSubmitting && !vendorError) {
      setFormStatus({
        showCreateCategoryForm: false,
        showCreateVendorForm: false,
        showCreateTagForm: false,
      });
    }
  }, [vendorSubmitting, vendorError]);

  useEffect(() => {
    if (!tagSubmitting && !tagError) {
      setFormStatus({
        showCreateCategoryForm: false,
        showCreateVendorForm: false,
        showCreateTagForm: false,
      });
    }
  }, [tagSubmitting, tagError]);

  useEffect(() => {
    getSubCategoriesByFilter(null);
    // const {getProductById} = props;
    // getProductById(true);
    // differentiate if id is create dont fetch data
    // const { id } = props.match.params;
    setMode(id);
    // can be gotten by passing down thru props.location.state but this way is safer, doesnt require navigation
    const fetchCategoriesAndVendors = async response => {
      // getTags({
      //   page: 1,
      //   per_page: 20,
      //   // query: query
      // })
      // const vendorsResponse = await request('GET', `/api/v1/admin/vendors`);
      // if (vendorsResponse) {
      //   // response.vendor_id = vendorsResponse.categories.map(
      //   //   (v) => v.id,
      //   // )[0];
      //   if (response.vendor) {
      //     const vendor = vendorsResponse.find(x => x.id === response.vendor.id);
      //     if (!vendor) {
      //       vendorsResponse.push(response.vendor);
      //     }
      //     response.vendor_id = response.vendor.id;
      //   }
      //   setVendors(vendorsResponse);
      // }
      // const categoriesResponse = await request(
      //   'GET',
      //   '/api/v1/admin/categories',
      // );
      // // convert from array back into string
      // if (categoriesResponse) {
      //   if (response.category) {
      //     const category = categoriesResponse.categories.find(x => x.id === response.category.id);
      //     if (!category) {
      //       categoriesResponse.categories.push(response.category);
      //     }
      //     response.category_id = response.category.id;
      //   }
      //   setCategories(categoriesResponse.categories); // to map over for selection option
      // }
      return response;
    };

    const fetchProductsData = id =>
      request('GET', `/api/v1/admin/products/${id}`);

    if (id !== 'create') {
      message.loading('fetching data...');

      fetchProductsData(id).then(async response => {
        // if (response.product && response.product.properties) {
        //   setSelectedTags(
        //     response.product.properties.map(x => ({
        //       value: x.id,
        //       label: x.title,
        //     }))
        //   );
        // }
        if (response.product && response.product.vendor) {
          response.product.vendor_id = response.product.vendor.id;
        }
        if (response.product && response.product.category) {
          response.product.category_id = response.product.category.id;
        }
        if (response.product && response.product.sub_category) {
          response.product.sub_category_id = response.product.sub_category.id;
        }
        if (response.product && response.product.items) {
          let has_quantity = false;
          let has_options = false;

          response.product.items = response.product.items.map(x => {
            has_quantity = x.has_quantity;

            if (x.properties && x.properties.length) {
              has_options = true;
            }

            return {
              ...x,
              images: x.image,
              image: ' ',
              property_ids: x?.properties?.map(x => x.id),
            };
          });

          setHasQuantity(has_quantity);
          setHasOptions(has_options);
          setFormItems([...response.product.items]);
          response.product.category_id &&
            getSubCategoriesByFilter(response.product.category_id);
        }
        if (response.product && response.product.image) {
          response.product.images = response.product.image;
          response.product.image = null;
        }
        if (response?.product?.items?.length > 1) {
          setSingleOrMultipleItems('multiple');
        } else {
          setSingleOrMultipleItems('single');
        }
        try {
          const responseWithCatPlusVendors = await fetchCategoriesAndVendors(
            response.product
          );
          setForm(responseWithCatPlusVendors);
          message.destroy();
        } catch (error) {
          message.destroy();
          console.log(error);
        }
      });
    } else {
      fetchCategoriesAndVendors({})
        .then(responseWithCatPlusVendors => {
          setForm({ ...defaultForm, ...responseWithCatPlusVendors });
          message.destroy();
        })
        .catch(() => {
          message.destroy();
        });
    }
  }, [id, getTags, getSubCategoriesByFilter]);

  const { submitting } = props;
  const formObject = props.formObject;
  useEffect(() => {
    if (formObject === null) {
      return;
    }
    onClickBack();
  }, [formObject, onClickBack]);

  const dupObjs = arr => {
    const lookup = arr.reduce((a, e) => {
      a[e.id] = ++a[e.id] || 0;
      return a;
    }, {});
    let vals = arr.filter(e => lookup[e.id]);
    return vals;
  };

  const onSubmit = formState => {
    const {
      product_type,
      title,
      description,
      vendor_id,
      category_id,
      sub_category_id,
      formItems,
      image,
      image_type,
    } = formState;

    let productForm = {
      id: formState['id'] || null,
      product_type,
      title,
      description,
      vendor_id,
      category_id,
      sub_category_id,
      items_attributes: formItems.map(formItem => {
        const {
          title,
          description,
          amount,
          unit,
          property_ids,
          campaign_start_at,
          campaign_end_at,
          campaign_type,
          calendar_settings_attributes,
          promoted,
          image_type,
          is_request_for_quote,
        } = formItem;
        // let flag = 0;

        let image;
        let images;
        let attach_library_image_attributes;
        if (image_type === 'attached') {
          if (image && image['data']) {
            image = formItem['image'];
          }
        } else {
          if (formItem.library_image_id) {
            attach_library_image_attributes = {
              library_image_id: formItem.library_image_id,
            };
          } else {
            attach_library_image_attributes = {
              library_image_id: formItem?.images?.library_image_id,
            };
          }
          images = '';
          image = '';
        }

        if (
          'calendar_settings' in formItem &&
          'calendar_settings_attributes' in formItem
        ) {
          delete formItem.calendar_settings;
          calendar_settings_attributes.map(item => {
            if ('_destroy' in item && item._destroy === 2) delete item._destroy;
            // if (i > 0 && item.id === calendar_settings_attributes[i - 1].id)
            //   flag = 1;
            // if (flag) delete item.id;
            return item;
          });
        }
        const arr = [...calendar_settings_attributes];
        let repObj = dupObjs(arr)[0];
        let uniqArr = calendar_settings_attributes.filter(
          i => i?.id !== repObj?.id
        );
        let dupEntries = dupObjs(arr).map((item, i) => {
          if (i !== 0) delete item.id;
          return item;
        });
        const finalArr = [...uniqArr, ...dupEntries];

        // console.log('this ', repObj, uniqArr,finalArr);

        if (formItem['id']) {
          if (is_request_for_quote) {
            return {
              id: formItem['id'],
              image,
              images,
              title,
              description,
              attach_library_image_attributes,
              is_request_for_quote,
            };
          } else {
            return {
              ...formItem,
              has_quantity: hasQuantity,
              attach_library_image_attributes,
              images,
              is_request_for_quote,
            };
          }
        } else {
          if (is_request_for_quote) {
            return {
              image,
              images,
              title,
              description,
              attach_library_image_attributes,
              is_request_for_quote,
            };
          } else {
            return {
              ...formItem,
              image,
              images,
              title,
              description,
              promoted,
              unit,
              amount,
              property_ids,
              has_quantity: hasQuantity,
              campaign_start_at,
              campaign_end_at,
              campaign_type,
              calendar_settings_attributes:
                finalArr.length > 0 ? finalArr : calendar_settings_attributes,
              attach_library_image_attributes,
              is_request_for_quote,
            };
          }
        }
      }),
    };

    if (image_type === 'attached') {
      if (image) {
        productForm['image'] = image;
      }
    } else {
      if (formState.library_image_id) {
        productForm['attach_library_image_attributes'] = {
          library_image_id: formState.library_image_id,
        };
      } else {
        productForm['attach_library_image_attributes'] = {
          library_image_id: formState?.images?.library_image_id,
        };
      }
    }

    if (mode === 'create') {
      props.createSubscription(productForm);
    } else {
      props.updateSubscription(productForm);
    }
  };
  const onCategoryFormInputChange = ({ target: { name, value } }) => {
    const newUi = { ...uiState.formData, [name]: value };
    updateUI({ ...uiState, formData: newUi });
  };

  const onVendorFormInputChange = ({ target: { name, value } }) => {
    const updatedCreateVendorForm = { ...createVendorForm, [name]: value };
    updateCreateVendorForm(updatedCreateVendorForm);
  };

  const onCategoryFormSubmit = values => {
    const _formData = { ...values, image: null };
    if (formData.images) {
      _formData.image = formData.images;
    } else {
      _formData.images = null;
    }
    createCategory(_formData);
  };

  const onVendorFormSubmit = values => {
    const { company_reg, contact, vendor_name, website } = values;
    const { picture } = createVendorForm;
    createVendor({
      company_reg,
      contact,
      vendor_name,
      website,
      picture,
      vendor_users_attributes: [],
    });
  };

  const handleRemoveItem = (setFieldValue, values, item, formItems) => {
    let updatedFormItems = [...values['formItems']];

    let newItem;

    if (item === 'single') {
      if (id !== 'create') {
        updatedFormItems = updatedFormItems.map(x => {
          return {
            ...x,
            _destroy: 'true',
          };
        });
        updatedFormItems.push(formItems);
        setFieldValue('formItems', updatedFormItems, false);
      } else {
        newItem = Object.assign({}, formItems);
        setFieldValue('formItems', [newItem], false);
      }
    } else {
      if (id !== 'create') {
        const temp = updatedFormItems;
        temp.map((x, index) => {
          if (x.id) {
            if (x === item) {
              updatedFormItems[index] = { ...x, _destroy: 'true' };
            }
          } else {
            if (x.sku === item.sku) {
              updatedFormItems.splice(index, 1);
            }
          }
          return null;
        });
      } else {
        updatedFormItems = updatedFormItems.filter(
          value => value.sku !== item.sku
        );
      }
      setFieldValue('formItems', [...updatedFormItems], false);
    }
  };

  const handleAddItem = (setFieldValue, values) => {
    let newItem = Object.assign({}, defaultItemForm);
    newItem = { ...newItem, sku: Math.random() };
    setFieldValue('formItems', [...values['formItems'], newItem], false);
  };

  const handleSingleOrMultipleItems = (e, setFieldValue, values) => {
    setSingleOrMultipleItems(e.target.value);
    if (id === 'create') {
      const formItems = {
        ...defaultItemForm,
        title: values.title,
        description: values.description,
        image: values.image,
        sku: Math.random(),
      };
      handleRemoveItem(setFieldValue, values, 'single', formItems);
      setFieldValue();
    }
  };

  const onTagFormSubmit = () => {
    createTag({
      property: {
        ...createTagForm,
        property_type: 'subscription',
      },
    });
  };

  // const onTagChange = value => {
  //   setSelectedTags([...value]);
  // };

  const [tagSearchKeyword, setTagSearchKeyword] = useState(null);
  useEffect(() => {
    getTags({
      page: 1,
      per: 30,
      query: tagSearchKeyword,
    });
  }, [tagSearchKeyword, getTags]);

  const { tagsData, fetchingTags } = props;
  // const tagsDataOption = [...tagsData.data, ...form.properties];
  const tagsDataOption = tagsData.data;

  // const formCategory = form.category? categories.find(x => x.id === form.category_id): null;
  const pageTitle =
    mode === 'create' ? 'Add New Subscription' : 'Edit Subscription';

  removeNullAttrsFromCalender(form);

  return (
    <>
      <div className="page-header">
        <div className="page-title">
          <LeftOutlined onClick={onClickBack} />
          <Row>
            <Col span={24}>
              <h4>{pageTitle}</h4>
            </Col>
            <Col>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <a href="/admin/">Home</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="/admin/list/subscription/">Subscriptions</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </div>
      <div className="page-content">
        <Formik
          initialValues={{
            ...formInitialValues['createSubscriptionForm'],
            ...form,
            formItems,
            campaign_start_at:
              formItems.campaign_start_at === null
                ? ''
                : formItems?.campaign_start_at
                ? formItems.campaign_start_at
                : '',
          }}
          enableReinitialize={true}
          validationSchema={formValidations['CreateSubscriptionFormValidation']}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <StyledForm onSubmit={handleSubmit}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Card bordered={false}>
                      <Card title=" Information" bordered={false}>
                        <StyledItemForm>
                          <div>
                            <Row gutter={24}>
                              <Col xs={24} sm={24} md={18}>
                                <Row gutter={24}>
                                  <Col xs={24} sm={24} md={6}>
                                    <div
                                      className="form-group"
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <label>Title</label>
                                      <Form.Item
                                        hasFeedback
                                        validateStatus={
                                          touched['title'] && errors['title']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['title'] && errors['title']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Input
                                          value={values.title}
                                          onChange={handleChange}
                                          name="title"
                                          placeholder="Enter product name"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={24} md={6}>
                                    <div
                                      className="form-group"
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <ProductCreateFieldTitle
                                        label="Category"
                                        btnText=""
                                        btnOnClick={() => {
                                          setFormStatus({
                                            showCreateCategoryForm: true,
                                            showCreateVendorForm: false,
                                            showCreateTagForm: false,
                                          });
                                          updateUI({
                                            ...uiState,
                                            formData:
                                              DEFAULT_CATEGORY_FORM_DATA,
                                          });
                                        }}
                                      />
                                      <Form.Item
                                        hasFeedback
                                        validateStatus={
                                          touched['category_id'] &&
                                          errors['category_id']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['category_id'] &&
                                          errors['category_id']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Select
                                          value={values.category_id}
                                          onChange={value => {
                                            handleChange({
                                              target: {
                                                name: 'category_id',
                                                value,
                                              },
                                            });
                                            setFieldValue(
                                              'sub_category_id',
                                              null
                                            );

                                            getSubCategoriesByFilter(value);
                                          }}
                                          placeholder="select category"
                                          notFoundContent={
                                            categoryloading ? (
                                              <Spin size="small" />
                                            ) : null
                                          }
                                          filterOption={false}
                                          onSearch={setcategorySearchKeyword}
                                          showSearch
                                        >
                                          {categories &&
                                            categories.data
                                              .filter(
                                                category =>
                                                  category['category_type'] ===
                                                  'subscription'
                                              )
                                              .map(category => (
                                                <Select.Option
                                                  key={category.id}
                                                  value={category.id}
                                                >
                                                  {category.title}
                                                </Select.Option>
                                              ))}
                                        </Select>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={24} md={6}>
                                    <div
                                      className="form-group"
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <ProductCreateFieldTitle
                                        label="Sub Category"
                                        btnText=""
                                        btnOnClick={() => {
                                          handleOpenSubCategoryDrawer();
                                        }}
                                      />
                                      <Form.Item
                                        disabled={
                                          values.category_id ? false : true
                                        }
                                        hasFeedback
                                        validateStatus={
                                          touched['sub_category_id'] &&
                                          errors['sub_category_id']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['sub_category_id'] &&
                                          errors['sub_category_id']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Select
                                          value={values.sub_category_id}
                                          onChange={value =>
                                            handleChange({
                                              target: {
                                                name: 'sub_category_id',
                                                value,
                                              },
                                            })
                                          }
                                          placeholder="select sub category"
                                          notFoundContent={
                                            categoryloading ? (
                                              <Spin size="small" />
                                            ) : null
                                          }
                                          filterOption={false}
                                          onSearch={setcategorySearchKeyword}
                                          showSearch
                                        >
                                          {subCategoryState?.map(subcat => (
                                            <Select.Option
                                              key={subcat.id}
                                              value={subcat.id}
                                            >
                                              {subcat.name}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col xs={24} sm={24} md={6}>
                                    <div
                                      className="form-group"
                                      style={{ marginBottom: '10px' }}
                                    >
                                      <ProductCreateFieldTitle
                                        label="Vendor"
                                        btnText=""
                                        btnOnClick={() => {
                                          setFormStatus({
                                            showCreateCategoryForm: false,
                                            showCreateVendorForm: true,
                                            showCreateTagForm: false,
                                          });
                                          updateCreateVendorForm({
                                            picture: null,
                                            vendor_name: '',
                                            company_reg: '',
                                            website: '',
                                            contact: '',
                                          });
                                        }}
                                      />
                                      <Form.Item
                                        hasFeedback
                                        validateStatus={
                                          touched['vendor_id'] &&
                                          errors['vendor_id']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['vendor_id'] &&
                                          errors['vendor_id']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Select
                                          value={values.vendor_id}
                                          onChange={value =>
                                            handleChange({
                                              target: {
                                                name: 'vendor_id',
                                                value,
                                              },
                                            })
                                          }
                                          placeholder="select vendor"
                                          notFoundContent={
                                            vendorsloading ? (
                                              <Spin size="small" />
                                            ) : null
                                          }
                                          filterOption={false}
                                          onSearch={setVendorSearchKeyword}
                                          showSearch
                                        >
                                          {vendors &&
                                            vendors.map(vendor => (
                                              <Select.Option
                                                key={vendor.id}
                                                value={vendor.id}
                                              >
                                                {vendor.vendor_name}
                                              </Select.Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                </Row>
                                <div
                                  className="form-group"
                                  style={{ marginTop: 16 }}
                                >
                                  <label> Description</label>
                                  <Form.Item
                                    hasFeedback
                                    validateStatus={
                                      touched['description'] &&
                                      errors['description']
                                        ? 'error'
                                        : null
                                    }
                                    help={
                                      touched['description'] &&
                                      errors['description']
                                    }
                                    style={{ margin: 0 }}
                                  >
                                    <Input.TextArea
                                      value={values.description}
                                      onChange={handleChange}
                                      name="description"
                                      placeholder="Enter description here"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col
                                xs={24}
                                sm={24}
                                md={6}
                                style={{ height: '200px' }}
                              >
                                <Radio.Group
                                  defaultValue={values.image_type}
                                  onChange={handleChange}
                                  name="image_type"
                                  buttonStyle="solid"
                                  style={{ marginBottom: '10px' }}
                                >
                                  <Radio.Button
                                    name="image_type"
                                    value={'attached'}
                                  >
                                    Upload Image
                                  </Radio.Button>
                                  <Radio.Button
                                    name="image_type"
                                    value={'library'}
                                  >
                                    Choose From Library
                                  </Radio.Button>
                                </Radio.Group>
                                {values.image_type === 'attached' ? (
                                  <ImageUpload
                                    value={
                                      values['images']
                                        ? getImageURI(values['images'].url)
                                        : null
                                    }
                                    onChange={value =>
                                      handleChange({
                                        target: {
                                          name: 'image',
                                          value: { data: value },
                                        },
                                      })
                                    }
                                    accept=".png, .jpg, .jpeg"
                                    error={errors}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      height: '250px',
                                      overflowY: 'scroll',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <Radio.Group
                                      onChange={handleChange}
                                      size="large"
                                      defaultValue={
                                        values['images']?.library_image_id
                                      }
                                      name="library_image_id"
                                    >
                                      {libraryImage?.map(item => (
                                        <Radio
                                          key={item?.id}
                                          className="radioImage"
                                          name="library_image_id"
                                          value={item?.id}
                                        >
                                          <Card>
                                            <Image
                                              src={getImageURI(
                                                item?.image_path?.url
                                              )}
                                              width={120}
                                              preview={false}
                                            />
                                          </Card>
                                        </Radio>
                                      ))}
                                    </Radio.Group>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </StyledItemForm>
                      </Card>
                      <CardWrapper
                        title="Items"
                        bordered={false}
                        extra={
                          <div className="form-group ">
                            <Radio.Group
                              onChange={e =>
                                handleSingleOrMultipleItems(
                                  e,
                                  setFieldValue,
                                  values
                                )
                              }
                              value={singleOrMultipleItems}
                            >
                              <Radio value="single">Single Item</Radio>
                              <Radio disabled value="multiple">
                                Multiple Items
                              </Radio>
                            </Radio.Group>
                          </div>
                        }
                      >
                        {values.formItems.length > 0 && (
                          <>
                            {/* <h1>2. Product Items</h1> */}
                            {values.formItems.map((item, index) => {
                              if (item['_destroy'] !== 'true') {
                                return (
                                  <Card
                                    key={'item' + index}
                                    style={{ marginTop: index !== 0 ? 16 : '' }}
                                  >
                                    <SubscriptionItemForm
                                      index={index}
                                      ref={itemForm =>
                                        (itemFormRefs[index] = itemForm)
                                      }
                                      key={index}
                                      selected={singleOrMultipleItems}
                                      initialState={item}
                                      handleRemoveItem={item => {
                                        return handleRemoveItem(
                                          setFieldValue,
                                          values,
                                          item
                                        );
                                      }}
                                      pageTitle={pageTitle}
                                      hasOptions={hasOptions}
                                      setTagSearchKeyword={setTagSearchKeyword}
                                      fetchingTags={fetchingTags}
                                      tagsDataOption={tagsDataOption}
                                      setFormStatus={setFormStatus}
                                      updateCreateTagForm={updateCreateTagForm}
                                      values={values}
                                      libraryImage={libraryImage}
                                      onInputChange={ev => {
                                        const { name, value } = ev['target'];
                                        let updatedFormItems = [
                                          ...values.formItems,
                                        ];
                                        updatedFormItems[index][name] = value;
                                        setFieldValue(
                                          'formItems',
                                          updatedFormItems,
                                          false
                                        );
                                      }}
                                      touched={
                                        touched['formItems']
                                          ? touched['formItems'][index]
                                          : null
                                      }
                                      errors={
                                        errors['formItems']
                                          ? errors['formItems'][index]
                                          : null
                                      }
                                      handleCheckbox={val => {
                                        let updatedFormItems = [
                                          ...values.formItems,
                                        ];
                                        updatedFormItems[index].term_perpetual =
                                          val;
                                        setFieldValue(
                                          'formItems',
                                          updatedFormItems,
                                          false
                                        );
                                      }}
                                      handleCheckbox2={(name, val) => {
                                        let updatedFormItems = [
                                          ...values.formItems,
                                        ];
                                        updatedFormItems[index][name] = val;
                                        setFieldValue(
                                          'formItems',
                                          updatedFormItems,
                                          false
                                        );
                                      }}
                                    />
                                  </Card>
                                );
                              }
                              return null;
                            })}
                          </>
                        )}
                        <Button
                          type="default"
                          onClick={() => handleAddItem(setFieldValue, values)}
                          block
                          style={{ marginTop: 16, width: 142 }}
                          disabled={
                            singleOrMultipleItems === '' ||
                            singleOrMultipleItems === 'single'
                          }
                        >
                          + ADD NEW ITEM
                        </Button>
                      </CardWrapper>
                      <Row gutter={16}>
                        <Col
                          xs={{ span: 6, push: 19 }}
                          md={{ span: 6, push: 18 }}
                        >
                          <Button
                            disabled={
                              submitting || singleOrMultipleItems === ''
                            }
                            onClick={handleSubmit}
                            type="primary"
                            htmlType="submit"
                            block
                          >
                            Save
                          </Button>
                        </Col>
                        <Col
                          xs={{ span: 6, push: 7 }}
                          md={{ span: 6, push: 6 }}
                        >
                          <Button
                            type="default"
                            block
                            disabled={submitting}
                            onClick={onClickBack}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Drawer
                    closable={false}
                    placement="right"
                    width={450}
                    visible={
                      formStatus['showCreateCategoryForm'] ||
                      formStatus['showCreateVendorForm'] ||
                      formStatus['showCreateTagForm']
                    }
                    onClose={handleCloseDrawer}
                  >
                    {formStatus['showCreateCategoryForm'] && formData && (
                      <CategoryForm
                        formData={formData}
                        formErrors={uiState.formErrors}
                        onSubmit={onCategoryFormSubmit}
                        onInputChange={onCategoryFormInputChange}
                        onDeleteClick={() => {}}
                        onCloseModal={handleCloseDrawer}
                        loading={categorySubmitting}
                        disabled={categorySubmitting}
                      />
                    )}
                    {formStatus['showCreateVendorForm'] && createVendorForm && (
                      <CreateVendorForm
                        formData={createVendorForm}
                        onSubmit={onVendorFormSubmit}
                        onInputChange={onVendorFormInputChange}
                        onDelete={() => {}}
                        onCloseModal={handleCloseDrawer}
                        loading={vendorSubmitting}
                        disabled={vendorSubmitting}
                      />
                    )}
                    {formStatus['showCreateTagForm'] && createTagForm && (
                      <>
                        <div className="drawer-header">
                          <h3>Add Option</h3>
                        </div>
                        <div className="drawer-body">
                          <form onSubmit={onTagFormSubmit}>
                            <div className="form-group">
                              <label>Option Title</label>
                              <Input
                                placeholder="Enter Option Title"
                                name="title"
                                value={createTagForm['title']}
                                onChange={ev =>
                                  updateCreateTagForm({
                                    title: ev['target']['value'],
                                  })
                                }
                              />
                            </div>
                            <Button
                              type="primary"
                              htmlType="submit"
                              block
                              loading={tagSubmitting}
                              disabled={tagSubmitting}
                            >
                              Save
                            </Button>
                          </form>
                        </div>
                      </>
                    )}
                  </Drawer>
                  <CreateSubCategoryDrawer
                    categoryDefaultValue={values.category_id}
                    isModalOpen={isModalOpen}
                    handleClose={handleCloseSubCategoryDrawer}
                  />
                </Row>
              </StyledForm>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

SubscriptionFormPage.propTypes = {
  tagsData: PropTypes.object,
  created: PropTypes.bool,
  fetchingTags: PropTypes.bool,
  categoryState: PropTypes.object,
  getCategories: PropTypes.func,
  getSubCategoriesByFilter: PropTypes.func,
  vendorsState: PropTypes.object,
  getVendors: PropTypes.func,
  createSubscription: PropTypes.func,
  updateSubscription: PropTypes.func,
  submitting: PropTypes.bool,
  formObject: PropTypes.object,
  getTags: PropTypes.func,
  updateUI: PropTypes.func,
  createCategory: PropTypes.func,
  createVendor: PropTypes.func,
  createTag: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  uiState: PropTypes.object,
  categorySubmitting: PropTypes.bool,
  categoryError: PropTypes.object,
  vendorSubmitting: PropTypes.bool,
  vendorError: PropTypes.object,
  tagSubmitting: PropTypes.bool,
  tagError: PropTypes.object,
  subCategoryState: PropTypes.any,
  getMedia: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    fetchingTags: state.tags.loading,
    tagsData: state.tags.tags,
    formObject: state.services.formObject,
    submitting: state.services.submitting,
    vendorsState: state.vendors,
    categoryState: state.categories,
    subCategoryState: state.subCategories.subCategoriesByFilter,
    uiState: state.categories.uiState,
    categorySubmitting: state.categories.submitting,
    categoryError: state.categories.error,
    vendorSubmitting: state.vendors.submitting,
    vendorError: state.vendors.error,
    tagSubmitting: state.tags.submitting,
    tagError: state.tags.error,
    created: state.subscription.created,
    libraryImage: state.subscription.media_library_images?.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories: q => dispatch(getCategories(q)),
    getSubCategoriesByFilter: id => dispatch(getSubCategoriesByFilter(id)),
    createCategory: data => dispatch(createCategory(data)),
    getVendors: q => dispatch(getVendors(q)),
    createSubscription: data => dispatch(createSubscription(data)),
    updateSubscription: data => dispatch(updateSubscription(data)),
    getSubscriptionById: data => dispatch(getSubscriptionById(data)),
    updateUI: payload => dispatch(updateUI(payload)),
    getTags: q => dispatch(getTags(q)),
    createVendor: data => dispatch(createVendor(data)),
    createTag: data => dispatch(createTag(data)),
    getMedia: () => dispatch(getMedia()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionFormPage);
