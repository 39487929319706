import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getSlides,
  getSlidesFailed,
  getSlidesSuccess,
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  createSlide,
  createSlideSuccess,
  createSlideFailed,
  updateSlide,
  updateSlideSuccess,
  updateSlideFailed,
  deleteSlide,
  deleteSlideFailed,
  deleteSlideSuccess,
} from './reducers';

const API_BASE_NAME = '/api/v1/admin/slide_show_images';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=banner`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getSlidesSaga({ payload }) {
  let action;
  let url = `${API_BASE_NAME}?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }

    const response = yield call(request, 'GET', url);
    action = getSlidesSuccess(response);
  } catch (error) {
    action = getSlidesFailed(error.message);
  }
  yield put(action);
}

function* createSlideSaga({ payload }) {
  console.log('payload', payload);
  let action;
  let response;
  const {
    active,
    content,
    end_date,
    start_date,
    title,
    mediaImageId,
    image_type,
    link_url,
    image,
    images,
    image_url,
  } = payload;
  try {
    if (image_type === 'attached') {
      response = yield call(request, 'POST', `${API_BASE_NAME}`, {
        slide_show_image: {
          active,
          content,
          end_date,
          start_date,
          title,
          link_url,
          image,
          images,
          image_url,
        },
      });
    } else {
      response = yield call(request, 'POST', `${API_BASE_NAME}`, {
        slide_show_image: {
          active,
          content,
          end_date,
          start_date,
          title,
          attach_library_image_attributes: { library_image_id: mediaImageId },
          link_url,
        },
      });
    }
    action = createSlideSuccess(response);
  } catch (error) {
    action = createSlideFailed(error.message);
  }
  yield put(action);
}

function* updateSlideSaga({ payload }) {
  let action;
  let response;
  const {
    active,
    content,
    end_date,
    start_date,
    title,
    mediaImageId,
    image_type,
    link_url,
    image,
    images,
    image_url,
  } = payload;
  try {
    if (image_type === 'attached') {
      response = yield call(request, 'PUT', `${API_BASE_NAME}/${payload.id}`, {
        slide_show_image: {
          active,
          content,
          end_date,
          start_date,
          title,
          link_url,
          image,
          images,
          image_url,
        },
      });
    } else {
      response = yield call(request, 'PUT', `${API_BASE_NAME}/${payload.id}`, {
        slide_show_image: {
          active,
          content,
          end_date,
          start_date,
          title,
          attach_library_image_attributes: { library_image_id: mediaImageId },
          link_url,
        },
      });
    }
    action = updateSlideSuccess(response);
  } catch (error) {
    action = updateSlideFailed(error.message);
  }
  yield put(action);
}

function* deleteSlideSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `${API_BASE_NAME}/${payload}`
    );
    action = deleteSlideSuccess(response);
  } catch (error) {
    action = deleteSlideFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getSlides, getSlidesSaga);
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(createSlide, createSlideSaga);
  yield takeLatest(updateSlide, updateSlideSaga);
  yield takeLatest(deleteSlide, deleteSlideSaga);
}
