import { notification } from 'antd';

// different types of error handdling
const showErrors = errors => {
  if (
    Array.isArray(errors) &&
    errors['length'] &&
    !Object.prototype.hasOwnProperty.call(errors[0], 'message')
  ) {
    return errors.forEach(error => {
      return _apiError(error);
    });
  } else if (Array.isArray(errors)) {
    return errors.forEach(error => {
      if (Array.isArray(error.message)) {
        return error.message.forEach(message => {
          _apiError(message);
        });
      }
      return _apiError(error.message);
    });
  }
  if (errors && errors.message) {
    return _apiError(errors.message);
  }
  _apiError(errors);
};

export const _apiError = payload => {
  notification.error({
    message: 'Alert',
    description: payload,
  });
};

export const apiError = payload => {
  showErrors(payload);
};
