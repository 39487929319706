import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  createMedia,
  createMediaSuccess,
  createMediaFailed,
  updateMedia,
  updateMediaSuccess,
  updateMediaFailed,
  deleteMedia,
  deleteMediaFailed,
  deleteMediaSuccess,
} from './reducers';

const API_BASE_NAME = '/api/v1/admin/library_images';

function* getMediaSaga({ payload }) {
  let action;
  let url = `${API_BASE_NAME}?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['categoryTab']) {
      url += `&image_type=${payload['categoryTab']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* createMediaSaga({ payload }) {
  let action;
  const { categoryTab, ...data } = payload;
  try {
    const response = yield call(request, 'POST', `${API_BASE_NAME}`, {
      library_image: data,
    });
    action = createMediaSuccess({ response, categoryTab: categoryTab });
  } catch (error) {
    action = createMediaFailed(error.message);
  }
  yield put(action);
}

function* updateMediaSaga({ payload }) {
  let action;
  const { categoryTab, ...data } = payload;
  try {
    const response = yield call(request, 'PUT', `${API_BASE_NAME}/${data.id}`, {
      library_image: data,
    });
    action = updateMediaSuccess({ response, categoryTab: categoryTab });
  } catch (error) {
    action = updateMediaFailed(error.message);
  }
  yield put(action);
}

function* deleteMediaSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `${API_BASE_NAME}/${payload}`
    );
    action = deleteMediaSuccess(response);
  } catch (error) {
    action = deleteMediaFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(createMedia, createMediaSaga);
  yield takeLatest(updateMedia, updateMediaSaga);
  yield takeLatest(deleteMedia, deleteMediaSaga);
}
