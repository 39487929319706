import React from 'react';
const WithDrawRequestIcon = props => (
  <span aria-label="message" className="anticon anticon-message">
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width="16px"
      height="16px"
      strokeWidth="1.5"
      {...props}
    >
      <path d="M17 16v2a2 2 0 01-2 2H5a2 2 0 01-2-2v-7a2 2 0 012-2h2m3-4H9a2 2 0 00-2 2v7a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-1m-1 4l-3 3m0 0l-3-3m3 3V3" />
    </svg>
  </span>
);

export default WithDrawRequestIcon;
