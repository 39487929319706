import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { capitalize, getImageURI } from '../../../utils/apisauce';
import {
  DeleteFilledIcon,
  EditFilledIcon,
} from '../../../components/Icons/Icons';

const TableColumns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    render: function avatar(text, record) {
      return (
        <Avatar
          src={getImageURI(record['image'] && record['image'].url)}
          icon={<UserOutlined />}
          size={'large'}
          shape="square"
        />
      );
    },
  },
  {
    title: 'Categories Name',
    key: 'title',
    render: function status(_, record) {
      return <span>{capitalize(record.title)}</span>;
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: description =>
      description ? (
        <Tooltip placement="topLeft" title={description}>
          {capitalize(description).substring(0, 20)}
          <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
            ...Read more
          </span>
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    title: 'Type',
    dataIndex: 'category_type',
    key: 'category_type',
    render: function status(_, record) {
      return (
        <span
          style={{
            textTransform: 'capitalize',
          }}
        >
          {record.category_type}
        </span>
      );
    },
  },
  {
    title: 'Status',
    key: 'status',
    align: 'center',
    render: function status(_, record) {
      const colorVal = {
        active: '#6ABB98',
        inactive: '#F1B13B',
        suspended: '#DF5E67',
      };
      return (
        <span
          style={{
            color: colorVal[record.status],
            textTransform: 'capitalize',
          }}
        >
          {record.status}
        </span>
      );
    },
  },
  {
    title: 'Actions',
    key: 'action',
    align: 'center',
    render: function action(text, record) {
      return (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || onDelete(record)}
          />
        </div>
      );
    },
  },
];

export default TableColumns;
