import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  getProfile,
  getProfileSuccess,
  getProfileFailed,
  archiveProduct,
  archiveProductFailed,
  archiveProductSuccess,
  unArchiveProduct,
  unArchiveProductFailed,
  unArchiveProductSuccess,
  getTags,
  getTagsSuccess,
  getTagsFailed,
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailed,
  getSubCategories,
  getSubCategoriesSuccess,
  getSubCategoriesFailed,
  getProducts,
  getProductsFailed,
  getProductsSuccess,
  createProduct,
  createProductSuccess,
  createProductFailed,
  updateProduct,
  updateProductSuccess,
  updateProductFailed,
  deleteProduct,
  deleteProductFailed,
  deleteProductSuccess,
  productbulkArchive,
  productbulkArchiveSuccess,
  productbulkArchiveFailed,
  productbulkUnarchive,
  productbulkUnarchiveSuccess,
  productbulkUnarchiveFailed,
  productbulkDelete,
  productbulkDeleteSuccess,
  productbulkDeleteFailed,
  getSubCategoriesByFilter,
  getSubCategoriesByFilterSuccess,
  getSubCategoriesByFilterFailed,
} from './reducers';

function* profileSaga() {
  let action;
  try {
    const response = yield call(request, 'GET', '/api/v1/profile');
    action = getProfileSuccess(response);
  } catch (error) {
    action = getProfileFailed(error.message);
  }
  yield put(action);
}

function* getTagSaga({ payload }) {
  let action;
  let url = `/api/v1/vendor/properties/?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }

    const response = yield call(request, 'GET', url);
    action = getTagsSuccess(response);
  } catch (error) {
    action = getTagsFailed(error.message);
  }
  yield put(action);
}

function* archiveProductSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/products/${id}/archive`
    );

    action = archiveProductSuccess(response);
  } catch (error) {
    action = archiveProductFailed(error.message);
  }
  yield put(action);
}

function* unArchiveProductSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/products/${id}/unarchive`
    );

    action = unArchiveProductSuccess(response);
  } catch (error) {
    action = unArchiveProductFailed(error.message);
  }
  yield put(action);
}
function* getCategoriesSaga({ payload }) {
  let action;
  let url = `/api/v1/vendor/categories?category_type=${
    payload.activeTab === 'all' ? '' : payload.activeTab
  }`;
  try {
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getCategoriesSuccess(response);
  } catch (error) {
    action = getCategoriesFailed(error.message);
  }
  yield put(action);
}

function* getSubCategoriesSaga() {
  let action;
  let url = `/api/v1/vendor/sub_categories`;
  try {
    const response = yield call(request, 'GET', url);
    action = getSubCategoriesSuccess(response);
  } catch (error) {
    action = getSubCategoriesFailed(error.message);
  }
  yield put(action);
}

function* getSubCategoriesByFilterSaga(payload) {
  let action;

  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/vendor/sub_categories?filter[category_id]=${payload.payload}`
    );
    action = getSubCategoriesByFilterSuccess(response);
  } catch (error) {
    action = getSubCategoriesByFilterFailed(error.message);
  }
  yield put(action);
}

function* getProductsSaga({ payload }) {
  let action;
  let url = '/api/v1/vendor/products?';
  try {
    if (payload['filterTab']) {
      url += `status=${payload['filterTab']}`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getProductsSuccess(response);
  } catch (error) {
    action = getProductsFailed(error.message);
  }
  yield put(action);
}

function* createProductSaga({ payload }) {
  let action;
  payload.items_attributes.map(({ image }, i) => {
    payload.items_attributes[i].images = image;
    delete payload.items_attributes[i].image;
    return null;
  });
  try {
    const response = yield call(request, 'POST', `/api/v1/vendor/products`, {
      product: payload,
    });
    action = createProductSuccess(response);
  } catch (error) {
    action = createProductFailed(error.message);
  }
  yield put(action);
}

function* updateProductSaga({ payload }) {
  let action;
  payload.items_attributes.map(({ image }, i) => {
    payload.items_attributes[i].images = image;
    delete payload.items_attributes[i].image;
    return null;
  });

  try {
    const id = payload.id;
    delete payload.id;
    const response = yield call(
      request,
      'PUT',
      `/api/v1/vendor/products/${id}`,
      { product: payload }
    );

    action = updateProductSuccess(response);
  } catch (error) {
    action = updateProductFailed(error.message);
  }
  yield put(action);
}

function* productbulkDeleteSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/vendor/products/bulk_destroy`,
      product
    );
    action = productbulkDeleteSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = productbulkDeleteFailed(error.message);
  }
  yield put(action);
}

function* deleteProductSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/vendor/products/${payload.id}`
    );

    action = deleteProductSuccess(response);
  } catch (error) {
    action = deleteProductFailed(error.message);
  }
  yield put(action);
}

function* productbulkArchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/products/bulk_archive`,
      product
    );
    action = productbulkArchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = productbulkArchiveFailed('');
  }
  yield put(action);
}

function* productbulkUnarchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/vendor/products/bulk_unarchive`,
      product
    );
    action = productbulkUnarchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = productbulkUnarchiveFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getTags, getTagSaga);
  yield takeLatest(getCategories, getCategoriesSaga);
  yield takeLatest(getSubCategories, getSubCategoriesSaga);
  yield takeLatest(getSubCategoriesByFilter, getSubCategoriesByFilterSaga);
  yield takeLatest(getProfile, profileSaga);
  yield takeLatest(getProducts, getProductsSaga);
  yield takeLatest(archiveProduct, archiveProductSaga);
  yield takeLatest(unArchiveProduct, unArchiveProductSaga);
  yield takeLatest(createProduct, createProductSaga);
  yield takeLatest(productbulkDelete, productbulkDeleteSaga);
  yield takeLatest(updateProduct, updateProductSaga);
  yield takeLatest(deleteProduct, deleteProductSaga);
  yield takeLatest(productbulkArchive, productbulkArchiveSaga);
  yield takeLatest(productbulkUnarchive, productbulkUnarchiveSaga);
}
