import { takeLatest, call, put, delay } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getJobById,
  getJobByIdFailed,
  getJobByIdSuccess,
  getClients,
  getClientsSuccess,
  getClientsFailed,
  getVendors,
  getVendorsByQuery,
  getVendorsByQueryDebounce,
  getVendorsSuccess,
  getVendorsFailed,
  getVendorWorkers,
  getVendorWorkersSuccess,
  getVendorWorkersFailed,
  getReportCustomers,
  getReportCustomersSuccess,
  getReportCustomersFailed,
  getReportWorkers,
  getReportWorkersSuccess,
  getReportWorkersFailed,
  getReportGroupedWorkers,
  getReportGroupedWorkersSuccess,
  getReportGroupedWorkersFailed,
  getReportWorkerDaily,
  getReportWorkerDailySuccess,
  getReportWorkerDailyFailed,
} from './reducers';

function* getJobByIdSaga({ payload }) {
  let action;

  try {
    const response = yield call(request, 'GET', `/api/v1/jobs/${payload}`);
    action = getJobByIdSuccess(response);
  } catch (error) {
    action = getJobByIdFailed(error.message);
  }
  yield put(action);
}

function* getClientsSaga({ payload }) {
  let action;
  let url = `/api/v1/clients/${payload.type}?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getClientsSuccess(response);
  } catch (error) {
    action = getClientsFailed(error.message);
  }
  yield put(action);
}

function* getVendorsSaga({ payload }) {
  let action;
  let url = `/api/v1/admin/vendors?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getVendorsSuccess(response);
  } catch (error) {
    action = getVendorsFailed(error.message);
  }
  yield put(action);
}

function* getVendorsByQuerySaga({ payload }) {
  let action;
  let url = `/api/v1/admin/vendors?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getVendorsSuccess(response);
  } catch (error) {
    action = getVendorsFailed(error.message);
  }
  yield put(action);
}

function* debounceGetVendorsByQuerySaga({ payload }) {
  yield delay(1000);
  yield put(getVendorsByQuery({ query: payload }));
}

function* getVendorWorkersSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/vendor_board/vendors/${payload}/vendor_users`
    );
    action = getVendorWorkersSuccess(response['vendor']['vendor_users']);
  } catch (error) {
    action = getVendorWorkersFailed(error.message);
  }
  yield put(action);
}

function* getReportCustomersSaga({ payload }) {
  const { selectedClient } = payload;
  let action;
  let url = `/api/v1/admin/reports/customer`;
  try {
    const params = {
      client_id: payload['client_id'],
      client_type:
        payload['client_type'] === 'personal'
          ? 'PersonalClient'
          : 'BusinessClient',
      start_date: payload['start_date'].format('DD-MM-YYYY'),
      end_date: payload['end_date'].format('DD-MM-YYYY'),
      address_ids: payload['address_ids'],
    };

    if (payload['page']) {
      params['page'] = payload['page'];
    }
    if (payload['per']) {
      params['per'] = payload['per'];
    }

    const response = yield call(request, 'GET', url, params);
    if (payload['client_type'] === 'personal') {
      if (
        selectedClient[0]['personal_users'] &&
        selectedClient[0]['personal_users']['length']
      ) {
        action = getReportCustomersSuccess({
          ...selectedClient[0]['personal_users'][0],
          meta: response['meta'],
          jobs: response['job_details'],
          start_date: payload['start_date'].format('DD-MM-YYYY'),
          end_date: payload['end_date'].format('DD-MM-YYYY'),
        });
      }
    } else if (payload['client_type'] === 'business') {
      if (selectedClient[0]) {
        action = getReportCustomersSuccess({
          ...selectedClient[0],
          meta: response['meta'],
          jobs: response['job_details'],
          start_date: payload['start_date'].format('DD-MM-YYYY'),
          end_date: payload['end_date'].format('DD-MM-YYYY'),
        });
      }
    }
  } catch (error) {
    action = getReportCustomersFailed(error.message);
  }
  yield put(action);
}

function* getReportWorkersSaga({ payload }) {
  const { selectedVendor, selectedWorker } = payload;
  let action;
  let url = `/api/v1/admin/reports/worker`;
  try {
    const params = {
      worker_id: payload['worker_id'],
      start_date: payload['start_date'].format('DD-MM-YYYY'),
      end_date: payload['end_date'].format('DD-MM-YYYY'),
    };

    if (payload['page']) {
      params['page'] = payload['page'];
    }
    if (payload['per']) {
      params['per'] = payload['per'];
    }

    const response = yield call(request, 'GET', url, params);
    if (selectedVendor) {
      action = getReportWorkersSuccess({
        ...selectedVendor,
        selectedWorker,
        meta: response['meta'],
        jobs: response['worker_details'],
        start_date: payload['start_date'].format('DD-MM-YYYY'),
        end_date: payload['end_date'].format('DD-MM-YYYY'),
      });
    }
  } catch (error) {
    action = getReportWorkersFailed(error.message);
  }
  yield put(action);
}
function* getReportGroupedWorkersSaga({ payload }) {
  const { selectedVendor, selectedWorker } = payload;
  let action;
  let url = `/api/v1/admin/reports/grouped_worker_by_service_date`;
  try {
    const params = {
      worker_id: payload['worker_id'],
      start_date: payload['start_date'].format('YYYY-MM-DD'),
      end_date: payload['end_date'].format('YYYY-MM-DD'),
    };

    if (payload['page']) {
      params['page'] = payload['page'];
    }
    if (payload['per']) {
      params['per'] = payload['per'];
    }

    const response = yield call(request, 'GET', url, params);
    if (selectedVendor) {
      action = getReportGroupedWorkersSuccess({
        ...selectedVendor,
        selectedWorker,
        meta: response['meta'],
        jobs: response,
        start_date: payload['start_date'].format('YYYY-MM-DD'),
        end_date: payload['end_date'].format('YYYY-MM-DD'),
      });
    }
  } catch (error) {
    action = getReportGroupedWorkersFailed(error.message);
  }
  yield put(action);
}

function* getReportWorkerDailySaga({ payload }) {
  const { selectedVendor } = payload;
  let action;
  let url = `/api/v1/admin/reports/department_daily`;
  try {
    const params = {
      vendor_id: payload['vendor_id'],
      date: payload['date'].format('DD-MM-YYYY'),
    };

    if (payload['page']) {
      params['page'] = payload['page'];
    }
    if (payload['per']) {
      params['per'] = payload['per'];
    }

    const response = yield call(request, 'GET', url, params);
    if (selectedVendor) {
      action = getReportWorkerDailySuccess({
        ...selectedVendor,
        meta: response['meta'],
        workers: response['department_daily_details'],
        date: payload['date'].format('DD-MM-YYYY'),
      });
    }
  } catch (error) {
    action = getReportWorkerDailyFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getJobById, getJobByIdSaga);
  yield takeLatest(getClients, getClientsSaga);
  yield takeLatest(getVendors, getVendorsSaga);
  yield takeLatest(getVendorsByQuery, getVendorsByQuerySaga);
  yield takeLatest(getVendorsByQueryDebounce, debounceGetVendorsByQuerySaga);
  yield takeLatest(getVendorWorkers, getVendorWorkersSaga);
  yield takeLatest(getReportCustomers, getReportCustomersSaga);
  yield takeLatest(getReportWorkers, getReportWorkersSaga);
  yield takeLatest(getReportGroupedWorkers, getReportGroupedWorkersSaga);
  yield takeLatest(getReportWorkerDaily, getReportWorkerDailySaga);
}
