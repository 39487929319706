import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  login,
  loginSuccess,
  loginFailed,
  impersonateLogin,
  impersonateLoginSuccess,
  impersonateLoginFailed,
  getProfile,
  getProfileSuccess,
  getProfileFailed,
  searchVendors,
  searchVendorsSuccess,
  searchVendorsFailed,
} from './reducers';

function* profileSaga() {
  let action;
  try {
    const response = yield call(request, 'GET', '/api/v1/profile');
    action = getProfileSuccess(response);
  } catch (error) {
    action = getProfileFailed(error.message);
  }
  yield put(action);
}

function* loginSaga({ payload }) {
  const body = {
    grant_type: 'password',
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
    resource_owner_type: 'VendorUser',
    ...payload,
  };

  let action;
  try {
    const response = yield call(request, 'POST', '/oauth/token', body);
    action = loginSuccess(response);
  } catch (error) {
    action = loginFailed(error.message);
  }

  yield put(action);
}

function* impersonateLoginSaga({ payload }) {
  const body = {
    vendor_id: payload.vendorId,
  };

  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/vendors/users/${payload.userId}/impersonate`,
      body
    );
    action = impersonateLoginSuccess(response);
  } catch (error) {
    action = impersonateLoginFailed(error.message);
  }

  yield put(action);
}

function* searchVendorsSaga() {
  let action;
  try {
    const params = {
      service_date: 'service_date = 2021-12-12',
    };

    const response = yield call(
      request,
      'GET',
      `/api/v1/vendor/vendor_board/vendor`,
      params
    );
    action = searchVendorsSuccess(response);
  } catch (error) {
    action = searchVendorsFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(login, loginSaga);
  yield takeLatest(impersonateLogin, impersonateLoginSaga);
  yield takeLatest(searchVendors, searchVendorsSaga);
  yield takeLatest(getProfile, profileSaga);
}
