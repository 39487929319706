import React from 'react';
import { Table } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

import './Index.css';
class DataTable extends React.PureComponent {
  state = {
    expandedRows: [],
    expandedKeys: [],
    selectedRowKeys: [],
  };

  get primaryKey() {
    return this.props.rowKey || Math.random() * 10000;
  }

  handleExpand = key => {
    const rows = this.state.expandedRows;
    const index = rows.indexOf(key);
    if (index > -1) {
      rows.splice(index, 1);
    } else {
      rows.push(key);
    }
    return this.setState({ expandedRows: [...rows] });
  };

  onExpand = (expanded, record) => {
    const keys = this.state.expandedKeys;
    let id =
      record?.product_type === 'product'
        ? record.id
        : record?.order?.id
        ? record.order.id
        : 'worker_id' in record
        ? record?.worker_id
        : record?.service_date;

    const expandedKeys = expanded
      ? keys.concat(id)
      : keys.filter(k => k !== id);
    this.setState({ expandedKeys });
  };
  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys: selectedRowKeys });
    this.props?.onSelectRecord && this.props.onSelectRecord(selectedRowKeys);
  };

  render() {
    const {
      data,
      loading,
      columns,
      pagination,
      rowSelectionFlag,
      onClickRow,
      onShow,
      footer,
      handleTableChange,
      expandedRowRender,
      activeTab,
      // onSelectRecord
      childrenColumnName = 'children',
    } = this.props;
    const { expandedKeys, selectedRowKeys } = this.state;

    let rowSelection;

    if (this.props?.rowSelection) {
      rowSelection = this.props.rowSelection;
    } else
      rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };

    if (pagination) {
      const { limit, total, page, onChange, data: source } = pagination;
      return (
        <>
          {/* <div style={{ marginBottom: 16 }}>
            <Button type="primary" onClick={this.start} disabled={!hasSelected} loading={loading}>
              Reload
            </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
            </span>
          </div> */}
          <Table
            className={'my-table'}
            size="small"
            scroll={{ x: 'max-content' }}
            rowKey={this.primaryKey}
            onRow={(record, index) => {
              return {
                onClick: e => onClickRow && onClickRow(e, record),
                onDoubleClick: () => onShow && onShow(record, index),
                className: expandedKeys.includes(
                  record?.product_type === 'product'
                    ? record.id
                    : record?.worker_id
                    ? 'worker_id' in record
                      ? record.worker_id
                      : record.service_date
                    : record?.order?.id
                )
                  ? 'expand-parent' //expand-parent removed for hover color changing of nested table
                  : '',
              };
            }}
            pagination={{
              pageSize: limit,
              total: total,
              current: page,
              onChange: onChange,
              pageSizeOptions: ['5', '10', '20', '50'],
              showSizeChanger: true,
            }}
            hideOnSinglePage={true}
            loading={loading}
            dataSource={data || source}
            columns={columns}
            rowSelection={rowSelectionFlag ? rowSelection : ''}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender,
              expandIcon:
                activeTab === 'department-daily' || activeTab === 'worker'
                  ? ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <CaretUpOutlined onClick={e => onExpand(record, e)} />
                      ) : (
                        <CaretDownOutlined onClick={e => onExpand(record, e)} />
                      )
                  : '',
            }}
            onExpand={this.onExpand}
            // scroll={{ x: 'max-content' }}
            childrenColumnName={childrenColumnName}
          />
        </>
      );
    }

    return (
      <Table
        size="small"
        scroll={{ x: 'max-content', y: 400 }}
        rowKey={this.primaryKey}
        onRow={(record, index) => ({
          onClick: e => onClickRow && onClickRow(e, record),
          onDoubleClick: () => onShow && onShow(record, index),
        })}
        hideOnSinglePage={true}
        loading={loading}
        dataSource={data}
        columns={columns}
        pagination={pagination}
        rowSelection={rowSelectionFlag ? rowSelection : ''}
        footer={footer ? footer : null}
        expandedRowRender={expandedRowRender}
      />
    );
  }
}

DataTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  columns: PropTypes.array,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  rowSelection: PropTypes.object,
  onClickRow: PropTypes.func,
  onShow: PropTypes.func,
  footer: PropTypes.element,
  handleTableChange: PropTypes.func,
  rowKey: PropTypes.any,
  expandedRowRender: PropTypes.func,
};

export default DataTable;
