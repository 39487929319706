import { UserOutlined } from '@ant-design/icons';
import { Avatar, message, Upload } from 'antd';
import { dig } from 'digdata';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

class AvatarUpload extends React.Component {
  state = {
    value: this.props.value,
  };

  componentDidUpdate(prevProps) {
    const value = dig(this.props, 'value');
    if (
      typeof value === 'string' &&
      value !== prevProps['value'] &&
      value.startsWith('http')
    ) {
      this.props.onChange(undefined);
      this.setState({ value });
    } else if (!value && prevProps['value']) {
      this.setState({ value: null });
    }
  }

  handleRequest = ({ file }) => {
    const reader = new FileReader();
    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 1) {
      message.error('File size must be lesser than 1 MB');
      return;
    }

    reader.addEventListener('load', () => {
      const { onChange } = this.props;

      if (onChange) {
        onChange(reader.result);
      }

      this.setState({
        loading: false,
        value: reader.result,
      });
    });
    reader.readAsDataURL(file);
  };

  render() {
    const { value } = this.state;
    const { icon, accept } = this.props;

    let uploadProps = {
      className: 'avatar-uploader',
      name: 'avatar',
      listType: 'picture-card',
      showUploadList: false,
      customRequest: this.handleRequest,
    };

    if (accept) {
      uploadProps['accept'] = accept;
    }

    return (
      <Upload {...uploadProps}>
        <Avatar src={value} icon={icon ? icon : <UserOutlined />} size={100} />
      </Upload>
    );
  }
}

AvatarUpload.propTypes = {
  icon: PropTypes.element,
  value: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};

export default AvatarUpload;
