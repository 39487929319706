import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_TAGS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};
const slice = createSlice({
  name: 'medialibrary',
  initialState: {
    loading: false,
    submitting: false,
    formOpen: false,
    media_library_images: DEFAULT_TAGS,
    error: '',
    errors: {},
  },
  reducers: {
    getMedia: state => {
      state.loading = true;
    },
    getMediaSuccess: (state, { payload }) => {
      state.loading = false;
      state.media_library_images = {
        data: payload.library_images,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getMediaFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    createMedia: state => {
      state.submitting = true;
      state.message = message.loading('Creating a new media ...', 0);
    },
    createMediaSuccess: (state, { payload }) => {
      const { response, categoryTab } = payload;
      state.message();
      state.submitting = false;
      state.formOpen = false;
      state.errors = {};
      const media_library_image = response.library_image;
      if (categoryTab === response?.library_image?.image_type) {
        state.media_library_images.data = [
          media_library_image,
          ...state.media_library_images.data,
        ];
      }
      notification.success({
        message: 'New Media',
        description: `New Media has been created.`,
      });
    },
    createMediaFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.formOpen = true;
      apiError(payload);
      if (payload && payload.length > 0) {
        state.errors = payload[0].meta.errors;
      }
    },
    updateMedia: state => {
      state.submitting = true;
      state.message = message.loading('Updating media show info...', 0);
    },
    updateMediaSuccess: (state, { payload }) => {
      const { response, categoryTab } = payload;
      state.message();
      state.formOpen = false;
      state.submitting = false;
      state.errors = {};
      const media_library_image = response.library_image;
      if (categoryTab === response?.library_image?.image_type) {
        const idx = state.media_library_images.data.findIndex(
          el => el?.id === media_library_image?.id
        );
        if (idx >= 0) {
          state.media_library_images.data[idx] = {
            ...state.media_library_images.data[idx],
            ...media_library_image,
          };
        }
      }
      const idx = state.media_library_images.data.findIndex(
        el => el?.id === media_library_image?.id
      );
      if (idx >= 0) {
        state.media_library_images.data[idx] = {
          ...state.media_library_images.data[idx],
          ...media_library_image,
        };
      }

      notification.success({
        message: 'Media Updated',
        description: `Media has been updated.`,
      });
    },
    updateMediaFailed: (state, { payload }) => {
      state.message();
      state.submitting = true;
      state.formOpen = true;
      apiError(payload);
      if (payload && payload.length > 0) {
        state.errors = payload[0].meta.errors;
      }
    },
    deleteMedia: state => {
      state.message = message.loading('Deleting Media ...', 0);
    },
    deleteMediaSuccess: (state, { payload }) => {
      state.message();
      state.media_library_images.data = state.media_library_images.data.filter(
        x => x.id !== payload.library_image.id
      );
      notification.success({
        message: 'Media Deleted',
        description: `Media has been deleted.`,
      });
    },
    deleteMediaFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    startEditMedia: (state, { payload }) => {
      state.formOpen = payload;
    },
  },
});

export const {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  createMedia,
  createMediaSuccess,
  createMediaFailed,
  updateMedia,
  updateMediaSuccess,
  updateMediaFailed,
  deleteMedia,
  deleteMediaFailed,
  deleteMediaSuccess,
  startEditMedia,
} = slice.actions;
export default slice.reducer;
