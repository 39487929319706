import { Card, Tooltip, message, Form, Button, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJobs, udpateJobNotes } from '../reducers';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

import DataTable from '../../../../components/DataTable';

import { capitalize } from '../../../../utils/apisauce';
import MobileTable from './MobTable';

const statusLabels = {
  pending_schedule: {
    color: '#F1B13B',
    label: 'Unassigned',
  },
  scheduled: {
    color: '#34A6BD',
    label: 'In Scheduled',
  },
  in_progress: {
    color: '#6ABB98',
    label: 'In Progress',
  },
  completed: {
    color: '#828282',
    label: 'Completed',
  },
  cancelled: {
    color: '#DF5E67',
    label: 'Cancelled/Skipped',
  },
};

class AllJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageQuery: {
        page: 1,
        per: 20,
        query: null,
      },
      windowSize: false,
    };
  }
  onFinish = values => {
    this.setState({ recallGetJobApi: !this.state.recallGetJobApi });
    this.setState({ jobModal: false });
    const { udpateJobNotes } = this.props;
    const { job_notes } = values;
    this.state.jobNotesId !== null
      ? udpateJobNotes({
          id: this.state.jobNotesId,
          job_notes,
        })
      : message.error('something wrong');
  };

  componentDidUpdate(prevProps, prevState) {
    const { getJobs, queryString } = this.props;
    const { pageQuery } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getJobs({ ...pageQuery, query: queryString });
      } else {
        getJobs(pageQuery);
      }
    }
  }

  handleWindowSize = () => {
    if (window.innerWidth < 767) {
      this.setState({ windowSize: true });
    } else {
      this.setState({ windowSize: false });
    }
  };

  componentDidMount() {
    const { getJobs, queryString } = this.props;
    const { pageQuery } = this.state;
    if (window.innerWidth < 767) {
      this.handleWindowSize();
    }
    window.addEventListener('resize', this.handleWindowSize);
    if (queryString) {
      getJobs({ ...pageQuery, query: queryString });
    } else {
      getJobs(pageQuery);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSize);
  }

  onClickRow = (_, { id }) => {
    const { history, jobDetailsModalOpen } = this.props;
    history.push('#' + id);
    jobDetailsModalOpen();
  };

  closeModal = () => {
    const { history } = this.props;
    history.goBack();
  };

  tableColumns = () => [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
      width: 70,
    },
    {
      title: 'Status',
      key: 'job_status',
      fixed: 'left',
      width: 120,
      render: (_, record) =>
        record?.job_status ? (
          <span
            style={{
              color: statusLabels[record.job_status]
                ? statusLabels[record.job_status].color
                : '',
            }}
          >
            {statusLabels[record.job_status]
              ? statusLabels[record.job_status].label
              : ''}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Service Date',
      key: 'service_date',
      dataIndex: 'service_date',
      defaultSortOrder: 'ascend',
      width: 120,
      sorter: (a, b) => {
        const dateA = new Date(a.service_date).getTime();
        const dateB = new Date(b.service_date).getTime();
        return dateA - dateB;
      },
      render: (_, record) =>
        record?.service_date
          ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-',
    },
    {
      title: 'Order ID',
      key: 'order_id',
      width: 140,
      render: (_, record) => (record?.order ? record?.order?.id : '-'),
    },
    {
      title: 'Order Amount',
      key: 'order_amount',
      width: 140,
      render: (_, record) => (record?.order ? record?.order?.amount : '-'),
    },
    {
      title: 'Created By',
      key: 'created_by',
      width: 140,
      render: (_, record) =>
        record?.creator_type
          ? capitalize(record?.creator_type)
          : 'Order System',
    },
    {
      title: 'Job Title',
      key: 'title',
      width: 180,
      render: (_, record) => (record?.title ? capitalize(record.title) : '-'),
    },
    {
      title: 'Client Name',
      key: 'client_name',
      width: 180,
      render: (_, record) =>
        record?.client_type === 'PersonalClient'
          ? record?.client?.title
          : record?.client?.business_name
          ? record.client.business_name
          : '-',
    },
    {
      title: 'Client Address',
      key: 'client_address',

      render: (_, record) =>
        record?.address ? (
          <Tooltip
            title={`${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`}
            placement="top"
          >
            {capitalize(
              `${record.address?.address_title}, ${record.address?.address}, ${record.address?.unit_number}, ${record.address?.postal_code}`
            ).substring(0, 20)}
            <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
              ...Read more
            </span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      title: 'Vendor Name',
      key: 'vendor_id',
      width: 120,
      render: (_, record) =>
        record?.vendor?.vendor_name
          ? capitalize(record.vendor.vendor_name)
          : '-',
    },
    {
      title: 'Assigned Worker',
      key: 'worker',
      width: 120,
      render: (_, record) =>
        record['assigned_worker']
          ? Object?.keys(record['assigned_worker'])?.length > 0
            ? `${capitalize(
                record['assigned_worker']?.first_name
              )} ${capitalize(record['assigned_worker']?.last_name)}`
            : '-'
          : '-',
    },
    {
      title: 'Preferred Time',
      key: 'preferred_time',
      width: 120,
      render: (_, record) =>
        record['preferred_time'] ? record['preferred_time'] : '-',
    },
    {
      title: 'Assigned Time',
      key: 'assigned_time',
      width: 120,
      render: (_, record) =>
        record['assigned_time'] ? record['assigned_time'] : '-',
    },
    {
      title: 'Service Duration',
      key: 'job_duration_in_minutes',
      width: 120,
      render: (_, record) =>
        record['job_duration_in_minutes']
          ? record['job_duration_in_minutes'] + ' min'
          : '-',
    },
    {
      title: 'Check-in Time',
      key: 'checkin_time',
      width: 120,
      render: (_, record) =>
        record['checkin_time'] ? record['checkin_time'] : '-',
    },
    {
      title: 'Check-out Time',
      key: 'checkout_time',
      width: 120,
      render: (_, record) =>
        record['checkout_time'] ? record['checkout_time'] : '-',
    },
    {
      title: 'Active Job Duration',
      width: 120,
      render: (_, record) => {
        if (record?.checkin_time && record?.checkout_time) {
          return record['total_job_time'];
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Job Notes',
      key: 'job_notes',
      render: (_, record) =>
        record['job_notes'] ? (
          <div style={{ display: 'flex' }}>
            {`${
              record['job_notes']['length'] > 25
                ? record['job_notes'].slice(0, 25) + '...'
                : record['job_notes']
            }`}
            <span
              className="ant-table-action-cell"
              style={{
                color: '#2d9cdb',
                cursor: 'pointer',
                marginLeft: '10px',
              }}
              onClick={e =>
                e.stopPropagation() ||
                this.setState({
                  jobModal: true,
                  jobNotesValue: record['job_notes'] ? record['job_notes'] : '',
                  jobNotesId: record['id'] ? record['id'] : null,
                })
              }
            >
              Edit
            </span>
          </div>
        ) : (
          <span
            onClick={e =>
              e.stopPropagation() ||
              this.setState({
                jobModal: true,
                jobNotesValue: '',
                jobNotesId: record['id'] ? record['id'] : null,
              })
            }
            style={{ color: '#2d9cdb', cursor: 'pointer' }}
          >
            Add
          </span>
        ),
    },
    {
      title: 'Customer Notes',
      key: 'customer_notes',
      width: 120,
      render: (_, record) =>
        record['customer_notes']
          ? `${
              record['customer_notes']['length'] > 25
                ? record['customer_notes'].slice(0, 25) + '...'
                : record['customer_notes']
            }`
          : '-',
    },
    {
      title: 'Repeating',
      key: 'repeating_job_id',
      fixed: 'right',
      width: 100,
      render: (_, record) => {
        if (record['repeating_job_id']) {
          return <span style={{ color: '#059c5a' }}>Yes</span>;
        } else {
          return <span style={{ color: '#DF5E67' }}>No</span>;
        }
      },
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { pageQuery } = this.state;
    const { order } = sorter;

    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    updatedPageQuery['order'] = order;
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const { TextArea } = Input;
    const { loading, jobs } = this.props;
    const { limit, total, page, data } = jobs;
    return (
      <>
        {this.state.windowSize ? (
          <Card
            title="All Jobs"
            className="overflow-x-auto"
            style={{ marginTop: 15 }}
          >
            <MobileTable
              records={data}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              allTab={true}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        ) : (
          <Card
            title="All Jobs"
            className="overflow-x-auto"
            style={{ marginTop: 15 }}
          >
            <DataTable
              loading={loading}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              onClickRow={this.onClickRow}
              columns={this.tableColumns()}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        )}
        <Modal
          visible={this.state.jobModal}
          onCancel={() => {
            this.setState({ jobModal: false });
          }}
          footer={null}
          destroyOnClose
          bodyStyle={{ padding: '40px 50px 20px 50px' }}
        >
          <Form
            name="form"
            className="login-form"
            initialValues={{
              job_notes: this.state.jobNotesValue,
              remember: true,
            }}
            onFinish={this.onFinish}
          >
            <Form.Item
              name="job_notes"
              rules={[
                {
                  required: true,
                  message: 'Please input text here',
                },
              ]}
            >
              <TextArea
                value="this is the job notes"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

AllJobs.propTypes = {
  loading: PropTypes.bool,
  jobs: PropTypes.object,
  getJobs: PropTypes.func,
  history: PropTypes.object,
  queryString: PropTypes.string,
  jobDetailsModalOpen: PropTypes.func,
  udpateJobNotes: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.vendorJobs.loading,
    jobs: state.vendorJobs.jobs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJobs: payload => dispatch(getJobs(payload)),
    udpateJobNotes: id => dispatch(udpateJobNotes(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AllJobs));
