import { Card } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { dig } from 'digdata';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import { getAccounts } from './reducers';
// import { propTypes } from 'qrcode.react';
// import { getImageURI } from '../../utils/apisauce';
// import CreateVendor from './components/create-vendor';
// import TableSearch from '../../components/TableSearch';
import {
  MoneyCollectOutlinedIcon,
  SolutionOutlinedIcon,
} from '../../components/Icons/Icons';

class CompanyAccounts extends React.PureComponent {
  state = {
    modalOpened: false,
    selectedRow: null,
    formData: null,
    lastSearch: '',
    dataSource: [],
    pageQuery: {
      page: 1,
      per: 20,
    },
    queryString: '',
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      getAccounts,
      accounts,
      // submitting, error
    } = this.props;
    const { pageQuery, queryString } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getAccounts({ ...pageQuery, query: queryString });
      } else {
        getAccounts(pageQuery);
      }
    }

    if (accounts && prevProps['accounts'] !== accounts) {
      this.setState({ dataSource: accounts });
    }
  }

  componentDidMount() {
    const { getAccounts } = this.props;
    const { pageQuery } = this.state;
    getAccounts(pageQuery);
  }

  // onSearch = () => {
  //   const { getAccounts } = this.props;
  //   const { pageQuery, queryString } = this.state;
  //   const updatedPageQuery = { ...pageQuery, page: 1 };
  //   this.setState({ pageQuery: updatedPageQuery }, () =>
  //     getAccounts({ query: queryString, per: pageQuery['per'] })
  //   );
  // };

  // closeModal = () => {
  //   this.setState({
  //     modalOpened: false,
  //     selectedRow: null,
  //     formData: null,
  //   });
  // };

  onClickRow = record => {
    const {
      history: { push },
    } = this.props;
    const {
      title,
      account: { id },
    } = record;
    push({
      pathname: `/admin/company_accounts/${id}/transactions`,
      state: { accountTitle: title },
    });
  };
  onClickGroupedTrans = record => {
    const {
      history: { push },
    } = this.props;
    const {
      title,
      account: { id },
    } = record;
    push({
      pathname: `/admin/company_accounts/${id}/grouped_transactions`,
      state: { accountTitle: title },
    });
  };

  // onCreate = () => {
  //   this.setState({
  //     modalOpened: 1,
  //     selectedRow: null,
  //     formData: {
  //       picture: null,
  //       vendor_name: '',
  //       company_reg: '',
  //       website: '',
  //       contact: '',
  //     },
  //   });
  // };

  // onEdit = record => {
  //   this.setState({
  //     modalOpened: 1,
  //     selectedRow: null,
  //     formData: {
  //       id: record.id,
  //       picture_path: record.picture_path,
  //       vendor_name: record.vendor_name,
  //       company_reg: record.company_reg,
  //       website: record.website,
  //       contact: record.contact,
  //     },
  //   });
  // };

  // onDelete = record => {
  //   this.setState({
  //     modalOpened: 2,
  //     selectedRow: record,
  //     formData: null,
  //   });
  // };

  // onSubmit = values => {
  //   const { formData } = this.state;

  //   if (formData.id) {
  //     const { updateVendor } = this.props;
  //     updateVendor({ ...formData, ...values, picture: formData['picture'] });
  //   } else {
  //     const { createVendor } = this.props;
  //     createVendor({
  //       ...formData,
  //       ...values,
  //       picture: formData['picture'],
  //       vendor_users_attributes: [],
  //     });
  //   }
  //   // this.closeModal();
  // };

  // performDelete = () => {
  //   const { selectedRow } = this.state;
  //   const { deleteVendor } = this.props;

  //   deleteVendor(selectedRow.id);
  //   this.closeModal();
  // };

  // onInputChange = ({ target: { name, value } }) => {
  //   const formData = { ...this.state.formData };

  //   formData[name] = value;
  //   this.setState({ formData });
  // };

  tableColumns = () => [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      columnWidth: '20%',

      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Title',
      key: 'title',
      columnWidth: '20%',

      dataIndex: 'title',
    },
    {
      title: 'Account Id',
      key: 'account_id',
      columnWidth: '20%',

      render: (text, record) => <span>{record.account.id}</span>,
    },
    {
      title: 'Balance',
      key: 'balance',
      columnWidth: '20%',

      render: (text, record) => <span>{record.account.formatted_balance}</span>,
    },
    {
      title: 'Actions',
      key: 'trans_details',
      columnWidth: '20%',

      render: (text, record) => (
        <div className="ant-table-action-cell">
          <MoneyCollectOutlinedIcon
            color={'#2d9cdb'}
            onClick={e => e.stopPropagation() || this.onClickRow(record)}
          />
          <SolutionOutlinedIcon
            color={'#2d9cdb'}
            onClick={e =>
              e.stopPropagation() || this.onClickGroupedTrans(record)
            }
          />
        </div>
      ),
    },
  ];

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = pagination => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    this.setState({ pageQuery: updatedPageQuery });
  };

  render() {
    const {
      // modalOpened,
      // selectedRow,
      // formData,
      pageQuery,
      dataSource,
      // queryString,
    } = this.state;
    const { loading, metaInfo } = this.props;

    return (
      <div className="vendors-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Company Accounts</h4>
          </div>
        </div>
        <div className="page-content">
          {/* <TableSearch
            placeholder="Search Vendor"
            btnText="Search"
            onBtnClick={this.onSearch}
            value={queryString}
            onInputChange={ev =>
              this.setState({ queryString: ev['target']['value'] })
            }
          /> */}
          <Card title="Accounts" style={{ marginTop: 15 }}>
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              data={dataSource}
              columns={this.tableColumns()}
              // onClickRow={this.onClickRow}
              pagination={{
                limit: pageQuery['per'],
                total: metaInfo ? metaInfo['total_count'] : 0,
                page: pageQuery['page'],
                data: dataSource,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        </div>
      </div>
    );
  }
}

CompanyAccounts.propTypes = {
  loading: PropTypes.bool,
  accounts: PropTypes.array,
  metaInfo: PropTypes.object,
  getAccounts: PropTypes.func,
  history: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.companyAccounts.loading,
    accounts: state.companyAccounts.accounts,
    submitting: state.companyAccounts.submitting,
    error: state.companyAccounts.error,
    metaInfo: state.companyAccounts.meta,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAccounts: payload => dispatch(getAccounts(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyAccounts));
