import React from 'react';
import { CloseOutlined, DeleteFilled, UserOutlined } from '@ant-design/icons';
import { Button, Input, Form, Select } from 'antd';
import { dig } from 'digdata';
import PropTypes from 'prop-types';
import AvatarUpload from '../../../components/AvatarUpload';
import { getImageURI } from '../../../utils/apisauce';
import { Formik } from 'formik';

const { Option, OptGroup } = Select;

// import { formInitialValues } from '../../../utils/formsInitialValues';
// import { formValidations } from '../../../utils/formValidations';
const CAT_TYPES = ['PRODUCT', 'CLASSES', 'SERVICE', 'SUBSCRIPTION'];

export default function CreateVendor(props) {
  const {
    formData,
    onDelete,
    closeModal,
    onInputChange,
    onSubmit,
    loading,
    disabled,
    handleChange,
    categories,
  } = props;

  return (
    <>
      <div className="drawer-header">
        <h3>{formData.id ? 'Edit Sub Category' : 'Add Sub Category'}</h3>
        <div className="header-actions">
          {formData.id && (
            <DeleteFilled
              className="action-primary"
              onClick={() => onDelete(formData)}
            />
          )}
          <CloseOutlined onClick={closeModal} />
        </div>
      </div>
      <div className="drawer-body">
        <Formik
          initialValues={{
            ...formData,
          }}
          //   validationSchema={formValidations['CreateVendorFormValidation']}
          onSubmit={values => onSubmit(values)}
        >
          {({ errors, touched, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="user-avatar">
                  <AvatarUpload
                    value={
                      formData['image']
                        ? getImageURI(dig(formData, 'image.url'))
                        : null
                    }
                    icon={<UserOutlined />}
                    onChange={value =>
                      onInputChange({
                        target: {
                          name: 'image',
                          value: { data: value },
                        },
                      })
                    }
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                {formData.id && (
                  <div className="form-group">
                    <label>Sub Category ID</label>
                    <span>{formData.id}</span>
                  </div>
                )}
                <div className="form-group">
                  <label>Sub Category Name</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['name'] && errors['name'] ? 'error' : null
                    }
                    help={touched['name'] && errors['name']}
                    style={{ margin: 0 }}
                  >
                    <Input
                      name="name"
                      value={formData.name}
                      placeholder="Enter Sub Category Name"
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Category</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['category_id'] && errors['category_id']
                        ? 'error'
                        : null
                    }
                    help={touched['category_id'] && errors['category_id']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      placeholder="Select Category"
                      style={{ width: '100%' }}
                      value={formData?.category?.title}
                      onChange={value =>
                        handleChange({
                          target: { name: 'category_id', value },
                        })
                      }
                      disabled={formData.id}
                    >
                      {CAT_TYPES.map(cat => (
                        <OptGroup label={cat}>
                          {categories &&
                            categories.map(cItem => {
                              return (
                                cItem.category_type.toLowerCase() ===
                                  cat.toLowerCase() && (
                                  <Option value={cItem.id}>
                                    {cItem.title}
                                  </Option>
                                )
                              );
                            })}
                        </OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <Form.Item
                    hasFeedback
                    validateStatus={
                      touched['status'] && errors['status'] ? 'error' : null
                    }
                    help={touched['status'] && errors['status']}
                    style={{ margin: 0 }}
                  >
                    <Select
                      placeholder="Select status"
                      style={{ width: '100%' }}
                      value={formData.status}
                      onChange={value =>
                        handleChange({
                          target: { name: 'status', value },
                        })
                      }
                    >
                      <Select.Option value="active">Active</Select.Option>
                      <Select.Option value="inactive">Inactive</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                  disabled={disabled}
                >
                  Save
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

CreateVendor.propTypes = {
  formData: PropTypes.object,
  onDelete: PropTypes.func,
  closeModal: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};
