import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getClasses,
  getClassesFailed,
  getClassesSuccess,
  createClass,
  createClassSuccess,
  createClassFailed,
  updateClass,
  updateClassSuccess,
  updateClassFailed,
  archiveClass,
  archiveClassFailed,
  archiveClassSuccess,
  unArchiveClass,
  unArchiveClassFailed,
  unArchiveClassSuccess,
  deleteClass,
  deleteClassFailed,
  deleteClassSuccess,
  classbulkDelete,
  classbulkDeleteSuccess,
  classbulkDeleteFailed,
  classbulkArchive,
  classbulkArchiveSuccess,
  classbulkArchiveFailed,
  classbulkUnarchive,
  classbulkUnarchiveSuccess,
  classbulkUnarchiveFailed,
} from './reducers';

function* getMediaSaga() {
  let action;
  let url = `/api/v1/admin/library_images`;
  try {
    url += `?image_type=list`;

    const response = yield call(request, 'GET', url);
    action = getMediaSuccess(response);
  } catch (error) {
    action = getMediaFailed(error.message);
  }
  yield put(action);
}

function* getClassesSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/classes?';
  try {
    if (payload['filterTab']) {
      url += `status=${payload['filterTab']}`;
    }
    if (payload['page']) {
      url += `&page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getClassesSuccess(response);
  } catch (error) {
    action = getClassesFailed(error.message);
  }
  yield put(action);
}

function* createClassSaga({ payload }) {
  let action;

  try {
    const response = yield call(request, 'POST', `/api/v1/admin/classes`, {
      classes: payload,
    });

    action = createClassSuccess(response);
  } catch (error) {
    action = createClassFailed(error.message);
  }
  yield put(action);
}

function* updateClassSaga({ payload }) {
  let action;

  try {
    const id = payload.id;
    delete payload.id;
    const response = yield call(request, 'PUT', `/api/v1/admin/classes/${id}`, {
      classes: payload,
    });
    action = updateClassSuccess(response);
  } catch (error) {
    action = updateClassFailed(error.message);
  }
  yield put(action);
}

function* archiveClassSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/classes/${id}/archive`
    );

    action = archiveClassSuccess(response);
  } catch (error) {
    action = archiveClassFailed(error.message);
  }
  yield put(action);
}

function* unArchiveClassSaga({ payload }) {
  let action;
  try {
    const id = payload.id;
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/classes/${id}/unarchive`
    );

    action = unArchiveClassSuccess(response);
  } catch (error) {
    action = unArchiveClassFailed(error.message);
  }
  yield put(action);
}

function* deleteClassSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/products/${payload.id}`
    );

    action = deleteClassSuccess(response);
  } catch (error) {
    action = deleteClassFailed(error.message);
  }
  yield put(action);
}

function* classbulkDeleteSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const product = {
    product: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/products/bulk_destroy`,
      product
    );
    action = classbulkDeleteSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = classbulkDeleteFailed(error.message);
  }
  yield put(action);
}

function* classbulkArchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const classes = {
    classes: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/classes/bulk_archive`,
      classes
    );
    action = classbulkArchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = classbulkArchiveFailed('');
  }
  yield put(action);
}

function* classbulkUnarchiveSaga({ payload }) {
  let action;
  const idsString = payload.toString();
  const classes = {
    classes: {
      ids: idsString,
    },
  };
  try {
    const response = yield call(
      request,
      'PATCH',
      `/api/v1/admin/classes/bulk_unarchive`,
      classes
    );
    action = classbulkUnarchiveSuccess({
      response,
      ids: payload,
    });
  } catch (error) {
    action = classbulkUnarchiveFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getMedia, getMediaSaga);
  yield takeLatest(getClasses, getClassesSaga);
  yield takeLatest(createClass, createClassSaga);
  yield takeLatest(updateClass, updateClassSaga);
  yield takeLatest(archiveClass, archiveClassSaga);
  yield takeLatest(unArchiveClass, unArchiveClassSaga);
  yield takeLatest(deleteClass, deleteClassSaga);
  yield takeLatest(classbulkDelete, classbulkDeleteSaga);
  yield takeLatest(classbulkArchive, classbulkArchiveSaga);
  yield takeLatest(classbulkUnarchive, classbulkUnarchiveSaga);
}
