import React from 'react';
const JobFeedbackIcon = props => (
  <span aria-label="message" className="anticon anticon-message">
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      width="16px"
      height="16px"
      strokeWidth="1.5"
      {...props}
    >
      <path d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
    </svg>
  </span>
);

export default JobFeedbackIcon;
