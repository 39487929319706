import React from 'react';
import DataTable from '../../../components/DataTable';
import PropTypes from 'prop-types';
import { capitalize } from '../../../utils/apisauce';

function OrdersTable({ dataSource }) {
  const tableColumns = () => [
    {
      title: <span className="table-column-title">Title</span>,
      key: 'title',
      render: (text, record) =>
        record.title ? capitalize(record['title']) : '',
    },
    {
      title: <span className="table-column-title">Description</span>,
      key: 'desc',
      render: (text, record) =>
        record.description ? record['description'] : '',
    },
    {
      title: <span className="table-column-title">Additional Notes</span>,
      key: 'notes',
      render: (text, record) =>
        record.additional_notes ? capitalize(record['additional_notes']) : '',
    },
    {
      title: <span className="table-column-title">Status</span>,
      key: 'status',
      dataIndex: 'status',
      align: 'center',
    },
  ];

  return (
    <DataTable
      // rowKey={record =>  'id'}
      // loading={loading}
      data={dataSource}
      columns={tableColumns()}
      // onClickRow={() => {}}
      // pagination={pagination}
    />
  );
}

OrdersTable.propTypes = {
  dataSource: PropTypes.array,
  // pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  // loading: PropTypes.bool,
  // footer: PropTypes.func,
};

export default OrdersTable;
