import React from 'react';
import DataTable from '../../components/DataTable';
import {
  jobDetailRepeatingTableColums,
  jobDetailSingleTableColums,
} from './columns';
import PropTypes from 'prop-types';

function jobDetailTable({ dataSource, pagination, loading }) {
  return (
    <DataTable
      rowKey={record => record['id'] || 'id'}
      loading={loading}
      data={dataSource}
      columns={
        dataSource[0].job_type == 'single'
          ? jobDetailSingleTableColums()
          : jobDetailRepeatingTableColums()
      }
      onClickRow={() => {}}
      pagination={pagination}
    />
  );
}

jobDetailTable.propTypes = {
  dataSource: PropTypes.array,
  pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  loading: PropTypes.bool,
  footer: PropTypes.func,
};

export default jobDetailTable;
