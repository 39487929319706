import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../components/DataTable';
import { tableRecipientColumns } from './columns';

function RecipientListTable(props) {
  const { dataSource } = props;
  console.log(props);
  return (
    <DataTable
      rowKey={record => record['client_id'] || 'id'}
      loading={false}
      data={dataSource}
      columns={tableRecipientColumns()}
      onClickRow={() => {}}
    />
  );
}

RecipientListTable.propTypes = {
  pagination: PropTypes.object,
  dataSource: PropTypes.array.isRequired,
};

export default RecipientListTable;
