import React, { useEffect, useCallback, useState } from 'react';

import StyledForm from '../styled-components/form-wrapper';
import ImageUpload from '../../../../components/ImageUpload';
import CardWrapper from '../styled-components/card-wrapper';
import {
  Button,
  Input,
  Select,
  Card,
  Row,
  Col,
  Breadcrumb,
  Form,
  message,
  Radio,
} from 'antd';
import request from '../../../../utils/apisauce';
import {
  createProduct,
  updateProduct,
  getProductById,
  getCategories,
  getProfile,
} from '../reducers';
import { getSubCategoriesByFilter } from '../reducers';
import { connect } from 'react-redux';
import ProductItemForm from './product-item-form';
import StyledItemForm from '../styled-components/form-split';
// import CategoryForm from '../../CategoriesPage/components/category-form';
// import CreateVendorForm from '../../VendorPage/components/create-vendor';
import { LeftOutlined, CloseCircleFilled } from '@ant-design/icons';
import { getImageURI } from '../../../../utils/apisauce';
import PropTypes from 'prop-types';
import {
  Formik,
  // useField
} from 'formik';
import { formInitialValues } from '../../../../utils/formsInitialValues';
import { formValidations } from '../../../../utils/formValidations';

const ProductCreateFieldTitle = props => (
  <div
    className="product-create-field-title-container"
    style={{ marginBottom: 0 }}
  >
    <label>
      <h4 className="product-create-field-title">{props['label']}</h4>
    </label>
    <button
      className="product-create-field-title-btn"
      onClick={props['btnOnClick']}
    >
      {props['btnText']}
    </button>
  </div>
);

ProductCreateFieldTitle.propTypes = {
  btnOnClick: PropTypes.func,
  btnText: PropTypes.string,
  label: PropTypes.string,
};

const AddItemForms = props => (
  <Card
    title={props['title']}
    className="card-container"
    extra={
      <CloseCircleFilled
        className="close-icon"
        onClick={props['onCloseIconClick']}
      />
    }
  >
    {props['children']}
  </Card>
);

AddItemForms.propTypes = {
  onCloseIconClick: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// import the reducers here so you can access delete,create,update
const defaultForm = {
  title: '',
  description: '',
  product_type: 'product',
  vendor_id: '',
  image: null,
  items: [],
  properties: [],
  // sku: Math.random(),
  category_id: null,
  sub_category_id: null,
};

const defaultItemForm = {
  title: '',
  stock_count: '',
  amount: '',
  unit: '',
  description: '',
  image: null,
  images: [],
};

// to later on suffix * dynamically when see how required is determiend from form passed
function ProductFormPage(props) {
  const {
    history: { push },
    created,
    vendor,
  } = props;

  const [id] = useState(props.match.params.id);
  const [form, setForm] = useState(defaultForm);
  const [formItems, setFormItems] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [mode, setMode] = useState(null);
  const [singleOrMultipleItems, setSingleOrMultipleItems] = useState('');
  const { categories, getCategories, getProfile } = props;
  const { subCategoryState, getSubCategoriesByFilter } = props;
  const itemFormRefs = [];
  const onClickBack = useCallback(() => {
    push('/vendor/list/products');
  }, [push]);

  useEffect(() => {
    getProfile();
    getCategories({
      // query: categorySearchKeyword,
      per: 30,
      activeTab: 'product',
    });
  }, [getCategories, getProfile]);

  useEffect(() => {
    if (created) {
      push('/vendor/list/products');
    }
  }, [created, push]);

  useEffect(() => {
    getSubCategoriesByFilter(null);
    // const {getProductById} = props;
    // getProductById(true);
    // differentiate if id is create dont fetch data
    // const { id } = props.match.params;

    setMode(id);

    const fetchCategoriesAndVendors = async response => {
      // getTags({
      //   page: 1,
      //   per_page: 20,
      //   // query: query
      // })
      // const vendorsResponse = await request('GET', `/api/v1/admin/vendors`);
      // if (vendorsResponse) {
      //   // response.vendor_id = vendorsResponse.categories.map(
      //   //   (v) => v.id,
      //   // )[0];
      //   if (response.vendor) {
      //     const vendor = vendorsResponse.find(x => x.id === response.vendor.id);
      //     if (!vendor) {
      //       vendorsResponse.push(response.vendor);
      //     }
      //     response.vendor_id = response.vendor.id;
      //   }
      //   setVendors(vendorsResponse);
      // }
      // const categoriesResponse = await request(
      //   'GET',
      //   '/api/v1/admin/categories',
      // );

      // if (categoriesResponse) {
      //   if (response.category) {
      //     const category = categoriesResponse.categories.find(x => x.id === response.category.id);
      //     if (!category) {
      //       categoriesResponse.categories.push(response.category);
      //     }
      //     response.category_id = response.category.id;
      //   }
      //   setCategories(categoriesResponse.categories); // to map over for selection option
      // }
      return response;
    };

    const fetchProductsData = id =>
      request('GET', `/api/v1/vendor/products/${id}`);

    if (id !== 'create') {
      message.loading('fetching data...');

      fetchProductsData(id).then(async response => {
        if (response.product && response.product.properties) {
          setSelectedTags(
            response.product.properties.map(x => ({
              value: x.id,
              label: x.title,
            }))
          );
        }
        if (response.product && response.product.vendor) {
          response.product.vendor_id = response.product.vendor.id;
        }
        if (response.product && response.product.category) {
          response.product.category_id = response.product.category.id;
        }
        if (response.product && response.product.sub_category) {
          response.product.sub_category_id = response.product.sub_category.id;
        }
        if (response.product && response.product.items) {
          response.product.items = response.product.items.map(x => ({
            ...x,
            images: x.images,
            image: null,
          }));
          setFormItems([...response.product.items]);

          response.product.category_id &&
            getSubCategoriesByFilter(response.product.category_id);
        }
        if (response.product && response.product.image) {
          response.product.images = response.product.image;
          response.product.image = null;
        }
        if (response?.product?.items?.length > 1) {
          setSingleOrMultipleItems('multiple');
        } else {
          setSingleOrMultipleItems('single');
        }
        try {
          const responseWithCatPlusVendors = await fetchCategoriesAndVendors(
            response.product
          );
          setForm(responseWithCatPlusVendors);
          message.destroy();
        } catch (error) {
          message.destroy();
          console.log(error);
        }
      });
    } else {
      fetchCategoriesAndVendors({})
        .then(responseWithCatPlusVendors => {
          setForm({ ...defaultForm, ...responseWithCatPlusVendors });
          message.destroy();
        })
        .catch(() => {
          message.destroy();
        });
    }
  }, [id, getSubCategoriesByFilter]);

  const { submitting } = props;
  const formObject = props.formObject;
  useEffect(() => {
    if (formObject === null) {
      return;
    }
    onClickBack();
  }, [formObject, onClickBack]);

  const onSubmit = formState => {
    const {
      product_type,
      title,
      description,
      // vendor_id,
      category_id,
      sub_category_id,
      selectedTags,
      formItems,
      image,
    } = formState;

    let productForm = {
      id: formState['id'] || null,
      product_type,
      title,
      description,
      vendor_id: vendor?.vendor_user?.vendor?.id,
      category_id,
      sub_category_id,
      property_ids: selectedTags.map(x => x.value),
      items_attributes: formItems.map(formItem => {
        const {
          title,
          description,
          commission_percentage,
          amount,
          stock_count,
          unit,
          sku,
          image,
          images,
        } = formItem;

        if (formItem['id']) {
          return formItem;
        } else {
          return {
            title,
            description,
            commission_percentage,
            amount,
            stock_count,
            unit,
            sku,
            image,
            images,
          };
        }
      }),
    };

    if (image && image['data']) {
      productForm['image'] = image;
    }

    if (mode === 'create') {
      props.createProduct(productForm);
    } else {
      props.updateProduct(productForm);
    }
  };

  // const onCategoryFormInputChange = ({ target: { name, value } }) => {
  //   const newUi = { ...uiState.formData, [name]: value };
  //   updateUI({ ...uiState, formData: newUi });
  // };

  const handleRemoveItem = (setFieldValue, values, item, formItems) => {
    let updatedFormItems = [...values['formItems']];

    let newItem;

    if (item === 'single') {
      if (id !== 'create') {
        updatedFormItems = updatedFormItems.map(x => {
          return {
            ...x,
            _destroy: 'true',
          };
        });
        updatedFormItems.push(formItems);
        setFieldValue('formItems', updatedFormItems, false);
      } else {
        newItem = Object.assign({}, formItems);
        setFieldValue('formItems', [newItem], false);
      }
    } else {
      if (id !== 'create') {
        const temp = updatedFormItems;
        temp.map((x, index) => {
          if (x.id) {
            if (x === item) {
              updatedFormItems[index] = { ...x, _destroy: 'true' };
            }
          } else {
            if (x.sku === item.sku) {
              updatedFormItems.splice(index, 1);
            }
          }
          return null;
        });
      } else {
        updatedFormItems = updatedFormItems.filter(
          value => value.sku !== item.sku
        );
      }
      setFieldValue('formItems', [...updatedFormItems], false);
    }
  };

  const handleAddItem = (setFieldValue, values) => {
    const newItem = Object.assign({}, defaultItemForm);
    newItem.sku = Math.random();
    setFieldValue('formItems', [...values['formItems'], newItem], false);
  };

  const handleSingleOrMultipleItems = (e, setFieldValue, values) => {
    setSingleOrMultipleItems(e.target.value);
    if (id === 'create') {
      const formItems = {
        ...defaultItemForm,
        title: values.title,
        description: values.description,
        sku: Math.random(),
      };
      handleRemoveItem(setFieldValue, values, 'single', formItems);
      setFieldValue();
    }
  };

  const handleClone = (newItem, setFieldValue, values) => {
    const newItm = { ...newItem };
    delete newItm.id;
    const cloneItems = {
      ...defaultItemForm,
      ...newItm,
      sku: Math.random(),
    };
    setFieldValue('formItems', [...values['formItems'], cloneItems], false);
  };

  // const onTagChange = value => {
  //   setSelectedTags([...value]);
  // };

  // const tagsDataOption = [...tagsData.data, ...form.properties];

  // const formCategory = form.category? categories.find(x => x.id === form.category_id): null;
  const pageTitle = mode === 'create' ? 'Add New Product' : 'Edit Product';

  return (
    <>
      <div className="page-header">
        <div className="page-title">
          <LeftOutlined onClick={onClickBack} />
          <Row>
            <Col span={24}>
              <h4>{pageTitle}</h4>
            </Col>
            <Col>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <a href="vendor/">Home</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="/vendor/list/products">Products</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{pageTitle} </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </div>
      <div className="page-content">
        <Formik
          initialValues={{
            ...formInitialValues['createProductForm'],
            ...form,
            selectedTags,
            formItems,
          }}
          enableReinitialize={true}
          validationSchema={formValidations['CreateProductFormValidation']}
          onSubmit={values => onSubmit(values)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <StyledForm onSubmit={handleSubmit}>
                <Row gutter={24}>
                  <Col span={24}>
                    <Card bordered={false}>
                      <Card title="New Product" bordered={false}>
                        <StyledItemForm>
                          <div>
                            <Row gutter={24}>
                              <Col span={20}>
                                <Row gutter={24}>
                                  <Col span={24 / 3}>
                                    <div className="form-group">
                                      <label>Title</label>
                                      <Form.Item
                                        hasFeedback
                                        validateStatus={
                                          touched['title'] && errors['title']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['title'] && errors['title']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Input
                                          value={values.title}
                                          onChange={handleChange}
                                          name="title"
                                          placeholder="Enter product name"
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={24 / 3}>
                                    <div className="form-group">
                                      <label>Select Category</label>
                                      <Form.Item
                                        hasFeedback
                                        validateStatus={
                                          touched['category_id'] &&
                                          errors['category_id']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['category_id'] &&
                                          errors['category_id']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Select
                                          value={values.category_id}
                                          onChange={value => {
                                            handleChange({
                                              target: {
                                                name: 'category_id',
                                                value,
                                              },
                                            });
                                            setFieldValue(
                                              'sub_category_id',
                                              null
                                            );

                                            getSubCategoriesByFilter(value);
                                          }}
                                          placeholder="select category"
                                          filterOption={false}
                                          showSearch
                                        >
                                          {categories &&
                                            categories.data
                                              .filter(
                                                category =>
                                                  category['category_type'] ===
                                                  'product'
                                              )
                                              .map(category => (
                                                <Select.Option
                                                  key={category.id}
                                                  value={category.id}
                                                >
                                                  {category.title}
                                                </Select.Option>
                                              ))}
                                        </Select>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={24 / 3}>
                                    <div className="form-group">
                                      <label>Sub Category</label>
                                      <Form.Item
                                        disabled={
                                          values.category_id ? false : true
                                        }
                                        hasFeedback
                                        validateStatus={
                                          touched['sub_category_id'] &&
                                          errors['sub_category_id']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['sub_category_id'] &&
                                          errors['sub_category_id']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Select
                                          value={values.sub_category_id}
                                          onChange={value =>
                                            handleChange({
                                              target: {
                                                name: 'sub_category_id',
                                                value,
                                              },
                                            })
                                          }
                                          placeholder="select sub category"
                                          // notFoundContent={
                                          //   categoryloading ? (
                                          //     <Spin size="small" />
                                          //   ) : null
                                          // }
                                          filterOption={false}
                                          // onSearch={setcategorySearchKeyword}
                                          showSearch
                                        >
                                          {subCategoryState?.map(subcat => (
                                            <Select.Option
                                              key={subcat.id}
                                              value={subcat.id}
                                            >
                                              {subcat.name}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col span={24 / 3}>
                                    <div className="form-group">
                                      <Form.Item
                                        hidden={true}
                                        hasFeedback
                                        validateStatus={
                                          touched['vendor_id'] &&
                                          errors['vendor_id']
                                            ? 'error'
                                            : null
                                        }
                                        help={
                                          touched['vendor_id'] &&
                                          errors['vendor_id']
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        <Select
                                          // value={vendor.vendor_user.vendor.id}
                                          defaultValue={
                                            vendor?.vendor_user?.vendor?.id
                                          }
                                          onChange={value =>
                                            handleChange({
                                              target: {
                                                name: 'vendor_id',
                                                value,
                                              },
                                            })
                                          }
                                          filterOption={false}
                                        ></Select>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                </Row>
                                <div
                                  className="form-group"
                                  style={{ marginTop: 16 }}
                                >
                                  <label> Description</label>
                                  <Form.Item
                                    hasFeedback
                                    validateStatus={
                                      touched['description'] &&
                                      errors['description']
                                        ? 'error'
                                        : null
                                    }
                                    help={
                                      touched['description'] &&
                                      errors['description']
                                    }
                                    style={{ margin: 0 }}
                                  >
                                    <Input.TextArea
                                      value={values.description}
                                      onChange={handleChange}
                                      name="description"
                                      placeholder="Enter description here"
                                    />
                                  </Form.Item>
                                </div>
                              </Col>
                              <Col span={4}>
                                <ImageUpload
                                  value={
                                    values['images']
                                      ? getImageURI(values['images'].url)
                                      : null
                                  }
                                  onChange={value =>
                                    handleChange({
                                      target: {
                                        name: 'image',
                                        value: { data: value },
                                      },
                                    })
                                  }
                                  accept=".png, .jpg, .jpeg"
                                  error={errors}
                                />
                              </Col>
                            </Row>
                          </div>
                        </StyledItemForm>
                      </Card>
                      <CardWrapper
                        title={`Items `}
                        bordered={false}
                        extra={
                          <div className="form-group ">
                            <Radio.Group
                              onChange={e =>
                                handleSingleOrMultipleItems(
                                  e,
                                  setFieldValue,
                                  values
                                )
                              }
                              value={singleOrMultipleItems}
                            >
                              <Radio value="single">Single Item</Radio>
                              <Radio value="multiple">Multiple Items</Radio>
                            </Radio.Group>
                          </div>
                        }
                      >
                        {values.formItems.length > 0 && (
                          <>
                            {/* <h1>2. Product Items</h1> */}
                            {values.formItems.map((item, index) => {
                              if (item['_destroy'] !== 'true') {
                                return (
                                  <Card
                                    key={'item' + index}
                                    style={{ marginTop: index !== 0 ? 16 : '' }}
                                  >
                                    <ProductItemForm
                                      index={index}
                                      ref={itemForm =>
                                        (itemFormRefs[index] = itemForm)
                                      }
                                      selected={singleOrMultipleItems}
                                      initialState={item}
                                      handleRemoveItem={item => {
                                        return handleRemoveItem(
                                          setFieldValue,
                                          values,
                                          item
                                        );
                                      }}
                                      handleClone={item =>
                                        handleClone(item, setFieldValue, values)
                                      }
                                      onInputChange={ev => {
                                        const { name, value } = ev['target'];
                                        let updatedFormItems = [
                                          ...values.formItems,
                                        ];

                                        updatedFormItems[index][name] = value;

                                        setFieldValue(
                                          'formItems',
                                          updatedFormItems,
                                          false
                                        );
                                      }}
                                      touched={
                                        touched['formItems']
                                          ? touched['formItems'][index]
                                          : null
                                      }
                                      errors={
                                        errors['formItems']
                                          ? errors['formItems'][index]
                                          : null
                                      }
                                    />
                                  </Card>
                                );
                              }
                              return null;
                            })}
                          </>
                        )}

                        <Button
                          type="default"
                          onClick={() => handleAddItem(setFieldValue, values)}
                          block
                          style={{ marginTop: 16, width: 142 }}
                          disabled={
                            singleOrMultipleItems === '' ||
                            singleOrMultipleItems === 'single'
                          }
                        >
                          + ADD NEW ITEM
                        </Button>
                      </CardWrapper>
                      <Row gutter={16}>
                        <Col
                          xs={{ span: 6, push: 19 }}
                          md={{ span: 5, push: 19 }}
                        >
                          <Button
                            disabled={
                              submitting || singleOrMultipleItems === ''
                            }
                            onClick={handleSubmit}
                            type="primary"
                            htmlType="submit"
                            block
                          >
                            Save
                          </Button>
                        </Col>
                        <Col
                          xs={{ span: 6, push: 7 }}
                          md={{ span: 4, push: 10 }}
                        >
                          <Button
                            type="default"
                            block
                            disabled={submitting}
                            onClick={onClickBack}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </StyledForm>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

ProductFormPage.propTypes = {
  getCategories: PropTypes.func,
  getSubCategoriesByFilter: PropTypes.func,
  getProfile: PropTypes.func,
  createProduct: PropTypes.func,
  updateProduct: PropTypes.func,
  submitting: PropTypes.bool,
  formObject: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  tagError: PropTypes.object,
  categories: PropTypes.array,
  created: PropTypes.string,
  vendor: PropTypes.object,
  subCategoryState: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    formObject: state.vendorProducts.formObject,
    submitting: state.vendorProducts.submitting,
    // vendorsState: state.vendors,
    categories: state.vendorProducts.categories,
    subCategoryState: state.vendorProducts.subCategoriesByFilter,
    created: state.vendorProducts.created,
    vendor: state.vendorProducts.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories: q => dispatch(getCategories(q)),
    getSubCategoriesByFilter: id => dispatch(getSubCategoriesByFilter(id)),
    createProduct: data => dispatch(createProduct(data)),
    updateProduct: data => dispatch(updateProduct(data)),
    getProductById: data => dispatch(getProductById(data)),
    getProfile: () => dispatch(getProfile()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormPage);
