import React, { useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import StyledItemForm from '../styled-components/form-split';
// import ImageUpload from '../../../components/ImageUpload';
import StyledFormItem from '../styled-components/form-item';
import moment from 'moment';
// import moment from 'moment';
import {
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  DatePicker,
  Radio,
  // TimePicker,
} from 'antd';
import ReactTimePicker from '../../../components/TimePicker';
import request from '../../../utils/apisauce';
import { DeleteFilledIcon } from '../../../components/Icons/Icons';

// import ServiceOptions from '../../../components/OptionTable/dummyData.json';
// import Form from 'antd/lib/form/Form';

const dateFormat = 'YYYY-MM-DD';
// const timeFormat = 'HH:mm';

const NumberValidation = item => {
  if (!Number(item)) {
    return {
      validateStatus: false,
      help: 'Only numbers allowed',
    };
  }
  return {
    validateStatus: true,
  };
};

const ConciergeItemForm = React.forwardRef(
  ({ initialState, inputChange, handleRemoveItem }, ref) => {
    const [itemState, setItemState] = useState(initialState);
    const [isCreateJob, setIsCreateJob] = useState(
      initialState?.is_create_job || false
    );
    const [vendors, setVendors] = useState([]);

    useImperativeHandle(
      ref,
      () => ({
        getState: () => itemState,
      }),
      [itemState]
    );

    useEffect(() => {
      getVendorsList();
    }, []);

    const onInputChange = e => {
      const { name, value } = e.target;
      setItemState({ ...itemState, [name]: value });
      inputChange(e);
    };

    const getVendorsList = async () => {
      let url = `api/v1/admin/vendors`;
      try {
        const { vendors } = await request('GET', url);
        setVendors(vendors);
      } catch (error) {
        console.log(error);
      }
    };
    const typeFixedFromHandler = (item, dateString) => {
      onInputChange({
        target: { name: 'preferred_time', value: dateString },
      });
    };
    return (
      <>
        <Row gutter={24} style={{ float: 'right' }}>
          <DeleteFilledIcon
            style={{ padding: 5, height: '20px', width: '12px' }}
            onClick={() => handleRemoveItem(itemState)}
          />
        </Row>
        <div ref={ref}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24 / 2} style={{ marginTop: '20px' }}>
              <StyledFormItem label={'Name *'}>
                <Input
                  value={itemState.title}
                  name="title"
                  onChange={value => onInputChange(value)}
                  placeholder="Enter item name"
                />
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={24} md={24 / 2}>
              <StyledFormItem label="Assign Vendor" style={{ marginTop: 20 }}>
                <Select
                  value={itemState.vendor_id}
                  onChange={value =>
                    onInputChange({
                      target: { name: 'vendor_id', value },
                    })
                  }
                  placeholder="select vendor"
                  // notFoundContent={vendorsloading ? <Spin size="small" /> : null}
                  filterOption={false}
                  // onSearch={setVendorSearchKeyword}
                  showSearch
                >
                  {vendors?.map(vendor => (
                    <Select.Option key={vendor.id} value={vendor.id}>
                      {vendor.id + ' : ' + vendor.vendor_name}
                    </Select.Option>
                  ))}
                </Select>
              </StyledFormItem>
            </Col>
          </Row>
          <StyledFormItem label={'Description *'}>
            <Input.TextArea
              value={itemState.description}
              name="description"
              onChange={value => onInputChange(value)}
              placeholder="Enter the message here"
            />
          </StyledFormItem>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24 / 3}>
              <StyledFormItem label={'Unit'}>
                <Input
                  value={itemState.unit}
                  onChange={value => onInputChange(value)}
                  name="unit"
                  placeholder="Enter unit name"
                />
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={24} md={24 / 3}>
              <StyledFormItem
                {...NumberValidation(itemState.amount)}
                label={'Amount price *'}
              >
                <InputNumber
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  value={itemState.amount}
                  onChange={value =>
                    onInputChange({ target: { name: 'amount', value } })
                  }
                  name="amount"
                  placeholder="Enter price"
                  style={{ width: '100%' }}
                />
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={24} md={24 / 3}>
              <StyledFormItem label={'Commission %'}>
                <InputNumber
                  defaultValue={0}
                  min={0}
                  max={100}
                  formatter={value => `${value}%`}
                  parser={value => value.replace('%', '')}
                  value={itemState.commission_percentage}
                  onChange={value =>
                    onInputChange({
                      target: { name: 'commission_percentage', value },
                    })
                  }
                  name="commission_percentage"
                  placeholder="Enter commission percentage"
                  style={{ width: '100%' }}
                  prefix={'%'}
                />
              </StyledFormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8}>
              <StyledFormItem label="Create Job? *">
                {/* <Radio.Group onChange={() => {}} value={value.concierge_instant_charge}> */}
                <Radio.Group
                  onChange={e => {
                    onInputChange({
                      target: {
                        name: 'is_create_job',
                        value: e.target.value,
                      },
                    });

                    setIsCreateJob(e.target.value);
                  }}
                  value={itemState.is_create_job}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </StyledFormItem>
            </Col>
          </Row>

          {isCreateJob && (
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12}>
                <StyledFormItem label={'Service Date'}>
                  <DatePicker
                    name="service_date"
                    placeholder="select date"
                    onChange={(date, dateString) =>
                      onInputChange({
                        target: { name: 'service_date', value: dateString },
                      })
                    }
                    value={
                      itemState.service_date
                        ? moment(itemState.service_date, dateFormat)
                        : null
                    }
                    style={{ width: '100%' }}
                  />
                </StyledFormItem>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <StyledFormItem label={'Prefered Time'}>
                  {/* <TimePicker
                    format={timeFormat}
                    name="service_date"
                    placeholder="select date"
                    onChange={(date, dateString) =>
                      onInputChange({
                        target: { name: 'preferred_time', value: dateString },
                      })
                    }
                    value={
                      itemState.preferred_time
                        ? moment(itemState.preferred_time, timeFormat)
                        : null
                    }
                    // value={moment(itemState.preferred_time)}
                    style={{ width: '100%' }}
                  /> */}
                  <ReactTimePicker
                    name="preferred_time"
                    item={itemState}
                    cahngeFormTime={typeFixedFromHandler}
                  />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <StyledFormItem label={'Job Notes'}>
                  <Input.TextArea
                    value={itemState.job_notes}
                    onChange={onInputChange}
                    name="job_notes"
                    placeholder="Enter Job Notes"
                    autoSize={{ minRows: 5 }}
                  />
                </StyledFormItem>
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
);

ConciergeItemForm.propTypes = {
  initialState: PropTypes.obj,
  inputChange: PropTypes.func,
  index: PropTypes.number,
  handleRemoveItem: PropTypes.func,
};

ConciergeItemForm.displayName = 'ConciergeItemForm';

export default ConciergeItemForm;
