import {
  // SafetyOutlined,
  UserOutlined,
  ScheduleOutlined,
  TeamOutlined,
  AppstoreOutlined,
  // DatabaseOutlined,
  // MessageOutlined,
  // SlidersOutlined,
  // SlidersOutlined,
  SolutionOutlined,
  DashboardOutlined,
  // BarsOutlined,
  // MailOutlined,
  // ProjectFilled,
  // ScheduleFilled,
  GoldOutlined,
  // MoneyCollectOutlined,
  // SettingFilled,
} from '@ant-design/icons';

import JobIcon from './assets/icons/jobIcon';

// import Dashboard from './pages/Dashboard';
import Dashboard from './Vendor/pages/Dashboard';
// import AdminPage from './pages/AdminPage';
// import AuthPage from './pages/AuthPage';
import VendorJobPage from './Vendor/pages/JobPage';
// import JobsFeedback from './pages/JobsFeedback';
import VendorProfilePage from './Vendor/pages/VendorProfilePage';
import VendorSchedulerPage from './Vendor/pages/SchedulerPage';
import ClientPage from './Vendor/pages/ClientPage';
// import ClientProfilePage from './Vendor/pages/ClientProfilePage';
import VendorJobProfilePage from './Vendor/pages/JobProfilePage';
// import ConciergePage from './pages/ConciergePage';
// import ConciergeFormPage from './pages/ConciergePage/components/concierge-create';
import ProductsPage from './Vendor/pages/ProductsPage';
import ServicesPage from './Vendor/pages/ServicesPage';
import ServiceFormPage from './Vendor/pages/ServicesPage/components/service-create';

import VendorOrderPage from './Vendor/pages/Order';
// import VendorTransactionPage from './Vendor/pages/Transaction';
import ProductFormPage from './Vendor/pages/ProductsPage/components/product-create';
// import TagsPage from './pages/TagsPage';
// import SlidesShow from './pages/SlidesShow';
// import CategoriesPage from './pages/CategoriesPage';
// import AnnouncementPage from './pages/Announcement';
// import ReportsPage from './pages/ReportsPage';
// import PoolSchedularPage from './pages/PoolSchedularPage';
import SubscriptionPage from './Vendor/pages/SubscriptionPage';
import SubscriptionCreate from './Vendor/pages/SubscriptionPage/components/subscription-create';
import ClassesPage from './Vendor/pages/ClassesPage';
import ClassFormPage from './Vendor/pages/ClassesPage/components/class-create';
// import SettingsPage from './pages/SettingsPage/index';

const Routes = [
  {
    path: '/vendor',
    component: Dashboard,
    disableWrapper: true,
    name: 'dashboard_tab',
    visibilityDependency: null,
    menu: {
      name: 'Dashboard',
      Icon: DashboardOutlined,
    },
  },
  // {
  //   path: '/admin/login',
  //   component: AuthPage,
  //   disableWrapper: true,
  //   name: 'auth_page',
  //   visibilityDependency: null,
  // },
  //   {
  //     path: '/admin/admins',
  //     component: AdminPage,
  //     disableWrapper: true,
  //     name: 'admins_tab',
  //     visibilityDependency: null,
  //     menu: {
  //       name: 'Admins',
  //       Icon: SafetyOutlined,
  //     },
  //   },
  {
    path: '/vendor/users',
    component: VendorProfilePage,
    disableWrapper: true,
    name: 'vendor_users',
    visibilityDependency: null,
    menu: {
      name: 'Workers',
      Icon: UserOutlined,
    },
  },
  {
    path: '/vendor/scheduler',
    component: VendorSchedulerPage,
    disableWrapper: true,
    name: 'vendor_scheduler_tab',
    visibilityDependency: null,
    menu: {
      name: 'Scheduler',
      Icon: ScheduleOutlined,
    },
  },
  {
    path: '/vendor/clients',
    component: ClientPage,
    disableWrapper: true,
    name: 'clients_tab',
    visibilityDependency: null,
    menu: {
      name: 'Clients',
      Icon: TeamOutlined,
    },
  },
  //   {
  //     path: '/admin/concierge',
  //     component: ConciergePage,
  //     disableWrapper: true,
  //     name: 'concierge_tab',
  //     visibilityDependency: null,
  //     menu: {
  //       name: 'Concierge',
  //       Icon: MessageOutlined,
  //     },
  //   },
  //   {
  //     path: '/admin/concierge/form/:id',
  //     component: ConciergeFormPage,
  //     disableWrapper: true,
  //     visibilityDependency: 'concierge_tab',
  //   },
  {
    path: '/vendor/list/classes',
    component: ClassesPage,
    disableWrapper: true,
    name: 'subscription_tab',
    visibilityDependency: null,
  },
  {
    path: '/vendor/list/classes/form/:id',
    component: ClassFormPage,
    disableWrapper: true,
    name: 'subscription_tab',
    visibilityDependency: null,
  },
  {
    path: '/vendor/list/subscription',
    component: SubscriptionPage,
    disableWrapper: true,
    name: 'subscription_tab',
    visibilityDependency: null,
  },
  {
    path: '/vendor/list/services',
    component: ServicesPage,
    disableWrapper: true,
    name: 'services_tab',
    visibilityDependency: null,
  },
  {
    path: '/vendor/list/services/form/:id',
    component: ServiceFormPage,
    disableWrapper: true,
    visibilityDependency: 'services_tab',
  },
  {
    path: '/vendor/jobs',
    component: VendorJobPage,
    disableWrapper: true,
    name: 'vendor_jobs_tab',
    visibilityDependency: null,
    menu: {
      name: 'Jobs',
      Icon: JobIcon,
    },
  },
  //   {
  //     path: '/admin/jobsfeedback',
  //     component: JobsFeedback,
  //     disableWrapper: true,
  //     name: 'jobs_tab',
  //     visibilityDependency: null,
  //     menu: {
  //       name: 'Jobs Feedback',
  //       Icon: TeamOutlined,
  //     },
  //   },
  {
    path: '/vendor/list/products',
    component: ProductsPage,
    disableWrapper: true,
    name: 'products_tab',
    visibilityDependency: null,
  },
  {
    path: '/vendor/list/products/form/:id',
    component: ProductFormPage,
    disableWrapper: true,
    visibilityDependency: 'products_tab',
  },

  {
    name: 'List',
    Icon: AppstoreOutlined,
    visibilityDependency: null,
    path: '/vendor/list',
    menu: {
      name: 'List',
      Icon: AppstoreOutlined,
    },
    children: [
      {
        path: '/vendor/list/classes',
        component: ClassesPage,
        disableWrapper: true,
        name: 'Classes',
        visibilityDependency: null,
        Icon: GoldOutlined,
      },
      {
        path: '/vendor/list/services',
        component: ServicesPage,
        disableWrapper: true,
        name: 'Services',
        visibilityDependency: null,
        Icon: GoldOutlined,
      },
      {
        path: '/vendor/list/subscription',
        component: SubscriptionPage,
        disableWrapper: true,
        name: 'Subscription',
        visibilityDependency: null,
        Icon: GoldOutlined,
      },
      {
        path: '/vendor/list/products',
        component: ProductsPage,
        disableWrapper: true,
        name: 'Products',
        Icon: AppstoreOutlined,
        visibilityDependency: null,
      },
    ],
  },
  {
    path: '/vendor/list/subscription/form/:id',
    component: SubscriptionCreate,
    disableWrapper: true,
    visibilityDependency: 'subscription_tab',
  },
  //   {
  //     name: 'Categories and Properties',
  //     Icon: AppstoreOutlined,
  //     visibilityDependency: null,
  //     path: '/admin/cat',
  //     menu: {
  //       name: 'Categories and Properties',
  //       Icon: AppstoreOutlined,
  //     },
  //     children: [
  //       {
  //         path: '/admin/cat/categories',
  //         component: CategoriesPage,
  //         disableWrapper: true,
  //         visibilityDependency: null,
  //         name: 'Categories',
  //         Icon: BarsOutlined,
  //       },
  //       {
  //         path: '/admin/cat/tags',
  //         component: TagsPage,
  //         disableWrapper: true,
  //         visibilityDependency: null,
  //         name: 'Properties',
  //         Icon: DatabaseOutlined,
  //       },
  //     ],
  //   },
  // {
  //   path: '/vendor/transactions',
  //   component: VendorTransactionPage,
  //   disableWrapper: true,
  //   name: 'Transactions',
  //   Icon: MoneyCollectOutlined,
  //   visibilityDependency: null,
  //   menu: {
  //     name: 'Transactions',
  //     Icon: MoneyCollectOutlined,
  //   },
  // },
  {
    path: '/vendor/orders',
    component: VendorOrderPage,
    disableWrapper: true,
    name: 'vendor_orders_tab',
    visibilityDependency: null,
    menu: {
      name: 'Orders',
      Icon: SolutionOutlined,
    },
  },
  {
    path: '/vendor/jobs/:id',
    component: VendorJobProfilePage,
    disableWrapper: true,
    visibilityDependency: 'vendor_jobs_tab',
  },
  //   {
  //     path: '/admin/clients/:type/:id',
  //     component: ClientProfilePage,
  //     disableWrapper: true,
  //     visibilityDependency: 'clients_tab',
  //   },
  //   {
  //     path: '/admin/vendors/:id',
  //     component: VendorProfilePage,
  //     disableWrapper: true,
  //     visibilityDependency: 'vendors_tab',
  //   },
  {
    path: '/vendor/services',
    component: ServicesPage,
    disableWrapper: true,
    name: 'services_tab',
    visibilityDependency: 'null',
  },
  //   {
  //     path: '/admin/slideshow',
  //     component: SlidesShow,
  //     disableWrapper: true,
  //     name: 'slideshow_tab',
  //     visibilityDependency: null,
  //     menu: {
  //       name: 'SlideShow',
  //       Icon: SlidersOutlined,
  //     },
  //   },
  //   {
  //     path: '/admin/announcement',
  //     component: AnnouncementPage,
  //     disableWrapper: true,
  //     name: 'announcement_tab',
  //     visibilityDependency: null,
  //     menu: {
  //       name: 'Announcement',
  //       Icon: MailOutlined,
  //     },
  //   },
  //   {
  //     path: '/admin/reports',
  //     component: ReportsPage,
  //     disableWrapper: true,
  //     name: 'reports_tab',
  //     visibilityDependency: null,
  //     menu: {
  //       name: 'Reports',
  //       Icon: ProjectFilled,
  //       iconProps: {
  //         style: {
  //           transform: 'rotate(180deg)',
  //         },
  //       },
  //     },
  //   },
  //   {
  //     path: '/admin/pool-scheduler',
  //     component: PoolSchedularPage,
  //     disableWrapper: true,
  //     menu: {
  //       name: 'Pool Scheduler',
  //       Icon: ScheduleFilled,
  //     },
  //   },
  //   {
  //     path: '/admin/settings',
  //     component: SettingsPage,
  //     disableWrapper: true,
  //     menu: {
  //       name: 'Settings Page',
  //       Icon: SettingFilled,
  //     },
  //   },
];

export default Routes;
