import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const closeModal = (uiState, updateUI) => {
  updateUI({
    ...uiState,
    selectedRow: null,
    modalOpened: false,
  });
};
const performArchive = (selectedRow, classbulkArchive, setSelectedRows) => {
  classbulkArchive(selectedRow);
  setSelectedRows([]);
};

const ArchiveClass = ({
  uiState,
  updateUI,
  classbulkArchive,
  setSelectedRows,
}) => {
  const { modalOpened, selectedRow } = uiState;
  return (
    <Modal
      className="delete-user-modal"
      visible={modalOpened === 3}
      onCancel={() => closeModal(uiState, updateUI)}
      onOk={() =>
        performArchive(selectedRow, classbulkArchive, setSelectedRows)
      }
      okText="Yes"
      destroyOnClose
    >
      {selectedRow && (
        <>
          <p className="main-message">Are you sure want to archive classes?</p>
        </>
      )}
    </Modal>
  );
};

ArchiveClass.propTypes = {
  uiState: PropTypes.object,
  updateUI: PropTypes.object,
  classbulkArchive: PropTypes.func,
};

export default ArchiveClass;
