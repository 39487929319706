import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../../utils/notification';

const DEFAULT_CLASSES = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};
const DEFAULT_TAGS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'vendorClasses',
  initialState: {
    tags: {
      loading: false,
      tags: DEFAULT_TAGS,
    },
    loading: false,
    created: false,
    submitting: false,

    classes: DEFAULT_CLASSES,
    error: '',
    formObject: null,

    uiState: {
      modalOpened: false,
      selectedRow: null,
    },
  },
  reducers: {
    getTags: (state, { page }) => {
      state.tags.loading = true;
      state.tags.tags.page = page || 1;
    },
    getTagsSuccess: (state, { payload }) => {
      state.tags.loading = false;

      state.tags.tags = {
        data: payload.properties,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getTagsFailed: state => {
      state.tags.loading = false;
    },
    getClasses: state => {
      state.loading = true;
    },
    getClassesSuccess: (state, { payload }) => {
      state.loading = false;
      state.classes = {
        data: payload.classes,
        limit: parseInt(payload.meta?.per_page),
        page: parseInt(payload.meta?.page),
        total: parseInt(payload.meta?.total_count),
      };
    },
    getClassesFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    setCreatedFalse: state => {
      state.created = false;
    },
    createClass: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Creating a new class ...', 0);
    },
    createClassSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.classes;
      state.loading = false;
      state.created = true;
      state.submitting = false;
      state.classes.data.push(payload.classes);

      notification.success({
        message: 'New Class',
        description: `New Class ${payload.classes.title} has been created.`,
      });
    },
    createClassFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.created = false;
      state.loading = false;
      apiError(payload);
    },
    updateClass: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Updating class info...', 0);
    },
    updateClassSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.classes;
      state.loading = false;
      state.submitting = false;
      const idx = state.classes.data.findIndex(
        el => el.id === payload.classes.id
      );
      if (idx >= 0) {
        state.classes[idx] = payload.classes;
      }
      notification.success({
        message: 'Class Updated',
        description: `Class ${payload.classes.title} has been updated.`,
      });
    },
    updateClassFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    archiveClass: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Archiving class info...', 0);
    },
    archiveClassSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.classes;
      state.loading = false;
      state.submitting = false;
      const idx = state.classes.data.findIndex(
        el => el.id === payload.classes.id
      );
      if (idx >= 0) {
        state.classes.data.splice(idx, 1);
      }
      notification.success({
        message: 'Class Archived',
        description: `Class ${payload.classes.title} has been Archived.`,
      });
    },
    archiveClassFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    unArchiveClass: state => {
      state.loading = true;
      state.submitting = true;
      state.message = message.loading('Unarchiving class info...', 0);
    },
    unArchiveClassSuccess: (state, { payload }) => {
      state.message();
      state.formObject = payload.classes;
      state.loading = false;
      state.submitting = false;
      const idx = state.classes.data.findIndex(
        el => el.id === payload.classes.id
      );
      if (idx >= 0) {
        state.classes.data.splice(idx, 1);
      }
      notification.success({
        message: 'Class Unarchived',
        description: `Class ${payload.classes.title} has been unarchived.`,
      });
    },
    unArchiveClassFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    classbulkArchive: state => {
      state.message = message.loading('Archiving Classes ...', 0);
    },
    classbulkArchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.classes.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.classes.data = updateArr;
      notification.success({
        message: 'Classes Archived',
        description: `Selected Classes ${payload.ids} has been Archived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    classbulkArchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    classbulkUnarchive: state => {
      state.message = message.loading('Unarchiving Classes ...', 0);
    },
    classbulkUnarchiveSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.classes.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.classes.data = updateArr;
      notification.success({
        message: 'Classes Unarchived',
        description: `Selected Classes ${payload.ids} has been Unarchived.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    classbulkUnarchiveFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    deleteClass: state => {
      state.message = message.loading('Deleting class ...', 0);
    },
    deleteClassSuccess: (state, { payload }) => {
      state.message();
      state.classes.data = state.classes.data.filter(
        x => x.id !== payload.classes.id
      );
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
      notification.success({
        message: 'Class Deleted',
        description: `Class ${payload.classes.title} has been deleted.`,
      });
    },
    deleteClassFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    classbulkDelete: state => {
      state.message = message.loading('Deleting classes ...', 0);
    },
    classbulkDeleteSuccess: (state, { payload }) => {
      state.message();
      let newArr = [...state.classes.data];
      const updateArr = newArr.filter(
        value => !payload['ids'].includes(value.id)
      );
      state.classes.data = updateArr;
      notification.success({
        message: 'Classes Deleted',
        description: `Selected Classes ${payload.ids} has been deleted.`,
      });
      state.uiState = {
        ...state.uiState,
        modalOpened: false,
        selectedRow: null,
      };
    },
    classbulkDeleteFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    getClassById: (state, { payload }) => {
      state.formObject = payload;
      //TODO: move code from product-create to here
    },
    updateUI: (state, { payload }) => {
      state.uiState = { ...payload };
    },
  },
});

export const {
  getTags,
  getTagsSuccess,
  getTagsFailed,
  setCreatedFalse,
  getClasses,
  getClassesSuccess,
  getClassesFailed,
  createClass,
  createClassSuccess,
  createClassFailed,
  updateClass,
  updateClassSuccess,
  updateClassFailed,
  archiveClass,
  archiveClassFailed,
  archiveClassSuccess,
  unArchiveClass,
  unArchiveClassFailed,
  unArchiveClassSuccess,
  classbulkArchive,
  classbulkArchiveSuccess,
  classbulkArchiveFailed,
  classbulkUnarchive,
  classbulkUnarchiveSuccess,
  classbulkUnarchiveFailed,
  deleteClass,
  deleteClassFailed,
  deleteClassSuccess,
  classbulkDelete,
  classbulkDeleteSuccess,
  classbulkDeleteFailed,
  getClassById,
  updateUI,
} = slice.actions;
export default slice.reducer;
