import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getJob,
  getJobFailed,
  getJobSuccess,
  updateJob,
  updateJobFailed,
  updateJobSuccess,
  deleteJob,
  deleteJobFailed,
  deleteJobSuccess,
  assignWorker,
  assignWorkerFailed,
  assignWorkerSuccess,
  getUpcomingJob,
  getUpcomingJobs,
  getUpcomingSuccess,
  getUpcomingFailed,
  getJobById,
  getJobByIdFailed,
  getJobByIdSuccess,
  cancelJobs,
  cancelJobsSuccess,
  cancelJobsFailed,
  pauseJobs,
  pauseJobsSuccess,
  pauseJobsFailed,
  skipJobs,
  skipJobsSuccess,
  skipJobsFailed,
} from './reducers';

function* getJobSaga({ payload }) {
  let action;

  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/jobs/type/repeatings/${payload}`
    );
    action = getJobSuccess(response);
  } catch (error) {
    action = getJobFailed(error.message);
  }
  yield put(action);
}

function* getUpcomingJobSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/repeating_jobs/${payload}/upcoming_jobs`
    );
    action = getUpcomingSuccess(response);
  } catch (error) {
    action = getUpcomingFailed(error.message);
  }
  yield put(action);
}

function* getUpcomingJobsSaga({ payload }) {
  let action;
  let url = `/api/v1/admin/repeating_jobs/${payload.id}/upcoming_jobs?`;
  url += `active=true&sort=service_date&direction=asc`;

  if (payload['page']) {
    url += `&page=${payload['page']}`;
  }
  if (payload['per']) {
    url += `&per=${payload['per']}`;
  }
  if (payload['query']) {
    url += `&query=${payload['query']}`;
  }

  try {
    const response = yield call(request, 'GET', url);
    action = getUpcomingSuccess(response);
  } catch (error) {
    action = getUpcomingFailed(error.message);
  }
  yield put(action);
}

function* updateJobSaga({ payload }) {
  let action;

  try {
    const response = yield call(
      request,
      'PUT',
      `api/v1/jobs/${payload.job.id}`,
      payload
    );
    action = updateJobSuccess(response);
  } catch (error) {
    action = updateJobFailed(error.message);
  }
  yield put(action);
}

function* pauseJobsSaga({ payload }) {
  let action;
  let action1;
  let params = {
    start_date: payload.start_date,
    end_date: payload.end_date,
  };
  let url = `/api/v1/admin/repeating_jobs/${payload.id}/upcoming_jobs?`;
  url += `active=true&sort=service_date&direction=asc`;

  if (payload['page']) {
    url += `&page=${payload['page']}`;
  }
  if (payload['per']) {
    url += `&per=${payload['per']}`;
  }
  if (payload['query']) {
    url += `&query=${payload['query']}`;
  }
  try {
    const response = yield call(
      request,
      'PATCH',
      `api/v1/admin/repeating_jobs/${payload.id}/batch_pause`,
      params
    );
    action = pauseJobsSuccess(response);
    yield put(action);
  } catch (error) {
    action = pauseJobsFailed(error.message);
    yield put(action);
    return;
  }
  try {
    const response1 = yield call(request, 'GET', url);
    action1 = getUpcomingSuccess(response1);
    yield put(action1);
  } catch (error) {
    action1 = getUpcomingFailed(error.message);
    yield put(action1);
  }
}

function* cancelJobsSaga({ payload }) {
  let action;
  let action1;
  let params = {
    start_date: payload.start_date,
    end_date: payload.end_date,
    remove_assigned_worker_and_time: payload?.remove_assigned_worker_and_time,
  };
  let url = `/api/v1/admin/repeating_jobs/${payload.id}/upcoming_jobs?`;
  url += `active=true&sort=service_date&direction=asc`;

  if (payload['page']) {
    url += `&page=${payload['page']}`;
  }
  if (payload['per']) {
    url += `&per=${payload['per']}`;
  }
  if (payload['query']) {
    url += `&query=${payload['query']}`;
  }
  try {
    const response = yield call(
      request,
      'PATCH',
      `api/v1/admin/repeating_jobs/${payload.id}/batch_cancel`,
      params
    );
    action = cancelJobsSuccess(response);
    yield put(action);
  } catch (error) {
    action = cancelJobsFailed(error.message);
    yield put(action);
    return;
  }
  try {
    const response1 = yield call(request, 'GET', url);
    action1 = getUpcomingSuccess(response1);
    yield put(action1);
  } catch (error) {
    action1 = getUpcomingFailed(error.message);
    yield put(action1);
  }
}

function* skipJobsSaga({ payload }) {
  let action;
  let action1;
  let params = { start_date: payload.start_date, end_date: payload.end_date };
  let url = `/api/v1/admin/repeating_jobs/${payload.id}/upcoming_jobs?`;
  url += `active=true&sort=service_date&direction=asc`;

  if (payload['page']) {
    url += `&page=${payload['page']}`;
  }
  if (payload['per']) {
    url += `&per=${payload['per']}`;
  }
  if (payload['query']) {
    url += `&query=${payload['query']}`;
  }
  try {
    const response = yield call(
      request,
      'PATCH',
      `api/v1/admin/repeating_jobs/${payload.id}/batch_skip`,
      params
    );
    action = skipJobsSuccess(response);
    yield put(action);
  } catch (error) {
    action = skipJobsFailed(error.message);
    yield put(action);
    return;
  }
  try {
    const response1 = yield call(request, 'GET', url);
    action1 = getUpcomingSuccess(response1);
    yield put(action1);
  } catch (error) {
    action1 = getUpcomingFailed(error.message);
    yield put(action1);
  }
}

function* assignWorkerSaga({ payload }) {
  let action;

  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/jobs/type/repeatings/${payload.id}/update_jobs`,
      payload.data
    );
    action = assignWorkerSuccess(response);
  } catch (error) {
    action = assignWorkerFailed(error.message);
  }
  yield put(action);
}

function* deleteJobSaga({ payload }) {
  let action;
  try {
    const response = yield call(request, 'DELETE', `/api/v1/jobs/${payload}`);
    action = deleteJobSuccess(response);
  } catch (error) {
    action = deleteJobFailed(error.message);
  }
  yield put(action);
}
function* getJobByIdSaga({ payload }) {
  let action;
  try {
    const response = yield call(request, 'GET', `/api/v1/jobs/${payload}`);
    action = getJobByIdSuccess(response);
  } catch (error) {
    action = getJobByIdFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getJobById, getJobByIdSaga);
  yield takeLatest(getJob, getJobSaga);
  yield takeLatest(updateJob, updateJobSaga);
  yield takeLatest(deleteJob, deleteJobSaga);
  yield takeLatest(assignWorker, assignWorkerSaga);
  yield takeLatest(getUpcomingJob, getUpcomingJobSaga);
  yield takeLatest(getUpcomingJobs, getUpcomingJobsSaga);
  yield takeLatest(cancelJobs, cancelJobsSaga);
  yield takeLatest(pauseJobs, pauseJobsSaga);
  yield takeLatest(skipJobs, skipJobsSaga);
}
