import React, { useState, useEffect } from 'react';
import { Modal, Card, Form, Input, Button } from 'antd';
import OrderTable from '../../../components/OrderTable';
import OrderItemsTable from '../../../components/OrderTable/OrderItemsTable';
import JobDetailTable from '../../../components/OrderTable/JobDetailTable';
import OrderTransactionsTable from '../../../components/OrderTable/OrderTransactionsTable.js';
import TransactionTable from '../../../components/TransactionTable';
import TableSearch from '../../../components/TableSearch';
import {
  getJob,
  getOrders,
  orderShipped,
  orderDelivered,
  cancelOrderRequest,
} from './reducers';
import EditableTagGroup from '../../../components/OrderTable/EditableTagGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.css';

const OrderItemTableHeading = ({ orderId, itemCount }) => {
  return (
    <div className="order-item-table-heading-container">
      <h2 className="order-item-table-heading">Order Items</h2>
      <p className="order-item-table-text">{`Order ID: ${orderId} - ${itemCount} items`}</p>
    </div>
  );
};

OrderItemTableHeading.propTypes = {
  orderId: PropTypes.string,
  itemCount: PropTypes.number,
};

const OrderItemTableFooter = ({ formattedAmount }) => {
  return (
    <div className="order-item-table-footer-container">
      <h2 className="order-item-table-footer-text oi-table-footer-heading">
        Total Amount
      </h2>
      <h2 className="order-item-table-footer-text oi-table-footer-text">
        {formattedAmount}
      </h2>
    </div>
  );
};

OrderItemTableFooter.propTypes = {
  formattedAmount: PropTypes.string,
};

function Order(props) {
  const { orders, getOrders, loading, getJob, jobs } = props;
  const { orderShipped, orderDelivered, cancelOrderRequest } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [searchText, updateSearchText] = useState('');
  const [ordersList, updateOrdersList] = useState({ meta: null, orders: [] });
  const [activeTab, setActiveTab] = useState('all');
  const [orderItemsModal, updateOrderItemsModal] = useState({
    status: false,
    records: [],
    orderId: '',
    formattedAmount: '',
  });
  const [jobDetailModal, updateJobDetailModal] = useState({
    status: false,
    records: jobs,
    orderId: '',
  });
  const [orderTransactionsModal, updateOrderTransactionsModal] = useState({
    status: false,
    records: [],
    formattedAmount: '',
  });
  const [isTrModalOpen, setIsTrModalOpen] = useState(false);
  const [trackingNumebrId, setTrackingNumberId] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState({
    openModal: false,
    selectedRow: null,
  });

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const onSearch = () => {
    if (searchText.trim() !== '') {
      getOrders({ query: searchText, per: ordersList['meta']['per_page'] });
    } else {
      getOrders({
        per: ordersList['meta']['per_page'],
        query: null,
      });
    }
  };

  const handleTableChange = pagination => {
    getOrders({
      page: pagination['current'],
      per: pagination['pageSize'],
      query: searchText ? searchText : null,
      activeTab,
    });
  };

  const showjobDetailModal = data => {
    getJob({
      id: data?.job_id ? data?.job_id : data?.repeating_job_id,
      type: data?.job_id ? 'single' : 'repeating',
    });
    updateJobDetailModal({ ...jobDetailModal, orderId: data?.id });
  };

  const showOrderItemsModal = (data, activeTab) => {
    updateOrderItemsModal({
      status: true,
      formattedAmount: data['formatted_amount'],
      records:
        activeTab === 'concierge'
          ? data['concierge_order_items']
          : data['order_items'],
      orderId: data['id'],
    });
  };
  const showOrderTransactionsModal = data => {
    updateOrderTransactionsModal({
      status: true,
      formattedAmount:
        '$' +
        data['order_transactions']
          .reduce(
            (accumulator, current) => accumulator + parseFloat(current.amount),
            0
          )
          .toFixed(2),
      records: data['order_transactions'],
    });
  };
  const handleCancelOrder = record => {
    setSelectedRecord({
      ...selectedRecord,
      openModal: true,
      selectedRow: record,
    });
  };
  const handleCancelRequestModalClose = () => {
    setSelectedRecord({ openModal: false, selectedRow: null });
  };
  const submitCancelRequest = values => {
    const params = {
      id: selectedRecord?.selectedRow?.id,
      order: { subject: values.subject, content: values.detail },
    };

    cancelOrderRequest(params);
    handleCancelRequestModalClose();
  };

  useEffect(() => {
    if (jobs?.length && jobs !== ['undefined']) {
      updateJobDetailModal({
        ...jobDetailModal,
        status: true,
        records: jobs,
      });
    }
  }, [jobs]);

  useEffect(() => {
    updateOrdersList(orders);
  }, [orders]);

  useEffect(() => {
    getOrders({
      page: 1,
      per: 20,
      query: null,
      activeTab: 'all',
    });
  }, [getOrders]);

  return (
    <div className="transaction-container">
      <div className="page-header">
        <div className="page-title">
          <h4>Orders</h4>
        </div>
      </div>
      <div className="transaction-content-container">
        <TableSearch
          placeholder="Search Order"
          btnText="Search"
          onBtnClick={onSearch}
          value={searchText}
          onInputChange={ev => updateSearchText(ev['target']['value'])}
        />
        <Card>
          <OrderTable
            dataSource={ordersList['orders']}
            loading={loading}
            getOrders={getOrders}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setModalOpen={setModalOpen}
            showjobDetailModal={showjobDetailModal}
            showOrderItemsModal={showOrderItemsModal}
            showOrderTransactionsModal={showOrderTransactionsModal}
            orderDelivered={orderDelivered}
            setTrackingIdModalOpen={setIsTrModalOpen}
            setTrackingNumberId={setTrackingNumberId}
            pagination={{
              limit: ordersList['meta'] ? ordersList['meta']['per_page'] : 20,
              total: ordersList['meta'] ? ordersList['meta']['total_count'] : 0,
              page: ordersList['meta'] ? ordersList['meta']['page'] : 1,
              data: ordersList['orders'],
            }}
            handleTableChange={handleTableChange}
            isVendor
            cancelOrder={handleCancelOrder}
          />
        </Card>
      </div>
      <Modal
        title={<OrderItemTableHeading title={'Job'} />}
        visible={jobDetailModal['status']}
        onOk={() => updateJobDetailModal({ status: false, records: [] })}
        onCancel={() => updateJobDetailModal({ status: false, records: [] })}
        width="80%"
      >
        <JobDetailTable dataSource={jobDetailModal['records']} />
      </Modal>
      <Modal
        title={
          <OrderItemTableHeading
            orderId={orderItemsModal['orderId']}
            itemCount={orderItemsModal['records']['length']}
          />
        }
        visible={orderItemsModal['status']}
        onOk={() => updateOrderItemsModal({ status: false, records: [] })}
        onCancel={() => updateOrderItemsModal({ status: false, records: [] })}
        width="80%"
      >
        <OrderItemsTable
          dataSource={orderItemsModal['records']}
          footer={() =>
            orderItemsModal['records']['length'] ? (
              <OrderItemTableFooter
                formattedAmount={orderItemsModal['formattedAmount']}
              />
            ) : null
          }
        />
      </Modal>
      <Modal
        title="Order Transactions Details"
        visible={orderTransactionsModal['status']}
        onOk={() =>
          updateOrderTransactionsModal({ status: false, records: [] })
        }
        onCancel={() =>
          updateOrderTransactionsModal({ status: false, records: [] })
        }
        width="80%"
      >
        <OrderTransactionsTable
          dataSource={orderTransactionsModal['records']}
          footer={() =>
            orderTransactionsModal['records']['length'] ? (
              <OrderItemTableFooter
                formattedAmount={orderTransactionsModal['formattedAmount']}
              />
            ) : null
          }
        />
      </Modal>
      <Modal
        title="Transaction Details"
        visible={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        width="80%"
      >
        <TransactionTable
          records={[
            {
              key: 0,
              timeStamp: '28/06/2020',
              transactionId: 'TX121',
              invoiceID: '121313',
              transactionType: { type: 'DEBIT', text: 'Debit' },
              transactionFrom: 'Stripe',
              transactionTo: 'Wallet',
              orderId: '1029',
              amountPrice: '190',
              clientType: 'Personal',
              clientName: 'Rebeca Chander',
              message: '',
              status: { type: 'SUCCESS', text: 'Successful' },
            },
          ]}
          pagination={false}
        />
      </Modal>
      <Modal
        onCancel={() => {
          setIsTrModalOpen(false);
        }}
        footer={null}
        title="Tracking Id"
        visible={isTrModalOpen}
        destroyOnClose
      >
        <EditableTagGroup
          trackingNumebrId={trackingNumebrId}
          orderShipped={orderShipped}
          setIsTrModalOpen={setIsTrModalOpen}
        />
      </Modal>
      <Modal
        onCancel={() => {
          handleCancelRequestModalClose();
        }}
        footer={null}
        title="Cancel Request"
        visible={selectedRecord.openModal}
        destroyOnClose
      >
        <Form {...layout} name="nest-messages" onFinish={submitCancelRequest}>
          <Form.Item
            name="subject"
            label="Subject"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
            name="detail"
            label="Detail"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
            <Button type="primary" htmlType="submit">
              Request to cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

Order.propTypes = {
  jobs: PropTypes.object,
  getJob: PropTypes.func,
  orders: PropTypes.object,
  getOrders: PropTypes.func,
  loading: PropTypes.bool,
  orderShipped: PropTypes.func,
  orderDelivered: PropTypes.func,
  cancelOrderRequest: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.vendorOrders.loading,
    jobs: state.vendorOrders.jobs,
    orders: state.vendorOrders.orders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJob: payload => dispatch(getJob(payload)),
    getOrders: payload => dispatch(getOrders(payload)),
    orderShipped: id => dispatch(orderShipped(id)),
    orderDelivered: id => dispatch(orderDelivered(id)),
    cancelOrderRequest: payload => dispatch(cancelOrderRequest(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
