import { Avatar, Upload, message } from 'antd';
import { dig } from 'digdata';
import React from 'react';
import PropTypes from 'prop-types';
import { InboxOutlined } from '@ant-design/icons';
// import './styles.css';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import ImgCrop from 'antd-img-crop';

const { Dragger } = Upload;

class DraggerUpload extends React.Component {
  state = {
    value: this.props.value,
    localValue: null,
  };

  componentDidUpdate() {
    const value = dig(this.props, 'value');
    if (
      typeof value === 'string' &&
      value.startsWith('http') &&
      this.state.value !== value
    ) {
      this.props.onChange(undefined);
      this.setState({ value });
    }
  }

  handleRequest = ({ file }) => {
    const { largeSize } = this.props;
    const reader = new FileReader();
    const fileSize = file.size / 1024 / 1024;

    if (largeSize) {
      if (fileSize > 3) {
        message.error('File size must be lesser than 3 MB');
        this.props?.handleSubmit ? this.props.handleSubmit(true) : null;
      } else {
        this.props?.handleSubmit ? this.props.handleSubmit(false) : null;
      }
    } else {
      if (fileSize > 1) {
        message.error('File size must be lesser than 1 MB');
        this.props?.handleSubmit ? this.props.handleSubmit(true) : null;
      } else {
        this.props?.handleSubmit ? this.props.handleSubmit(false) : null;
      }
    }
    reader.addEventListener('load', () => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(reader.result);
      }

      this.setState({
        loading: false,
        localValue: reader.result,
      });
    });
    reader.readAsDataURL(file);
  };

  render() {
    const { value, localValue } = this.state;
    const { accept } = this.props;

    let uploadProps = {
      name: 'image',
      multiple: false,
      listType: 'picture-card',
      showUploadList: false,
      customRequest: this.handleRequest,
    };

    if (accept) {
      uploadProps['accept'] = accept;
    }

    return (
      <ImgCrop aspect={this.props?.aspectRatio}>
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {this.props?.message
              ? this.props.message
              : 'Click or drag file to this area to upload'}
          </p>
          <Avatar
            icon={<PlusOutlined />}
            shape="square"
            src={localValue || value}
            size={100}
            style={{
              color: '#d3d3d3',
              backgroundColor: '#fff',
              width: this.props?.width ? `${this.props.width}%` : '100px',
              height: this.props?.height ? `${this.props.height}%` : '100px',
            }}
          />
        </Dragger>
      </ImgCrop>
    );
  }
}

DraggerUpload.propTypes = {
  value: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};

export default DraggerUpload;
