import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const getServiceStatusClass = status => {
  if (status === 'IN_TRANSIT') {
    return 'in-transit';
  } else if (status === 'COMPLETED') {
    return 'completed';
  } else if (status === 'NOT_STARTED') {
    return 'not-started';
  }
};

const tableColumns = () => [
  {
    title: <span className="table-column-title">Vendor Name</span>,
    key: 'vendor_name',
    dataIndex: 'vendor_name',
    render: (text, record) => record['vendor_name'],
  },
  {
    title: <span className="table-column-title">Image</span>,
    key: 'worker_image',
    dataIndex: 'worker_image',
    width: 140,
    align: 'center',
    render: function avatar(text, record) {
      return (
        <Avatar
          size={40}
          src={record['worker_image']}
          icon={<UserOutlined />}
        />
      );
    },
  },
  {
    title: <span className="table-column-title">Worker Name</span>,
    key: 'worker_name',
    dataIndex: 'worker_name',
    render: (text, record) => record['worker_name'],
  },
  {
    title: <span className="table-column-title">Completed Jobs</span>,
    key: 'total_count',
    align: 'center',
    render: (text, record) =>
      `${record['completed_count']} of ${record['total_count']}`,
  },
  {
    title: <span className="table-column-title">Status Service</span>,
    key: 'statusService',
    align: 'center',
    render: function statusService() {
      return (
        <span className={`font-bold ${getServiceStatusClass('completed')}`}>
          Completed
        </span>
      );
    },
  },
];

export default tableColumns;
