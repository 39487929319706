import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Row, Col, notification, DatePicker, Button, Modal } from 'antd';
import moment from 'moment';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  handleVendorChange,
  searchWorkers,
  formatWorkerContent,
  assignJobToWorker,
  reassignJobToWorker,
  unassignJobFromWorker,
  assignWorkerPerm,
  searchVendorsSuccess,
} from './reducers';
import VendorList from './components/vendorList';
import WorkerCard from './components/workerCard';

import './styles.css';
// SOMEONE CHANGE THIS TO FUNCTIONAL COMPONENT WHEN YOU CALL, FETCH ALL AT ONCE,
// THIS IS CAUSING NEEDLESS REPEATED RERENDERS
// useContext to pass down properties required by the jobCard

export const JobContext = React.createContext({
  permanentReassign: false,
  permanentReassignSelectedDay: false,
  permanentlyReassignedJobsId: [],
  handlePermReassignSelectedDay: () => {},
  checkboxOnChange: () => {},
});
class Scheduler extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      pickerOpened: false,
      currentDate: moment(),
      CURRENT_DATE: moment(),
      permanentReassign: false,
      permanentReassignSelectedDay: false,
      permanentlyReassignedJobsId: [],
      permanentReassignSelectedDayString: '',
    };
  }

  // handlePermReassignSelectedDay = (ev, job) => {
  //   const { permanentlyReassignedJobsId, currentDate } = this.state;
  //   let updatedPermanentlyReassignedJobsId = [...permanentlyReassignedJobsId];
  //   const dayString = currentDate.format('dddd').toLowerCase();

  //   if (ev['target']['checked']) {
  //     const idExists = permanentlyReassignedJobsId.find(
  //       id => id === job.repeating_job_id
  //     );
  //     if (!idExists) {
  //       updatedPermanentlyReassignedJobsId = [
  //         ...permanentlyReassignedJobsId,
  //         job.repeating_job_id,
  //       ];
  //     }
  //   } else {
  //     updatedPermanentlyReassignedJobsId = permanentlyReassignedJobsId.filter(
  //       id => id !== job.repeating_job_id
  //     );
  //   }

  //   this.setState({
  //     permanentReassignSelectedDay: ev.target.checked,
  //     permanentReassignSelectedDayString: ev.target.checked ? dayString : '',
  //     permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
  //   });
  // };

  // checkboxOnChange = (ev, job) => {
  //   const { permanentlyReassignedJobsId } = this.state;
  //   let updatedPermanentlyReassignedJobsId = [...permanentlyReassignedJobsId];

  //   if (ev['target']['checked']) {
  //     const idExists = permanentlyReassignedJobsId.find(
  //       id => id === job.repeating_job_id
  //     );
  //     if (!idExists) {
  //       updatedPermanentlyReassignedJobsId = [
  //         ...permanentlyReassignedJobsId,
  //         job.repeating_job_id,
  //       ];
  //     }
  //   } else {
  //     updatedPermanentlyReassignedJobsId = permanentlyReassignedJobsId.filter(
  //       id => id !== job.repeating_job_id
  //     );
  //   }

  //   this.setState({
  //     permanentReassign: ev['target']['checked'],
  //     permanentlyReassignedJobsId: updatedPermanentlyReassignedJobsId,
  //   });
  // };

  componentDidMount() {
    window.addEventListener('scroll', this.loadMore);
  }

  componentDidUpdate(prevProps) {
    if (this.props.vendorSelected?.id !== prevProps.vendorSelected?.id) {
      this.refreshData();
      this.handleReload();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  }

  fetchWorkers = () => {
    const { searchWorkers, currentWorkerPage, vendorSelected } = this.props;
    const { currentDate, collapsed } = this.state;

    const params = {
      size: collapsed ? 4 : 3,
      page: currentWorkerPage + 1,
      date: currentDate.format('YYYY-MM-DD'),
    };
    params.vendor_id = vendorSelected.id;
    searchWorkers(params);
  };

  refreshData = () => {
    const { formatWorkerContent } = this.props;

    formatWorkerContent();
    setTimeout(this.fetchWorkers, 10);
  };

  loadMore = () => {
    const { loadingWorkers, currentWorkerPage, totalWorkerPages } = this.props;
    if (
      !loadingWorkers &&
      window.scrollY + window.innerHeight * currentWorkerPage >=
        document.body.clientHeight - 99 * currentWorkerPage &&
      currentWorkerPage < totalWorkerPages
    ) {
      this.fetchWorkers();
    }
  };

  handleDatePicker = status => {
    this.setState({
      pickerOpened: status,
    });
  };

  handleReload = () => {
    const { formatWorkerContent } = this.props;

    formatWorkerContent();
    this.refreshData();
    setTimeout(this.fetchWorkers, 10);
  };

  handleDateChange = date => {
    const { currentDate } = this.state;

    if (date === 'prev') {
      date = moment(currentDate.format()).subtract(1, 'days');
    } else if (date === 'next') {
      date = moment(currentDate.format()).add(1, 'days');
    } else {
      date = moment(date);
    }

    this.setState(
      {
        currentDate: date,
      },
      this.refreshData
    );
  };

  handleCollapse = () => {
    this.setState(
      prevState => ({
        collapsed: !prevState.collapsed,
      })
      // this.refreshData
    );
  };

  onDragEnd = result => {
    // very first condition to check before starting expensive operations like destructuring
    const {
      vendorSelected,
      workers,
      unassignJobFromWorker,
      assignJobToWorker,
      reassignJobToWorker,
      assignWorkerPerm,
    } = this.props;
    const { source, destination } = result;
    if (
      !this.state.currentDate.isSameOrAfter(this.state.CURRENT_DATE, 'date')
    ) {
      Modal.warning({
        title: 'Not Allowed',
        content: 'Cannot update historical jobs.',
        icon: <CloseCircleOutlined />,
      });
      return; // disable update if date before
    }
    let vendorSelectedObj = null;

    if (vendorSelected) {
      vendorSelectedObj = vendorSelected;
    }
    // reason why i aqm doinbg it here is because i have access to the full jobs array?, then maybe i should pass it down
    // i need it here too, cauise dropping, okay right now get checkmark to stay
    if (destination && source.droppableId !== destination.droppableId) {
      const sourceId = source.droppableId;
      const destinationId = destination.droppableId;

      let job = vendorSelectedObj.unassigned_jobs.find(
        el => el.id === source.index
      );

      if (!job && sourceId !== 'unassign') {
        const [workerIndex] = sourceId.split('.');
        job = workers[workerIndex].jobs.find(el => el.id === source.index);
      }

      let jobsId = [];
      let permanentReassignSelectedDayString = '';

      // if (job) {
      //   jobsId = job['permanentlyReassignedJobsId'];
      //   permanentReassignSelectedDayString =
      //     job['permanentReassignSelectedDayString'];
      // }

      if (jobsId.length) {
        // determine result destination to remove permanent schedule or add permanent schedule
        if (destination && destination.droppableId === 'unassign') {
          // drag back to vendorList if checked, unassign perm repeating jobs
          // if unassiging find job from workers
          const [workerIndex] = sourceId.split('.');
          // job = workers[workerIndex].jobs.find(el => el.id === source.index);
          // destination: {droppableId: 'unassign', index: 0}
          let assignData = {
            start_date: job.service_date,
            end_date: null, // DONT CHANGE
            assigned_worker_id: null,
            assigned_time: null,
            previous_worker_id: workers[workerIndex].id,
            // job_status: 'scheduled',
          };

          if (permanentReassignSelectedDayString.trim() !== '') {
            assignData['day'] = permanentReassignSelectedDayString;
          }

          assignWorkerPerm(+job.repeating_job_id, assignData);

          this.setState({
            permanentReassignSelectedDayString: '',
          });
        } else if (
          job &&
          source.droppableId === 'unassign' &&
          jobsId.includes(job.repeating_job_id)
        ) {
          const [workerIndex, serviceHour] = destinationId.split('.');
          let assignData = {
            start_date: job.service_date,
            end_date: null, // DONT CHANGE
            assigned_worker_id: workers[workerIndex].id,
            assigned_time: serviceHour,
            // job_status: 'scheduled',
          };

          if (permanentReassignSelectedDayString.trim() !== '') {
            assignData['day'] = permanentReassignSelectedDayString;
          }

          assignWorkerPerm(+job.repeating_job_id, assignData);

          // this.setState({
          //   permanentReassignSelectedDayString: '',
          // });
        } else {
          // transferring from one worker card to another worker card
          const [workerIndexFrom] = sourceId.split('.');
          if (workers[workerIndexFrom]?.jobs) {
            const [workerIndexTo, serviceHourTo] = destinationId.split('.');
            job = workers[workerIndexFrom].jobs.find(
              el => el.id === source.index
            );
            let assignData = {
              start_date: job.service_date,
              end_date: null, // DONT CHANGE
              assigned_worker_id: workers[workerIndexTo].id,
              assigned_time: serviceHourTo,
              previous_worker_id: workers[workerIndexFrom].id,
              // job_status: 'scheduled',
            };

            if (permanentReassignSelectedDayString.trim() !== '') {
              assignData['day'] = permanentReassignSelectedDayString;
            }

            assignWorkerPerm(+job.repeating_job_id, assignData);
          } else if (sourceId === 'unassign') {
            const [workerIndex, serviceHour] = destinationId.split('.');
            const job = vendorSelectedObj.unassigned_jobs.find(
              el => el.id === source.index
            );
            if (workers[workerIndex].vendor_id !== job.vendor_id) {
              return notification.error({
                message: 'Job not belongs to this vendor ',
              });
            }

            let assignData = {
              start_date: job.service_date,
              end_date: null, // DONT CHANGE
              assigned_worker_id: workers[workerIndex].id,
              assigned_time: serviceHour,
              previous_worker_id: null,
              // job_status: 'scheduled',
            };

            if (permanentReassignSelectedDayString.trim() !== '') {
              assignData['day'] = permanentReassignSelectedDayString;
            }

            assignWorkerPerm(+job.repeating_job_id, assignData);
          }
          // clear out
          // this.setState({
          //   permanentlyReassignedJobsId: [],
          //   permanentReassignSelectedDayString: '',
          // });
        }
      } else {
        if (sourceId === 'unassign') {
          const [workerIndex, serviceHour] = destinationId.split('.');
          const job = vendorSelectedObj.unassigned_jobs.find(
            el => el.id === source.index
          );
          if (workers[workerIndex].vendor_id !== job.vendor_id) {
            return notification.error({
              message: 'Job not belongs to this - vendor ',
            });
          }

          const body = {
            job_id: job.id,
            vendor_id: job.vendor_id,
            assigned_worker_id: workers[workerIndex].id,
            service_date: job.service_date,
            assigned_time: serviceHour,
          };
          assignJobToWorker({
            body,
            workerIndex,
          });
        } else if (destinationId === 'unassign') {
          const [workerIndex] = sourceId.split('.');
          const job = workers[workerIndex].jobs.find(
            el => el.id === source.index
          );

          unassignJobFromWorker({
            body: { job_id: job.id },
            workerIndex,
          });
        } else {
          const [srcIndex] = sourceId.split('.');
          const [destIndex, serviceHour] = destinationId.split('.');

          const job = workers[srcIndex].jobs.find(el => el.id === source.index);
          if (job.job_status === 'cancelled' || job.job_status === 'skipped') {
            return notification.error({
              message: 'This job can not be moved ',
            });
          }
          if (workers[srcIndex].vendor_id !== job.vendor_id) {
            return notification.error({
              message: 'Job not belongs to this vendor ',
            });
          }
          const body = {
            job_id: job.id,
            vendor_id: job.vendor_id,
            assigned_worker_id: workers[destIndex].id,
            service_date: job.service_date,
            assigned_time: serviceHour,
          };
          reassignJobToWorker({
            body,
            destIndex,
            srcIndex,
          });
        }
      }
    }
  };

  render() {
    const { collapsed, pickerOpened, currentDate, CURRENT_DATE } = this.state;
    const { loadingWorkers, workers, vendors, vendorSelected, totalWorkers } =
      this.props;

    let vendorSelectedObj = null;

    if (vendorSelected && vendors) {
      vendorSelectedObj = vendors.find(item => item['id'] === vendorSelected);
    }

    return (
      <div className="schedule-page">
        <div className="page-header">
          <div className="page-title">
            <h4>Scheduler</h4>
            <Row className="page-date-selector">
              <Col flex={1}>
                <Button
                  type="link"
                  className="page-btn"
                  icon={<LeftOutlined />}
                  onClick={() => this.handleDateChange('prev')}
                />
              </Col>
              <Col
                flex={3}
                onClick={() => this.handleDatePicker(true)}
                style={{ textAlign: 'center' }}
              >
                <DatePicker
                  bordered={false}
                  allowClear={false}
                  suffixIcon={null}
                  showToday={true}
                  format="YYYY-MM-DD"
                  open={pickerOpened}
                  onOpenChange={this.handleDatePicker}
                  value={currentDate}
                  onChange={this.handleDateChange}
                />
              </Col>
              <Col flex={1} style={{ textAlign: 'end' }}>
                <Button
                  type="link"
                  className="page-btn"
                  icon={<RightOutlined />}
                  onClick={() => this.handleDateChange('next')}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="page-content">
          <DragDropContext isDrag onDragEnd={this.onDragEnd}>
            {/* <JobContext.Provider
              value={{
                permanentReassign,
                permanentReassignSelectedDay,
                checkboxOnChange: this.checkboxOnChange,
                handlePermReassignSelectedDay: this
                  .handlePermReassignSelectedDay,
              }}
            > */}
            <VendorList
              allowUpdate={currentDate.isSame(CURRENT_DATE, 'date')}
              // reassignedJobIds={permanentlyReassignedJobsId}
              setSchedulerState={this.setState.bind(this)}
              collapsed={collapsed}
              currentDate={currentDate}
              handleCollapse={this.handleCollapse}
              handleReload={this.handleReload}
              // assignedJobsLength={assignedJobsLength}
            />
            {/* </JobContext.Provider> */}

            <div className="schedule-content">
              <div className="main-content">
                <div className="schedule-content-header">
                  <div className="schedule-content-header-top">
                    <h3>Worker Schedule</h3>
                    {vendorSelectedObj && (
                      <>
                        <span className="header-dash">-</span>
                        <h4>{vendorSelectedObj.vendor_name}</h4>
                      </>
                    )}
                  </div>
                  <div className="schedule-content-header-bottom">
                    <p>{totalWorkers} Data employees found</p>
                    {/* <div className="schedule-content-header-pagination">
                      <span>
                        {currentWorkerPage} of {totalWorkerPages}
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="schedule-content-body">
                  {loadingWorkers ? (
                    <div className="loading-overlay">
                      <LoadingOutlined />
                    </div>
                  ) : (
                    workers.map((el, i) => (
                      // <JobContext.Provider
                      //   key={el.id}
                      //   value={{
                      //     permanentReassign,
                      //     permanentReassignSelectedDay,
                      //     checkboxOnChange: this.checkboxOnChange,
                      //     handlePermReassignSelectedDay: this
                      //       .handlePermReassignSelectedDay,
                      //   }}
                      // >
                      <WorkerCard
                        key={el.id}
                        allowUpdate={currentDate.isSame(CURRENT_DATE, 'date')}
                        // reassignedJobIds={permanentlyReassignedJobsId}
                        currentDate={currentDate}
                        setSchedulerState={this.setState.bind(this)}
                        index={i}
                        worker={el}
                      />
                      // </JobContext.Provider>
                    ))
                  )}
                </div>
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

Scheduler.propTypes = {
  loadingWorkers: PropTypes.bool,
  workers: PropTypes.array,
  vendors: PropTypes.array,
  vendorSelected: PropTypes.number,
  currentWorkerPage: PropTypes.number,
  totalWorkerPages: PropTypes.number,
  totalWorkers: PropTypes.number,
  searchVendorsSuccess: PropTypes.func,
  unassignJobFromWorker: PropTypes.func,
  assignJobToWorker: PropTypes.func,
  reassignJobToWorker: PropTypes.func,
  assignWorkerPerm: PropTypes.func,
  searchWorkers: PropTypes.func,
  formatWorkerContent: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loadingWorkers: state.vendorScheduler.loadingWorkers,
    workers: state.vendorScheduler.workers,
    vendors: state.vendorScheduler.vendors,
    vendorSelected: state.vendorScheduler.vendorSelected,
    currentWorkerPage: state.vendorScheduler.currentWorkerPage,
    totalWorkerPages: state.vendorScheduler.totalWorkerPages,
    totalWorkers: state.vendorScheduler.totalWorkers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formatWorkerContent: () => dispatch(formatWorkerContent()),
    handleVendorChange: v => dispatch(handleVendorChange(v)),
    searchWorkers: params => dispatch(searchWorkers(params)),
    assignJobToWorker: payload => dispatch(assignJobToWorker(payload)),
    reassignJobToWorker: payload => dispatch(reassignJobToWorker(payload)),
    unassignJobFromWorker: payload => dispatch(unassignJobFromWorker(payload)),
    assignWorkerPerm: (id, data) => dispatch(assignWorkerPerm({ id, data })),
    searchVendorsSuccess: payload => dispatch(searchVendorsSuccess(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
