import {
  RightOutlined,
  CloseOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { EditFilledIcon } from '../../../components/Icons/Icons';
import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { getNotes, getNewNotes, updateNotes } from '../reducers';

import 'react-perfect-scrollbar/dist/css/styles.css';

const { TextArea } = Input;

class NotesDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fixed: false,
      screenDimensions: [0, 0],
      edit: false,
      note: {
        leads: '',
        new_customer: '',
        cancelling_customer: '',
        pause_subscription: '',
        resume_subscription: '',
      },
      updated_at: '',
      newUpdated_at: '',
      modalOpened: null,
      showSubmit: false,
      modalConfirm: null,
    };
  }

  updateSize = () => {
    this.setState({
      screenDimensions: [window.innerWidth, window.innerHeight],
    });
  };

  componentDidMount() {
    // this.fetchVendors();
    const { getNotes } = this.props;
    getNotes();
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps)
      this.setState({
        note: { ...this.state.note, ...this.props?.notes?.content },
        updated_at: this.props?.notes?.updated_at,
        newUpdated_at: this.props?.newNotes?.updated_at,
      });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.updateSize);
  }

  onScroll = () => {
    this.setState({
      fixed: window.scrollY > 161,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpened: 1,
    });
  };

  closeAlert = () => {
    this.setState({
      modalOpened: null,
    });
  };

  discardChanges = () => {
    this.setState({
      edit: false,
      modalOpened: null,
    });
  };
  closeConfirm = () => {
    this.setState({
      modalConfirm: null,
    });
  };

  handleNewNotes = values => {
    const { getNewNotes } = this.props;
    getNewNotes();
    this.setState({ showSubmit: true, note: { ...values } });
  };

  reloadNotes = () => {
    const { getNotes, newNotes } = this.props;
    getNotes();
    this.setState({
      note: newNotes?.content,
      updated_at: '',
      newUpdated_at: '',
      showSubmit: false,
      edit: false,
      modalConfirm: null,
    });
  };
  handleOnSubmit = values => {
    const { updateNotes } = this.props;
    const { newUpdated_at, updated_at } = this.state;

    if (newUpdated_at === updated_at) {
      updateNotes({ ...values });

      this.setState({
        edit: false,
        showSubmit: false,
        modalConfirm: null,
        updated_at: '',
        newUpdated_at: '',
      });
    } else {
      this.setState({
        modalConfirm: 1,
      });
    }
  };

  editNotes = () => {
    this.setState({
      edit: true,
    });
  };

  render() {
    const { collapsed, handleCollapse, loadingNotes } = this.props;

    const { fixed, edit, note, showSubmit, modalConfirm, modalOpened } =
      this.state;

    return (
      <div
        className={`notes-drawer ${fixed ? 'fixed ' : ''}${
          collapsed ? 'collapsed' : 'expanded'
        }`}
      >
        <div className="notes-drawer-container">
          <PerfectScrollbar>
            <div className="header">
              <h3 style={{ marginLeft: '10px' }}>Notes</h3>
              <div className="header-actions">
                {!edit ? (
                  <EditFilledIcon
                    className="action-primary"
                    onClick={this.editNotes}
                    style={{ marginRight: '12px' }}
                  />
                ) : (
                  <CloseOutlined
                    onClick={this.closeModal}
                    style={{ marginRight: '12px' }}
                  />
                )}
              </div>
            </div>
            <div className="notes-drawer-content">
              {edit ? (
                <Formik
                  initialValues={note}
                  onSubmit={values => this.handleOnSubmit(values)}
                >
                  {({ values, handleChange, handleSubmit }) => {
                    return (
                      <>
                        {loadingNotes ? (
                          <div className="loading-overlay">
                            <LoadingOutlined />
                          </div>
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label>Leads</label>
                              <Form.Item style={{ marginBottom: '5px' }}>
                                <TextArea
                                  name="leads"
                                  autoSize
                                  value={values?.leads}
                                  placeholder="Enter Leads Notes"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>New Customer</label>
                              <Form.Item style={{ marginBottom: '5px' }}>
                                <TextArea
                                  name="new_customer"
                                  autoSize
                                  value={values?.new_customer}
                                  placeholder="Enter New Customer Notes"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Cancelling Customer</label>
                              <Form.Item style={{ marginBottom: '5px' }}>
                                <TextArea
                                  name="cancelling_customer"
                                  autoSize
                                  value={values?.cancelling_customer}
                                  placeholder="Enter Cancelling Customer Notes"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Pause Subscription</label>
                              <Form.Item style={{ marginBottom: '5px' }}>
                                <TextArea
                                  name="pause_subscription"
                                  autoSize
                                  value={values?.pause_subscription}
                                  placeholder="Enter Pause Subscription Notes"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            <div className="form-group">
                              <label>Resume Subscription</label>
                              <Form.Item style={{ marginBottom: '5px' }}>
                                <TextArea
                                  name="resume_subscription"
                                  autoSize
                                  value={values?.resume_subscription}
                                  placeholder="Enter Resume Subscription Notes"
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </div>
                            {showSubmit ? (
                              <Button type="primary" htmlType="submit" block>
                                Save
                              </Button>
                            ) : (
                              <Button
                                type="primary"
                                block
                                onClick={values => this.handleNewNotes(values)}
                              >
                                Ready to save
                              </Button>
                            )}
                          </form>
                        )}
                      </>
                    );
                  }}
                </Formik>
              ) : (
                <div>
                  <div className="note-group">
                    <h3>Leads</h3>
                    <TextArea
                      readOnly
                      autoSize
                      rows={4}
                      value={note?.leads}
                      placeholder="No Notes"
                    />
                  </div>
                  <div className="note-group">
                    <h3>New Customer</h3>
                    <TextArea
                      readOnly
                      autoSize
                      rows={4}
                      value={note?.new_customer}
                      placeholder="No Notes"
                    />
                  </div>
                  <div className="note-group">
                    <h3>Cancelling Customer</h3>
                    <TextArea
                      readOnly
                      autoSize
                      rows={4}
                      value={note?.cancelling_customer}
                      placeholder="No Notes"
                    />
                  </div>
                  <div className="note-group">
                    <h3>Pause Subscription</h3>
                    <TextArea
                      readOnly
                      autoSize
                      rows={4}
                      value={note?.pause_subscription}
                      placeholder="No Notes"
                    />
                  </div>
                  <div className="note-group">
                    <h3>Resume Subscription</h3>
                    <TextArea
                      readOnly
                      autoSize
                      rows={4}
                      value={note?.resume_subscription}
                      placeholder="No Notes"
                    />
                  </div>
                </div>
              )}
            </div>
            <Modal
              visible={modalConfirm === 1}
              onCancel={() => this.closeConfirm()}
              onOk={() => this.reloadNotes()}
              okText="Reload"
              destroyOnClose
            >
              <>
                <h3 style={{ display: 'inline-flex' }}>
                  <InfoCircleOutlined
                    style={{
                      color: '#faad14',
                      marginRight: '5px',
                      fontSize: 22,
                    }}
                  />{' '}
                  Warning
                </h3>
                <h4>
                  There are new changes available to the notes that you can miss
                  or overwrite. Please refresh before making any updates.
                </h4>
              </>
            </Modal>
            <Modal
              visible={modalOpened === 1}
              onCancel={() => this.closeAlert()}
              onOk={() => this.discardChanges()}
              okText="Discard"
              okType="danger"
              destroyOnClose
            >
              <>
                <h3 style={{ display: 'inline-flex' }}>
                  <InfoCircleOutlined
                    style={{
                      color: '#faad14',
                      marginRight: '5px',
                      fontSize: 22,
                    }}
                  />{' '}
                  Warning
                </h3>
                <h4>Please save your changes otherwise it may discard.</h4>
              </>
            </Modal>
          </PerfectScrollbar>
        </div>

        <Button
          type="link"
          className="btn-collapse"
          icon={<RightOutlined />}
          onClick={handleCollapse}
        />
      </div>
    );
  }
}

NotesDrawer.propTypes = {
  collapsed: PropTypes.bool,
  handleCollapse: PropTypes.func,
  getNotes: PropTypes.func,
  getNewNotes: PropTypes.func,
  loadingNotes: PropTypes.bool,
  updateNotes: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    notes: state.scheduler.notes?.admin_note,
    newNotes: state.scheduler.newNotes?.admin_note,
    loadingNotes: state.scheduler.loadingNotes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNotes: () => dispatch(getNotes()),
    getNewNotes: () => dispatch(getNewNotes()),
    updateNotes: payload => dispatch(updateNotes(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotesDrawer);
