import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  DatePicker,
  Drawer,
  Modal,
  Select,
  // TimePicker,
  Form,
  Checkbox,
} from 'antd';
import ReactTimePicker from '../../../components/TimePicker';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getJob,
  updateJob,
  deleteJob,
  assignWorker,
  getUpcomingJobs,
  pauseJobs,
  cancelJobs,
  skipJobs,
} from '../reducers';
import { getJobById } from '../../JobPage/reducers';
import DataTable from '../../../components/DataTable';
import JobForm from '../../JobPage/components/form';
import JobDetailModal from './jobDetailModal';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { formInitialValues } from '../../../utils/formsInitialValues';
import { formValidations } from '../../../utils/formValidations';
import {
  EditFilledIcon,
  DeleteFilledIcon,
} from '../../../components/Icons/Icons';
import MobileTable from './MobTable';

const { Option } = Select;

const statusLabels = {
  pending_schedule: {
    color: '#F1B13B',
    label: 'Unassigned',
  },
  scheduled: {
    color: '#34A6BD',
    label: 'In Scheduled',
  },
  in_progress: {
    color: '#6ABB98',
    label: 'In Progress',
  },
  completed: {
    color: '#828282',
    label: 'Completed',
  },
  cancelled: {
    color: '#DF5E67',
    label: 'Cancelled',
  },
  skipped: {
    color: '#34A6BD',
    label: 'Skipped',
  },
  paused: {
    color: '#34A6BD',
    label: 'Paused',
  },
};

class UpcomingJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isJobDetailsModalOpen: false,
      jobID: '',
      modalOpened: false,
      selectedRow: null,
      formData: null,
      assignData: null,
      pauseData: {
        start_date: null,
        end_date: null,
        update: false,
      },
      cancelSkipData: {
        status: null,
        start_date: null,
        end_date: null,
        update: false,
      },
      cancelSkipCheckbox: true,
      pageQuery: {
        page: 1,
        per: 20,
        query: null,
      },
      windowSize: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      submitting,
      error,
      match: {
        params: { id },
      },
      getUpcomingJobs,
      queryString,
    } = this.props;

    if (
      (prevProps['submitting'] !== submitting ||
        prevProps['error'] !== error) &&
      !submitting &&
      !error
    ) {
      this.closeModal();
    }
    const { pageQuery } = this.state;
    if (pageQuery && prevState['pageQuery'] !== pageQuery) {
      if (queryString) {
        getUpcomingJobs({ ...pageQuery, query: queryString, id: id });
      } else {
        getUpcomingJobs({ ...pageQuery, id: id });
      }
      if (this.state?.cancelSkipData?.update)
        this.setState(state => ({
          ...state,
          cancelSkipData: { ...state.cancelSkipData, update: false },
        }));
    }
  }

  handleWindowSize = () => {
    if (window.innerWidth < 767) {
      this.setState({ windowSize: true });
    } else {
      this.setState({ windowSize: false });
    }
  };

  componentDidMount() {
    const {
      getUpcomingJobs,
      queryString,
      match: {
        params: { id },
      },
    } = this.props;
    const { pageQuery } = this.state;
    if (window.innerWidth < 767) {
      this.handleWindowSize();
    }
    window.addEventListener('resize', this.handleWindowSize);
    if (queryString) {
      getUpcomingJobs({ ...pageQuery, query: queryString, id: id });
    } else {
      getUpcomingJobs({ ...pageQuery, id: id });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSize);
  }

  closeModal = () => {
    this.setState({
      modalOpened: false,
      selectedRow: null,
      formData: null,
      assignData: null,
      // cancelSkipData: null,
    });
  };

  onClickAssign = () => {
    this.setState({
      modalOpened: 4,
      selectedRow: null,
      formData: null,
      cancelSkipData: null,
      assignData: {
        start_date: null,
        end_date: null,
        assigned_worker_id: undefined,
        assigned_time: null,
      },
    });
  };

  onPauseSub = () => {
    this.setState({
      modalOpened: 7,
      selectedRow: null,
      formData: null,
      pauseData: {
        status: null,
        start_date: null,
        end_date: null,
        update: false,
      },
      assignData: null,
    });
  };

  onCancelSkip = () => {
    this.setState({
      modalOpened: 5,
      selectedRow: null,
      formData: null,
      cancelSkipData: {
        status: null,
        start_date: null,
        end_date: null,
        update: false,
      },
      assignData: null,
    });
  };
  onConfirm = () => {
    this.setState({
      modalOpened: 6,
      selectedRow: null,
      formData: null,
      assignData: null,
    });
  };

  onPauseConfirm = () => {
    this.setState({
      modalOpened: 8,
      selectedRow: null,
      formData: null,
      assignData: null,
    });
  };

  onClickRow = (e, { id }) => {
    this.setState({ isJobDetailsModalOpen: true, jobID: id });
  };

  onEdit = record => {
    this.setState({
      modalOpened: 1,
      selectedRow: null,
      assignData: null,
      formData: {
        id: record.id,
        title: record.title,
        description: record.description,
        job_duration_in_minutes: record.job_duration_in_minutes,
        client_type:
          record.client_type === 'PersonalClient' ? 'personal' : 'business',
        vendor_id: record.vendor_id,
        assigned_worker_id: record['assigned_worker']
          ? record['assigned_worker'].id
          : '',
        assigned_time: record.assigned_time,
        client_id: record.client.id,
        address_id: record.address.id,
        service_date: record.service_date,
        preferred_time: record.preferred_time,
        job_notes: record.job_notes,
      },
    });
  };

  onDelete = record => {
    this.setState({
      isJobDetailsModalOpen: false,
      modalOpened: 2,
      selectedRow: record,
      formData: null,
      assignData: null,
    });
  };

  onInputChange = ({ name, value }) => {
    const { assignData } = this.state;
    assignData[name] = value;

    this.setState({ assignData });
  };

  performDelete = () => {
    const { selectedRow } = this.state;
    const { deleteJob } = this.props;

    deleteJob(selectedRow.id);
    this.closeModal();
  };

  bulkAssign = values => {
    const { job, assignWorker } = this.props;
    const { assignData } = this.state;
    assignWorker(job.id, { ...assignData, ...values });
  };

  onSubmit = data => {
    const { updateJob } = this.props;
    const body = {
      job: {
        id: data.id,
        title: data.title,
        description: data.description,
        job_duration_in_minutes: data.job_duration_in_minutes,
        vendor_id: data.vendor_id,
        assigned_worker_id: data.assigned_worker_id,
        assigned_time: data.assigned_time,
        client_type:
          data.client_type === 'personal' ? 'PersonalClient' : 'BusinessClient',
        client_id: data.client_id,
        address_id: data.address_id,
        service_date: data.service_date,
        preferred_time: data.preferred_time,
        job_notes: data.job_notes,
      },
    };

    updateJob(body);
  };

  tableColumns = () => [
    {
      title: 'Job ID',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left',
    },
    {
      title: 'Status',
      key: 'job_status',
      fixed: 'left',
      width: 70,
      render: (_, record) => {
        return (
          <span
            style={{
              color: statusLabels[record.job_status]
                ? statusLabels[record.job_status].color
                : '',
            }}
          >
            {statusLabels[record.job_status]
              ? statusLabels[record.job_status].label
              : ''}
          </span>
        );
      },
    },
    {
      title: 'Service Date',
      key: 'service_date',
      dataIndex: 'service_date',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const dateA = new Date(a.service_date).getTime();
        const dateB = new Date(b.service_date).getTime();
        return dateA - dateB;
      },
      render: (_, record) => {
        return record?.service_date
          ? moment(record.service_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : '-';
      },
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
      width: 140,
    },
    {
      title: 'Client Name',
      key: 'client_name',
      width: 180,
      render: (_, record) =>
        record?.client_type === 'PersonalClient'
          ? record.client?.title
          : record.client?.business_name,
    },
    {
      title: 'Client  Address',
      key: 'client_address',
      width: 160,
      render: (_, record) =>
        `${record?.address?.address_title}, ${record?.address?.address}, ${record?.address?.unit_number}`,
    },
    {
      title: 'Vendor Name',
      key: 'vendor_id',
      width: 160,
      render: (_, record) =>
        record?.vendor ? record.vendor?.vendor_name : '-',
    },
    {
      title: 'Assigned Worker',
      key: 'worker',
      width: 160,
      render: (_, record) =>
        record['assigned_worker']
          ? Object.keys(record['assigned_worker']).length > 0
            ? `${record['assigned_worker']?.first_name} ${record['assigned_worker']?.last_name}`
            : '-'
          : '-',
    },
    {
      title: 'Preferred Time',
      key: 'preferred_time',
      width: 120,
      render: (_, record) =>
        record['preferred_time'] ? record['preferred_time'] : '-',
    },
    {
      title: 'Assigned Time',
      key: 'assigned_time',
      width: 120,
      render: (_, record) =>
        record['assigned_time'] ? record['assigned_time'] : '-',
    },
    {
      title: 'Service Duration',
      key: 'job_duration_in_minutes',
      width: 120,
      render: (_, record) =>
        record['job_duration_in_minutes']
          ? record['job_duration_in_minutes'] + ' min'
          : '-',
    },
    {
      title: 'Check In',
      key: 'checkin_time',
      width: 120,
      render: (_, record) => (record?.checkin_time ? record.checkin_time : '-'),
    },
    {
      title: 'Check Out',
      key: 'checkout_time',
      width: 120,
      render: (_, record) =>
        record?.checkout_time ? record.checkout_time : '-',
    },
    {
      title: 'Active Job Duration',
      width: 160,
      render: (_, record) => {
        if (record?.checkin_time && record?.checkout_time) {
          return record['total_job_time'];
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Job Notes',
      key: 'job_notes',

      render: (_, record) =>
        record['job_notes']
          ? `${
              record['job_notes']['length'] > 25
                ? record['job_notes'].slice(0, 25) + '...'
                : record['job_notes']
            }`
          : '-',
    },
    {
      title: 'Customer Notes',
      key: 'customer_notes',
      width: 120,
      render: (_, record) =>
        record['customer_notes']
          ? `${
              record['customer_notes']['length'] > 25
                ? record['customer_notes'].slice(0, 25) + '...'
                : record['customer_notes']
            }`
          : '-',
    },
    {
      title: 'Duration',
      key: 'job_duration_in_minutes',
      width: 120,
      render: (_, record) =>
        record['job_duration_in_minutes']
          ? record['job_duration_in_minutes']
          : '-',
    },
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      width: 120,
      align: 'center',
      render: (text, record) => (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || this.onEdit(record)}
          />
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || this.onDelete(record)}
          />
        </div>
      ),
    },
  ];

  jobDetailsModalClose = () => {
    this.setState(state => ({
      ...state,
      isJobDetailsModalOpen: false,
      jobID: null,
    }));
  };

  jobDetailsModalOpen = () => {
    this.setState({ isJobDetailsModalOpen: true });
  };

  onPageChange = newPage => {
    const { pageQuery } = this.state;
    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = newPage;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { pageQuery } = this.state;
    const { order } = sorter;

    let updatedPageQuery = { ...pageQuery };
    updatedPageQuery['page'] = pagination['current'];
    updatedPageQuery['per'] = pagination['pageSize'];
    updatedPageQuery['order'] = order;
    this.setState({ pageQuery: updatedPageQuery });
  };

  handlePauseJobs = () => {
    const { queryString } = this.props;
    const { pageQuery } = this.state;

    if (queryString) {
      this.props.pauseJobs({
        id: this.props.job.id,
        start_date: this.state.pauseData.start_date,
        end_date: this.state.pauseData.end_date,
        ...pageQuery,
        query: queryString,
      });
    } else {
      this.props.pauseJobs({
        id: this.props.job.id,
        start_date: this.state.pauseData.start_date,
        end_date: this.state.pauseData.end_date,
        ...pageQuery,
      });
    }
  };

  handleCancelJobs = () => {
    const { queryString } = this.props;
    const { pageQuery, cancelSkipCheckbox } = this.state;

    if (queryString) {
      this.props.cancelJobs({
        id: this.props.job.id,
        start_date: this.state.cancelSkipData.start_date,
        end_date: this.state.cancelSkipData.end_date,
        ...pageQuery,
        query: queryString,
        remove_assigned_worker_and_time: cancelSkipCheckbox,
      });
    } else {
      this.props.cancelJobs({
        id: this.props.job.id,
        start_date: this.state.cancelSkipData.start_date,
        end_date: this.state.cancelSkipData.end_date,
        ...pageQuery,
        remove_assigned_worker_and_time: cancelSkipCheckbox,
      });
    }
  };

  handleSkipJobs = () => {
    const { queryString } = this.props;
    const { pageQuery } = this.state;

    if (queryString) {
      this.props.skipJobs({
        id: this.props.job.id,
        start_date: this.state.cancelSkipData.start_date,
        end_date: this.state.cancelSkipData.end_date,
        ...pageQuery,
        query: queryString,
      });
    } else {
      this.props.skipJobs({
        id: this.props.job.id,
        start_date: this.state.cancelSkipData.start_date,
        end_date: this.state.cancelSkipData.end_date,
        ...pageQuery,
      });
    }
  };

  render() {
    const { loading, workers, upcomingJobs, status } = this.props;
    const { modalOpened, selectedRow, formData, assignData, windowSize } =
      this.state;
    const { limit, total, page, data } = upcomingJobs;

    return (
      <>
        <div className="page-sub-header">
          <h4>Jobs List</h4>
          <div>
            {status === 'active' ? (
              <Button
                type="primary"
                onClick={this.onPauseSub}
                style={{ marginRight: '10px' }}
              >
                Pause Subscription
              </Button>
            ) : (
              ''
            )}
            <Button
              type="danger"
              onClick={this.onCancelSkip}
              style={{ marginRight: '10px' }}
            >
              Cancel/Skip
            </Button>
            <Button type="primary" onClick={this.onClickAssign}>
              Assign Worker
            </Button>
          </div>
        </div>
        {windowSize ? (
          <Card
            title="All Jobs"
            className="overflow-x-auto"
            style={{ marginTop: 15 }}
          >
            <MobileTable
              records={data}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              onEdit={this.onEdit}
              onDelete={this.onDelete}
              onClickRow={this.onClickRow}
              handleTableChange={this.handleTableChange}
            />
          </Card>
        ) : (
          <Card>
            <DataTable
              rowKey={record => record['id']}
              loading={loading}
              pagination={{
                limit,
                total,
                page,
                data,
                onChange: this.onPageChange,
              }}
              handleTableChange={this.handleTableChange}
              columns={this.tableColumns()}
              onClickRow={this.onClickRow}
            />
          </Card>
        )}
        <Drawer
          closable={false}
          placement="right"
          width={450}
          className="job-create-drawer"
          visible={modalOpened === 1}
          onClose={this.closeModal}
        >
          {formData && (
            <>
              <div className="drawer-header">
                <h3>Edit Job</h3>
                <div className="header-actions">
                  <DeleteFilledIcon
                    className="action-primary"
                    onClick={() => this.onDelete(formData)}
                  />
                  <CloseOutlined onClick={this.closeModal} />
                </div>
              </div>
              <div className="drawer-body">
                <JobForm
                  data={formData}
                  type="single"
                  onSubmit={this.onSubmit}
                />
              </div>
            </>
          )}
        </Drawer>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 2}
          onCancel={this.closeModal}
          onOk={this.performDelete}
          okText="Yes"
          destroyOnClose
        >
          {selectedRow && (
            <>
              <h1 className="main-message pop-up-heading">Are you sure?</h1>
              <p className="pop-up-body">
                Do you really want to remove this job? The process cannot be
                undone.
              </p>
            </>
          )}
        </Modal>
        {this.state.isJobDetailsModalOpen && (
          <JobDetailModal
            jobID={this.state.jobID}
            jobDetailsModalClose={this.jobDetailsModalClose}
          />
        )}
        <Modal
          title="Assign Worker"
          className="assign-worker-modal"
          visible={modalOpened === 4}
          onCancel={this.closeModal}
          okButtonProps={{ htmlType: 'submit', form: 'assign-worker-form' }}
          destroyOnClose
        >
          {assignData && (
            <Formik
              initialValues={{
                ...formInitialValues['assignWorkerForm'],
                ...formData,
              }}
              validationSchema={formValidations['AssignWorkerFormValidation']}
              onSubmit={values => this.bulkAssign(values)}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => {
                return (
                  <form id="assign-worker-form" onSubmit={handleSubmit}>
                    <div className="date-range">
                      <label>Select Date Range</label>

                      <div className="form-row">
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['start_date'] && errors['start_date']
                              ? 'error'
                              : null
                          }
                          help={touched['start_date'] && errors['start_date']}
                          style={{ margin: 0, width: '100%' }}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            value={
                              values.start_date
                                ? moment(values.start_date, 'YYYY-MM-DD')
                                : null
                            }
                            onChange={(date, dateString) => {
                              handleChange({
                                target: {
                                  name: 'start_date',
                                  value: dateString,
                                },
                              });
                            }}
                          />
                        </Form.Item>
                        <span>to</span>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['end_date'] && errors['end_date']
                              ? 'error'
                              : null
                          }
                          help={touched['end_date'] && errors['end_date']}
                          style={{ margin: 0, width: '100%' }}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            value={
                              values.end_date
                                ? moment(values.end_date, 'YYYY-MM-DD')
                                : null
                            }
                            onChange={(date, dateString) =>
                              handleChange({
                                target: {
                                  name: 'end_date',
                                  value: dateString,
                                },
                              })
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="assign-worker">
                      <div className="form-group">
                        <label>Select Worker</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['assigned_worker_id'] &&
                            errors['assigned_worker_id']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['assigned_worker_id'] &&
                            errors['assigned_worker_id']
                          }
                          style={{ margin: 0 }}
                        >
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            value={values.assigned_worker_id}
                            placeholder="Select Worker"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            onChange={value =>
                              handleChange({
                                target: {
                                  name: 'assigned_worker_id',
                                  value,
                                },
                              })
                            }
                          >
                            {workers.map(el => (
                              <Option key={el.id} value={el.id}>
                                {`${el.first_name} ${el.last_name}`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>Assigned Time</label>
                        <Form.Item
                          hasFeedback
                          validateStatus={
                            touched['assigned_time'] && errors['assigned_time']
                              ? 'error'
                              : null
                          }
                          help={
                            touched['assigned_time'] && errors['assigned_time']
                          }
                          style={{ margin: 0 }}
                        >
                          {/* <TimePicker
                            format="hh:mm A"
                            style={{ width: '100%' }}
                            use12Hours
                            value={
                              values.assigned_time
                                ? moment(values.assigned_time, 'hh:mm A')
                                : null
                            }
                            onChange={val =>
                              handleChange({
                                target: {
                                  name: 'assigned_time',
                                  value: val.format('hh:mm A'),
                                },
                              })
                            }
                          /> */}
                          <ReactTimePicker
                            name="assigned_time"
                            item={values}
                            cahngeFormTime={(item, timeString) => {
                              handleChange({
                                target: {
                                  name: 'assigned_time',
                                  value: timeString,
                                },
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </Modal>
        <Modal
          title="Confirmation"
          className="assign-worker-modal"
          visible={modalOpened === 5}
          onCancel={this.closeModal}
          footer={[
            <Button
              key={0}
              type="danger"
              style={{ width: '150px', borderRadius: '4px' }}
              htmlType="submit"
              form="cancel-skip"
              onClick={e => {
                e.stopPropagation();
                this.setState(state => ({
                  ...state,
                  cancelSkipData: { ...state.cancelSkipData, status: 'cancel' },
                }));
              }}
            >
              Cancel Service
            </Button>,
            <Button
              key="submit"
              type="primary"
              style={{ width: '150px' }}
              htmlType="submit"
              form="cancel-skip"
              onClick={e => {
                e.stopPropagation();
                this.setState(state => ({
                  ...state,
                  cancelSkipData: { ...state.cancelSkipData, status: 'skip' },
                }));
              }}
            >
              Skip Service
            </Button>,
          ]}
          okButtonProps={{ htmlType: 'submit', form: 'cancel-skip' }}
          cancelButtonProps={{ htmlType: 'submit', form: 'cancel-skip' }}
          destroyOnClose
        >
          <div className="job-cancel-modal">
            <p>Are you sure want to cancel/skip the service?</p>
          </div>
          <Formik
            initialValues={{
              start_date: '',
              end_date: '',
            }}
            validationSchema={formValidations['CancelSkipFormValidation']}
            onSubmit={values => {
              this.onConfirm();
              this.setState(state => ({
                ...state,
                cancelSkipData: {
                  ...state.cancelSkipData,
                  start_date: values.start_date,
                  end_date: values.end_date,
                },
              }));
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form id="cancel-skip" onSubmit={handleSubmit}>
                <div className="date-range">
                  <label>Select Date Range</label>

                  <div className="form-row">
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['start_date'] && errors['start_date']
                          ? 'error'
                          : null
                      }
                      help={touched['start_date'] && errors['start_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.start_date
                            ? moment(values.start_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) => {
                          handleChange({
                            target: {
                              name: 'start_date',
                              value: dateString,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                    <span>to</span>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['end_date'] && errors['end_date']
                          ? 'error'
                          : null
                      }
                      help={touched['end_date'] && errors['end_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.end_date
                            ? moment(values.end_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) =>
                          handleChange({
                            target: {
                              name: 'end_date',
                              value: dateString,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </div>
                  <Checkbox
                    className="checkbox"
                    checked={this.state.cancelSkipCheckbox}
                    onChange={() => {
                      this.setState({
                        cancelSkipCheckbox: !this.state.cancelSkipCheckbox,
                      });
                    }}
                  >
                    <p style={{ fontWeight: 'bold' }}>
                      Would you like to remove assigned worker and assigned time
                      as well.
                    </p>
                  </Checkbox>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 6}
          onCancel={() => {
            this.closeModal();
          }}
          onOk={() => {
            if (this.state.cancelSkipData.status === 'cancel') {
              this.handleCancelJobs();
            } else if (this.state.cancelSkipData.status === 'skip') {
              this.handleSkipJobs();
            }
            this.closeModal();
          }}
          okText="Yes"
          destroyOnClose
        >
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
            <p className="pop-up-body">
              {`To ${this.state?.cancelSkipData?.status} this service. The process cannot be undone.`}
            </p>
          </>
        </Modal>
        <Modal
          title="Confirmation"
          className="assign-worker-modal"
          visible={modalOpened === 7}
          onCancel={this.closeModal}
          footer={[
            <Button key={0} type="danger" onClick={this.closeModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              form="pause-sub"
              onClick={e => {
                e.stopPropagation();
                this.setState(state => ({
                  ...state,
                  pauseData: { ...state.pauseData, status: 'skip' },
                }));
              }}
            >
              Pause Service
            </Button>,
          ]}
          okButtonProps={{ htmlType: 'submit', form: 'cancel-skip' }}
          destroyOnClose
        >
          <div className="job-cancel-modal">
            <p>Are you sure want to pause the service?</p>
          </div>
          <Formik
            initialValues={{
              start_date: '',
              end_date: '',
            }}
            validationSchema={formValidations['PauseSubscriptionValidation']}
            onSubmit={values => {
              this.onPauseConfirm();
              this.setState(state => ({
                ...state,
                pauseData: {
                  ...state.pauseData,
                  start_date: values.start_date,
                  end_date: values.end_date,
                },
              }));
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form id="pause-sub" onSubmit={handleSubmit}>
                <div className="date-range">
                  <label>Select Date Range</label>

                  <div className="form-row">
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['start_date'] && errors['start_date']
                          ? 'error'
                          : null
                      }
                      help={touched['start_date'] && errors['start_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.start_date
                            ? moment(values.start_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) => {
                          handleChange({
                            target: {
                              name: 'start_date',
                              value: dateString,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                    <span>to</span>
                    <Form.Item
                      hasFeedback
                      validateStatus={
                        touched['end_date'] && errors['end_date']
                          ? 'error'
                          : null
                      }
                      help={touched['end_date'] && errors['end_date']}
                      style={{ margin: 0, width: '100%' }}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        value={
                          values.end_date
                            ? moment(values.end_date, 'YYYY-MM-DD')
                            : null
                        }
                        onChange={(date, dateString) =>
                          handleChange({
                            target: {
                              name: 'end_date',
                              value: dateString,
                            },
                          })
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
        <Modal
          className="delete-user-modal"
          visible={modalOpened === 8}
          onCancel={() => {
            this.closeModal();
          }}
          onOk={() => {
            this.handlePauseJobs();
            this.closeModal();
          }}
          okText="Pause"
          destroyOnClose
        >
          <>
            <h1 className="main-message pop-up-heading">Are you sure?</h1>
            <p className="pop-up-body">The process cannot be undone.</p>
          </>
        </Modal>
      </>
    );
  }
}

UpcomingJobs.propTypes = {
  loading: PropTypes.bool,
  job: PropTypes.object,
  getJobs: PropTypes.func,
  updateJob: PropTypes.func,
  deleteJob: PropTypes.func,
  assignWorker: PropTypes.func,
  workers: PropTypes.array,
  history: PropTypes.object,
  submitting: PropTypes.bool,
  error: PropTypes.string,
  upcomingJobs: PropTypes.object,
  getUpcomingJobs: PropTypes.func,
  match: PropTypes.object,
  params: PropTypes.object,
  id: PropTypes.string,
  queryString: PropTypes.string,
  pauseJobs: PropTypes.func,
  cancelJobs: PropTypes.func,
  skipJobs: PropTypes.func,
  status: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    loading: state.job.loading,
    job: state.job.job,
    submitting: state.job.submitting,
    error: state.job.error,
    upcomingJobs: state.job.upcomingJobs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getJob: id => dispatch(getJob(id)),
    updateJob: data => dispatch(updateJob(data)),
    deleteJob: id => dispatch(deleteJob(id)),
    assignWorker: (id, data) => dispatch(assignWorker({ id, data })),
    getJobById: jobId => dispatch(getJobById(jobId)),
    getUpcomingJobs: payload => dispatch(getUpcomingJobs(payload)),
    pauseJobs: payload => dispatch(pauseJobs(payload)),
    cancelJobs: payload => dispatch(cancelJobs(payload)),
    skipJobs: payload => dispatch(skipJobs(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpcomingJobs));
