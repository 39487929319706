import styled from 'styled-components';
import { Form } from 'antd';

export default styled(Form.Item)`
  &&& {
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '')};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '')};
    margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '')};
    margin-right: ${({ marginRight }) => (marginRight ? marginRight : '')};
    display: flex;
    flex-direction: column;

    .ant-form-item {
      margin-bottom: 15px;
    }
    .ant-form-item-label {
      height: 22px;
      color: #000000;
      font-weight: 500;
      label {
        display: block;
        text-align: left;
        &::after {
          opacity: 0;
        }
      }
    }
    .ant-form-item-control {
      line-height: 1.5;
    }
  }
`;
