import React from 'react';

import 'antd/dist/antd.css';

import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './styles.css';
import ImgCrop from 'antd-img-crop';

function getBase64(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
}

async function getUrltoBase64(url) {
  const base = await fetch(url).then(x => x.blob());
  const blob = await getBase64(base);
  return blob;
}

class PicturesWall extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  };

  componentDidMount() {
    let thumbNail = [];
    Promise.all(
      this.props.values.map(url => {
        getUrltoBase64(url)
          .then(res => {
            thumbNail.push({ uid: Math.random(), thumbUrl: res });
            this.setState({
              fileList: this.props.values?.length > 0 ? thumbNail : [],
            });
            this.props.onImagesChange({
              target: {
                name: 'image',
                value: thumbNail.map(({ thumbUrl }) => thumbUrl),
              },
            });
            return res;
          })
          .catch(err => console.log('error ', err));
        return null;
      })
    );
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => {
    const newList = fileList.slice(0, 5);
    this.setState({ fileList: newList });
    setTimeout(() => {
      let uploadedImages = [];
      let file;
      fileList.map(async ({ originFileObj, thumbUrl }) => {
        if (originFileObj !== undefined) {
          file = await getBase64(originFileObj);
          uploadedImages.push(file);
        } else {
          uploadedImages.push(thumbUrl);
        }
        return null;
      });

      this.props.onImagesChange({
        target: { name: 'image', value: uploadedImages },
      });
    }, 1000);
  };

  render() {
    const { fileList } = this.state;
    const { error } = this.props;
    const {
      accept,
      // selected
    } = this.props;
    const uploadProps = {
      showUploadList: {
        showPreviewIcon: false,
      },
    };
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <>
        <ImgCrop
          aspect={
            this.props?.aspectRatio === undefined
              ? 1 / 1
              : this.props?.aspectRatio
          }
        >
          <Upload
            listType="picture-card"
            fileList={fileList}
            {...uploadProps}
            onChange={this.handleChange}
            multiple={true}
            // disabled={selected === 'single'}
            accept={accept}
            className={`multi-upload ${error?.image === 'Upload' && 'error'}`}
          >
            {/* <Avatar
            icon={fileList.length >= 5 ? null : uploadButton}
            shape="square"
            // src={localValue || value}
            size={100}
            style={{
              color: '#d3d3d3',
              backgroundColor: '#fff',
            }}
          /> */}
            {fileList.length >= 5 ? null : uploadButton}
          </Upload>
        </ImgCrop>
        {error?.image === 'Upload' && (
          <div role="alert" className="image-alert-message">
            {'Select Image'}
          </div>
        )}
      </>
    );
  }
}

PicturesWall.propTypes = {
  values: PropTypes.array,
  accept: PropTypes.string,
  onImagesChange: PropTypes.func,
  selected: PropTypes.string,
  error: PropTypes.any,
};

export default PicturesWall;
