import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'settings',
  initialState: {
    submitting: false,
    loading: false,
    settings: [],
    error: '',
  },
  reducers: {
    getSettings: state => {
      state.loading = true;
    },
    getSettingsSuccess: (state, { payload }) => {
      state.loading = false;
      state.settings = payload;
    },
    getSettingsFailed: state => {
      state.loading = false;
      state.settings = [];
    },
    createSettings: state => {
      state.submitting = true;
      state.message = message.loading('Saving User Settings..!', 0);
    },
    createSettingsSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.settings = { ...payload };
      notification.success({
        message: 'Successful',
        description: `Your Settings has been successfully saved.`,
      });
    },
    createSettignsFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    runJob: state => {
      state.submitting = true;
    },
    runJobSuccess: (state, { payload }) => {
      state.submitting = false;
      notification.success({
        message: 'Successful',
        description: payload.message,
      });
    },
    runJobFailed: (state, { payload }) => {
      state.submitting = false;
      apiError(payload);
    },
    runRevertJob: state => {
      state.submitting = true;
    },
    runRevertJobSuccess: (state, { payload }) => {
      state.submitting = false;
      notification.success({
        message: 'Successful',
        description: payload.message,
      });
    },
    runRevertJobFailed: (state, { payload }) => {
      state.submitting = false;
      apiError(payload);
    },
  },
});

export const {
  getSettings,
  getSettingsSuccess,
  getSettingsFailed,
  createSettings,
  createSettingsSuccess,
  createSettignsFailed,
  runJob,
  runJobFailed,
  runJobSuccess,
  runRevertJob,
  runRevertJobFailed,
  runRevertJobSuccess,
} = slice.actions;

export default slice.reducer;
