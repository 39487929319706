import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getSettings,
  getSettingsSuccess,
  getSettingsFailed,
  createSettings,
  createSettingsSuccess,
  createSettignsFailed,
  runJob,
  runJobSuccess,
  runJobFailed,
  runRevertJob,
  runRevertJobSuccess,
  runRevertJobFailed,
} from './reducer';

function* getSettingsSaga() {
  let action;
  let url = 'api/v1/admin/system_configurations';
  try {
    const response = yield call(request, 'GET', url);
    action = getSettingsSuccess(response);
  } catch (error) {
    action = getSettingsFailed(error.message);
  }
  yield put(action);
}

function* createSettingsSaga({ payload }) {
  let action;
  let url = '/api/v1/admin/system_configurations';
  try {
    const response = yield call(request, 'POST', url, {
      system_configuration: payload,
    });
    action = createSettingsSuccess(response);
  } catch (error) {
    action = createSettignsFailed(error.message);
  }
  yield put(action);
}

function* runJobSaga() {
  let action;
  let url = '/api/v1/admin/system_configurations/run_job';

  try {
    const response = yield call(request, 'PUT', url, {});
    if (response.workers_created) {
      action = runJobSuccess(response);
    } else {
      action = runJobFailed(response);
    }
  } catch (error) {
    action = runJobFailed(error.message);
  }
  yield put(action);
}

function* runRevertJobSaga() {
  let action;
  let url = '/api/v1/admin/system_configurations/revert_job';

  try {
    const response = yield call(request, 'PUT', url, {});
    if (response.workers_reverted) {
      action = runRevertJobSuccess(response);
    } else {
      action = runRevertJobFailed(response);
    }
  } catch (error) {
    action = runRevertJobFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getSettings, getSettingsSaga);
  yield takeLatest(createSettings, createSettingsSaga);
  yield takeLatest(runJob, runJobSaga);
  yield takeLatest(runRevertJob, runRevertJobSaga);
}
