import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getJobsFeedback,
  getjobsfeedbackSuccess,
  getjobsfeedbackFailed,
} from './reducers';

function* getJobsSaga({ payload }) {
  let action;
  let url = `api/v1/jobs/feedback?sort=service_date&direction=desc`;

  if (payload['page']) {
    url += `&page=${payload['page']}`;
  }
  if (payload['per']) {
    url += `&per=${payload['per']}`;
  }
  if (payload['query']) {
    url += `&query=${payload['query']}`;
  }

  try {
    const response = yield call(request, 'GET', url);
    action = getjobsfeedbackSuccess({
      type: payload.type,
      response,
    });
  } catch (error) {
    action = getjobsfeedbackFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getJobsFeedback, getJobsSaga);
}
