import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../../utils/notification';

const slice = createSlice({
  name: 'vendorusers',
  initialState: {
    submitting: false,
    loading: false,
    vendor: null,
    error: null,
  },
  reducers: {
    getVendor: state => {
      state.loading = true;
      state.error = null;
    },
    getVendorSuccess: (state, { payload }) => {
      state.loading = false;
      state.vendor = payload;
      state.error = null;
    },
    getVendorFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    createUser: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Adding a new worker ...', 0);
    },
    createUserSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      state.vendor = payload?.vendor_users;
      notification.success({
        message: 'New Worker',
        description: `New Worker has been added.`,
      });
    },
    createUserFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    updateUser: state => {
      state.submitting = true;
      state.error = null;
      state.message = message.loading('Updating vendor info...', 0);
    },
    updateUserSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = null;
      state.vendor = payload?.vendor_users;
      notification.success({
        message: 'Worker Updated',
        description: `Worker has been updated.`,
      });
    },
    updateUserFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.error = payload;
      apiError(payload);
    },
    deleteUser: state => {
      state.message = message.loading('Deleting vendor user ...', 0);
      state.error = null;
    },
    deleteUserSuccess: (state, { payload }) => {
      state.message();
      state.vendor = state.vendor.filter(
        x => x.id !== payload['vendor_user'].id
      );
      state.error = null;
      notification.success({
        message: 'Delete Vendor User',
        description: `Vendor User ${payload['vendor_user'].first_name} ${payload['vendor_user'].last_name} has been deleted.`,
      });
    },
    deleteUserFailed: (state, { payload }) => {
      state.message();
      state.error = payload;
      apiError(payload);
    },
  },
});

export const {
  getVendor,
  getVendorSuccess,
  getVendorFailed,
  createUser,
  createUserSuccess,
  createUserFailed,
  updateUser,
  updateUserSuccess,
  updateUserFailed,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailed,
} = slice.actions;

export default slice.reducer;
