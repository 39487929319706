import React from 'react';

import { Drawer } from 'antd';
import PropTypes from 'prop-types';

import CreateSubCategory from './createSubCategory';
import { connect } from 'react-redux';

import {
  createSubCategory,
  getSubCategories,
  getSubCategoriesByFilter,
} from '../../pages/SubCategoriesPage/reducers';

function CreateSubCategoryDrawer(props) {
  const {
    createSubCategory,
    loading,
    submitting,
    categoryState,
    getSubCategoriesByFilter,
  } = props;
  const { categories } = categoryState;
  const { getSubCategories } = props;
  React.useEffect(() => {
    getSubCategories({
      page: 1,
      per: 20,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isModalOpen, handleClose, categoryDefaultValue } = props;

  const onSubmit = async (values, resetForm) => {
    await createSubCategory({ sub_category: values });
    getSubCategoriesByFilter(categoryDefaultValue);
    resetForm();
    handleClose();
  };
  return (
    <Drawer
      closable={false}
      placement="right"
      width={450}
      visible={isModalOpen}
      onClose={handleClose}
      destroyOnClose
      forceRender={false}
    >
      <CreateSubCategory
        closeModal={handleClose}
        onSubmit={onSubmit}
        loading={loading || submitting}
        categories={categories.data}
        categoryDefaultValue={categoryDefaultValue || ''}
      />
    </Drawer>
  );
}

CreateSubCategoryDrawer.prototype = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  loding: PropTypes.bool,
  submitting: PropTypes.bool,
  createSubCategory: PropTypes.func,
  updateSubCategory: PropTypes.func,
  categories: PropTypes.array,
  categoryDefaultValue: PropTypes.number,
  getSubCategoriesByFilter: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loading: state.subCategories.loading,
    categories: state.subCategories.categories,
    submitting: state.subCategories.submitting,
    subCategories: state.subCategories.subCategories,
    categoryState: state.categories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createSubCategory: data => dispatch(createSubCategory(data)),
    getSubCategories: q => dispatch(getSubCategories(q)),
    getSubCategoriesByFilter: id => dispatch(getSubCategoriesByFilter(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSubCategoryDrawer);
