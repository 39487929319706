import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../../utils/apisauce';
import {
  getClients,
  getClientsSuccess,
  getClientsFailed,
  createClient,
  createClientSuccess,
  createClientFailed,
  updateClient,
  updateClientSuccess,
  updateClientFailed,
  deleteClient,
  deleteClientSuccess,
  deleteClientFailed,
  getTransactionDetails,
  getTransactionDetailsSuccess,
  getTransactionDetailsFailed,
} from './reducers';

function* getClientsSaga({ payload }) {
  let action;
  // let url = `/api/v1/clients/${payload.type}?`;
  let url = `/api/v1/vendor/personal_users?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }
    const response = yield call(request, 'GET', url);
    action = getClientsSuccess(response);
  } catch (error) {
    action = getClientsFailed(error.message);
  }
  yield put(action);
}

function* createClientSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/vendor/${
        payload.type === 'personal' ? 'personal_users' : 'business_users'
      }`,
      payload.data
    );
    action = createClientSuccess({
      type: payload.type,
      response: response.personal_user,
    });
  } catch (error) {
    action = createClientFailed(error.message);
  }
  yield put(action);
}

function* updateClientSaga({ payload }) {
  let action;
  try {
    let path;
    // let path = `/api/v1/clients/business/${payload.data.id}`;
    if (payload.type === 'personal') {
      path = `api/v1/vendor/personal_users/${payload.clientId}`;
    }
    const response = yield call(request, 'PUT', path, payload.data);
    action = updateClientSuccess({
      type: payload.type,
      response: response.personal_user,
    });
  } catch (error) {
    action = updateClientFailed(error.message);
  }
  yield put(action);
}

function* deleteClientSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/vendor/${payload.type}/${payload.id}`
    );
    action = deleteClientSuccess({
      type: payload.type,
      response,
    });
  } catch (error) {
    action = deleteClientFailed(error.message);
  }
  yield put(action);
}

function* getTransactionDetailsSaga({ payload }) {
  let action;
  try {
    const { accounting_transactions } = yield call(
      request,
      'GET',
      `/api/v1/admin/accounting_transactions?filter[account_id]=${payload[0].account.id}`
    );
    action = getTransactionDetailsSuccess({
      accounting_transactions,
    });
  } catch (error) {
    action = getTransactionDetailsFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getClients, getClientsSaga);
  yield takeLatest(createClient, createClientSaga);
  yield takeLatest(updateClient, updateClientSaga);
  yield takeLatest(deleteClient, deleteClientSaga);
  yield takeLatest(getTransactionDetails, getTransactionDetailsSaga);
}
