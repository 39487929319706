import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import * as subCategoryReducers from './reducers';
import {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailed,
  getSubCategories,
  getSubCategoriesSuccess,
  getSubCategoriesFailed,
  getSubCategoriesByFilter,
  getSubCategoriesByFilterSuccess,
  getSubCategoriesByFilterFailed,
} from './reducers';

const API_BASE_NAME = '/api/v1/admin/sub_categories';

function* getCategoriesSaga() {
  let action;
  let url = `/api/v1/admin/categories?all=true`;
  try {
    const response = yield call(request, 'GET', url);
    action = getCategoriesSuccess(response);
  } catch (error) {
    action = getCategoriesFailed(error.message);
  }
  yield put(action);
}

function* getSubCategoriesSaga({ payload }) {
  let action;
  if (!payload) {
    payload = {
      page: 1,
      per: 20,
    };
  }
  let url = `${API_BASE_NAME}?`;
  try {
    if (payload['page']) {
      url += `page=${payload['page']}`;
    }
    if (payload['per']) {
      url += `&per=${payload['per']}`;
    }
    if (payload['query']) {
      url += `&query=${payload['query']}`;
    }

    const response = yield call(request, 'GET', url);

    action = getSubCategoriesSuccess(response);
  } catch (error) {
    action = getSubCategoriesFailed(error.message);
  }
  yield put(action);
}

function* getSubCategoriesByFilterSaga(payload) {
  let action;
  try {
    const response = yield call(
      request,
      'GET',
      `/api/v1/admin/sub_categories?filter[category_id]=${payload.payload}`
    );

    action = getSubCategoriesByFilterSuccess(response);
  } catch (error) {
    action = getSubCategoriesByFilterFailed(error.message);
  }
  yield put(action);
}

function* createSubCategorySaga({ payload }) {
  let action;
  try {
    const response = yield call(request, 'POST', `${API_BASE_NAME}`, payload);
    action = subCategoryReducers.createSubCategorySuccess(response);
  } catch (error) {
    action = subCategoryReducers.createSubCategoryFailed(error.message);
  }
  yield put(action);
}

function* updateSubCategorySaga({ payload }) {
  let action;
  const subcatId = payload.sub_category.id;
  delete payload.sub_category.id;
  try {
    const response = yield call(
      request,
      'PUT',
      `${API_BASE_NAME}/${subcatId}`,
      payload
    );
    action = subCategoryReducers.updateSubCategorySuccess(response);
  } catch (error) {
    action = subCategoryReducers.updateSubCategoryFailed(error.message);
  }
  yield put(action);
}

function* deleteSubCategorySaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `${API_BASE_NAME}/${payload}`
    );
    action = subCategoryReducers.deleteSubCategorySuccess(response);
  } catch (error) {
    action = subCategoryReducers.deleteSubCategoryFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getCategories, getCategoriesSaga);
  yield takeLatest(getSubCategories, getSubCategoriesSaga);
  yield takeLatest(getSubCategoriesByFilter, getSubCategoriesByFilterSaga);
  yield takeLatest(
    subCategoryReducers.createSubCategory,
    createSubCategorySaga
  );
  yield takeLatest(
    subCategoryReducers.updateSubCategory,
    updateSubCategorySaga
  );
  yield takeLatest(
    subCategoryReducers.deleteSubCategory,
    deleteSubCategorySaga
  );
}
