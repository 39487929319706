import './styles.css';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Tooltip } from 'antd';
// import { PlusOutlined, UserOutlined } from '@ant-design/icons';
// import TableSearch from '../../components/TableSearch';
import DeleteModal from './components/delete-modal';
import CreateDrawer from './components/create-drawer';
import DataTable from '../../components/DataTable';
import {
  getWithdrawals,
  withdrawalRequestAction,
} from '../VendorProfilePage/reducers';
// import { withdrawalRequestAction } from './reducers';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  CheckOutlinedIcon,
  CloseOutlinedIcon,
  CheckCircleOutlinedIcon,
  CloseCircleOutlinedIcon,
} from '../../components/Icons/Icons';

import {
  localStore,
  // getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
} from './reducers';
// import { getImageURI, capitalize } from '../../utils/apisauce';
// import {
//   DeleteFilledIcon,
//   EditFilledIcon,
//   EyeFilledIcon,
// } from '../../components/Icons/Icons';

function tableColumns(
  showEdit,
  showDelete,
  selectRow,
  rejectedHandler,
  disbursedHandler
) {
  return [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Amount',
      dataIndex: 'formatted_amount',
      key: 'formatted_amount',
    },
    {
      title: 'Vendor',
      key: 'vendor_name',
      render: function vendordisp(_, record) {
        return <span>{record.vendor.vendor_name}</span>;
      },
    },
    {
      title: 'Requested By',
      key: 'requested_by',
      render: function adminDisplay(_, record) {
        return <span>{record.vendor_user.vendor_name}</span>;
      },
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: function adminActions(text, record) {
        return (
          <div
            className="ant-table-action-cell"
            style={{ justifyContent: 'center' }}
          >
            {record.status === 'requested' ? (
              <>
                <CheckOutlinedIcon
                  onClick={e => e.stopPropagation() || disbursedHandler(record)}
                />
                <CloseOutlinedIcon
                  onClick={e => e.stopPropagation() || rejectedHandler(record)}
                />
              </>
            ) : record.status === 'disbursed' ? (
              <Tooltip title={`Disbursed`}>
                <CheckCircleOutlinedIcon />
              </Tooltip>
            ) : record.status === 'rejected' ? (
              <Tooltip title={`Rejected`}>
                <CloseCircleOutlinedIcon />
              </Tooltip>
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
  ];
}

function WithdrawalRequest(props) {
  const dispatch = useDispatch();
  const { meta, loading, submitting, error } = useSelector(store =>
    localStore(store)
  );
  const [modalState, setModalState] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState(null);
  // const [dataSource, updateDataSource] = useState([]);
  // const [
  // queryString,
  // updateQueryString
  // ] = useState('');

  // const onTableSearch = () =>
  //   getAdminsHandler({ query: queryString, per: meta['per_page'] });

  // const getAdminsHandler = pageQuery => dispatch(getWithd  rawals(pageQuery));
  // const withdrawalRequestAction = (id,status) => dispatch(withdrawalRequestAction(id,status));

  useEffect(() => {
    dispatch(
      getWithdrawals({
        page: 1,
        per: 20,
        query: null,
      })
    );
  }, [dispatch]);

  // useEffect(() => {
  //   updateDataSource(
  //     withdrawals.map(admin => ({
  //       ...withdrawas,
  //       // full_name: `${admin['first_name']} ${admin['last_name']}`,
  //     }))
  //   );
  // }, [admins]);

  useEffect(() => {
    if (!submitting && !error) {
      closeModal();
    }
  }, [submitting, error]);

  const closeModal = () => {
    setModalState(0);
    setSelectedRow(null);
    setFormData(null);
  };

  const selectRow = record => {
    setModalState(1);
    setSelectedRow(record);
    setFormData(null);
  };

  // const showCreateModal = () => {
  //   setModalState(1);
  //   setSelectedRow(null);
  //   setFormData(defaultUser);
  // };

  const showEditModal = record => {
    setModalState(1);
    setSelectedRow(null);
    setFormData({ ...record, password: '' });
  };

  const showDeleteModal = record => {
    setModalState(2);
    setSelectedRow(record);
    setFormData(null);
  };

  const onDeleteAdmin = () => {
    dispatch(deleteAdmin(selectedRow.id));
    closeModal();
  };

  const onCreateAdmin = admin => {
    dispatch(createAdmin(admin));
  };

  const onUpdateAdmin = admin => {
    dispatch(updateAdmin(admin));
    closeModal();
  };

  // const handleTableChange = pagination => {
  //   getAdminsHandler({
  //     page: pagination['current'],
  //     per: pagination['pageSize'],
  //     query: queryString ? queryString : null,
  //   });
  // };

  const disbursedHandler = record => {
    // console.log('ok hai')
    dispatch(
      withdrawalRequestAction({
        id: record.id,
        status: 'disbursed',
      })
    );
  };
  const rejectedHandler = record => {
    dispatch(
      withdrawalRequestAction({
        id: record.id,
        status: 'rejected',
      })
    );
  };

  const dataColumns = tableColumns(
    showEditModal,
    showDeleteModal,
    selectRow,
    rejectedHandler,
    disbursedHandler
  );
  const { withdrawal_requests } = props;
  // console.log('with with ', withdrawal_requests);

  return (
    <div className="admins-page">
      <DeleteModal
        onClose={closeModal}
        onDelete={onDeleteAdmin}
        visible={modalState === 2}
        selected={selectedRow}
      />
      <CreateDrawer
        onUpdate={onUpdateAdmin}
        onCreate={onCreateAdmin}
        onClose={closeModal}
        onChange={setFormData}
        showDeleteModal={showDeleteModal}
        showEditModal={showEditModal}
        visible={modalState === 1}
        selected={selectedRow}
        formData={formData}
      />
      <div className="page-header">
        <div className="page-title">
          <h4>Widthdrawals Requests</h4>
        </div>
        {/* <div className="page-header-actions">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showCreateModal}
          >
            Add New Admin
          </Button>
        </div> */}
      </div>
      <div className="page-content">
        {/* <TableSearch
          placeholder="Search withdrawals"
          btnText="Search"
          onBtnClick={onTableSearch}
          value={queryString}
          onInputChange={ev => updateQueryString(ev['target']['value'])}
        /> */}
        <Card>
          <DataTable
            rowKey={record => record['id'] || 'id'}
            loading={loading}
            data={withdrawal_requests ? withdrawal_requests : []}
            columns={dataColumns}
            // onClickRow={selectRow}
            pagination={{
              limit: meta ? meta['per_page'] : 20,
              total: meta ? meta['total_count'] : 0,
              page: meta ? meta['page'] : 1,
              data: withdrawal_requests ? withdrawal_requests : [],
              // onChange: onPageChange,
            }}
            // handleTableChange={handleTableChange}
          />
        </Card>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    withdrawal_requests: state.vendor.withdrawal_requests,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWithdrawals: data => dispatch(getWithdrawals(data)),
    withdrawalRequestAction: (id, status) =>
      dispatch(withdrawalRequestAction(id, status)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WithdrawalRequest));
