import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';

import { apiError } from '../../utils/notification';

const slice = createSlice({
  name: 'orders',
  initialState: {
    submitting: false,
    loading: false,
    orders: [],
    error: '',
    accounts: [],
    orderPlaced: false,
    jobs: [],
  },
  reducers: {
    getAccounts: state => {
      state.loading = true;
    },
    getAccountsSuccess: (state, { payload }) => {
      state.loading = false;
      state.accounts = payload.accounts;
    },
    getAccountsFailed: state => {
      state.loading = false;
      state.accounts = [];
    },
    getJob: state => {
      state.loading = true;
    },
    getJobSuccess: (state, { payload }) => {
      state.loading = false;
      if (payload.job) {
        state.jobs = [payload.job];
      } else {
        state.jobs = [payload];
      }
    },
    getJobFailed: state => {
      state.loading = false;
      state.jobs = [];
    },
    getOrders: state => {
      state.loading = true;
    },
    getOrdersSuccess: (state, { payload }) => {
      state.loading = false;
      state.orders = payload;
    },
    getOrdersFailed: state => {
      state.loading = false;
      state.orders = [];
    },
    createOrder: state => {
      state.submitting = true;
      state.message = message.loading('Creating a new order ...', 0);
    },
    createOrderSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      const orders = state.orders.orders;
      orders.push({
        ...payload.order,
        worker_count: 0,
      });
      state.orders.orders = orders;
      notification.success({
        message: 'New Order',
        description: `${payload.meta.message}`,
      });
    },
    createOrderFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    updateOrder: state => {
      state.submitting = true;
      state.message = message.loading('Updating Order info...', 0);
    },
    updateOrderSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      const idx = state.orders.orders.findIndex(el => el.id === payload.id);
      if (idx >= 0) {
        state.orders[idx] = {
          ...state.orders[idx],
          ...payload,
        };
      }
      notification.success({
        message: 'Order Updated',
        description: `Order has been updated.`,
      });
    },
    updateOrderFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      apiError(payload);
    },
    deleteOrder: state => {
      state.message = message.loading('Deleting Order ...', 0);
    },
    deleteOrderSuccess: (state, { payload }) => {
      state.message();
      state.orders = state.orders.filter(x => x.id !== payload.id);
      notification.success({
        message: 'Delete Order',
        description: `Order have been deleted.`,
      });
    },
    deleteOrderFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    orderShipped: state => {
      // state.submitting = true;

      state.loading = true;
      state.message = message.loading('Shipping order ...', 0);
    },
    orderShippedSuccess: (state, { payload }) => {
      // state.submitting = false;
      console.log('this payload of shipped order is', payload);
      state.loading = false;
      state.message();
      const idx = state.orders.orders.findIndex(
        el => el.id === payload?.order?.id
      );
      if (idx >= 0) {
        state.orders.orders[idx] = {
          ...state.orders.orders[idx],
          ...payload.order,
        };
      }
      notification.success({
        message: 'Order Updated',
        description: `Order has been updated.`,
      });
    },
    orderShippedFailed: (state, { payload }) => {
      state.message(payload?.error ? payload.error : 'Error');
      // state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    orderDelivered: state => {
      // state.submitting = true;
      state.loading = true;
      state.message = message.loading('Delivering  order ...', 0);
    },
    orderDeliveredSuccess: (state, { payload }) => {
      state.message();
      // state.submitting = false;
      state.loading = false;
      const idx = state.orders.orders.findIndex(
        el => el.id === payload?.order?.id
      );
      if (idx >= 0) {
        state.orders.orders[idx] = {
          ...state.orders.orders[idx],
          ...payload.order,
        };
      }
      notification.success({
        message: 'Order Updated',
        description: `Order has been updated.`,
      });
    },
    orderDeliveredFailed: (state, { payload }) => {
      state.message(payload?.error ? payload.error : 'Error');
      // state.submitting = false;
      state.loading = false;
      apiError(payload);
    },
    cancelOrderRequest: state => {
      state.loading = true;
      state.message = message.loading('Cancel Request Sending ...', 0);
    },
    cancelOrderRequestSuccess: (state, { payload }) => {
      state.loading = false;
      state.message();
      console.log('payload in succes', payload);
      const idx = state.orders.orders.findIndex(
        el => el.id === payload?.order?.id
      );
      console.log('idx', idx);
      if (idx >= 0) {
        state.orders.orders[idx] = {
          ...state.orders.orders[idx],
          ...payload.order,
        };
      }
      notification.success({
        message: 'Order Updated',
        description: `Order has been canceled.`,
      });
    },
    cancelOrderRequestFailed: (state, { payload }) => {
      state.message(payload?.error ? payload.error : 'Error');
      state.loading = false;
      apiError(payload);
    },
  },
});

export const {
  getAccounts,
  getAccountsSuccess,
  getAccountsFailed,
  getJob,
  getJobSuccess,
  getJobFailed,
  getOrders,
  getOrdersSuccess,
  getOrdersFailed,
  createOrder,
  createOrderSuccess,
  createOrderFailed,
  updateOrder,
  updateOrderSuccess,
  updateOrderFailed,
  deleteOrder,
  deleteOrderSuccess,
  deleteOrderFailed,
  orderShipped,
  orderShippedSuccess,
  orderShippedFailed,
  orderDelivered,
  orderDeliveredSuccess,
  orderDeliveredFailed,
  cancelOrderRequest,
  cancelOrderRequestSuccess,
  cancelOrderRequestFailed,
} = slice.actions;

export default slice.reducer;
