import { Card, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderLoadableComponent } from '../../../../components/Loader';
const dayLabels = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

const Detail = ({ job }) => {
  // const { job_details } = job;
  // const { client } = job_details;

  const [active, setActive] = React.useState(false);
  const [jobDetail, setJobDetail] = React.useState({});

  useEffect(() => {
    if (job.job_details) {
      // const { client } = job.job_details;
      setJobDetail(job);
      setActive(true);
    }
  }, [job.job_details, job]);
  const renderCard = () => {
    const {
      title,
      client,
      vendor,
      address,
      // assigned_worker,
      description,
      job_notes,
      preferred_time,
      job_duration_in_minutes,
      formatted_amount,
    } = jobDetail.job_details;
    // checkin_time,
    // checkout_time } = jobDetail.job_details;
    const { frequency_value, days, days_quantity_limit, status } = job;
    return (
      <Card>
        <div className="job-info-top">
          <Row>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Title</label>
                <span>{title}</span>
              </div>
            </Col>
            <Col span={6}>
              <div className="job-detail-block">
                <label>Description</label>
                <span>{description ? description : ''}</span>
              </div>
            </Col>
            <Col span={4}>
              <div className="job-detail-block">
                <label>Amount</label>
                <span>{formatted_amount ? formatted_amount : '$0.00'}</span>
              </div>
            </Col>
            <Col span={4}>
              <div className="job-detail-block">
                <label>Status</label>
                <span style={{ textTransform: 'capitalize' }}>
                  {status ? status : ''}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="job-info-top">
          <Row>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Client Name</label>
                <span>{client?.title}</span>
              </div>
              <div className="job-detail-block">
                <label>Address</label>
                <span>
                  {address
                    ? Object.keys(address).length > 0
                      ? `${address?.address_title}, ${address?.unit_number}, ${address?.address}, ${address?.city}, ${address?.country}`
                      : ''
                    : ''}
                </span>
                <span>
                  {address
                    ? Object.keys(address).length > 0
                      ? address?.postal_code
                      : ''
                    : ''}
                </span>
              </div>
            </Col>
            <Col span={16}>
              <div className="job-detail-block">
                <label>Job Notes</label>
                <span>{job_notes ? job_notes : ''}</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="job-info-bottom">
          <Row>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Assigned Vendor</label>
                <span>
                  {vendor
                    ? Object.keys(vendor).length > 0
                      ? vendor?.vendor_name
                      : ''
                    : ''}
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Jobs Frequency</label>
                <span>
                  {frequency_value ? frequency_value : ''} times per weeks
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Preferred Day of Service</label>
                <span>
                  {days
                    ? days.length > 0
                      ? days.map(el => dayLabels[el]).join(', ')
                      : ''
                    : ''}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Date Limit</label>
                <span>{days_quantity_limit ? days_quantity_limit : ''}</span>
              </div>
            </Col>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Preferred Time Arrival</label>
                <span>
                  {preferred_time
                    ? moment(preferred_time, 'hh:mm:ss A').format('hh:mm A')
                    : '-'}
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div className="job-detail-block">
                <label>Job Duration</label>
                <span>
                  {job_duration_in_minutes
                    ? job_duration_in_minutes + ' minutes'
                    : '-'}{' '}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    );
  };

  // const cal_job_duration = (checkin_time, checkout_time) => {
  //   const startTime = moment(checkin_time, 'HH:mm:ss a');
  //   const endTime = moment(checkout_time, 'HH:mm:ss a');
  //   const { _data } = moment.duration(endTime.diff(startTime));
  //   return `${_data.hours}hr ${_data.minutes}min ${_data.seconds}sec`;
  // }

  return (
    <React.Fragment>
      {active === true ? (
        <React.Fragment>
          {renderLoadableComponent(false, renderCard())}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderLoadableComponent(true, <div></div>)}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Detail.propTypes = {
  job: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    loading: state.vendorJob.loading,
    job: state.vendorJob.job,
  };
}

export default connect(mapStateToProps, null)(withRouter(Detail));
