import React from 'react';
import Icon, { UserOutlined } from '@ant-design/icons';
import { Avatar, List, Tooltip, Table } from 'antd';
import { capitalize, getImageURI } from '../../../utils/apisauce';
import './../style.css';
import {
  EditFilledIcon,
  DeleteFilledIcon,
} from '../../../components/Icons/Icons';
import Archive from '../../../assets/icons/archive';
import Unarchive from '../../../assets/icons/unArchive ';

export const TableColumns = (onEdit, onDelete, onArchive, onUnarchive) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: function avatar(title, record) {
      return (
        <Avatar
          src={getImageURI(record['image'] && record['image'].url)}
          icon={<UserOutlined />}
          size={'large'}
          shape="square"
        />
      );
    },
  },
  {
    title: 'Product Name',
    key: 'title',
    render: (title, record) => {
      return <p>{record.title ? record.title : ''}</p>;
    },
  },
  {
    title: 'Category',
    key: 'category',
    render: (title, record) => {
      return <p>{record.category ? capitalize(record.category.title) : ''}</p>;
    },
  },
  {
    title: 'Sub Category',
    key: 'sub_category',
    render: (title, record) => {
      return (
        <p>{record.sub_category ? capitalize(record.sub_category.name) : ''}</p>
      );
    },
  },
  {
    title: 'Items',
    key: 'items',
    align: 'center',
    render: (text, record) =>
      record['items'].length > 0 ? record['items'].length : '-',
  },
  {
    title: 'Actions',
    key: 'action',
    align: 'center',
    render: function action(text, record) {
      return (
        <div
          className="ant-table-action-cell"
          style={{ justifyContent: 'center' }}
        >
          <EditFilledIcon
            onClick={e => e.stopPropagation() || onEdit(record)}
          />
          {record.status === 'active' ? (
            <Icon
              component={Archive}
              onClick={e => e.stopPropagation() || onArchive(record)}
            />
          ) : (
            <Icon
              component={Unarchive}
              onClick={e => e.stopPropagation() || onUnarchive(record)}
            />
          )}
          <DeleteFilledIcon
            onClick={e => e.stopPropagation() || onDelete(record)}
          />
        </div>
      );
    },
  },
];
export const expandedRowRender = record => {
  const columns = [
    {
      title: 'ID',
      key: 'id',
      width: 70,
      render: function jobId(text, record) {
        return <span>{record['id']}</span>;
      },
    },
    {
      title: 'Item Image',
      key: 'itemImage',
      width: 180,
      render: function itemImage(title, record) {
        return (
          <List
            split={false}
            dataSource={[record]}
            renderItem={item => {
              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      item?.images?.length >= 1 ? (
                        item?.images.map((img, i) => (
                          <Avatar
                            key={i}
                            src={getImageURI(img.url)}
                            icon={<UserOutlined />}
                            size={'large'}
                            shape="square"
                            style={{ margin: '2px' }}
                          />
                        ))
                      ) : (
                        <Avatar
                          src={getImageURI(item['image'] && item['image'].url)}
                          icon={<UserOutlined />}
                          size={'large'}
                          shape="square"
                        />
                      )
                    }
                  />
                </List.Item>
              );
            }}
          />
        );
      },
    },
    {
      title: 'Item Name',
      key: 'itemName',
      width: 180,
      render: function itemName(title, record) {
        return (
          <List
            split={false}
            dataSource={[record]}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  style={{ width: '140px' }}
                  description={
                    item.title?.length > 20 ? (
                      <Tooltip title={`${item.title}`} placement="top">
                        {capitalize(item.title).substring(0, 20)}
                        <span style={{ color: '#2d9cdb', cursor: 'pointer' }}>
                          ...Read more
                        </span>
                      </Tooltip>
                    ) : (
                      item.title
                    )
                  }
                />
              </List.Item>
            )}
          />
        );
      },
    },
    {
      title: 'Unit',
      key: 'unit',
      width: 150,
      render: (text, record) => (record['unit'] ? record['unit'] : '-'),
    },
    {
      title: 'Stock',
      key: 'stock_count',
      width: 100,
      align: 'center',
      render: (text, record) =>
        record['stock_count'] ? record['stock_count'] : '-',
    },
    {
      title: 'Max Limit',
      key: 'max_redemption_per_order',
      width: 100,
      align: 'center',
      render: (text, record) =>
        record['max_redemption_per_order']
          ? record['max_redemption_per_order']
          : '-',
    },
    {
      title: 'Amount',
      key: 'amount',
      width: 100,
      render: (text, record) => (record['amount'] ? record['amount'] : '-'),
    },
    {
      title: 'Commission',
      key: 'commission_percentage',
      width: 100,
      render: (text, record) =>
        record['commission_percentage'] ? record['commission_percentage'] : '-',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={record.items}
      pagination={false}
      bordered
    />
  );
};
