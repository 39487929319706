import styled from 'styled-components';
import {
  DeleteFilled,
  EditFilled,
  EyeFilled,
  TransactionOutlined,
  MoneyCollectOutlined,
  SolutionOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';

export const TransIcon = styled(TransactionOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
  }
`;
export const DeleteFilledIcon = styled(DeleteFilled)`
  svg {
    color: ${props => (props.color ? props.color : '#DC4E4E')};
  }
`;
export const EditFilledIcon = styled(EditFilled)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
  }
`;
export const EyeFilledIcon = styled(EyeFilled)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
  }
`;
export const MoneyCollectOutlinedIcon = styled(MoneyCollectOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
  }
`;
export const SolutionOutlinedIcon = styled(SolutionOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
  }
`;
export const IdcardOutlinedIcon = styled(IdcardOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
  }
`;
export const CheckOutlinedIcon = styled(CheckOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#48BB53')};
  }
`;
export const CloseOutlinedIcon = styled(CloseOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#DC4E4E')};
  }
`;
export const CheckCircleOutlinedIcon = styled(CheckCircleOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#48BB53')};
    opacity: ${props => (props.disabled ? '0.5' : '1')};
    :hover {
      cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    }
  }
`;
export const CloseCircleOutlinedIcon = styled(CloseCircleOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#DC4E4E')};
  }
`;
export const InfoCircleOutlinedIcon = styled(InfoCircleOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
  }
`;
export const SendOutlinedIcon = styled(SendOutlined)`
  svg {
    color: ${props => (props.color ? props.color : '#2d9cdb')};
    opacity: ${props => (props.disabled ? '0.5' : '1')};
    :hover {
      cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    }
  }
`;
