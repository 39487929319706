import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import './style.css';
import { SearchOutlined } from '@ant-design/icons';

function TableSearch(props) {
  const {
    name,
    value,
    placeholder,
    btnText,
    onBtnClick,
    onInputChange,
    style,
  } = props;

  return (
    <form
      className="table-search-container"
      onSubmit={ev => {
        ev.preventDefault();
        onBtnClick();
      }}
      style={style}
    >
      <input
        className="table-search-input"
        value={value ? value : ''}
        name={name}
        placeholder={placeholder}
        onChange={onInputChange}
      />
      <Button className="table-search-btn" type="primary" htmlType="submit">
        <SearchOutlined />
        {btnText}
      </Button>
    </form>
  );
}

TableSearch.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  btnText: PropTypes.string,
  onBtnClick: PropTypes.func,
  onInputChange: PropTypes.func,
};

export default TableSearch;
