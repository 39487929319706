import { takeLatest, call, put } from 'redux-saga/effects';
import request from '../../utils/apisauce';
import {
  getTransactionsforReferral,
  getTransactionsforReferralSuccess,
  getTransactionsforReferralFailed,
  getTransactionsforAdmin,
  getTransactionsforAdminSuccess,
  getTransactionsforAdminFailed,
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
  getTransactionsforTransfer,
  getTransactionsforTransferSuccess,
  getTransactionsforTransferFailed,
  getTransactionsfortopup,
  getTransactionsfortopupSuccess,
  getTransactionsfortopupFailed,
  gettransactionsforProduct,
  getTransactionsforProductSuccess,
  getTransactionsforProductFailed,
  gettransactionsforService,
  getTransactionsforServiceSuccess,
  getTransactionsforServiceFailed,
  getTransactionsForClasses,
  getTransactionsForClassesSuccess,
  getTransactionsForClassesFailed,
  getTransactionsforSubcription,
  getTransactionsforSubcriptionSuccess,
  getTransactionsforSubcriptionFailed,
  createTransaction,
  createTransactionSuccess,
  createTransactionFailed,
  updateTransaction,
  updateTransactionSuccess,
  updateTransactionFailed,
  deleteTransaction,
  deleteTransactionSuccess,
  deleteTransactionFailed,
} from './reducers';

function* gettransactionSagaforReferral() {
  let action;
  let url = `api/v1/admin/withdrawal_requests`;

  // if (payload.query) {
  //   url = `api/v1/admin/accounting_transactions?filter[transaction_for]=mobile_transfer&query=${payload.query}`;
  // }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforReferralSuccess(response);
  } catch (error) {
    action = getTransactionsforReferralFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagaforAdmin({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=admin`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=mobile_transfer&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforAdminSuccess(response);
  } catch (error) {
    action = getTransactionsforAdminFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagaforTransfer({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=mobile_transfer`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=mobile_transfer&query=${payload.query}`;
  }
  try {
    const response = yield call(request, 'GET', url);
    action = getTransactionsforTransferSuccess(response);
  } catch (error) {
    action = getTransactionsforTransferFailed(error.message);
  }
  yield put(action);
}

function* gettransactionSagaforSubscription({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=subscription`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=subscription&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforSubcriptionSuccess(response);
  } catch (error) {
    action = getTransactionsforSubcriptionFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagaforService({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=service`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=service&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforServiceSuccess(response);
  } catch (error) {
    action = getTransactionsforServiceFailed(error.message);
  }

  yield put(action);
}

function* getTransactionsSagaForClasses({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=classes`;

  if (payload.query) {
    url += `&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsForClassesSuccess(response);
  } catch (error) {
    action = getTransactionsForClassesFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagaforProduct({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=product`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=product&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsforProductSuccess(response);
  } catch (error) {
    action = getTransactionsforProductFailed(error.message);
  }

  yield put(action);
}

function* gettransactionSagafortopup({ payload }) {
  let action;
  let url = `api/v1/admin/accounting_transactions?filter[transaction_for]=topup`;

  if (payload.query) {
    url = `api/v1/admin/accounting_transactions?filter[transaction_for]=topup&query=${payload.query}`;
  }

  try {
    const response = yield call(request, 'GET', url);

    action = getTransactionsfortopupSuccess(response);
  } catch (error) {
    action = getTransactionsfortopupFailed(error.message);
  }

  yield put(action);
}

function* getTransactionsSaga({ payload }) {
  let action;
  try {
    if (payload.query) {
      const response = yield call(
        request,
        'GET',
        `api/v1/admin/accounting_transactions?query=${payload.query}`
      );
      action = getTransactionsSuccess(response);
    } else {
      const response = yield call(
        request,
        'GET',
        `api/v1/admin/accounting_transactions`
      );
      action = getTransactionsSuccess(response);
    }
  } catch (error) {
    action = getTransactionsFailed(error.message);
  }
  yield put(action);
}

function* createTransactionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'POST',
      `/api/v1/admin/vendors`,
      payload
    );
    action = createTransactionSuccess(response);
  } catch (error) {
    action = createTransactionFailed(error.message);
  }
  yield put(action);
}

function* updateTransactionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'PUT',
      `/api/v1/admin/vendors/${payload.id}`,
      payload
    );
    action = updateTransactionSuccess(response);
  } catch (error) {
    action = updateTransactionFailed(error.message);
  }
  yield put(action);
}

function* deleteTransactionSaga({ payload }) {
  let action;
  try {
    const response = yield call(
      request,
      'DELETE',
      `/api/v1/admin/vendors/${payload}`
    );
    action = deleteTransactionSuccess(response);
  } catch (error) {
    action = deleteTransactionFailed(error.message);
  }
  yield put(action);
}

export default function* saga() {
  yield takeLatest(getTransactionsforReferral, gettransactionSagaforReferral);
  yield takeLatest(getTransactionsforAdmin, gettransactionSagaforAdmin);
  yield takeLatest(getTransactionsforTransfer, gettransactionSagaforTransfer);
  yield takeLatest(
    getTransactionsforSubcription,
    gettransactionSagaforSubscription
  );
  yield takeLatest(gettransactionsforService, gettransactionSagaforService);
  yield takeLatest(getTransactionsForClasses, getTransactionsSagaForClasses);
  yield takeLatest(gettransactionsforProduct, gettransactionSagaforProduct);
  yield takeLatest(getTransactionsfortopup, gettransactionSagafortopup);
  yield takeLatest(getTransactions, getTransactionsSaga);
  yield takeLatest(createTransaction, createTransactionSaga);
  yield takeLatest(updateTransaction, updateTransactionSaga);
  yield takeLatest(deleteTransaction, deleteTransactionSaga);
}
