import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { apiError } from '../../utils/notification';

const DEFAULT_TAGS = {
  data: [],
  limit: 0,
  total: 0,
  page: 1,
};
const slice = createSlice({
  name: 'slideshow',
  initialState: {
    loading: false,
    submitting: false,
    formOpen: false,
    slide_show_images: DEFAULT_TAGS,
    media_library_images: { data: [] },
    error: '',
    errors: {},
  },
  reducers: {
    getMedia: state => {
      state;
    },
    getMediaSuccess: (state, { payload }) => {
      state.media_library_images = {
        data: payload.library_images,
      };
    },
    getMediaFailed: state => {
      state;
    },
    getSlides: state => {
      state.loading = true;
    },
    getSlidesSuccess: (state, { payload }) => {
      state.loading = false;
      state.slide_show_images = {
        data: payload.slide_show_images,
        limit: parseInt(payload.meta.per_page),
        page: parseInt(payload.meta.page),
        total: parseInt(payload.meta.total_count),
      };
    },
    getSlidesFailed: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      apiError(payload);
    },
    createSlide: state => {
      state.submitting = true;
      state.message = message.loading('Creating a new slide show ...', 0);
    },
    createSlideSuccess: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.formOpen = false;
      state.errors = {};
      const slide_show_image = payload.slide_show_image;
      state.slide_show_images.data = [
        slide_show_image,
        ...state.slide_show_images.data,
      ];
      notification.success({
        message: 'New Slide',
        description: `New Slide ${slide_show_image.title} has been created.`,
      });
    },
    createSlideFailed: (state, { payload }) => {
      state.message();
      state.submitting = false;
      state.formOpen = true;
      apiError(payload);
      if (payload && payload.length > 0) {
        state.errors = payload[0].meta.errors;
      }
    },
    updateSlide: state => {
      state.submitting = true;
      state.message = message.loading('Updating slide show info...', 0);
    },
    updateSlideSuccess: (state, { payload }) => {
      state.message();
      state.formOpen = false;
      state.submitting = false;
      state.errors = {};
      const slide_show_image = payload.slide_show_image;
      const idx = state.slide_show_images.data.findIndex(
        el => el.id === slide_show_image.id
      );
      if (idx >= 0) {
        state.slide_show_images.data[idx] = {
          ...state.slide_show_images.data[idx],
          ...slide_show_image,
        };
      }
      // state.formData = {...INIT_FORM};

      notification.success({
        message: 'Slide Updated',
        description: `Slide ${slide_show_image.title} has been updated.`,
      });
    },
    updateSlideFailed: (state, { payload }) => {
      state.message();
      state.submitting = true;
      state.formOpen = true;
      apiError(payload);
      if (payload && payload.length > 0) {
        state.errors = payload[0].meta.errors;
      }
    },
    deleteSlide: state => {
      state.message = message.loading('Deleting slide ...', 0);
    },
    deleteSlideSuccess: (state, { payload }) => {
      state.message();
      const slide_show_image = payload.slide_show_image;
      state.slide_show_images.data = state.slide_show_images.data.filter(
        x => x.id !== slide_show_image.id
      );
      notification.success({
        message: 'Slide Deleted',
        description: `Slide ${slide_show_image.title} has been deleted.`,
      });
    },
    deleteSlideFailed: (state, { payload }) => {
      state.message();
      apiError(payload);
    },
    startEditSlide: (state, { payload }) => {
      state.formOpen = payload;
    },
  },
});

export const {
  getMedia,
  getMediaFailed,
  getMediaSuccess,
  getSlides,
  getSlidesFailed,
  getSlidesSuccess,
  createSlide,
  createSlideSuccess,
  createSlideFailed,
  updateSlide,
  updateSlideSuccess,
  updateSlideFailed,
  deleteSlide,
  deleteSlideFailed,
  deleteSlideSuccess,
  startEditSlide,
} = slice.actions;
export default slice.reducer;
